import { action, computed, makeAutoObservable, runInAction } from "mobx";
import { Socket } from "socket.io-client";

import {
    ICommunicationChatMessage,
    ICommunicationGameHistoryNew,
    ICommunicationGameNotification,
} from "./types";

import {
    IBetRequest,
    IApplyBetRequest,
} from "./../../service/api/game/requestResponses";
import GameStore from "./index";

class GameCommunicationState {
    constructor(gameStore: GameStore) {
        makeAutoObservable(this);
        this.gameStore = gameStore;
    }
    socket: Socket;
    gameStore: GameStore;
    messages: ICommunicationChatMessage[] = [];
    history: ICommunicationGameHistoryNew[] = [];
    notifications: ICommunicationGameNotification[] = [];
}

export default GameCommunicationState;
