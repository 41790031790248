import React, { CSSProperties, memo, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import TabPanel from "@mui/lab/TabPanel";

import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import { EGameChatTab, IStickerPackList, IStickerRequest } from "src/store/models";
import { ICommunicationChatMessage } from "src/store/gameStore/types";
import useChangeTheme from "src/hooks/useChangeTheme";
import {
    isGameDialogMessage,
    isGameDialogMessageNew,
} from "src/store/gameStore/types";

import BlockMessages from "../blockMessages";
import { useStyles } from "../styles";
import { GameChatHistoryMessage } from "../message";
import { IChatOpponentAndMeMessages } from "../types/IChat";

const ChatMessage = memo(({ item, key, stickers, calcPosition, getTextMessage, getStickerMessage }: {
    item: ICommunicationChatMessage;
    key: string;
    stickers: IStickerPackList[];
    calcPosition: (item: ICommunicationChatMessage) => string;
    getTextMessage: (message: any) => string | undefined;
    getStickerMessage: (message: any) => string | undefined;
}) => {
    return (
        <GameChatHistoryMessage
            key={`${item?.time}_${key}`}
            time={item.time}
            position={calcPosition(item)}
            stickers={stickers}
            getStickerMessage={item.type === 3 ? getStickerMessage(item.message) : undefined}
        >
            {getTextMessage(item.message)}
        </GameChatHistoryMessage>
    );
}, (prevProps, nextProps) => {
    return prevProps.item === nextProps.item &&
        prevProps.stickers === nextProps.stickers;
});

ChatMessage.displayName = 'ChatMessage';

const GameChatPanel = memo(({
    showHideOpponent,
    chat,
    isChatBlocked,
}: IChatOpponentAndMeMessages) => {
    const appearanceTheme = useChangeTheme();
    const { extractCompiled } = useLocale();
    const { gameStore, authStore, tournamentsStore } = useStores();

    const shouldHide = tournamentsStore.isHideChat;
    const chessboardSize = gameStore?.chessboardSize;
    const stickers = authStore.stickers;

    const classes = useStyles({
        shouldHide,
        showHideOpponent,
        appearanceTheme,
        chessboardSize,
        stickers,
    });

    const {
        game: {
            chat: { blockedMessages },
        },
    } = useLocale();

    const getTextMessage = useCallback((message) => {
        if (typeof message === 'object' && message.id && message.pack) {
            return undefined;
        }
        if (typeof message === 'object') {
            return message.label
                ? extractCompiled(message.label, message.props || {}, message.defaultValue || '')
                : JSON.stringify(message);
        }
        return message;
    }, [extractCompiled]);

    const getStickerMessage = useCallback((message: IStickerRequest): string | undefined => {
        if (stickers && message && typeof message === 'object' && message.id && message.pack) {
            const currentStickerPack = stickers.find(pack => pack.stickerPackName === message.pack);
            return currentStickerPack?.stickersDetails.find(sticker => sticker.id === message.id)?.filename;
        }
        return undefined;
    }, [stickers]);

    const calcPosition = useCallback(
        (item: ICommunicationChatMessage) => {
            return authStore.currentUser?._id === item.owner._id ? "rtl" : "ltr";
        },
        [authStore.currentUser?._id]
    );

    const filteredMessages = useMemo(() => {
        if (!chat) return [];
        return chat
            .filter(item => isGameDialogMessageNew(item) && (item.type === 1 || item.type === 3))
            .slice()
            .reverse();
    }, [chat]);

    const blurStyle = useMemo((): CSSProperties => ({
        filter: shouldHide ? 'blur(20px)' : 'none',
        opacity: shouldHide ? 0.1 : 1,
        pointerEvents: shouldHide ? 'none' as const : 'auto' as const,
        transition: 'filter 0.3s, opacity 0.3s',
    }), [shouldHide]);

    return (
        <TabPanel
            value={EGameChatTab.chat}
            style={{
                height: "100%",
                padding: 0,
                border: "1px solid rgba(255, 255, 255, 0.3)",
                borderRadius: "12px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderBottomStyle: "none",
                width: "auto",
                margin: 0,
            }}
        >
            <div className={cn(classes.messagesBox, appearanceTheme)} style={blurStyle}>
                {!isChatBlocked ? (
                    <div className={cn(classes.messagesBoxContainer, EGameChatTab.chat, appearanceTheme)}>
                        {filteredMessages.map((item) => (
                            <ChatMessage
                                key={`${item.time}_${item.owner._id}`}
                                item={item}
                                stickers={stickers}
                                calcPosition={calcPosition}
                                getTextMessage={getTextMessage}
                                getStickerMessage={getStickerMessage}
                            />
                        ))}
                    </div>
                ) : (
                    <BlockMessages message={blockedMessages("Opponent blocked Chat")} />
                )}
            </div>
        </TabPanel>
    );
});

GameChatPanel.displayName = 'GameChatPanel';

export default GameChatPanel;
