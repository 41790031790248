import { ColorVariant, UserBalanceType } from "../../../store/models";
import { IHistoryGame } from "../../../store/viewGameStore/types";
import { string } from "yup";
import { GameTimeValues } from "src/store/lobbyStore";

export interface ICreateGameRequest {
    color: ColorVariant;
    undo: boolean;
    position: boolean;
    rounds: number;
    private: boolean;
    time: number;
    increment: number;
    fen: "";
    bet: number;
    doubling: boolean;
    invite: "";
    challenged?: string;
    balanceType: UserBalanceType;
}

export interface ICreateGameResponse {
    id: string;
    balance: {
        [UserBalanceType.play]: number;
        [UserBalanceType.coins]: number;
        [UserBalanceType.referralBonus]: number;
    };
    error?: any;
}

export enum GameCreateFen {
  classic = 1,
  nineHundredSixty = 2,
}

export enum GameModeCreate {
    Solo = 1,
    Match = 2,
    Free = 4,
}

export enum GameTypeCreate {
    one = 1,
    two = 2,
}

export enum GameCreateTime {
    one = 60000,
    three = 300000,
    five = 600000,
    ten = 1200000,
    twenty = 2400000,
}

export interface ICreateGameRequestNew {
    bank: number;
    freeGame: GameTypeCreate; // 1 - Coin and 2 - Freecoin
    fenType: GameCreateFen; // 1 - ClassicGame and 2 - 960 Mode Game
    gameType: GameModeCreate; // 1 - Solo and 2 - Match Game
    gameCount: number; // number of Matches (if its matches game)
    time: GameTimeValues;
    sendTo?: string;
}

export interface ISendInviteRequestNew extends ICreateGameRequestNew {
    sendTo: string;
}

type SuccessResponseGame<T extends string> = {
    [key: string]: T;
};
export interface IGameErrorResponse {
    error: string;
    message: string;
    statusCode: number;
}

export type ICreateGameResponseNew =
    | SuccessResponseGame<string>
    | IGameErrorResponse;

export interface IRemoveGameRequest {
    gameId: string;
}

export interface IRemoveGameResponse {
    error?: any;
    id: string;
    balance: {
        [UserBalanceType.play]: number;
        [UserBalanceType.coins]: number;
        [UserBalanceType.referralBonus]: number;
    };
}

export interface IGetGameRequest {
    gameId: string;
}

export interface IGetGameResponse extends IHistoryGame {
    error?: any;
    rounds: number;
}

export interface IinviteGameRequest {
    inviteId: string;
}

export type IinviteGameResponse = IinviteGameRequest | IGameErrorResponse;
export type IinviteNoramalGameResponse = IGetGameRequest | IGameErrorResponse;

export interface SportEventData {
    title: string;
    imageURL: string;
    drawCoefficient: string;
    eventAt: string;
    participants: {
        name: string;
        avatarURL: string;
        coefficient: number;
        betPercent: number;
    }[];
}
export interface SportEventResponseData {
    data: SportEventData;
    name: string;
    _id: string;
}

export interface ISportEventResponse {
    data: SportEventResponseData;
}