import React, { useMemo } from "react";
import cn from "classnames";
import { Scrollbar } from "react-scrollbars-custom";
import useStores from "src/hooks/useStores";
import { observer } from "mobx-react";
import { useTransition, animated } from "react-spring";

import crownIcon from "src/assets/icons/crown-contained.svg";
import { ITopPlayersNew } from "src/store/models";
import refreshIcon from "src/assets/icons/refresh.svg";
import { Spinner } from "src/components/spinner";

import { useStyles } from "../styles";
import { TopPlayerItem } from "./TopPlayerItem";

export interface ITopPlayersElement {}

const TopListBox = () => {
    const classes = useStyles();
    const { authStore, achievementsStatStore } = useStores();
    const topPlayersNew = achievementsStatStore.topPlayersNew;
    const me = authStore?.currentUser?._id;

    const transitionsTopPlayers = useTransition(topPlayersNew, {
        from: {
            opacity: 0.3,
            transform: "translate3d(300px,0,0) rotate(120deg)",
            "-webkit-transform": "translate3d(300px,0,0) rotate(120deg)",
        },
        enter: {
            opacity: 1,
            transform: "translate3d(0px,0,0) rotate(0deg)",
            "-webkit-transform": `translate3d(0px,0,0) rotate(0deg)`,
        },
        trail: 70,
    });
    const myPosition = (
        topArray: ITopPlayersNew[] | null,
        me: string | undefined
    ) => {
        if (topArray && me) {
            return topArray.findIndex((player) => player._id === me) + 1;
        }
        return null;
    };

    return (
        <>
            <div style={{}} className={classes.customScrollbar}>
                <div className={classes.listWrapper}>
                    {transitionsTopPlayers(
                        (style, topPlayer, transitionState, i) => (
                            <animated.div
                                style={{
                                    ...style,
                                    backfaceVisibility: "hidden",
                                    willChange: "transform",
                                }}
                            >
                                {topPlayer ? (
                                    <TopPlayerItem
                                        myPosition={topPlayer._id === me}
                                        place={i + 1}
                                        topPlayer={topPlayer}
                                        key={`${topPlayer.nickname}_${topPlayer.rating}_${i}`}
                                    />
                                ) : null}
                            </animated.div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};
export default observer(TopListBox);
