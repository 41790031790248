import { action, computed, makeAutoObservable, runInAction } from "mobx";

import { map } from "rxjs/operators";

import { useHistory } from "react-router-dom";
import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";
import isEqual from "lodash/isEqual";
import { Nullable } from "src/shared/types";
import { errorService } from "src/service/services";
import throttle from "src/utils/throttle";
import {
    IBetRequest,
    IMooveRequest,
    IRollRequest,
} from "src/service/api/game/requestResponses";
import { ISendMessageInGame } from "src/service/api/gameChat/requestResponses";
import ItemAvatar from "src/components/modals/ChangeAvatarModal/ItemAvatar";

import paths from "src/consts/paths";
import { GameModeType, RankType } from "../models";
import { IsocketGameData } from "../models";

import {
    ColorVariant,
    GameResultData,
    GameStatus,
    IPlayer,
    OnlineStatus,
    IGameNew,
} from "../models";
import { IGuestNew } from "../gameStore/types";
import { getGameById } from "../../service/api/lobby";
import { rootStore } from "../../hooks/useStores";
import { RootStore } from "../index";
import sleep from "../../utils/sleep";
import { rematchDecline } from "src/service/api/game";

//====

import {
    IClockPerPlayer,
    IConnectivityPerPlayer,
    IDoublingData,
    IGameMessage,
    IGuest,
    IHistoryGame,
    IInitGameData,
    INotification,
    IRematchInfo,
    IGameState,
} from "./types";
import { extractFen } from "./utils";
import GameDialogs from "./dialogs";
import GameState from "./gameState";

import { IGameState as IGameStateGameStore } from "../gameStore/types";

export enum LoadingStatus {
    loading,
    loaded,
    notLoaded,
}
import {
    ERolledFigures,
    IConvertedRoleFigure,
    ERolledFiguresNumber,
} from "../gameStore/types";

const defaultFen = "nbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - -";

export class GameStore {
    dialogs: GameDialogs = new GameDialogs(this);
    gameState: GameState = new GameState(this);
    rootStore: RootStore;
    socket: Socket | null;
    currentGame?: IHistoryGame;
    gameId?: string;
    joined: boolean = false;
    loadingStatus: LoadingStatus = LoadingStatus.loading;
    clock: IClockPerPlayer[] = [];
    connectivity: IConnectivityPerPlayer[] = [];
    state?: IGameState;
    // initData?: IInitGameData;
    initData?: any;
    messages?: IGameMessage[] = [];
    notifications: INotification[] = [];
    rematch: IRematchInfo = {
        [ColorVariant.white]: null,
        [ColorVariant.black]: null,
    };
    // guests: IGuest[] = [];
    currentEventTimer: number = 1;
    nextEventTimer: boolean = false;
    prevEventTimer: boolean = false;
    play: boolean = true;
    stop: boolean = false;
    isNextButtonDisabled: boolean = false;
    isPrevButtonDisabled: boolean = false;
    isPlayButtonDisabled: boolean = false;
    isAnotherGameButtonDisabled: boolean = false;
    isPlayMode: boolean = false;
    clockTimeW: number = this.gameState.getTimeLeftByColor(ColorVariant.white);
    clockTimeB: number = this.gameState.getTimeLeftByColor(ColorVariant.black);
    doubleMessage: boolean = false;
    chessboardSize: number = 0;
    piecesSize: number = 0;
    //====
    guests: any[] = [];
    stateNew: IGameStateGameStore = {
        fen: defaultFen,
        history: [],
        legalMoves: [],
        legalPieces: [],
        rolledFigures: undefined,
        movesHistory: [],
        undo: null,
    };
    currentGameNew?: IGameNew;
    gameIsStart: boolean = false;

    sendMessage(message: string) {
        this.socket?.emit("chat:message", message);
    }
    get isReady() {
        return !!(
            this.initData &&
            this.connectivity &&
            this.state &&
            this.currentGame &&
            this.clock
        );
    }

    get isLoaded() {
        return !!(
            this.initData &&
            this.state &&
            this.currentGame &&
            this.clock
        );
    }

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    setRematchInfo(rematchInfo: IRematchInfo) {
        this.rematch = rematchInfo;
        this.dialogs.refreshRematchDialogState();
    }

    @action
    setGuests(guests: IGuest[]) {
        this.guests = guests;
    }

    @action
    addGuest(guest: IGuest) {
        this.guests.push(guest);
    }

    @action
    removeGuest(guestId: IGuest["id"]) {
        this.guests = this.guests.filter((guest) => guest.id !== guestId);
    }

    @action
    async rematchDecline(gameId?: string) {
        if (!gameId) return;
        try {
            const res = await rematchDecline({ gameId });
            if ("success" in res.data) {
                console.log("Decline Rematch Response");
            }
        } catch (e) {
            return errorService.sendError(
                e.response?.data?.message || "rematchDecline error"
            );
        }
    }

    @action
    setDoubling(
        doublingData: IDoublingData,
        showOpponentAcceptedDoublingDialog: boolean = true
    ) {
        if (!this.initData) return;
        this.initData.doubling = doublingData;

        this.dialogs.showOpponentAcceptedDoublingDialog();
        setTimeout(() => {
            this.dialogs.closeDoublingDialog();
        }, 1000);

        if (
            // this.initData?.status === GameStatus.IN_PROGRESS &&
            doublingData &&
            doublingData.aggressor
        ) {
            if (doublingData.accepted) {
                this.dialogs.showDoublingDialog();
            }
            if (doublingData.accepted && showOpponentAcceptedDoublingDialog) {
                this.dialogs.showOpponentAcceptedDoublingDialog();
                setTimeout(() => {
                    this.dialogs.closeDoublingDialog();
                }, 1000);
            }
            // if (
            //     doublingData.aggressor !== this.gameState.getMyColor() &&
            //     doublingData.accepted
            // ) {
            //     this.dialogs.closeDoublingDialog();
            // }
        } else {
            this.dialogs.closeDoublingDialog();
        }
    }

    @action
    setDraw(color: ColorVariant | null) {
        if (!this.initData) return;

        this.initData.drawingProposer = color;
        if (this.currentGame?.status === GameStatus.IN_PROGRESS && color) {
            if (color === this.gameState.getMyColor()) {
                this.dialogs.openWaitToDecideDrawDialog();
            } else {
                this.dialogs.openDecideDrawDialog();
            }
        } else {
            this.dialogs.closeDrawDialog();
        }
    }

    @action
    setOpponentRejectDraw(color: ColorVariant) {
        if (!this.initData) return;

        this.initData.drawingProposer = color;
        const isOpponentColor = color !== this.gameState.getMyColor();
        if (
            this.currentGame?.status === GameStatus.IN_PROGRESS &&
            color &&
            isOpponentColor
        ) {
            this.dialogs.openRejectedDrawDialog();
            setTimeout(() => {
                this.dialogs.closeDrawDialog();
            }, 1000);
        }
    }

    @action
    initGameBegin(status: boolean) {
        this.gameIsStart = status;
    }

    @action
    setGameResult(resultData: GameResultData) {
        if (!this.initData) return;
        this.initData.over = resultData;
        this.initData.status = GameStatus.FINISHED;
        this.currentGame!.status = GameStatus.FINISHED;
    }
    @action
    setClearGameResult() {
        if (!this.initData) return;
        //this.initData.over = resultData;
        this.initData.status = GameStatus.IN_PROGRESS;
        this.currentGame!.status = GameStatus.IN_PROGRESS;
    }

    @computed
    getClockByColor(color: ColorVariant) {
        return this.clock.find(
            (item) => item.color === color
        ) as IClockPerPlayer;
    }

    @action
    setClock(newClock: [IClockPerPlayer, IClockPerPlayer]) {
        this.clock = newClock;
    }

    @action
    setConnectivity(
        newConnectivity: [IConnectivityPerPlayer, IConnectivityPerPlayer]
    ) {
        this.connectivity = newConnectivity;
    }

    @action
    setGameState(state: IGameState) {
        this.state = state;

        //this.gameState.rolled = false;
    }

    move(move: string) {
        this.socket?.emit("move", move);
        this.gameState.resetMove();
    }

    @action
    roll() {
        this.socket?.emit("roll");
        this.gameState.diceRnd = Math.random().toString();
        this.gameState.rolled = true;
    }

    get isMyGame() {
        return (
            this.currentGame?.players[0].id ===
            rootStore.authStore.currentUser?._id
        );
    }

    @action
    setGameId(gameId: string) {
        if (this.gameId === gameId) return;
        this.gameId = gameId;
        if (this.initData !== undefined) {
            this.initData = undefined;
            this.currentGame = undefined;
        }
        this.rematch = {
            [ColorVariant.white]: null,
            [ColorVariant.black]: null,
        };
        this.dialogs = new GameDialogs(this);
        this.gameState = new GameState(this);
        this.setup();
    }
    //===============

    @action
    setGameIdNewView(gameId: string) {
        if (this.gameId === gameId) return;
        this.gameId = gameId;
        if (this.initData !== undefined) {
            this.initData = undefined;
            this.currentGame = undefined;
        }
        this.rematch = {
            [ColorVariant.white]: null,
            [ColorVariant.black]: null,
        };
        this.dialogs = new GameDialogs(this);
        this.gameState = new GameState(this);
    }

    @action
    setGame(game: IHistoryGame | undefined) {
        this.currentGame = game;
        this.currentGame && (this.currentGame.status = GameStatus.IN_PROGRESS);
        this.gameId = game?.id;
        if (!game) return;
        this.setInitData({
            type: GameModeType.REGULAR,
            clock: [
                {
                    color: ColorVariant.white,
                    timeleft: 300000,
                    running: true,
                },
                {
                    color: ColorVariant.black,
                    timeleft: 300000,
                    running: false,
                },
            ],
            connectivity: [
                {
                    idx: 0,
                    status: OnlineStatus.online,
                },
                {
                    idx: 0,
                    status: OnlineStatus.online,
                },
            ],
            doubling: {
                multiplier: 1,
            },
            drawingProposer: null,
            history: [],
            inactivity: null,
            messages: [],
            players: game.players as [IPlayer, IPlayer],
            round: 1,
            settings: game.settings,
            state: {
                fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - -",
                legalMoves: [],
                legalPieces: [],
                undo: false,
            },
            status: GameStatus.IN_PROGRESS,
            over: null,
            rematch: {
                [ColorVariant.white]: null,
                [ColorVariant.black]: null,
            },
            guests: [],
        });
    }

    //=======

    @action async initGameSocketView() {
        this.clearSocket();
        const token = this.rootStore.authStore.getAuthToken();
        const gameForSearch = location.href.split("/").slice(-1)[0];
        if (!this.gameId) {
            this.gameId = gameForSearch;
        }
        const gameId = this.gameId;
        const host = process.env.REACT_APP_API_ENDPOINT;

        if (!gameId || !token || !host) {
            window.location.href = paths.diceChessLobby;
            errorService.sendError("Problem with auth or path to game");
            // throw new Error("Missing: gameId, token, or host");
        }
        try {
            const socket = io(`${host}/game`, {
                query: {
                    gameId: gameId,
                    userKey: token,
                    place: 4,
                },
                transports: ["websocket"],
                reconnection: true,
                reconnectionAttempts: 3,
                reconnectionDelay: 2000,
            });
            socket.on("connect", () => {
                console.log("connect", socket);
            });
            socket.on("connect_error", (error) => {
                console.log("connect_error", error);
            });
            socket.on("error", (error) => {
                console.log("error", error);
            });
            socket.on("disconnect", (reason) => {
                console.log("disconnect:", reason);
            });
            return socket;
        } catch (error) {
            console.error("Socket connection error:", error);
            return null;
        }
    }

    @action rolledConvert(
        rolledFigures: [ERolledFigures, ERolledFigures, ERolledFigures]
    ): IConvertedRoleFigure[] {
        if (!rolledFigures || rolledFigures.length < 3) {
            return [
                {
                    figure: ERolledFiguresNumber.p,
                    active: false,
                    id: 1,
                },
                {
                    figure: ERolledFiguresNumber.p,
                    active: false,
                    id: 2,
                },
                {
                    figure: ERolledFiguresNumber.p,
                    active: false,
                    id: 3,
                },
            ];
        }
        const converted = rolledFigures.map((item, index) => {
            switch (item) {
                case ERolledFigures.p:
                    return {
                        figure: ERolledFiguresNumber.p,
                        active: false,
                        id: index,
                    };
                case ERolledFigures.n:
                    return {
                        figure: ERolledFiguresNumber.n,
                        active: false,
                        id: index,
                    };
                case ERolledFigures.b:
                    return {
                        figure: ERolledFiguresNumber.b,
                        active: false,
                        id: index,
                    };
                case ERolledFigures.r:
                    return {
                        figure: ERolledFiguresNumber.r,
                        active: false,
                        id: index,
                    };
                case ERolledFigures.q:
                    return {
                        figure: ERolledFiguresNumber.q,
                        active: false,
                        id: index,
                    };
                case ERolledFigures.k:
                    return {
                        figure: ERolledFiguresNumber.k,
                        active: false,
                        id: index,
                    };
                default:
                    return {
                        figure: ERolledFiguresNumber.p,
                        active: false,
                        id: index,
                    };
            }
        });
        return converted;
    }

    @action setUpEventListenerView(data: IsocketGameData) {
        if (data) {
            if (this.loadingStatus !== LoadingStatus.loaded) {
                this.loadingStatus = LoadingStatus.loaded;
            }
            const _ = require("lodash");
            runInAction(() => {
                const dataForGameState = {
                    fen: data.fen,
                    legalMoves: data.availableMoves,
                    legalPieces: data.activeFigures,
                    undo: null,
                    history: data.history,
                    movesHistory: data.movesHistory,
                    rolledFigures: this.rolledConvert(data.rollFigures),
                };

                const existFen = this.stateNew.fen;
                const existRolledFigures = this.stateNew.rolledFigures;
                const existLegalPieces = this.stateNew.legalPieces;
                const existGameId = this.currentGameNew?._id;
                const existCurrentMove = this.currentGameNew?.curMove;
                const existExtra = this.currentGameNew?.extra;
                const existGameStatus = this.currentGameNew?.state;
                const existWinner = this.currentGameNew?.winner;
                const existBank = this.currentGameNew?.bank;
                const existCurrentPlayerCanX2 =
                    this.currentGameNew?.currentPlayerCanX2;

                const requiredUpdate = !(
                    _.isEqual(dataForGameState.fen, existFen) &&
                    _.isEqual(
                        dataForGameState.rolledFigures,
                        existRolledFigures
                    ) &&
                    _.isEqual(dataForGameState.legalPieces, existLegalPieces) &&
                    _.isEqual(data._id, existGameId) &&
                    _.isEqual(data.curMove, existCurrentMove) &&
                    _.isEqual(data.extra, existExtra) &&
                    _.isEqual(data.state, existGameStatus) &&
                    _.isEqual(data.winner, existWinner) &&
                    _.isEqual(
                        data.currentPlayerCanX2,
                        existCurrentPlayerCanX2
                    ) &&
                    _.isEqual(data.bank, existBank)
                );
                if (requiredUpdate) {
                    this.stateNew = dataForGameState;
                    this.currentGameNew = data;
                }
            });

            runInAction(() => {
                // Logical for get Viewers
                const requiredUpdate = !_.isEqual(this.guests, data.viewers);
                if (requiredUpdate) {
                    this.guests = data.viewers as IGuestNew[];
                }
            });
            // runInAction(() => {
            //     //Rematch Logical
            //     const rematchData = {
            //         rematchStatus: data.rematchStatus,
            //         rematchStartedBy: data.rematchStartedBy,
            //         matchId: data.matchId,
            //         matchCount: data.matchCount,
            //         nextMatch: data.nextMatch,
            //         gameDieTime: data.gameDieTime,
            //         rematchEnd: data.rematchEnd,
            //     };
            //     const existRematchData = this.dialogs.rematchData;
            //     const requiredUpdate = !_.isEqual(
            //         existRematchData,
            //         rematchData
            //     );
            //     if (requiredUpdate) {
            //         console.log("Rematch Log");
            //         this.dialogs.rematchData = rematchData as IRematchCollector;
            //     }
            // });

            // runInAction(() => {
            //     // Logical for Clock update
            //     const existClock = this.rightBlockCollector?.clockData;
            //     const clockData = data.timeLeft;
            //     const requiredUpdate = !_.isEqual(existClock, clockData);
            //     const initTime = 0;
            //     const almostEndGame = 15000;
            //     const second = 1000;
            //     const fiveSecond = 5000;
            //     const updateTime =
            //         clockData[0] <= almostEndGame ||
            //         clockData[1] <= almostEndGame
            //             ? second
            //             : fiveSecond;

            //     const updateClock = (time: number[]) => {
            //         this.rightBlockCollector.clockData = time as number[];
            //     };

            //     const isInitTime =
            //         existClock[0] === initTime && existClock[1] === initTime;
            //     if (requiredUpdate && isInitTime) {
            //         updateClock(clockData);
            //     }
            //     const throttle =
            //         existClock[0] - clockData[0] > updateTime ||
            //         existClock[1] - clockData[1] > updateTime;
            //     if (requiredUpdate && throttle && !data.winner) {
            //         updateClock(clockData);
            //     }
            // });
            runInAction(() => {
                //Dialog Logical
                // console.log(data);
            });
        }
    }
    @action
    setGameNewView() {
        this.setInitData({
            clock: [
                {
                    color: ColorVariant.white,
                    timeleft: 300000,
                    running: true,
                },
                {
                    color: ColorVariant.black,
                    timeleft: 300000,
                    running: false,
                },
            ],
            connectivity: [
                {
                    idx: 0,
                    status: OnlineStatus.online,
                },
                {
                    idx: 0,
                    status: OnlineStatus.online,
                },
            ],
            doubling: {
                multiplier: 1,
            },
            drawingProposer: null,
            history: [],
            inactivity: null,
            messages: [],
            players: [
                {
                    avatar: "empty",
                    status: "empty",
                    color: ColorVariant.white,
                    id: "123",
                    name: "test1",
                    nickname: "test1",
                    rating: 123,
                    score: 123,
                    winInGames: 123,
                    isSystemAvatar: false,
                    _id: "123",
                    rank: { title: RankType.atlant3 },
                },
                {
                    avatar: "empty",
                    status: "empty",
                    color: ColorVariant.black,
                    id: "321",
                    name: "test2",
                    nickname: "test2",
                    rating: 321,
                    score: 321,
                    winInGames: 321,
                    isSystemAvatar: false,
                    _id: "321",
                    rank: { title: RankType.atlant1 },
                },
            ],
            round: 1,
            settings: null,
            state: {
                fen: "8/8/8/8/8/8/8/8",
                legalMoves: [],
                legalPieces: [],
                undo: false,
            },
            status: GameStatus.IN_PROGRESS,
            over: null,
            rematch: {
                [ColorVariant.white]: null,
                [ColorVariant.black]: null,
            },
            guests: [],
        });
    }

    @action
    setGameNewViewDataUpdate() {
        this.setInitData({
            clock: [
                {
                    color: ColorVariant.white,
                    timeleft: 300000,
                    running: true,
                },
                {
                    color: ColorVariant.black,
                    timeleft: 300000,
                    running: false,
                },
            ],
            connectivity: [
                {
                    idx: 0,
                    status: OnlineStatus.online,
                },
                {
                    idx: 0,
                    status: OnlineStatus.online,
                },
            ],
            doubling: {
                multiplier: 1,
            },
            drawingProposer: null,
            history: [],
            inactivity: null,
            messages: [],
            players: [
                {
                    avatar: "empty",
                    status: "empty",
                    color: ColorVariant.white,
                    id: "123",
                    name: "test1",
                    nickname: "test1",
                    rating: 123,
                    score: 123,
                    winInGames: 123,
                    isSystemAvatar: false,
                    _id: "123",
                    rank: { title: RankType.atlant3 },
                },
                {
                    avatar: "empty",
                    status: "empty",
                    color: ColorVariant.black,
                    id: "321",
                    name: "test2",
                    nickname: "test2",
                    rating: 321,
                    score: 321,
                    winInGames: 321,
                    isSystemAvatar: false,
                    _id: "321",
                    rank: { title: RankType.atlant1 },
                },
            ],
            round: 1,
            settings: null,
            state: {
                fen: "8/8/8/8/8/8/8/8",
                legalMoves: [],
                legalPieces: [],
                undo: false,
            },
            status: GameStatus.IN_PROGRESS,
            over: null,
            rematch: {
                [ColorVariant.white]: null,
                [ColorVariant.black]: null,
            },
            guests: [],
        });
    }

    @action
    // setInitData(initData: IInitGameData ) {
    setInitData(initData: any) {
        this.initData = initData;
        this.setConnectivity(initData.connectivity);
        this.setGameState(initData.state);
        this.setClock(initData.clock);
        this.setDoubling(initData.doubling, false);
        this.setDraw(initData.drawingProposer);
        this.setRematchInfo(initData.rematch);
        this.setGuests(initData.guests);
        this.gameState.rolled = false;
    }

    async setup() {
        await this.loadGame();
        //this.setUpEventListener();
    }

    async loadGame() {
        runInAction(() => {
            this.loadingStatus = LoadingStatus.loading;
        });
        const gameId = this.gameId;
        if (!gameId) return;
        const res = await getGameById({ gameId });
        if (res.status === 200 && !res.data.error) {
            // this.setGame(res.data);
            runInAction(() => {
                this.loadingStatus = LoadingStatus.loaded;
            });
        } else {
            runInAction(() => {
                console.log("Error");
                this.loadingStatus = LoadingStatus.notLoaded;
            });
        }
    }
    //===

    addMessage(messages: IGameMessage[]) {
        this.messages = [...messages];
    }

    refreshBalance() {
        if (!this.socket) return;
        this.socket.emit("balance");
    }

    @action
    setUpEventListener() {}

    clearSocket() {
        if (this.socket) {
            this.socket.removeAllListeners();
            this.socket.disconnect();
            this.socket = null;
        }
    }

    join() {}

    @action
    cancelJoin() {
        this.socket?.emit("join:canceled");
        this.reset();
    }

    @action
    reset() {
        this.gameId = undefined;
        this.currentGame = undefined;
        this.clearSocket();
        this.joined = false;
    }

    @computed
    get fen() {
        if (!this.state) return defaultFen;
        return extractFen(this.state.fen);
    }

    @computed
    get gameSettings() {
        return this.initData?.settings;
    }

    @computed
    isParticipating() {
        const user = this.currentGame?.players.find((item) => {
            return item.id === rootStore.authStore.currentUser?._id;
        });
        return !!user;
    }

    @computed
    isViewMode() {
        return (
            !this.isParticipating() &&
            this.gameState.getMyColor() === ColorVariant.none
        );
    }

    @computed
    get isShowWinOrLostDialog() {
        return !this.dialogs.rematchDialog.isOpen && this.gameState.isOver;
    }

    @action
    setPlayMode(bool: boolean) {
        this.isPlayMode = bool;
    }

    @action
    setCurrentEventTimer(num: number) {
        this.currentEventTimer = num;
    }
    @action
    setNextEventTimer(bool: boolean) {
        this.nextEventTimer = bool;
    }
    @action
    setPrevEventTimer(bool: boolean) {
        this.prevEventTimer = bool;
    }

    @action
    setPlay(bool: boolean) {
        this.play = bool;
    }
    @action
    setStop(bool: boolean) {
        this.stop = bool;
    }
    @action
    setIsNextButtonDisabled(bool: boolean) {
        this.isNextButtonDisabled = bool;
    }
    @action
    setIsPlayButtonDisabled(bool: boolean) {
        this.isPlayButtonDisabled = bool;
    }
    @action
    setIsPrevButtonDisabled(bool: boolean) {
        this.isPrevButtonDisabled = bool;
    }
    @action
    setIsAnotherGameButtonDisabled(bool: boolean) {
        this.isAnotherGameButtonDisabled = bool;
    }
    @action
    setClockTimeW(time: number) {
        this.clockTimeW = time;
    }
    @action
    setClockTimeB(time: number) {
        this.clockTimeB = time;
    }
    @action
    setDoubleMessage(bool: boolean) {
        this.doubleMessage = bool;
    }
    @action
    setDoublingData(data, bool: boolean) {
        data.doubling.accepted = bool;
    }

    @action
    checkNextDoubleSuccess(startPoint: number, endPoint: number) {
        const events = this.currentGame?.events;
        for (let j = startPoint - 1; j < endPoint; j++) {
            if (events && events[j].event === "double:success") {
                if (this.currentEventTimer !== 1)
                    this.setCurrentEventTimer(startPoint - 3);
            }
        }
    }
    @action
    checkPrevDoubleSuccess(startPoint: number, endPoint: number) {
        const events = this.currentGame?.events;
        for (let j = startPoint - 1; j < endPoint; j--) {
            if (events && events[j].event === "double:success") {
                if (this.currentEventTimer !== 1)
                    this.setCurrentEventTimer(startPoint - 3);
            }
        }
    }

    @action
    checkDoublingModal(
        startPoint: number,
        endPoint: number
    ): boolean | undefined {
        const events = this.currentGame?.events;
        if (!events) return;
        //let startPoint = this.currentEventTimer;

        for (let i = startPoint; i < endPoint; i++) {
            if (events[i].event === "double:success") {
                if (events[i + 2].event === "double:success") {
                    this.setDoublingData(events[i].data, true);
                    this.setDoubleMessage(true);
                    break;
                } else {
                    this.setDoubleMessage(false);
                }
            }
        }
    }

    @action
    checkPrevDoublingModal(
        startPoint: number,
        endPoint: number
    ): boolean | undefined {
        const events = this.currentGame?.events;
        if (!events) return;

        for (let i = startPoint; i > 0; i--) {
            if (events[i].event === "double:success") {
                if (events[i + 2].event === "double:success") {
                    this.setDoublingData(events[i].data, false);
                    this.setDoubleMessage(true);
                    break;
                } else {
                    this.setDoubleMessage(false);
                    break;
                }
            }
        }
    }

    @action
    getEventTimer(): number[] | undefined {
        const events = this.currentGame?.events;
        if (!events) return;
        let startPoint = this.currentEventTimer;

        let endPoint;
        if (this.nextEventTimer && this.currentEventTimer < events.length) {
            for (
                startPoint = this.currentEventTimer;
                startPoint < events.length;
                startPoint++
            ) {
                const { event } = events[startPoint];
                if (event === "roll:pre") {
                    if (this.currentEventTimer !== 1)
                        this.setCurrentEventTimer(startPoint);
                }
                if (event === "move:success") {
                    endPoint = startPoint;
                    if (events[endPoint + 1].event === "over") {
                        endPoint = startPoint + 1;
                        this.setPlay(false);
                        break;
                    } else {
                        this.setPlay(false);
                        break;
                    }
                }
            }
            this.checkNextDoubleSuccess(this.currentEventTimer, endPoint);
            this.checkDoublingModal(this.currentEventTimer, endPoint);
        }
        if (this.prevEventTimer) {
            this.setIsNextButtonDisabled(false);
            for (
                startPoint = this.currentEventTimer + 1;
                startPoint > 0;
                startPoint--
            ) {
                const { event } = events[startPoint];
                if (event === "roll") {
                    this.setCurrentEventTimer(startPoint);
                }
                if (event === "roll:success") {
                    if (!endPoint) endPoint = startPoint;
                    this.setPlay(false);
                    break;
                }
            }
        }
        if (this.play) {
            startPoint = this.currentEventTimer;
            endPoint = events.length;
        }
        return [startPoint, endPoint];
    }

    async playGame(arr: number[]) {
        const events = this.currentGame?.events;
        if (!events) return;
        let turn = ColorVariant.none;
        let j;

        if (this.nextEventTimer && this.currentEventTimer <= events.length) {
            this.setIsPlayButtonDisabled(true);
            this.setIsPrevButtonDisabled(true);
            this.setIsNextButtonDisabled(true);
            this.setIsAnotherGameButtonDisabled(true);
            this.setStop(false);
            for (j = this.currentEventTimer; j <= arr[1]; j++) {
                const { event, data, color } = events[j];
                if (this.stop) {
                    this.setIsPrevButtonDisabled(false);
                    this.setIsPlayButtonDisabled(false);
                    this.setIsNextButtonDisabled(false);
                    this.setCurrentEventTimer(j);
                    break;
                }

                if (event == "roll:pre") {
                    this.setGameState({
                        fen: this.state?.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                }

                /*if (event == 'roll') {
                    this.setGameState({
                        fen: this.state?.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);

                }*/

                if (event == "roll:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: data.pieces,
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                }

                if (event == "roll:autopass:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                }

                if (event === "move:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: data.pieces,
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                    this.setCurrentEventTimer(j + 1);
                    this.setIsNextButtonDisabled(false);
                    this.setIsPlayButtonDisabled(false);
                }

                if (event == "double:success") {
                    if (this.doubleMessage) {
                        this.gameState.setCurrentPlayerColor(color);
                        this.setDoubling(data.doubling);
                        this.setDoubleMessage(false);
                    }
                    //continue;
                }

                if (event === "over") {
                    this.setGameResult(data);
                    this.setCurrentEventTimer(j);
                    this.setIsAnotherGameButtonDisabled(false);
                    this.setPlayMode(true);
                    const whiteClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.white
                    );
                    const blackClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.black
                    );
                    this.clock = [
                        {
                            color: ColorVariant.white,
                            timeleft: whiteClock,
                            running: false,
                        },
                        {
                            color: ColorVariant.black,
                            timeleft: blackClock,
                            running: false,
                        },
                    ];
                    this.setIsPrevButtonDisabled(false);
                    continue;
                }

                if (color && event !== "over") {
                    this.setClockTimeW(
                        this.gameState.getTimeLeftByColor(ColorVariant.white)
                    );
                    this.setClockTimeB(
                        this.gameState.getTimeLeftByColor(ColorVariant.black)
                    );
                    this.clock = [
                        {
                            color: ColorVariant.white,
                            timeleft: events[j].data.clocks
                                ? events[j].data.clocks[1].timeleft
                                : this.clockTimeW,
                            running: turn === ColorVariant.white,
                        },
                        {
                            color: ColorVariant.black,
                            timeleft: events[j].data.clocks
                                ? events[j].data.clocks[0].timeleft
                                : this.clockTimeB,
                            running: turn === ColorVariant.black,
                        },
                    ];
                    if (events[j].data.clocks)
                        this.setClockTimeW(events[j].data.clocks[1].timeleft);
                    if (events[j].data.clocks)
                        this.setClockTimeB(events[j].data.clocks[0].timeleft);
                    turn = color;
                }
                await sleep(1000);
            }
            this.setIsPrevButtonDisabled(false);
            this.setIsAnotherGameButtonDisabled(false);
        }
        if (this.prevEventTimer && this.currentEventTimer > 1) {
            this.setIsPlayButtonDisabled(true);
            this.setIsPrevButtonDisabled(true);
            this.setIsNextButtonDisabled(true);
            this.setIsAnotherGameButtonDisabled(true);
            this.setPlayMode(false);
            this.setStop(false);
            if (this.currentEventTimer === arr[1] - 1) {
                this.setCurrentEventTimer(arr[1]);
            }

            for (j = this.currentEventTimer; j >= arr[1]; j--) {
                const { event, data, color } = events[j];
                if (this.stop) {
                    this.setIsPrevButtonDisabled(false);
                    this.setIsPlayButtonDisabled(false);
                    this.setIsNextButtonDisabled(false);
                    this.setCurrentEventTimer(j);
                    break;
                }

                if (event == "roll:pre") {
                    this.setGameState({
                        fen: this.state?.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                }

                /*if (event == 'roll') {
                    this.setGameState({
                        fen: this.state?.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);

                }*/
                if (event == "roll:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: data.pieces,
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                    this.setIsPrevButtonDisabled(false);
                    this.setIsPlayButtonDisabled(false);
                    this.setIsNextButtonDisabled(false);
                    this.setCurrentEventTimer(j);
                }

                if (event == "roll:autopass:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                }

                if (event === "move:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: data.pieces,
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                }

                if (event == "double:success") {
                    this.checkPrevDoublingModal(j, j + 1);
                    if (this.doubleMessage) {
                        this.setDoubling(data.doubling);
                        this.setDoubleMessage(false);
                    }
                    continue;
                }

                if (event === "over") {
                    const whiteClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.white
                    );
                    const blackClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.black
                    );
                    this.clock = [
                        {
                            color: ColorVariant.white,
                            timeleft: whiteClock,
                            running: turn !== ColorVariant.white,
                        },
                        {
                            color: ColorVariant.black,
                            timeleft: blackClock,
                            running: turn !== ColorVariant.black,
                        },
                    ];
                    continue;
                }

                if (color) {
                    const whiteClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.white
                    );
                    const blackClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.black
                    );
                    this.clock = [
                        {
                            color: ColorVariant.white,
                            timeleft: events[j].data.clocks
                                ? events[j].data.clocks[1].timeleft
                                : whiteClock,
                            running: turn === ColorVariant.white,
                        },
                        {
                            color: ColorVariant.black,
                            timeleft: events[j].data.clocks
                                ? events[j].data.clocks[0].timeleft
                                : blackClock,
                            running: turn === ColorVariant.black,
                        },
                    ];
                    if (events[j].data.clocks)
                        this.setClockTimeW(events[j].data.clocks[1].timeleft);
                    if (events[j].data.clocks)
                        this.setClockTimeB(events[j].data.clocks[0].timeleft);
                    await sleep(1000);
                }
            }
            this.setCurrentEventTimer(j - 1);
            this.setIsAnotherGameButtonDisabled(false);
        }

        if (this.play) {
            this.setIsPlayButtonDisabled(true);
            this.setIsPrevButtonDisabled(true);
            this.setIsNextButtonDisabled(true);
            for (j = this.currentEventTimer; j < events.length; j++) {
                const { event, data, color } = events[j];

                if (event == "roll:pre") {
                    this.setGameState({
                        fen: this.state?.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                    this.setCurrentEventTimer(j);
                }

                /*if (event == 'roll') {
                    this.setGameState({
                        fen: this.state?.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);

                }*/
                if (event == "roll:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: data.pieces,
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                    this.setCurrentEventTimer(j);
                }

                if (event == "roll:autopass:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: [],
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                    this.setCurrentEventTimer(j);
                }

                if (event === "move:success") {
                    this.setGameState({
                        fen: data.fen as string,
                        legalMoves: [],
                        legalPieces: data.pieces,
                        undo: null,
                    });
                    if (!this.initData || !this.state) return;
                    this.initData.history.push(this.state.fen);
                    this.setCurrentEventTimer(j);
                }

                if (event == "double:success") {
                    this.setCurrentEventTimer(j);
                    this.checkDoublingModal(j, j + 2);
                    if (this.doubleMessage) {
                        this.gameState.setCurrentPlayerColor(color);
                        this.setDoubling(data.doubling);
                        this.setDoubleMessage(false);
                    }
                }

                if (event === "over") {
                    this.setGameResult(data);
                    this.setCurrentEventTimer(j);
                    this.setIsPlayButtonDisabled(true);
                    this.setPlayMode(true);
                    const whiteClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.white
                    );
                    const blackClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.black
                    );
                    this.clock = [
                        {
                            color: ColorVariant.white,
                            timeleft: whiteClock,
                            running: false,
                        },
                        {
                            color: ColorVariant.black,
                            timeleft: blackClock,
                            running: false,
                        },
                    ];
                    continue;
                }

                if (color && event !== "over") {
                    this.gameState.activeClock.color = color;
                    this.setClockTimeW(
                        this.gameState.getTimeLeftByColor(ColorVariant.white)
                    );
                    this.setClockTimeB(
                        this.gameState.getTimeLeftByColor(ColorVariant.black)
                    );
                    this.clock = [
                        {
                            color: ColorVariant.white,
                            timeleft: events[j].data.clocks
                                ? events[j].data.clocks[1].timeleft
                                : this.clockTimeW,
                            running: turn === ColorVariant.white,
                        },
                        {
                            color: ColorVariant.black,
                            timeleft: events[j].data.clocks
                                ? events[j].data.clocks[0].timeleft
                                : this.clockTimeB,
                            running: turn === ColorVariant.black,
                        },
                    ];
                    if (events[j].data.clocks)
                        this.setClockTimeW(events[j].data.clocks[1].timeleft);
                    if (events[j].data.clocks)
                        this.setClockTimeB(events[j].data.clocks[0].timeleft);
                    turn = color;
                }
                await sleep(1000);
                if (!this.play) {
                    this.setIsPlayButtonDisabled(false);
                    this.setIsPrevButtonDisabled(false);
                    const whiteClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.white
                    );
                    const blackClock = this.gameState.getTimeLeftByColor(
                        ColorVariant.black
                    );
                    this.clock = [
                        {
                            color: ColorVariant.white,
                            timeleft: whiteClock,
                            running: false,
                        },
                        {
                            color: ColorVariant.black,
                            timeleft: blackClock,
                            running: false,
                        },
                    ];
                    break;
                }
            }
            this.setIsPrevButtonDisabled(false);
            this.setIsNextButtonDisabled(false);
        }
    }

    @action
    setChessboardViewSize(size: number) {
        this.chessboardSize = size;
    }

    @action
    setPiecesViewSize(size: number) {
        if (size <= 95) {
            this.piecesSize = size;
        } else {
            this.piecesSize = 90;
        }
    }
}

export default GameStore;
