export class ArrayHelper {
    public static getArrayOrDefault<T, D = any>(
        input: T,
        defaultObject: D[] = []
    ) {
        if (Array.isArray(input)) return input;
        return defaultObject;
    }

    public static reverseMap<T, R>(
        array: T[],
        cb: (item: T, index: number) => R
    ): R[] {
        const result: R[] = [];
        for (let i = array.length - 1; i >= 0; i--) {
            result.push(cb(array[i], i));
        }

        return result;
    }

    public static pickRandom<T>(array: T[]): T {
        return array[Math.floor(Math.random() * array.length)];
    }

    public static fromRange(start: number, end: number) {
        if (start > end) throw new Error("start cannot be greater than end");
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i + 1);
        }
        return result;
    }

    public static chunkArray<T>(data: T[], chunkSize: number): T[][] {
        const result: T[][] = [];

        for (let i = 0; i < data.length; i += chunkSize) {
            result.push(data.slice(i, i + chunkSize));
        }

        return result;
    }
}
