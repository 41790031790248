import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";
import { React_Brackets_WIDTH_CONDITION } from "src/consts/window-size-params";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_XL_TABLET,
} from "src/pages/game-new/constants";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes moveGradient": {
            "0%": {
                opacity: 0.3,
            },
            "50%": {
                opacity: 0.7,
            },
            "100%": {
                opacity: 0.3,
            },
        },
    },

    gameNotSelect: {
        color: "white",
        left: "50%",
        top: "50%",
        fontSize: 15,
        transform: "translate(-50%,-50%)",
        position: "absolute",
    },

    returnToPreviousWrapper: {
        [theme.breakpoints.down(1250)]: {
            width: "100%",
            display: "flex",
            justifyContent: "align-start",
            alignItems: "center",
        },
        [theme.breakpoints.up(1250)]: {
            width: "100%",
            display: "flex",
            justifyContent: "align-start",
            alignItems: "center",
            cursor: "pointer",
            position: "absolute",
            top: 5,
            left: 10,
            opacity: 0.6,
            zIndex: 5,
            transition: "all .4s",
            "&:hover": {
                transition: "all .4s",
                opacity: 1,
            },
        },
        "&.isPreview": {
            [theme.breakpoints.between(
                React_Brackets_WIDTH_CONDITION,
                MAX_CHANGE_SCREEN_WIDTH
            )]: {
                width: "100%",
                display: "flex",
                justifyContent: "align-start",
                alignItems: "center",
                cursor: "pointer",
                position: "absolute",
                top: 5,
                left: 10,
                opacity: 0.6,
                zIndex: 5,
                transition: "all .4s",
                "&:hover": {
                    transition: "all .4s",
                    opacity: 1,
                },
            },
        },
    },

    previewGame: {
        // padding: '15px 10px;',
        borderRadius: 12,
        height: "calc(100vh - 200px)",
        position: "relative",
        background: "#202233",
        border: "1px solid #0B0C12",
        "&.deepBlack": {
            border: "1px solid #a0a0a061",
            "&.isFriends": {
                border: "1px solid #a0a0a061",
                background:
                    "linear-gradient(210.52deg, #3E2D6F 1.46%, #190E37 82.47%)",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                borderRadius: "12px",
            },
        },

        "@media screen and (min-width: 1251px) and (max-height: 900px)": {
            height: "calc(100vh - 150px)",
        },

        [`@media screen and (min-width: ${React_Brackets_WIDTH_CONDITION}px) 
        and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 850px)`]:
            {
                height: "calc(100vh - 150px)",
            },
    },

    previewGameContent: {
        padding: "5px",
        borderRadius: 12,
        height: "100%",
        position: "relative",
        background: "#202233",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.1)",
        "&.darkBlue": {
            "&.isFriends": {
                background:
                    "linear-gradient(210.52deg, #3E2D6F 1.46%, #190E37 82.47%)",
                // boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                borderRadius: "12px",
            },
        },
        "&.darkGreen": {
            "&.isFriends": {
                // border: '1px solid #0D7E71',
                background: "#141C20",
                // boxShadow: '0px 0px 40px 10px #113C4F',
                borderRadius: "12px",
            },
        },
        "&.deepBlack": {
            "&.isFriends": {
                background:
                    "linear-gradient(210.52deg, #3E2D6F 1.46%, #190E37 82.47%)",
                // boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                borderRadius: "12px",
            },
        },
        "@media screen and (min-width: 1251px) and (max-height: 900px)": {
            // height: 'calc(100vh - 150px)',
            height: "100%",
        },
        // height: '542px',
        // height: 'calc(100vh - 110px - 35px - 80px)',
        // marginTop: 16,
        [`@media screen and (min-width: ${React_Brackets_WIDTH_CONDITION}px) 
      and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 850px)`]: {
            height: "100%",
            // height: 'calc(100vh - 150px)',
        },
    },

    arrowStyle: {
        color: appColors.white,
        "&:hover": {
            color: appColors.txt300,
            transition: "all .4s",
        },
        "&.isPreview": {
            [theme.breakpoints.between(
                React_Brackets_WIDTH_CONDITION,
                MAX_CHANGE_SCREEN_WIDTH
            )]: {
                maxWidth: "82px",
                oveflow: "hidden",
                whiteSpace: "nowrap",
                maskImage:
                    "linear-gradient(to right,black 75%,transparent 100%)",
            },
        },
    },

    nonAuthBox: {
        [theme.breakpoints.up(MAX_XL_TABLET)]: {
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            oveflow: "hidden",
        },
    },

    nonAuthBoxText: {
        margin: 0,
        fontSize: "36px",
        fontWeight: 700,
        animationIterationCount: "infinite",
        animationDuration: "4000ms",
        textAlign: "center",
        background: "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        position: "absolute",
        top: "35%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        zIndex: 3,
    },

    nonAuthBoxShine: {
        width: "300px",
        height: "200px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background:
            "radial-gradient(50% 4622.78% at 50% 50%, #F8BF44 0%, rgba(255, 255, 255, 0) 100%)",
        opacity: 0.35,
        filter: "blur(25px)",
        zIndex: 1,
        animationName: "moveGradient",
        animationDelay: "0s",
        animationIterationCount: "infinite",
        animationFillMode: "forwards",
        animationDuration: "6000ms",
    },

    rightDice: {
        width: "300px",
        height: "300px",
        position: "absolute",
        top: "55%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
    },

    chatElementsWrapper: {
        justifyContent: "flex-start",
        alignItems: "center",
        maxHeight: "34px !important",
        minHeight: "34px !important",
        margin: "0px",
        marginTop: 10,
        flexGrow: 1,
        opacity: 1,
        maskImage: "linear-gradient(to right,black 88%,transparent 100%)",
        "&.finalBox": {
            maskImage: "linear-gradient(to left,black 88%,transparent 100%)",
        },
        "&.darkBlue": {
            "& .MuiTabs-flexContainer": {
                justifyContent: "space-evenly",
                alignItems: "center",
            },
            "& .MuiTab-root": {
                fontSize: "14px",
                fontWeight: "700",
                color: appColors.white,
                borderRadius: "8px",
                maxHeight: "34px",
                minHeight: "34px",
                minWidth: "30px",
                opacity: 0.65,
                transform: "scale(0.9)",
            },
            "& .MuiTabs-indicator": {
                background: "none",
            },
            "& .Mui-selected": {
                opacity: 1,
                transform: "scale(1.1)",
                transition: "all ease-in 300ms",
                color: `${appColors.white} !important`,
            },
        },
        "&.darkGreen": {
            "& .MuiTabs-flexContainer": {
                justifyContent: "space-evenly",
                alignItems: "center",
            },
            "& .MuiTab-root": {
                fontSize: "14px",
                fontWeight: "700",
                color: appColors.white,
                borderRadius: "8px",
                maxHeight: "34px",
                minHeight: "34px",
                minWidth: "30px",
                opacity: 0.65,
                transform: "scale(0.9)",
            },
            "& .MuiTabs-indicator": {
                background: "none",
            },
            "& .Mui-selected": {
                opacity: 1,
                transform: "scale(1.1)",
                transition: "all ease-in 300ms",
                color: `${appColors.white} !important`,
            },
        },
        "&.deepBlack": {
            "& .MuiTabs-flexContainer": {
                justifyContent: "space-evenly",
                alignItems: "center",
            },
            "& .MuiTab-root": {
                fontSize: "14px",
                fontWeight: "700",
                color: appColors.white,
                borderRadius: "8px",
                maxHeight: "34px",
                minHeight: "34px",
                minWidth: "30px",
                opacity: 0.65,
                transform: "scale(0.9)",
            },
            "& .MuiTabs-indicator": {
                background: "none",
            },
            "& .Mui-selected": {
                opacity: 1,
                transform: "scale(1.1)",
                transition: "all ease-in 300ms",
                color: `${appColors.white} !important`,
                backgoround: appColors.bg200,
            },
        },
    },
});
