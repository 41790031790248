import {useEffect, useState} from "react";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {Box, Dialog} from "@mui/material";
import {observer} from "mobx-react";
import cn from "classnames";

import {useLocale} from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import paths from "src/consts/paths";
import {usePlayer} from "src/sounds/use-player";
import useChangeTheme from "src/hooks/useChangeTheme";
import {EGameChatTab, EModalsLabels, UserBalanceType} from "src/store/models";
import ModalTitles from "src/components/modals/ModalGameComponents/ModalTitles/ModalTitles";
import ModalImagesBox from "src/components/modals/ModalGameComponents/ModalImagesBox/ModalImagesBox";
import ModalUserRangBox from "src/components/modals/ModalGameComponents/ModalUserRangBox/ModalUserRangBox";
import ModalDivider from "src/components/modals/ModalGameComponents/ModalDivider/ModalTitles";
import ModalButtonsBox from "src/components/modals/ModalGameComponents/ModalButtonsBox/ModalButtonsBox";
import {REJECT_OR_ACCEPT_MODAL_TIMEOUT} from "src/consts/lobby-params";
import {iHaveBeenChallenged, isGameBetweenFriends,} from "src/utils/game-between-friends";
import ChooseTableModal from "src/components/modals/ChooseTableModal";

import TimeAndBetPreview from "../../TimeAndBetPreview";
import ProgressBar from "../../ProgressBar";
import ConfirmGameFromFriend from "../../ConfirmGameFromFriend";

import {useStyles} from "./styles";

const ConfirmGameFormNew = ({ placePosition }: any) => {
    const { lobbyStore, authStore, generalStore, gamesStore } = useStores();
    const inviteToMyGame = lobbyStore.inviteToMyGame();
    const [showChooseTableModal, setShowChooseTableModal] = useState(false);
    const appearanceTheme = useChangeTheme();
    const ratio = window.innerWidth / window.innerHeight;
    const classes = useStyles({ ratio });
    const location = useLocation();
    const history = useHistory();
    const soundPlayer = usePlayer();
    const isGamePage = useRouteMatch("/game/:id");
    const isMultiTablePage = useRouteMatch("/games");
    const {
        lobby: {
            userInteraction: { playGame, cancelGame },
            confirmGameModal: { title, frenemy },
            playerMiniStats: { statsRank, tournamentRp },
        },
    } = useLocale();

    const user = authStore.currentUser;
    const game = lobbyStore.acceptedGame;
    const participatingGames = lobbyStore.getParticipatingGames();
    const [isRejectButtonDisabled, setIsRejectButtonDisabled] = useState(false);


    useEffect(() => {
        if (
            game &&
            user &&
            isGameBetweenFriends(game) &&
            !iHaveBeenChallenged(game, user?._id)
        ) {
            acceptHandler();
        }
    }, [game]);

    useEffect(() => {
        !location.pathname.includes(paths.game.slice(1)) &&
            soundPlayer("joinSoundPlay");
    }, []);

    const acceptHandlerNew = async () => {
        if (inviteToMyGame?._id) {
            await lobbyStore.connectToNormalGame(inviteToMyGame?._id);
            !!lobbyStore.connectionGameId &&
                history.push(`/game/${lobbyStore.connectionGameId}`);
        }
    };

    useEffect(() => {
        if (lobbyStore.connectionGameId) {
        }
    }, [lobbyStore.connectionGameId]);

    const rejectHandlerNew = () => {
        if (isRejectButtonDisabled) return;
        setIsRejectButtonDisabled(true);
        if (!!inviteToMyGame) {
            lobbyStore.declineFreeGame(inviteToMyGame._id);
        }
    };

    if (!!inviteToMyGame) {
        return (
            <Dialog
                open={true}
                className={classes.dialog}
                PaperProps={{
                    className: cn(
                        classes.dialogPaperConfirmNewGame,
                        appearanceTheme
                    ),
                }}
            >
                <ModalTitles
                    title={(!inviteToMyGame.isNormalInvite ? `${frenemy('Frenemy')}, ` : '') + title("Let’s play!")}
                    label={EModalsLabels.lobbyConfirmGame}
                />
                <ModalImagesBox
                    nickname={inviteToMyGame.isNormalInvite ? inviteToMyGame.to.nickname : inviteToMyGame.from.nickname}
                    avatar={inviteToMyGame.isNormalInvite ? inviteToMyGame.to.avatar : inviteToMyGame.from.avatar}
                    isSystemAvatar={inviteToMyGame.isNormalInvite ? inviteToMyGame.to.isSystemAvatar : inviteToMyGame.from.isSystemAvatar}
                    label={EModalsLabels.lobbyConfirmGame}
                />
                {/* <ModalUserRangBox
                    rank={user?.rank?.title || "gm1"}
                    playerRating={+player2.rating?.toFixed(0) || 0}
                    label={EModalsLabels.lobbyConfirmGame}
                    rankCountTitle={tournamentRp("Rank Points")}
                    rankPieceTitle={statsRank("Rank")}
                /> */}
                <ModalDivider label={EModalsLabels.lobbyConfirmGame} />
                { <TimeAndBetPreview
                    timeControl={{
                        time: inviteToMyGame.time,
                        increment: 0,
                    }}
                    bet={inviteToMyGame.details.bank}
                    fenType={inviteToMyGame.details.fenType}
                    rounds={inviteToMyGame.details.type === 2 ? inviteToMyGame.details.gameCount : 0}
                    type={inviteToMyGame.details.type === 4 ? UserBalanceType.free : UserBalanceType.play}
                />}
                <ProgressBar
                    seconds={REJECT_OR_ACCEPT_MODAL_TIMEOUT}
                    pause={false}
                    showTime={true}
                    progressColor="linear-gradient(90deg, #20766C 0%, #5DC5BB 101.95%)"
                    progressShortTimeColor="linear-gradient(90deg, #A60F0F 0%, #FF4236 101.95%)"
                    progressWarningColor="linear-gradient(90deg, #DB640E 0%, #FFD336 101.95%)"
                    onEnd={rejectHandlerNew}
                />
                <ModalButtonsBox
                    acceptText={playGame("Play")}
                    rejectText={cancelGame("Cancel")}
                    acceptActions={acceptHandlerNew}
                    rejectActions={rejectHandlerNew}
                    label={EModalsLabels.lobbyConfirmGame}
                />
            </Dialog>
        );
    }

    if (!game || !user) return null;
    const [player1, player2] = game.players;

    const acceptHandler = () => {
        if (isMultiTablePage && generalStore.isMultiTableAvailable) {
            const isAssigned = gamesStore.createAndAssignToGameTable(game.id);
            if (!isAssigned) {
                setShowChooseTableModal(true);
            }
            return;
        }
        lobbyStore.setAcceptedGame(null);
        lobbyStore.setJoinedGame(null);
        if (
            (isGamePage || participatingGames.length > 0) &&
            generalStore.isMultiTableAvailable
        ) {
            history.push(`${paths.games}?id=${game.id}`);
            return;
        }
        authStore.toggleUserSettingsPage(false);
        history.push(`${paths.game}/${game.id}`);
    };

    const rejectHandler = () => {
        if (isRejectButtonDisabled) return;
        setIsRejectButtonDisabled(true);
        lobbyStore.cancelGame();
    };
    //====

    if (isGameBetweenFriends(game) && iHaveBeenChallenged(game, user?._id)) {
        return (
            <ConfirmGameFromFriend
                joinHandler={() => {
                    lobbyStore.setAcceptedGame(null);
                    lobbyStore.setJoinedGame(game);
                    authStore.toggleUserSettingsPage(false);
                }}
                cancelJoinHandler={() => {
                    if (isRejectButtonDisabled) return;
                    setIsRejectButtonDisabled(true);
                    lobbyStore.setAcceptedGame(null);
                    lobbyStore.setJoinedGame(null);
                    lobbyStore.cancelFriendJoin(game.id);
                }}
                player={player1}
                game={game}
            />
        );
    }

    if (showChooseTableModal) {
        return (
            <ChooseTableModal
                open={showChooseTableModal}
                onClose={() => {
                    setShowChooseTableModal(false);
                }}
                id={game.id}
            />
        );
    }

    if (!player2) return null;

    if (placePosition === EGameChatTab.fastGame) {
        return (
            <Box component="div" className={cn(classes.dialog, placePosition)}>
                <ModalTitles
                    title={title("Let’s play!")}
                    label={EModalsLabels.lobbyConfirmGame}
                    placePosition={placePosition}
                />
                <ModalImagesBox
                    nickname={player2.name}
                    avatar={player2.avatar}
                    isSystemAvatar={player2.isSystemAvatar}
                    status={player2.status}
                    label={EModalsLabels.lobbyConfirmGame}
                    placePosition={placePosition}
                />
                <ModalUserRangBox
                    rank={user?.rank?.title || "gm1"}
                    playerRating={+user?.rating?.elo?.toFixed(0) || 0}
                    label={EModalsLabels.lobbyConfirmGame}
                    placePosition={placePosition}
                    rankCountTitle={tournamentRp("Rank Points")}
                    rankPieceTitle={statsRank("Rank")}
                />
                <TimeAndBetPreview
                    timeControl={{
                        time: game.timeControl.time,
                        increment: game.timeControl.increment,
                    }}
                    bet={game.bet}
                    rounds={game.rounds}
                    type={game.balanceType}
                />
                <ProgressBar
                    seconds={REJECT_OR_ACCEPT_MODAL_TIMEOUT}
                    pause={false}
                    showTime={true}
                    progressColor="linear-gradient(90deg, #20766C 0%, #5DC5BB 101.95%)"
                    progressShortTimeColor="linear-gradient(90deg, #A60F0F 0%, #FF4236 101.95%)"
                    progressWarningColor="linear-gradient(90deg, #DB640E 0%, #FFD336 101.95%)"
                    onEnd={rejectHandler}
                />
                <ModalButtonsBox
                    acceptText={playGame("Play")}
                    rejectText={cancelGame("Cancel")}
                    acceptActions={acceptHandlerNew}
                    rejectActions={rejectHandlerNew}
                    label={EModalsLabels.lobbyConfirmGame}
                />
            </Box>
        );
    }
};

export default observer(ConfirmGameFormNew);
