import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes upRank": {
            "0%": {
                transform: "scale(0.95)",

                opacity: 0.8,
            },
            "45%": {
                transform: "scale(1)",

                opacity: 1,
            },
            "55%": {
                transform: "scale(1)",

                opacity: 1,
            },
            "100%": {
                transform: "scale(0.95)",
                opacity: 0.8,
            },
        },
    },

    wrapperFilterPopup: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridRowGap: "0px",
        position: "absolute",
        background: "#303346",
        border: "1px solid #5C637C",
        filter: "drop-shadow(0px 0px 5px #000000)",
        boxShadow: "rgb(0 0 0 / 20%) 0px 4px 4px inset",
        borderRadius: 8,
        width: "100%",
        zIndex: 200,
        "&.darkBlue": {
            background: "#303346",
        },
        "&.darkGreen": {
            background:
                "linear-gradient(19.92deg, rgba(1, 1, 1, 0.7) 13.3%, rgba(14, 15, 15, 0.7) 86.7%)",
        },
        "&.deepBlack": {
            borderRadius: "4px",
            border: "1px solid rgba(255, 255, 255, 0.3)",
            background: "black",
            padding: "3",
        },
        // '&.isMultitablePage': {
        //     height: '345px',
        // },
        "&.bet-filter-popup": {
            "&.fastGame": {
                height: 250,
            },
        },
    },
    icon: {
        width: 24,
        height: 24,
        objectFit: "contain",
        marginRight: 8,
        opacity: 0.5,
        [theme.breakpoints.down("md")]: {
            width: 20,
            height: 20,
        },
        "&.deepBlack": {
            fontWeight: 600,
            fontFamily: "Montserrat",
            fontSize: "16px",
            lineHeight: "18px",
            color: "rgba(245, 245, 245, 0.9)",
            padding: "0px 5px",
            "&.gameMode": {
                display: "none",
            },
            [theme.breakpoints.down("md")]: {
                width: 20,
                height: 20,
            },
        },
    },
    filterItem: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        // justifyContent: 'flex-start',
        justifyContent: "space-between",
        gridGap: 5,
        width: "100%",
        // height: 40,
        height: 30,
        // padding: '0 25px 0 10px',
        padding: "0 5px 0 10px",
        [theme.breakpoints.down(390)]: {
            padding: "0 0px 0 5px",
        },
        "&.active": {
            background: "rgba(105, 114, 128, 0.3)",
        },
        "&.active $icon": {
            opacity: 1,
        },
        "&.active $text": {
            color: appColors.white,
        },
        "&.time": {
            height: 40,
        },
        [theme.breakpoints.down("sm")]: {
            maskImage: "linear-gradient(to right,black 80%,transparent 100%)",
        },

        "&.fastGame": {
            overflow: "hidden",
            maskImage: "linear-gradient(to right,black 85%,transparent 95%)",
        },
        "&.deepBlack": {
            fontWeight: 600,
            fontFamily: "Montserrat",
            fontSize: "16px",
            lineHeight: "18px",
            color: "rgba(245, 245, 245, 0.9)",
            padding: "0px 5px",
        },
    },

    betSpan: {
        display: "flex",
        justifyContent: "flex-start",
    },

    rankSpan: {
        display: "flex",
        justifyContent: "flex-start",
        gridGap: 3,
        alignItems: "center",
        width: "fit-content",
        overflow: "hidden",
        maskImage: "linear-gradient(to right,black 98%,transparent 100%)",
        animationName: "upRank",
        animationIterationCount: "infinite",
        animationFillMode: "forwards",
        animationDuration: "2000ms",
        // borderRadius: "50%",
        [theme.breakpoints.down("sm")]: {
            width: 66,
            maskImage: "linear-gradient(to right,black 98%,transparent 100%)",
        },
        "& > svg": {
            marginTop: 3,
            transform: "rotateX(180deg)",
            alignItems: "center",
            animationName: "upRank",
            animationIterationCount: "infinite",
            animationFillMode: "forwards",
            animationDuration: "2000ms",
            borderRadius: "50%",
        },
    },

    text: {
        color: "rgba(255, 255, 255, 0.4)",
        fontSize: 16,
        fontWeight: 600,
        whiteSpace: "nowrap",
        "&.isRank": {
            width: 12,
            color: "gold",
        },
        [theme.breakpoints.down(390)]: {
            fontSize: 15,
        },
        [theme.breakpoints.down(340)]: {
            fontSize: 14,
        },
        "&.deepBlack": {
            fontWeight: 500,
            fontFamily: "Montserrat",
            fontSize: "16px",
            whiteSpace: "nowrap",
            color: "rgba(245, 245, 245, 0.9)",
            [theme.breakpoints.down(390)]: {
                fontSize: 15,
            },
            [theme.breakpoints.down(340)]: {
                fontSize: 14,
            },
        },
    },
});
