import { EColor } from "src/components/types";

export const getButtonColor = (appearanceTheme, buttonType) => {
    if (appearanceTheme === EColor.darkBlue) return buttonType.primary;
    if (appearanceTheme === EColor.darkGreen) return buttonType.purple;
    return buttonType.primary;
};


export const replaceDomain = (list: string[]) => {
    return list.map(str => str.replace(/beturanga\.com/g, document.location.host))
}