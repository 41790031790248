import React, { ReactNode } from 'react';

import { useStyles } from './styles';

export interface IListItemProps {
    children: ReactNode;
    listStyleType?: string;
}

export const ListItem = ({ listStyleType, children }: IListItemProps) => {
    const styles = useStyles();
    return (
        <span className={styles.listItem}>
            {listStyleType && (
                <span className={styles.listItemDot}>{listStyleType}</span>
            )}
            {children}
        </span>
    );
};
