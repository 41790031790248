export const roundIndex = [
    'Intro',
    'Final',
    'Semi-Final',
    '1/4',
    '1/8',
    '1/16',
    '1/32',
    '1/64',
    '1/128',
];
