import { makeStyles } from "@mui/styles";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new/constants";
import { appColors, backgroundColors } from "src/consts/app-config";
import { DARKGREEN_ALERTS } from "src/pages/game-new/constants";
import theme from "src/theme";

const useStyles = makeStyles({
    finishedGameDialogContainer: {
        background: backgroundColors.darkBlue,
        width: "100%",
        // padding: "15px 25px 31px 25px",
        position: "relative",
        borderRadius: 8,
        maxWidth: 390,
        // margin: "auto",
        padding: "15px",
        margin: 0,
        filter: "drop-shadow(0px 9.65126px 9.65126px rgba(0, 0, 0, 0.25))",
        "&.darkBlue": {
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                background: backgroundColors.darkBlue,
                border: "1px solid #343A59",
            },
        },
        "&.darkGreen": {
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                background: backgroundColors.darkGreen,
                border: "1px solid #004348",
            },
        },
        "&.deepBlack": {
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                background: backgroundColors.darkBlue,
                border: "1px solid #343A59",
            },
        },

        // "@media screen and (max-width: 1300px)": {
        //     padding: "10px 15px",
        // },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            padding: "10px",
            "&.darkBlue": {
                background: backgroundColors.darkBlue,
                border: "1px solid #343A59",
                "@media (orientation: portrait)": {
                    background: "none",
                    border: "0px solid #343A59",
                },
            },
            "&.darkGreen": {
                background: backgroundColors.darkGreen,
                border: "1px solid #004348",
                "@media (orientation: portrait)": {
                    background: "none",
                    border: "0px solid #004348",
                },
            },
            "&.deepBlack": {
                background: backgroundColors.darkBlue,
                border: "1px solid #343A59",
                "@media (orientation: portrait)": {
                    background: "none",
                    border: "0px solid #343A59",
                },
            },
        },
        [theme.breakpoints.down(MAX_L_TABLET)]: {
            background: "none",
        },
        "@media screen and (max-width: 420px) and (max-height: 720px)": {
            padding: "10px 8px",
        },
        "@media screen and (max-width: 420px) and (min-height: 721px)": {
            padding: "20px 8px",
        },
        "@media screen and (min-width: 1251px)": {
            marginBottom: 5,
            width: "100%",
        },
        [theme.breakpoints.up(1800)]: {
            width: "100%",
            // transform: 'scale(1.2)',
            maring: 0,
            padding: "10px",
            minWidth: "80%",
            // marginBottom: 10,
        },
        [theme.breakpoints.up(2400)]: {
            width: "100%",
            // transform: 'scale(1.25)',
            margin: 0,
            padding: "15px",
            // marginBottom: 40,
        },
        "&.isMultiTable": {
            padding: "5px",
            [theme.breakpoints.up(1800)]: {
                width: "fit-content",
                transform: "scale(1)",
                marginBottom: 0,
                padding: "10px",
                minWidth: "auto",
            },
            "@media screen and (max-height: 850px)": {
                transform: "scale(0.86)",
                margin: 0,
            },
        },
    },

    diamondGold: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: "none",
        [theme.breakpoints.up("lg")]: {
            // Switch off win or lost on modal animation
            // animationName: '$diamondGold',
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDelay: "0.5s",
            animationDuration: "800ms",
        },
    },

    getPot: {
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: -110,
            width: "fit-content",
            height: "fit-content",
            animationName: "$getPot",
            animationDelay: "0.9s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "2000ms",
        },
    },

    getPotText: {
        opacity: 0,
        fontWeight: 700,
        fontSize: 40,
        lineHeight: 0,
        color: appColors.white,
        textShadow: "0px 0px 4px #171A29, 0px 4px 20px #6B666D",
        animationName: "$getPotText",
        animationDelay: "0.9s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "2000ms",
    },

    "@keyframes getPot": {
        "0%": {
            opacity: 0,
        },
        "50%": {
            opacity: 1,
        },
        "100%": {
            opacity: 0,
            display: "none",
        },
    },

    "@keyframes getPotText": {
        "0%": {
            opacity: 0,
        },
        "50%": {
            opacity: 1,
            color: "#F2B040",
            textShadow: "0px 0px 4px #171A29, 0px 4px 20px #6B666D",
        },
        "100%": {
            opacity: 0,
        },
    },

    "@keyframes diamondGold": {
        "0%": {
            opacity: 0.1,
            background:
                "radial-gradient(55% 55% at 50% 2.77%, #FFD06F 0%, #875D1F 59.38%, #242445 100%), #242445",
            backgroundSize: "100% 100%",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
            borderRadius: "16px",
        },

        "100%": {
            opacity: 1,
            background:
                "radial-gradient(65% 65% at 50% 2.77%, #F7D258 0%, #AC702D 100%), #242445",
            backgroundSize: "100% 300%",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
            borderRadius: "16px",
        },
    },

    flexContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: 10,
        width: "100%",
        height: "100%",
        [`@media screen and (max-width: ${MAX_XL_TABLET}px)`]: {
            display: "none",
        },
        // [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
        //     display: 'none',
        // },
    },
    avatarBlockWrapper: {
        marginTop: -45,
        paddingBottom: 5,
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            marginTop: -40,
            paddingBottom: 15,
        },
        "@media screen and (max-width: 420px)": {
            marginTop: -30,
            paddingBottom: 14,
        },
    },
    avatarBlock: {
        width: 120,
        aspectRatio: "1 / 1",
        padding: 5,
        "& .nickname-box": {
            fontSize: 24,
        },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 1100px)`]:
            {
                width: 100,
                height: 100,
            },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            width: 90,
            height: 90,
            padding: 4,
            "& .nickname-box": {
                fontSize: 15,
            },
        },
        "@media screen and (max-width: 420px)": {
            width: 70,
            height: 70,
        },
        "&.isMultiTable": {
            width: 90,
            height: 90,
            [theme.breakpoints.down(1450)]: {
                width: 80,
                height: 80,
            },
        },
    },

    textWinnerWrapper: {
        zIndex: 1,
    },

    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        zIndex: 1,
        "&.isTournament": {
            justifyContent: "center",
            maxWidth: "fit-content",
        },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            justifyContent: "center",
        },
        maxWidth: 300,
        margin: "auto",
        "&.blocked": {
            justifyContent: "center",
        },
    },

    blockedMessage: {
        margin: 0,
        padding: 0,
        color: appColors.txt950,
        textAlign: "center",
        width: "100%",
        transition: "all 300ms ease-out",
        position: "absolute",
        top: "-22px",
        height: "0px",
        overflow: "hidden",
        "&.showBlockedMessage": {
            height: "23px",
            transition: "all 300ms ease-in",
        },
    },

    divider: {
        minWidth: 10,
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-width: 360px)`]:
            {
                minWidth: 20,
            },
        "&.blocked": {
            display: "none",
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 400,
        color: appColors.white,
        margin: 0,
        textAlign: "center",
        [theme.breakpoints.down(550)]: {
            fontSize: 20,
        },
        "&.isMultiTable": {
            fontSize: 20,
        },
    },
    description: {
        color: appColors.white,
        fontSize: 18,
        margin: 0,
        textAlign: "center",
        marginBottom: 5,
        [theme.breakpoints.down(550)]: {
            fontSize: 16,
            marginBottom: 5,
        },
        "&.isMultiTable": {
            fontSize: 16,
            marginBottom: 5,
        },
    },
    message: {
        fontSize: 18,
        fontWeight: 500,
        color: appColors.white,
        margin: 0,
        marginBottom: 10,
        textAlign: "center",
    },
});

export default useStyles;
