import React from "react";

export const SortDown = (props) => {
    const color = props && props.color ? props.color : "#fff";
    return (
        <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="6"
                y="12"
                width="1.6"
                height="8"
                transform="rotate(-135 6 12)"
                fill={color}
            />
            <rect
                x="5.65698"
                y="11.7882"
                width="8"
                height="1.6"
                transform="rotate(-135 5.65698 11.7882)"
                fill={color}
            />
        </svg>
    );
};

export const SortDownNew = (props) => {
    const color = props && props.color ? props.color : "#fff";
    return (
        <svg
            width="14"
            height="8"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.0312 1.53063L9.53122 9.03063C9.46156 9.10036 9.37885 9.15568 9.2878 9.19342C9.19675 9.23116 9.09915 9.25059 9.00059 9.25059C8.90203 9.25059 8.80443 9.23116 8.71339 9.19342C8.62234 9.15568 8.53962 9.10036 8.46997 9.03063L0.969966 1.53063C0.864957 1.42573 0.793432 1.29204 0.764444 1.14648C0.735456 1.00092 0.750309 0.850025 0.807124 0.712907C0.863938 0.57579 0.960159 0.458613 1.0836 0.37621C1.20705 0.293807 1.35217 0.249883 1.50059 0.25H16.5006C16.649 0.249883 16.7941 0.293807 16.9176 0.37621C17.041 0.458613 17.1372 0.57579 17.1941 0.712907C17.2509 0.850025 17.2657 1.00092 17.2367 1.14648C17.2078 1.29204 17.1362 1.42573 17.0312 1.53063Z"
                fill={color}
                fill-opacity="0.9"
            />
        </svg>
    );
};
