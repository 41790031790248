import { useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import cn from 'classnames';

import LazyImages from 'src/components/lazyImages';
import endpoints from 'src/core/endpoints';
import AvatarBorder from 'src/assets/images/avatar-border.svg';
import avatarPlaceholder from 'src/assets/images/defaultPlaceholder.png';
import { IPlayer } from 'src/store/models';

import { useStyles } from './styles';

interface Props {
    player: IPlayer;
    rolled: boolean;
    elemHeight?: number | undefined;
    isMultitable?: boolean;
}

function LazyImage({ player, rolled, elemHeight, isMultitable }: Props) {
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;

    const styles = useStyles();
    const [isLoaded, setLoaded] = useState<boolean>(false);

    return (
        <>
            <Box
                className={cn(styles.playerImgWrapper, {
                    rolled: rolled,
                    isMultitable: isMultitable
                })}
            >
                <LazyImages
                    className={cn(styles.avatarImage, {
                        isLoaded: !isLoaded,
                    })}
                    wrapperClassName={styles.lazyImageWrapper}
                    placeholderImg={
                        player?.pathCompressed
                            ? `${endpoints.avatarPath}/${player.pathCompressed}`
                            : avatarPlaceholder
                    }
                    src={`${endpoints.avatarPath}/${player.avatar}`}
                    errorImg={avatarPlaceholder}
                    setLoaded={(status: boolean) => setLoaded(status)}
                />
                {!player.isSystemAvatar && (
                    <img
                        src={AvatarBorder}
                        className={cn(
                            styles.avatarBorderImage,
                            'avatar-border'
                        )}
                        alt='Avatar Border'
                    />
                )}
            </Box>
        </>
    );
}

export default observer(LazyImage);
