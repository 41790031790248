import React from "react";

export const EyeHidden = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 2C3 2 0 7 0 7C0 7 0.8 8.3 2.2 9.6L9.6 2.2C9.1 2 8.6 2 8 2Z"
            fill="#A8B1CE"
        />
        <path
            d="M13.7999 4.3999L6.3999 11.7999C6.8999 11.8999 7.4999 11.9999 7.9999 11.9999C12.9999 11.9999 15.9999 6.9999 15.9999 6.9999C15.9999 6.9999 15.1999 5.6999 13.7999 4.3999Z"
            fill="#A8B1CE"
        />
        <path
            d="M13.0464 -5.78329e-05L1.09473 11.9482L2.86223 13.7162L14.8139 1.76794L13.0464 -5.78329e-05Z"
            fill="#A8B1CE"
        />
    </svg>
);
