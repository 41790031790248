import { makeStyles } from "@mui/styles";
import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";
import { DARKGREEN_ALERTS } from "src/pages/game-new/constants";

const ALERT_BORDER_COLOR = "#DCE5F4";
const ALERT_COLOR = "#252B4F";

export const useStyles = makeStyles({
    container: {
        padding: "5px 20px",
        display: "grid",
        placeItems: "center",
        // position: 'absolute',
        // left: '50%',
        // top: 0,
        background: ALERT_COLOR,
        border: `1px solid ${ALERT_BORDER_COLOR}`,
        borderRadius: 10,
        // zIndex: 3,
        textAlign: "center",
        color: appColors.white,
        fontWeight: 500,
        maxWidth: "80%",
        minWidth: "40%",
        margin: "0 auto",
        // marginBottom: 15,
        marginBottom: 0,
        [theme.breakpoints.between("xs", 369)]: {
            // top: '8%',
            padding: "2px 10px",
            fontSize: "22px",
            minWidth: "60%",
        },
        [theme.breakpoints.between(370, "sm")]: {
            // top: '11%',
            padding: "2px 10px",
            fontSize: "24px",
        },
        [theme.breakpoints.between("sm", "md")]: {
            // top: '15px',
            padding: "10px 10px",
            fontSize: "24px",
        },
        "@media screen and (max-width: 360px)": {
            padding: "2px 12px",
        },
        "@media screen and (max-width: 450px) and (max-height: 680px)": {
            fontSize: "17px",
            padding: "2px 12px",
        },
        "@media screen and (max-width: 450px) and (max-height: 730px)": {
            fontSize: "16px",
            padding: "2px 10px",
        },
        "@media screen and (max-width: 450px) and (max-height: 760px)": {
            fontSize: "16px",
            padding: "2px 12px",
        },
        "&.darkBlue": {
            background: ALERT_COLOR,
            border: `1px solid ${ALERT_BORDER_COLOR}`,
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            // background: "rgba(245, 245, 245, 0.1)",
            // border: `1px solid ${ALERT_BORDER_COLOR}`,
            border: "1px solid transparent",
            background:
                "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 35%) padding-box, linear-gradient(180.21deg, rgb(76, 55, 76) 25%, rgba(145, 116, 157, 0.85) 98%) border-box",
            // background:
            //     "linear-gradient(rgb(48, 33, 33), rgba(58, 44, 65, 0.8) 35%) padding-box, linear-gradient(180.21deg, rgb(76, 55, 76) 25%, rgba(145, 116, 157, 0.85) 98%) border-box",
        },
    },
    arrowHolder: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        display: "none",
    },
    arrow: {
        background: ALERT_COLOR,
        transform: "rotate(45deg) translate(-50%, -30%)",
        width: 12,
        height: 12,
        borderLeft: `1px solid ${ALERT_BORDER_COLOR}`,
        borderTop: `1px solid ${ALERT_BORDER_COLOR}`,
        margin: "auto",
        "&.darkBlue": {
            background: ALERT_COLOR,
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            border: "1.5px solid transparent",
            background:
                "linear-gradient(rgba(41, 33, 48, 0.92), rgba(22, 13, 27, 0.72) 100%) padding-box, linear-gradient(180.21deg, rgb(76, 55, 76) 25%, rgba(189, 173, 195, 0.85) 50%) border-box",
        },
    },

    titleAlertMobile: {
        color: "rgba(0, 186, 108, 1)",
        font: "Montserrat",
        fontWeight: 600,
    },
});
