import { IGame } from 'src/store/models';

export const isGameBetweenFriends = (game: IGame) => {
    return !!game.challenged;
};

export const getFriendNicknameFromGame = (game: IGame) => {
    const friendData = game.challenged;

    return friendData?.nickname;
};

export const iHaveBeenChallenged = (game: IGame, myId: string) => {
    return game.challenged?.id === myId;
};
