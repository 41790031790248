import { ChessMap } from '../chess/chess.map';
import { Figure } from '../chess/figure';
import { Movement } from '../chess/movement';
import { Rook } from './rook';

export class King extends Figure {
  moves = 0;
  moveVector = 0;

  constructor(
    public isBlack: boolean,
    public chessMapRef: ChessMap | null = null,
  ) {
    super(isBlack, 'k', chessMapRef);
  }

  getMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    const movements: Movement[] = [];

    for (let dtX = -1; dtX <= 1; dtX++) {
      for (let dtY = -1; dtY <= 1; dtY++) {
        if (!dtX && !dtY) continue;

        const toX = fromX + dtX;
        const toY = fromY + dtY;

        if (this.canMove(map, toX, toY, this.isBlack)) {
          movements.push(
            new Movement({
              rowFrom: fromX,
              columnFrom: fromY,
              rowTo: toX,
              columnTo: toY,
              castling: this.isBlack ? 'canRightBlack' : 'canRightWhite',
              castling2: this.isBlack ? 'canLeftBlack' : 'canLeftWhite',
            }),
          );
        }
      }
    }

    if (!this.moves) {
      // right

      const hasRookMove = !!this.chessMapRef?.moves.find(
        (figure) => figure.type.toLowerCase() === 'r',
      );

      const rightKey = this.isBlack ? 'canRightBlack' : 'canRightWhite';

      let canRight =
        map.cells[fromX][8].figure &&
        map.cells[fromX][8].figure?.type === 'r' &&
        (map.cells[fromX][8].figure as Rook).moves === 0 &&
        !this.chessMapRef?.is960 &&
        hasRookMove &&
        map[rightKey];

      if (canRight) {
        for (let column = fromY + 1; column < 8; column++) {
          if (map.cells[fromX][column].figure) {
            canRight = false;
          }
        }
      }

      if (canRight) {
        movements.push(
          new Movement({
            rowFrom: fromX,
            columnFrom: fromY,
            rowTo: fromX,
            columnTo: fromY + 2,
            row2From: fromX,
            column2From: 8,
            row2To: fromX,
            column2To: fromY + 1,
            castling: this.isBlack ? 'canRightBlack' : 'canRightWhite',
            castling2: this.isBlack ? 'canLeftBlack' : 'canLeftWhite',
          }),
        );
      }

      // left

      const leftKey = this.isBlack ? 'canLeftBlack' : 'canLeftWhite';

      let canLeft =
        map.cells[fromX][1].figure &&
        map.cells[fromX][1].figure?.type === 'r' &&
        (map.cells[fromX][1].figure as Rook).moves === 0 &&
        !this.chessMapRef?.is960 &&
        hasRookMove &&
        map[leftKey];

      if (canLeft) {
        for (let column = 2; column < fromY; column++) {
          if (map.cells[fromX][column].figure) {
            canLeft = false;
          }
        }
      }

      if (canLeft) {
        movements.push(
          new Movement({
            rowFrom: fromX,
            columnFrom: fromY,
            rowTo: fromX,
            columnTo: fromY - 2,
            row2From: fromX,
            column2From: 1,
            row2To: fromX,
            column2To: fromY - 1,
            castling: this.isBlack ? 'canRightBlack' : 'canRightWhite',
            castling2: this.isBlack ? 'canLeftBlack' : 'canLeftWhite',
          }),
        );
      }
    }

    return movements;
  }

  getSafeMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    const movements: Movement[] = [];

    for (let dtX = -1; dtX <= 1; dtX++) {
      for (let dtY = -1; dtY <= 1; dtY++) {
        if (!dtX && !dtY) continue;

        const toX = fromX + dtX;
        const toY = fromY + dtY;

        if (this.canMove(map, toX, toY, !this.isBlack)) {
          if (
            map.cells[toX][toY].figure &&
            map.cells[toX][toY].figure?.isBlack === this.isBlack
          ) {
            movements.push(
              new Movement({
                rowFrom: fromX,
                columnFrom: fromY,
                rowTo: toX,
                columnTo: toY,
              }),
            );
          }
        }
      }
    }

    return movements;
  }

  getAttackMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    // const movements: Movement[] = [];
    //
    //
    //
    // for (let dtX = -1; dtX <= 1; dtX++) {
    //     for (let dtY = -1; dtY <= 1; dtY++) {
    //
    //         if (!dtX && !dtY) continue;
    //
    //         const toX = fromX + dtX;
    //         const toY = fromY + dtY;
    //
    //         if (this.canMove(map, toX, toY, this.isBlack)) {
    //             if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack !== this.isBlack) {
    //                 movements.push(new Movement({
    //                     rowFrom: fromX,
    //                     columnFrom: fromY,
    //                     rowTo: toX,
    //                     columnTo: toY,
    //                 }));
    //             }
    //         }
    //     }
    // }
    //
    //
    // return movements;

    return [];
  }

  onMove() {
    this.moves++;
  }
  onUnMove() {
    this.moves--;
  }
}
