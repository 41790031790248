import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { EGameRematchStatus } from "src/store/models";

import { usePlayer } from "../../../../sounds/use-player";
import { RematchStage } from "../../../../store/gameStore/dialogs";
import { useChessBoardProps } from "../../hooks/useChessBoardProps";

import WaitForOpponentDialog from "./components/waitForOpponentToDecide";
import OpponentRejectedRematchAlert from "./components/OpponentRejectedRematch";
import AcceptOrRejectDialog from "./components/acceptOrReject";

interface IRematchAlert {
    isTabletMobile?: boolean | undefined;
}

const RematchAlerts = ({ isTabletMobile }: IRematchAlert) => {
    const { isMultiTable, onLobbyClose } = useChessBoardProps();
    const { authStore, gameStore } = useStores();
    const me = authStore?.currentUser;
    const { isOpen, stage } = gameStore.dialogs.rematchDialog;
    const rematchInfo = gameStore.dialogs.rematchData;
    const iAmInitiator = me?._id === rematchInfo.rematchStartedBy;

    const viewMode = useMemo(() => {
        return gameStore.viewGameMode;
    }, [gameStore.viewGameMode]);

    // if (viewMode) {
    //     return (
    //         <GameFinishedDialog
    //             cause
    //             ratio={designRatio}
    //             onCancel={() => {
    //                 history.push("/lobby");
    //             }}
    //         />
    //     );
    // }

    // if (!isOpen) return null;

    const getRematchAlertVariant = useMemo(() => {
        if (rematchInfo.rematchStatus === EGameRematchStatus.NOT_INIT)
            return null;
        if (
            rematchInfo.rematchStatus === EGameRematchStatus.WAITING_FOR_ANSWER
        ) {
            if (iAmInitiator) {
                return (
                    <WaitForOpponentDialog
                        isXlTablet={isTabletMobile}
                        isMultiTable={isMultiTable}
                    />
                );
            }
            return (
                <AcceptOrRejectDialog
                    isXlTablet={isTabletMobile}
                    isMultiTable={isMultiTable}
                />
            );
        } else if (
            rematchInfo.rematchStatus === EGameRematchStatus.DECLINED &&
            !iAmInitiator
        ) {
            return (
                <OpponentRejectedRematchAlert
                    isXlTablet={isTabletMobile}
                    isMultiTable={isMultiTable}
                    onLobbyClose={onLobbyClose}
                />
            );
        }
    }, [rematchInfo.rematchStatus, iAmInitiator]);

    return <>{getRematchAlertVariant}</>;
};

export default observer(RematchAlerts);
