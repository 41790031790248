import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    container: () => ({
        //background:
        //    'radial-gradient(42.17% 5.88% at 50% 5.88%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(239.36deg, #6D7491 32.89%, #2D3142 64.62%, #1B2030 93.81%)',
        //width: 55,
        //height: 55,
        //display: 'flex',
        //justifyContent: 'center',
        //alignItems: 'center',
        ////borderRadius: props.type === 'square' ? 8 : 55,
        //padding: 2,
    }),
    innerContainer: (props) => ({
        //display: 'flex',
        //justifyContent: 'center',
        //alignItems: 'center',
        ////borderRadius: props.type === 'square' ? 8 : 55,
        //height: '100%',
        //width: '100%',
    }),
});
