import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    wrapperBurger: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 22,
        width: 28,
        cursor: 'pointer',
    },
    burgerLine: {
        display: 'block',
        borderRadius: '10px',
        width: '100%',
        height: 3,
        background: '#A8B1CE',
        '&.line1': {
            transformOrigin: '0% 0%',
            transition: 'transform 0.4s ease-in-out',
            '&.line1Open': {
                transform: 'rotate(45deg)',
            },
        },
        '&.line2': {
            transition: 'transform 0.2s ease-in-out',
            '&.line2Open': {
                transform: 'scaleY(0)',
            },
        },
        '&.line3': {
            transformOrigin: '0% 100%',
            transition: 'transform 0.4s ease-in-out',
            '&.line3Open': {
                transform: 'rotate(-45deg)',
            },
        },
    },
});
