import { appColors, backgroundColors } from "src/consts/app-config";
import { makeStyles } from "@mui/styles";

import { DARKGREEN_ALERTS } from "src/pages/game-new/constants";
import theme from "src/theme";

interface Props {
    titleColor: string;
    titleFontSize: number;
    amountColor: string;
}

export const useStyles = makeStyles({
    root: () => ({
        height: 145,
        background: backgroundColors.darkBlue,
        boxShadow: "0px 2px 6px rgba(21, 26, 39, 0.4)",
        borderRadius: 16,
        border: "2px solid rgba(255, 255, 255, 0.03)",
        paddingTop: 15,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        "&.darkBlue": {
            [theme.breakpoints.up(1250)]: {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
            },
        },
        "&.darkGreen": {
            [theme.breakpoints.up(1250)]: {
                background: backgroundColors.darkGreen,
                border: "1px solid #004348",
            },
        },
        "&.deepBlack": {
            [theme.breakpoints.up(1250)]: {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
            },
        },
    }),
    icon: () => ({
        width: 75,
        height: 75,
        justifyContent: "center",
        transform: `scale(1)`,
        alignItems: "center",
        display: "flex",
        position: "absolute",
        top: -35,
        filter: "drop-shadow(0px 12px 11px rgba(0, 0, 0, 0.25))",
    }),
    title: (props: Props) => ({
        fontSize: props.titleFontSize,
        fontWeight: 900,
        color: "white",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        background: props.titleColor,
        textShadow: "0px 5px 4px rgba(0, 0, 0, 0.3)",
        marginBottom: 7,
        textAlign: "center",
    }),
    message: (props) => ({
        fontSize: 14,
        color: "rgba(237, 244, 255, 0.65)",
        marginBottom: 7,
    }),
    amount: (props) => ({
        fontSize: 32,
        fontWeight: 900,
        letterSpacing: "0.03em",
        color: "white",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        background: props.amountColor,
        textShadow: "0px 5px 4px rgba(0, 0, 0, 0.25)",
    }),
    messageBlock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "0px dashed orange",
        "&.deepBlack": {
          
        }
    },
});
