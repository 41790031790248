import React, { useEffect, useState } from 'react'; 
import { Box } from '@mui/material';  
import cn from "classnames";  

import useStores from 'src/hooks/useStores';  
import useChangeTheme from 'src/hooks/useChangeTheme';

import coin from '../../../../../assets/icons/coin.svg';  
import rakePlus from 'src/assets/icons/rakePlus.svg';  
import rakeMinus from 'src/assets/icons/rakeMinus.svg'; 
import { UserBalanceType } from '../../../../../store/models';  

import { useStyles } from './styles'; 

const RakeBackModal = () => { 
    const styles = useStyles(); 
    const appearanceTheme = useChangeTheme();
    const { 
        gameStore,  
        authStore, 
    } = useStores();  
    const increasedRakeBack = authStore?.increasedRakeBack
    const increasedRP = authStore.increasedRP;

    if (gameStore?.currentGame?.balanceType !== UserBalanceType.play) return null;

    return (  
          <Box className={cn(styles.rakeBackModalWrapper, appearanceTheme)}>
              <div className={cn(styles.rakeBackFlexContainer, appearanceTheme)}>
                  <div className={cn(styles.rakeBackModal, appearanceTheme)}>
                      <div className={cn(styles.rakeBackTextWrapper, appearanceTheme)}>
                          {increasedRP >= 0 && 
                          <>
                            <p className={cn(styles.rakeBackText, appearanceTheme)}>Rakeback</p>
                            <img
                              src={rakePlus}
                              alt='increase value'
                              style={{ height: 17 }}
                              />
                            <p className={styles.rakeBackValue}>
                                {increasedRakeBack}
                            </p>
                            <img src={coin} alt='rakeback value' />
                          </>
                          }
                          <>
                            <img
                              src={increasedRP >= 0 ? rakePlus : rakeMinus}
                              alt='plusminus'
                              style={{ height: 17 }}
                              />
                            <p className={cn(styles.rakeBackValue, appearanceTheme, {
                              up: increasedRP >=0,
                              down: increasedRP < 0
                            })}>{Math.abs(increasedRP)} RP</p>
                          </> 
                      </div>
                  </div>
              </div>
          </Box>
    );
};

export default RakeBackModal;
