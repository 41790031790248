import React from "react";
import cx from "classnames";

import DiceIconRedesign from "src/assets/icons/DiceDesktopRedesign.svg";
import DicesIconForGreen from "src/assets/icons/rollDicesButtonForGreen.svg";
import DiceIconMobile from "src/assets/icons/rollDicesButton2.png";
import { EColor } from "src/components/types";
import theme from "src/theme";
import useChangeTheme from "src/hooks/useChangeTheme";

import DicesIcon from "./images/dice-roll.png";
import "./style.css";
import { useStyles } from "./styles";

interface Props {
    onClick: () => void;
    enabled: boolean;
    isMultiTable?: boolean;
    togglerState?: boolean;
}
const DiceButton = ({
    enabled,
    onClick,
    isMultiTable,
    togglerState,
}: Props) => {
    const appearanceTheme = useChangeTheme();
    const classes = useStyles();
    const currentWidth = window.innerWidth;

    const getDice = () => {
        if (appearanceTheme === EColor.darkGreen) return DicesIconForGreen;
        return currentWidth < theme.breakpoints.values.md
            ? DiceIconRedesign
            : DiceIconRedesign;
    };

    return (
        <div
            className={cx(
                {
                    [classes.flash]: enabled,
                    enabled: !togglerState,
                    isMultiTable,
                },
                appearanceTheme,
                classes.diceCircle
            )}
            onClick={() => {
                !togglerState && onClick();
            }}
        >
            <img src={getDice()} alt="rollDice" className={classes.image} />
        </div>
    );
};

export default DiceButton;
