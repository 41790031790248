import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import cn from "classnames";
import { useState } from "react";
import { observer } from "mobx-react";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import useStores from "src/hooks/useStores";
import { getButtonColor } from "src/helpers";
import { ButtonType, GreenButton } from "src/components/buttons/greenButton";
import { GameType } from "src/store/models";
import { Nullable } from "src/shared/types";

import { Fade } from "../Fade";

import { useStyles } from "./styles";

type ConfirmModalProps = {
    open: boolean;
    onClose: () => void;
    id: Nullable<string>;
};

const ChooseTableModal = ({ open, onClose, id }: ConfirmModalProps) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const { gamesStore } = useStores();
    const {
        game: {
            multiTable: { chooseGameTable, chooseButton },
        },
    } = useLocale();

    const [activeTableIndex, setActiveTableIndex] = useState(0);

    const onChooseTable = () => {
        gamesStore.updateGameTableByIndex(activeTableIndex, {
            id,
            type: GameType.dicechess,
            state: "game",
            isMenu: false,
        });
        setActiveTableIndex(0);
        // Do not use unnecessary methods, 'closeAfterChoose' is equivalent to 'onClose'
        // closeAfterChoose && closeAfterChoose();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            className={styles.modalWrapper}
        >
            <Fade in={open} className={cn(styles.modal, appearanceTheme)}>
                <div className={styles.modalContainer}>
                    <Box className={cn(styles.crossWrapper)}>
                        <div
                            onClick={() => onClose()}
                            className={cn(styles.cross)}
                        />
                    </Box>
                    <h1 className={styles.title}>
                        {chooseGameTable("Choose Table")}
                    </h1>
                    <div className={styles.tablesContainer}>
                        {Array(4)
                            .fill(0)
                            .map((_, i) => (
                                <div
                                    key={i}
                                    className={cn(
                                        styles.tableItem,
                                        appearanceTheme,
                                        {
                                            active: i === activeTableIndex,
                                        }
                                    )}
                                    onClick={() => setActiveTableIndex(i)}
                                >
                                    <span>{i + 1}</span>
                                </div>
                            ))}
                    </div>
                    <div className={styles.buttonContainer}>
                        <GreenButton
                            text={chooseButton("Choose")}
                            type={getButtonColor(appearanceTheme, ButtonType)}
                            fontSize={20}
                            isWidthFixed
                            onClick={onChooseTable}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default observer(ChooseTableModal);
