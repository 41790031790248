import { makeStyles } from '@mui/styles';

import theme from 'src/theme';
import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';
import { appColors, backgroundColors } from 'src/consts/app-config';

import { useBlockCommonStyles } from './../../../styles';

export const useTimeConfigStyles = makeStyles({
    wrapperTimeConfig: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            '@media (orientation: portrait)': {
                height: 42,
            },
        },
        [theme.breakpoints.down('md')]: {
            height: 42,
        },
    },
    divContainer: {
        height: '100%',
    },
    container: {
        ...useBlockCommonStyles.timeConfigContainer,
    },
    leftSideBox: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            '@media (orientation: portrait)': {
                maskImage: 'none',
            },
        },
        [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
                maxWidth: 'calc(100% - 17px)',
                width: 'calc(100% - 17px)',
            },
        },
        [theme.breakpoints.down(600)]: {
            '@media (orientation: portrait)': {
                maxWidth: 'calc(100% - 17px)',
                width: 'calc(100% - 17px)',
                maskImage:
                    'linear-gradient(to right,black 80%,transparent 100%)',
            },
        },
    },
    icon: {
        ...useBlockCommonStyles.icon,
    },
    text: {
        color: appColors.white,
        fontWeight: 700,
        fontSize: 16,
        marginLeft: 8,
        overflow: 'hidden',
        lineHeight: '16px',
        maxHeight: 16,
        width: 'calc(100% - 25px)',
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: '14px',
            marginLeft: 2,
        },
        "&.fastGame": {
          [theme.breakpoints.down(1150)]: {
            fontSize: '14px',
            marginLeft: 0,
          },
        },
    },
    sortDown: {
        marginLeft: 9,
        transform: 'scale(1.3)',
        transition: 'all 0.2s ease-in-out',
    },
    sortDownOpen: {
        marginLeft: 9,
        transition: 'all 0.2s ease-in-out',
        transform: 'rotateX(180deg) scale(1.3) ',
    },
});
