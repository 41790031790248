const descRandomMessageGenerator = (pathname?: number | undefined) => {
    if (!pathname && pathname !== 0) {
        return false;
    }
    if (+pathname > 6) {
        return 1;
    }
    if (+pathname > 3) {
        return 2;
    }
    if (+pathname >= 0) {
        return 3;
    }
};
export default descRandomMessageGenerator;
