import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import { appColors, backgroundColors } from 'src/consts/app-config';
import paths from 'src/consts/paths';

import { useStyles } from '../styles';

interface IGamePreview {
    title: () => string;
    isPreviewGame?: boolean | undefined;
    isFriends?: boolean | undefined;
    showFriends?: (status: boolean) => void;
    isPreview?: boolean | string | undefined;
    onClick: () => void;
}

const arrowStyle = {
    color: appColors.white,
    '&:hover': {
        color: appColors.txt300,
        transition: 'all .4s',
    },
};

const ReturnToPrevious = ({ onClick, title, isPreview }: IGamePreview) => {
    const classes = useStyles();
    return (
        <div
            className={cn(classes.returnToPreviousWrapper, isPreview)}
            onClick={onClick}
        >
            <ArrowBackIosNewOutlinedIcon sx={arrowStyle} />
            <p className={cn(classes.arrowStyle, isPreview)}>{title()}</p>
        </div>
    );
};

export default observer(ReturnToPrevious);
