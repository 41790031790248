export const updateSearchParams = (params: Record<string, unknown>) => {
    const searchParams = new URLSearchParams(window.location.search);

    for (const [key, value] of Object.entries(params)) {
        if (value !== null && value !== undefined) {
            // @ts-ignore
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }
    }
    const strSearchParams = searchParams.toString().trim()
        ? `?${searchParams.toString()}`
        : '';
    const newUrl = window.location.pathname + strSearchParams;
    window.history.replaceState({}, '', newUrl);
};
