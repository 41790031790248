import lastMoveIsUnAvailable from '../../utils/lastMoveIsUnAvailable';

import { IClockPerPlayer } from './types';

import GameStore from './index';

export const extractFen = (dicechessFen: string) => {
    return dicechessFen.split(' ')[0];
};

const SQUARES = {
    a8: 0,
    b8: 1,
    c8: 2,
    d8: 3,
    e8: 4,
    f8: 5,
    g8: 6,
    h8: 7,
    a7: 16,
    b7: 17,
    c7: 18,
    d7: 19,
    e7: 20,
    f7: 21,
    g7: 22,
    h7: 23,
    a6: 32,
    b6: 33,
    c6: 34,
    d6: 35,
    e6: 36,
    f6: 37,
    g6: 38,
    h6: 39,
    a5: 48,
    b5: 49,
    c5: 50,
    d5: 51,
    e5: 52,
    f5: 53,
    g5: 54,
    h5: 55,
    a4: 64,
    b4: 65,
    c4: 66,
    d4: 67,
    e4: 68,
    f4: 69,
    g4: 70,
    h4: 71,
    a3: 80,
    b3: 81,
    c3: 82,
    d3: 83,
    e3: 84,
    f3: 85,
    g3: 86,
    h3: 87,
    a2: 96,
    b2: 97,
    c2: 98,
    d2: 99,
    e2: 100,
    f2: 101,
    g2: 102,
    h2: 103,
    a1: 112,
    b1: 113,
    c1: 114,
    d1: 115,
    e1: 116,
    f1: 117,
    g1: 118,
    h1: 119,
};
let board: (string | null)[] = [];

const isDigit = (c: string) => {
    return '0123456789'.indexOf(c) !== -1;
};

const load = (position: string) => {
    let square = 0;

    for (let i = 0; i < position.length; i++) {
        const piece = position.charAt(i);

        if (piece === '/') {
            square += 8;
        } else if (isDigit(piece)) {
            square += parseInt(piece, 10);
        } else {
            put(piece, algebraic(square));
            square++;
        }
    }
};

const put = (piece: string, square: string) => {
    const sq: number = SQUARES[square];
    board[sq] = piece;
};

function rank(i: number) {
    return i >> 4;
}

function file(i: number) {
    return i & 15;
}

function algebraic(i: number) {
    let f = file(i),
        r = rank(i);
    return 'abcdefgh'.substring(f, f + 1) + '87654321'.substring(r, r + 1);
}

const generateFen = () => {
    let empty = 0;
    let fen = '';

    for (let i = SQUARES.a8; i <= SQUARES.h1; i++) {
        if (board[i] == null) {
            empty++;
        } else {
            if (empty > 0) {
                fen += empty;
                empty = 0;
            }

            var piece = board[i];

            fen += piece;
        }

        if ((i + 1) & 0x88) {
            if (empty > 0) {
                fen += empty;
            }

            if (i !== SQUARES.h1) {
                fen += '/';
            }

            empty = 0;
            i += 8;
        }
    }

    return fen;
};

export const generateMove = (fen: string, from: string, to: string) => {
    board = new Array(128);
    const parts = fen.split(' ');

    load(parts[0]);

    board[SQUARES[to]] = board[SQUARES[from]];

    board[SQUARES[from]] = null;

    return `${generateFen()} ${parts[1]} ${parts[2]} ${parts[3]} ${parts[4]}`;
};

export const updateClocks = (
    gameStore: GameStore,
    data: [IClockPerPlayer, IClockPerPlayer]
) => {
    gameStore.gameState.rolled = false;
    if (!gameStore.gameState) return;
    const TIMEOUT = 2000;

    const replaceOneClock = () => {
        return [
            ...gameStore.clock.filter((el) => el.color !== data[0].color),
            ...data,
        ] as [IClockPerPlayer, IClockPerPlayer];
    };

    const replaceAndChangeTime = () => {
        const activeClock = data.find(
            (el) => el.color === gameStore.gameState.activeClock.color
        ) as IClockPerPlayer;
        if (data.length === 2) {
            const nonActiveClock = data.find(
                (el) => el.color !== activeClock?.color
            ) as IClockPerPlayer;
            return [
                { ...activeClock },
                {
                    ...nonActiveClock,
                    timeleft: nonActiveClock.timeleft - TIMEOUT,
                },
            ] as [IClockPerPlayer, IClockPerPlayer];
        }
        const missedClock = gameStore.clock.find(
            (el) => el.color !== data[0].color
        );
        return [missedClock, { ...data[0], timeleft: data[0].timeleft }] as [
            IClockPerPlayer,
            IClockPerPlayer
        ];
    };

    const isLastMoveIsUnAvailable =
        gameStore.isReady &&
        lastMoveIsUnAvailable(
            !gameStore.gameState.legalPieces.length,
            gameStore.gameState.history,
            gameStore.gameState.getMyColor()
        );

    if (isLastMoveIsUnAvailable) {
        setTimeout(() => {
            gameStore.setClock(replaceAndChangeTime());
        }, 2000);
        return;
    }
    gameStore.setClock(data.length === 2 ? data : replaceOneClock());
    // gameStore.gameState.rolled = false;
};
