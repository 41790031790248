import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    languagePicker: {
        position: 'relative',
        cursor: 'pointer',
        fontFamily: 'Montserrat',
    },
    selectedLanguage: {
        display: 'flex',
        alignItems: 'center',
        height: 32,
        padding: '22px 15px',
        backgroundColor: 'rgba(245, 245, 245, 0.05)',
        borderRadius: 4,
        [`@media (min-width: 2500px)`]: {
            height: 70,
            padding: 30,
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            height: 34,
            padding: 15,

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            height: 32,
            padding: 6,
        },
    },
    menuItemSelected: {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
    languageFlag: {
        width: 20,
        height: 16,
        marginRight: 5,
        objectFit: 'cover',
    },
    languageCode: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    dropdownMenu: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: 'rgba(245, 245, 245, 0.05)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        //borderBottomLeftRadius: 4,
        //borderBottomRightRadius: 4,
        borderRadius: 4,
        marginTop: '0.2vw',
        padding: '0.3vw 0',
        [`@media (min-width: 2500px)`]: {
            marginTop: '0.1vw',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            marginTop: '0.2vw',
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            marginTop: '0.5vw',
        },
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        margin: '0.3vw 0',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        [`@media (min-width: 2500px)`]: {
            padding: '10px 15px',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            padding: '10px 15px',
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            padding: '10px 15px',
        },
    },
    dropdownIcon: {
        width: 13,
        height: 19,
        marginLeft: '8px',
        transition: 'transform 0.3s ease',
    },
    dropdownIconOpen: {
        transform: 'rotate(180deg)',
    },
    // Стили для isHalyk
    halykLanguagePicker: {
        position: 'relative',
        cursor: 'pointer',
        fontFamily: 'Montserrat',
    },
    halykSelectedLanguage: {
        display: 'flex',
        alignItems: 'center',
        height: 32,
        padding: '22px 15px',
        backgroundColor: 'rgba(245, 245, 245, 0.05)',
        borderRadius: 4,
        [`@media (min-width: 2500px)`]: {
            height: 70,
            padding: 30,
        },
        [`@media (min-width: 760px) and (max-width: 1022px)`]: {
            height: 34,
            padding: 15,

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            height: 32,
            padding: 6,
        },
    },
    halykDropdownMenu: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: 'rgba(245, 245, 245, 0.05)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        borderRadius: 4,
        marginTop: '0.2vw',
        padding: '0.3vw 0',
        [`@media (min-width: 2500px)`]: {
            marginTop: '0.1vw',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            marginTop: '0.2vw',
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            marginTop: '0.5vw',
        },
    },
    halykMenuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        margin: '0.3vw 0',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        [`@media (min-width: 2500px)`]: {
            padding: '15px 40px',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            padding: '10px 15px',
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            padding: '8px 7px',
        },
    },
    halykLanguageIcon: {
        width: 24,
        height: 16,
        marginRight: 5,
        objectFit: 'cover',
    },
    halykLanguageLabel: {
        fontSize: '14px',
        color: '#fff',
        fontFamily: 'Montserrat',
    },
});