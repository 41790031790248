import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

export const useStyles = makeStyles({
    rankBoxWrapper: {
        width: '100%',
        height: '100%',
        maxWidth: 90,
        maxHeight: 90,
        borderRadius: 16,
        padding: 2,
        justifySelf: 'end',
        '@media screen and (max-width: 1100px)': {
            maxWidth: 70,
            maxHeight: 70,
        },
        '@media screen and (max-height: 720px) and (min-width: 1251px)': {
            maxWidth: 60,
            maxHeight: 60,
        },
        '@media screen and (max-width: 1366px) and (min-width: 1024px)': {
            borderRadius: 8,
        },
        '&.showBg': {
            background:
                'radial-gradient(42.17% 5.88% at 50% 5.88%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(239.36deg, #6D7491 32.89%, #2D3142 64.62%, #1B2030 93.81%)',
        },
        '&.friendFightReceive': {
            justifySelf: 'center',
        },
        '&.lobbyConfirmGame': {
            justifySelf: 'center',
        },
    },

    rankIcon: {
      width: 48,
      height: 48,
      '& path': {
          fill: '#fff',
      },
      '@media screen and (max-width: 1366px)': {
          width: 40,
          height: 40,
      },
      '@media screen and (max-width: 1250px)': {
          width: 40,
          height: 40,
      },
      '@media screen and (max-width: 768px)': {
          width: 24,
          height: 24,
      },
      '&.nonProfile': {
        maxWidth: 32,
        maxHeight: 32,
      },
  },


    rankBox: {
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'center',
        padding: '8px 0px',
        borderRadius: 16,
        background:
            'radial-gradient(47.56% 8.33% at 50% 8.33%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(56.22% 7.5% at 50% 92.5%, #FFFFFF 0%, rgba(255, 255, 255, 0) 69.87%), linear-gradient(0deg, #3CC6CB, #3CC6CB), linear-gradient(19.92deg, #0D5C52 13.3%, #50BAAE 86.7%), linear-gradient(19.92deg, #C21C1C 13.3%, #ED6666 86.7%)',
        backgroundBlendMode: 'overlay, overlay, normal, normal, normal',
        '@media screen and (max-width: 1366px)': {
            width: '100%',
            padding: 14,
            borderRadius: 8,
        },
        '@media screen and (max-width: 1024px)': {
            padding: 16,
            borderRadius: 16,
        },
        '@media screen and (max-width: 768px)': {
            padding: 12,
            borderRadius: 12,
        },

    },

    rankTitle: {
        color: appColors.white,
        fontSize: 14,
        fontWeight: 700,
        textTransform: 'uppercase',
        '@media screen and (max-width: 1366px)': {
            display: 'none',
        },
    },
    nonChessmenFigureIcon: {
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 65,
        },
        [theme.breakpoints.down(345)]: {
            maxWidth: 55,
            '&.friendFightReceive': {
                maxWidth: '100%',
            },
            '&.lobbyConfirmGame': {
                maxWidth: '100%',
            },
        },
        '&.nonProfile': {
          maxWidth: 32,
          maxHeight: 32,
        },
    },
});
