import React, { memo, useState, useLayoutEffect, useEffect, useCallback } from "react";
import cn from "classnames";
import PulseLoader from "react-spinners/PulseLoader";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import { appColors, backgroundColors } from "src/consts/app-config";
import { IStickerPackList } from "src/store/models";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import { fileHelper } from "src/shared/helpers/FileHelper";

import { useStyles } from "./styles";

import stickerChtr from 'src/assets/icons/stickers/cheat.gif';
import stickerCry from 'src/assets/icons/stickers/cry.gif';
import stickerGG from 'src/assets/icons/stickers/GG_02.gif';
import stickerGL from 'src/assets/icons/stickers/Luck.gif';
import stickerHeart from 'src/assets/icons/stickers/love.gif';
import stickerRainbow from 'src/assets/icons/stickers/lgbt_03.gif';
import stickerWtf from 'src/assets/icons/stickers/wtf.gif';
import stickerDance from 'src/assets/icons/stickers/dance.gif';
import stickerThin from 'src/assets/icons/stickers/thin2.gif';
import stickerZZZ from 'src/assets/icons/stickers/zzz.gif';
import stickerLoL from 'src/assets/icons/stickers/lol.gif';

import stickerChtrSvg from 'src/assets/icons/stickers/cheater.svg';
import stickerCrySvg from 'src/assets/icons/stickers/cry.svg';
import stickerGGSvg from 'src/assets/icons/stickers/gg.svg';
import stickerGLSvg from 'src/assets/icons/stickers/gl.svg';
import stickerHeartSvg from 'src/assets/icons/stickers/heart.svg';
import stickerRainbowSvg from 'src/assets/icons/stickers/rainbow.svg';
import stickerWtfSvg from 'src/assets/icons/stickers/wtf.svg';
import stickerDanceSvg from 'src/assets/icons/stickers/dance.png';
import stickerThinSvg from 'src/assets/icons/stickers/thin.png';
import stickerZZZSvg from 'src/assets/icons/stickers/zzz.png';
import stickerLoLSvg from 'src/assets/icons/stickers/lol.png';

export interface IStickerContainer {
    onSendSticker?: (gifUrl: string, svgUrl: string) => void;
}

const stickers = [
    { id: 'sticker1', gif: stickerChtr, svg: stickerChtrSvg },
    { id: 'sticker2', gif: stickerCry, svg: stickerCrySvg },
    { id: 'sticker3', gif: stickerGG, svg: stickerGGSvg },
    { id: 'sticker4', gif: stickerGL, svg: stickerGLSvg },
    { id: 'sticker5', gif: stickerHeart, svg: stickerHeartSvg },
    { id: 'sticker6', gif: stickerRainbow, svg: stickerRainbowSvg },
    { id: 'sticker7', gif: stickerWtf, svg: stickerWtfSvg },
    { id: 'sticker8', gif: stickerDance, svg: stickerDanceSvg },
    { id: 'sticker9', gif: stickerThin, svg: stickerThinSvg },
    { id: 'sticker10', gif: stickerZZZ, svg: stickerZZZSvg },
    { id: 'sticker11', gif: stickerLoL, svg: stickerLoLSvg },
];

const StickerContainer = memo(({
    onSendSticker,
}: IStickerContainer) => {
    const classes = useStyles();
    const { chatMobileType } = useLeftLayoutContext();
    const [isLoaded, setLoaded] = useState<boolean>(false);

    // Мемоизируем обработчик клика
    const handleStickerClick = useCallback((sticker) => {
        if (onSendSticker) {
            onSendSticker(sticker.gif, sticker.id);
        } else {
            console.error('onSendSticker is not defined');
        }
    }, [onSendSticker]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    // Мемоизируем рендер стикера
    const renderSticker = useCallback((sticker) => (
        <img
            key={sticker.id}
            className={cn(classes.stickersImg, {
                isLoaded: isLoaded,
            })}
            src={sticker.svg}
            alt={sticker.id}
            onClick={() => handleStickerClick(sticker)}
        />
    ), [classes, isLoaded, handleStickerClick]);

    return (
        <React.Fragment>
            {isLoaded && (
                <div
                    className={cn(classes.stickersListBox, chatMobileType)}
                >
                    <div className={cn(classes.stickersList)}>
                        {stickers.map(renderSticker)}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
});

// Добавляем проверку пропсов для мемоизации
export default memo(StickerContainer, (prevProps, nextProps) => {
    return prevProps.onSendSticker === nextProps.onSendSticker;
});