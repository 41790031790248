import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useWindowSize from "src/hooks/useWindowSize";
import { ColorVariant, EGameRematchStatus } from "src/store/models";
import useStores from "src/hooks/useStores";
import UserAvatar from "src/components/UserAvatar";
import useInitRematchTimer from "src/hooks/useInitRematchTimer";
import useChangeTheme from "src/hooks/useChangeTheme";
import FadeInOutVertical from "src/components/Animated";
import { CountdownCircular } from "src/components/countDownCircular";
import paths from "src/consts/paths";
import { updateSearchParams } from "src/utils/update-search-params";
import { MAX_XL_TABLET } from "src/pages/game-new2/constants";

import { endpointsRoot } from "../../../../../../core/endpoints";
import useStyles from "../../../common/rematchGeneralDialog/style";

const WaitForOpponentToDecideAlert = ({ isXlTablet, isMultiTable = false }) => {
    const history = useHistory();
    const { gameStore, gamesStore } = useStores();
    const appearanceTheme = useChangeTheme();
    const { width, height } = useWindowSize();
    const isMobileSize = width < MAX_XL_TABLET;
    const styles = useStyles();
    const {
        game: {
            dialogs: {
                rematch: {
                    waitForOpponent: { title },
                },
            },
        },
    } = useLocale();
    useEffect(() => {
        const isRematchAccepted = gameStore.gameState.isRematchAccepted();
        if (isRematchAccepted && !isMultiTable) {
            history.push(`${paths.game}/${gameStore.rematch.gameId}`);
        } else if (isRematchAccepted && isMultiTable) {
            updateSearchParams({ id: undefined });

            const newGameTables = gamesStore.gameTables.map((table) => {
                if (table && table?.id === gameStore.currentGame?.id) {
                    return { ...table, id: gameStore.rematch.gameId as string };
                }
                return table;
            });
            gamesStore.setGameTables(newGameTables);
        }
    }, [
        gameStore.rematch.gameId,
        gameStore.rematch[ColorVariant.white],
        gameStore.rematch[ColorVariant.black],
    ]);

    const opponentColor = gameStore.gameState.getOpponentColor();

    const actualTimer = useInitRematchTimer(
        gameStore.dialogs.rematchData.rematchEnd,
        15000
    );

    // if (!opponentColor) return null;
    const player = gameStore.gameState.getPlayerByColor(opponentColor);
    // if (!player) return null;

    if (isMobileSize) {
        return (
            <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
                <div
                    className={cn(
                        styles.rematchAlertContainer,
                        "waitForOpponentToDecideAlert"
                    )}
                >
                    <p className={cn(styles.waitForOpponentTitle)}>
                        {title("Waiting for Opponents Answer")}
                    </p>
                </div>
            </FadeInOutVertical>
        );
    }

    return (
        <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
            <>
                <div
                    className={cn(
                        styles.rematchAlertContainer,
                        "waitForOpponentToDecideAlert"
                    )}
                >
                    {actualTimer && (
                        <CountdownCircular
                            seconds={actualTimer}
                            size={50}
                            reset={0}
                            pause={false}
                            onEnd={() => {
                                gameStore.rematchDecline(
                                    gameStore?.currentGameNew?._id
                                );
                                history.push(paths.diceChessLobby);
                            }}
                            modalType={EGameRematchStatus.WAITING_FOR_ANSWER}
                            className={cn(
                                styles.circularRequest,
                                "waitForOpponentToDecideAlert"
                            )}
                        />
                    )}

                    <p className={cn(styles.waitForOpponentTitle)}>
                        {title("Waiting for Opponents Answer")}
                    </p>
                </div>
            </>
        </FadeInOutVertical>
    );

    // return (
    //     <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
    //         <div
    //             className={cn(
    //                 styles.rematchActionContainer,
    //                 appearanceTheme,
    //                 "waitForOpponentToDecideAlert",
    //                 {
    //                     isMultiTable,
    //                 }
    //             )}
    //         >
    //             <div
    //                 className={cn(styles.flexContainer, {
    //                     isXlTablet: isXlTablet,
    //                     isMultiTable,
    //                 })}
    //             >
    //                 <div className={styles.avatarBlockWrapper}>
    //                     <UserAvatar
    //                         avatarUrl={
    //                             (player?.avatar &&
    //                                 `${endpointsRoot}/avatars/${player.avatar}`) ||
    //                             ""
    //                         }
    //                         nickname={""}
    //                         className={cn(styles.avatarBlock, { isMultiTable })}
    //                         isSystemAvatar={player?.isSystemAvatar ?? false}
    //                     />
    //                 </div>
    //                 <div>
    //                     <p className={cn(styles.title, { isMultiTable })}>
    //                         <span className={styles.playerName}>
    //                             {player?.name}
    //                         </span>
    //                         <span>{title("Is thinking")}</span>
    //                     </p>
    //                 </div>
    //             </div>
    //             <div className={styles.buttonContainer}>
    //                 <CountdownCircular
    //                     seconds={10}
    //                     size={50}
    //                     reset={0}
    //                     pause={false}
    //                     onEnd={() => {
    //                         gameStore.dialogs.openRematchDialogRejected();
    //                     }}
    //                 />
    //             </div>
    //         </div>
    //     </FadeInOutVertical>
    // );
};

export default observer(WaitForOpponentToDecideAlert);
