import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export const HideEye = () => {
    return (
        <SVGUniqueID>
            <svg
                width='20'
                height='20'
                viewBox='0 0 21 21'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M2.625 2.625L18.375 18.375'
                    stroke='#5C637C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M9.1875 9.3418C8.9152 9.65023 8.75 10.0555 8.75 10.4993C8.75 11.4658 9.53348 12.2493 10.5 12.2493C10.9438 12.2493 11.349 12.0841 11.6575 11.8118'
                    stroke='#5C637C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M6.44162 6.61523C4.97002 7.64649 3.74407 9.11572 2.625 10.4993C4.27749 13.1164 7.24649 15.7493 10.5 15.7493C11.8562 15.7493 13.163 15.2918 14.3455 14.5687'
                    stroke='#5C637C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M10.5 5.25C14.0073 5.25 16.3638 8.01343 18.375 10.5C18.0963 10.9413 17.7803 11.3831 17.4318 11.8125'
                    stroke='#5C637C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </svg>
        </SVGUniqueID>
    );
};
