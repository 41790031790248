import React, { useRef, useEffect, useState, useMemo } from "react";

import LocaleText from "src/components/locale-text/locale.text";
import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { FadeInOutVertical } from "src/components/Animated";
import useWindowSize from "src/hooks/useWindowSize";

import { ReactComponent as WRIcon } from "../pieces/white-02.svg";
import { ReactComponent as WNIcon } from "../pieces/white-03.svg";
import { ReactComponent as WBIcon } from "../pieces/white-04.svg";
import { ReactComponent as WQIcon } from "../pieces/white-05.svg";
import { ReactComponent as BRIcon } from "../pieces/black-02.svg";
import { ReactComponent as BNIcon } from "../pieces/black-03.svg";
import { ReactComponent as BBIcon } from "../pieces/black-04.svg";
import { ReactComponent as BQIcon } from "../pieces/black-05.svg";

import useStyles from "./styles";

const LOOK_UP_TABLE = {
    WR: <WRIcon />,
    WN: <WNIcon />,
    WB: <WBIcon />,
    WQ: <WQIcon />,

    BR: <BRIcon />,
    BN: <BNIcon />,
    BB: <BBIcon />,
    BQ: <BQIcon />,
};

const PROMOTION_WEIGHTS = {
    N: 1,
    B: 2,
    R: 3,
    Q: 4,
};

export default function SelectFiguresAlert({ setFigure, isTabletMobile }) {
    const classes = useStyles();
    const {
        alerts: {
            selectFigures: { title, message },
        },
    } = useLocale();
    const { gameStore } = useStores();
    const { width, height } = useWindowSize();
    const piecesDesktopSize = gameStore.piecesSize;

    const color = gameStore.getMyColorNew().toUpperCase();
    const figures = gameStore.dialogs.selectFiguresDialog.figures;

    //  for test instead figures
    // ['Q', 'R', 'B', 'N'] for test instead figures
    const sortedFigures = [...figures].sort((a, b) => {
        return PROMOTION_WEIGHTS[b] - PROMOTION_WEIGHTS[a];
    });

    return (
        <>
            <LocaleText
                defaultValue="Choose figure"
                label="game.dialogs.alertToChooseFigure"
                className={classes.chooseFigureAlert}
            />
            {/* <PromotionDialog/> */}
            <div className={classes.buttonsCenter}>
                {sortedFigures.map((figure, i) => (
                    <div
                        style={{
                            maxHeight:
                                isTabletMobile && width ? width / 6 : undefined,
                            height: isTabletMobile
                                ? piecesDesktopSize
                                : piecesDesktopSize * 0.8,
                            minHeight: isTabletMobile ? 45 : undefined,
                            width: !isTabletMobile
                                ? piecesDesktopSize * 0.8
                                : undefined,
                        }}
                        className={classes.wrapperRect}
                        key={i}
                    >
                        <div
                            className={classes.rect}
                            id={figure}
                            onClick={() => {
                                setFigure(figure);
                            }}
                        >
                            {LOOK_UP_TABLE[color + figure.toUpperCase()]}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
