import React, { ReactNode } from "react";
import FlagIcon from "@mui/icons-material/Flag";

import { useStyles } from "./styles";

export interface IRoundsPreviewProps {
    value?: ReactNode;
}

export const RoundsPreview = ({ value }: IRoundsPreviewProps) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <FlagIcon className={classes.icon} />
            {value && <span className={classes.text}>{value}</span>}
        </div>
    );
};
