import React from "react";
import { useLocale } from "src/providers/LocaleProvider";
import { observer } from "mobx-react";
import BaseAlert from "../BaseAlert";
import { IPlayer } from "../../../../../store/models";
import { useStyles } from "./styles";
import useStores from "src/hooks/useStores";
import descRandomMessageGenerator from "src/utils/descRandomMessageGenerator";

interface PlayerThinkingProps {
    player: IPlayer;
    randomValue: number;
}

export const MobilePlayerThinking = ({
    player,
    randomValue,
}: PlayerThinkingProps) => {
    const {
        game: {
            notifications: { playerIsThinking },
            dialogs: {
                x2: { randommessages },
            },
        },
    } = useLocale();

    const generatedTitle = descRandomMessageGenerator(randomValue);

    return (
        <BaseAlert
            title={
                generatedTitle
                    ? randommessages[generatedTitle].compile(
                          { playerNick: player.name },
                          "{{playerNick}}"
                      )
                    : playerIsThinking.compile(
                          { playerNick: player.name },
                          "{{playerNick}} is thinking"
                      )
            }
            isOffering={true}
        />
    );
};

export default observer(MobilePlayerThinking);
