import { PlayerInfoExistPosition, IGame, ITournaments, IViewers } from "src/store/models";

export type WatchingType = {
    _id: string;
    agreement: boolean;
    blockReason: null;
    email: string;
    isBot: boolean;
    isSupport: boolean;
    isSystemAvatar: boolean;
    nickname: string;
    online: boolean;
    referralProgramId: null;
    type: string;
    avatar: string;
    rank: number;
    rankFull?: {
        end?: number;
        name?: string;
        rakeBack?: number;
        rank?: number;
        start?: number;
    };
    rating?: number;
};

export enum WatchersStatusType {
    show = "show",
    hide = "hide",
}

export interface IWatchingList {
    setOpen: (properties: boolean | WatchersStatusType) => void;
    isOpen: boolean | string;
    watchingArray: IViewers[];
    openStatus: boolean | WatchersStatusType;
    elementPlace?: keyof typeof PlayerInfoExistPosition;
    dryStyle?: boolean;
    height?: number;
    hideToggler?: boolean;
    isMobileGamePage?: boolean;
    togglerClassName?: string;
    isPreview?: boolean | undefined;
    solidMultiGames?: IGame[] | any;
    isMobileView?: boolean | undefined;
}
