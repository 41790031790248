import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import Badge from "@mui/material/Badge";
import TextsmsIcon from "@mui/icons-material/Textsms";
import CloseIcon from "@mui/icons-material/Close";

import { appColors, backgroundColors } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import {
    EGameChatTab,
    GameChatToggleStatus,
    GameModeType,
} from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";

import { useStyles } from "../styles";
import { IBadgeDeskMobile } from "../types/IChat";

const BadgeMobile = observer(
    ({ currentTab, showHideOpponent, isChatBlocked }: IBadgeDeskMobile) => {
        const appearanceTheme = useChangeTheme();
        const {
            gameStore,
            authStore,
            achievementsStatStore,
            tournamentsStore,
        } = useStores();
        const textsmsStyle = {
            color: appColors.white,
            transform: "rotateY(-180deg)",
        };

        const {
            onChatToggler,
            chatMobileType,
            chatDesktopType,
            chatTogglerState,
        } = useLeftLayoutContext();

        const me = authStore.currentUser?._id;

        const shouldHide = tournamentsStore.isHideChat;
        const chessboardSize = gameStore?.chessboardSize;
        const chatMessagesCount = gameStore?.newChatMessage?.length;
        const stickers = authStore.stickers;

        const classes = useStyles({
            shouldHide,
            showHideOpponent,
            appearanceTheme,
            chessboardSize,
            stickers,
        });

        return (
            <div
                className={cn(
                    classes.hideChatButton,
                    chatMobileType || chatDesktopType,
                    chatTogglerState,
                    appearanceTheme
                )}
                onClick={() => {
                    onChatToggler && onChatToggler();
                }}
            >
                <Badge
                    className={cn(classes.badgeNotifications, "mobileToggler", {
                        chatTogglerState,
                        emptyNote:
                            chatMessagesCount === 0 ||
                            isChatBlocked ||
                            (chatDesktopType &&
                                currentTab === EGameChatTab.chat) ||
                            (chatMobileType &&
                                chatTogglerState === GameChatToggleStatus.open),
                        moreThan99: chatMessagesCount > 99,
                    })}
                    badgeContent={chatMessagesCount > 99 ? "99+" : chatMessagesCount}
                    color="primary"
                >
                    {chatTogglerState !== GameChatToggleStatus.open ? (
                        <TextsmsIcon sx={textsmsStyle} />
                    ) : (
                        <CloseIcon sx={textsmsStyle} />
                    )}
                </Badge>
            </div>
        );
    }
);

export default memo(BadgeMobile);
