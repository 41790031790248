export enum EFilter {
    all = "all",
    popular = "popular",
    new = "new",
    favorites = "favorites",
    providers = "providers",
}

export enum EExternalProviderType {
    algNet = "algNet",
    evenBet = "evenBet",
}

export interface ISlotGame {
    id: string;
    name: string;
    imageUrl: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface ISlotsResponse {
    id: string;
    name: string;
    image: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface IExternalGameResponse {
    id: string;
    name: string;
    image: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
    generalMin: number;
    generalMax: number;
}

export interface ILiveDealerGame {
    id: string;
    name: string;
    generalMin: number;
    generalMax: number;
    lastWins: number[];
    imageUrl: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface ILiveDealerGameResponse {
    id: string;
    name: string;
    isOpened: boolean;
    general_min: number;
    general_max: number;
    lastWins: number[];
    image: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface IVirtualSport {
    id: string;
    name: string;
    imageUrl: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface IGetVirtualSportGameResponse {
    id: string;
    name: string;
    image: string;
    providerId: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    onlyHorizontal: boolean;
}

export interface IGameProvider {
    id: string;
    name: string;
}

export interface IGameProviderResponse {
    _id: string;
    name: string;
}

export interface IGetGameProviderResponse {
    providers: IGameProviderResponse[];
}

export enum ExternalGameType {
    SLOTS = 1,
    LIVE_DEALER = 2,
    SPORTS_BETTING = 3,
    VIRTUAL_SPORT = 4,
}
