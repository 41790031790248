import React from 'react';

import { useLocale } from 'src/providers/LocaleProvider';

import BaseAlert from '../BaseAlert';

export const MobileGiveUpAlert = () => {
    const {
        game: {
            dialogs: {
                giveUp: { title },
            },
        },
    } = useLocale();
    return <BaseAlert title={title('You are about to resign')} />;
};
