import React, { ReactNode } from 'react';

import { useStyles } from './styles';

export interface IContentProps {
    children: ReactNode;
}

export const Content = ({ children }: IContentProps) => {
    const styles = useStyles();
    return <div className={styles.content}>{children}</div>;
};
