import cn from "classnames";
import React, { useCallback, memo, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import moment from "moment";

import { useChessBoardProps } from "src/pages/game-new/hooks/useChessBoardProps";
import useCalcTableLabel from "src/hooks/useCalcTableLabel";
import { useWinOrLostStatus } from "src/pages/game-new/hooks/useWinOrLostStatus";
import useStores from "src/hooks/useStores";
import { GameMode, GameModeType } from "src/store/models";
import UserAvatar from "src/components/UserAvatar";
import paths from "src/consts/paths";
import { CountdownCircular } from "src/components/countDownCircular";
import { useLocale } from "src/providers/LocaleProvider";
import { REDIRECT_TOURNAMENT_GAME } from "src/consts/lobby-params";
import FadeInOutVertical from "src/components/Animated";
import useChangeTheme from "src/hooks/useChangeTheme";
import { emitter } from "src/shared/emitter";

import {
    ButtonType,
    GreenButton,
} from "../../../../../components/buttons/greenButton";
import { endpointsRoot } from "../../../../../core/endpoints";

import useStyles from "./style";

interface Props {
    avatar: string | undefined;
    amount?: string;
    amountColor?: string;
    titleColor?: string;
    titleFontSize?: number;
    message?: string | JSX.Element;
    title: string | JSX.Element;
    loadingMessage?: boolean;
    approveText: string;
    cancelText: string;
    onApprove: () => any;
    onCancel: () => void;
    approveButtonType?: ButtonType;
    rejectButtonType?: ButtonType;
    isSystemAvatar?: boolean;
    isXlTablet?: boolean | undefined;
    isMultiTable?: boolean;
}

interface IMatchMessage {
    gameStageProp: number;
    winBox?: boolean | undefined;
    isXlTablet?: boolean | undefined;
}

const GeneralGameDialogNew = observer((props: Props) => {
    const styles = useStyles();
    const {
        gameStore,
        gamesStore,
        tournamentsStore,
        achievementsStatStore,
        authStore,
    } = useStores();
    const { isWon } = useWinOrLostStatus();
    const { tableIndex } = useChessBoardProps();
    const isMultiTablePage = useRouteMatch("/games");
    const {
        tournaments: {
            infos: {
                gameResult: { nextRound, stayThisRound },
            },
        },
        game: {
            chat: { blockedGames },
        },
    } = useLocale();
    const bank = gameStore.gameState.bank;
    const me = authStore.currentUser;
    const gameType = gameStore.currentGame?.type;
    const history = useHistory();
    const appearanceTheme = useChangeTheme();
    const rounds =
        gameStore?.currentGame?.tournament?.gameSeries ??
        (gameStore.currentGame?.settings.rounds || 0);
    const [firstPlayerScore, secondPlayerScore] = gameStore.getScores();
    const NEXT_GAME_IN_MATCH_TIME = +Date.parse(gameStore?.nextRound?.startAt!);
    const nextRoundGameId = gameStore?.nextRound?.gameId!;
    const gameStage = gameStore?.currentGame?.tournament?.stage;
    const winner = gameStore?.gameState?.gameResultData?.winner;
    const myColor = gameStore.gameState.getMyColor();
    const meOrWhitePlayer = gameStore.gameState.getPlayerByColor(myColor);
    const { onLobbyClose } = useChessBoardProps();
    const isLastRound =
        rounds - firstPlayerScore! === 0 || rounds - secondPlayerScore! === 0;
    const isMultiRoundGame = gameType === GameModeType.TOURNAMENT || rounds > 1;

    const opponent = gameStore?.currentGame?.players.find(
        (player) => player.id !== me?._id
    );
    const tournamentID = gameStore?.currentGame?.tournament?.id;

    const meFriendBlockStatus = achievementsStatStore.getMeSettingsCollections(
        opponent?.id
    ).isGamePlayBlocked;

    useEffect(() => {
        if (nextRoundGameId) {
            emitter.emit("tournament:notification:close", nextRoundGameId);
        }
    }, [nextRoundGameId]);

    const appTransmitter = useCallback(() => {
        if (gameType === GameModeType.TOURNAMENT) {
            return history.push(paths.tournaments);
        }
        if (gameType === GameModeType.REGULAR) {
            props.onCancel();
        }
    }, [gameType]);

    const calcFinalMatch = (resetFuntion?: () => void) => {
        if (rounds) {
            if (isLastRound) {
                resetFuntion && resetFuntion();
                return true;
            }
            return false;
        }
        return false;
    };

    const generateNextMatchTimer = (delayTime: number) => {
        const date = Date.parse(new Date().toUTCString());

        if (!isMultiTablePage) {
            if (gameType === GameModeType.TOURNAMENT) {
                return REDIRECT_TOURNAMENT_GAME;
            }
            const nowDate = moment(new Date());
            const nextGameStartDate = moment(gameStore?.nextRound?.startAt);
            const differenceInSeconds = nextGameStartDate.diff(
                nowDate,
                "seconds"
            );
            return Math.max(differenceInSeconds, 0);
        }
        if (isLastRound) return REDIRECT_TOURNAMENT_GAME;
        const timeDelay =
            NEXT_GAME_IN_MATCH_TIME && +NEXT_GAME_IN_MATCH_TIME > +date
                ? (
                      (+NEXT_GAME_IN_MATCH_TIME - +date - delayTime) /
                      1000
                  ).toFixed(2)
                : 0;
        return +timeDelay;
    };

    const endRoundProvider = (resetFuntion?: () => void) => {
        if (isMultiTablePage) {
            if (nextRoundGameId) {
                gamesStore.updateGameTableByIndex(tableIndex!, {
                    id: nextRoundGameId,
                });
                return;
            }
            if (isLastRound) {
                onLobbyClose?.();
            }
            return;
        }
        if (!rounds) return;
        if (gameType === GameModeType.REGULAR && !isLastRound) {
            history.push(`/game/${nextRoundGameId}`);
            return;
        }
        if (isLastRound) {
            isWon
                ? history.push(`${paths.tournaments}/${tournamentID}`)
                : history.push(`${paths.tournaments}`);
            resetFuntion?.();
        }
        if (
            rounds - firstPlayerScore! !== 0 &&
            rounds - secondPlayerScore! !== 0
        ) {
            history.push(paths.tournaments);
            resetFuntion?.();
        }
    };

    const MatchResultMessages = ({
        gameStageProp,
        winBox,
    }: IMatchMessage): JSX.Element | null => {
        const getRoundWinner = useCalcTableLabel(+gameStageProp / 2);
        const getRoundLoser = useCalcTableLabel(+gameStageProp);

        if (!gameStageProp) return null;

        return (
            <>
                {winBox ? (
                    <p className={styles.description}>
                        {nextRound.compile(
                            {
                                stage: getRoundWinner,
                            },
                            // `Поздравляем, Ты проходишь в {{stage}} раунд!`
                            `Congratulations, You goes to the {{stage}} round!`
                        )}
                    </p>
                ) : (
                    <p className={styles.description}>
                        {stayThisRound.compile(
                            {
                                stage: getRoundLoser,
                            },
                            // `Ты проиграл, и заканчиваешь в {{stage}}.( Попробуй еще раз`
                            `You lost and finished in the {{stage}} round. Try roll it again`
                        )}
                    </p>
                )}
            </>
        );
    };

    return (
        <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
            <div
                className={cn(
                    styles.finishedGameDialogContainer,
                    appearanceTheme,
                    {
                        isMultiTable: props.isMultiTable,
                    }
                )}
            >
                <div className={cn(styles.flexContainer, {})}>
                    {isWon && <div className={styles.diamondGold} />}
                    {isWon && gameType === GameModeType.REGULAR && (
                        <Box className={styles.getPot}>
                            <p className={styles.getPotText}>+{bank}</p>
                        </Box>
                    )}
                    <div className={styles.avatarBlockWrapper}>
                        <UserAvatar
                            isWon={isWon}
                            avatarUrl={
                                (props.avatar &&
                                    `${endpointsRoot}/avatars/${props.avatar}`) ||
                                ""
                            }
                            nickname={""}
                            className={cn(styles.avatarBlock, {
                                isMultiTable: props.isMultiTable,
                            })}
                            isSystemAvatar={props.isSystemAvatar}
                        />
                    </div>
                    {gameType === GameModeType.REGULAR && (
                        <div className={styles.textWinnerWrapper}>
                            <p
                                className={cn(styles.title, {
                                    isMultiTable: props.isMultiTable,
                                })}
                            >
                                {props.title}
                            </p>
                            <p
                                className={cn(styles.description, {
                                    isMultiTable: props.isMultiTable,
                                })}
                            >
                                {props.message}
                            </p>
                        </div>
                    )}
                    {/* title=
                    {title.compile(
                        { nickname: rejectedPlayer?.name },
                        `The {{nickname}} is rejected a draw`
                    )} */}
                    {gameType === GameModeType.TOURNAMENT && (
                        <div className={styles.textWinnerWrapper}>
                            <p className={styles.title}>{props.title}</p>
                            {typeof gameStage === "number" &&
                                gameStage > 1 &&
                                calcFinalMatch() && (
                                    <>
                                        {meOrWhitePlayer?.color === winner ? (
                                            <MatchResultMessages
                                                gameStageProp={gameStage}
                                                winBox={true}
                                            />
                                        ) : (
                                            <MatchResultMessages
                                                gameStageProp={gameStage}
                                            />
                                        )}
                                    </>
                                )}
                        </div>
                    )}
                </div>
                <div
                    className={cn(styles.buttonContainer, {
                        isTournament: isMultiRoundGame,
                        blocked:
                            meFriendBlockStatus &&
                            gameType === GameModeType.REGULAR,
                    })}
                >
                    {gameType === GameModeType.REGULAR &&
                        (rounds === 1 || isLastRound) && (
                            <>
                                <p
                                    className={cn(styles.blockedMessage, {
                                        showBlockedMessage: meFriendBlockStatus,
                                    })}
                                >
                                    {blockedGames("Opponent blocked game")}
                                </p>
                                <GreenButton
                                    type={
                                        props.rejectButtonType ||
                                        ButtonType.purple
                                    }
                                    fontSize={props.isMultiTable ? 14 : 16}
                                    text={props.cancelText}
                                    onClick={appTransmitter}
                                    isWidthFixed
                                />
                                <div
                                    className={cn(styles.divider, {
                                        blocked: meFriendBlockStatus,
                                    })}
                                />
                                {!meFriendBlockStatus && (
                                    <GreenButton
                                        type={
                                            props.approveButtonType ||
                                            ButtonType.primary
                                        }
                                        fontSize={props.isMultiTable ? 14 : 16}
                                        text={props.approveText}
                                        onClick={props.onApprove}
                                        isWidthFixed
                                    />
                                )}
                            </>
                        )}
                    {(gameType === GameModeType.TOURNAMENT ||
                        (rounds > 1 && !isLastRound)) && (
                        <>
                            <div className={styles.buttonContainer}>
                                <CountdownCircular
                                    seconds={generateNextMatchTimer(2000)}
                                    size={50}
                                    reset={0}
                                    pause={false}
                                    onEnd={() => {
                                        endRoundProvider(() => {
                                            tournamentsStore.setCurrentTournamentGame(
                                                null
                                            );
                                            tournamentsStore.resetTournamentAlertsState();
                                        });
                                    }}
                                    gameType={gameType}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </FadeInOutVertical>
    );
});

export default memo(GeneralGameDialogNew);
