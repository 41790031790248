import React from "react";
import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useMediaQuery } from "@mui/material";
import OutsideClickHandler from "react-outside-click-handler";

import useStores from "src/hooks/useStores";
import paths from "src/consts/paths";
import Balance from "src/components/balance";
import { UserBalanceType } from "src/store/models";
import { BonusDescriptionModal } from "src/components/modals/BonusDescriptionModal";

import { useStyles } from "./styles";
import { IInfoEdition } from "./type";

const InfoMobileEdition = ({
    onHiddenChange,
    onClickBonus,
    isBonusModalOpen,
}: IInfoEdition) => {
    const { authStore, generalStore, lobbyStore } = useStores();
    const user = authStore.currentUser;
    const isMobile = useMediaQuery("(max-width: 1024px)");
    const styles = useStyles();
    const history = useHistory();
    const location = useLocation();

    return (
        <>
            {isMobile && (
                <>
                    <div className={cn(styles.appBarElement, "balance")}>


                    </div>
                </>
            )}
        </>
    );
};

export default observer(InfoMobileEdition);
