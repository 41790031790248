import { makeStyles } from '@mui/styles';

import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';
import theme from 'src/theme';

export const useStyles = makeStyles({
    pageLoaderWrapper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
            '&.isGamePage': {
                height: 'calc(100vh - 100px)',
            },
        },
    },
});
