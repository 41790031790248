import { makeStyles } from '@mui/styles';
import { buttonSizes } from '../styles';
import { MAX_CHANGE_SCREEN_WIDTH } from '../../../constants';

export const useStyles = makeStyles({
    x2Buttonwrapper: {
        opacity: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        animationName: '$wrapperX2',
        animationDelay: '0s',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDuration: '800ms',
    },

    '@keyframes wrapperX2': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },

    x2Button: {
        cursor: 'pointer',
        display: 'grid',
        placeItems: 'center',
        fontWeight: 'bolder',
        color: '#C5C5C5',
        fontSize: 20,
        boxShadow: '0px 0px 22.5618px rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        ...buttonSizes,
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 900px)`]:
            {
                fontSize: 16,
            },
    },
    amount: {
        fontSize: 12,
    },
    x2Image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: -1,
    },
});
