import { AxiosError } from "axios";

import { ILocaleItem } from "src/store/models";

export class LocaleHelper {
    public static extractFromError(e: AxiosError) {
        if (e.response?.data?.error?.code) {
            return {
                label: `common.errors.${e.response?.data?.error.code}`,
                defaultValue: e.response?.data?.error?.message,
            } as ILocaleItem;
        }
        return null;
    }
}
