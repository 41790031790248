import React from 'react';

import nullIconPng from './png_icons/null.png';

export const NullIcon = (): JSX.Element => {
    return (
        <>
            <img src={nullIconPng} alt='none bet' />
        </>
    );
};
