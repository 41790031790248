import loadable from "@loadable/component";
import { useMediaQuery, Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
} from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import RatingCrown from "src/components/RaitingCrownBox";
import paths from "src/consts/paths";
import useChangeTheme from "src/hooks/useChangeTheme";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import {GameType} from "src/store/models";
import { ButtonType, GreenButton } from "src/components/buttons/greenButton/index";
import { ModalTypes, ModalProvider } from "src/pages/landing/utils/context";
import LoginForm from "src/dialogs/login/LoginForm";
import { RegisterForm } from "src/pages/register/RegisterForm";

import BurgerMenu from "../../components/burgerMenu/burger-menu";
import Info from "../../components/info/Info";
import Logo from "../../components/logo/Logo";

import { useStyles } from "./styles";
import DesktopNav from "../../components/desktopNav";
import LanguagePicker from "../../components/LanguagePicker";
import {PhoneAuth} from "../../../../../dialogs/phone-auth";
import { MainActionButton } from "../../../../buttons/newDesignButtons/mainActionButton";

const JackPotComponent = loadable(() => import("src/components/JackPot"));

const HalykAppBar = (props: { isGamePage: boolean; sideMenuIsOpen: boolean }) => {
    const location = useLocation();
    const {
        mainLanding: {
            registeR, logIn
        },
    } = useLocale();
    const { authStore, generalStore, lobbyStore, achievementsStatStore } =
        useStores();
    const {
        login,
        register,
        recoveryPassword,
        alg1xBet,
        algaltente,
        liveDealer: liveDealerPath,
        jackpoker,
        sports,
        logout,
        games,
    } = paths;
    const isPoker = generalStore.gameType === GameType.jackpoker;
    const topPlayers = achievementsStatStore?.topPlayers;

    const styles = useStyles({
        isGamePage: props.isGamePage,
        isPoker: isPoker,
    });
    const appearanceTheme = useChangeTheme();
    const [increaseRakeBar, calcIncreaseRakeBar] = useState<Array<number>>([]);
    const user = authStore.currentUser;

    const isGameScreen = useRouteMatch("/game/:id");
    const isPurchaseDiceCoins = lobbyStore.isPurchaseDiceCoins;

    const userRakeBack = user?.balance.rakeBack;
    const isDesktop = useMediaQuery("(min-width: 1025px)");
    const isTablet = useMediaQuery("(max-width: 1024px)");
    const isMobile = useMediaQuery("(max-width: 768px)");
    const isLanding = useRouteMatch(paths.lobby);

    const isFirstPages =
        location.pathname === login ||
        location.pathname === register ||
        location.pathname === recoveryPassword ||
        location.pathname === logout;

    const hideJackPot =
        //location.pathname === alg1xBet ||
        //location.pathname === algaltente ||
        //location.pathname === liveDealerPath ||
        location.pathname === jackpoker;
        //location.pathname.includes("slot") ||
        //location.pathname.includes("live-dealer") ||
        //location.pathname === sports;

    const isMultitablePage = location.pathname === games;

    const refTypePopup = useRef();
    const refTypeButton = useRef();

    const refBetPopup = useRef();
    const refBetButton = useRef();

    const refTimePopup = useRef();
    const refTimeButton = useRef();

    useOnClickOutside([refTypePopup, refTypeButton], () => { });

    useOnClickOutside([refBetPopup, refBetButton], () => {
        lobbyStore.toggleBetFilterForm(false);
    });

    useOnClickOutside([refTimePopup, refTimeButton], () => {
        lobbyStore.toggleTimeFilterForm(false);
    });

    const baseLimit = 20;
    useEffect(() => {
        if (
            !achievementsStatStore.isLoadedRating &&
            (!topPlayers || topPlayers.length < baseLimit)
        ) {
            achievementsStatStore.getTopPlayers({
                skip: 0,
                limit: baseLimit,
            });
        }
    }, []);

    useEffect(() => {
        if (typeof userRakeBack === "number") {
            userRakeBack &&
                calcIncreaseRakeBar([...increaseRakeBar, userRakeBack]);
        }
    }, [userRakeBack]);

    const toggleOnClick = useCallback(() => {
        generalStore.toggleSideMenu();
    }, []);
    const [modalState, setModalState] = useState({
        register: false,
        'phone-auth': false,
        login: false,
        businessMsg: false,
        notifications: false,
    });

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };
    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    return (
        <>
            <ModalProvider.Provider value={{ openModal, closeModal }}>
                {modalState.register && <RegisterForm />}
                {modalState['phone-auth'] && <PhoneAuth />}
                {modalState.login && <LoginForm />}
                {
                    <div
                        className={cn(styles.containerNav, appearanceTheme, {
                            isPoker: isPoker,
                            isLanding: isLanding,
                            isPurchaseDiceCoins: isPurchaseDiceCoins,
                        })}
                    >
                        <Box
                            component="div"
                            className={cn(styles.appBar, appearanceTheme, {
                                isGamePage: props.isGamePage,
                                isPoker: isPoker,
                                isMultitablePage: isMultitablePage,
                            })}
                        >
                            <div className={styles.leftSection}>
                                {!isDesktop && (
                                    <Box
                                        component="div"
                                        className={styles.burgerRatingWrapper}
                                    >
                                        <BurgerMenu
                                            onClick={toggleOnClick}
                                            sideMenuIsOpen={props.sideMenuIsOpen}
                                        />
                                        {isTablet && authStore.isAuthorized && <RatingCrown />}
                                    </Box>
                                )}
                                <Logo />
                                {isDesktop && (
                                    <DesktopNav styles={styles} isLanding={isLanding} />
                                )}
                            </div>

                            <div className={styles.centerSection}>
                                {authStore.isAuthorized && (
                                    <JackPotComponent isFullAnimation={false} />
                                )}
                            </div>

                            <div className={styles.rightSection}>
                                {authStore.isAuthorized ? (
                                    <>
                                        <Box
                                            component="div"
                                            className={cn(styles.infoGrid, {
                                                isMultitablePage: isMultitablePage,
                                            })}
                                        >
                                        </Box>
                                        <Info />
                                    </>
                                ) : (
                                    <div className={styles.authWrapper}>
                                        <div className={styles.registration}>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text={registeR("Sign Up")}
                                                className={styles.signInBtn}
                                                onClick={() => openModal("register")}
                                            />
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text={logIn("Log In")}
                                                className={styles.logInBtn}
                                                onClick={() => openModal("login")}
                                            />
                                        </div>
                                        { window.innerWidth > 1024 ? <div className={styles.languageWrapper}>
                                            <LanguagePicker showInDropper={true} />
                                        </div> : <></> }
                                    </div>
                                )}
                            </div>
                        </Box>
                    </div>
                }
            </ModalProvider.Provider>
        </>
    );
};

export default observer(HalykAppBar);
