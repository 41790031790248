import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    wrapper: {
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        color: 'white',
    },
});
