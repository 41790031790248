import React from "react";
import { Box, Typography } from "@mui/material";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import paths from "src/consts/paths";
import useChangeTheme from "src/hooks/useChangeTheme";
import { PopupFilterType } from "src/store/models";

import { useStyles } from "./styles";
import { GameModeCreate } from "src/service/api/lobby/requestResponses";

type FilterPopupProps = {
    paramList: {
        value: boolean;
        text: string | GameModeCreate;
        icon?: string | undefined;
        action: () => void;
    }[];
    className: string;
    filterType?: PopupFilterType | undefined;
    placePosition?: string | undefined;
};

const FilterPopup = ({
    paramList,
    className,
    filterType,
    placePosition,
}: FilterPopupProps) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const location = useLocation();
    const isMultitablePage = location.pathname === paths.games;
    const isLobby = location.pathname === paths.diceChessLobby;
    const showRank =
        filterType !== PopupFilterType.match &&
        filterType !== PopupFilterType.gameMode;

    return (
        <div
            className={cn(
                styles.wrapperFilterPopup,
                className,
                appearanceTheme,
                filterType,
                placePosition,
                {
                    isMultitablePage: isMultitablePage,
                }
            )}
        >
            {paramList.map((param, i) => (
                <Box
                    component="div"
                    key={i}
                    onClick={param.action}
                    className={cn(
                        styles.filterItem,
                        filterType,
                        placePosition,
                        appearanceTheme,
                        {
                            active: param.value,
                        }
                    )}
                >
                    <span
                        className={cn(
                            styles.betSpan,
                            placePosition,
                            filterType
                        )}
                    >
                        <img
                            src={param.icon}
                            className={cn(
                                styles.icon,
                                placePosition,
                                appearanceTheme,
                                filterType
                            )}
                        />
                        <Typography
                            className={cn(
                                styles.text,
                                placePosition,
                                appearanceTheme
                            )}
                        >
                            {param.text}
                        </Typography>
                    </span>
                    {isLobby && showRank && (
                        <>
                            <span
                                className={cn(
                                    styles.rankSpan,
                                    placePosition,
                                    appearanceTheme
                                )}
                            >
                                {+param.text >= 1 ? (
                                    <Typography
                                        className={cn(
                                            styles.text,
                                            "isRank",
                                            placePosition,
                                            appearanceTheme
                                        )}
                                    >
                                        +
                                    </Typography>
                                ) : null}
                                {+param.text >= 1 && (
                                    <Typography
                                        className={cn(
                                            styles.text,
                                            placePosition,
                                            appearanceTheme
                                        )}
                                    >
                                        Rank
                                    </Typography>
                                )}
                            </span>
                        </>
                    )}
                </Box>
            ))}
        </div>
    );
};

export default FilterPopup;
