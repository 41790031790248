import { useState, useEffect } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';

const useOrientation = () => {
    const [windowOrientation, setWindowOrientation] = useState<number>();
    const handleOrientation = (event) => {
        if (isIOS && typeof window.orientation === 'number') {
            return window.orientation === 0
                ? setWindowOrientation(0)
                : setWindowOrientation(90);
        }
        if (isAndroid && typeof window.orientation === 'number') {
            return window.orientation === 0
                ? setWindowOrientation(0)
                : setWindowOrientation(90);
        }
        if (screen.availWidth <= screen.availHeight) {
            return setWindowOrientation(0);
        }
        if (screen.availWidth > screen.availHeight) {
            return setWindowOrientation(90);
        }
    };

    useEffect(() => {
        if (screen.availWidth <= screen.availHeight) {
            setWindowOrientation(0);
        }

        if (screen.availWidth > screen.availHeight) {
            setWindowOrientation(90);
        }

        window.addEventListener('orientationchange', handleOrientation);
        window.screen.orientation?.addEventListener('change', (event) => {
            //@ts-ignore
            setWindowOrientation(event.target.angle);
        });
        window.addEventListener('resize', handleOrientation);
        return () => {
            window.removeEventListener('resize', handleOrientation);
            window.removeEventListener('orientationchange', handleOrientation);
            window.screen.orientation?.removeEventListener(
                'change',
                handleOrientation
            );
        };
    }, []);

    return windowOrientation;
};

export default useOrientation;
