import { IGameProvider, IGameProviderResponse } from "./types";

export class GameProviderModel implements IGameProvider {
    public id: string;
    public name: string;

    constructor(data: IGameProviderResponse) {
        this.formatAndSave(data);
    }

    private formatAndSave(data: IGameProviderResponse) {
        this.id = data._id;
        this.name = data.name;
    }
}
