import { makeStyles } from '@mui/styles';
import theme from '../../../theme';

export const useStyles = makeStyles({
    buttonWrapper: (props) => ({
        color: '#fff',
        padding: "0",
        /* height: props.height * props.ratio,
        width: props.width,*/
        mixBlendMode: 'normal',
        filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2))',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        transition: '0.2s',
        '&:active': {
            filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.4))',
            transform: 'scale(0.98)',
        },
    }),

    noBackGroundWrapper: (props) => ({
        color: '#fff',
        /* height: props.height * props.ratio,
      width: props.width,*/
        mixBlendMode: 'normal',
        filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2))',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'none',
        position: 'relative',
        cursor: 'pointer',
        transition: '0.2s',
        '&:active': {
            filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.4))',
            transform: 'scale(0.98)',
        },
    }),
    /*
    buttonSteelWrapper: (props) => ({
        padding: 2,
        background:
            'linear-gradient(180deg, #353849 0%, #D2D6E7 51.56%, #646879 100%)',
        mixBlendMode: 'normal',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        height: '100%',
    }),
    buttonBlackWrapper: (props) => ({
        padding: 1,
        background:
            'linear-gradient(180deg, #020309 0%, #4B4F60 51.56%, #06070C 100%)',
        mixBlendMode: 'normal',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        height: '100%',
    }),
     */

    customButton: (props: { disabled: boolean }) => ({
        //width: '100%',
        //height: '48px',
        borderRadius: "0.2vw",
        //fontSize: "1.2vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        cursor: "pointer",
        bottom: "0",
        "&:hover": {
            opacity: 0.9,
        },

        // Turn Off skewomorphism buttons
        // '&::before': {
        //     content: "''",
        //     display: 'block',
        //     width: '80%',
        //     height: '12px',
        //     position: 'absolute',
        //     top: '-6px',
        //     left: '10%',
        //     background:
        //         'radial-gradient(80.07% 33.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(36, 36, 50, 0) 55%)',
        // },
        // '&::after': {
        //     content: "''",
        //     display: 'block',
        //     width: '80%',
        //     height: '12px',
        //     position: 'absolute',
        //     bottom: '-6px',
        //     left: '10%',
        //     background:
        //         'radial-gradient(80.07% 33.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(36, 36, 50, 0) 55%)',
        // },
        position: 'relative',
        opacity: props.disabled ? 0.3 : 1,

        // marginBottom: props.disableMarginBottom
        //   ? 0
        //   : 'calc(1.5 * env(safe-area-inset-bottom))',
        '&:active': {
            transform: 'scale(1)',
        },
        '&:focus': {
            outline: 'none',
        },
    }),
    buttonText: () => ({
        fontWeight: 700,
        lineHeight: "1.4vw",
        textAlign: 'center',
        fontStyle: 'normal',
        //textShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',

    }),

    primary: {
        background: '#00BA6C',
        border: "none",
    },

    purple: {
        background: 'linear-gradient(19.92deg, #220D5C 13.3%, #6C50BA 86.7%)',
    },

    negative: {
        background: 'linear-gradient(19.92deg, #341010 13.3%, #831D1D 86.7%)',
    },

    secondary: {
        background: 'linear-gradient(19.92deg, #220D5C 13.3%, #6C50BA 86.7%)',
    },
    info: {
        background: 'linear-gradient(19.92deg, #0D235C 13.3%, #506EBA 86.7%)',
    },
    yellow: {
        background: '#141618',
        border: "0.1vw solid #00BA6C",
        color: '#00BA6C',
    },
    desktop: {
        background: 'linear-gradient(180deg, #495677 0%, #303C59 100%)',
        boxShadow: '0px 1px 3px rgba(21, 26, 39, 0.25)',
        borderRadius: 100,
        border: 'none',
    },
    registerBtn: {
        width: '100%',
        background: '#00BA6C',
        border: "none",
        fontSize: "0.85em",
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
        paddingLeft: "7.75em",
        paddingRight: "7.75em",
        borderRadius: "0.4em",

        '@media (max-width: 1024px) and (min-width: 769px)': {
            padding: '1rem 0 !important',
            fontSize: '2vw !important',
            borderRadius: '1vw !important',
        },
        '@media (max-width: 1000px) and (min-width: 700px)': {
            padding: '1rem 0 !important',
            fontSize: '2vw !important',
            borderRadius: '1vw !important',
        },
        '@media (max-width: 768px) and (min-width: 431px)': {
            padding: '1.2rem 0 !important',
            borderRadius: '1vw !important',
        },
        '@media (max-width: 500px) and (min-width: 300px)': {
            padding: '1.2rem 0 !important',
            fontSize: '2vw !important',
            borderRadius: '2vw !important',
        },
    },

    continueBtn: {
        width: '100%',
        background: '#00BA6C',
        border: "none",
        fontSize: "0.85em",
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
        paddingLeft: "7.75em",
        paddingRight: "7.75em",
        borderRadius: "0.4em",

        '@media (max-width: 1024px) and (min-width: 769px)': {
            padding: '1rem 0 !important',
            fontSize: '2vw !important',
            borderRadius: '1vw !important',
        },
        '@media (max-width: 1000px) and (min-width: 700px)': {
            padding: '1rem 0 !important',
            fontSize: '2vw !important',
            borderRadius: '1vw !important',
        },
        '@media (max-width: 768px) and (min-width: 431px)': {
            padding: '1.2rem 0 !important',
            borderRadius: '1vw !important',
        },
        '@media (max-width: 500px) and (min-width: 300px)': {
            padding: '1.2rem 0 !important',
            fontSize: '2vw !important',
            borderRadius: '2vw !important',
        },
    },

    signUp: {
        fontSize: "1em !important",
        paddingTop: "0.7em !important",
        paddingBottom: "0.7em !important",
        paddingLeft: "0.8em !important",
        paddingRight: "0.8em !important",
        background: "#00BA6C !important",
        border: "none",
        [theme.breakpoints.down('lg')]: {
            fontSize: "0.9em !important",
            padding: "0.8em 0.7em !important",
            borderRadius: '0.5vw !important',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "0.9em !important",
            padding: "1em 0.7em !important",
            borderRadius: '0.5vw !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.45em !important",
            padding: "1.5em 0.8em !important",
            borderRadius: '0.5vw !important',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.5em !important",
            padding: "0.8em 0.6em !important",
            borderRadius: '0.6vw !important',
        },
    },

    logIn: {
        color: "#00BA6C !important",
        fontSize: "1em !important",
        paddingTop: "0.7em !important",
        paddingBottom: "0.7em !important",
        paddingLeft: "0.8em !important",
        paddingRight: "0.8em !important",
        background: "transparent !important",
        border: "2px solid #00BA6C",
        [theme.breakpoints.down('lg')]: {
            fontSize: "0.9em !important",
            padding: "0.8em 0.7em !important",
            borderRadius: '1vw !important',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "0.9em !important",
            padding: "0.9em 0.7em !important",
            borderRadius: '1vw !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.45em !important",
            padding: "1.3em 0.8em !important",
            borderRadius: '1vw !important',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.5em !important",
            padding: "0.8em 0.6em !important",
            borderRadius: '1vw !important',
        },
    },
});
