export const media = (
    min,
    max,
    minScreenWidth = 320,
    maxScreenWidth = 1920
) => {
    return `calc(${min}px + ${
        max - min
    } * ((100vw - ${minScreenWidth}px) / (${maxScreenWidth} - ${minScreenWidth})))`;
};
