import { ColorVariant } from "src/store/models";

interface ICalcPieces {
    [key: string]: {
        [key: string]: number;
    };
}

export const getBeatCounter = (
    killedPieces: ICalcPieces
): {
    [key: string]: number;
} => {
    if (!killedPieces)
        return {
            w: 0,
            b: 0,
        };
    const multiplicator = [1, 3, 3, 5, 9, 1];
    const whitebeat = Object.values(killedPieces[ColorVariant.white])
        .map((item, index) => {
            return item * multiplicator[index];
        })
        .reduce((acc, item) => {
            return acc + item;
        }, 0);

    const blackbeat = Object.values(killedPieces[ColorVariant.black])
        .map((item, index) => {
            return item * multiplicator[index];
        })
        .reduce((acc, item) => {
            return acc + item;
        }, 0);

    return {
        w: whitebeat - blackbeat,
        b: blackbeat - whitebeat,
    };
};
