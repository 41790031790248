import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import cn from "classnames";
import React, { useEffect } from "react";

import { usePlayer } from "src/sounds/use-player";
import { Button } from "src/shared/ui/Button";
import useStores from "src/hooks/useStores";

import { useStyles } from "./styles";
import { useChessBoardProps } from "../../hooks/useChessBoardProps";
import useDoublingStageStatus from "../../hooks/useDoublingStageStatus";
import X2Dialog from "../x2Alert";

import OpponentAcceptedDoubling from "./OpponentAcceptedDoubling";

const X2Dialogs = ({
    showOpponentAcceptedDoubling = true,
    randomValue,
    isTabletMobile,
}: {
    showOpponentAcceptedDoubling?: boolean;
    randomValue?: number | undefined;
    isTabletMobile?: boolean | undefined;
}) => {
    const { showAcceptOrRejectDialog, showOpponentAcceptedDoublingDialog } =
        useDoublingStageStatus();
    const { isMultiTable } = useChessBoardProps();
    const { gameStore, authStore } = useStores();
    const styles = useStyles();
    const player = usePlayer();

    const handleAcceptClick = () => {
        gameStore.dialogs.showOpponentAcceptedDoublingDialogNew();
        gameStore.gameBetNew({
            gameId: gameStore?.currentGameNew?._id || "",
            bet:
                (gameStore?.currentGameNew?.extra?.currentBank || 0) -
                (gameStore?.currentGameNew?.bank || 0),
        });
    };

    const handleRejectClick = () => {
        gameStore.rematchDecline(gameStore?.currentGameNew?._id);
    };

    return (
        <Box className={cn(styles.x2Contaner)}>
            <Button
                text="Nope"
                color="red"
                onClick={() => {
                    handleRejectClick();
                }}
                className={cn(styles.x2ContainerButton, "red")}
            />
            <Button
                text="Accept"
                onClick={() => handleAcceptClick()}
                className={cn(styles.x2ContainerButton, "green")}
            />
        </Box>
    );

    // if (showAcceptOrRejectDialog) {
    //     return (
    //         <X2Dialog isXlTablet={isTabletMobile} isMultiTable={isMultiTable} />
    //     );
    // } else if (
    //     showOpponentAcceptedDoublingDialog &&
    //     showOpponentAcceptedDoubling
    // ) {
    //     return (
    //         <OpponentAcceptedDoubling
    //             randomValue={randomValue}
    //             isXlTablet={isTabletMobile}
    //             isMultiTable={isMultiTable}
    //         />
    //     );
    // }

    return null;
};

export default observer(X2Dialogs);
