import { ChessMap } from '../chess/chess.map';
import { Pawn } from './pawn';

export class PawnWhite extends Pawn {
  moveVector = -1;

  constructor(public chessMapRef: ChessMap | null = null) {
    super(false, chessMapRef);
  }
}
