import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";
import { React_Brackets_WIDTH_CONDITION } from "src/consts/window-size-params";

export const useStyles = makeStyles({
    popup: {
        width: "100%",
        left: 0,
        top: 55,
        overflowY: "scroll",
        overflowX: "hidden",
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "&.top": {
            top: "unset",
            bottom: 50,
        },
        "&.bottom": {
            left: 0,
            top: 50,
        },
        // [theme.breakpoints.between(
        //     React_Brackets_WIDTH_CONDITION,
        //     MAX_CHANGE_SCREEN_WIDTH
        // )]: {
        //     top: 'unset',
        //     bottom: 50,
        // },
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            "@media (orientation: portrait)": {
                top: "unset",
                bottom: 50,
                "&.bet-filter-popup": {
                    width: "fit-content",
                },
            },
        },
        [theme.breakpoints.down("md")]: {
            top: "unset",
            bottom: 50,
            "&.bet-filter-popup": {
                width: "fit-content",
            },
        },
        "@media screen and (min-width: 1024px) and (max-height: 940px)": {
            maxHeight: 550,
        },
        "@media screen and (min-width: 1024px) and (max-height: 820px)": {
            maxHeight: 490,
        },
        "@media screen and (min-width: 1024px) and (max-height: 715px)": {
            maxHeight: 450,
        },
        "@media screen and (min-width: 768px) and (max-height: 820px)": {
            maxHeight: 490,
        },
        "@media screen and (min-width: 768px) and (max-height: 715px)": {
            maxHeight: 420,
        },
        "@media screen and (max-width: 768px) and (max-height: 835px)": {
            maxHeight: 535,
        },
        "@media screen and (max-width: 768px) and (max-height: 810px)": {
            maxHeight: 480,
        },
        "@media screen and (max-width: 768px) and (max-height: 715px)": {
            maxHeight: 410,
        },
        "@media screen and (max-width: 768px) and (max-height: 645px)": {
            maxHeight: 360,
        },
        "@media screen and (max-width: 768px) and (max-height: 600px)": {
            maxHeight: 345,
        },
        "&.isMultitablePage": {
            maxHeight: 345,
        },
        "&.fastGame": {
            height: 280,
        },
    },
});
