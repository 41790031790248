import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

export const useStyles = makeStyles({
    eyeWrapper: {
        height: 15,
        cursor: 'pointer',
        opacity: 0.5,
        transition: '0.2s',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 10,
        marginLeft: 0,
        '&:hover': {
            opacity: 0.7,
        },
        [theme.breakpoints.down('lg')]: {
            marginRight: 0,
            transform: 'scale(0.87)',
            height: 18,
            marginLeft: 5,
        },
    },
});
