import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export const Camera = () => {
    return (
        <SVGUniqueID>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='60%'
                height='60%'
                viewBox='0 0 20 20'
                style={{ filter: 'invert(1)' }}
            >
                <defs>
                    <clipPath id='a'>
                        <path
                            d='M13 11a3 3 0 1 0-3 3 3 3 0 0 0 3-3zm-3 5a5 5 0 1 1 5-5 5 5 0 0 1-5 5zM6.42 2.56l-.67.64a2 2 0 0 1-1.38.56H2a1.89 1.89 0 0 0-2 1.75v10.54a1.89 1.89 0 0 0 2 1.76h16a1.89 1.89 0 0 0 2-1.76V5.51a1.89 1.89 0 0 0-2-1.76h-2.37a2 2 0 0 1-1.38-.56l-.67-.64A2 2 0 0 0 12.2 2H7.8a2 2 0 0 0-1.38.56z'
                            clip-rule='evenodd'
                        />
                    </clipPath>
                </defs>
                <title>camera</title>
                <path
                    fill-rule='evenodd'
                    d='M13 11a3 3 0 1 0-3 3 3 3 0 0 0 3-3zm-3 5a5 5 0 1 1 5-5 5 5 0 0 1-5 5zM6.42 2.56l-.67.64a2 2 0 0 1-1.38.56H2a1.89 1.89 0 0 0-2 1.75v10.54a1.89 1.89 0 0 0 2 1.76h16a1.89 1.89 0 0 0 2-1.76V5.51a1.89 1.89 0 0 0-2-1.76h-2.37a2 2 0 0 1-1.38-.56l-.67-.64A2 2 0 0 0 12.2 2H7.8a2 2 0 0 0-1.38.56z'
                />
                <g clip-path='url(#a)'>
                    <path d='M0 0h20v20H0z' />
                </g>
            </svg>
        </SVGUniqueID>
    );
};
