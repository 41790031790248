import { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "src/hooks/useStores";
import { useSocket } from "src/core/useSocket";
import { infoService } from "src/service/services";
import {
    INotification,
    IUserBalance,
    UserBalanceType,
    IBlockedSettings,
} from "src/store/models";
import { InfoMessage } from "src/pages/internet-speed/types";
import { IStateFromSocket } from "src/store/models";

import endpoints from "../../core/endpoints";

const MainSocket = () => {
    const { authStore, friendsStore, generalStore, achievementsStatStore } =
        useStores();
    const errorTimer = [];

    const [initSocket, socket] = useSocket({
        token: authStore.getAuthToken(),
    });
    useEffect(() => {
        if (!socket) return;
        socket.on("balance", (balance: IUserBalance) => {
           
            authStore.updateBalance({
                play:
                    (balance?.play?.available ??
                        authStore.currentUser?.balance?.play) ||
                    0,
                coins:
                    (balance?.coins?.available ??
                        authStore.currentUser?.balance?.coins) ||
                    0,
                [UserBalanceType.referralBonus]:
                    (balance?.referralBonus?.available ??
                        authStore.currentUser?.balance?.bonus) ||
                    0,
            });
            authStore.setIncreasedRakeBack(balance?.rakeBack || 0);
        });
       
        socket.on("jackpot:balance", ({ amount }: { amount: number }) => {
            generalStore.updateJackpot({ amount });
        });
        socket.on("rating", (data: number) => {
            authStore.setIncreasedRP(data);
        });
        socket.on("friend:connected", (friendId: string) => {
            friendsStore.updateFriendById(friendId, { online: true });
        });
        socket.on("friend:disconnected", (friendId: string) => {
            // friendsStore.updateFriendById(friendId, { online: false });
            friendsStore.updateAfterDisconnect(friendId);
        });
        socket.on("friend:game:joined", ({ user }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: true,
                joinAwait: true,
            });
            // infoService.sendInfo(`friend:game:joined ${user}`);
        });

        socket.on("friend:game:cancelJoin", ({ user }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: false,
                rematchAwait: false,
                joinAwait: false,
            });
            // infoService.sendInfo(`friend:game:cancelJoin ${user}`);
        });

        socket.on("friend:game:started", ({ user, id }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: true,
                game: {
                    id,
                },
                rematchAwait: false,
                joinAwait: false,
            });
            // infoService.sendInfo(`friend:game:started ${user} game id ${id}`);
        });

        socket.on("settings:update", (settings: IBlockedSettings) => {
           
            achievementsStatStore.putMeSettingCollections(settings);
            // infoService.sendInfo(`friend:game:cancelJoin ${user}`);
        });

        socket.on("friend:game:returned", ({ user, id }) => {
            friendsStore.updateFriendById(user, { isPlaying: true });
            // infoService.sendInfo(`friend:game:started ${user} game id ${id}`);
        });

        socket.on("friend:game:finished", ({ user }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: false,
                joinAwait: false,
            });
            // infoService.sendInfo(`friend:game:finished ${user}`);
        });

        socket.on("friend:game:rejected", ({ user }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: false,
                joinAwait: false,
            });
            // infoService.sendInfo(`friend:game:rejected ${user}`);
        });

        socket.on("friend:game:created", ({ user }) => {
            // infoService.sendInfo(`friend:game:created ${user}`);
        });

        socket.on("friend:game:rematch", ({ user }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: false,
                rematchAwait: true,
            });
            // infoService.sendInfo(`friend:game:rematch ${user}`);
        });
        socket.on("friend:game:rematch:refused", ({ user }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: false,
                rematchAwait: false,
                joinAwait: false,
            });
            // infoService.sendInfo(`friend:game:rematch:refused ${user}`);
        });
        socket.on("friend:game:rematched", ({ user, id }) => {
            friendsStore.updateFriendById(user, {
                isPlaying: true,
                rematchAwait: false,
            });
            // infoService.sendInfo(`friend:game:rematched ${user}`);
        });

        socket.on("friend:game:removed", ({ user }) => {
      
            friendsStore.updateFriendById(user, {
                joinAwait: false,
            });
            // infoService.sendInfo(`friend:game:removed ${user}`);
        });

        socket.on("connect", function () {
            // console.log("connected", generalStore.internetStatus);
            generalStore.setApplicationStatus(true);
            if (generalStore.internetStatus === InfoMessage.lost) {
                errorTimer.splice(0, errorTimer.length);
                generalStore.setInternetStatus(InfoMessage.connected);
            }
        });
        socket.on("reconnect", function () {
           
            errorTimer.splice(0, errorTimer.length);
            generalStore.setInternetStatus(InfoMessage.connected);
            generalStore.setApplicationStatus(true);
        });
        socket.on("disconnect", function () {
            
            errorTimer.splice(0, errorTimer.length);
        });
        socket.on("connect_failed", function () {
         
            //@ts-ignore
            errorTimer.push("connection failed");
            errorTimer.length >= 3 &&
                generalStore.setInternetStatus(InfoMessage.lost);
        });
        socket.on("connect_error", function () {
            if (generalStore.isOpenApplication) {
                //@ts-ignore
                errorTimer.push("error");
            }
            authStore.isAuthorized &&
                errorTimer.length >= 3 &&
                generalStore.setInternetStatus(InfoMessage.lost);
        });
        socket.on("connect_timeout", function () {
      
            if (generalStore.isOpenApplication) {
                //@ts-ignore
                errorTimer.push("connect_timeout");
            }
            errorTimer.length >= 3 &&
                generalStore.setInternetStatus(InfoMessage.lost);
        });
        socket.on("reconnect_failed", function () {
       
            if (generalStore.isOpenApplication) {
                //@ts-ignore
                errorTimer.push("reconnect_failed");
            }
            errorTimer.length >= 3 &&
                generalStore.setInternetStatus(InfoMessage.lost);
        });
        socket.on("reconnect_error", function () {
         
            //@ts-ignore
            if (generalStore.isOpenApplication) {
                //@ts-ignore
                errorTimer.push("reconnect_error");
            }
            errorTimer.length >= 3 &&
                generalStore.setInternetStatus(InfoMessage.lost);
        });
        socket.on("error", function () {
     
            if (generalStore.isOpenApplication) {
                //@ts-ignore
                errorTimer.push("error");
            }
            errorTimer.length >= 3 &&
                generalStore.setInternetStatus(InfoMessage.lost);
        });
        socket.on("user:notification", (data: INotification) => {
        
        });
        //=======
        socket.on("state", (data: IStateFromSocket) => {
            friendsStore.getFriends();
        });
    }, [socket]);

    useEffect(() => {
        if (!authStore.isAuthorized) {
            socket?.disconnect();
            generalStore.setInternetStatus(null);
            return;
        }
        // if (authStore.isAuthorized) {
        //     socket?.connect();
        //     // initSocket(endpoints.mainSocket);
        //     //======
        //     initSocket(endpoints.lobbySocket);
        // }
    }, [authStore.isAuthorized]);

    return null;
};

export default observer(MainSocket);
