import axios from 'axios';

import endpoints from 'src/core/endpoints';

export class ExternalGameService {
    public static toggleFavorite(id: string | number) {
        return axios.post(endpoints.toggleExternalGameFavorite, {
            id,
        });
    }
}
