import React from 'react';
import cn from 'classnames';

import { Spinner } from '../spinner';

import { useStyles } from './styles';

interface IPageLoaderProps {
    isGamePage?: boolean;
}

const PageLoader = ({ isGamePage }: IPageLoaderProps) => {
    const styles = useStyles();

    return (
        <div className={cn(styles.pageLoaderWrapper, { isGamePage })}>
            <Spinner isAbsolute background='none' />
        </div>
    );
};

export default PageLoader;
