import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import cn from "classnames";
import _get from "lodash/get";
import { GameStoreProvider } from "src/hooks/useGameStore";
import { RedesignClock } from "src/components/PlayerInfoRedesign/components/RedesignClock";
import paths from "src/consts/paths";
import { IPlayer } from "src/store/models";
import LazyImages from "src/components/lazyImages";
import shortColor from "src/utils/shortColor";
import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";

import useStores from "../../../../hooks/useStores";
import endpoints from "../../../../core/endpoints";
import useChangeTheme from "../../../../hooks/useChangeTheme";
import { useChessBoardProps } from "../../hooks/useChessBoardProps";

import { useStyles } from "./styles";

interface IMultiGameTabs {
    id: string;
    time: string;
    players: [IPlayer] | [IPlayer, IPlayer];
}

const RenderGameTab = observer(({ id, players }: IMultiGameTabs) => {
    const styles = useStyles();
    const { isMultiTable, isTwoGameTable } = useChessBoardProps();
    const appearanceTheme = useChangeTheme();
    const history = useHistory();
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const { gameStore } = useStores();
    const myColor = gameStore.gameState.getMyColor();
    const activeClock = gameStore.gameState.activeClock;

    const myTime = gameStore.gameState.myTimeLeft;
    const isActivePlayer = (color, clock) =>
        //@ts-ignore
        shortColor(color) === _get(clock, "color", "unknown");

    return (
        <Box
            component="div"
            className={cn(styles.multiGameWrapper, appearanceTheme)}
            key={`${id}_multiGame`}
            onClick={() => {
                history.push(`${paths.game}/${id}`);
                //Each time we have to update game store after switching other game tab
                gameStore.setGameIdMobileTab(id);
            }}
        >
            <Box component="div" className={styles.gameTabContainer}>
                <Box component="div" className={styles.avatarGameTabBox}>
                    <LazyImages
                        className={cn(styles.playerAvatar, "firstPlayer", {
                            isLoaded: !isLoaded,
                        })}
                        placeholderImg={avatarPlaceholder}
                        src={`${endpoints.avatarPath}${players[0]?.avatar}`}
                        errorImg={avatarPlaceholder}
                        id={id}
                        setLoaded={(status: boolean) => setLoaded(status)}
                    />
                    <LazyImages
                        className={cn(styles.playerAvatar, "secondPlayer", {
                            isLoaded: !isLoaded,
                        })}
                        placeholderImg={avatarPlaceholder}
                        src={`${endpoints.avatarPath}${players[1]?.avatar}`}
                        errorImg={avatarPlaceholder}
                        id={id}
                        setLoaded={(status: boolean) => setLoaded(status)}
                    />
                </Box>
                <Box component="div" className={styles.redesignClockBox}>
                    <RedesignClock
                        warningLimit={30}
                        active={isActivePlayer(myColor, activeClock)}
                        initTime={myTime}
                        noTimerSound
                        activeClock={activeClock}
                        color={myColor}
                        isMultiTable={isMultiTable}
                        isTwoGameTable={isTwoGameTable}
                    />
                </Box>
            </Box>
        </Box>
    );
});

const MultiGameTabs = ({ solidMultiGames }: any) => {
    const { authStore } = useStores();
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();

    if (!authStore?.currentUser?._id || !solidMultiGames) return null;

    return (
        <Box
            component="div"
            className={cn(styles.multiGameContainer, appearanceTheme)}
        >
            {solidMultiGames &&
                solidMultiGames.map((game) => (
                    <GameStoreProvider id={game.id}>
                        <RenderGameTab
                            id={game.id}
                            time={game.created}
                            players={game.players}
                        />
                    </GameStoreProvider>
                ))}
        </Box>
    );
};

export default observer(MultiGameTabs);
