// Dice Button Size Styles

import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
} from '../../../pages/game-new/constants';

const curretWidth = window.innerWidth;

export const useStyles = makeStyles({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    diceCircle: {
        width: 120,
        height: 120,
        margin: '0 auto',
        boxShadow:
            '0 1px 3px rgba(0, 0, 0, 0.25), 0 0 8px rgba(41, 134, 113, 1)',
        borderRadius: '50%',
        opacity: 0.4,
        '&.enabled': {
            opacity: 1,
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25), 0 0 8px #298671',
        },
        '&.isMultiTable': {
            width: 85,
            height: 85,
            [theme.breakpoints.down(1650)]: {
                width: 75,
                height: 75,
            },
            [theme.breakpoints.down(1450)]: {
                width: 65,
                height: 65,
            },
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height: '100%',
        },
        '&.darkGreen': {
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25), 0 0 8px #6C50BA',
        },
        [theme.breakpoints.up('xs')]: {
          // width: '120%',
          // height: '120%',
          width: '100%',
          height: '100%',
          aspectRatio: '1 / 1',
        },
        [theme.breakpoints.up('lg')]: {
          // width: '80%',
          // height: '80%',
        }
    },
    flash: {
        cursor: 'pointer',
        '-webkit-transform': 'translate3d(0,0,0)',
        '&::after': {
            content: '""',
            boxShadow:
                curretWidth > MIN_CHANGE_SCREEN_WIDTH
                    ? '0 0 60px #2AE7BD'
                    : '0 0 60px #2AE7BD47',
            animation: '$animationDice',
            animationIterationCount: 10000,
            animationDuration: '700ms',
            animationTimingFunction: 'ease-in-out',
            width: '100%',
            height: '100%',
            display: 'none',
            position: 'absolute',
            top: 0,
            borderRadius: 80,
        },
        '&.darkGreen': {
            '&::after': {
                content: '""',
                boxShadow:
                    curretWidth > MIN_CHANGE_SCREEN_WIDTH
                        ? '0 0 60px #6C50BA'
                        : '0 0 60px #6C50BA96',
                animation: '$animationDice',
                animationIterationCount: 10000,
                animationDuration: '700ms',
                animationTimingFunction: 'ease-in-out',
                width: '100%',
                height: '100%',
                display: 'block',
                position: 'absolute',
                top: 0,
                borderRadius: 80,
            },
        },
        [theme.breakpoints.up('xs')]: {
          width: '100%',
          height: '100%',
          aspectRatio: 1,
        },
        [theme.breakpoints.up('md')]: {
          width: '100%',
          height: '100%',
          aspectRatio: 1,
        },
        [theme.breakpoints.up('lg')]: {
          width: '80%',
          height: '80%',
        }
    },

    '@keyframes animationDice': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
});
