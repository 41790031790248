import { action, makeAutoObservable } from 'mobx';

import { getGamesHistory } from '../service/api/myGames';

import { IMyGame } from './models';

export class GamesHistoryStore {
    gamesHistory: IMyGame[] = [];
    isLoaded: boolean = false;
    errorOccurred: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async getGamesHistory() {
        try {
            this.setLoadedState(false);
            this.setIsErrorOccurred(false);
            const { data: gamesHistory } = await getGamesHistory();
            this.setGamesHistory(gamesHistory.games);
        } catch (e) {
            this.setIsErrorOccurred(true);
        }
    }

    @action
    setLoadedState(isLoaded: boolean) {
        this.isLoaded = isLoaded;
    }

    @action
    setIsErrorOccurred(isLoaded: boolean) {
        this.errorOccurred = isLoaded;
    }

    @action
    setGamesHistory(gamesHistory: IMyGame[]) {
        this.gamesHistory = gamesHistory;
        this.isLoaded = true;
    }
}

export default GamesHistoryStore;
