import { height } from '@mui/system';
import { makeStyles } from '@mui/styles';

import { media } from 'src/utils/media';
import theme from 'src/theme';

export const useStyles = makeStyles({
    menuLayer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: 'auto',
        '&.authorized': {
            justifyContent: 'flex-start',
        //    paddingLeft: '2vw', // Добавляем отступ слева
        },
        // Для больших экранов до XL
        [theme.breakpoints.down('xl')]: {
            width: 'auto',
        },

        // Общие настройки для экрана меньше 1300px
        '@media screen and (max-width: 1300px)': {
            width: 'auto',
        },

        // Экран меньше 1150px с портретной ориентацией
        '@media screen and (max-width: 1150px) and (orientation: portrait)': {
            width: 'auto',
            marginTop: 0,
        },

        // Исправление синтаксиса для экрана меньше 400px
        '@media screen and (max-width: 400px)': {
            width: '110px',
            marginTop: 0,
            justifyContent: 'center',
        },

        // Общие настройки для экрана до LG
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
        },

        // Для экранов до MD
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        //    marginLeft: '2vw',
        },

        // Исправленный синтаксис для разрешений меньше 520px
        [theme.breakpoints.down(520)]: {
            display: 'flex', // Можете использовать другое свойство
        },

        // Для экранов до SM
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
        },
    },

    gameDetectWrapperBox: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up(1250)]: {
            '&:hover .navDropdown': {
                visibility: 'visible',
                opacity: 1,
                transform: 'translateY(0)',
            },
        },
    },

    logoWrapper: {
        position: 'relative',
        marginTop: '0',
        '&.authorized': {
            marginLeft: '0',
        },
        '@media screen and (min-width: 1600px)': {
          width: 210,
          height: 'auto',
        },
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            fontSize: 15,

        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            marginTop: '1vw',

        },
        [`@media (min-width: 400px) and (max-width: 480px)`]: {
            marginTop: '1vw',
        },
        [`@media (min-width: 300px) and (max-width: 380px)`]: {
            marginTop: '2vw',
        },
        '&.isPoker': {
            width: '100%',
            height: '100%',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                height: '100%',
            },
        },

    },

    logo: {
        height: media(20, 50, 1024),
        objectFit: 'contain',
        cursor: 'pointer',
        '&.authorized': {
            marginLeft: '0',
        },
        '@media screen and (max-width: 1500px)': {
            height: 35,
        },
        '@media screen and (max-width: 1024px)': {
            height: 32,
        },
        '@media screen and (max-width: 768px)': {
            height: 40,
        },
        '@media screen and (min-width: 2300px)': {
            height: 'calc(20px + 30 * ((75vw - 1024px) / (1920 - 1024)))',
        },
        '@media screen and (min-width: 3500px)': {
            height: 'calc(20px + 30 * ((60vw - 1024px) / (1920 - 1024)))',
        },
        '&.isHalyk': {
            height: media(55, 65, 1024),
            '@media screen and (min-width: 2500px)': {
                height: '100px', 
            },
            '@media screen and (min-width: 3500px)': {
                height: '120px',
            },
        },
    },

    mobileLogo: {
        height: media(20, 50, 1024),
        objectFit: 'contain',
        cursor: 'pointer',
        '@media screen and (max-width: 1500px)': {
            height: 35,
        },
        '@media screen and (max-width: 1024px)': {
            height: 32,
        },
        '@media screen and (max-width: 768px)': {
            height: 27,
        },
        '@media screen and (min-width: 2300px)': {
            height: 'calc(20px + 30 * ((75vw - 1024px) / (1920 - 1024)))',
        },
        '@media screen and (min-width: 3500px)': {
            height: 'calc(20px + 30 * ((60vw - 1024px) / (1920 - 1024)))',
        },
        '&.isHalyk': {
            height: media(55, 65, 1024),
            '@media screen and (min-width: 2500px)': {
                height: '100px',
            },
            '@media screen and (min-width: 3500px)': {
                height: '120px',
            },
        },
    },

    halykLogo: {
        marginLeft: '1vw',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '-3vw',
        },
        '@media screen and (max-width: 480px)': {
            marginLeft: '0'
        },
        '@media screen and (max-width: 360px)': {
            marginLeft: '0',
        },
    },

    logoFlake: {
        width: '40px',
        position: 'absolute',
        right: '0%',
        top: '25%',
        transform: 'translate(45%, -50%)',
        objectFit: 'cover',
        zIndex: -1,

        animationDelay: '0s',
        animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        animationDuration: '5000ms',
        [theme.breakpoints.down('xl')]: {
            width: '38px',
        },
        '@media screen and (max-width: 1300px)': {
            width: '33px',
        },
        '@media screen and (max-width: 1150px)': {
            width: '25px',
        },
        [theme.breakpoints.down('lg')]: {
            width: '20px',
        },
        '&.newYearFlake': {
            animationName: '$newYearFlake',
        },
        '&.newYearChina': {
            transform: 'translate(60%, -50%)',
            animationName: '$newYearChina',
            borderRadius: '50%',
        },
    },

    '@keyframes newYearFlake': {
        '0%': {
            right: '0%',
            top: '25%',
            transform: 'translate(45%, -50%) scale(1) rotate(0deg)',
        },
        '75%': {
            right: '0%',
            top: '25%',
            transform: 'translate(45%, -50%) scale(1.2) rotate(180deg)',
        },
        '100%': {
            right: '0%',
            top: '25%',
            transform: 'translate(45%, -50%) scale(1) rotate(360deg)',
        },
    },

    '@keyframes newYearChina': {
        '0%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1) rotate(0deg)',
        },
        '25%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1) rotate(20deg)',
            boxShadow: '0px 0px 42px 0px rgba(255, 101, 101, 0.5) inset',
        },
        '50%': {
            right: '0%',
            top: '25%',
            transform: 'translate(60%, -50%) scale(1.15) rotate(0deg)',
            boxShadow: 'rgba(255, 101, 101, 0.7) inset',
            filter: 'drop-shadow(0px 0px 15px rgba(255, 101, 101, 1))',
        },
        '75%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1.2) rotate(-20deg)',
            boxShadow: '0px 0px 42px 0px rgba(255, 101, 101, 0.5) inset',
        },
        '100%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1) rotate(0deg)',
        },
    },
});
