import React from "react";
import cn from "classnames";
import { Box } from "@mui/material";

import useChangeTheme from "src/hooks/useChangeTheme";

import { useStyles } from "./styles";

type IModalDevider = {
    label?: string;
    color?: string;
};
const ModalDivider = ({ label }: IModalDevider) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();

    return (
        <Box className={cn(styles.modalDevider, appearanceTheme, label)}></Box>
    );
};

export default ModalDivider;
