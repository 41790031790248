import { makeStyles } from '@mui/styles';
import theme from 'src/theme';
import errorIcon from './images/error.svg';

export const useStyles = makeStyles({
    container: {
        background: 'rgba(0, 0, 0, 0.6)',
        padding: '0 1.5vw',
        '& .MuiDialog-paper': {
            padding: 0,
            margin: 0,
            width: '100%',
        },
        '& .MuiPaper-root': {
            background: 'transparent',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 1vw',
        },
    },


    dialogPaper: {
        maxWidth: 432,
        width: '100%',
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '3vw 2vw',
        margin: 0,
        overflowY: 'unset',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 18,
        },
    },
    formContainer: {
        width: '100vw',
        maxWidth: '480px',
        minWidth: '300px',
        [theme.breakpoints.down('md')]: {
            width: '70vw',
            maxWidth: '480px',
            minWidth: '300px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            maxWidth: '480px',
            minWidth: '300px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
        },
    },
    formWrapper: {
        maxHeight: '100vh', 
        overflowY: 'auto', 
        padding: 25,
        background: 'rgba(245, 245, 245, 0.05)',
        backdropFilter: 'blur(24px)',
        borderRadius: '1.5vw',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
        scrollbarWidth: 'none', 
        msOverflowStyle: 'none', 
        '&::-webkit-scrollbar': {
            display: 'none', 
        },
        [theme.breakpoints.down('md')]: {
            padding: '3vw',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '2vw',
            background: 'rgba(245, 245, 245, 0.05)',
            backdropFilter: 'blur(24px)',
            borderRadius: '1.5vw',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1.8vw 1.2vw',
        },
    },
    titleBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    enterGame: {
        fontWeight: 500,
        fontFamily: 'Montserrat',
        fontSize: '1.3vw',
        textAlign: 'left',
        color: '#FFFFFF',
        lineHeight: '29.26px',
        marginBottom: '1vw',
        [theme.breakpoints.down('xl')]: {
            fontSize: '2vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '2vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '5vw',
            fontWeight: 400,
            marginBottom: '6vw',
        },
    },
    joinGame: {
        textAlign: 'left',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '20px',
        color: '#969BAD',
        marginBottom: 16,
        '& a': {
            textDecoration: 'none',
            color: '#50baae',
            fontSize: 20,
            lineHeight: '25px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    fieldGrid: {
        width: '100%',
    },
    fieldHolder: {
        width: '100%',
    },
    inputContainer: {
        marginBottom: '1.2vw',
        position: 'relative',

        //backgroundColor: 'transparent', 
        [theme.breakpoints.down('sm')]: {
            marginBottom: '4vw',

        },
    },
    checkBoxContainer: {
        marginBottom: '1vw',
    },
    checkBoxHolder: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px',
        marginBottom: 5,
        '& input[type="checkbox"]': {
            width: '16px',
            height: '16px',
            flexShrink: 0,
        },
    },
    checkBoxFieldText: {
        fontFamily: 'Montserrat',
        fontSize: 13,
        lineHeight: 1.2,
        fontWeight: 400,
        color: '#999999',
        marginLeft: 9,
        transition: 'color 0.2s',
        flex: '1',
        '& a': {
            color: '#50BAAE',
            textDecoration: 'none',
        },
    },
    checkBoxDescription: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
    },
    rulesLink: {
        fontFamily: 'Montserrat',
        fontSize: 13,
        fontWeight: 700,
        lineHeight: '19.2px',
        textDecoration: 'none',
        color: '#00BA6C',
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontWeight: 400,
        fontSize: 12,
        color: '#EB5757',
        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
        paddingTop: 10,
        '&:before': {
            content: '""',
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: `url(${errorIcon})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            marginRight: 10,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 5,
            paddingBottom: 5,

        },
    },

    inputWrapper: {
        position: 'relative',
    },

    passwordInputWrapper: {
        position: 'relative',
        width: '100%',
    },
    outlinedInput: {
        width: '100%',
        height: 42,
        padding: '10px 40px 10px 16px',
        backgroundColor: 'transparent',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        borderRadius: '8px',
        color: '#fff',
        fontSize: '13px',
        fontFamily: 'Montserrat',
        transition: 'all 0.3s ease',
        '&:focus': {
            outline: 'none',
            border: '1px solid rgba(255, 255, 255, 1)',
            fontSize: '13px',
        },
        '&::placeholder': {
            color: '#969BAD',
            fontSize: '13px',
            transition: 'all 0.3s ease',
        },
        '&:focus::placeholder': {
            color: '#969BAD',
            fontSize: '13px',
        },
        '&:hover': {
            border: '1px solid rgba(255, 255, 255, 0.5)',
        },
        '&:-internal-autofill-selected': {
            fontSize: '13px !important',
            transition: 'background-color 5000s ease-in-out 0s',
            boxShadow: '0 0 0px 1000px transparent inset !important',
            WebkitTextFillColor: '#fff !important',
        },
        [theme.breakpoints.down('xl')]: {
            height: '4vw',
            lineHeight: '3vw',
            fontSize: '1.4vw',
            borderRadius: '0.6vw',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            '&::placeholder': {
                fontSize: 13,
                lineHeight: "2.5vw",
            },
        },
        [theme.breakpoints.down('lg')]: {
            height: '5vw',
            lineHeight: '3vw',
            fontSize: '1.4vw',
            borderRadius: '0.6vw',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            '&::placeholder': {
                fontSize: 13,
                lineHeight: "2.5vw",
            },
        },
        [theme.breakpoints.down('md')]: {
            height: '7.5vw',
            lineHeight: '4vw',
            fontSize: '2vw',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: '0.8vw',
            '&::placeholder': {
                fontSize: 12,
                lineHeight: "3vw",
            },
        },
        [theme.breakpoints.down('sm')]: {
            height: '10vw',
            lineHeight: '5vw',
            fontSize: '3vw',
            backgroundColor: 'transparent',
            borderRadius: '2vw',
            '&::placeholder': {
                fontSize: 10,
                lineHeight: "4vw",
            },
        },
        [theme.breakpoints.down('xs')]: {
            height: '7vw',
            lineHeight: '6vw',
            fontSize: '2vw',
            borderRadius: '1.2vw',
            '&::placeholder': {
                fontSize: 10,
                lineHeight: "5vw",
            },
        },
    },

    inputError: {
        border: '1px solid #EB5757',
    },
    hidePasswordBtn: {
        position: 'absolute',
        right: '12px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '24px',
        height: '24px',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: 17,
            height: 17,
            opacity: 0.5,
            transition: 'opacity 0.3s ease',
        },
        '&:hover img': {
            opacity: 0.8,
        },
    },

    inputAutoFillOverride: {
        '&:-internal-autofill-selected': {
            appearance: 'menulist-button !important',
            backgroundImage: 'none !important',
            backgroundColor: 'transparent !important',
            color: 'inherit !important',
        },
    },

    outlinedLabel: {
        color: "#969BAD",
        fontFamily: 'Montserrat',
        fontSize: 11,
        fontWeight: 700,
        lineHeight: '0.8vw',
        transition: 'all 0.2s',
        width: '100%',
        paddingBottom: '0.2vw',
        letterSpacing: '0.05em',
        [theme.breakpoints.down('lg')]: {
            fontSize: 11,
            margin: '1vw 0 0.8vw 0',
            lineHeight: '0.2w',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 11,
            margin: '1vw 0 1.5vw 0',
            lineHeight: '0.2w',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            margin: '1vw 0 2vw 0',
            lineHeight: '0.2w',
        },
    },

    passwordHint: {
        color: '#ffffff',
        fontFamily: 'Montserrat',
        fontSize: '0.62vw',
        paddingTop: '0.15vw',
        [theme.breakpoints.down('lg')]: {
            fontSize: 8,
            margin: '0.8vw 0 2vw 0',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 10,
            margin: '0.8vw 0 2vw 0',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            margin: '1vw 0 2vw 0',
        },
    },

    socialIcon: {
        width: '1.5vw',
        height: '1.5vw',
        objectFit: 'cover',
        [theme.breakpoints.down(3500)]: {
            
        },
        [theme.breakpoints.down('lg')]: {
            width: '2vw',
            height: '2vw',
        },
        [theme.breakpoints.down('md')]: {
            width: '3vw',
            height: '3vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '5vw',
            height: '5vw',
        },
    },

    socialRegister: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        gap: '1vw',
    },

    socialRegisterBtn: {
        background: 'rgba(255, 255, 255, 0.05)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        borderRadius: '0.4vw',
        padding: '0.4em 4em',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 1,
        width: '100%',
        height: '100%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            padding: '0.7em 4em',
            borderRadius: '0.7vw',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '1em 3.8em',
            borderRadius: '1vw',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            padding: '0.7em 3.8em',
            borderRadius: '0.7vw',
        },
        [theme.breakpoints.between(400, 600)]: {
            padding: '0.7em 2.5em',
            borderRadius: '0.7vw',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0.8em 3em',
            borderRadius: '2vw',
        },
    },

    lineBox: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#999999',
        fontSize: 14,
        margin: '1vw 0',
        width: '100%',
        '&::before, &::after': {
            content: '""',
            flexGrow: 1,
            height: '1px',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
        '&::before': {
            margin: '0 1vw 0 0',
            width: '12vw',
            [theme.breakpoints.down('md')]: {
                width: "26vw",
            },
            [theme.breakpoints.down('sm')]: {
                width: "38vw",
            },
        },
        '&::after': {
            margin: '0 0 0 1vw',
            width: '12vw',
            [theme.breakpoints.down('md')]: {
                width: "26vw",
            },
            [theme.breakpoints.down('sm')]: {
                width: "38vw",
            },
        },
        [theme.breakpoints.down('md')]: {
            margin: '3vw 0',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '3vw 0',
        },
    },

    lineContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    logInBlock: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '1vw',
        '& p': {
            margin: 0,
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: '0.8vw',
            lineHeight: '2vw',
            color: '#ffffff',
            [theme.breakpoints.down('xl')]: {
                fontSize: '0.9vw',
                fontWeight: 300,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.1vw',
                fontWeight: 300,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '2vw',
                fontWeight: 300,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '3.5vw',
                fontWeight: 300,
            },
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '4vw',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '4vw 0',
        },
    },
    logIn: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '#00BA6C',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700,
        fontSize: '0.8vw',
        lineHeight: '2vw',
        cursor: 'pointer',
        [theme.breakpoints.down('xl')]: {
            fontSize: '0.9vw',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.1vw',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.5vw',

        },
    },

    registerBtn: {
        width: '100%',
        height: 40,
        backgroundColor: '#00BA6C',
        borderRadius: 5,
        border: 'none',
        color: '#11141C',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '2vw',
        cursor: 'pointer',
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.5vw',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '3w',
            height: 40,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.5vw',
            height: 35,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.5vw',

        },
    },

    passwordInputContainer: {
        position: 'relative',
        height: '48px', 
    },
    iconButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '0.25rem',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    icon: {
        color: '#fff',
    },

    button: {
        backgroundColor: '#00BA6C',
        padding: '0.75vw 0.5vw',
        fontSize: '1vw',
    },

    closeIcon: {
        cursor: "pointer",
        width: "0.9vw",
        height: "0.9vw",
        [theme.breakpoints.down('lg')]: {
            width: "1.5vw",
            height: "1.5vw",
        },
        [theme.breakpoints.down('md')]: {
            width: "2vw",
            height: "2vw",
        },
        [theme.breakpoints.down('sm')]: {
            width: "3vw",
            height: "3vw",
        },
        [theme.breakpoints.down('xs')]: {
            width: "3vw",
            height: "3vw",
            marginBottom: '5vw',
        },
    },
    inputSelectContainer: {
        marginBottom: '1.4vw',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '4vw',
        },
    },
    selectInput: {
        backgroundColor: 'transparent',
        borderRadius: '0.4vw',
        color: 'rgba(255, 255, 255, 0.5)',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontFamily: 'Montserrat',
        fontWeight: 700, 
        letterSpacing: '0.02vw',
        height: '3vw',
        lineHeight: '2.5vw',
        fontSize: '0.8vw',
        textTransform: 'uppercase', 
        '& input': {
            fontWeight: 700, 
            textTransform: 'uppercase', 
            '&::placeholder': {
                textTransform: 'uppercase',
                fontWeight: 700,
                opacity: 1, 
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(255, 255, 255, 1)',
                borderWidth: '1px',
            },
        },
        '&:focus-within': {
            border: '1px solid rgba(255, 255, 255, 1)',
        },
        [theme.breakpoints.down('xl')]: {
            height: '5vw',
            lineHeight: '3vw',
            fontSize: '1vw',
            borderRadius: '0.6vw',
        },
        [theme.breakpoints.down('lg')]: {
            height: '6vw',
            lineHeight: '3vw',
            fontSize: '1vw',
            borderRadius: '0.6vw',
        },
        [theme.breakpoints.down('md')]: {
            height: '4w',
            lineHeight: '4vw',
            fontSize: '1.2vw',
            borderRadius: '0.8vw',
        },
        [theme.breakpoints.down('sm')]: {
            height: '10vw',
            lineHeight: '6vw',
            fontSize: '3vw',
            borderRadius: '2vw',
        },
        [theme.breakpoints.down('xs')]: {
            height: '8vw',
            lineHeight: '8vw',
            fontSize: '4vw',
            borderRadius: '2.5vw',
        },
    },

    popupIndicator: {
        color: '#00BA6C',
        width: '1.1vw',
        height: '1.1vw',
        transition: 'transform 0.2s ease-in-out',
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 

        '&.MuiAutocomplete-popupIndicatorOpen': { 
            transform: 'rotate(180deg)', 
        },

        [theme.breakpoints.down('lg')]: {
            width: '3.4vw',
            height: '2.1vw',
        },
        [theme.breakpoints.down('md')]: {
            width: '3.4vw',
            height: '2.1vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '3.4vw',
            height: '6.1vw',
        },
        [theme.breakpoints.down('xs')]: {
            width: '1.1vw',
            height: '1.1vw',
        },
    },
    arrowIconContainer: {
        position: 'absolute',
        right: '1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '17px',
        height: '17px',
        pointerEvents: 'none',
        [theme.breakpoints.down('lg')]: {
            width: '2vw',
            height: '2vw',
        },
        [theme.breakpoints.down('md')]: {
            width: '2.5vw',
            height: '2.5vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '3vw',
            height: '3vw',
            right: '2vw',
        },
        [theme.breakpoints.down('xs')]: {
            width: '4vw',
            height: '4vw',
            right: '2.5vw',
        },
    },
    arrowIcon: {
        transition: 'transform 0.2s ease-in-out',
        width: '100%',
        height: '100%',
    },
    arrowIconOpen: {
        transform: 'rotate(180deg)',
    },
    dropdownOption: {
        backgroundColor: '#363434',
        color: '#FFFFFF',
        padding: '0.5rem 1rem',
        fontFamily: 'Montserrat',
        fontSize: '0.8vw',
        fontWeight: 200,
        letterSpacing: '0.02vw',
        transition: 'all 0.2s ease-in-out',
        opacity: 1,
        '&:hover': {
            backgroundColor: 'rgba(0, 186, 108, 0.1) !important',
            color: '#FFFFFF',
            opacity: 1,
        },
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent !important',
            color: '#00BA6C',
            fontWeight: 700,
            '& .MuiChip-deleteIcon': {
                display: 'none',
            },
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '3vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '4vw',
        },
    },

    dropdownOptionDisabled: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        color: 'rgba(255, 255, 255)',
        fontFamily: 'Montserrat',
        fontSize: '0.8vw',
        fontWeight: 200,
        letterSpacing: '0.02vw',
        cursor: 'not-allowed',
        opacity: 1,
        [theme.breakpoints.down('lg')]: {
            fontSize: '1vw',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '3vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '3vw',
        },
    },

    noScrollbar: {
        '& .MuiAutocomplete-listbox': {
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '-ms-overflow-style': 'none',  
            'scrollbar-width': 'none', 
        },
    },

    placeholderStyles: {
        "&::placeholder": {
            color: "#969BAD",
            fontSize: "0.75rem",
            fontWeight: 700,
            lineHeight: "2vw",
            textTransform: "uppercase",
            letterSpacing: "0.05em",
            padding: "0 !important",
            
        },
    },
    inputStyles: {
        padding: "0 1vw 0 0.5vw!important",
        display: "flex",
        alignItems: "center",
        "&::placeholder": {
            padding: "0 !important",
        },
    },
    dropdownPaper: {
        backgroundColor: '#363434',
        boxShadow: 'none',
        border: '3px solid #FFFFFF',
        borderRadius: '4px',
        overflowY: 'hidden',
    },

    googleButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        flexGrow: 1,
        width: '7vw',
        [theme.breakpoints.down(3500)]: {
            width: '4vw',
        },
    },

    googleButtonOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.001,
        zIndex: 2,
        width: '100%',
        height: '100%',
    },

    googleButtonInner: {
        width: '100%',
        height: '100%',
    },

    appleButtonWrapper: {
        position: 'relative',
        flexGrow: 1,
        width: '7vw',
        [theme.breakpoints.down(3500)]: {
            width: '4vw',
        },
    },

    phoneButtonWrapper: {
        position: 'relative',
        flexGrow: 1,
        width: '7vw',
        [theme.breakpoints.down(3500)]: {
            width: '4vw',
        },
    },

    facebookButtonWrapper: {
        position: 'relative',
        cursor: 'pointer',
        flexGrow: 1,
        width: '7vw',
        [theme.breakpoints.down(3500)]: {
            width: '4vw',
        },
    },

    facebookLoginButton: {
        opacity: 0.001,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        width: '100%',
        height: '100%',
    },

    clearIcon: {
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
    },

    fieldContainer: {
        position: 'relative',
        marginBottom: '22px',
    },
});
