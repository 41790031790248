import { IGameMessage, IGameMoveMessage, IPiece } from "../types";
import { ColorVariant } from "../../models";

import FenHelper, { DiceStatus, IDiceItemDetails } from "./helper";
import { ICommunicationHistoryNotification } from "../types";
class FenParser {
    helper: typeof FenHelper;

    constructor(helper: typeof FenHelper) {
        this.helper = helper;
    }

    replaceNumberWithDashes(str: string) {
        const numSpaces = parseInt(str);
        let newStr = "";
        for (let i = 0; i < numSpaces; i++) {
            newStr += "-";
        }
        return newStr;
    }

    getBoardLocation(horizontal: number, vertical: number) {
        return this.helper.rowToLetter(vertical) + horizontal;
    }

    parseFen(fen: string) {
        const board = {};
        const fenParts = fen
            .replace(/^\s*/, "")
            .replace(/\s*$/, "")
            .split(/\/|\s/);
        let location = "";
        let piece = "";
        let color = "";
        let part = 0;
        for (let j = 8; j > 0; j--) {
            const row = fenParts[part].replace(
                /\d/g,
                this.replaceNumberWithDashes
            );
            part++;

            let subpart = 0;
            for (let k = 1; k <= 8; k++) {
                piece = row.substring(subpart, subpart + 1);
                subpart++;
                if (piece !== "-") {
                    location = this.getBoardLocation(j, k);

                    color = this.helper.getPieceColor(piece);

                    board[location] = {
                        color: color,
                        piece: this.helper.letterToName(piece.toLowerCase()),
                    };
                }
            }
        }

        return board;
    }

    // TODO: add types
    clone(obj: any) {
        const copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }

    // TODO: add types
    getAllKeys(boardOld: any, boardNew: any) {
        const board = this.clone(boardOld);
        for (const pos in boardNew) {
            if (boardNew.hasOwnProperty(pos)) {
                if (!(pos in board)) {
                    board[pos] = boardNew[pos];
                }
            }
        }

        return board;
    }

    pieceToString(piece: IPiece): string {
        return piece.piece + "_" + piece.color;
    }

    stringToPiece(v: string): IPiece {
        const pieces = v.split("_");

        return {
            piece: pieces[0],
            color: pieces[1] as ColorVariant,
        };
    }

    isEqual(pieceA: IPiece, pieceB: IPiece) {
        return pieceA.color === pieceB.color && pieceA.piece === pieceB.piece;
    }

    diff(fenOld: string, fenNew: string) {
        const boardOld = this.parseFen(fenOld);
        const boardNew = this.parseFen(fenNew);
        const actions: {
            diapered: { [pieceId: string]: string[] };
            appeared: { [pieceId: string]: IPiece };
            changed: { [pieceId: string]: {} };
            replaced: { [pieceId: string]: {} };
        } = {
            diapered: {},
            appeared: {},
            changed: {},
            replaced: {},
        };

        const boardPrototype = this.getAllKeys(boardOld, boardNew);
        for (let position in boardPrototype) {
            if (!boardOld[position] && boardNew[position]) {
                actions.appeared[position] = boardNew[position];
            } else if (boardOld[position] && !boardNew[position]) {
                if (!actions.diapered[this.pieceToString(boardOld[position])]) {
                    actions.diapered[this.pieceToString(boardOld[position])] =
                        [];
                }
                actions.diapered[this.pieceToString(boardOld[position])].push(
                    position
                );
            } else if (!this.isEqual(boardNew[position], boardOld[position])) {
                actions.appeared[position] = boardNew[position];
                if (!actions.diapered[this.pieceToString(boardOld[position])]) {
                    actions.diapered[this.pieceToString(boardOld[position])] =
                        [];
                }
                actions.diapered[this.pieceToString(boardOld[position])].push(
                    position
                );
            }
        }

        const moves: string[] = [];
        const creates: {
            position: string;
            piece: string;
            color: ColorVariant;
        }[] = [];
        const removes: string[] = [];

        for (const appeared in actions.appeared) {
            if (actions.appeared.hasOwnProperty(appeared)) {
                const newPiece = actions.appeared[appeared];
                const candidates =
                    actions.diapered[this.pieceToString(newPiece)];
                const t = this.pieceToString(newPiece);

                if (candidates && candidates.length > 0) {
                    const fromField = candidates[0];
                    moves.push(`${fromField}${appeared}`);
                    candidates.splice(0, 1);
                } else {
                    creates.push({
                        position: appeared,
                        piece: newPiece.piece,
                        color: this.helper.colorCodeToWord(newPiece.color),
                    });
                }
            }
        }
        for (const figureCombined in actions.diapered) {
            const positions = actions.diapered[figureCombined];
            for (let k = 0; k < positions.length; k++) {
                removes.push(positions[k]);
            }
        }

        return {
            moves: moves,
            creates: creates,
            removes: removes,
        };
    }

    getKilledPieces(realHistory: string[], currentFen: string) {
        const killed = {
            w: {
                pawn: 0,
                knight: 0,
                bishop: 0,
                rook: 0,
                queen: 0,
                king: 0,
            },
            b: {
                pawn: 0,
                knight: 0,
                bishop: 0,
                rook: 0,
                queen: 0,
                king: 0,
            },
        };
        let lastFen: string;

        const history = realHistory.slice(0);
        history.push(currentFen);

        lastFen = history[0];
        for (let i = 1; i < history.length; i++) {
            const changes = this.diff(lastFen, history[i]);
            if (changes.removes.length > 0) {
                const lastFenPositions = this.parseFen(lastFen);
                const currentFenPositions = this.parseFen(history[i]);

                for (let p = 0; p < changes.removes.length; p++) {
                    const currentChange = changes.removes[p];
                    const piece = lastFenPositions[currentChange];
                    if (
                        (piece.piece !== "pawn" && currentChange[1] !== "7") ||
                        (piece.piece !== "pawn" && currentChange[1] !== "2")
                    ) {
                        killed[piece.color][piece.piece] =
                            killed[piece.color][piece.piece] + 1;

                        // else means that pawn desapears (killed or promotion)
                    } else {
                        // if old piece place not filled than it means that piece is killed (not promotion)
                        if (currentFenPositions[currentChange] !== undefined) {
                            killed[piece.color][piece.piece] =
                                killed[piece.color][piece.piece] + 1;
                        }

                        // don't count removed promoted pawn (do nothing)
                    }
                }
            }
            lastFen = history[i];
        }

        const { w, b } = killed;

        return {
            [ColorVariant.white]: {
                P: w.pawn,
                N: w.knight,
                B: w.bishop,
                R: w.rook,
                Q: w.queen,
                K: w.king,
            },
            [ColorVariant.black]: {
                P: b.pawn,
                N: b.knight,
                B: b.bishop,
                R: b.rook,
                Q: b.queen,
                K: b.king,
            },
        };
    }

    getDice(fen: string): number[] {
        const parts = fen.split(" ");
        const figures = parts[4];
        const dice: number[] = [];

        if (figures !== "-") {
            const moves = parts[4].length;
            const peaces = parts[4].split("");

            for (let i = 0; i < moves; i++) {
                dice.push(this.helper.letterToNumber(peaces[i]));
            }
        }
        return dice;
    }

    getDiceDetail(fen: string, history: string[]) {
        const dice = this.getDice(fen);
        let full = dice.slice(0);

        let i = history.length - 1;
        // Change i > 0 to i >= 0 for fix empty figures while we roll first time
        // while (i > 0 && full.length !== 3) {
        while (i >= 0 && full.length !== 3) {
            const prevDice = this.getDice(history[i]);
            if (prevDice.length === 3) {
                full = prevDice;
                break;
            }
            i--;
        }

        const response: IDiceItemDetails[] = [];
        let index = 0;

        const length = full.length;
        for (let k = 0; k < length; k++) {
            let singleDice: IDiceItemDetails = {
                figure: full[k],
                active: true,
                status: DiceStatus.active,
            };
            index = dice.indexOf(full[k]);
            if (index === -1) {
                singleDice.active = false;
                singleDice.status = DiceStatus.inactive;
            } else {
                dice.splice(index, 1);
            }

            response.push(singleDice);
        }

        if (response.length === 0) {
            return this.helper.diceArrayToObject([0, 0, 0], false);
        }

        return response;
    }

    getFenData(fen: string) {
        const parts = fen.split(" ");

        let moves = 0;
        const dice = [0, 0, 0];

        if (parts[4] !== "-") {
            moves = parts[4].length;
            const peaces = parts[4].split("");

            for (let i = 0; i < moves; i++) {
                dice[i] = this.helper.letterToNumber(peaces[i]);
            }
        }

        return {
            turn: parts[1],
            castling: parts[2],
            en_passant: parts[3],
            legal_pieces: parts[4],
            moves: moves,
            dice: dice,
            rolled: !(parts[4] === "-" || parts[4].length < 1),
            toss: parts[1] === "-" || parts[1].length < 1,
        };
    }

    getColor(fen: string) {
        const parts = fen.split(" ");
        return parts[1];
    }

    getLastDices(fenHistory: string[]) {
        // 1. Забираем фен из стейта, он туда кладется отсюда
        // 2. Каждый ход в фене у нас накапливаются сведению о доске, ходах, и фигурах, которые инитились для мува
        const lastDicesFen = [...fenHistory].reverse().find((fen) => {
            const [, , , , dices] = fen.split(" ");
            // 3. Тут мы забираем Именно те фигуры , которые у нас были при броске костей
            // Это всегда 3 фигуры, потому что мы используем метод find для поиска по массиву того содержимого фен, которе соответствует этому условию
            // 4. Вот тут как раз и бага. когда мы первый раз бросили кости у нас выпало например, и это все есть в массиве фен:
            // 1: "rn2kbnr/ppp1pppp/3qb3/3p4/8/4P3/PPPP1PPP/RNBQKBNR w KQkq - PBN 1655803594701"
            // 2: "rn2kbnr/ppp1pppp/3qb3/3p4/8/4P2N/PPPP1PPP/RNBQKB1R w KQkq - PB 1655803685982"
            // 3: "rn2kbnr/ppp1pppp/3qb3/3p4/8/4P2N/PPPP1PPP/RNBQKB1R w KQkq - P 1655803685982"
            //Так как мы прописали мин условие для посика 3, то нам всегда будет возвращатсья фен, с QBN
            // 5. НО когда мы меняем фигуру, к примеру на Q нам все еще возвращается фен старый, и оно не меняет фигуру, так как длинна после ходу не 3

            // То есть по идее должно быть так.
            // Начальный ФЕН - 1: "rn2kbnr/ppp1pppp/3qb3/3p4/8/4P3/PPPP1PPP/RNBQKBNR w KQkq - PBN 1655803594701"
            // Хожу фигурой B
            // 2: "rn2kbnr/ppp1pppp/3qb3/3p4/8/4P2N/PPPP1PPP/RNBQKB1R w KQkq - PN 1655803685982"
            //Делаю замену пешки на Q и инициирую новый фен с длиной dices === 3
            // "rn2kbnr/ppp1pppp/3qb3/3p4/8/4P3/PPPP1PPP/RNBQKBNR w KQkq - QBN 1655803594701"
            // И сразу же чтобы не было багов
            // "rn2kbnr/ppp1pppp/3qb3/3p4/8/4P3/PPPP1PPP/RNBQKBNR w KQkq - QN 1655803594701"
            //... оставшиеся ходы
            return dices.length === 3;
        });

        if (!lastDicesFen) return [1, 1, 1];

        const [, , , , dices] = lastDicesFen.split(" ");

        // Ниже мы отдаем это все в хелпер которые преобразует это все в цифры
        return dices.split("").map((l) => this.helper.letterToNumber(l));
    }

    mergeChatHistoryMessages(
        messages: IGameMessage[] = [],
        newMessages: IGameMessage[] = []
    ) {
        return messages.concat(...newMessages).sort((a, b) => a.time - b.time);
    }
    //========
    mergeChatHistoryMessagesNew(
        messages: ICommunicationHistoryNotification[] = [],
        newMessages: ICommunicationHistoryNotification[] = []
    ) {
        return messages.concat(...newMessages).sort((a, b) => a.time - b.time);
    }

    getTime(fen: string): number {
        const parts = fen.split(" ");
        return parseInt(parts[5]) || Date.now();
    }

    pushToDiceMessages(
        messages: IGameMoveMessage[] = [],
        leftColor = ColorVariant.white,
        currentFen: string,
        previousFen: string
    ) {
        let [lastMove, ...rest] = messages.reverse();
        const moves = rest.reverse();
        const color = this.helper.colorCodeToWord(this.getColor(currentFen));

        const dices = this.getDice(currentFen);
        const diff = this.diff(previousFen, currentFen);
        const parsed = this.parseFen(previousFen);

        if (dices.length === 3) {
            if (lastMove) {
                moves.push({
                    ...lastMove,
                    pieces: lastMove.pieces.map((move) => ({
                        ...move,
                        moved: true,
                    })),
                });
            }

            lastMove = {
                color,
                pieces: dices.map((dice) => ({
                    dice,
                    type: this.helper.numberToLetter(dice),
                    moved: false,
                    move: "",
                })),
                time: this.getTime(currentFen),
                position: color === leftColor ? "ltr" : "rtl",
            };
        } else if (lastMove && parsed) {
            const [firstMove] = diff.moves;

            if (firstMove && diff.moves.length === 1) {
                const humanizedMove = `${firstMove}`.replace(
                    /^(.{2})(.{2})$/,
                    "$1 - $2"
                );
                const [from, to] = `${humanizedMove}`.split(" - ");
                const currentDice = this.helper.letterToNumber(
                    this.helper.nameToLetter(parsed[from].piece, color)
                );

                lastMove.pieces.find((move) => {
                    if (!move.moved && move.dice === currentDice) {
                        move.moved = true;

                        if (diff.moves.length) {
                            move.move = `${from} - ${to}`;
                        }

                        return true;
                    }
                });
            } else if (diff.moves.length === 2) {
                // castling
                const kingMove = diff.moves.find((move) => move[0] === "e");
                const kingMoveIndex = diff.moves.findIndex(
                    (move) => move[0] === "e"
                );
                // getting another move
                // if kingMoveIndex 0 -> rookMove is 1
                // if kingMoveIndex 1 -> roomMove is 0
                const rookMove = diff.moves[(kingMoveIndex + 1) % 2];

                let isLongCastling = true;
                lastMove.castling = "long";
                if (kingMove) {
                    if (kingMove[2] === "g") {
                        // O-O
                        isLongCastling = false;
                        lastMove.castling = "short";
                    }
                    const humanizedMoveKing = `${kingMove}`.replace(
                        /^(.{2})(.{2})$/,
                        "$1 - $2"
                    );
                    const humanizedMoveRook = `${rookMove}`.replace(
                        /^(.{2})(.{2})$/,
                        "$1 - $2"
                    );
                    const [fromKing] = `${humanizedMoveKing}`.split(" - ");
                    const [fromRook] = `${humanizedMoveRook}`.split(" - ");
                    const kingDice = this.helper.letterToNumber(
                        this.helper.nameToLetter(parsed[fromKing].piece, color)
                    );

                    const rookDice = this.helper.letterToNumber(
                        this.helper.nameToLetter(parsed[fromRook].piece, color)
                    );

                    lastMove.pieces.forEach((move) => {
                        if (
                            !move.moved &&
                            [kingDice, rookDice].indexOf(move.dice) >= 0
                        ) {
                            move.moved = true;

                            if (diff.moves.length) {
                                move.move = isLongCastling ? "O-O-O" : "O-O";
                            }
                        }
                    });
                }
            }
        }

        if (lastMove) {
            moves.push(lastMove);
        }

        return moves;
    }

    convertFenToMoveMessages(
        history: string[] = [],
        leftColor = ColorVariant.white
    ) {
        let moves: IGameMoveMessage[] = [];
        const [firstFen, ...fens] = history;
        const prevHistory = [firstFen];

        fens.reduce((previous, current) => {
            moves = this.pushToDiceMessages(
                moves,
                leftColor,
                current,
                previous
            );

            const a = this.getDiceDetail(current, prevHistory);

            prevHistory.push(current);

            return current || a;
        }, firstFen);

        return moves;
    }

    diceMessagesFromHistory(
        history = [],
        leftColor = ColorVariant.white,
        notifications = [],
        finished = false
    ) {
        const moves = this.convertFenToMoveMessages(history, leftColor);

        if (finished && moves[moves.length - 1]) {
            const lastMove = moves[moves.length - 1];

            lastMove.pieces.forEach((piece) => (piece.moved = true));
        }

        return this.mergeChatHistoryMessages(
            moves,
            notifications
        ) as IGameMoveMessage[];
    }

    getWhoLastRollsColor(history: string[] = []): ColorVariant | null {
        const moves = this.convertFenToMoveMessages(history, ColorVariant.none);

        const lastMove = moves[moves.length - 1];

        return lastMove?.color ?? null;
    }
}

export default FenParser;
