import React from 'react';
import { useStyles } from '../styles';
import useStores from 'src/hooks/useStores';
import cn from 'classnames';

import sunrays from '../images/sunrays.svg';
import coinsGroup from '../images/coinsGroup.svg';
import coinFull from '../images/coinsFull.png';
import sunshine from '../images/sunshine.png';
import sunraysRotate from '../images/sunraysRotate.png';

type IAvatarAnimation = {
    winner?: string;
    userColor?: string;
    isWon?: boolean;
    viewMode?: boolean;
    isChessBoard?: boolean;
    isSystemAvatar?: boolean;
};

function AvatarAnimation({
    winner,
    userColor,
    isWon,
    viewMode,
    isChessBoard,
    isSystemAvatar,
}: IAvatarAnimation) {
    const styles = useStyles({});
    const { gameStore } = useStores();
    const colorGet = gameStore?.gameState?.getMyColor();

    return (
        <>
            {viewMode && winner ? (
                <div className={styles.winnerAnimated}>
                    <div
                        className={cn(styles.avatarBorderInside, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <div
                        className={cn(styles.avatarBorderImageMid, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <img src={sunrays} className={styles.sunrays} />
                    <img src={sunshine} className={styles.sunshine} />
                    <img src={coinsGroup} className={styles.coinsGroup} />
                    <img src={coinFull} className={styles.coinFull} />
                    <img src={sunraysRotate} className={styles.sunraysRotate} />
                    <img src={sunshine} className={styles.sunshineRotate} />
                </div>
            ) : !viewMode && winner === userColor && userColor === colorGet ? (
                <div className={styles.winnerAnimated}>
                    <div
                        className={cn(styles.avatarBorderInside, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <div
                        className={cn(styles.avatarBorderImageMid, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <img src={sunrays} className={styles.sunrays} />
                    <img src={sunshine} className={styles.sunshine} />
                    <img src={coinsGroup} className={styles.coinsGroup} />
                    <img src={coinFull} className={styles.coinFull} />
                    <img src={sunraysRotate} className={styles.sunraysRotate} />
                    <img src={sunshine} className={styles.sunshineRotate} />
                </div>
            ) : isWon ? (
                <div className={styles.winnerAnimatedRight}>
                    <div
                        className={cn(styles.avatarBorderInside, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <div
                        className={cn(styles.avatarBorderImageMid, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <img src={sunrays} className={styles.sunraysRight} />
                    <img src={sunrays} className={styles.sunraysRightDouble} />
                    <img src={sunshine} className={styles.sunshineRight} />
                </div>
            ) : (
                <div className={styles.winnerAnimated}>
                    <div
                        className={cn(styles.avatarBorderInside, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <div
                        className={cn(styles.avatarBorderImageMid, {
                            isChessBoard: isChessBoard,
                            isSystemAvatar: isSystemAvatar,
                        })}
                    ></div>
                    <img src={sunrays} className={styles.sunrays} />
                    <img src={sunshine} className={styles.sunshine} />
                    <img src={sunraysRotate} className={styles.sunraysRotate} />
                    <img src={sunshine} className={styles.sunshineRotate} />
                </div>
            )}
        </>
    );
}

export default AvatarAnimation;
