import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

// Конь
const WhiteDice = ({ width, height }) => {
    return (
        <svg
            id='b-05_svg__Layer_1'
            x={0}
            y={0}
            viewBox='0 0 70 70'
            xmlSpace='preserve'
            width={width}
            height={height}
        >
            <style>{'.b-05_svg__st9{fill:#d4d1b4}'}</style>
            <linearGradient
                id='b-05_svg__SVGID_1_'
                gradientUnits='userSpaceOnUse'
                x1={35}
                y1={69.5}
                x2={35}
                y2={0.5}
            >
                <stop offset={0} stopColor='#bcc5c7' />
                <stop offset={0.65} stopColor='#e6e8e9' />
            </linearGradient>
            <path
                d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
                fill='url(#b-05_svg__SVGID_1_)'
            />
            <path
                d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
                fill='#fff'
            />
            <g>
                <linearGradient
                    id='b-05_svg__SVGID_2_'
                    gradientUnits='userSpaceOnUse'
                    x1={11.188}
                    y1={36.02}
                    x2={58.813}
                    y2={36.02}
                >
                    <stop offset={0} stopColor='#3a3a3f' />
                    <stop offset={1} stopColor='#040408' />
                </linearGradient>
                <path
                    d='M11.6 40.5s6.1-10.1 6.1-10.8 0-3.4.7-4.7 2.7-3.4 2.7-3.4v-2s-1.3-6.1-.7-6.7 6.1 2.7 6.7 4c0 0 1.3-2.7 2.7-3.4s4 4.7 4 4.7 20.9-1.3 23.6 22.3c0 0 1.3 11.5 1.3 18.9H24.4v-4s0-3.4 6.1-8.8 4.7-8.8 4.7-8.8-4 2.7-8.1 3.4l-2 2s-.7 6.1-6.1 5.4c0 0-1.3-1.3-2.7 0 0 0-6.8-1.4-4.7-8.1z'
                    fill='url(#b-05_svg__SVGID_2_)'
                />
                <linearGradient
                    id='b-05_svg__SVGID_3_'
                    gradientUnits='userSpaceOnUse'
                    x1={34.999}
                    y1={60.849}
                    x2={34.999}
                    y2={11.19}
                >
                    <stop offset={0.001} stopColor='#fff' />
                    <stop offset={1} stopColor='#a4b0b2' />
                </linearGradient>
                <path
                    d='M20.6 12.7c1.3 0 5.8 2.9 6.5 4.1 0 0 1.3-2.7 2.7-3.4.1 0 .2-.1.3-.1 1.4 0 3.8 4.8 3.8 4.8h.6c3.5 0 20.6 1.1 23 22.3 0 0 1.3 11.5 1.3 18.9H24.4v-4s0-3.4 6.1-8.8 4.7-8.8 4.7-8.8-4 2.7-8.1 3.4l-2 2s-.6 5.5-5.3 5.5c-.3 0-.5 0-.8-.1 0 0-.6-.6-1.4-.6-.4 0-.8.1-1.3.6 0 0-6.7-1.3-4.7-8.1 0 0 6.1-10.1 6.1-10.8 0-.7 0-3.4.7-4.7.7-1.3 2.7-3.4 2.7-3.4v-2s-1.3-6.1-.7-6.7c0-.1.1-.1.2-.1m0-1.5c-.7 0-1.1.3-1.3.5-.5.5-1.2 1.2.2 8V21c-.7.7-2 2.2-2.5 3.3-.5 1.1-.8 2.8-.8 5.2-.5 1.1-3.3 6-5.9 10.3l-.1.2h-.1c-.7 2.4-.6 4.6.5 6.4 1.7 2.9 5.2 3.6 5.4 3.6l.8.2.6-.6.2-.2c.1 0 .3.1.4.2l.4.4h.5c.3 0 .7.1 1 .1 4.4 0 6.2-3.8 6.7-6.2l1.3-1.3c2.1-.4 4-1.3 5.6-2-.5 1.2-1.6 2.9-3.9 4.9-6.4 5.7-6.6 9.5-6.6 9.9v5.5h37.4v-1.5c0-7.4-1.3-18.6-1.4-19.1-2.5-21.9-20-23.5-24.2-23.6-1.7-3.2-3.3-4.8-4.7-4.8-.3 0-.6.1-.9.2-.9.4-1.7 1.4-2.3 2.3-2-1.6-4.9-3.2-6.3-3.2z'
                    fill='url(#b-05_svg__SVGID_3_)'
                />
                <path
                    className='b-05_svg__st9'
                    d='M34.5 20.2l.7 2s15.5-2 16.9 22.9V58h3.4V45.2c-.1 0-.1-24.3-21-25zM36.5 29s.5 3.2-.8 4.9c-.4.5-.6 1.2-.4 1.9.1.4.3.7.5.7.7 0 2 .7 2-7.4h-1.3zM21 43.2s-.7 2-2 2c0 0 0 2 1.3.7s2.7-2.7.7-2.7zM15.6 40.5s-2 2 .7 2l1.3-1.3c0-.1.7-2.8-2-.7zM25.7 24.3l-4 4s0 2.7 1.3.7h.3c.3.4.8.7 1.3.7.9 0 1.7-.8 1.7-1.7 0-.6-.2-1.4-.7-1.7l.7-.7c.1 0 2.1-1.3-.6-1.3z'
                />
            </g>
        </svg>
    );
};

const GoldDice = ({ width, height }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 54 54'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                width='54'
                height='54'
                rx='7.03677'
                fill='url(#paint0_linear_5445_63194)'
            />
            <circle
                cx='27'
                cy='27'
                r='27'
                fill='url(#paint1_linear_5445_63194)'
                fill-opacity='0.3'
            />
            <g filter='url(#filter0_i_5445_63194)'>
                <path
                    d='M40.163 34.3798C40.4874 38.2526 37.2606 41.4111 33.3743 41.4111H24.0177C21.6028 41.4111 19.7445 39.4108 20.7392 37.2103C22.045 34.3217 24.1959 31.4861 25.348 30.0683C25.6467 29.7008 25.3853 29.1332 24.9117 29.1332C24.7796 29.1332 24.6518 29.1809 24.552 29.2675L23.067 30.5562C22.0939 31.4006 21.5343 32.6306 20.7474 33.6507C20.1125 34.4737 19.1905 35.0639 18.1396 35.2566C16.2265 35.5992 14.2849 34.8283 13.1713 33.2293C13.065 33.0876 13.041 32.9005 13.1079 32.7365L18.221 20.2106C18.3554 19.8813 18.4159 19.5265 18.3981 19.1713L18.2852 16.9116C18.2273 15.7543 19.5448 15.0539 20.4718 15.7492L20.5074 15.7759C21.092 16.2144 21.9214 16.0959 22.3598 15.5113C22.8443 14.8653 23.7894 14.8002 24.3579 15.3736L25.5492 16.5751C26.0267 17.0568 26.6666 17.3398 27.3403 17.4184C36.5506 18.4938 39.4 25.2677 40.163 34.3798Z'
                    fill='#272626'
                />
                <path
                    d='M16.1354 30.9686C16.2311 30.7601 16.4777 30.6688 16.6862 30.7645C16.8946 30.8603 16.9859 31.1069 16.8902 31.3153L16.5434 32.0701C16.4476 32.2785 16.201 32.3699 15.9926 32.2741C15.7842 32.1784 15.6928 31.9318 15.7886 31.7233L16.1354 30.9686Z'
                    fill='#272626'
                />
                <path
                    d='M20.4342 21.7473C20.5426 21.5451 20.7943 21.4691 20.9964 21.5775C21.1986 21.6859 21.2746 21.9376 21.1662 22.1398L19.7924 24.7019C19.3881 24.4852 19.2361 23.9817 19.4529 23.5774L20.4342 21.7473Z'
                    fill='#272626'
                />
                <path
                    d='M20.5774 23.2379C20.9817 23.4546 21.1337 23.9581 20.9169 24.3624C20.7002 24.7667 20.1967 24.9187 19.7924 24.7019C19.3881 24.4852 19.2361 23.9817 19.4529 23.5774C19.6696 23.1731 20.1731 23.0211 20.5774 23.2379Z'
                    fill='#272626'
                />
                <path
                    d='M21.307 37.6733C21.307 37.4439 21.4929 37.2579 21.7223 37.2579H39.1657C39.3951 37.2579 39.581 37.4439 39.581 37.6733C39.581 37.9026 39.3951 38.0886 39.1657 38.0886H21.7223C21.4929 38.0886 21.307 37.9026 21.307 37.6733Z'
                    fill='#272626'
                />
            </g>
            <defs>
                <filter
                    id='filter0_i_5445_63194'
                    x='13.0703'
                    y='14.9819'
                    width='30.1609'
                    height='29.4749'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dx='3.04568' dy='3.04568' />
                    <feGaussianBlur stdDeviation='2.28426' />
                    <feComposite
                        in2='hardAlpha'
                        operator='arithmetic'
                        k2='-1'
                        k3='1'
                    />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='shape'
                        result='effect1_innerShadow_5445_63194'
                    />
                </filter>
                <linearGradient
                    id='paint0_linear_5445_63194'
                    x1='15'
                    y1='50.5'
                    x2='48.9003'
                    y2='16.287'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#8E4A06' />
                    <stop offset='0.484375' stop-color='#DFA863' />
                    <stop offset='1' stop-color='#9F6013' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_5445_63194'
                    x1='57'
                    y1='7'
                    x2='2.43556e-06'
                    y2='44.4194'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#FFF5A6' />
                    <stop offset='1' stop-color='#E5BD66' stop-opacity='0.54' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default function B5({ width, height }) {
    return (
        <SVGUniqueID>
            <WhiteDice width={width} height={height} />
            {/* <GoldDice width={width} height={width} /> */}
        </SVGUniqueID>
    );
}
