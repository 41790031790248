import { makeStyles } from "@mui/styles";

import theme from "src/theme";

export const useStyles = makeStyles({
    lazyImageWrapper: {
        position: "relative",
        display: "flex",
        [theme.breakpoints.down("lg")]: {
            borderRadius: "50%",
        },
    },
    mainImg: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    image: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        margin: 0,
        padding: 0,
        width: "inherit",
        [theme.breakpoints.down("lg")]: {
            aspectRatio: 1 / 1,
        },
    },
});
