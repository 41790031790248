import { makeStyles } from "@mui/styles";
import { appColors } from "src/consts/app-config";
import theme from "src/theme";

export const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        zIndex: 2,
        maxWidth: 320,
        width: "100%",
        padding: "12px 8px",
        justifySelf: "center",
        border: "1px solid transparent",
        borderRadius: "12px",
        background:
            "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 35%) padding-box, linear-gradient(180.21deg, rgb(76, 55, 76) 25%, rgba(145, 116, 157, 0.85) 98%) border-box",
        "@media screen and (min-width: 1251px) and (max-height: 740px)": {
            maxWidth: 350,
        },
        "&.isViewFullScreen": {
            [theme.breakpoints.up("lg")]: {
                padding: "6px",
                border: "1px solid transparent",
                borderRadius: "calc(0.4vmin + 0.4%)",
                // padding: "calc(0.4vmin + 1%)",
                background:
                    "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 35%) padding-box, linear-gradient(180.21deg, rgb(76, 55, 76) 25%, rgba(145, 116, 157, 0.85) 98%) border-box",
                [theme.breakpoints.up("xl")]: {
                    padding: "8px",
                    borderRadius: "14px",
                },
                [theme.breakpoints.up(1800)]: {
                    borderRadius: "16px",
                },
                [theme.breakpoints.up(2400)]: {
                    borderRadius: "calc(1vmin + 1%)",
                },
            },
            [theme.breakpoints.up("xl")]: {
                padding: "6px",
            },
        },
    },

    text: {
        textAlign: "center",
        fontFamily: "Montserrat",
        fontSize: 22,
        fontWeight: 500,
        letterSpacing: "-0.3px",
        color: `${appColors.juiceGreen}`,
        "&.isViewFullScreen": {
            fontSize: "22px",
            textAlign: "center",
            fontWeight: 500,
            [theme.breakpoints.up("xl")]: {
                fontSize: "26px",
                fontWeight: 600,
            },
            [theme.breakpoints.up(1800)]: {
                fontSize: "32px",
            },
            [theme.breakpoints.up(2400)]: {
                fontSize: "40px",
            },
            [theme.breakpoints.up(3200)]: {
                fontSize: "50px",
            },
        },

        // '&.gray': {
        //     color: '#798097',
        // },
        // '&.gold': {
        //     background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        //     '-webkit-background-clip': 'text',
        //     '-webkit-text-fill-color': 'transparent',
        //     backgroundClip: 'text',
        //     'text-fill-color': 'transparent',
        // },
        // '&.green': {
        //     background:
        //         'linear-gradient(19.92deg, #158F80 13.3%, #50BAAE 86.7%)',
        //     '-webkit-background-clip': 'text',
        //     '-webkit-text-fill-color': 'transparent',
        //     backgroundClip: 'text',
        //     'text-fill-color': 'transparent',
        // },
    },
    message: {
        margin: "5px 0 0 0",
    },
});
