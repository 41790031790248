import cn from 'classnames';

import defaultLoaderGif from './assets/king-loading.gif';
import halykLoaderGif from '../../assets/Halyk/halyk_transp_gif.gif';
import { useStyles } from './styles';

export interface ILoaderProps {
    size?: number;
    className?: string;
    isHalyk?: boolean;
}

export const Loader = ({ size, className, isHalyk }: ILoaderProps) => {
    const styles = useStyles();

    const loaderGif = isHalyk ? halykLoaderGif : defaultLoaderGif;


    return (
        <img
            src={loaderGif}
            className={cn(styles.image, className)}
            style={{ width: size, height: size }}
        />
    );
};
