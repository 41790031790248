import React, { useEffect } from 'react';
import { useLocale } from 'src/providers/LocaleProvider';
import { observer } from 'mobx-react';
import useStores from '../../../../hooks/useStores';
import GeneralGameDialogNew from '../common/generalDialogNew';

const WaitingForOpponentToDecideDialog = () => {
    const {
        game: {
            dialogs: {
                draw: {
                    waitForOpponentToDecide: { title },
                },
            },
        },
    } = useLocale();

    const { gameStore } = useStores();
    const player = gameStore.gameState.getCurrentPlayer();

    if (!player) return null;

    return (
        <GeneralGameDialogNew
            avatar={player?.avatar}
            noButtons={true}
            title={title('Thinking...')}
            loadingMessage={true}
            isSystemAvatar={player.isSystemAvatar}
        />
    );
};

export default observer(WaitingForOpponentToDecideDialog);
