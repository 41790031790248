import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import _keyBy from 'lodash/keyBy';

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";

import { useGameProviders } from "../../api";
import type { ExternalGameType, IGameProvider } from "../../model";

import { useStyles } from "./styles";

export interface IProviderSelectProps {
    value: string[];
    onChange: (value: string[]) => void;
    gameType: ExternalGameType;
    isMultiTable?: boolean;
    isSelected?: boolean;
}

export const ProviderSelect = ({
    value,
    onChange,
    gameType,
    isMultiTable,
    isSelected,
}: IProviderSelectProps) => {
    const { providers } = useGameProviders(gameType);
    const appearanceTheme = useChangeTheme();

    const [providersById, setProvidersById] = useState<
        Record<string, IGameProvider>
    >({});
    const [open, setOpen] = useState(false);
    const styles = useStyles();
    const {
        externalGames: {
            filters: { providers: providersLabel },
        },
    } = useLocale();

    useEffect(() => {
        const providersGroupedById = _keyBy(providers, 'id');
        setProvidersById(providersGroupedById);
    }, [providers]);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        const newValue = typeof value === "string" ? value.split(",") : value;
        onChange(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl variant="standard">
            <Select
                labelId="provider-select-label"
                id="provider-select"
                value={value}
                onChange={handleChange}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                displayEmpty
                className={cn(styles.select, appearanceTheme, {
                    open: open || isSelected,
                })}
                inputProps={{
                    classes: {
                        icon: styles.selectIcon,
                        root: styles.selectRoot,
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        className: cn(styles.paper, appearanceTheme),
                    },
                    className: styles.menu,
                }}
                input={
                    <InputBase
                        className={cn(styles.selectInput, appearanceTheme, {
                            isMultiTable,
                        })}
                    />
                }
                renderValue={(selected) => {
                    if (selected.length === 0)
                        return providersLabel("Providers");

                    const labels = selected.map((v) => providersById[v]?.name);
                    return labels.join(", ");
                }}
            >
                {Object.values(providersById).map((provider) => (
                    <MenuItem
                        value={provider.id}
                        className={cn(styles.selectItem, { isMultiTable })}
                    >
                        {provider.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
