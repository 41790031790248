import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

export const useStyles = makeStyles({
    container: {
        minWidth: 200,
        width: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateY(20px)',
        transition: '0.4s ease',
        padding: '10px 40px 12px 18px',
        background:
            'linear-gradient(147.58deg, #D1A750 23.72%, #EACF8D 43.83%, #FFD24B 65.03%)',
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)',
        borderRadius: 11,
        position: 'absolute',
        left: 0,
        top: '100%',
        '&.open': {
            transition: '0.3s ease',
            opacity: 1,
            transform: 'translateY(0px)',
            visibility: 'visible',
        },
        [theme.breakpoints.down('md')]: {
            right: 0,
            left: 'unset',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '2px',
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        color: '#866838',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
    list: {
        // listStylePosition: 'inside',
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 0,
        padding: '0 0 0 7px',
    },
    listItem: {
        fontSize: 16,
        fontWeight: 400,
        color: '#866838',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
    listItemDot: {
        fontSize: 13,
        marginRight: 4,
    },
});
