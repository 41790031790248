import React from "react";

import rocketIcon from "src/assets/icons/rocket.svg";
import bulletPng from "./png_icons/bullet.png";

export const BulletIcon = (): JSX.Element => {
    return (
        <>
            <img src={rocketIcon} alt="bullet" />
        </>
    );
};
