export const canAccessLocalStorage = (): boolean => {
    try {
        const localStorageTestKey = '__testLocalStorage__';
        window.localStorage.setItem(localStorageTestKey, 'test');
        window.localStorage.removeItem(localStorageTestKey);

        return true;
    } catch (e) {
        return false;
    }
};
