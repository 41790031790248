import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
import { ICustomIconProps } from './tournaments/types';

function ModalStatusWrapperMobile({
    onClick,
    width,
    height,
    fillColor,
    fullFillColor,
}: ICustomIconProps) {
    const currnetWidth = width || 324;
    const currnetHeight = height || 76;

    return (
        <SVGUniqueID>
            <svg
                width={currnetWidth}
                height={currnetHeight}
                viewBox='0 0 324 76'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g filter='url(#filter0_i_6484_13125)'>
                    <path
                        d='M0 24C0 10.7452 10.7452 0 24 0H300C313.255 0 324 10.7452 324 24V45V45C324 57.7025 313.703 68 301 68H181.777C179.293 68 176.843 68.5783 174.622 69.6892L163.789 75.1056C162.663 75.6686 161.337 75.6686 160.211 75.1056L149.378 69.6892C147.157 68.5783 144.707 68 142.223 68H23C10.2975 68 0 57.7025 0 45V45V24Z'
                        fill='#212736'
                    />
                </g>
                <defs>
                    <filter
                        id='filter0_i_6484_13125'
                        x='0'
                        y='0'
                        width='324'
                        height='75.5278'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                    >
                        <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                        />
                        <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='BackgroundImageFix'
                            result='shape'
                        />
                        <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                        />
                        <feOffset dy='-2' />
                        <feComposite
                            in2='hardAlpha'
                            operator='arithmetic'
                            k2='-1'
                            k3='1'
                        />
                        <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0'
                        />
                        <feBlend
                            mode='normal'
                            in2='shape'
                            result='effect1_innerShadow_6484_13125'
                        />
                    </filter>
                </defs>
            </svg>
        </SVGUniqueID>
    );
}

export default ModalStatusWrapperMobile;
