import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';

export const useStyles = makeStyles({
    standardNotification: {
        background: '#141623',
        borderRadius: 5,
        padding: 15,
        marginBottom: 15,
        '&.isRankUpdateNotification': {
            background:
                'linear-gradient(119.63deg, rgba(255, 210, 76, 0.58) 1%, #D1A750 68.13%), linear-gradient(147.58deg, #FFDB47 23.72%, #F5AF3E 72.14%)',
        },
    },
    standardNotificationTitle: {
        fontSize: 15,
        fontWeight: 700,
        color: appColors.white,
        '&.isRankUpdateNotification': {
            fontSize: 18,
            color: '#AC7C2F',
            fontFamily: 'Montserrat',
        },
    },
    standardNotificationTime: {
        fontSize: 14,
        color: '#898A91',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    notificationDescription: {
        color: 'rgba(255, 255, 255, 0.5)',
        marginBottom: 15,
        fontSize: 15,
        fontWeight: 500,
        '&.isRankUpdateNotification': {
            fontSize: 16,
            color: '#AC7C2F',
            fontWeight: 400,
            fontFamily: 'Montserrat',
        },
    },
    actionsList: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
});
