import {useReducer, useMemo} from 'react';
import {observer} from 'mobx-react';
import {isAndroid, isIOS, isMobileSafari} from 'react-device-detect';

import useStores, {rootStore} from '../../hooks/useStores';
import {ColorVariant, GameStatus} from '../../store/models';
import {usePlayer} from 'src/sounds/use-player';
import cn from 'classnames';

import {useStyles} from './styles';

import {DragEvent, MouseEventHandler, useEffect, useRef, useState} from 'react';
import {ChessMap} from './chess/chess.map';
import {
    boardAsChessMap,
    chessMapToFen,
    getFiguresToFen,
    movementToFen,
    moveToFen,
} from './proxy/helper';
import {
    BBIcon,
    BKIcon,
    BNIcon,
    BPIcon,
    BQIcon,
    BRIcon,
    WBIcon,
    WKIcon,
    WNIcon,
    WPIcon,
    WQIcon,
    WRIcon,
} from '../fastChessboard/piece-memos-deepBlack';
import {Chess} from './chess/chess';
import {EActionBoard, boardStateUpdater} from './dispatchCore';
import {Movement} from './chess/movement';

interface Props {}

const figureMap = [
    {
        p: <WPIcon width={20} mobileHover={false} isMultiTable={false} />,
        k: <WKIcon width={20} mobileHover={false} isMultiTable={false} />,
        h: <WNIcon width={20} mobileHover={false} isMultiTable={false} />,
        r: <WRIcon width={20} mobileHover={false} isMultiTable={false} />,
        b: <WBIcon width={20} mobileHover={false} isMultiTable={false} />,
        q: <WQIcon width={20} mobileHover={false} isMultiTable={false} />,
    },
    {
        p: <BPIcon width={20} mobileHover={false} isMultiTable={false} />,
        q: <BQIcon width={20} mobileHover={false} isMultiTable={false} />,
        k: <BKIcon width={20} mobileHover={false} isMultiTable={false} />,
        h: <BNIcon width={20} mobileHover={false} isMultiTable={false} />,
        r: <BRIcon width={20} mobileHover={false} isMultiTable={false} />,
        b: <BBIcon width={20} mobileHover={false} isMultiTable={false} />,
    },
];

const chess = new Chess();

let lastSoundMove = '';

export interface IBoard2State {
    myColor: ColorVariant;
    board: ChessMap;
    availableMovesStart: Record<string, boolean>;
    availableMovesEnd: Record<string, boolean>;
    keys: number[];
    labels: string[];
    picked: string;
    lastFens: string[];
}

export interface IBoard2Animation {
    lastMove: string;
    startTime: Date;
    cellX: number;
    cellY: string;
    dx: number;
    dy: number;
    beat: string;
    goTime: number;
}

let lastAnimation = '';
let actionCalculated = false;

const {
    MY_COLOR,
    BOARD,
    AVALIABLE_MOVE_START,
    AVALIABLE_MOVE_END,
    KEYS,
    LABELS,
    PICKED,
    LAST_FENS,
    LAST_FENS_PUSH,
    STATE_UPDATE,
} = EActionBoard;

const BoardV2 = (props: Props) => {
    const {gameStore} = useStores();
    const isViewMode = gameStore.isViewMode();
    const isMyMove =
        gameStore.isMyMove &&
        gameStore.currentGameNew?.curChessMove ===
            gameStore.currentGameNew?.curMove;
    const isTouch = isAndroid || isMobileSafari || isIOS;
    const boardTableNew = document.getElementById('boardTableNew');
    const isWinner = !!gameStore.currentGameNew?.winner;
    const player = usePlayer();

    //sounds
    const onMoveSound = () => {
        player('pieceSoundPlay');
    };

    const onBeatSound = () => {
        player('beatSoundPlay');
    };

    // states

    const setStartBoardState = (): IBoard2State => ({
        myColor: ColorVariant.white,
        board: new ChessMap(),
        availableMovesStart: {},
        availableMovesEnd: {},
        keys: [],
        labels: [],
        picked: '',
        lastFens: [],
    });
    const [state, dispatch] = useReducer(
        boardStateUpdater,
        {props},
        setStartBoardState
    );
    const myColorForStyles = state.myColor === ColorVariant.white ? 0 : 1;

    //
    const [state2, setState] = useState<IBoard2State>({
        myColor: ColorVariant.white,
        board: new ChessMap(),
        availableMovesStart: {},
        availableMovesEnd: {},
        keys: [],
        labels: [],
        picked: '',
        lastFens: [],
    });
    const [moves, setMoves] = useState<Movement[]>([]);
    const [runHover, setRunHover] = useState<string | null>(null);
    const [animation, setAnimation] = useState<IBoard2Animation>({
        lastMove: '',
        cellX: 0,
        dx: 0,
        dy: 0,
        cellY: '',
        beat: '',
        startTime: new Date(),
        goTime: 0,
    });
    const [animationMove, setAnimationMove] = useState<boolean>(false);

    const [dragItem, setDragItem] = useState({
        keyLabel: -1,
        label: '',
        dx: 0,
        dy: 0,
        clientX: 0,
        clientY: 0,
        tm: 0,
    });

    const cntRef = useRef(null);
    const tableRef = useRef(null);

    const classes = useStyles();
    const [beatDetected, setBeatDetected] = useState(false);

    //const opponentSound = () => {
    //    return null;
    //    //Old Logical
    //    if (
    //        !isWinner &&
    //        isMyMove &&
    //        !isViewMode &&
    //        !gameStore.currentGameNew?.moves.length
    //    ) {
    //        // last move opponent view
    //        player('pieceSoundPlay');
    //    }
    //    if (!isWinner && (!isMyMove || isViewMode)) {
    //        //regular move opponent view
    //        player('pieceSoundPlay');
    //    }
    //};

    useEffect(() => {
        const nwMyColor =
            gameStore?.currentGameNew?.players[1]._id ===
            rootStore.authStore.currentUser?._id
                ? ColorVariant.black
                : ColorVariant.white;

        const keys =
            nwMyColor === ColorVariant.black
                ? [1, 2, 3, 4, 5, 6, 7, 8]
                : [8, 7, 6, 5, 4, 3, 2, 1];
        const labels =
            nwMyColor === ColorVariant.black
                ? ['h', 'g', 'f', 'e', 'd', 'c', 'b', 'a']
                : ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

        state.myColor = nwMyColor;
        state.keys = keys;
        state.labels = labels;

        // dispatch({type: MY_COLOR, myColor: nwMyColor});
        // dispatch({type: KEYS, keys: keys});
        // dispatch({type: LABELS, labels: labels});
        dispatch({
            type: STATE_UPDATE,
            state,
        });
        // setState({ ...state });
    }, [gameStore?.currentGameNew?.players[1]._id]);

    useEffect(() => {
        const nwFen0 =
            gameStore.currentGameNew?.fen.split(' ')[0] +
            ' ' +
            gameStore.currentGameNew?.fen.split(' ')[4];

        if (!nwFen0) return;

        if (state.lastFens.includes(nwFen0)) return;

        state.lastFens.push(nwFen0);

        // dispatch({type: LAST_FENS_PUSH, lastFensPush: nwFen0});

        const lastHistoryItems = (gameStore.currentGameNew?.movesHistory || []).slice(-1);
        if (lastHistoryItems.length) {
            const lastHistoryItem = lastHistoryItems[0];

            if (lastHistoryItem.type === 'move') {
                if (!animation.beat) {                    

                    const beatFigure = state.board.cells[8 - +lastHistoryItem.move[3]][
                        lastHistoryItem.move[2].charCodeAt(0) - 'a'.charCodeAt(0) + 1
                    ]?.figure?.type || '';

                    if (beatFigure) {
                        animation.beat = beatFigure;
                    }
                }
            }
        }

        const map = boardAsChessMap(gameStore.currentGameNew);

        const availableStart = {};
        if (map.currentMoveBlack == (state.myColor === ColorVariant.black)) {
            for (const move of gameStore.currentGameNew?.availableMoves || []) {
                availableStart[move.substr(0, 2)] = true;
            }
        }

        state.availableMovesStart = availableStart;

        // dispatch({
        //     type: AVALIABLE_MOVE_START,
        //     availableMovesStart: availableStart,
        // });
        state.lastFens = state.lastFens.slice(-3);
        state.board = map;
        // dispatch({type: BOARD, board: map});
        // dispatch({type: LAST_FENS, lastFens: state.lastFens.slice(-3)});
        if (lastHistoryItems.length) {
            const lastHistoryItem = lastHistoryItems[0];

            if (
                lastHistoryItem.type === 'move' &&
                Date.now() - +new Date(lastHistoryItem.date) < 1000
            ) {
                // if (animation.lastMove) {
                //     animation.lastMove = '';
                //     setAnimation({...animation});
                // }

                let dx =
                    lastHistoryItem.move.charCodeAt(2) -
                    lastHistoryItem.move.charCodeAt(0);
                let dy = +lastHistoryItem.move[3] - +lastHistoryItem.move[1];

                if (state.myColor === ColorVariant.black) {
                    dy *= -1;
                } else {
                    dx *= -1;
                }

                const table = tableRef.current as any as HTMLElement;

                const cell = table.children
                    .item(0)
                    ?.children.item(0);

                if (cell) {
                    dx *= cell.clientHeight;
                    dy *= cell.clientWidth;
                }
                // if (lastHistoryItem.move !== lastAnimation) {
                //
                //     const mult = window.innerWidth <= 800 ? 0.2 : 0.1;
                //
                //     const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
                //
                //     const speed = 50; // cells per second
                //     const time = ((distance / speed) * 1000 + 300) * 0.5;
                //
                //     const goTime = Math.round(time / 1000 * 10) / 10 * mult;
                //
                //     console.log('ch2', dx, dy, Math.round(time / 1000 * 10) / 10 * mult);
                //
                //     setAnimation({
                //         lastMove: lastHistoryItem.move,
                //         startTime: new Date(),
                //         cellX: +lastHistoryItem.move[3],
                //         cellY: lastHistoryItem.move[2],
                //         dx,
                //         dy,
                //         beat: animation.beat,
                //         goTime,
                //     });
                //
                //
                //     const it = setTimeout(() => {
                //         setAnimationMove(true);
                //         clearTimeout(it);
                //     }, 10);
                //
                //     const it2 = setTimeout(() => {
                //
                //         setAnimationMove(false);
                //         setAnimation({
                //             ...animation,
                //             lastMove: '',
                //             cellX: 0,
                //             cellY: '',
                //         });
                //         clearTimeout(it2);
                //     }, Math.round(goTime*1000));
                //
                //     lastAnimation = lastHistoryItem.move;
                // }
            }
        }
        // setState({ ...state });
        dispatch({
            type: STATE_UPDATE,
            state,
        });

        setMoves(
            !gameStore.currentGameNew?.availableMoves.length
                ? []
                : chess.getMoves(state.board)
        );
    }, [gameStore.currentGameNew?.fen, state.myColor]);

    useEffect(() => {
        if (gameStore.currentGameNew?.state === GameStatus.FINISHED) {
            state.picked = '';
            state.availableMovesEnd = {};
            state.availableMovesStart = {};

            // dispatch({
            //     type: PICKED,
            //     picked: "",
            // });
            // dispatch({
            //     type: AVALIABLE_MOVE_END,
            //     availableMovesEnd: {},
            // });
            // dispatch({
            //     type: AVALIABLE_MOVE_START,
            //     availableMovesStart: {},
            // });

            dispatch({
                type: STATE_UPDATE,
                state,
            });
        }
    }, [gameStore.currentGameNew?.state]);

    const moveHistory = gameStore.currentGameNew?.movesHistory;
    const moveHistoryData = moveHistory
        ? moveHistory[moveHistory.length - 1]
        : null;

    const oppoSound = moveHistoryData && !!moveHistoryData.move;

    const pick = (label, keyLabel) => {
        const availableEnd = {};
        if (
            state.board.currentMoveBlack ==
            (state.myColor === ColorVariant.black)
        ) {
            for (const move of gameStore.currentGameNew?.availableMoves || []) {
                if (move.substr(0, 2) === label + keyLabel) {
                    availableEnd[move.substr(2, 2)] = true;
                }
            }
        }

        state.picked = label + keyLabel;
        state.availableMovesEnd = availableEnd;
        // dispatch({
        //     type: PICKED,
        //     picked: label + keyLabel,
        // });
        // dispatch({
        //     type: AVALIABLE_MOVE_END,
        //     availableMovesEnd: availableEnd,
        // });

        dispatch({
            type: STATE_UPDATE,
            state,
        });
    };

    //useEffect(() => {
    //    if (
    //        !isViewMode &&
    //        !isWinner &&
    //        gameStore.gameState.moveSound &&
    //        isMyMove
    //    ) {
    //        //logical for my move
    //    //    player('pieceSoundPlay');
    //    }
    //}, [gameStore.gameState.moveSound, isViewMode, isWinner, isMyMove]);

    const prevHistoryLength = useRef(0);
    const prevGameState = useRef(gameStore.currentGameNew?.state);

    useEffect(() => {
        const lastMove = gameStore.currentGameNew?.movesHistory?.[
            gameStore.currentGameNew?.movesHistory?.length - 1
        ];
        const currentHistoryLength = gameStore.currentGameNew?.movesHistory?.length || 0;
        const currentGameState = gameStore.currentGameNew?.state;

        if (lastMove &&
            lastMove.type === 'move' &&
            currentHistoryLength > prevHistoryLength.current &&
            !(prevGameState.current !== GameStatus.FINISHED && currentGameState === GameStatus.FINISHED)
        ) {
            if (lastSoundMove !== lastMove.move) {
                lastSoundMove = lastMove.move;
                if (lastMove.beatFigure) {
                    onBeatSound();
                } else {
                    onMoveSound();
                }
            }
        }

        prevHistoryLength.current = currentHistoryLength;
        prevGameState.current = currentGameState;
    }, [
        gameStore.currentGameNew?.movesHistory,
        gameStore.currentGameNew?.state,
        isMyMove
    ]);

    const dragStart = (eve: any, label, keyLabel, isTouch = false) => {
        setRunHover(null);

        if (state.picked && state.availableMovesEnd[label + keyLabel]) {
            const table = tableRef.current as any as HTMLElement;
            const cell = table.children.item(0)?.children.item(0);

            const width = cell?.clientWidth || 80;
            const height = cell?.clientHeight || 80;

            dragItem.label = state.picked[0];
            dragItem.keyLabel = +state.picked[1];
            dragItem.dx =
                (state.labels.indexOf(label) -
                    state.labels.indexOf(state.picked[0])) *
                width;
            dragItem.dy =
                (state.keys.indexOf(keyLabel) -
                    state.keys.indexOf(+state.picked[1])) *
                height;

            onEnd(isTouch, true);
            return;
        }

        if (state.picked) {
            state.picked = '';
        }

        if (
            !state.picked &&
            isMyMove &&
            !isViewMode &&
            state.availableMovesStart[label + keyLabel]
        ) {
            const clientX =
                eve?.touches && eve?.touches[0] && eve?.touches[0].clientX
                    ? eve?.touches[0].clientX
                    : eve.clientX;
            const clientY =
                eve?.touches && eve?.touches[0] && eve?.touches[0].clientY
                    ? eve?.touches[0].clientY
                    : eve.clientY;
            setDragItem({
                label,
                keyLabel,
                dy: 0,
                dx: 0,
                clientX,
                clientY,
                tm: Date.now(),
            });

            const availableEnd = {};
            if (
                state.board.currentMoveBlack ==
                (state.myColor === ColorVariant.black)
            ) {
                for (const move of gameStore.currentGameNew?.availableMoves ||
                    []) {
                    if (move.substr(0, 2) === label + keyLabel) {
                        availableEnd[move.substr(2, 2)] = true;
                    }
                }
            }

            state.availableMovesEnd = availableEnd;
            // dispatch({
            //     type: AVALIABLE_MOVE_END,
            //     availableMovesEnd: availableEnd,
            // });
            // setState({ ...state });
            dispatch({
                type: STATE_UPDATE,
                state,
            });
        }

        // alert('2' + state.picked);
    };
    const drag = (eve: any) => {
        if (dragItem.label && isMyMove && !isViewMode) {
            const clientX = eve.clientX || eve?.touches[0]?.clientX;
            const clientY = eve.clientY || eve?.touches[0]?.clientY;

            dragItem.dx = clientX - dragItem.clientX;
            dragItem.dy = clientY - dragItem.clientY;

            const table = tableRef.current as any as HTMLElement;
            const cell = table.children.item(0)?.children.item(0);

            const width = cell?.clientWidth || 80;
            const height = cell?.clientHeight || 80;

            const dx = Math.round(dragItem.dx / width);
            const dy = Math.round(dragItem.dy / height);

            let keyIndex = state.keys.indexOf(dragItem.keyLabel);
            let labelIndex = state.labels.indexOf(dragItem.label);

            keyIndex += dy;
            labelIndex += dx;

            const nwKeyLabel = state.keys[keyIndex];
            const nwLabel = state.labels[labelIndex];
            setRunHover(nwLabel + nwKeyLabel);
            setDragItem({...dragItem});
        }
    };

    const onEnd = (isTouch, startAnimation = false) => {
        const table = tableRef.current as any as HTMLElement;
        const cell = table.children.item(0)?.children.item(0);

        const width = cell?.clientWidth || 80;
        const height = cell?.clientHeight || 80;

        const dx = Math.round(dragItem.dx / width);
        const dy = Math.round(dragItem.dy / height);

        let keyIndex = state.keys.indexOf(dragItem.keyLabel);
        let labelIndex = state.labels.indexOf(dragItem.label);

        keyIndex += dy;
        labelIndex += dx;

        const nwKeyLabel = state.keys[keyIndex];
        const nwLabel = state.labels[labelIndex];

        if (state.availableMovesEnd[nwLabel + nwKeyLabel]) {
            const move =
                dragItem.label + dragItem.keyLabel + nwLabel + nwKeyLabel;

            const moveEnd = moves.find((movement) => {
                const curCode = movementToFen(movement);

                return curCode.substr(0, 4) === move;
            });

            const lastMove = move;

            animation.beat =
                //@ts-ignore
                state.board.cells[8 - +lastMove[3]][
                    //@ts-ignore
                    lastMove[2].charCodeAt(0) - 'a'.charCodeAt(0) + 1
                ]?.figure?.type || '';

            if (moveEnd) {
                const isCapture = !!animation.beat;

                if (lastSoundMove !== move) {
                    lastSoundMove = move;
                    if (isCapture) {
                        onBeatSound();
                    } else {
                        onMoveSound();
                    }
                }

                if (moveEnd.movement.transformTo) {
                    moveEnd.movement.transformTo = undefined;
                }

                moveEnd.move(state.board);

                animation.beat = '';
            }

            gameStore.gameState
                .moveNew(move, gameStore.currentGameNew?._id || '')
                .catch(console.error);

            const moves2 = chess.getMoves(state.board);

            const availableMoves = moveToFen(moves2);

            const availableStart = {};

            if (
                state.board.currentMoveBlack ==
                (state.myColor === ColorVariant.black)
            ) {
                for (const move of availableMoves || []) {
                    availableStart[move.substr(0, 2)] = true;
                }
            }

            state.availableMovesStart = availableStart;

            state.picked = '';
            state.availableMovesEnd = {};

            state.lastFens.push(
                chessMapToFen(state.board) +
                    ' ' +
                    getFiguresToFen(state.board.moves)
            );

            state.lastFens = state.lastFens.slice(-3);
            setMoves(moves2);
            // setState({ ...state });

            if (lastMove && startAnimation) {
                // console.log(lastMove, startAnimation);
                // if (animation.lastMove) {
                //     animation.lastMove = "";
                //     animation.dx = 0;
                //     animation.dy = 0;
                //     setAnimation({ ...animation });
                // }

                let dx = lastMove.charCodeAt(2) - lastMove.charCodeAt(0);
                let dy = +lastMove[3] - +lastMove[1];

                if (state.myColor === ColorVariant.black) {
                    dy *= -1;
                } else {
                    dx *= -1;
                }

                const table = tableRef.current as any as HTMLElement;

                const cell = table.children.item(0)?.children.item(0);

                if (cell) {
                    dx *= cell.clientHeight;
                    dy *= cell.clientWidth;
                }

                if (lastMove !== lastAnimation) {
                    const mult = window.innerWidth <= 800 ? 0.2 : 0.1;

                    const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

                    const speed = 50; // cells per second
                    const time = ((distance / speed) * 1000 + 300) * 0.5;

                    const goTime = Math.round(time / 1000 * 10) / 10 * mult;

                    // console.log('ch', dx, dy, Math.round(time / 1000 * 10) / 10 * mult);
                    // setAnimation({
                    //     lastMove: lastMove,
                    //     startTime: new Date(),
                    //     cellX: +lastMove[3],
                    //     cellY: lastMove[2],
                    //     dx,
                    //     dy,
                    //     beat: animation.beat,
                    //     goTime,
                    // });
                    //
                    // const it = setTimeout(() => {
                    //     setAnimationMove(true);
                    //     clearTimeout(it);
                    // }, 10);
                    //
                    // const it2 = setTimeout(() => {
                    //     setAnimationMove(false);
                    //     setAnimation({
                    //         ...animation,
                    //         lastMove: '',
                    //         cellX: 0,
                    //         cellY: '',
                    //     });
                    //     clearTimeout(it2);
                    // }, Math.round(goTime*1000));

                    lastAnimation = lastMove;
                }
            }

            dispatch({
                type: STATE_UPDATE,
                state,
            });
        }

        const tm = setTimeout(() => {
            // alert(`dd: ${nwKeyLabel == dragItem.keyLabel} &&
            //     ${nwLabel === dragItem.label} &&
            //     ${state.availableMovesStart[
            //     dragItem.label + dragItem.keyLabel
            //         ]} &&
            //     ${dragItem.label} &&
            //     ${Date.now() - dragItem.tm < 500}`);

            if (
                nwKeyLabel == dragItem.keyLabel &&
                nwLabel === dragItem.label &&
                state.availableMovesStart[dragItem.label + dragItem.keyLabel] &&
                dragItem.label &&
                Date.now() - dragItem.tm < 500
            ) {
                pick(dragItem.label, dragItem.keyLabel);
            } else {
                if (
                    !state.availableMovesEnd[dragItem.label + dragItem.keyLabel]
                ) {
                    state.availableMovesEnd = {};
                    dispatch({
                        type: STATE_UPDATE,
                        state,
                    });
                }
            }

            setRunHover(null);
            setDragItem({
                label: '',
                keyLabel: -1,
                dy: 0,
                dx: 0,
                clientX: 0,
                clientY: 0,
                tm: dragItem.tm,
            });

            clearTimeout(tm);
        }, 50);
    };

    const dragEnd = (
        eve: any,
        label: string,
        keyLabel: number,
        isTouch = false
    ) => {
        eve.preventDefault();
        eve.stopPropagation();
        setRunHover(null);

        if (state.picked && state.availableMovesEnd[label + keyLabel]) {
            const table = tableRef.current as any as HTMLElement;
            const cell = table.children.item(0)?.children.item(0);

            const width = cell?.clientWidth || 80;
            const height = cell?.clientHeight || 80;

            dragItem.label = state.picked[0];
            dragItem.keyLabel = +state.picked[1];
            dragItem.dx =
                (state.labels.indexOf(label) -
                    state.labels.indexOf(state.picked[0])) *
                width;
            dragItem.dy =
                (state.keys.indexOf(keyLabel) -
                    state.keys.indexOf(+state.picked[1])) *
                height;
            onEnd(isTouch, true);
            return;
        }

        if (dragItem.label && Date.now() - dragItem.tm > 200) {
            onEnd(isTouch);
        } else {
            if (dragItem.label) {
                if (
                    state.availableMovesStart[
                        dragItem.label + dragItem.keyLabel
                    ] &&
                    !state.picked
                ) {
                    pick(dragItem.label, dragItem.keyLabel);
                } else if (
                    !state.availableMovesEnd[dragItem.label + dragItem.keyLabel]
                ) {
                    state.picked = '';
                    state.availableMovesEnd = {};
                    dispatch({
                        type: STATE_UPDATE,
                        state,
                    });
                }
            } else {
                if (state.picked) {
                    state.picked = '';
                    state.availableMovesEnd = {};
                    dispatch({
                        type: STATE_UPDATE,
                        state,
                    });
                }
            }

            setDragItem({
                label: '',
                keyLabel: -1,
                dy: 0,
                dx: 0,
                clientX: 0,
                clientY: 0,
                tm: dragItem.tm,
            });
        }

        // alert('1' + state.picked);
    };

    return (
        <div className={cn(classes.table)} ref={tableRef} id='boardTableNew'>
            {state.keys.map((keyLabel, rowId) => {
                return (
                    <div className={classes.row}>
                        {' '}
                        {state.labels.map((label, cellId) => {
                            const key = 9 - keyLabel;
                            const cell =
                                state.myColor === ColorVariant.black
                                    ? 7 - cellId
                                    : cellId;
                            const figureKey = state.board.cells[key - 1][
                                cell + 1
                            ].figure?.isBlack
                                ? 1
                                : 0;
                            const figureType =
                                state.board.cells[key - 1][cell + 1].figure
                                    ?.type || '';

                            return (
                                <div
                                    // onTouchStart={(eve) => {
                                    //     if (!isTouch) return;
                                    //     dragStart(eve, label, keyLabel, false);
                                    // }}
                                    onMouseUp={(eve) =>
                                        dragEnd(eve, label, keyLabel, false)
                                    }
                                    onTouchEnd={(eve) => {
                                        if (!isTouch) return;
                                        dragEnd(eve, label, keyLabel, true);
                                    }}
                                    className={cn(classes.cell, figureKey, {
                                        dark: (cellId + rowId) % 2 === 1,
                                        light: (cellId + rowId) % 2 === 0,
                                        availableStart:
                                            state.availableMovesStart[
                                                label + keyLabel
                                            ] &&
                                            !state.picked &&
                                            !dragItem.label &&
                                            !isViewMode,
                                        picked:
                                            state.picked === label + keyLabel &&
                                            !isViewMode,
                                        availableEnd:
                                            state.availableMovesEnd[
                                                label + keyLabel
                                            ] && !isViewMode,
                                        runHover:
                                            label + keyLabel === runHover &&
                                            !!runHover &&
                                            !isViewMode,
                                        [classes.cellActive]:
                                            (keyLabel === animation.cellX &&
                                                label === animation.cellY) ||
                                            (keyLabel === dragItem.keyLabel &&
                                                label === dragItem.label) ||
                                            state.picked === label + keyLabel,
                                    })}
                                >
                                    {((keyLabel === dragItem.keyLabel &&
                                        label === dragItem.label) ||
                                        state.availableMovesEnd[
                                            label + keyLabel
                                        ]) && (
                                        <div
                                            className={cn(
                                                classes.availableEndCircle,
                                                figureType,
                                                {
                                                    show: state
                                                        .availableMovesEnd[
                                                        label + keyLabel
                                                    ],
                                                    isFigure:
                                                        state.availableMovesEnd[
                                                            label + keyLabel
                                                        ] &&
                                                        figureType &&
                                                        myColorForStyles !==
                                                            figureKey,
                                                    showShadowPiece:
                                                        figureType &&
                                                        myColorForStyles ===
                                                            figureKey,
                                                }
                                            )}
                                        ></div>
                                    )}

                                    {animation.lastMove &&
                                        keyLabel === animation.cellX &&
                                        label === animation.cellY && (
                                            <div
                                                className={cn(classes.lastMove)}
                                                ref={cntRef}
                                                style={{
                                                    left: animationMove ? 0 : animation.dx,
                                                    top: animationMove ? 0 : animation.dy,
                                                    transition: `top ${animation.goTime}s linear, left ${animation.goTime}s linear`,
                                                    // willChange: 'left, top'
                                                }}
                                            >
                                                {state.board &&
                                                    state.board.cells[key - 1][
                                                        cell + 1
                                                    ].figure &&
                                                    figureMap[figureKey][
                                                        figureType
                                                    ]}
                                            </div>
                                        )}

                                    {animation.lastMove &&
                                        keyLabel === animation.cellX &&
                                        label === animation.cellY &&
                                        animation.beat &&
                                        figureMap[1 - figureKey][
                                            animation.beat
                                        ]}

                                    {(!animation.lastMove ||
                                        keyLabel !== animation.cellX ||
                                        label !== animation.cellY) &&
                                        state.board &&
                                        state.board.cells[key - 1][cell + 1]
                                            .figure && (
                                            <div
                                                style={{
                                                    left:
                                                        keyLabel ===
                                                            dragItem.keyLabel &&
                                                        label === dragItem.label
                                                            ? dragItem.dx
                                                            : 0,
                                                    top:
                                                        keyLabel ===
                                                            dragItem.keyLabel &&
                                                        label === dragItem.label
                                                            ? dragItem.dy
                                                            : 0,
                                                }}
                                                onMouseDown={(eve) => {
                                                    if (isViewMode) return;
                                                    dragStart(
                                                        eve,
                                                        label,
                                                        keyLabel
                                                    );
                                                }}
                                                onMouseMove={(eve) => {
                                                    drag(eve);
                                                }}
                                                onMouseUp={(eve) =>
                                                    dragEnd(
                                                        eve,
                                                        label,
                                                        keyLabel,
                                                        false
                                                    )
                                                }
                                                //
                                                onTouchStart={(eve) => {
                                                    if (!isTouch || isViewMode)
                                                        return;
                                                    dragStart(
                                                        eve,
                                                        label,
                                                        keyLabel,
                                                        true
                                                    );
                                                }}
                                                onTouchMove={(eve) => {
                                                    if (!isTouch || isViewMode)
                                                        return;
                                                    boardTableNew?.addEventListener(
                                                        'touchmove',
                                                        function (eve) {
                                                            eve.preventDefault();
                                                            drag(eve);
                                                        }
                                                    );
                                                }}
                                                onTouchEnd={(eve) => {
                                                    if (!isTouch) return;
                                                    dragEnd(
                                                        eve,
                                                        label,
                                                        keyLabel,
                                                        true
                                                    );
                                                }}
                                                className={cn(classes.figure, {
                                                    [classes.figureMove]:
                                                        keyLabel ===
                                                            dragItem.keyLabel &&
                                                        label ===
                                                            dragItem.label,
                                                })}
                                            >
                                                {
                                                    figureMap[figureKey][
                                                        figureType
                                                    ]
                                                }
                                            </div>
                                        )}

                                    {!cellId && (
                                        <div
                                            className={cn(classes.keyNotation, {
                                                dark:
                                                    (cellId + rowId) % 2 === 1,
                                                light:
                                                    (cellId + rowId) % 2 === 0,
                                            })}
                                        >
                                            {keyLabel}
                                        </div>
                                    )}
                                    {rowId === 7 && (
                                        <div
                                            className={cn(classes.labelNotation, {
                                                dark:
                                                    (cellId + rowId) % 2 === 1,
                                                light:
                                                    (cellId + rowId) % 2 === 0,
                                            })}
                                        >
                                            {label}
                                        </div>
                                    )}
                                </div>
                            );
                        })}{' '}
                    </div>
                );
            })}
        </div>
    );
};

export default observer(BoardV2);
