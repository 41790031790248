import { ChessMap } from '../chess/chess.map';
import { Pawn } from './pawn';

export class PawnDark extends Pawn {
  moveVector = 1;

  constructor(public chessMapRef: ChessMap | null = null) {
    super(true, chessMapRef);
  }
}
