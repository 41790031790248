import { useHistory } from 'react-router-dom';

import { ButtonType, GreenButton } from 'src/components/buttons/greenButton';
import { useLocaleByLabel } from 'src/hooks/useLocaleByLabel';
import useStores from 'src/hooks/useStores';
import {
    INotification,
    INotificationAction,
    NotificationActionTypes,
    NotificationCustomActionNames,
} from 'src/store/models';

interface IActionButtonProps {
    action: INotificationAction;
    notification: INotification;
    isRankUpdateNotification?: boolean;
}

const ActionButton = ({
    action,
    notification,
    isRankUpdateNotification,
}: IActionButtonProps) => {
    const { authStore, generalLobbyStore } = useStores();
    const history = useHistory();
    const { getLocaleByLabel } = useLocaleByLabel();

    const customFunction = (actionName: NotificationCustomActionNames) => {
        if (
            actionName === NotificationCustomActionNames.reward_rank_progression
        ) {
            const rank = authStore.currentUser?.ranks?.find(
                (r) => r?.title === notification?.locale?.label?.props?.rank
            );
            authStore.getBonuses(
                notification.additionalData?.rankId ?? rank?._id
            );
        }
        generalLobbyStore.setOpenNotificationsModal(false);
    };

    const onActionClick = async () => {
        await authStore.markNotificationAsViewed({
            id: notification.id,
        });
        if (
            action.actionType === NotificationActionTypes.internal_redirect &&
            action.actionProperties.path
        ) {
            history.push(action.actionProperties.path);
        } else if (
            action.actionType === NotificationActionTypes.external_redirect &&
            action.actionProperties.link
        ) {
            window.location.replace(action.actionProperties.link);
        } else if (
            action.actionType === NotificationActionTypes.custom_action
        ) {
            action.actionProperties?.actionName &&
                customFunction(action.actionProperties?.actionName);
        }
        generalLobbyStore.setOpenNotificationsModal(false);
    };
    return (
        <GreenButton
            text={getLocaleByLabel(
                action.actionProperties.tagLabel!,
                'Click',
                action.actionProperties?.tagLocaleProps
            )}
            type={
                isRankUpdateNotification
                    ? ButtonType.yellow
                    : ButtonType.primary
            }
            fontSize={16}
            onClick={onActionClick}
            paddingLeft={15}
            paddingRight={15}
            disabled={action.disabled}
        />
    );
};

export default ActionButton;
