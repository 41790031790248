import useStores from "src/hooks/useStores";
import { DoublingStage } from "src/store/gameStore/dialogs";
import { EGameRematchStatus } from "src/store/models";

const useDoublingStageStatus = () => {
    const { gameStore, authStore } = useStores();

    const giveUpDialogIsOpen = gameStore?.dialogs?.giveUpDialog?.isOpen;
    const showAcceptOrRejectDialog =
        gameStore?.dialogs?.doublingDialog?.isOpen &&
        !giveUpDialogIsOpen &&
        gameStore?.dialogs?.doublingDialog?.stage ===
            DoublingStage.acceptOrRejectDoubling;
    const showOpponentAcceptedDoublingDialog =
        gameStore?.dialogs?.doublingDialog?.isOpen &&
        !giveUpDialogIsOpen &&
        gameStore?.dialogs?.doublingDialog?.stage ===
            DoublingStage.opponentAcceptedDoubling;

    return {
        showAcceptOrRejectDialog,
        showOpponentAcceptedDoublingDialog,
    };
};

export default useDoublingStageStatus;
