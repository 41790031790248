import React from "react";

import BOneIcon from "./images/numbers/b-1";
import BTwoIcon from "./images/numbers/b-2";
import BThreeIcon from "./images/numbers/b-3";
import BFourIcon from "./images/numbers/b-4";
import BFiveIcon from "./images/numbers/b-5";
import BSixIcon from "./images/numbers/b-6";
import WOneIcon from "./images/numbers/w-1";
import WTwoIcon from "./images/numbers/w-2";
import WThreeIcon from "./images/numbers/w-3";
import WFourIcon from "./images/numbers/w-4";
import WFiveIcon from "./images/numbers/w-5";
import WSixIcon from "./images/numbers/w-6";
import BBIcon from "./images/pieces/b-4";
import BKIcon from "./images/pieces/b-2";
import BQIcon from "./images/pieces/b-3";
import BPIcon from "./images/pieces/b-1";
import BNIcon from "./images/pieces/b-5";
import BRIcon from "./images/pieces/b-6";
import WBIcon from "./images/pieces/w-3";
import WKIcon from "./images/pieces/w-5";
import WQIcon from "./images/pieces/w-4";
import WPIcon from "./images/pieces/w-6";
import WNIcon from "./images/pieces/w-2";
import WRIcon from "./images/pieces/w-1";

export const getBlackFiguresIcons = (size) => {
    return [
        <BPIcon key={1} width={size} height={size} />,
        <BNIcon key={2} width={size} height={size} />,
        <BBIcon key={3} width={size} height={size} />,
        <BRIcon key={4} width={size} height={size} />,
        <BQIcon key={5} width={size} height={size} />,
        <BKIcon key={6} width={size} height={size} />,
    ];
};

export const getWhiteFiguresIcons = (size) => {
    return [
        // Пешка w-6
        <WPIcon key={1} width={size} height={size} />,
        // Конь w-2
        <WNIcon key={2} width={size} height={size} />,
        // Офицер w-3
        <WBIcon key={3} width={size} height={size} />,
        // Тура w-1
        <WRIcon key={4} width={size} height={size} />,
        // Королева w-4
        <WQIcon key={5} width={size} height={size} />,
        // Король w-5
        <WKIcon key={6} width={size} height={size} />,
    ];
};

export const getWhiteDigitsIcons = (size) => {
    return [
        <WOneIcon key={1} width={size} height={size} />,
        <WTwoIcon key={2} width={size} height={size} />,
        <WThreeIcon key={3} width={size} height={size} />,
        <WFourIcon key={4} width={size} height={size} />,
        <WFiveIcon key={5} width={size} height={size} />,
        <WSixIcon key={6} width={size} height={size} />,
    ];
};

export const getBlackDigitsIcons = (size) => {
    return [
        <BOneIcon key={1} width={size} height={size} />,
        <BTwoIcon key={2} width={size} height={size} />,
        <BThreeIcon key={3} width={size} height={size} />,
        <BFourIcon key={4} width={size} height={size} />,
        <BFiveIcon key={5} width={size} height={size} />,
        <BSixIcon key={6} width={size} height={size} />,
    ];
};
