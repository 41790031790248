import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

interface IUserAvatar {
    isLobbyAvatar?: boolean | undefined;
}

export const useStyles = makeStyles({
    avatarBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarImageBlock: {
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center',
        position: 'relative',
        marginTop: 0,
    },
    winnerAnimated: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        animationName: '$avatarImageBlock',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDelay: '1.3s',
        animationDuration: '700ms',
    },

    winnerAnimatedRight: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },

    avatarImage: {
        zIndex: 2,
        borderRadius: '50%',
        objectFit: 'cover',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        animationName: '$avatarImagePreload',
        animationDelay: '0',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDuration: '250ms',
        '&.isSystemAvatar': {
            width: '100%',
            height: '100%',
        },
        '&.isLoaded': {
            filter: 'blur(4px)',
            borderRadius: '50%',
        },
    },

    '@keyframes avatarImagePreload': {
        '0%': {
            filter: 'blur(10px)',
            opacity: 1,
        },
        '50%': {
            filter: 'blur(5px)',
        },
        '100%': {
            filter: 'blur(0px)',
            opacity: 1,
        },
    },

    avatarBorderImage: ({ isLobbyAvatar }: IUserAvatar) => ({
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        borderRadius: '50%',
    }),

    avatarBorderInside: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        borderRadius: '50%',
        // background:
        //     'linear-gradient(0deg, #C0C0C2 0.01%, #1A171B 13.33%, #2C2B2E 22.93%, #3D3D3F 33.94%, #E1E1E2 50%, #CFCFD0 52.17%, #9F9FA0 56.54%, #525254 62.66%, #3D3D3F 64.24%, #1A171B 80.61%, #ACACAE 93.33%)',
        animationDelay: '1s',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDuration: '700ms',
        '&.isChessBoard': {
            background: 'white',
            animationName: '$avatarBorderImageInside',
        },
        '&.isSystemAvatar': {
            background: 'white',
            // background:
            //     'linear-gradient(0deg, #C0C0C2 0.01%, #1A171B 13.33%, #2C2B2E 22.93%, #3D3D3F 33.94%, #E1E1E2 50%, #CFCFD0 52.17%, #9F9FA0 56.54%, #525254 62.66%, #3D3D3F 64.24%, #1A171B 80.61%, #ACACAE 93.33%)',
        },
    },
    avatarBorderImageMid: {
        width: '95%',
        height: '95%',
        objectFit: 'contain',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        borderRadius: '50%',
        background: 'white',
        // background:
        //     'linear-gradient(90deg, #ACACAE 6.66%, #1A171B 19.38%, #3D3D3F 35.76%, #525254 37.34%, #9F9FA0 43.46%, #CFCFD0 47.83%, #E1E1E2 50%, #3D3D3F 66.06%, #2C2B2E 77.07%, #1A171B 86.67%, #C0C0C2 100%)',
        // animationName: '$avatarBorderImageMid',
        animationDelay: '1s',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDuration: '700ms',
        '&.isChessBoard': {
            background: 'white',
            animationName: '$avatarBorderImageMid',
        },
        '&.isSystemAvatar': {
            background: 'white',
        },
    },

    sunrays: {
        opacity: 0,
        width: 'fit-content',
        height: '40px',
        objectFit: 'none',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$sunrays',
        animationDelay: '1.1s',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        animationDuration: '1900ms',
    },

    sunraysRight: {
        opacity: 0,
        width: 'fit-content',
        height: 'fit-content',
        objectFit: 'none',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$sunraysRight',
        animationDelay: '1.1s',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        animationDuration: '1900ms',
    },

    sunraysRightDouble: {
        opacity: 0,
        width: 'fit-content',
        height: 'fit-content',
        objectFit: 'none',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%) rotate(90deg)',
        zIndex: 1,
        animationName: '$sunraysRight',
        animationDelay: '1.2s',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        animationDuration: '2400ms',
    },

    sunshine: {
        opacity: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        borderRadius: '50%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$sunshine',
        animationDelay: '1.1s',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        animationDuration: '1900ms',
    },

    sunshineRight: {
        opacity: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        borderRadius: '50%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$sunshineRight',
        animationDelay: '1.1s',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        animationDuration: '1900ms',
    },

    sunraysRotate: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        borderRadius: '50%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$sunraysRotate',
        animationDelay: '1s',
        animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        animationDuration: '10000ms',
    },

    sunshineRotate: {
        opacity: 0,
        width: '400%',
        height: '400%',
        objectFit: 'cover',
        position: 'absolute',
        borderRadius: '50%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$sunshineRotate',
        animationDelay: '1.1s',
        animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        animationDuration: '3000ms',
    },

    coinsGroup: {
        opacity: 0,
        width: '100%',
        height: '100%',
        // objectFit: 'none',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$coinsGroup',
        animationDelay: '1.2s',
        animationIterationCount: 1,
        // animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        animationDuration: '1900ms',
    },

    coinFull: {
        opacity: 0,
        width: '100%',
        height: '100%',
        // objectFit: 'none',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        animationName: '$coinFull',
        animationDelay: '1.2s',
        animationIterationCount: 1,
        // animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        animationDuration: '1900ms',
    },

    '@keyframes avatarBorderImageMid': {
        '0%': {
            opacity: 1,
            // background:
            //     'linear-gradient(90deg, #ACACAE 6.66%, #1A171B 19.38%, #3D3D3F 35.76%, #525254 37.34%, #9F9FA0 43.46%, #CFCFD0 47.83%, #E1E1E2 50%, #3D3D3F 66.06%, #2C2B2E 77.07%, #1A171B 86.67%, #C0C0C2 100%)',
        },
        '25%': {
            opacity: 0.2,
        },
        '100%': {
            opacity: 1,
            // background:
            //     'linear-gradient(90deg, #E1C589 3.77%, #C5A06A 8.8%, #4E3620 16.55%, #2C2B2E 25.75%, #A5824D 36.31%, #FFF5DF 51.71%, #EEE1C7 53.79%, #D9C59B 57.98%, #896543 70.25%, #956232 79.86%, #BC8721 84.96%, #BFA382 95.47%, #B8A785 99.66%)',
        },
    },

    '@keyframes avatarBorderImageInside': {
        '0%': {
            opacity: 1,
            // background:
            //     'linear-gradient(0deg, #C0C0C2 0.01%, #1A171B 13.33%, #2C2B2E 22.93%, #3D3D3F 33.94%, #E1E1E2 50%, #CFCFD0 52.17%, #9F9FA0 56.54%, #525254 62.66%, #3D3D3F 64.24%, #1A171B 80.61%, #ACACAE 93.33%)',
        },
        '40%': {
            opacity: 0.2,
        },
        '100%': {
            opacity: 1,
            // background:
            //     'linear-gradient(0deg, #FFE9BC 0.02%, #FFC978 5.26%, #4E3620 13.33%, #2C2B2E 22.93%, #A5824D 33.94%, #FFF5DF 50%, #EEE1C7 52.17%, #D9C59B 56.54%, #896543 69.34%, #956232 79.35%, #BC8721 84.67%, #FFDA7A 95.63%, #FFE1A8 100%)',
        },
    },

    '@keyframes avatarImageBlock': {
        '0%': {
            filter: 'drop-shadow(0px 0px 10px #B7C7FF)',
        },
        '33%': {
            filter: 'drop-shadow(0px 0px 20px #B7C7FF)',
        },
        '100%': {
            filter: 'drop-shadow(0px 0px 60px #B7C7FF)',
        },
    },

    '@keyframes sunrays': {
        '0%': {
            mixBlendMode: 'screen',
            opacity: 0.1,
        },
        '35%': {
            mixBlendMode: 'normal',
            opacity: 1,
        },
        '65%': {
            mixBlendMode: 'normal',
            opacity: 1,
        },
        '100%': {
            mixBlendMode: 'screen',
            opacity: 0.5,
        },
    },

    '@keyframes sunraysRight': {
        '0%': {
            mixBlendMode: 'screen',
            opacity: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(1)',
        },
        '50%': {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(3)',
        },
        '100%': {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(1)',
        },
    },

    '@keyframes sunshine': {
        '0%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
        '50%': {
            opacity: 0.5,
            width: '200%',
            height: '200%',
        },
        '100%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
    },

    '@keyframes sunshineRight': {
        '0%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
        '50%': {
            opacity: 0.4,
            width: '250%',
            height: '250%',
        },
        '100%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
    },

    '@keyframes sunraysRotate': {
        '0%': {
            mixBlendMode: 'screen',
            opacity: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(1.5) rotate(0deg)',
        },
        '75%': {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(2.5) rotate(180deg)',
        },
        '100%': {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(1.5) rotate(360deg)',
        },
    },

    '@keyframes sunshineRotate': {
        '0%': {
            opacity: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(1) rotate(0deg)',
        },
        '50%': {
            top: 0,
            left: 0,
            opacity: 0.4,
            width: '100%',
            height: '100%',
            transform: 'scale(2)',
        },
        '100%': {
            opacity: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(1) rotate(45deg)',
        },
    },

    '@keyframes coinsGroup': {
        '0%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
        '50%': {
            opacity: 1,
            width: '150%',
            height: '150%',
        },
        '100%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
    },

    '@keyframes coinFull': {
        '0%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
        '50%': {
            opacity: 1,
            width: '250%',
            height: '250%',
        },
        '100%': {
            opacity: 0,
            width: '100%',
            height: '100%',
        },
    },

    noImageBlock: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'grid',
        placeItems: 'center',
        zIndex: 2,
        background: 'rgb(26, 23, 27)',
        color: '#fff',
        fontSize: 14,
    },
    lazyImageWrapper: {
        width: '100%',
        height: '100%',
    },
});
