import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

const currentHeight = window.innerHeight;

export const useStyles = makeStyles({
    modalTitlesBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        height: 'fit-content',
        overflow: 'hidden',
        '&.nicknames': {
            maskImage: 'linear-gradient(to right,black 80%,transparent 100%)',
        },
        '&.tournamentTitle': {
            [theme.breakpoints.down('sm')]: {
                minHeight: '35px',
            },
        },
        '&.groupStage': {
            [theme.breakpoints.down('sm')]: {
                minHeight: '25px',
            },
        },
        '&.tagline': {
            [theme.breakpoints.down('sm')]: {
                minHeight: '20px',
            },
        },
    },

    modalTitles: {
        fontWeight: 500,
        fontSize: '32px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: appColors.white,
        fontFamily: 'Montserrat',
        margin: 0,
        //@ts-ignore
        wordWrap: 'anywhere',
        '&.nicknames': {
            fontWeight: 500,
            fontSize: '24px',
            overflow: 'hidden',
            wordWrap: 'normal',
            [theme.breakpoints.down(375)]: {
                fontSize: '20px',
            },
        },
        '&.tournamentTitle': {
            zIndex: 2,
            textShadow:
                '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 50px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.5)',
            fontWeight: 700,
        },
        '&.groupStage': {
            fontWeight: 500,
            fontSize: '18px',
            color: 'rgba(255, 255, 255, 0.5)',
        },
        '&.tagline': {
            fontWeight: 500,
            fontSize: '18px',
            color: 'rgba(255, 255, 255, 0.8)',
            fontStyle: 'italic',
        },
        '&.fastGame': {
          fontWeight: 500,
          fontSize: '24px',
          '@media screen and (min-height: 760px)': {
            fontSize: '26px',
          },
      },
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            '&.nicknames': {
                fontWeight: 500,
                fontSize: '24px',
                overflow: 'hidden',
                wordWrap: 'normal',
                [theme.breakpoints.down(375)]: {
                    fontSize: '20px',
                },
            },
            '&.tournamentTitle': {
                zIndex: 2,
                textShadow:
                    '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 50px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.5)',
                fontWeight: 700,
            },
            '&.groupStage': {
                fontWeight: 500,
                fontSize: '18px',
                color: 'rgba(255, 255, 255, 0.5)',
            },
            '&.tagline': {
                fontWeight: 400,
                fontSize: '4vw',
                color: 'rgba(255, 255, 255, 0.8)',
                fontStyle: 'italic',
            },
        },
    },

    '@keyframes uploadAvatarWrapper': {
        '0%': {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: '10px',
        },
        '100%': {
            opacity: 1,
            borderRadius: '35px',
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },
});
