import cn from 'classnames';
import { ReactNode } from 'react';

import { useStyles } from './styles';

interface IGoldModalProps {
    className?: string;
    open?: boolean;
    children?: ReactNode;
}

export const GoldModal = ({ className, open, children }: IGoldModalProps) => {
    const styles = useStyles();

    return (
        <div className={cn(styles.container, className, { open })}>
            {children}
        </div>
    );
};
