import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    blockedChatWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: 20,
        alignItems: 'center',
        overflow: 'hidden',
        padding: '0px 20px',
        margin: 'auto',
        height: 'fit-content',
    },

    blockChatSmile: {
        filter: 'grayscale(100%)',
        overflow: 'hidden',
        opacity: 0.5,
        width: '200px',
        height: '200px',
    },

    blockChatText: {
        margin: 0,
        fontWeight: 700,
        fontSize: '1.5rem',
        overflow: 'hidden',
        color: 'white',
        textAlign: 'center',
    },
});
