import { Box, Dialog, Typography } from '@mui/material';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';

import { useLocale } from 'src/providers/LocaleProvider';

import paths from '../../consts/paths';
import { endpointsRoot } from '../../core/endpoints';
import useStores from '../../hooks/useStores';
import { Avatar } from '../Avatar';

import NotificationItem from './components/NotificationItem';
import { useStyles } from './styles';

const Notifications = () => {
    const { authStore, generalStore, generalLobbyStore } = useStores();
    const notifications = authStore.notifications;

    const styles = useStyles();
    const history = useHistory();
    const {
        notifications: { close, editProfile, readAll, notification },
    } = useLocale();

    const isOpen = generalLobbyStore.isNotificationsModalOpen;

    useEffect(() => {
        if (isOpen) {
            authStore.getMyNotifications();
            readAllNotifications();
        }
    }, [isOpen]);

    const user = authStore.currentUser;
    if (!user) return null;

    const readAllNotifications = async () => {
        await authStore.markNotificationAsViewed();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                generalLobbyStore.setOpenNotificationsModal(false);
            }}
            maxWidth={false}
            className={styles.container}
            PaperProps={{ className: styles.dialogPaper }}
        >
            <Scrollbar
                className={styles.customScrollbar}
                trackYProps={{
                    renderer: (props) => {
                        const { elementRef, ...restProps } = props;
                        return (
                            <span
                                {...restProps}
                                ref={elementRef}
                                className={cn(
                                    'trackY',
                                    styles.customScrollTrackY
                                )}
                            />
                        );
                    },
                }}
            >
                <div>
                    <Box
                        className={styles.editProfile}
                        onClick={() => {
                            if (generalStore.isDicechessMode)
                                history.push(paths.profile);
                            generalLobbyStore.setOpenNotificationsModal(false);
                        }}
                    >
                        <Avatar
                            width={50}
                            nickname={user.nickname}
                            avatarUrl={
                                user.avatar &&
                                `${endpointsRoot}/avatars/${user.avatar}`
                            }
                        />
                        {generalStore.isDicechessMode && (
                            <Typography className={styles.editProfileButton}>
                                {editProfile('Редактировать профиль')}
                            </Typography>
                        )}
                    </Box>

                    <div className={styles.notificationMainTitle}>
                        <Typography
                            variant='h2'
                            className={styles.notificationTitle}
                        >
                            {notification('Уведомления')}
                        </Typography>
                        {/* <div onClick={readAllNotifications}>
                            <Typography
                                variant='subtitle1'
                                style={{ cursor: 'pointer' }}
                            >
                                {readAll(' Прочитать все')}
                            </Typography>
                        </div> */}
                    </div>

                    {notifications.map((notification) => {
                        return <NotificationItem notification={notification} />;
                    })}
                </div>

                <div
                    className={styles.closeButton}
                    onClick={() => {
                        generalLobbyStore.setOpenNotificationsModal(false);
                    }}
                >
                    {close('Закрыть')}
                </div>
            </Scrollbar>
        </Dialog>
    );
};

export default observer(Notifications);
