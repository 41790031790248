import React from 'react';
import { BracketColumnProps, MatchPairProps } from '../types';
import { useStyles } from "../styles";
import { COLUMN_GAPS, COLUMN_MARGINS } from '../constants';
import { observer } from 'mobx-react';

export const BracketColumn: React.FC<BracketColumnProps> = ({ title, children, columnIndex }) => {
    const classes = useStyles({ matchIndex: 0, marginTop: 0, columnIndex });

    const safeIndex = Math.min(columnIndex, COLUMN_GAPS.length - 1);
    const gap = COLUMN_GAPS[safeIndex];
    const marginTop = COLUMN_MARGINS[safeIndex];

    return (
        <div className={classes.columnWrapper}>
            <div className={classes.columnHeader}>{title}</div>
            <div
                className={classes.column}
                style={{
                    gap: `${gap}px`,
                    marginTop: `${marginTop}px`
                }}
            >
                {React.Children.map(children, (child, matchIndex) => {
                    if (React.isValidElement<MatchPairProps>(child)) {
                        return React.cloneElement(child, {
                            columnIndex,
                            matchIndex,
                        });
                    }
                    return child;
                })}
            </div>
        </div>
    );
};

export default observer(BracketColumn);
