import cn from 'classnames';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';

import useStores from 'src/hooks/useStores';
import {
    prizeModalGold,
    prizeModalSilver,
    prizeModalOther,
} from 'src/consts/app-config';
import TournamentTopPlayersIcon from 'src/assets/icons/tournaments/TournamentTopPlayersIcon';

import { useStyles } from './styles';

interface IRatingCrown {
    type?: string | undefined;
}

function RatingCrown({ type }: IRatingCrown) {
    const styles = useStyles();
    const { achievementsStatStore } = useStores();
    const togglerTopPlayers = achievementsStatStore?.topPlayersToggle;

    const topPlayersFromStore = achievementsStatStore?.topPlayers;
    const myPosition = achievementsStatStore?.myPlace;

    const openTopPlayerModal = () => {
        if (type === 'isLobbyPage') return;
        if (togglerTopPlayers === null) {
            achievementsStatStore.toggleTopPlayersInfo(!togglerTopPlayers);
        }
    };

    return (
        <>
            <Box className={cn(styles.topPlayerCrownMain)}>
                {topPlayersFromStore && (
                    <>
                        <Box className={cn(styles.topPlayerCrownWrapper)}>
                            <Box
                                className={cn(styles.topPlayersBoxShine)}
                            ></Box>
                            <Box
                                className={cn(styles.topPlayerCrown)}
                                onClick={() => openTopPlayerModal()}
                            >
                                <TournamentTopPlayersIcon
                                    width={42}
                                    height={42}
                                    fillColor={
                                        myPosition === 1
                                            ? prizeModalGold
                                            : myPosition === 2
                                            ? prizeModalSilver
                                            : prizeModalOther
                                    }
                                    strokeWidth={1}
                                />
                                <p
                                    className={cn(styles.topPlayerText, {
                                        first:
                                            typeof myPosition === 'number' &&
                                            myPosition === 1,
                                        second:
                                            typeof myPosition === 'number' &&
                                            myPosition === 2,
                                        overFlow:
                                            typeof myPosition === 'number' &&
                                            myPosition > 9999,
                                    })}
                                >
                                    {myPosition}
                                </p>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
}

export default observer(RatingCrown);
