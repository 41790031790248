import cn from 'classnames';
import { useLayoutEffect, useState } from 'react';

import { MathHelper } from 'src/shared/helpers/MathHelper';

import { useStyles } from './styles';

type ProgressBarProps = {
    seconds: number;
    initialProgress?: number;
    reset?: number;
    onEnd: () => void;
    progressColor?: string;
    progressWarningColor?: string;
    progressShortTimeColor?: string;

    textColor?: string;
    textWarningColor?: string;
    textShortTimeColor?: string;
    pause?: boolean;
    showTime?: boolean;
    label?: string;
};

const ProgressBar = ({
    seconds,
    initialProgress,
    reset,
    onEnd,
    progressColor,
    progressWarningColor,
    progressShortTimeColor,

    textColor,
    textShortTimeColor,
    textWarningColor,

    showTime,
    pause,
    label,
}: ProgressBarProps) => {
    const [progress, setProgress] = useState<number>(
        initialProgress ?? seconds
    );
    const [intervalId, setIntervalId] = useState<any>();
    const styles = useStyles();

    useLayoutEffect(() => {
        if (pause) {
            clearInterval(intervalId);
        } else {
            setIntervalId(
                setInterval(() => {
                    setProgress((progress) => progress - 1);
                }, 1000)
            );
        }
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [pause]);

    useLayoutEffect(() => {
        if (progress <= 0) {
            if (intervalId) clearInterval(intervalId);
            onEnd();
        }
    }, [progress]);

    const percent = MathHelper.percentageBetween(seconds, progress);
    const isWarning = percent < 50 && percent > 20;
    const isError = percent <= 20;
    const progressBgColor =
        isWarning || isError
            ? isWarning
                ? progressWarningColor
                : progressShortTimeColor
            : progressColor;
    const progressTextColor =
        isWarning || isError
            ? isWarning
                ? textWarningColor || textColor
                : textShortTimeColor
            : textColor;

    return (
        <div className={cn(styles.progressWrapper, label)}>
            {showTime && (
                <p
                    className={styles.timeText}
                    style={{ color: progressTextColor }}
                >
                    {progress}
                </p>
            )}
            <div className={styles.progressHolder}>
                <div
                    className={styles.progressActive}
                    style={{
                        width:
                            MathHelper.percentageBetween(seconds, progress) +
                            '%',
                        background: progressBgColor,
                    }}
                />
            </div>
        </div>
    );
};

export default ProgressBar;
