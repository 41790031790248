import React from 'react';
import { useStyles } from './styles';

import { EyeIcon } from '../../images/eye';
import { HideEye } from '../../images/hideEye';

interface IEyeProps {
    onChange?: (hidden: boolean, backGroundColor: string) => void;
    hidden: boolean;
    backGroundColor: string;
}

export const ToggleHidden = ({
    onChange,
    hidden,
    backGroundColor,
}: IEyeProps) => {
    const classes = useStyles();
    return (
        <div
            className={classes.eyeWrapper}
            onClick={() => {
                onChange && onChange(!hidden, backGroundColor);
            }}
        >
            {hidden ? <HideEye /> : <EyeIcon />}
        </div>
    );
};
