// TODO: refactor

export const arrayAsKeyStructure = (initialValue: unknown[]) => {
    const data: any = [...initialValue];
    return {
        set(key, value) {
            const alreadyHas = data.find((el) => el[0].find((el) => key));
            if (alreadyHas) throw new Error(`${key} already in the array`);
            data.push([[key], value]);
        },
        get(key) {
            const alreadyHas = data.find((el) => el[0].find((el) => key));
            if (!alreadyHas) return null;

            return alreadyHas[1];
        },
        push(key, value) {
            const alreadyHas = data.find((el) => el[0].find((el) => key));
            if (!alreadyHas) throw new Error(`${key} does not exist`);
            alreadyHas[1].push(value);
        },
    };
};
