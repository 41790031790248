import _get from 'lodash/get';

import { useLocale } from 'src/providers/LocaleProvider';

export const useLocaleByLabel = () => {
    const locales = useLocale();

    const getLocaleByLabel = (
        label: string,
        defaultValue: string,
        props?: Record<string, any>
    ) => {
        if (props) {
            return _get(locales, label).compile(props, defaultValue);
        }
        return _get(locales, label)(defaultValue);
    };
    return {
        getLocaleByLabel,
    };
};
