import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
const clientWidth = window.innerWidth;
const clientHeight = window.innerHeight;
import theme from "src/theme";
import { DARKGREEN_ALERTS } from "src/pages/game-new/constants";

export const useStyles = makeStyles({
    multiGameContainer: {
        maxWidth: "75%",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: 0,
        padding: 0,
        gridGap: "5px",
    },

    multiGameWrapper: {
        width: "33%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: 0,
        padding: 0,
        cursor: "pointer",
    },

    gameTabContainer: {
        color: appColors.white,
        width: "100%",
        height: "100%",
        borderBottom: "0.5px solid gold",
    },

    avatarGameTabBox: {
        width: "100%",
        height: "fit-content",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    redesignClockBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
    },

    playerAvatar: {
        [theme.breakpoints.down("lg")]: {
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            objectFit: "contain",
            "&.secondPlayer": {
                marginLeft: "-10px",
                zIndex: -1,
            },
        },
    },
});
