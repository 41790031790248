const paths = Object.freeze({
    main: "/",
    game: "/game",
    games: "/games",
    lobby: "/lobby",
    diceChessLobby: "/diceChessLobby",
    lobbyFreePlay: "/lobby-free-play",
    alg1xBet: "/betting",
    algaltente: "/slots",
    liveDealer: "/live-dealer",
    jackpoker: "/poker",
    sports: "/sports",
    virtualSports: "/virtual-sport",
    login: "/login",
    phoneAuth: "/phone-auth",
    profile: "/profile",
    profileForRef: "/referral-program",
    gamesHistory: "/games-history",
    gamesRules: "/games-rules",
    support: "/support",
    faq: "/faq",
    register: "/register",
    cashbox: "/cashbox",
    recoveryPassword: "/recovery-password",
    resetPassword: "/reset-password",
    amlPolicy: "/aml-policy",
    policy: "/policy",
    privacyPolicy: "/privacy-policy",
    aboutUs: "/about-us",
    responsibleGaming: "/responsible-gaming",
    termAndConditions: "/term-and-conditions",
    tournament: "/tournament",
    tournaments: "/tournaments",
    withdrawalPolicy: "/withdrawal-policy-and-refund-policy",
    pwaInstruction: "/PWA-instruction",
    notifications: "/notifications",
    logout: "/logout",
});

export default paths;

export const socialPaths = Object.freeze({
    telegram: "https://t.me/dicechess_games",
    instagram: "https://www.instagram.com/dicechess.app",
    youtube: "https://www.youtube.com/@DicechessTV",
    tiktok: "https://www.tiktok.com/@dicechess.games",
    discord: "https://discord.gg/kKHSt4GD",
});

export const supportPath = Object.freeze({
    emailSupoort: "support@beturanga.com",
    telegram: "https://t.me/dicechess_support",
    whatsApp: "https://wa.me/66660479829",
});

export const GSLink =
    "https://licensing.gaming-curacao.com/validator/?lh=2a3f09f2a6066937cf00bad896a98361";
