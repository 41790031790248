import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

export const useStyles = makeStyles({
    infoWrapper: {
        height: 'fit-content',
        lineHeight: 0,
    },
    infoText: {
        lineHeight: 0,
    },

    matchResultAlertBox: {
        border: '1px solid wheat',
        width: '100%',
        height: 'fit-content',
        borderRadius: '12px',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        boxShadow: '5px 10px 8px 0px rgba(34, 60, 80, 0.5)',
        animationName: '$matchResultAlertBox',
        animationDelay: '0.1s',
        animationFillMode: 'forwards',
        animationDuration: '400ms',
        animationIterationCount: 1,
    },

    description: {
        padding: '10px 10px',
        color: 'purple',
        margin: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 600,
        background:
            'linear-gradient(180deg, rgba(38, 215, 195, 0.5) 0%, rgba(200, 215, 195, 1) 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },

    '@keyframes matchResultAlertBox': {
        '0%': {
            transform: 'scale(0.3)',
            opacity: 0,
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 1,
        },
    },
});
