import { makeStyles } from "@mui/styles";
import theme from "src/theme";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";
import { React_Brackets_WIDTH_CONDITION } from "src/consts/window-size-params";

export const useStyles = makeStyles({
    popup: {
        left: 0,
        top: 55,
        overflow: "hidden",
        "&.top": {
            top: "unset",
            bottom: 50,
        },
        "&.bottom": {
            left: 0,
            top: 50,
        },
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            "@media (orientation: portrait)": {
                top: "unset",
                bottom: 50,
            },
        },
        [theme.breakpoints.down("md")]: {
            top: "unset",
            bottom: 50,
        },
    },
});
