import { makeAutoObservable } from "mobx";

import {
    ExternalGameType,
    ILiveDealerGame,
    ISlotGame,
    LiveDealerModel,
    SlotModel,
} from "src/entities/externalGame";
import { ExternalGameService } from "src/entities/externalGame/api/externalGame";
import { VirtualSportModel } from "src/entities/externalGame/model/virtualSport";
import { getGames } from "src/service/api/externalGames";
import { errorService } from "src/service/services";

import { IGetGamesParams } from "./models";

class ExternalGamesStore {
    slots: ISlotGame[] = [];
    liveDealers: ILiveDealerGame[] = [];
    virtualSports: ISlotGame[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async toggleFavorite(id: string | number, type: ExternalGameType) {
        if (type === ExternalGameType.SLOTS) {
            this.slots = this.slots.map((game) => {
                if (game.id === id) {
                    game.isFavorite = !game.isFavorite;
                }
                return game;
            });
        } else if (type === ExternalGameType.LIVE_DEALER) {
            this.liveDealers = this.liveDealers.map((game) => {
                if (game.id === id) {
                    game.isFavorite = !game.isFavorite;
                }
                return game;
            });
        }
        try {
            await ExternalGameService.toggleFavorite(id);
        } catch (e) {
            errorService.sendError(e.message);
        }
    }

    async getSlots({
        offset,
        limit,
        query,
        providerId,
        isPopular,
        isFavorite,
        isNew,
    }: IGetGamesParams) {
        try {
            const response = await getGames({
                offset,
                limit,
                query,
                providerId,
                isPopular,
                isFavorite,
                isNew,
                type: ExternalGameType.SLOTS,
            });
            const games = response.data.games.map((g) => new SlotModel(g));

            if (offset === 0) {
                this.slots = games;
            } else {
                this.slots.push(...games);
            }
        } catch (e) {}
    }
    async getLiveDealers({
        offset,
        limit,
        query,
        providerId,
        isPopular,
        isFavorite,
        isNew,
    }: IGetGamesParams) {
        try {
            const response = await getGames({
                offset,
                limit,
                query,
                providerId,
                isPopular,
                isFavorite,
                isNew,
                type: ExternalGameType.LIVE_DEALER,
            });
            const games = response.data.games.map(
                (g) => new LiveDealerModel(g)
            );

            if (offset === 0) {
                this.liveDealers = games;
            } else {
                this.liveDealers.push(...games);
            }
        } catch (e) {}
    }
    async getVirtualSportGames({
        offset,
        limit,
        query,
        providerId,
        isPopular,
        isFavorite,
        isNew,
    }: IGetGamesParams) {
        try {
            const response = await getGames({
                offset,
                limit,
                query,
                providerId,
                isPopular,
                isFavorite,
                isNew,
                type: ExternalGameType.VIRTUAL_SPORT,
            });
            const games = response.data.games.map(
                (g) => new VirtualSportModel(g)
            );

            if (offset === 0) {
                this.virtualSports = games;
            } else {
                this.virtualSports.push(...games);
            }
        } catch (e) {}
    }
}

export default ExternalGamesStore;
