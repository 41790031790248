import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
import { ICustomIconProps } from './tournaments/types';

function ModalStatusWrapper({
    onClick,
    width,
    height,
    fillColor,
    fullFillColor,
}: ICustomIconProps) {
    const currnetWidth = width || 260;
    const currnetHeight = height || 88;

    return (
        <SVGUniqueID>
            <svg
                width='268'
                height='96'
                viewBox='0 0 268 96'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g filter='url(#filter0_d_6405_9972)'>
                    <path
                        d='M11 24C11 10.7452 21.7452 0 35 0H240C253.255 0 264 10.7452 264 24V65V65C264 77.7025 253.703 88 241 88H7.31598C5.28046 88 4.03346 84.5998 5.274 82.986C8.09723 79.3132 11 73.4761 11 65V24Z'
                        fill='#212736'
                    />
                </g>
                <defs>
                    <filter
                        id='filter0_d_6405_9972'
                        x='0.779541'
                        y='0'
                        width='267.22'
                        height='96'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                    >
                        <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                        />
                        <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                        />
                        <feOffset dy='4' />
                        <feGaussianBlur stdDeviation='2' />
                        <feComposite in2='hardAlpha' operator='out' />
                        <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                        />
                        <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_6405_9972'
                        />
                        <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_6405_9972'
                            result='shape'
                        />
                    </filter>
                </defs>
            </svg>
        </SVGUniqueID>
    );
}

export default ModalStatusWrapper;
