import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export const EyeIcon = () => {
    return (
        <SVGUniqueID>
            <svg
                width='20'
                height='12'
                viewBox='0 0 16 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M1.71628 6.47165C2.09946 7.06496 2.55217 7.65868 3.06969 8.21071C4.51746 9.75499 6.16987 10.6667 8 10.6667C9.83013 10.6667 11.4825 9.75499 12.9303 8.21071C13.4478 7.65868 13.9005 7.06496 14.2837 6.47165C14.3925 6.30325 14.4889 6.14509 14.5729 6C14.4889 5.85491 14.3925 5.69675 14.2837 5.52835C13.9005 4.93504 13.4478 4.34132 12.9303 3.78929C11.4825 2.24501 9.83013 1.33333 8 1.33333C6.16987 1.33333 4.51746 2.24501 3.06969 3.78929C2.55217 4.34132 2.09946 4.93504 1.71628 5.52835C1.60752 5.69675 1.51106 5.85491 1.42714 6C1.51106 6.14509 1.60752 6.30325 1.71628 6.47165ZM0.0703819 5.70186C0.164094 5.51443 0.339978 5.20175 0.596224 4.80498C1.02033 4.1483 1.52075 3.49201 2.09698 2.87737C3.77421 1.08833 5.7468 0 8 0C10.2532 0 12.2258 1.08833 13.903 2.87737C14.4792 3.49201 14.9797 4.1483 15.4038 4.80498C15.66 5.20175 15.8359 5.51443 15.9296 5.70186C16.0235 5.88954 16.0235 6.11046 15.9296 6.29814C15.8359 6.48557 15.66 6.79825 15.4038 7.19502C14.9797 7.8517 14.4792 8.50799 13.903 9.12263C12.2258 10.9117 10.2532 12 8 12C5.7468 12 3.77421 10.9117 2.09698 9.12263C1.52075 8.50799 1.02033 7.8517 0.596224 7.19502C0.339978 6.79825 0.164094 6.48557 0.0703819 6.29814C-0.0234606 6.11046 -0.0234606 5.88954 0.0703819 5.70186Z'
                    fill='#A8B1CE'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.00065 8.66634C6.52789 8.66634 5.33398 7.47243 5.33398 5.99967C5.33398 4.52691 6.52789 3.33301 8.00065 3.33301C9.47341 3.33301 10.6673 4.52691 10.6673 5.99967C10.6673 7.47243 9.47341 8.66634 8.00065 8.66634ZM8.00065 7.33301C8.73703 7.33301 9.33398 6.73605 9.33398 5.99967C9.33398 5.26329 8.73703 4.66634 8.00065 4.66634C7.26427 4.66634 6.66732 5.26329 6.66732 5.99967C6.66732 6.73605 7.26427 7.33301 8.00065 7.33301Z'
                    fill='#A8B1CE'
                />
            </svg>
        </SVGUniqueID>
    );
};
