import { makeStyles } from "@mui/styles";
import theme from "src/theme";

export const useStyles = makeStyles({
    watchingWrapper: ({ height }: { height: number | undefined }) => ({
        display: "flex",
        justifСontent: "center",
        alignItems: "center",
        padding: "4px 12px",
        background: "rgba(113, 121, 148, 0.2)",
        borderRadius: "24px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "0px",
        color: "#A8B1CE",
        width: "fit-content",
        ...(height && {
            height: height,
        }),
        margin: "0 auto",
        cursor: "pointer",
        "&.darkBlue": {
            background: "rgba(113, 121, 148, 0.2)",
        },
        "&.darkGreen": {
            background: "rgba(17, 131, 110, 0.25)",
        },
        [theme.breakpoints.up(1800)]: {
            marginTop: 10,
            transform: "scale(1.25)",
        },
    }),

    watchingWrapperDry: {
        display: "flex",
        justifСontent: "center",
        alignItems: "center",
        padding: "4px 12px",
        borderRadius: "24px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "0px",
        color: "#A8B1CE",
        width: "fit-content",
        margin: "0 auto",
        cursor: "pointer",
    },

    watchingUsersIcon: {},

    watchingUsersText: {
        whiteSpace: "nowrap",
        [theme.breakpoints.up(1800)]: {
            fontSize: "24px",
        },
    },

    watchingUsersCount: {
        fontWeight: 700,
    },
});
