import { makeStyles } from "@mui/styles";

import { appColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        height: 56,
        position: "relative",
        fontSize: 16,
    },
    input: {
        width: "100%",
        height: "100%",
        background: "transparent",
        border: "1px solid #FFFFFF4D",
        borderRadius: "12px",
        padding: "0 22px",
        fontWeight: 400,
        fontSize: "inherit",
        outline: "none",
        color: appColors.white,
        fontFamily: "'Montserrat', sans-serif",
        "&::placeholder": {
            color: "#F5F5F599",
        },
    },
    icon: {
        width: 24,
        height: 24,
        objectFit: "contain",
        position: "absolute",
        right: 22,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
    },
});
