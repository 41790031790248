export enum InfoMessage {
    slow = 'Internet connection is too slow',
    lost = 'Lost internet connection',
    connected = 'Connected',
}

export interface ISpeed {
    wifiSpeedText?: string | number | null;
    minSpeed?: number | undefined;
    internetStatus?: boolean | undefined | string;
}
