export enum EColor {
    darkBlue = "darkBlue",
    deepBlack = "deepBlack",
    darkGreen = "darkGreen",
    darkViolet = "darkViolet",
    darkPurple = "darkPurple",
    darkGray = "darkGray",
    reload = "reload",
    open = "open",
    close = "close",
    jackPotViolet = "jackPotViolet",
}
