import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton } from '@mui/material';
import closeIcon from "src/assets/icons/x.svg";
import { useStyles } from "./styles";

const BusinessModal = ({ isOpen, onClose }) => {
    const classes = useStyles();

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Форма отправлена');
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className={classes.dialog}
        >
            <IconButton
                onClick={onClose}
                className={classes.closeButton}
            >
                <img src={closeIcon} alt="Close" style={{ width: '20px', height: '20px' }} />
            </IconButton>
            <DialogContent className={classes.content}>
                <h2 className={classes.title}>Связь с нами</h2>
                <p className={classes.subtitle}>
                    Есть идеи по сотрудничеству с проектом Dicechess?<br />
                    Оставь свой контакт для связи и расскажи о себе
                </p>
                <form onSubmit={handleSubmit}>
                    {['email', 'instagram', 'TikTok', 'twitch', 'youtube'].map((field) => (
                        <TextField
                            key={field}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            placeholder={field === 'email' ? 'mensonmaria@gmail.com' : field}
                            className={classes.input}
                        />
                    ))}
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                        placeholder="Оставь комментарий:"
                        className={`${classes.input} ${classes.multilineInput}`}
                    />
                    <p className={classes.subtitle}>
                        Мы с радостью рассмотрим твое предложение и свяжемся с тобой!
                    </p>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submitButton}
                    >
                        Отправить
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default BusinessModal;