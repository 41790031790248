import { useEffect, useRef } from "react";
import { Round } from "../types";
import { observer } from "mobx-react";

const TournamentLines: React.FC<{ bracket: Round[] }> = ({ bracket }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const drawLineWithRoundedCorners = (
            startX: number,
            startY: number,
            endX: number,
            endY: number,
            radius: number = 4
        ) => {
            ctx.beginPath();

            if (startY === endY) {
                // Горизонтальная линия
                ctx.moveTo(startX, startY);
                ctx.lineTo(endX, endY);
            } else {
                // Линия с изгибом
                const cornerX = startX;
                const cornerY = endY;

                ctx.moveTo(startX, startY);
                ctx.lineTo(cornerX, startY + (startY < cornerY ? -radius : radius));
                ctx.arcTo(cornerX, cornerY, cornerX + (startX < endX ? radius : -radius), cornerY, radius);
                ctx.lineTo(endX, endY);
            }

            ctx.stroke();
        };

        const drawLines = () => {
            const container = canvas.parentElement;
            if (!container) return;

            const containerRect = container.getBoundingClientRect();
            const scrollTop = container.scrollTop;
            const scrollLeft = container.scrollLeft;

            canvas.width = container.scrollWidth;
            canvas.height = container.scrollHeight;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.strokeStyle = "#2F3136";
            ctx.lineWidth = 1;

            const matchCoords: { [key: string]: { x: number; y: number; width: number; height: number } } = {};

            bracket.forEach((round, roundIndex) => {
                const matches = container.querySelectorAll(`[data-id^="${roundIndex}-"]`);
                matches.forEach((match, matchIndex) => {
                    const rect = match.getBoundingClientRect();
                    const isLastColumn = roundIndex === bracket.length - 1; // Проверка на последнюю колонку

                    const titleHeight = isLastColumn
                        ? ((match.querySelector('.matchLabel') as HTMLElement)?.offsetHeight || 0) + 15 // Учитываем высоту заголовка
                        : 0;

                    matchCoords[`${roundIndex}-${matchIndex}`] = {
                        x: rect.left - containerRect.left + scrollLeft,
                        y: rect.top - containerRect.top + scrollTop + titleHeight, // Добавляем высоту заголовка
                        width: rect.width,
                        height: rect.height,
                    };
                });
            });


            for (let roundIndex = 0; roundIndex < bracket.length - 1; roundIndex++) {
                const round = bracket[roundIndex];

                for (let matchIndex = 0; matchIndex < round.matches.length; matchIndex += 2) {
                    const match1 = matchCoords[`${roundIndex}-${matchIndex}`];
                    const match2 = matchCoords[`${roundIndex}-${matchIndex + 1}`];
                    const nextMatch = matchCoords[`${roundIndex + 1}-${matchIndex / 2}`];

                    if (!match1 || !nextMatch) continue;

                    const lineOffset = 40;
                    const verticalLineX = Math.round(match1.x + match1.width + lineOffset);

                    const match1Y = Math.round(match1.y + match1.height / 2);
                    const nextMatchY = Math.round(nextMatch.y + nextMatch.height / 2);

                    // Горизонтальная линия от первого матча
                    ctx.beginPath();
                    ctx.moveTo(Math.round(match1.x + match1.width), match1Y);
                    ctx.lineTo(verticalLineX, match1Y);
                    ctx.stroke();

                    if (match2) {
                        const match2Y = Math.round(match2.y + match2.height / 2);

                        // Горизонтальная линия от второго матча
                        ctx.beginPath();
                        ctx.moveTo(Math.round(match2.x + match2.width), match2Y);
                        ctx.lineTo(verticalLineX, match2Y);
                        ctx.stroke();

                        // Вертикальная линия
                        ctx.beginPath();
                        ctx.moveTo(verticalLineX, match1Y);
                        ctx.lineTo(verticalLineX, match2Y);
                        ctx.stroke();

                        const midY = Math.round((match1Y + match2Y) / 2);

                        // Линия к следующему матчу со скруглением
                        drawLineWithRoundedCorners(
                            verticalLineX,
                            midY,
                            Math.round(nextMatch.x),
                            nextMatchY
                        );
                    } else {
                        // Одиночная линия к следующему матчу
                        drawLineWithRoundedCorners(
                            verticalLineX,
                            match1Y,
                            Math.round(nextMatch.x),
                            nextMatchY
                        );
                    }
                }
            }
        };

        drawLines();

        const container = canvas.parentElement;
        if (container) {
            container.addEventListener("scroll", drawLines);
        }
        window.addEventListener("resize", drawLines);

        return () => {
            if (container) {
                container.removeEventListener("scroll", drawLines);
            }
            window.removeEventListener("resize", drawLines);
        };
    }, [bracket]);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: 'absolute',
                inset: 0,
                zIndex: -1,
                pointerEvents: 'none'
            }}
        />
    );
};
export default observer(TournamentLines);
