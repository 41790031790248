import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import Picker from "emoji-picker-react";
import { Box } from "@mui/material";

import { useLocale } from "src/providers/LocaleProvider";
import { GameMessageType } from "src/service/api/gameChat/requestResponses";

import { IGameChatHistoryTextArea } from "../types/IChat";

import { useStyles } from "./styles";
import { Smile } from "./smile.icon";
import { Send } from "./send.icon";
import "./styles.css";


export const GameChatHistoryTextArea = memo(
    ({
        gameID,
        text,
        onChange,
        onEnter,
        shouldHide,
        onStickerToggler,
        setInsertStickerFunction,
    }: IGameChatHistoryTextArea) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [inputValue, setInputValue] = useState('');
        const {
            game: {
                chat: { placeMessage },
            },
        } = useLocale();
        const inputHelpers = {
            placeholder: "Написать сообщение",
            targetKey: "Enter",
        };
        const classes = useStyles({ showPicker: false });

        const handleClear = () => {
            setInputValue('');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        };

        const handleSendMessage = () => {
            if (inputValue.trim()) {
                onEnter({
                    gameId: gameID,
                    message: inputValue,
                    type: GameMessageType.one,
                });
                handleClear();
            }
        };

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setInputValue(newValue);
            onChange(newValue);
        };

        // для вставки стикера в инпут
        const insertSticker = useCallback((stickerId: string) => {
            const stickerTag = `[img sticker="${stickerId}"]`;
            const newValue = inputValue + ' ' + stickerTag;
            setInputValue(newValue);
            onChange(newValue);
        }, [inputValue, onChange]);


        useEffect(() => {
            if (setInsertStickerFunction) {
                setInsertStickerFunction(() => insertSticker);
            }
        }, [setInsertStickerFunction, insertSticker]);


        return (
            <div className={classes.messageBackgroundBox}>
                <input
                    ref={inputRef}
                    className={classes.input}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeMessage(inputHelpers.placeholder)}
                    onKeyDown={(e) => {
                        if (e.key === inputHelpers.targetKey) {
                            handleSendMessage();
                        }
                    }}
                />
                <span
                    className={classes.messageStickericon}
                    onClick={onStickerToggler}
                >
                    <Smile width={20} height={20} />
                </span>
                <button
                    className={classes.buttonSendMessageFromChat}
                    onClick={handleSendMessage}
                >
                    <Send />
                </button>
            </div>
        );
    }
);
