import axios from "axios";

import { IGame } from "src/store/models";
import endpoints from "src/core/endpoints";

import {
    IBaseRequest,
    IMooveRequest,
    IRollRequest,
    IBetRequest,
    IApplyBetRequest,
    IGameResponse,
} from "./requestResponses";

export const getActiveGames = async () => {
    return await axios.get<IGame[]>(endpoints.getActiveGames);
};

export const gameRoll = async (params: IRollRequest) => {
    return await axios.post<IGameResponse>(endpoints.gameRoll, params);
};

export const gameMove = async (
    params: IMooveRequest,
    timeout: number = 5000
) => {
    return await axios.post<IGameResponse>(endpoints.gameMove, params, {
        timeout,
    });
};

export const gameBet = async (params: IBetRequest) => {
    return await axios.post<IGameResponse>(endpoints.gameBet, params);
};

export const gameApplyBet = async (params: IApplyBetRequest) => {
    return await axios.post<IGameResponse>(endpoints.gameApplyBet, params);
};

//===

export const rematchPropose = async (params: IBaseRequest) => {
    return await axios.post<IGameResponse>(endpoints.rematchPropose, params);
};

export const rematchAccept = async (params: IBaseRequest) => {
    return await axios.post<IGameResponse>(endpoints.rematchAccept, params);
};

export const rematchDecline = async (params: IBaseRequest) => {
    return await axios.post<IGameResponse>(endpoints.rematchDecline, params);
};
