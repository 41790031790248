export class URLHelper {
    static protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

    static localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
    static nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

    public static replaceSearchParams<K extends string = string, V = any>(
        newQuery: Record<K, V>
    ) {
        const queryString = Object.keys(newQuery)
            .map(
                (key) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        newQuery[key]
                    )}`
            )
            .join("&");
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, "", newUrl);

        return newUrl;
    }
    public static deleteSearchParam(parameter: string): string {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);

        searchParams.delete(parameter);

        const newUrl = `${url.origin}${
            url.pathname
        }?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, "", newUrl);

        return newUrl;
    }
    public static getSearchParam(param: string) {
        const searchParams = new URLSearchParams(window.location.search);
        const searchParam = searchParams.get(param);
        return searchParam;
    }

    public isURL(value: string) {
        if (typeof value !== "string") {
            return false;
        }

        var match = value.match(URLHelper.protocolAndDomainRE);
        if (!match) {
            return false;
        }

        var everythingAfterProtocol = match[1];
        if (!everythingAfterProtocol) {
            return false;
        }

        if (
            URLHelper.localhostDomainRE.test(everythingAfterProtocol) ||
            URLHelper.nonLocalhostDomainRE.test(everythingAfterProtocol)
        ) {
            return true;
        }

        return false;
    }
}
