import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import Badge from "@mui/material/Badge";

import { appColors, backgroundColors } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import useChangeTheme from "src/hooks/useChangeTheme";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import { EyeHidden } from "../icons/eye-hidden.icon";
import { Eye } from "../icons/eye.icon";
import { useStyles } from "../styles";
import CloseIcon from "../../../../../assets/icons/tournaments/closeIcon";
import { reaction, runInAction, toJS } from "mobx";

interface IBadgeDeskMobile {
    currentTab: string;
    showHideOpponent: boolean;
    isChatBlocked?: boolean; 
}

const BadgeDesktop: React.FC<IBadgeDeskMobile> = observer(
    ({ currentTab, showHideOpponent, isChatBlocked }) => {
        const { gameStore, authStore } = useStores();
        const [lastUnreadMessage, setLastUnreadMessage] = useState<string | null>(null);
        const [lastReadMessageId, setLastReadMessageId] = useState<string | null>(null);

        const classes = useStyles();

        useEffect(() => {
            const disposer = reaction(
                () => toJS(gameStore.communication.messages),
                (messages) => {
                    const lastReadIndex = lastReadMessageId
                        ? messages.findIndex(msg => msg.time === lastReadMessageId)
                        : -1;

                    const lastMessage = messages[messages.length - 1];

                    if (lastMessage?.owner._id === authStore.currentUser?._id) {
                        runInAction(() => {
                            setLastUnreadMessage(null);
                        });
                        return;
                    }

                    const newUnreadMessage = messages
                        .slice(lastReadIndex + 1)
                        .filter((msg) => msg.owner._id !== authStore.currentUser?._id)
                        .map((msg) => msg.time)
                        .pop();

                    if (newUnreadMessage) {
                        runInAction(() => {
                            setLastUnreadMessage(newUnreadMessage);
                        });
                    }
                }
            );

            return () => disposer();
        }, [gameStore.communication.messages, lastReadMessageId]);

        useEffect(() => {
            if (!isChatBlocked) {
                setLastUnreadMessage(null);
            }
        }, [isChatBlocked]);

        const displayDot = !!lastUnreadMessage;

        return (
            <div className={classes.badgeContainer}>
                {displayDot && <span className={classes.badgeDot} />}
            </div>
        );
    }
);

export default BadgeDesktop;

