import { Cell, ECell } from './cell';
import { ChessMap } from './chess.map';
import { Figure } from './figure';

export interface IMovement {
  rowFrom: number;
  columnFrom: number;
  rowTo: number;
  columnTo: number;
  row2From?: number;
  column2From?: number;
  row2To?: number;
  column2To?: number;
  transformTo?: Figure;
  dropRow?: number;
  dropColumn?: number;
  castling?: string;
  castling2?: string;
}

export class Movement {
  protected previusFigure?: Figure;
  protected prevMovesId: number | null = 0;
  protected prevMovesId2: number | null = 0;
  protected prevMovesFigures: Figure[] | null;
  protected prevMovesFigures2: Figure[] | null;
  protected oldCell?: Cell;
  protected oldCell2?: Cell;
  protected oldDropCell?: Cell;

  public calcValue = 0;
  protected defaultCalValue = 1000000000;

  constructor(public movement: IMovement) {}

  resetCalcValue(): void {
    this.calcValue = this.defaultCalValue;
  }

  calcValueIsSet(): boolean {
    return this.calcValue !== this.defaultCalValue;
  }

  moveKilledKing() {
    if (this.oldCell) {
      return this.oldCell.figure && this.oldCell.figure.type === 'k';
    } else {
      throw Error('cannot use before move or after unmove');
    }
  }

  moveKilledFigure(): string | null {
    if (this.oldCell) {
      return this.oldCell.figure ? this.oldCell.figure.type : null;
    } else {
      throw Error('cannot use before move or after unmove');
    }
  }

  move(map: ChessMap, ignoreFigures = false, ignoreSecondFigure = true) {
    const { columnFrom, rowFrom, columnTo, rowTo, transformTo } = this.movement;

    const type = map.cells[rowFrom][columnFrom]?.figure?.type || '';

    if (!ignoreFigures) {
      this.prevMovesId = map.moves.findIndex(
        (figure: Figure) => figure.type.toLowerCase() === type.toLowerCase(),
      );

      this.prevMovesFigures = map.moves.splice(this.prevMovesId, 1);
    }

    this.oldCell = map.cells[rowTo][columnTo];

    map.cells[rowTo][columnTo] = map.cells[rowFrom][columnFrom];
    map.cells[rowFrom][columnFrom] = new Cell(
      '.',
      map.cells[rowTo][columnTo].type,
    );

    map.cells[rowTo][columnTo].type = this.oldCell.type;

    map.cells[rowTo][columnTo].figure?.onMove(map.moveStep);

    if (transformTo) {
      this.previusFigure = map.cells[rowTo][columnTo].figure;

      map.cells[rowTo][columnTo].figure = transformTo;
    }

    if (this.movement.castling && !ignoreSecondFigure) {
      map[this.movement.castling] = false;
    }
    if (this.movement.castling2 && !ignoreSecondFigure) {
      map[this.movement.castling2] = false;
    }

    if (this.movement.row2From || this.movement.column2From) {
      const {
        column2From = 0,
        row2From = 0,
        column2To = 0,
        row2To = 0,
      } = this.movement;

      const type2 = map.cells[row2From][column2From]?.figure?.type;

      this.oldCell2 = map.cells[row2To][column2To];

      map.cells[row2To][column2To] = map.cells[row2From][column2From];
      map.cells[row2From][column2From] = new Cell(
        '.',
        map.cells[row2To][column2To].type,
      );

      map.cells[row2To][column2To].type = this.oldCell2?.type;

      if (!ignoreFigures && !ignoreSecondFigure) {
        this.prevMovesId2 = map.moves.findIndex(
          (figure: Figure) => figure.type.toLowerCase() === type2?.toLowerCase(),
        );

        this.prevMovesFigures2 = map.moves.splice(this.prevMovesId2, 1);
      } else {
        this.prevMovesId2 = null;

        this.prevMovesFigures2 = null;
      }
    }

    if (this.movement.dropRow) {
      const { dropRow = 0, dropColumn = 0 } = this.movement;
      this.oldDropCell = map.cells[dropRow][dropColumn];

      map.cells[dropRow][dropColumn] = new Cell('.', this.oldDropCell.type);
    }
  }

  unmove(map: ChessMap) {
    if (this.oldDropCell) {
      const { dropRow = 0, dropColumn = 0 } = this.movement;

      map.cells[dropRow][dropColumn] = this.oldDropCell;
    }

    if ((this.movement.row2From || this.movement.column2To) && this.oldCell2) {
      const {
        column2From = 0,
        row2From = 0,
        column2To = 0,
        row2To = 0,
      } = this.movement;

      const prevType = map.cells[row2From][column2From].type;

      map.cells[row2From][column2From] = map.cells[row2To][column2To];
      map.cells[row2From][column2From].type = prevType;
      map.cells[row2To][column2To] = this.oldCell2;
    }

    const { columnFrom, rowFrom, columnTo, rowTo, transformTo } = this.movement;

    if (transformTo) {
      map.cells[rowTo][columnTo].figure = this.previusFigure;
    }

    map.cells[rowTo][columnTo].figure?.onUnMove();

    const prevType = map.cells[rowFrom][columnFrom].type;

    map.cells[rowFrom][columnFrom] = map.cells[rowTo][columnTo];
    map.cells[rowFrom][columnFrom].type = prevType;
    if (this.oldCell) {
      map.cells[rowTo][columnTo] = this.oldCell;
    }

    if (this.prevMovesFigures2) {
      map.moves.splice(this.prevMovesId2 || 0, 0, this.prevMovesFigures2[0]);
    }
    if (this.prevMovesFigures) {
      map.moves.splice(this.prevMovesId || 0, 0, this.prevMovesFigures[0]);
    }

    this.prevMovesFigures = null;
    this.oldCell = undefined;
    this.oldCell2 = undefined;
    this.oldDropCell = undefined;
  }

  isEnemyKing(map: ChessMap) {
    const to = map.cells[this.movement.rowTo][this.movement.columnTo];
    return to.figure && to.figure.type === 'k';
  }
}
