import React, { useState } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import { observer } from "mobx-react";

import { Select } from "src/shared/ui/Select";
import { useLocale } from "src/providers/LocaleProvider";
import { ObjectHelper } from "src/shared/helpers/ObjectHelper";
import { getRoundsConfig } from "src/entities/game";

import { useStyles } from "./styles";
import { defaultProps } from "./constants";

export interface IRoundsConfigProps {
    includeEntry?: boolean;
    value: number;
    onChange: (value: number) => void;
}

const RoundsConfig = (props: IRoundsConfigProps) => {
    const { includeEntry, value, onChange } = ObjectHelper.merge(
        props,
        defaultProps
    );

    const classes = useStyles();
    const {
        lobby: {
            createGame: { emptyRoundsLabel },
        },
    } = useLocale();

    const roundsOptions = getRoundsConfig(includeEntry).map((config) => ({
        value: config,
        label: config.toString(),
        icon: <FlagIcon className={classes.roundIcon} />,
    }));

    // return (
    //     <Select
    //         value={value}
    //         onChange={(value) => onChange(value)}
    //         options={roundsOptions}
    //         emptyLabel={emptyRoundsLabel("Select rounds")}
    //         emptyIcon={<FlagIcon className={classes.roundIcon} />}
    //         dropdownProps={{
    //             position: "top",
    //         }}
    //     />
    // );

    return null;
};

export default observer(RoundsConfig);
