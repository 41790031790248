import axios from "axios";
import { useEffect, useState } from "react";

import endpoints from "src/core/endpoints";

import {
    IGameProvider,
    IGetGameProviderResponse,
    GameProviderModel,
    ExternalGameType,
} from "../model";

export class GameProviderService {
    public static findAll(type: ExternalGameType) {
        return axios.get<IGetGameProviderResponse>(
            endpoints.externalGameProviders,
            {
                params: {
                    type,
                },
            }
        );
    }
}

export const useGameProviders = (gameType: ExternalGameType) => {
    const [providers, setProviders] = useState<IGameProvider[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await GameProviderService.findAll(gameType);
                const providers = response.data.providers.map(
                    (provider) => new GameProviderModel(provider)
                );
                setProviders(providers);
            } catch (e) {}
        })();
    }, [gameType]);

    return {
        providers,
    };
};
