import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { Box } from '@mui/material';


import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

type IModalTitles = {
    title: string | undefined;
    label?: string | undefined;
    placePosition?: string
};
const ModalTitles = ({ title, label, placePosition }: IModalTitles) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();

    return (
        <Box className={cn(styles.modalTitlesBox, appearanceTheme, label, placePosition)}>
            <p className={cn(styles.modalTitles, appearanceTheme, label, placePosition)}>
                {title}
            </p>
        </Box>
    );
};

export default observer(ModalTitles);
