import { ChessMap } from '../chess/chess.map';
import { King } from './king';

export class KingWhite extends King {
  moveVector = -1;

  constructor(public chessMapRef: ChessMap | null = null) {
    super(false, chessMapRef);
  }
}
