import React from "react";
import cn from "classnames";
import PulseLoader from "react-spinners/PulseLoader";

import LocaleText from "src/components/locale-text/locale.text";
import { useLocale } from "src/providers/LocaleProvider";
import { appColors, backgroundColors } from "src/consts/app-config";
import useChangeTheme from "src/hooks/useChangeTheme";
import UserAvatar from "src/components/UserAvatar";
import useStores from "src/hooks/useStores";
import descRandomMessageGenerator from "src/utils/descRandomMessageGenerator";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";

import FadeInOutVertical from "../../../../components/Animated";
import { endpointsRoot } from "../../../../core/endpoints";
import { IPlayer } from "../../../../store/models";

import useStyles from "./style";

interface Props {
    player: IPlayer;
    randomValue?: number | undefined;
}

const PlayerThinking = (props: Props) => {
    const { player, randomValue } = props;
    const {
        game: {
            dialogs: {
                x2: { randommessages },
            },
        },
    } = useLocale();
    const { gameStore } = useStores();
    const { isMultiTable } = useChessBoardProps();
    const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
    const generatedTitle = descRandomMessageGenerator(randomValue);
    const styles = useStyles({ doubleThinking, generatedTitle });
    const appearanceTheme = useChangeTheme();

    return (
        <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
            <div
                className={cn(styles.containerPlayerThinking, appearanceTheme, {
                    isMultiTable: isMultiTable,
                })}
            >
                <div className={styles.flexContainer}>
                    <div>
                        <UserAvatar
                            avatarUrl={
                                player.avatar &&
                                `${endpointsRoot}/avatars/${player.avatar}`
                            }
                            nickname={player.name}
                            className={cn(styles.avatarBlock, { isMultiTable })}
                            isSystemAvatar={player.isSystemAvatar}
                        />
                    </div>
                    <div
                        className={cn(styles.playerInfoWrapper, {
                            isMultiTable,
                        })}
                    >
                        <div
                            className={cn(styles.playerName, { isMultiTable })}
                        >
                            {doubleThinking && generatedTitle ? (
                                <>
                                    {randommessages[generatedTitle].compile(
                                        { playerNick: player.name },
                                        "{{generatedTitle}}"
                                    )}
                                    <PulseLoader
                                        color={appColors.white}
                                        size={isMultiTable ? 5 : 7}
                                        margin="3px"
                                    />
                                </>
                            ) : (
                                <>
                                    <LocaleText
                                        label="game.notifications.playerIsThinking"
                                        defaultValue="{{playerNick}} is thinking"
                                        props={{
                                            playerNick: player.name,
                                        }}
                                    />
                                    <PulseLoader
                                        color={appColors.white}
                                        size={isMultiTable ? 7 : 10}
                                    />
                                </>
                            )}

                            {/* {doubleThinking && generatedTitle ? (
                                <LocaleText
                                    label={thinkingMessage}
                                    defaultValue='{{generatedTitle}}'
                                    props={{
                                        playerNick: player.name,
                                    }}
                                />
                            ) : (
                                <LocaleText
                                    label='game.notifications.playerIsThinking'
                                    defaultValue='{{playerNick}} is thinking'
                                    props={{
                                        playerNick: player.name,
                                    }}
                                />
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </FadeInOutVertical>
    );
};

export default PlayerThinking;
