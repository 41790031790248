import { makeStyles } from "@mui/styles";
import { ColorVariant } from "../../../../../store/models";
import theme from "src/theme";

interface Props {
    shortColor: ColorVariant;
}
export const useStyles = makeStyles({
    row: {
        margin: "4px 0",
        display: "flex",
        opacity: 0.2,
        filter: "blur(4px)",
        "&.deepBlack": {},
    },

    rowAnimate: {
        margin: "4px 0",
        display: "flex",
        opacity: 0.2,
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationName: "$getAnimate",
        animationDelay: "0s",
        animationDuration: "2000ms",
        "&.deepBlack": {},
    },
    "@keyframes getAnimate": {
        "0%": {
            opacity: 0.2,
        },
        "100%": {
            opacity: 1,
        },
    },

    iconGameHistoryMessage: ({ shortColor }: Props) => ({
        boxSizing: "border-box",
        padding: 1,
        borderRadius: 2,
        backgroundColor:
            shortColor === "b"
                ? "rgba(255, 255, 255, 1)"
                : "rgba(55, 43, 69, 1)",
        boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.15)",
        "&.deepBlack": {
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.15)",
        },
    }),

    moveOnHistoryMessage: {
        marginLeft: 10,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "14px",
        lineHeight: "30px",
        color: `rgb(255, 255, 255, 0.8)`,
        [theme.breakpoints.up(1800)]: {
            fontSize: "24px",
            lineHeight: "30px",
        },
        "&.deepBlack": {
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "29px",
            letterSpacing: "0em",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("xl")]: {
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
            },
        },
    },
    dots: {
        marginLeft: 6,
        minWidth: 20,
        "&.deepBlack": {},
    },
});
