import React from "react";

import blitzPng from "./png_icons/blitz.png";
import lightningIcon from "src/assets/icons/lightning.svg";

export const BlitzIcon = (): JSX.Element => {
    return (
        <>
            <img src={lightningIcon} alt="blitz" />
        </>
    );
};
