import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    wrapperBurger: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 22,
        width: 28,
        cursor: 'pointer',
        position: 'relative',
    },
    burgerLine: {
        display: 'block',
        borderRadius: '10px',
        width: '100%',
        height: 3,
        background: '#A8B1CE',
        '&.line1': {
            transformOrigin: '0% 0%',
            transition: 'transform 0.4s ease-in-out',
            '&.line1Open': {
                transform: 'rotate(45deg)',
            },
        },
        '&.line2': {
            transition: 'transform 0.2s ease-in-out',
            '&.line2Open': {
                transform: 'scaleY(0)',
            },
        },
        '&.line3': {
            transformOrigin: '0% 100%',
            transition: 'transform 0.4s ease-in-out',
            '&.line3Open': {
                transform: 'rotate(-45deg)',
            },
        },
    },

    gameInfoDot: {
        display: 'block',
        width: 8,
        height: 8,
        borderRadius: '50%',
        position: 'absolute',
        top: '70%',
        right: '-50%',
        transform: 'translate(-100%, -20%)',
        background: '#61DB8B',
        zIndex: 2,
        animationName: '$gameInfoDotMain',
        animationDelay: '0.3s',
        animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        animationDuration: '4000ms',
        opacity: 0,
        '&:after': {
            content: '""',
            display: 'block',
            width: 10,
            height: 10,
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#61DB8B',
            zIndex: 2,
            animationName: '$gameInfoDot',
            animationDelay: '0.3s',
            animationIterationCount: 'infinite',
            animationFillMode: 'forwards',
            animationDuration: '4000ms',
        },
    },

    '@keyframes gameInfoDotMain': {
        '0%': {
            opacity: 0,
            filter: 'blur(2px)',
        },
        '50%': {
            opacity: 1,
            filter: 'blur(0px)',
        },
        '100%': {
            opacity: 0,
            filter: 'blur(2px)',
        },
    },

    '@keyframes gameInfoDot': {
        '0%': {
            transform: 'translate(-50%, -50%) scale(1)',
            opacity: 0,
        },
        '50%': {
            transform: 'translate(-50%, -50%) scale(2)',
            opacity: 0.5,
            filter: 'blur(5px)',
        },
        '100%': {
            transform: 'translate(-50%, -50%) scale(1)',
            opacity: 0,
        },
    },
});
