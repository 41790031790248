import { ChessMap } from './chess.map';
import { Movement } from './movement';

export class Figure {
  moves = 0;
  constructor(
    public isBlack: boolean,
    public type: string,
    public chessMapRef: ChessMap | null = null,
  ) {}

  onMove(step: number) {}
  onUnMove() {}

  getMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    return [];
  }

  getSafeMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    return [];
  }
  getAttackMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    return [];
  }

  canMove(map: ChessMap, toX: number, toY: number, isBlack: boolean) {
    if (toX < 0 || toY < 1) {
      return false;
    }
    if (toX > 7 || toY > 8) {
      return false;
    }

    if (!map.cells[toX][toY].figure) return true;

    return map.cells[toX][toY]?.figure?.isBlack !== isBlack;
  }
}
