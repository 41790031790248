import React from 'react';

export const Smile = ({ width, height }) => (
    <svg
        width={width | '16'}
        height={height | '16'}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g opacity='0.8'>
            <path
                d='M8 16C3.58867 16 0 12.4113 0 8C0 3.58867 3.58867 0 8 0C12.4113 0 16 3.58867 16 8C16 12.4113 12.4113 16 8 16ZM8 1.33333C4.324 1.33333 1.33333 4.324 1.33333 8C1.33333 11.676 4.324 14.6667 8 14.6667C11.676 14.6667 14.6667 11.676 14.6667 8C14.6667 4.324 11.676 1.33333 8 1.33333Z'
                fill='white'
            />
            <path
                d='M5.66663 7.33325C6.21891 7.33325 6.66663 6.88554 6.66663 6.33325C6.66663 5.78097 6.21891 5.33325 5.66663 5.33325C5.11434 5.33325 4.66663 5.78097 4.66663 6.33325C4.66663 6.88554 5.11434 7.33325 5.66663 7.33325Z'
                fill='white'
            />
            <path
                d='M10.3334 7.33325C10.8857 7.33325 11.3334 6.88554 11.3334 6.33325C11.3334 5.78097 10.8857 5.33325 10.3334 5.33325C9.78109 5.33325 9.33337 5.78097 9.33337 6.33325C9.33337 6.88554 9.78109 7.33325 10.3334 7.33325Z'
                fill='white'
            />
            <path
                d='M8 12.6667C6.34333 12.6667 4.796 11.7727 3.96266 10.3341C3.778 10.0154 3.88666 9.60739 4.20533 9.42339C4.52466 9.23872 4.932 9.34806 5.11666 9.66606C5.712 10.6947 6.81733 11.3334 8 11.3334C9.18333 11.3334 10.2887 10.6947 10.8833 9.66606C11.0673 9.34739 11.4733 9.23672 11.794 9.42339C12.1127 9.60739 12.222 10.0154 12.0367 10.3341C11.2047 11.7727 9.65733 12.6667 8 12.6667Z'
                fill='white'
            />
        </g>
    </svg>
);
