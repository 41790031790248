import { action, makeAutoObservable } from "mobx";

// TODO: maybe we should remove this store
export class GeneralLobbyStore {
    isOpenIdDataForm: boolean = false;
    isOpenIdDataPhotoForm: boolean = false;
    isBusinessModalOpen: boolean = false;
    isNotificationsModalOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setOpenIdDataForm(open: boolean) {
        this.isOpenIdDataForm = open;
    }
    @action
    setOpenIdDataPhotoForm(open: boolean) {
        this.isOpenIdDataPhotoForm = open;
    }

    setOpenBusinessModal(open: boolean) {
        this.isBusinessModalOpen = open;
    }

    setOpenNotificationsModal(open: boolean) {
        this.isNotificationsModalOpen = open;
    }
}

export default GeneralLobbyStore;
