import React, { useState } from "react";
import { Box } from "@mui/material";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { UserBalanceType } from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";

import useStyles from "./styles";
interface IMobilePot {
    isGamePage: boolean;
    chessboardType: string;
    children: any;
    resultData: any;
    balanceType: any;
    amountProp: any;
    classes?: any;
}

const MobilePot = ({
    children,
    resultData,
    balanceType,
    amountProp,
    classes,
}: IMobilePot) => {
    const {
        game: { pot },
    } = useLocale();
    const { gameStore } = useStores();
    const appearanceTheme = useChangeTheme();

    const styles = useStyles({});

    // if(!resultData) return null;

    return (
        <>
            <Box
                className={cn(styles.potToTop, styles[classes as string], {
                    // logical for move pot
                    // runToMid: resultData,
                })}
            >
                {/* <span className={cn(styles.bankTitle, appearanceTheme)}>
                    {pot("Pot")}
                </span> */}
                {children}
                <span
                    className={
                        balanceType === UserBalanceType.play
                            ? cn(
                                  styles.bankAmount,
                                  appearanceTheme,
                                  styles[classes as string],
                                  {
                                      runToMid: resultData,
                                  }
                              )
                            : cn(
                                  styles.bankFreeAmount,
                                  appearanceTheme,
                                  styles[classes as string],
                                  {
                                      runToMid: resultData,
                                  }
                              )
                    }
                >
                    {gameStore?.isReadyNew ? amountProp.toFixed(2) : "0"}
                </span>
            </Box>
        </>
    );
};

export default MobilePot;
