import axios, { AxiosResponse } from "axios";

import endpoints from "src/core/endpoints";
import { IFriend, ISearchUsersResponse, IUser } from "src/store/models";

import {
    IAcceptFriendRequest,
    ICancelFriendRequest,
    IFriendActionRequest,
    IFriendDeleteRequest,
    IRejectFriendRequest,
    IFriendAdd,
    IFriendRemove,
    IFriendActionResponse,
} from "./requestResponses";

export const getFriends = async () => {
    const response = await axios.get<{ friends: IFriend[] }>(endpoints.friends);
    return { ...response, data: response.data.friends };
};

export const addFriend = async (params: IFriendAdd) => {
    return await axios.post<IFriendActionResponse>(endpoints.addFriend, params);
};

export const removeFriend = async (params: IFriendRemove) => {
    return await axios.post<IFriendActionResponse>(endpoints.removeFriend, params);
};

export const approveFriend = async (params: IFriendAdd) => {
    return await axios.post<IFriendActionResponse>(endpoints.approveFriend, params);
};

export const getAllUsers = async () => {
    return await axios.get<IUser[]>(endpoints.users); 
};

export const searchUsers = async (query: string): Promise<AxiosResponse<ISearchUsersResponse>> => {
    return await axios.get<ISearchUsersResponse>(endpoints.searchUsers, { params: { query } });
};