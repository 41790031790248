export const bets = [
    0.1, 0.25, 0.5, 1, 2, 3, 5, 10, 25, 50, 100, 200, 300, 400, 500, 1000, 2000,
    3000, 5000,
];

export const freeBets = [
    0.1, 0.25, 0.5, 1, 2
];

export const matches = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const coinsBet = [1, 3, 5];
