import { useEffect, useState } from 'react';

const useCheckFullScreen = () => {
    const [screenStatus, setWindowStatus] = useState<boolean>(false);
    const handleFullscreen = () => {
        if (document.fullscreenElement) {
            return setWindowStatus(true);
        } else {
            return setWindowStatus(false);
        }
    };
    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreen);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreen);
        };
    }, []);

    return screenStatus;
};

export default useCheckFullScreen;
