import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';
import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';

import { useBlockCommonStyles } from '../../../styles';

export const useBetConfigStyles = makeStyles({
    wrapperBetConfig: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            '@media (orientation: portrait)': {
                height: 42,
            },
        },
        [theme.breakpoints.down('md')]: {
            height: 42,
        },
    },
    container: {
        ...useBlockCommonStyles.betConfigContainer,
        "&.fastGame": {
          [theme.breakpoints.down(1150)]: {
            padding: '7px 10px',
          },
        },
    },
    leftSideBox: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            '@media (orientation: portrait)': {
                maskImage: 'none',
            },
        },
        [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
                maxWidth: 'calc(100% - 17px)',
                width: 'calc(100% - 17px)',
            },
        },
        [theme.breakpoints.down(600)]: {
            '@media (orientation: portrait)': {
                maxWidth: 'calc(100% - 17px)',
                width: 'calc(100% - 17px)',
                maskImage:
                    'linear-gradient(to right,black 80%,transparent 100%)',
            },
        },
    },
    coinIcon: {
        width: 24,
        height: 24,
        objectFit: 'contain',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 9,
        [theme.breakpoints.down('md')]: {
            marginRight: 4,
            maxWidth: 'calc(100% - 17px)',
        },
        "&.fastGame": {
          [theme.breakpoints.down(1150)]: {
            marginRight: 0,
          },
        },
    },
    text: {
        color: appColors.white,
        fontWeight: 700,
        fontSize: 16,
        marginLeft: 4,
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'nowrap',
            fontSize: '14px',
        },
        "&.fastGame": {
          [theme.breakpoints.down(1150)]: {
            fontSize: '14px',
          },
        },
    },
    sortDown: {
        marginLeft: 9,
        transform: 'scale(1.3)',
        transition: 'all 0.1s ease-in-out',
    },
    sortDownOpen: {
        marginLeft: 9,
        transition: 'all 0.1s ease-in-out',
        transform: 'rotateX(180deg) scale(1.3) ',
        [theme.breakpoints.down('md')]: {
            marginLeft: 7,
        },
    },
});
