import React from "react";
import { useLocale } from "src/providers/LocaleProvider";

import ViewModeModal, { textType } from "../ViewModeModal";
import useStores from "src/hooks/useStores";
import { observer } from "mobx-react";
import { ColorVariant } from "src/store/models";

const OfferedRematchModal = () => {
  const { gameStore } = useStores();
  const rematchInfo = gameStore.dialogs.rematchData;

  const player1 = gameStore.currentGameNew?.players[0];
  const player2 = gameStore.currentGameNew?.players[1];

  const player =
    player1?._id === rematchInfo.rematchStartedBy ? player2 : player1;

  const {
    game: {
      rematch: {
        acceptOrReject: { title },
      },
      dialogs: {
        rematch: {
          offeringRematch: { isOfferingToOpponent }
        },
      },
    },
  } = useLocale();

  return (
    <ViewModeModal
      // title={isOfferingToOpponent.compile(
      //     {
      //         playerWhoOffers: player?.nickname ?? "Anonymous",
      //     },
      //     "{{playerWhoOffers}} offered rematch"
      // )}
      title={`${player?.nickname}, ${title("Let's play one more time")}`}
      titleType={textType.gray}
      messsageType={textType.gray}
    />
  );
};

export default observer(OfferedRematchModal);
