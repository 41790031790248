import { makeStyles } from '@mui/styles';
import theme from 'src/theme';
import { appColors, backgroundColors } from 'src/consts/app-config';
import { ISpeed, InfoMessage } from './types';

export const useStyles = makeStyles({
    '@global': {
        '@keyframes connected': {
            '0%': {
                display: 'block',
                transform: 'translateY(-30px)',
                opacity: 0,
                background:
                    'linear-gradient(to top, rgba(45,28,94,0) 13.3%, #50BAAE 86.7%)',
            },
            '30%': {
                display: 'block',
                transform: 'translateY(0px)',
                opacity: 1,
            },
            '70%': {
                display: 'block',
                transform: 'translateY(0px)',
                opacity: 1,
            },
            '99%': {
                display: 'block',
                transform: 'translateY(-30px)',
                opacity: 0,
            },
            '100%': {
                opacity: 0,
                transform: 'translateY(-30px)',
                display: 'none',
            },
        },
        '@keyframes slow': {
            '0%': {
                transform: 'translateY(-30px)',
                opacity: 0,
                background:
                    'linear-gradient(to top, rgba(45,28,94,0), rgba(57,38,112,0.8))',
            },
            '30%': {
                transform: 'translateY(-0px)',
                opacity: 1,
            },
            '100%': {
                transform: 'translateY(-0px)',
                opacity: 1,
            },
        },
        '@keyframes lost': {
            '0%': {
                transform: 'translateY(-30px)',
                opacity: 0,
                background:
                    'linear-gradient(to top, rgba(45,28,94,0), rgba(255,0,0,0.8))',
            },
            '30%': {
                transform: 'translateY(-0px)',
                opacity: 1,
            },
            '100%': {
                transform: 'translateY(-0px)',
                opacity: 1,
            },
        },
    },

    speedWarningWrapped: {
        position: 'absolute',
        width: '100%',
        maxHeight: '25px',
        top: 0,
        right: 0,
        outline: '1px solid rgba(45,28,94,0)',
        borderRadius: '8px',
        zIndex: 4,
        transform: 'translateY(-30px)',
        '&.connected': {
            transform: 'translateY(-30px)',
            opacity: 0,
            background:
                'linear-gradient(to top, rgba(45,28,94,0) 13.3%, #50BAAE 86.7%)',
            animationName: 'connected',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            animationDuration: '2500ms',
            animationDelay: '0s',
        },
        '&.lost': {
            transform: 'translateY(-30px)',
            opacity: 0,
            background:
                'linear-gradient(to top, rgba(45,28,94,0), rgba(255,0,0,0.8))',
            animationName: 'lost',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            animationDuration: '2000ms',
            animationDelay: '0s',
        },
        '&.slow': {
            transform: 'translateY(-30px)',
            opacity: 0,
            background:
                'linear-gradient(to top, rgba(45,28,94,0), rgba(57,38,112,0.8))',
            animationName: 'slow',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            animationDuration: '2000ms',
            animationDelay: '0s',
        },
        [theme.breakpoints.down('lg')]: {
            zIndex: 201,
        },
    },

    speedWarningModal: {
        display: 'flex',
        width: '100%',
        maxHeight: '100%',
        margin: '0 auto',
        fontWeight: 600,
        color: appColors.white,
        justifyContent: 'center',
        lineHeight: 0,
        fontSize: '13px',
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
        textShadow: '0px 1px 1px black',
    },
});
