import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocale } from "src/providers/LocaleProvider";

import useStores from "../../../../../hooks/useStores";
import ViewModeModal, { textType } from "../ViewModeModal";
import { ColorVariant } from "src/store/models";

const OfferedX2Modal = () => {
  const { gameStore } = useStores();
  const {
    game: {
      dialogs: {
        x2: { title, userIsOfferingDoubling },
      },
    },
  } = useLocale();

  const [randomValue, setRandomValue] = useState<number>(1);

  const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
  const player1 = gameStore.currentGameNew?.players[0];
  const player2 = gameStore.currentGameNew?.players[1];
  const curMove = gameStore.currentGameNew?.curMove;
  const curChessMove = gameStore.currentGameNew?.curChessMove;

  const player =
    player1?._id === curChessMove && curMove !== curChessMove
      ? player1
      : player2;

  useEffect(() => {
    if (doubleThinking) {
      let generateRandomNumber = (Math.random() * 10) | 0;
      if (generateRandomNumber && randomValue === generateRandomNumber) {
        setRandomValue(9 - generateRandomNumber);
      } else {
        setRandomValue(generateRandomNumber);
      }
    }
  }, [doubleThinking]);

  return (
    <ViewModeModal
      // title={userIsOfferingDoubling.compile(
      //   {
      //     playerWhoOffers: player?.nickname ?? "Anonymous",
      //   },
      //   "{{playerWhoOffers}} offered his opponent a doubling"
      // )}
      title={title("Let's raise?")}
      titleType={textType.gray}
      messsageType={textType.gray}
    />
  );
};

export default observer(OfferedX2Modal);
