import axios from "axios";

import {
    ExternalGameType,
    IGetVirtualSportGameResponse,
} from "src/entities/externalGame/model/types";

import endpoints from "../../../core/endpoints";

import {
    IGetSportsBettingUrlResponse,
    IJackPokerGetUrlResponse,
    IGetLiveDealersResponse,
    IGetGamesResponse,
    IGetGamesRequest,
} from "./requestResponses";

export const getBettingUrl = async () => {
    return await axios.post<IGetSportsBettingUrlResponse>(endpoints.betting);
};

export const getAlgNetAltenteUrl = async (id: string) => {
    return await axios.post<IGetSportsBettingUrlResponse>(
        endpoints.algAltenteGetUrl,
        {
            id,
        }
    );
};

export const getJackPokerUrl = async () => {
    return await axios.post<IJackPokerGetUrlResponse>(
        endpoints.getJackpokerUrl
    );
};

export const getAlgLiveDealerUrl = async (id: string) => {
    return await axios.post<IGetSportsBettingUrlResponse>(
        endpoints.algAltenteGetUrl,
        {
            id,
        }
    );
};

export const getGames = async ({
    offset,
    limit,
    query,
    providerId,
    isPopular,
    isFavorite,
    isNew,
    type,
}: IGetGamesRequest) => {
    const response = await axios.get<IGetGamesResponse>(
        endpoints.externalGames,
        {
            params: {
                offset,
                limit,
                query,
                providerId,
                isPopular,
                isFavorite,
                isNew,
                type,
            },
        }
    );
    return response;
};
