import loadable, {
    DefaultComponent,
    LoadableComponent,
    OptionsWithResolver,
} from '@loadable/component';

import PageLoader from '../PageLoader';

type LoadableWithFallbackType<T> = LoadableComponent<T> & {
    preload?: () => void;
};

const loadableWithFallback = <
    T extends React.ComponentType<any>,
    Props,
    Module = DefaultComponent<Props>
>(
    importFunc: () => Promise<{ default: T }>,
    options?: OptionsWithResolver<Props, Module>,
    loaderProps?: { isGamePage?: boolean }
): LoadableWithFallbackType<T> => {
    return loadable(importFunc, {
        fallback: <PageLoader {...loaderProps} />,
        ...options,
    });
};

export default loadableWithFallback;
