import { makeAutoObservable } from 'mobx';

import LobbyStore from './lobbyStore';
import AuthStore from './authStore';
import ViewHistoryGameStore from './viewGameStore';
import GeneralStore from './generalStore';
import FriendsStore from './friendsStore';
import GamesHistoryStore from './gamesHistoryStore';
import TournamentsStore from './tournamentsStore';
import GamesStore from './gamesStore';
import GeneralLobbyStore from './generalLobbyStore';
import ExternalGamesStore from './externalGamesStore';
import AchievementsStatStore from './achievementsStatStore';

export class RootStore {
    constructor() {
        makeAutoObservable(this);
    }
    lobbyStore: LobbyStore = new LobbyStore(this);
    generalLobbyStore: GeneralLobbyStore = new GeneralLobbyStore();
    authStore: AuthStore = new AuthStore(this);
    gamesStore: GamesStore = new GamesStore(this);
    viewHistoryGameStore: ViewHistoryGameStore = new ViewHistoryGameStore(this);
    generalStore: GeneralStore = new GeneralStore();
    friendsStore: FriendsStore = new FriendsStore();
    gamesHistoryStore: GamesHistoryStore = new GamesHistoryStore();
    tournamentsStore: TournamentsStore = new TournamentsStore(this);
    externalGamesStore: ExternalGamesStore = new ExternalGamesStore();
    achievementsStatStore: AchievementsStatStore = new AchievementsStatStore(this);
}
