import React from "react";
import { animated, useSpring } from "react-spring";
import { isSafari } from "react-device-detect";
import { observer } from "mobx-react";

import useStores from "src/hooks/useStores";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";

const FadeInOutVertical: React.FC<{
    children: JSX.Element;
    delaynumber?: number | undefined;
    isPlayerInfo?: boolean | undefined;
    className?: string;
    stylesProp?: any;
}> = (props) => {
    const { gameStore } = useStores();

    const style = useSpring({
        from: {
            opacity: 0,
            transform: "translate3d(0,20px,0) scale(0.9)",
            "-webkit-transform": "translate3d(0,20px,0) scale(0.9)",
        },
        to: {
            opacity: 1,
            transform: `translate3d(0, 0, 0) scale(1)`,
            "-webkit-transform": `translate3d(0, 0, 0) scale(1)`,
        },
        delay: props?.delaynumber,
    });

    const openFigure = gameStore?.dialogs?.selectFiguresDialog?.isOpen;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const DSK_WIDTH = MAX_CHANGE_SCREEN_WIDTH;

    const chooseFigure = () => {
        return openFigure && width >= DSK_WIDTH
            ? {
                  alignItems: "center",
                  height: "calc(100% - 95px)",
              }
            : openFigure && width < DSK_WIDTH
            ? { alignItems: "center" }
            : { alignItems: "center" };
    };

    const elementProperties = {
        height: "100%",
        display: "flex",
        width: "100%",
        ...props.stylesProp,
        // ...chooseFigure(),
    };

    if (isSafari) {
        return (
            <div
                style={{
                    justifyContent: "center",
                    ...elementProperties,
                }}
                className={props.className}
            >
                {props.children}
            </div>
        );
    }

    return (
        <animated.div
            style={{
                justifyContent: "center",
                ...elementProperties,
                ...style,
            }}
            className={props.className}
        >
            {props.children}
        </animated.div>
    );
};
export default observer(FadeInOutVertical);

export const FadeIn: React.FC<{
    children: React.ReactNode;
    delaynumber?: number | undefined;
    className?: string;
}> = (props) => {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: "translate3d(-10px,0,0)",
            "-webkit-transform": "translate3d(-10px,0,0)",
        },
        to: {
            opacity: 1,
            transform: `translate3d(0, 0, 0)`,
            "-webkit-transform": `translate3d(0, 0, 0)`,
        },
    });

    const elementProperties = {
        height: "100%",
        display: "flex",
        width: "100%",
        overflow: "hidden",
    };

    if (isSafari) {
        return (
            <div
                style={{
                    ...elementProperties,
                    position: "relative",
                }}
                className={props.className}
            >
                {props.children}
            </div>
        );
    }

    return (
        <>
            <animated.div
                style={{
                    ...elementProperties,
                    position: "relative",
                    ...style,
                }}
                className={props.className}
            >
                {props.children}
            </animated.div>
        </>
    );
};
