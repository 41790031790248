import React from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from '@mui/material';
import cn from 'classnames';

import LocaleText from 'src/components/locale-text/locale.text';
import coinIcon from 'src/assets/icons/coin.svg';
import freeCoin from 'src/assets/icons/freeCoin.svg';
import bonusCoin from 'src/assets/icons/bonusCoin.svg';
import halykGoldCoin from 'src/assets/Halyk/coinTengeG.svg';  
import halykTenge from 'src/assets/Halyk/coinTengeB.svg';
import theme from 'src/theme';

import { UserBalanceType } from '../../store/models';

import { useStyles } from './styles';
import { roundBalance } from './utils';
import { ToggleHidden } from './components/ToggleHidden';
import useStores from 'src/hooks/useStores';

interface Props {
    amount: number;
    hidden: boolean;
    onChange?: (v: boolean, b: string) => void;
    type: UserBalanceType;
    backGroundColor: string;
    onClick: () => void;
    showEye?: boolean;
    className?: string;
}

const Balance = (props: Props) => {
    const classes = useStyles();
    const { generalStore } = useStores();

    const coinIcons = {
        [UserBalanceType.coins]: freeCoin,
        [UserBalanceType.play]: generalStore.isHalyk ? halykGoldCoin : coinIcon,
        [UserBalanceType.referralBonus]: generalStore.isHalyk ? halykTenge : bonusCoin,
    };

    const textClasses = {
        [UserBalanceType.play]: classes.amount,
        [UserBalanceType.coins]: classes.amountCoins,
        [UserBalanceType.referralBonus]: classes.amount,
    };

    return (
        <div className={cn(classes.wrapper, props.className)}>
            {props.showEye && (
                <ToggleHidden
                    onChange={props.onChange}
                    hidden={props.hidden}
                    backGroundColor={props.backGroundColor}
                />
            )}
            <div className={classes.balancesContainer}>
                <div
                    className={cn(
                        classes.balanceWrapper,
                        classes.balanceWrapperSkeleton,
                        {
                            gold: props.type === UserBalanceType.play,
                            silver: props.type === UserBalanceType.coins,
                            noBackground: props.type === UserBalanceType.referralBonus,
                        }
                    )}
                >
                    <div className={classes.iconWrapper}>
                        <img
                            src={coinIcons[props.type]}
                            className={classes.coinIcon}
                            onClick={props.onClick}
                        />
                    </div>
                    <div className={classes.amountWrapper}>
                        <span
                            className={cn(
                                textClasses[props.type],
                                classes.amountSkeleton
                            )}
                        >
                            {roundBalance(props.amount)}
                        </span>
                    </div>
                </div>

                <div
                    className={cn(
                        classes.balanceWrapper,
                        classes.mainBalanceWrapper,
                        {
                            gold: props.type === UserBalanceType.play,
                            silver: props.type === UserBalanceType.coins,
                            noBackground: props.type === UserBalanceType.referralBonus,
                        }
                    )}
                >
                    <img
                        src={coinIcons[props.type]}
                        className={classes.coinIcon}
                        onClick={props.onClick}
                    />
                    <div className={classes.amountWrapper} onClick={props.onClick}>
                        {generalStore.isBalanceHidden ? (
                            <span className={classes.hiddenBalance}>
                                <span>*****</span>
                            </span>
                        ) : (
                            <span className={cn(textClasses[props.type])}>
                                {!isNaN(props.amount) ? roundBalance(props.amount) : 0}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Balance);
