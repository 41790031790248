export const OPPONENT_INFO_HEIGHT = 100;
export const OPPONENT_INFO_PADDING = 0;
export const OPPONENT_TOP_PADDING =
    OPPONENT_INFO_HEIGHT + OPPONENT_INFO_PADDING;

export const MAX_CHANGE_SCREEN_WIDTH = 1250;
export const MIN_CHANGE_SCREEN_WIDTH = 1251;

export const MIN_XL_TABLET = 1023;
export const MAX_XL_TABLET = 1024;

export const MIN_L_TABLET = 767;
export const MAX_L_TABLET = 768;

export const DARKGREEN_ALERTS2 =
    "linear-gradient(19.92deg, rgba(1, 1, 1, 0.7) 13.3%, rgba(14, 15, 15, 0.7) 86.7%)";

export const DARKGREEN_ALERTS = "#374949";

export const deskGamePagePaddings = {
    x: 48,
    y: 58,
};
