import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    fullscreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.55)',
        zIndex: 6,
        backdropFilter: 'blur(15px)',
        [theme.breakpoints.down('lg')]: {
            zIndex: 4,
            backdropFilter: 'blur(15px)',
        },
    },

    fullscreenStatic: {
        position: 'static',
    },

    absoluteWrapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex: 6,
        backdropFilter: 'blur(5px)',
        left: 0,
        top: 0,
    },

    center: {
        left: '50%',
        top: '50%',
        width: 60,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
});
