import { lineHeight } from "@mui/system";
import { makeStyles } from "@mui/styles";

import {
    DARKGREEN_ALERTS,
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
} from "src/pages/game-new/constants";
import theme from "src/theme";

import { appColors, backgroundColors } from "src/consts/app-config";

interface IPlayer {
    doubleThinking?: boolean;
    generatedTitle?: false | 1 | 2 | 3 | undefined;
}

const useStyles = makeStyles({
    containerPlayerThinking: {
        background: backgroundColors.darkBlue,
        width: "100%",
        // padding: "15px 25px",
        position: "relative",
        borderRadius: 8,
        padding: "15px",
        filter: "drop-shadow(0px 9.65126px 9.65126px rgba(0, 0, 0, 0.25))",
        "&.darkBlue": {
            background: backgroundColors.darkBlue,
            border: "1px solid #343A59",
            "@media (orientation: portrait)": {
                border: "0px solid #343A59",
            },
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
            "@media (orientation: portrait)": {
                border: "0px solid #004348",
            },
        },
        "&.deepBlack": {
            background: backgroundColors.darkBlue,
            border: "1px solid #343A59",
            "@media (orientation: portrait)": {
                border: "0px solid #343A59",
            },
        },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            padding: "10px 17px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "10px 17px",
        },
        [theme.breakpoints.up(1800)]: {
            width: "fit-content",
            minWidth: "80%",
        },
        [theme.breakpoints.up(2400)]: {},
        "&.isMultiTable": {
            padding: "3px",
            marginRight: "10px",
            width: "100%",
            maxWidth: "320px",
            [theme.breakpoints.up(1800)]: {
                width: "fit-content",
                transform: "scale(1)",
                marginBottom: 0,
                padding: "8px",
                minWidth: "auto",
            },
        },
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
    },
    playerInfoWrapper: {
        paddingLeft: 20,
        "&.isMultiTable": {
            paddingLeft: 9,
        },
    },
    avatarBlock: {
        width: 60,
        // height: 60,
        aspectRatio: "1 / 1",
        padding: 1,
        "&.isMultiTable": {
            width: 45,
            height: 45,
        },
        "& .nickname-box": {
            fontSize: 18,
        },
        "@media screen and (max-width: 768px)": {
            width: 45,
            height: 45,
            padding: 1,
            "& .nickname-box": {
                fontSize: 15,
            },
        },
    },

    playerName: ({ doubleThinking, generatedTitle }: IPlayer) => ({
        fontSize: 22,
        fontWeight: 900,
        color: "#CCCCCC",
        margin: 0,
        marginBottom: 10,
        ...(doubleThinking &&
            generatedTitle && {
                fontSize: 30,
                display: "flex",
                flexWrap: "wrap",
                marginBottom: 0,
                margin: "5px 0px 0px 0px",
                lineHeight: "1px",
            }),
        "@media screen and (max-width: 1350px)": {
            ...(doubleThinking &&
                generatedTitle && {
                    fontSize: 26,
                }),
        },
        "@media screen and (max-width: 1300px)": {
            fontSize: 20,
            ...(doubleThinking &&
                generatedTitle && {
                    fontSize: 24,
                }),
        },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            fontSize: 16,
            ...(doubleThinking &&
                generatedTitle && {
                    fontSize: 16,
                }),
        },
        "&.isMultiTable": {
            fontSize: 18,
            [theme.breakpoints.down(1600)]: {
                fontSize: 16,
            },
        },
    }),

    loadingPulse: {},
    infoWrapper: {
        height: "fit-content",
        lineHeight: 0,
    },
    infoText: {
        lineHeight: 0,
    },
});

export default useStyles;
