import React, { FC } from 'react';
import { config, Transition } from 'react-spring';

type TransitionWithUnMountProps = {
    show: boolean;
    from: object;
    leave: object;
    enter: object;
    children: any;
    delay?: number;
};

const TransitionWithUnMount = ({
    children,
    show,
    from,
    enter,
    leave,
    delay
}: TransitionWithUnMountProps) => {
    return (
        <Transition
            items={show}
            from={from}
            enter={enter}
            leave={leave}
            config={config.stiff}
            delay={delay}
        >
            {(styleProps, isExpanded) => {
                return isExpanded && children(styleProps);
            }}
        </Transition>
    );
};

export default TransitionWithUnMount;
