import { action, computed, makeAutoObservable } from "mobx";

import GameStore from "./gameStore";
import { GameType, IGameTable } from "./models";

import { RootStore } from ".";

const emptyGameTables = new Array(4).fill({
    id: null,
    type: null,
    isMenu: true,
    state: null,
});

class GamesStore {
    private gameStores: Record<string, GameStore> = {};
    gameTables: IGameTable[] = emptyGameTables;
    gameIdsToChoose: string[] = [];

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    getGameStoreById(id: string): GameStore | undefined {
        return this.gameStores[id];
    }

    @action
    addGameStore(id: string, gameStore: GameStore): void {
        this.gameStores[id] = gameStore;
    }

    @action
    removeGameStoreById(id: string) {
        delete this.gameStores[id];
    }

    @action
    createGameStore(): GameStore {
        const newGameStore = new GameStore(this.rootStore);
        return newGameStore;
    }

    @action
    createAndAssignToGameTable(id: string) {
        const alreadyExists = this.gameTables.some((t) => t.id === id);
        if (alreadyExists) return true;
        const emptyTableIndex = this.gameTables.findIndex((t) => t.isMenu);
        const emptyLobbyIndex = this.gameTables.findIndex(
            (t) => !t.id && !t.isMenu && t.type === GameType.dicechess
        );
        if (emptyTableIndex === -1 && emptyLobbyIndex === -1) return false;
        const newGameStore = this.createGameStore();
        newGameStore.setGameId(id);
        const indexToUpdate =
            emptyLobbyIndex === -1 ? emptyTableIndex : emptyLobbyIndex;

        this.updateGameTableByIndex(indexToUpdate, {
            id: id,
            type: GameType.dicechess,
            state: "game",
            isMenu: false,
        });
        return true;
    }

    @action
    resetGameTables(): void {
        this.gameTables = emptyGameTables;
    }

    @action
    setGameTables(gameTables: IGameTable[]) {
        this.gameTables = gameTables;
    }

    @action
    updateGameTableByIndex(i: number, newData: Partial<IGameTable>) {
        this.gameTables = this.gameTables.map((gameTable, j) => {
            if (i === j) return { ...gameTable, ...newData };
            return gameTable;
        });
    }

    @action
    setGameIdsToChoose(gameIds: string[]) {
        this.gameIdsToChoose = gameIds;
    }

    @action
    addGameIdToChoose(gameId: string) {
        this.gameIdsToChoose.push(gameId);
    }

    @action
    addGameTable(gameTable: IGameTable) {
        this.gameTables.push(gameTable);
    }

    @computed
    getTableById(id: string) {
        return this.gameTables.find((table) => {
            return table.id === id;
        });
    }

    @computed
    getTablesWithId() {
        return this.gameTables.filter((table) => {
            return table.id;
        });
    }
}

export default GamesStore;
