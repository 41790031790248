import React, {useMemo, useState} from "react";
import cn from "classnames";
import {observer} from "mobx-react";
import {Link, useHistory} from "react-router-dom";

import {useLocale} from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import GCLogo from "src/assets/images/gc-logo.png";
import LogoIcon from "src/components/controls/app-bar/images/logo.png";
import businessIcon from "src/pages/lobbyNew/assets/briefcase.svg";
import VisaIcon from 'src/assets/icons/social/visaIcon.svg';
import MastercardIcon from 'src/assets/icons/social/masterCardIcon.svg';
import CoinIcon from 'src/assets/icons/social/sbpIcon.svg';
import SberIcon from 'src/assets/icons/social/sberIcon.svg';
import BitcoinIcon from 'src/assets/icons/social/bircoinIcon.svg';
import TetherIcon from 'src/assets/icons/social/tetherIcon.svg';
import EthereumIcon from 'src/assets/icons/social/etherIcon.svg';
import BinanceIcon from 'src/assets/icons/social/binanceIcon.svg';
import EnvelopeIcon from 'src/assets/icons/social/EnvelopeIcon.svg';
import WhatsappIcon from 'src/assets/icons/social/WhatsappIcon.svg';
import TelegramIcon from 'src/assets/icons/social/TelegramIcon.svg';
import halykBankIcon from 'src/assets/Halyk/halykBank.svg';
import kaspiBankIcon from 'src/assets/Halyk/kaspi-bank.svg';
import BtcIcon from 'src/assets/Halyk/Bitcoin_logo.svg';
import TthIcon from 'src/assets/Halyk/teth.svg';
import TethBankIcon from 'src/assets/Halyk/tether-usdt.svg';
import DiscordIcon from 'src/assets/icons/social/discordIcons.svg';
import TelegramsIcon from 'src/assets/icons/social/telegramIcons.svg';
import YoutubeIcon from 'src/assets/icons/social/youtubeIcons.svg';
import InstagramIcon from 'src/assets/icons/social/instaIcons.svg';
import TiktokIcon from 'src/assets/icons/social/tiktokIcons.svg';
import paths, {GSLink} from "src/consts/paths";
import {useMediaQuery} from "@mui/material";
import BusinessModal from 'src/components/wrapper/components/businessModal/index';
import { socialPaths } from "src/consts/paths";



import {ModalTypes} from "../../../pages/landing/utils/context";

import {useStyles} from "./styles";


interface IFooter extends React.HTMLAttributes<HTMLDivElement> {
    isMainPage?: boolean;
}

export const Footer = observer(({ isMainPage }: IFooter) => {
    const devType = process.env.REACT_APP_NODE_ENV;
    const GSLinkEnv = process.env.REACT_APP_GS_ENV;
    const GSLinkEnvMocked = "beturanga.com";

    const showGSLink = () => {
        const urlHref = new URL(window.location.href);
        if (
            urlHref.toString().includes(GSLinkEnvMocked) &&
            urlHref.hostname.toString().includes(GSLinkEnvMocked)
        ) {
            return true;
        }
        return false;
    };

    const showLink = useMemo(
        () => showGSLink(),
        [devType, GSLinkEnv, GSLinkEnvMocked]
    );

    const classes = useStyles();
    const { generalStore, generalLobbyStore } = useStores();
    const {
        footer: {
            privacyPolicy,
            contactUs,
            termAndConditions,
            responsibleGaming,
            amlPolicy,
        },
        aboutUs: {
            newContent: { contentMain },
        },
    } = useLocale();
    const navigate = useHistory();

    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        businessMsg: false,
        notifications: false,
    });
    const [isBusinessModalOpen, setIsBusinessModalOpen] = useState(false);

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const aboutUsText = [
        `Copyright (C) 2024 beturanga.com is owned and operated by R1 Entertainment N.V. as a License Holder (Curacao license No. 365/ Jaz).Rebux Ltd. provides processing service on the website as a Billing Agent and with a registered office located at Kalymnou 1, "Q MERITO" 4th Floor, Agios Nikolaos, 6037 Larnaca, Cyprus,(reg. number HE 432622). All rights reserved and protected by law.`,
    ];

    const aboutUsTextHalyk = [
        `Copyright (C) 2024 halyk.games is owned and operated by R1 Entertainment N.V. as a License Holder (Curacao license No. 365/ Jaz).Rebux Ltd. provides processing service on the website as a Billing Agent and with a registered office located at Kalymnou 1, "Q MERITO" 4th Floor, Agios Nikolaos, 6037 Larnaca, Cyprus,(reg. number HE 432622). All rights reserved and protected by law.`,
    ];

    const isMobile = useMediaQuery("(max-width: 701px)");
    const isLaptop = useMediaQuery("(min-width: 702px) and (max-width: 1200px)");

    const handleWhatsAppClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const phoneNumber = '905395184851';
        const message = 'Здравствуйте! У меня есть вопрос.';
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
    };

    const handleTelegramClick = (event) => {
        event.preventDefault();
        window.open('https://t.me/dicechess_support', '_blank', 'noopener,noreferrer');
    };

    const handleWhatsAppClickBt = (event) => {
        event.preventDefault();
        window.open('https://wa.me/66660479829', '_blank', 'noopener,noreferrer');
    };

    const handleContactsClick = (event) => {
        event.preventDefault();
        navigate.push(paths.support);
    };

    const FollowUsSection = () => (
        <div className={classes.followUsContainer}>
            <p className={classes.followUsTitle}>Follow us</p>
            <div className={classes.followIcons}>
                {/*<a href={socialPaths.discord} target="_blank" rel="noopener noreferrer">*/}
                {/*    <img src={DiscordIcon} alt="Discord" className={classes.folowIcon} />*/}
                {/*</a>*/}
                <a href={socialPaths.telegram} target="_blank" rel="noopener noreferrer">
                    <img src={TelegramIcon} alt="Telegram" className={classes.folowIcon} />
                </a>
                <a href={socialPaths.youtube} target="_blank" rel="noopener noreferrer">
                    <img src={YoutubeIcon} alt="Youtube" className={classes.folowIcon} />
                </a>
                <a href={socialPaths.instagram} target="_blank" rel="noopener noreferrer">
                    <img src={InstagramIcon} alt="Instagram" className={classes.folowIcon} />
                </a>
                <a href={socialPaths.tiktok} target="_blank" rel="noopener noreferrer">
                    <img src={TiktokIcon} alt="TikTok" className={classes.folowIcon} />
                </a>
            </div>
        </div>
    );

    const BusinessSection = () => (
        <div className={classes.businessContainer}>
            {!generalStore.isHalyk && (
                <>
                    {/*<div*/}
                    {/*    className={classes.business}*/}
                    {/*    onClick={() => {*/}
                    {/*        setIsBusinessModalOpen(true);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <img*/}
                    {/*        src={businessIcon}*/}
                    {/*        className={classes.businessIcon}*/}
                    {/*    />*/}
                    {/*    <span>Business</span>*/}
                    {/*</div>*/}
                    <div className={classes.social}>
                        <span className={classes.contactUsText}>Contact us</span>
                        <div className={classes.socialIcons}>
                            <img src={EnvelopeIcon} alt="Envelope" className={classes.socialIcon}
                                 onClick={handleContactsClick} />
                            <img
                                src={WhatsappIcon}
                                alt="Whatsapp"
                                className={classes.socialIcon}
                                onClick={handleWhatsAppClickBt}
                                style={{ cursor: 'pointer' }}
                            />
                            <img
                                src={TelegramIcon}
                                alt="Telegram"
                                className={classes.socialIcon}
                                onClick={handleTelegramClick}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                </>
            )}
            {generalStore.isHalyk && (
                <div className={classes.social} onClick={() => navigate.push(paths.faq)}>
                    <span className={classes.contactUsText}>Contact us</span>
                    <div className={classes.socialIcons}>
                        <img
                            src={WhatsappIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleWhatsAppClick(e);
                            }}
                            alt="Whatsapp"
                            className={classes.socialIcon}
                        />
                        <img src={TelegramIcon} alt="Telegram" className={classes.socialIcon} />
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div
            className={cn(classes.footer, {
                isMainPage: isMainPage,
            })}
        >
            {!generalStore.isHalyk && (
                <>
                    <div className={classes.logoContainer}>
                        <img src={LogoIcon} className={classes.logoIcon} />
                        <img src={GCLogo} className={classes.gcLogo} />
                    </div>
                    <div
                        className={cn(classes.aboutUsBox, {
                            isMainPage: isMainPage,
                        })}
                    >
                        <p
                            className={cn(classes.aboutUsText, {
                                isMainPage: isMainPage,
                                showLink: showLink,
                            })}
                        >
                            {contentMain(aboutUsText)}
                        </p>
                    </div>
                </>
            )}

            {isMobile && !generalStore.isHalyk && <FollowUsSection />}

            <div
                className={cn(classes.footerNavlinksWrapper, {
                    isMainPage: isMainPage,
                })}
            >
                <div className={classes.linksSection}>
                    <Link
                        to={paths.policy}
                        onClick={() => {
                            generalStore.closeSideMenu();
                        }}
                        className={cn(classes.link, { isMainPage: isMainPage })}
                    >
                        {amlPolicy("AML Policy")}
                    </Link>
                    <Link
                        to={paths.termAndConditions}
                        onClick={() => {
                            generalStore.closeSideMenu();
                        }}
                        className={cn(classes.link, { isMainPage: isMainPage })}
                    >
                        {termAndConditions("Terms and conditions")}
                    </Link>
                    <Link
                        to={paths.responsibleGaming}
                        onClick={() => {
                            generalStore.closeSideMenu();
                        }}
                        className={cn(classes.link, { isMainPage: isMainPage })}
                    >
                        {responsibleGaming("Responsive gaming")}
                    </Link>
                    <Link
                        to={paths.privacyPolicy}
                        onClick={() => {
                            generalStore.closeSideMenu();
                        }}
                        className={cn(classes.link, { isMainPage: isMainPage })}
                    >
                        {privacyPolicy("Privacy policy")}
                    </Link>
                </div>

                {isLaptop && !generalStore.isHalyk && (
                    <div className={classes.businessAndFollowUs}>
                        <FollowUsSection />
                        <BusinessSection />
                    </div>
                )}

                {!isLaptop && <BusinessSection />}

                <div className={classes.iconContainer}>
                    {generalStore.isHalyk ? (
                        <>
                            <img src={halykBankIcon} alt="halykBank" className={classes.payIcon} />
                            <img src={kaspiBankIcon} alt="kaspiBank" className={classes.payIcon} />
                            <img src={BtcIcon} alt="btc" className={classes.payIcon} />
                            <img src={TthIcon} alt="tht" className={`${classes.payIcon} ${classes.smallIcon}`} />
                            <img src={TethBankIcon} alt="teth" className={`${classes.payIcon} ${classes.smallIcon}`} />
                        </>
                    ) : (
                        <>
                            <div className={classes.bankIconsContainer}>
                                {[VisaIcon, MastercardIcon, CoinIcon, SberIcon, BitcoinIcon, TetherIcon, EthereumIcon, BinanceIcon].map((icon, index) => (
                                    <div key={index} className={classes.bankIconWrapper}>
                                        <img
                                            src={icon}
                                            alt={`Bank Icon ${index + 1}`}
                                            className={`${classes.payIcon} ${index === 7 ? classes.smallIcon : ''}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <BusinessModal
                isOpen={isBusinessModalOpen}
                onClose={() => setIsBusinessModalOpen(false)}
            />
        </div>
    );
});
