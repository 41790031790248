import { makeStyles } from "@mui/styles";

import { appColors, redIconStyle } from "src/consts/app-config";
import theme from "src/theme";
const width = window.innerWidth;

export const useStyles = makeStyles({
    "@global": {
        "@keyframes MoveGradient": {
            "0%": {
                backgroundPosition: "0% 50%",
                opacity: 1,
            },
            "35%": {
                backgroundPosition: "100% 50%",
                opacity: 1,
            },
            "70%": {
                backgroundPosition: "0% 50%",
                opacity: 1,
            },
            "95%": {
                backgroundPosition: "0% 50%",
                opacity: 1,
            },
            "100%": {
                backgroundPosition: "0% 50%",
                opacity: 1,
            },
        },
    },

    rakeBackModalWrapper: {
        [theme.breakpoints.down("md")]: {
            opacity: 0,
            position: "absolute",
            top: "5px",
            right: "0%",
            width: "fit-content",
            margin: "0 auto",
            animationName: "$rakeBackModalWrapperMobile",
            animationDelay: "0.9s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "4000ms",
        },
        "@media screen and (min-width: 768px)": {
            opacity: 0,
            position: "absolute",
            top: "-80px",
            right: "0%",
            width: "fit-content",
            margin: "0 auto",
            animationName: "$rakeBackModalWrapper",
            animationDelay: "0.9s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "4000ms",
            zIndex: 2,
        },
        "@media screen and (min-width: 1251px) and (max-height: 700px)": {
            top: "-60px",
        },
    },
    rakeBackFlexContainer: {
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
        borderRadius: "8px",
        padding: "2px 5px",
        "&.darkBlue": {
            background:
                "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgb(13 17 36) 68%)",
        },
        "&.darkGreen": {
            background:
                "linear-gradient(19.92deg, #0D5C52 13.3%, #50BAAE 86.7%)",
        },
        "&.deepBlack": {
            background:
                "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgb(13 17 36) 68%)",
        },
        [theme.breakpoints.down("lg")]: {
            width: "fit-content",
            height: "fit-content",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            margin: "0 auto",
            padding: "3px 5px",
            position: "relative",
            "&:before": {
                display: "block",
                content: "''",
                opacity: 1,
                position: "absolute",
                zIndex: -1,
                top: "-2px",
                left: "-2px",
                bottom: "-2px",
                right: "-2px",
                borderRadius: "10px",
                backgroundSize: "200% 200%",
                background: `linear-gradient(
            60deg,
            hsl(156, 49%, 10%),
            hsl(180, 50%, 15%),
            hsl(180, 100%, 95%),
            hsl(180, 50%, 15%),
            hsl(156, 49%, 10%))`,
                animationName: "MoveGradient",
                animationIterationCount: "infinite",
                animationFillMode: "forwards",
                animationDuration: "3000ms",
                animationDelay: "0s",
            },
        },
        "@media screen and (min-width: 1025px)": {
            width: "fit-content",
            height: "fit-content",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            margin: "0 auto",
            padding: "5px 7px",
            position: "relative",
            "&:before": {
                display: "block",
                content: "''",
                opacity: 1,
                position: "absolute",
                zIndex: -1,
                top: "-2px",
                left: "-2px",
                bottom: "-2px",
                right: "-2px",
                borderRadius: "10px",
                backgroundSize: "200% 200%",
                background: `linear-gradient(
            60deg,
            hsl(156, 49%, 10%),
            hsl(180, 50%, 15%),
            hsl(180, 100%, 95%),
            hsl(180, 50%, 15%),
            hsl(156, 49%, 10%))`,
                animationName: "MoveGradient",
                animationIterationCount: "infinite",
                animationFillMode: "forwards",
                animationDuration: "3000ms",
                animationDelay: "0s",
            },
        },
    },

    "@keyframes rakeBackModalWrapper": {
        "0%": {
            opacity: 0,
            transform: "translateX(120%)",
        },
        "25%": {
            opacity: 1,
            transform: "translateX(-3%)",
        },
        "85%": {
            opacity: 1,
            transform: "translateX(-3%)",
        },
        "100%": {
            opacity: 0,
            display: "none",
            transform: "translateX(120%)",
        },
    },

    "@keyframes rakeBackModalWrapperMobile": {
        "0%": {
            opacity: 0,
            transform: "translateX(120%)",
        },
        "25%": {
            opacity: 1,
            transform: "translateX(0%)",
        },
        "85%": {
            opacity: 1,
            transform: "translateX(0%)",
        },
        "100%": {
            opacity: 0,
            display: "none",
            transform: "translateX(120%)",
        },
    },

    rakeBackModal: {
        [theme.breakpoints.down("lg")]: {
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
            width: "100%",
        },
        "@media screen and (min-width: 1025px)": {
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
            width: "100%",
        },
    },
    rakeBackTextWrapper: {
        gridColumnGap: "5px",
        alignItems: "center",
        textAlign: "center",
        color: "#FFFFFF",
        display: "flex",
        textShadow: "0px 1.61975px 0px rgba(0, 0, 0, 0.15)",
        flex: "none",
        order: 0,
        flexGrow: 0,
        margin: 0,
        width: "100%",
        justifyContent: "space-evenly",
        [theme.breakpoints.down("lg")]: {
            fontSize: "20px",
            lineHeight: "20px",
        },
        "@media screen and (min-width: 1025px)": {
            fontSize: "24px",
            lineHeight: "24px",
        },
    },
    rakeBackText: {
        whiteSpace: "pre",
        [theme.breakpoints.down("lg")]: {
            fontWeight: 400,
        },
        "@media screen and (min-width: 1025px)": {
            fontWeight: 400,
        },
    },
    rakeBackValue: {
        whiteSpace: "pre",
        [theme.breakpoints.down("lg")]: {
            fontWeight: 600,
        },
        "@media screen and (min-width: 1025px)": {
            fontWeight: 700,
        },
        "&.up": {},
        "&.down": {
            color: "tomato",
            "-webkit-text-stroke": "0.5px #5c0e00",
        },
    },
    rakeBackIcons: {
        [theme.breakpoints.down("lg")]: {
            display: "flex",
            height: "100%",
            alignItems: "center",
            "& img": {
                height: "30px",
            },
        },
        "@media screen and (min-width: 1025px)": {
            display: "flex",
            height: "100%",
            alignItems: "center",
            "& img": {
                height: "30px",
            },
        },
    },
});
