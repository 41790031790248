import { observer } from 'mobx-react';

import LocaleText from 'src/components/locale-text/locale.text';
import { useLocale } from 'src/providers/LocaleProvider';

import useStores from '../../../../hooks/useStores';
import GeneralGameDialogNew from '../common/generalDialogNew';

const AcceptOrRejectDrawDialog = () => {
    const {
        game: {
            dialogs: {
                draw: {
                    acceptOrReject: { accept, reject },
                },
            },
        },
    } = useLocale();
    const { gameStore } = useStores();
    const player = gameStore.gameState.getPlayerByColor(
        gameStore.initData?.drawingProposer!
    );

    const onApprove = () => {
        gameStore.dialogs.acceptDraw();
    };
    const onCancel = () => {
        gameStore.dialogs.rejectDraw();
    };

    if (!player) return null;

    return (
        <GeneralGameDialogNew
            avatar={player?.avatar}
            noButtons={false}
            onApprove={onApprove}
            onCancel={onCancel}
            title={
                <LocaleText
                    label={'game.dialogs.draw.acceptOrReject.title'}
                    defaultValue={`Let's make a draw`}
                />
            }
            message={
                <LocaleText
                    label={'game.dialogs.draw.acceptOrReject.message'}
                    defaultValue={`I think nobody will win`}
                />
            }
            approveText={accept('Ok, fine')}
            cancelText={reject("No, I'll finish you")}
            isSystemAvatar={player.isSystemAvatar}
        />
    );
};

export default observer(AcceptOrRejectDrawDialog);
