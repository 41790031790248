import React, { createContext, useContext, useMemo } from 'react';

import { getLocale } from './locale';

const ctx = createContext(getLocale());

export const LocaleProvider = ({ children, locale, data, onUnknown }) => {
    const value = useMemo(() => {
        return {
            value: getLocale({ locale, data, onUnknown })
        };
    }, [locale, data, onUnknown]);

    return <ctx.Provider value={value.value}>{children}</ctx.Provider>;
};

export const useLocale = () => useContext(ctx);
