import cn from "classnames";
import { MatchPairProps } from '../types';
import { useStyles } from "../styles";
import tbdIcon from 'src/assets/icons/UserCircle.svg'; 
import { observer } from "mobx-react";

const MatchPair: React.FC<MatchPairProps & { columnIndex: number; matchIndex: number }> = ({
    player1,
    player2,
    columnIndex,
    matchIndex,
    totalColumns, 
}) => {
    const classes = useStyles({ matchIndex, marginTop: 0, columnIndex });

    const isFinal = columnIndex === totalColumns - 1 && matchIndex === 0; // Финал
    const isThirdPlace = columnIndex === totalColumns - 1 && matchIndex === 1; // За 3-е место


    const renderPlayer = (player: { nickname: string; avatar?: string; score?: number } | null) => {
        if (!player || player.nickname === "TBD") {
            return (
                <div className={classes.player}>
                    <div className={classes.tbdInfo}>
                        <img src={tbdIcon} className={classes.avatar} alt="To be decided" />
                        <span>To be decided</span>
                    </div>
                </div>
            );
        }

        return (
            <div className={cn(classes.player, { [classes.winner]: player.score === 1 })}>
                <div className={classes.playerInfo}>
                    <img
                        src={player.avatar || "/default-avatar.png"}
                        alt={player.nickname}
                        className={classes.avatar}
                    />
                    <span
                        className={cn({
                            [classes.winnerName]: player.score === 1,
                            [classes.loserName]: player.score !== 1,
                        })}
                    >
                        {player.nickname}
                    </span>
                </div>
                <span
                    className={cn(classes.score, {
                        [classes.winnerScore]: player.score === 1,
                        [classes.loserScore]: player.score !== 1,
                    })}
                >
                    {player.score ?? 0}
                </span>
            </div>
        );
    };


    return (
        <div className={classes.matchPair} data-id={`${columnIndex}-${matchIndex}`}>
            {/* Заголовок финала или за 3-е место */}
            {(isFinal || isThirdPlace) && (
                <div className={classes.matchLabel}>
                    {isFinal ? "1st place decider" : "3rd place decider"}
                </div>
            )}

            {/* Игроки */}
            {renderPlayer(player1 ? { nickname: player1.name, avatar: player1.avatar, score: player1.score } : null)}
            {renderPlayer(player2 ? { nickname: player2.name, avatar: player2.avatar, score: player2.score } : null)}
        </div>
    );
};
export default observer(MatchPair);
