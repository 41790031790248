/*
 const getForm = async () => {
    try {
      const response = await axios.post(endpoints.getPaymentForm, { amount, paymentGateway })
      const { data } = response
      const { action, method, parameters } = data.paymentForm
      const { amount: payAmount, fee, total } = data

      setPaymentForm({ action, method, parameters, amount: payAmount, fee, total })
      setShowCreateGameDialog(true)
    } catch (error) {
      console.log(error)
    }
  }
 */

import axios, { AxiosResponse } from "axios";

import { IPaymentMethods } from "src/store/models";

import endpoints from "../../../core/endpoints";

import {
    ICreateTopUpRequest,
    ICreateTopUpResponse,
    ICreateWithdrawalDetailsRequest,
    ICreateWithdrawalRequest,
    ICreateWithdrawalResponse,
    IGetPaymentsListParams,
    IGetTransactionsListResponse,
} from "./requestResponses";

export const getPaymentsList = async (params: IGetPaymentsListParams) => {
    return await axios.get<IGetTransactionsListResponse>(
        endpoints.paymentsList,
        {
            params: params,
        }
    );
};

export const createWithdrawal = async (params: ICreateWithdrawalRequest) => {
    return await axios.post<
        ICreateWithdrawalRequest,
        AxiosResponse<ICreateWithdrawalResponse>
    >(endpoints.createWithdraw, params);
};

export const cancelWithdrawal = async (id: string) => {
    return await axios.post(endpoints.cancelWithdraw, {
        id,
    });
};

export const createWithdrawalDetails = async (
    params: ICreateWithdrawalDetailsRequest
) => {
    return await axios.post(endpoints.createWithdrawalDetails, params);
};

export const createTopUp = async (params: ICreateTopUpRequest) => {
    return await axios.post<
        ICreateTopUpRequest,
        AxiosResponse<ICreateTopUpResponse>
    >(endpoints.topUp, params);
};

export const setP2PAsPaid = async (id: string) => {
    return await axios.post<
        { id: string },
        AxiosResponse<ICreateTopUpResponse>
    >(endpoints.p2pPaid, { id: id });
};

export const setP2PAsCanceled = async (id: string) => {
    return await axios.post<
        { id: string },
        AxiosResponse<ICreateTopUpResponse>
    >(endpoints.p2pCancel, { id });
};

export const getPaymentMethods = () => {
    return axios.get<IPaymentMethods>(endpoints.getPaymentMethods);
};

export const cancelPayout = async (id: string) => {
    return axios.post(endpoints.cancelWithdraw, { id });
};
