import axios from 'axios';

import endpoints from 'src/core/endpoints';

import {
    IGetMyNotificationsResponse,
    IMarkNotificationAsViewedRequest,
} from './requestResponses';

//export const getMyNotifications = () => {
//    return axios.get<IGetMyNotificationsResponse>(endpoints.getMyNotifications);
//};

export const markNotificationAsViewed = (
    params?: IMarkNotificationAsViewedRequest
) => {
    return axios.post(endpoints.markNotificationAsViewed, { id: params?.id });
};

export const getBonuses = (id: string) => {
    return axios.post(endpoints.getBonuses, { id: id });
};
