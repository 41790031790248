import React from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import useStores from "src/hooks/useStores";
import { bets, coinsBet } from "src/store/gameStore/constants/bets";
import paths from "src/consts/paths";

import FilterPopup from "../FilterPopup";
import { UserBalanceType } from "../../../../store/models";
import freeCoinIcon from "../../../../assets/icons/freeCoin.png";
import coinIcon from "../../../../assets/icons/coin.svg";

import { useStyles } from "./styles";
export type BetFilterPopupPosition = "top" | "bottom";

type BetFilterPopupProps = {
    position?: BetFilterPopupPosition;
    placePosition?: string | undefined;
};
const BetFilterPopup = ({ position, placePosition }: BetFilterPopupProps) => {
    const styles = useStyles();
    const { lobbyStore } = useStores();
    const location = useLocation();

    const betConfig = lobbyStore.betConfig;
    const mode = lobbyStore.lobbyMode;

    const betParamList = (mode === UserBalanceType.play ? bets : coinsBet).map(
        (bet) => ({
            text: bet.toString(),
            value: bet === betConfig.value,
            action: () => {
                lobbyStore.setBetConfigValue(bet);
            },
            icon: mode === UserBalanceType.play ? coinIcon : freeCoinIcon,
        })
    );
    const isMultitablePage = location.pathname === paths.games;

    return (
        <FilterPopup
            paramList={betParamList}
            className={cn(
                styles.popup,
                position,
                "bet-filter-popup",
                placePosition,
                {
                    isMultitablePage: isMultitablePage,
                }
            )}
        />
    );
};

export default BetFilterPopup;
