import "./notifications.css";

import { useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
import { map } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { useLocale } from "src/providers/LocaleProvider";

import { errorService, infoService } from "./services";

const AlertService = () => {
    const subscription = useRef<Subscription>();
    const subscriptionInfo = useRef<Subscription>();

    const locale = useLocale();

    const mapLocale = (obs: Observable<unknown>) =>
        obs.pipe(
            map((message: string | Record<string, any>) => {
                if (
                    message &&
                    "object" === typeof message &&
                    ("locale" in message ||
                        ("label" in message && "defaultValue" in message))
                ) {
                    const label = message.locale?.label ?? message.label ?? "";
                    const props = message.locale?.props ?? message.props ?? {};
                    const defaultValue =
                        message.locale?.defaultValue ??
                        message.defaultValue ??
                        "";

                    return locale.extractCompiled(label, props, defaultValue);
                }

                return message;
            })
        );

    useEffect(() => {
        const cleanup = () => {
            if (subscription.current) subscription.current.unsubscribe();
            if (subscriptionInfo.current)
                subscriptionInfo.current.unsubscribe();
        };

        cleanup();

        const newSubscription = mapLocale(errorService.getError()).subscribe(
            async (error) => {
                console.log({ error });
                NotificationManager.error(error);
            }
        );

        subscription.current = newSubscription;

        const newSubscriptionInfo = mapLocale(infoService.getInfo()).subscribe(
            async (info) => {
                //console.log({ info });
                NotificationManager.success(info);
            }
        );

        subscriptionInfo.current = newSubscriptionInfo;

        return cleanup;
    }, [locale]);

    return null;
};

export default AlertService;
