import { lineHeight } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";
import { DARKGREEN_ALERTS } from "src/pages/game-new/constants";

const clientWidth = window.innerWidth;

export const useStyles = makeStyles({
    watchingUsersIcon: {},

    watchingUsersText: {},

    watchingUsersCount: {
        fontWeight: 700,
    },

    watchingListShow: {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: clientWidth,
        background: "linear-gradient(0deg, #353A57, #353A57), #303449",
        boxShadow: "-4px 0px 20px 10px rgba(0, 0, 0, 0.4)",
        borderRadius: "0px",
        animationName: "$watchingListShow",
        animationDelay: "0s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "500ms",
        "&.darkBlue": {
            background:
                "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgb(13 17 36) 68%)",
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            backdropFilter: "blur(12px)",
            background:
                "linear-gradient(0deg, rgba(18, 18, 18, 0.8), rgba(18, 18, 18, 0.8)), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
        },
    },

    watchingListHide: {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: clientWidth + clientWidth / 2,
        background: "linear-gradient(0deg, #353A57, #353A57), #303449",
        boxShadow: "-4px 0px 20px 10px rgba(0, 0, 0, 0.4)",
        borderRadius: "0px",
        animationName: "$watchingListHide",
        animationDelay: "0s",
        transform: `translateX(${-clientWidth + 65}px)`,
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "1200ms",
        "&.darkBlue": {
            background: backgroundColors.darkBlue,
            border: "1px solid #353A57",
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
    },

    "@keyframes watchingListShow": {
        "0%": {
            transform: "translateX(0px)",
            boxShadow: "-65px 4px 8px 0px rgba(0, 0, 0, 0.0)",
        },
        "85%": {
            boxShadow: "-0px 4px 8px 0px rgba(0, 0, 0, 0.1)",
        },
        "99%": {
            transform: `translateX(${-clientWidth + 64}px)`,
        },
        "100%": {
            transform: `translateX(${-clientWidth + 65}px)`,
            boxShadow: "-65px 4px 8px 0px rgba(0, 0, 0, 0.6)",
        },
    },

    "@keyframes watchingListHide": {
        "0%": {
            transform: `translateX(${-(clientWidth + clientWidth / 2) + 65}px)`,
            boxShadow: "-65px 4px 8px 0px rgba(0, 0, 0, 0.0)",
        },
        "100%": {
            transform: "translateX(0px)",
            boxShadow: "-65px 4px 8px 0px rgba(0, 0, 0, 0.0)",
        },
    },

    watchingListReload: {
        "@media (orientation: landscape)": {
            display: "none",
        },
        "@media (orientation: portrait)": {
            opacity: 0,
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: clientWidth,
            background: "linear-gradient(0deg, #353A57, #353A57), #303449",
            boxShadow: "-4px 0px 20px 10px rgba(0, 0, 0, 0.4)",
            borderRadius: "0px",
            "&.darkBlue": {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
            },
            "&.darkGreen": {
                background: backgroundColors.darkGreen,
                border: "1px solid #004348",
            },
        },
    },

    watchingListContainer: {
        "@media (orientation: landscape)": {
            display: "none",
        },
        "@media (orientation: portrait)": {
            width: "calc(100% - 65px) !important",
            height: "100%",
            marginTop: "65px",
            marginLeft: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: 0,
            "&.darkBlue": {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
            },
            "&.darkGreen": {
                background: backgroundColors.darkGreen,
                border: "1px solid #004348",
            },
        },
    },

    watchingMainGrid: {
        maxWidth: "100%",
        height: "fit-content",
        maxHeight: "calc(100vh - 110px)",
        overflowY: "scroll",
        margin: "0 !important",
        padding: "10px 20px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "scrollbar-width": "none",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 10px",
        },
    },

    watchingUserBox: {
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
        alignSelf: "center",
        fontWeight: 500,
        fontSize: "18px",
        color: appColors.white,
        lineHeight: "0px",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
        "& > p": {
            width: "100%",
            maskImage: "linear-gradient(to left, transparent 1%, black 25%)",
            lineHeight: "18px",
            margin: 0,
            marginTop: 5,
            textAlign: "center",
        },
    },

    watchingUserAvatar: {
        maxWidth: 65,
        maHeight: 65,
        borderRadius: "30px",
    },
});
