import React from 'react';

import { useLocale } from 'src/providers/LocaleProvider';

import BaseAlert from '../BaseAlert';

const MobileAcceptOrReject = () => {
    const {
        game: {
            rematch: {
                acceptOrReject: { title },
            },
        },
    } = useLocale();

    return <BaseAlert title={title("Let's play one more time")} />;
};

export default MobileAcceptOrReject;
