import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    link: {
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: 20,
        textDecoration: 'none',
        listStyleType: 'none',
        color: '#5B627E',
        transition: '0.2s',
        '&:hover, &:active': {
            color: '#8a90ac',
        },
    },

    recoverLink: {
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: 20,
        textDecoration: 'none',
        listStyleType: 'none',
        color: '#878da1',
        transition: '0.2s',
        '&:hover, &:active': {
            color: '#8a90ac',
        },
    },
    text: {
        color: 'white',
        padding: 16,
        textAlign: 'center',
    },
    titleText: {
        color: '#969BAD',
        marginBottom: 16,
        fontSize: 16,
    },
    padding: {
        paddingLeft: 10,
        paddingRight: 10,
        width: '100%',
        padding: 8,
    },
    input: { backgroundColor: 'black' },
    recoveryPasswordButton: {
        textTransform: 'none !important',
        width: '300px',
        height: '60px',
        marginLeft: '12px',
        background: 'linear-gradient(180deg, #63D77D 0%, #33A954 100%)',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
        borderRadius: '100px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1.1rem !important',
        textAlign: 'center',
        color: '#FFFFFF !important',
        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
    },
    '@media screen and (min-width: 1370px)': {
        titleText: {
            fontSize: 20,
        },
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
    },
});
