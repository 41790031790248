import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";

import SelectFiguresAlert from "./select-figures-alert";
import { ColorVariant } from "src/store/models";
import useStores from "../../../../hooks/useStores";
import useStyles from "./styles";
import FadeInOutVertical from "src/components/Animated";
import useChangeTheme from "src/hooks/useChangeTheme";
import {
    MAX_L_TABLET,
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_XL_TABLET,
    MIN_XL_TABLET,
} from "../../constants";

const SelectFiguresAlertWrapper = ({ setNoanimation, isTabletMobile }) => {
    const { gameStore } = useStores();
    const classes = useStyles();

    const appearanceTheme = useChangeTheme();

    return (
        <div
            className={cn(
                isTabletMobile
                    ? classes.mobileFiguresAlertWrapper
                    : classes.figuresAlertWrapper,
                appearanceTheme
            )}
        >
            <SelectFiguresAlert
                setFigure={(figure) => {
                    const { from, to } = gameStore.dialogs.selectFiguresDialog;
                    if (!from || !to || !gameStore.currentGameNew?._id) return;
                    const normalizeFigure =
                        gameStore.getMyColorNew() === ColorVariant.black
                            ? figure.toLowerCase()
                            : figure;
                    // Тут мы отправляем мув с цветом фигуры
                    // gameStore.move(from + to + normalizeFigure);
                    gameStore.gameState.moveNew(
                        from + to + normalizeFigure.toLowerCase(),
                        gameStore.currentGameNew?._id
                    );
                    gameStore.dialogs.closeSelectFiguresDialog();
                    setNoanimation(true);
                }}
                isTabletMobile={isTabletMobile}
            />
        </div>
    );
};

export default SelectFiguresAlertWrapper;
