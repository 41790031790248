import { useLocale } from "src/providers/LocaleProvider";

export const useRanksTranslations = () => {
    const {
        common: {
            chessmen: {
                pawn3,
                pawn2,
                pawn1,
                knight3,
                knight2,
                knight1,
                bishop3,
                bishop2,
                bishop1,
                rook3,
                rook2,
                rook1,
                queen3,
                queen2,
                queen1,
                king3,
                king2,
                king1,
                grandmaster3,
                grandmaster2,
                grandmaster1,
                elite3,
                elite2,
                elite1,
                pro3,
                pro2,
                pro1,

                pawn,
                knight,
                bishop,
                rook,
                queen,
                king,
                grandmaster,
                elite,
                pro,
            },
        },
    } = useLocale();

    return {
        pawn3: pawn3("pawn 3"),
        pawn2: pawn2("pawn 2"),
        pawn1: pawn1("pawn 1"),

        knight3: knight3("knight 3"),
        knight2: knight2("knight 2"),
        knight1: knight1("knight 1"),

        bishop3: bishop3("Bishop 3"),
        bishop2: bishop2("Bishop 2"),
        bishop1: bishop1("Bishop 1"),

        castle3: rook3("Rook 3"),
        castle2: rook2("Rook 2"),
        castle1: rook1("Rook 1"),

        queen3: queen3("Queen 3"),
        queen2: queen2("Queen 2"),
        queen1: queen1("Queen 1"),

        king3: king3("King 3"),
        king2: king2("King 2"),
        king1: king1("King 1"),

        gm3: grandmaster3("GM 3"),
        gm2: grandmaster2("GM 2"),
        gm1: grandmaster1("GM 1"),

        elit3: elite3("Elit 3"),
        elit2: elite2("Elit 2"),
        elit1: elite1("Elit 1"),

        pro3: pro3("Pro 3"),
        pro2: pro2("Pro 2"),
        pro1: pro1("Pro 1"),

        pawn: pawn("Pawn"),
        knight: knight("Knight"),
        bishop: bishop("Bishop"),
        castle: rook("Rook"),
        queen: queen("Queen"),
        king: king("King"),
        // gm: grandmaster('GM'),
        // elit: elite1('Elit'),
        // pro: pro('Pro'),
    };
};
