import { makeStyles } from "@mui/styles";

import theme from "src/theme";

export const useStyles = makeStyles(() => ({
    x2Contaner: {
        width: "100%",
        height: "48px",
        display: "flex",
        justifyContent: "center",
        gridGap: "10px",
        marginBottom: "10px",
        padding: "1px 3px",
        alignSelf: "flex-end",
        [theme.breakpoints.up("xs")]: {
            height: "42px",
            marginBottom: "0px",
        },
        [theme.breakpoints.up("md")]: {
            height: "45px",
            marginBottom: "0px",
        },
        [theme.breakpoints.up("lg")]: {
            height: "48px",
            marginBottom: "10px",
        },
        [theme.breakpoints.up(2400)]: {
            height: "64px",
        },
        [theme.breakpoints.up(3200)]: {
            height: "72px",
        },
    },
    x2ContainerButton: {
        width: "50%",
        "&.red": {
            background: "black !important",
            border: "2px solid #FF1C29",
        },
        "&.green": {},
    },
}));
