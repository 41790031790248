import React from "react";
import { useLocale } from "src/providers/LocaleProvider";
import { observer } from "mobx-react";

import { useWinOrLostStatus } from "src/pages/game-new2/hooks/useWinOrLostStatus";
import ViewModeModal, { textType } from "../ViewModeModal";
import useStores from "src/hooks/useStores";
import { ColorVariant, GameFinishReason } from "src/store/models";
import getOppositeColor from "src/utils/oppositeColor";

const WinOrLostModal = () => {
  const { gameStore } = useStores();
  const {
    game: {
      notifications: { playerWin, gameEndedDueToDraw },
      winCauses: {
        baseMessage: winBaseMessage,
        timeout: winByTimeout,
        capture: winByCapture,
        resign: winByResign,
        inactivity: winByInactivity,
      },
      lossCauses: {
        baseMessage: lossBaseMessage,
        viewMode: {
          timeout: lossByTimeoutOnViewMode,
          capture: lossByCaptureOnViewMode,
          resign: lossByResignOnViewMode,
          inactivity: lossByInactivityOnViewMode,
        },
      },
    },
  } = useLocale();
  const { resultData, winCauses } = useWinOrLostStatus();

  const player1 = gameStore.currentGameNew?.players[0];
  const player2 = gameStore.currentGameNew?.players[1];

  const player =
    player1 && player1?._id === gameStore.currentGameNew?.winner
      ? player2
      : player1;

  const isStandoff = resultData?.cause === GameFinishReason.STANDOFF;

  // if (!isStandoff && !resultData?.winner) return null;

  // const losingPlayer = gameStore.gameState.getPlayerByColor(
  //     // getOppositeColor(resultData.winner)
  //     getOppositeColor(ColorVariant.none)
  // );

  // const lossCausesCompileData = { nickname: losingPlayer?.name };

  // const lossCauses = {
  //     timeout: lossByTimeoutOnViewMode.compile(
  //         lossCausesCompileData,
  //         "the {{nickname}} lost by timeout"
  //     ),
  //     capture: lossByCaptureOnViewMode.compile(
  //         lossCausesCompileData,
  //         "the {{nickname}} king was taken"
  //     ),
  //     resign: lossByResignOnViewMode.compile(
  //         lossCausesCompileData,
  //         "the {{nickname}} gave up"
  //     ),
  //     inactivity: lossByInactivityOnViewMode.compile(
  //         lossCausesCompileData,
  //         "The {{nickname}} lost by inactivity"
  //     ),
  // };

  const winnerPlayerText = () => {
    // const playerByColor = gameStore.gameState.getPlayerByColor(
    //     // resultData?.winner
    //     ColorVariant.none
    // );

    return playerWin.compile(
      { playerNick: player?.nickname },
      `{{playerNick}} win the game`
    );
  };

  return (
    <ViewModeModal
      // title={winnerPlayerText()}
      title={`${player?.nickname}, ${lossBaseMessage("Ты проиграл")}`}
      message={""}
      titleType={textType.gold}
      messsageType={textType.gray}
      delay={2000}
    />
  );

  // return (
  //     <ViewModeModal
  //         title={
  //             isStandoff
  //                 ? gameEndedDueToDraw("the game ended due to a draw")
  //                 : winnerPlayerText()
  //         }
  //         message={lossCauses[resultData.cause]}
  //         titleType={textType.gold}
  //         messsageType={textType.gray}
  //         delay={2000}
  //     />
  // );
};

export default observer(WinOrLostModal);
