import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
import { IconProps } from './types';

function TournamentTopPlayersIcon({
    width,
    height,
    fillColor,
    strokeWidth,
}: IconProps) {
    return (
        <SVGUniqueID>
            <svg
                width={width || 24}
                height={height || 18}
                viewBox='0 0 24 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M4.22839 16.375C4.25238 16.4736 4.29588 16.5664 4.35634 16.6479C4.41679 16.7294 4.49296 16.798 4.58036 16.8495C4.66775 16.9011 4.76459 16.9346 4.86516 16.9481C4.96573 16.9616 5.06798 16.9548 5.16589 16.9282C9.63493 15.6907 14.3562 15.6907 18.8253 16.9282C18.9232 16.9548 19.0254 16.9616 19.126 16.9481C19.2266 16.9346 19.3234 16.9011 19.4108 16.8495C19.4982 16.798 19.5744 16.7294 19.6348 16.6479C19.6953 16.5664 19.7388 16.4736 19.7628 16.375L22.1534 6.2219C22.1862 6.086 22.1804 5.94364 22.1366 5.81086C22.0928 5.67809 22.0128 5.56021 21.9055 5.47051C21.7983 5.38082 21.6681 5.32289 21.5297 5.30325C21.3912 5.28362 21.2501 5.30307 21.1221 5.35941L16.3784 7.46878C16.2065 7.54446 16.0127 7.55376 15.8343 7.49489C15.656 7.43603 15.5058 7.31316 15.4128 7.15003L12.6565 2.18128C12.5917 2.06405 12.4966 1.96634 12.3812 1.8983C12.2658 1.83026 12.1342 1.79437 12.0003 1.79437C11.8663 1.79437 11.7348 1.83026 11.6194 1.8983C11.5039 1.96634 11.4089 2.06405 11.344 2.18128L8.58776 7.15003C8.49471 7.31316 8.34451 7.43603 8.16618 7.49489C7.98784 7.55376 7.79401 7.54446 7.62214 7.46878L2.87839 5.35941C2.75043 5.30307 2.60927 5.28362 2.47085 5.30325C2.33243 5.32289 2.20225 5.38082 2.09501 5.47051C1.98776 5.56021 1.90772 5.67809 1.86393 5.81086C1.82013 5.94364 1.81431 6.086 1.84714 6.2219L4.22839 16.375Z'
                    stroke='url(#paint0_linear_4661_477)'
                    stroke-width={strokeWidth || 2}
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <defs>
                    <linearGradient
                        id='paint0_linear_4661_477'
                        x1='12.0003'
                        y1='1.79437'
                        x2='12.0003'
                        y2='16.9549'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop stop-color={fillColor.color1} />
                        <stop offset='1' stop-color={fillColor.color2} />
                    </linearGradient>
                </defs>
            </svg>
        </SVGUniqueID>
    );
}

export default TournamentTopPlayersIcon;
