import React from "react";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import theme from "src/theme";
import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";
import { GameTime, GameTimeValues } from "src/store/lobbyStore";
import filterBlueClockIcon from "src/assets/icons/filter-blue-clock.svg";
import lightningIcon from "src/assets/icons/lightning.svg";
import rocketIcon from "src/assets/icons/rocket.svg";
import classicalIcon from "src/assets/icons/classical.png";
import { PopupFilterType } from "src/store/models";

import FilterPopup from "../FilterPopup";

import { useStyles } from "./styles";

export type TimeFilterPopupPosition = "top" | "bottom";

type TimeFilterPopupProps = {
    position?: TimeFilterPopupPosition | undefined;
    placePosition?: string | undefined;
};

const TimeFilterPopup = ({ position, placePosition }: TimeFilterPopupProps) => {
    const styles = useStyles();
    const { lobbyStore } = useStores();
    const { width } = useWindowSize();
    const mobileAndTablet = theme.breakpoints.values.sm;

    const timeConfig = lobbyStore.timeConfig;

    const {
        lobby: {
            gameType: {
                bullet: { time: bulletTime, timeShort: bulletTimeShort },
                blitz: { time: blitzTime, timeShort: blitzTimeShort },
                rapid: { time: rapidTime, timeShort: rapidTimeShort },
                classical: {
                    time: classicalTime,
                    timeShort: classicalTimeShort,
                },
            },
        },
    } = useLocale();

    const timeParamList = [
        {
            text:
                width && width > mobileAndTablet
                    ? classicalTime("20 мин + 0 сек")
                    : classicalTimeShort("20м + 0сек"),
            // value: timeConfig.value === GameTime["20m"],
            value: timeConfig.value === GameTimeValues["20m"],
            icon: classicalIcon,
            action: () => {
                lobbyStore.setTimeConfigValue(GameTimeValues["20m"]);
            },
        },
        {
            text:
                width && width > mobileAndTablet
                    ? rapidTime(`10 мин + 0 сек`)
                    : rapidTimeShort("10м + 0сек"),
            value: timeConfig.value === GameTimeValues["10m"],
            icon: filterBlueClockIcon,
            action: () => {
                lobbyStore.setTimeConfigValue(GameTimeValues["10m"]);
            },
        },
        {
            text:
                width && width > mobileAndTablet
                    ? blitzTime(`5 мин + 5 сек`)
                    : blitzTimeShort("5м + 5сек"),
            value: timeConfig.value === GameTimeValues["5m"],

            icon: lightningIcon,
            action: () => {
                lobbyStore.setTimeConfigValue(GameTimeValues["5m"]);
            },
        },
        {
            text:
                width && width > mobileAndTablet
                    ? bulletTime("3 мин + 3 сек")
                    : bulletTimeShort("3м + 3сек"),
            value: timeConfig.value === GameTimeValues["3m"],
            icon: rocketIcon,
            action: () => {
                lobbyStore.setTimeConfigValue(GameTimeValues["3m"]);
            },
        },
    ];
    return (
        <FilterPopup
            paramList={timeParamList.reverse()}
            className={cn(styles.popup, position)}
            filterType={PopupFilterType.time}
            placePosition={placePosition}
        />
    );
};

export default TimeFilterPopup;
