import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import cn from "classnames";
import { useMediaQuery } from "@mui/material";
import paths from "src/consts/paths";
import { GameType } from "src/store/models";
import snowFlake from "src/assets/icons/snowflakeFirst.png";

import beturangaIcon from "../../images/logo.png";
import diceChessIcon from "../../images/logo_classic.png";
import mobLogoBet from "src/assets/icons/mobLogoBet.svg"; 
import useStores from "../../../../../hooks/useStores";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import HalykLogo from "src/assets/Halyk/halyk_transp_gif.gif";

import { useStyles } from "./styles";

interface ILogo {
    onClickToggler?: () => void;
    isMultitablePage?: boolean | undefined;
}

const Logo = ({ onClickToggler, isMultitablePage }: ILogo) => {
    const styles = useStyles();
    const history = useHistory();
    const { generalStore, authStore } = useStores();
    const refPopup = useRef(null);
    const refButton = useRef(null);
    const isDesktop = useMediaQuery("(min-width: 1025px)");
    const isMobile = useMediaQuery("(max-width: 560px)");

    useOnClickOutside([refPopup, refButton], () =>
        generalStore.setShowActiveGamePopup(false)
    );

    const isFirstPages =
        location.pathname === paths.login ||
        location.pathname === paths.register ||
        location.pathname === paths.recoveryPassword;
    const isPoker = generalStore.gameType === GameType.jackpoker;

    const getLogo = () => {
        if (generalStore.isHalyk) {
            return HalykLogo;
        }
        if (isMobile) {
            return mobLogoBet;
        }
        return generalStore.isDicechessMode ? beturangaIcon : diceChessIcon;
    };

    const logo = getLogo();

    return (
        <div
            className={cn(styles.menuLayer, {
                isPoker: isPoker,
                isMultitablePage: isMultitablePage,
                isFirstPages: isFirstPages && !authStore.isAuthorized,
                authorized: authStore.isAuthorized, 
            })}
        >
            <div
                className={cn(styles.logoWrapper, {
                    isPoker: isPoker,
                    authorized: authStore.isAuthorized,
                })}
                onClick={
                    generalStore.onLogoClick ||
                    (() => {
                        onClickToggler && onClickToggler();
                        generalStore.isDicechessMode
                            ? history.push(paths.lobby)
                            : history.push(paths.diceChessLobby);
                    })
                }
            >
                <img
                    src={logo}
                    className={cn(styles.logo, {
                        isPoker: isPoker,
                        isHalyk: generalStore.isHalyk,
                        [styles.halykLogo]: generalStore.isHalyk,
                        [styles.mobileLogo]: isMobile && !generalStore.isHalyk,
                        authorized: authStore.isAuthorized,
                    })}
                />
            </div>
        </div>
    );
};

export default observer(Logo);
