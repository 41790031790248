import { Dialog } from "@mui/material";
import React from "react";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { IGame, IPlayer, EModalsLabels } from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";
import { REJECT_OR_ACCEPT_MODAL_TIMEOUT_FRIENDS } from "src/consts/lobby-params";
import ModalUserRangBox from "src/components/modals/ModalGameComponents/ModalUserRangBox/ModalUserRangBox";
import ModalDivider from "src/components/modals/ModalGameComponents/ModalDivider/ModalTitles";
import ModalButtonsBox from "src/components/modals/ModalGameComponents/ModalButtonsBox/ModalButtonsBox";
import ModalTitles from "src/components/modals/ModalGameComponents/ModalTitles/ModalTitles";
import ModalImagesBox from "src/components/modals/ModalGameComponents/ModalImagesBox/ModalImagesBox";

import ProgressBar from "../ProgressBar";
import TimeAndBetPreview from "../TimeAndBetPreview";

import { useStyles } from "./styles";

const getTimerValue = (game: IGame) => {
    let timeLeft = Math.floor(
        game.willBeDeletedIn - (Date.now() / 1000 - game.timestamp)
    );
    if (timeLeft < 0) return 0;
    return timeLeft;
};

type ConfirmGameFromFriendProps = {
    joinHandler: () => void;
    cancelJoinHandler: () => void;
    player: IPlayer;
    game: IGame;
};

const ConfirmGameFromFriend = ({
    joinHandler,
    cancelJoinHandler,
    player,
    game,
}: ConfirmGameFromFriendProps) => {
    const classes = useStyles();
    const appearanceTheme = useChangeTheme();
    const { authStore } = useStores();
    const me = authStore.currentUser;
    const {
        lobby: {
            userInteraction: { playGame, cancelGame },
            playerMiniStats: { statsRank, tournamentRp },
        },
        friends: {
            inviteFriendToPlay: {
                confirmGameModal: { title },
            },
        },
    } = useLocale();

    if (!me) return null;

    return (
        <Dialog
            open={true}
            maxWidth={false}
            className={classes.dialog}
            PaperProps={{
                className: cn(
                    classes.dialogPaperInviteFromFriend,
                    appearanceTheme
                ),
            }}
        >
            <div className={cn(classes.pattern, appearanceTheme)} />
            <ModalTitles
                title={title("Let’s play with a friend!")}
                label={EModalsLabels.friendFightReceive}
            />
            {/*<SmallRankInfo rank={player.rank} />*/}
            <ModalImagesBox
                nickname={player.name}
                avatar={player.avatar}
                isSystemAvatar={player.isSystemAvatar}
                status={player.status}
                label={EModalsLabels.friendFightReceive}
            />
            <ModalUserRangBox
                rank={player?.rank?.title || "gm1"}
                playerRating={+player?.rating?.toFixed(0) || 0}
                label={EModalsLabels.friendFightReceive}
                rankCountTitle={tournamentRp("Rank Points")}
                rankPieceTitle={statsRank("Rank")}
            />
            <ModalDivider label={EModalsLabels.friendFightReceive} />
            <TimeAndBetPreview
                timeControl={{
                    time: game.timeControl.time,
                    increment: game.timeControl.increment,
                }}
                bet={game.bet}
                rounds={game.rounds}
                type={game.balanceType}
            />
            <ProgressBar
                seconds={REJECT_OR_ACCEPT_MODAL_TIMEOUT_FRIENDS}
                initialProgress={
                    (game.willBeDeletedIn && getTimerValue(game)) ??
                    REJECT_OR_ACCEPT_MODAL_TIMEOUT_FRIENDS
                }
                showTime
                progressColor="linear-gradient(90deg, #20766C 0%, #5DC5BB 101.95%)"
                progressShortTimeColor="linear-gradient(90deg, #A60F0F 0%, #FF4236 101.95%)"
                progressWarningColor="linear-gradient(90deg, #DB640E 0%, #FFD336 101.95%)"
                onEnd={cancelJoinHandler}
            />
            <ModalButtonsBox
                acceptText={playGame("Play")}
                rejectText={cancelGame("Cancel")}
                acceptActions={joinHandler}
                rejectActions={cancelJoinHandler}
                label={EModalsLabels.friendFightReceive}
            />
        </Dialog>
    );
};

export default ConfirmGameFromFriend;
