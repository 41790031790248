import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

export const useStyles = makeStyles({
    container: {
        width: '100%',
        padding: '10px 0',
    },
    tabContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 1.5vw',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    tabs: {
        minHeight: 'auto!important',
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        marginBottom: theme.spacing(2),
        '& .MuiTabs-flexContainer': {
            display: 'flex',
            flexGrow: 1,
        },
        '& .MuiTab-root': {
            borderRadius: '0',
            borderBottom: '2px solid transparent',
            flexGrow: 1,
            minWidth: '20%',
        },
        '& .Mui-selected': {
            color: `${appColors.white} !important`,
            background: 'transparent',
            borderBottom: '2px solid #00BA6C',
        },
        [theme.breakpoints.up('md')]: {
            width: '80%',
            marginBottom: 0,
            marginRight: theme.spacing(2),
        },
    },
    tabItem: {
        color: '#FFFFFF!important',
        textTransform: 'none',
        fontSize: '14px!important', 
        fontWeight: '500!important',
        fontFamily: 'Montserrat, sans-serif!important',
        minHeight: 'auto!important',
        padding: '10px 5px!important', 
        margin: '0!important',
        flexGrow: 1,
        minWidth: 0,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px!important',
            padding: '10px 15px!important',
        },
    },
    isMultiTable: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px !important',
            padding: '8px 12px !important',
        },
    },
    providerSelectTab: {
        padding: '0 !important',
        minWidth: '25% !important', 
        '& .MuiTab-wrapper': {
            display: 'block',
        },
    },
    searchContainer: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '95%',
            justifyContent: 'flex-end',
            marginTop: 0,
        },
    },
    searchTextField: {
        width: '100%',
        height: 40,
        fontSize: 14,
        '&.isMultiTable': {
            height: 40,
            fontSize: 14,
        },
    },
});
