import theme from 'src/theme';

const calcNameLength = (
    name: string,
    currentWidth: number,
    cutSize?: number
): string => {
    if (!name) return '-';
    return cutSize &&
        currentWidth <= theme.breakpoints.values.xl &&
        name.length >= 9
        ? // turn off ... for desktop, and return .. mb in future return ... logical
          name.slice(0, cutSize) + '..'
        : currentWidth <= theme.breakpoints.values.xl && name.length >= 9
        ? name.slice(0, 9) + '..'
        : name;
};

export default calcNameLength;
