export const windowsSizeParams = Object.freeze({
    // mobileAppBarHeight: 65,
    // largeTabletAppBarHeight: 68,
    // buttomMobileActionBar: 90,
    mobileAppBarHeight: 48,
    largeTabletAppBarHeight: 55,
    buttomMobileActionBar: 90,
    desktopAppBarHeight: 80,
    desktopAppBarHeightNew: 96,
    lobbyDeskHeightReducer: 290,
    lobbyMultiDeskHeightReducer: 400,
    minMultitableBoardSize: 250,
    maxMultitableBoardSize: 475,
});

export const React_Brackets_WIDTH_CONDITION = 993;
