import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export const Send = () => (
    <SVGUniqueID>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.312 2.68851C21.1234 2.50001 20.8879 2.3652 20.6299 2.29797C20.3718 2.23074 20.1005 2.23351 19.8439 2.30601H19.8298L1.83543 7.76601C1.54251 7.85025 1.28221 8.02172 1.08917 8.25759C0.896144 8.49346 0.779547 8.78254 0.75491 9.08633C0.730272 9.39012 0.798764 9.69421 0.951265 9.9581C1.10376 10.222 1.33304 10.4332 1.60855 10.5635L9.63543 14.3651L13.437 22.3919C13.557 22.649 13.7481 22.8664 13.9876 23.0184C14.2271 23.1703 14.5052 23.2506 14.7889 23.2498C14.832 23.2498 14.8751 23.2479 14.9182 23.2441C15.221 23.2196 15.5089 23.1031 15.7435 22.9102C15.9782 22.7174 16.1482 22.4573 16.2307 22.1651L21.687 4.1707C21.687 4.16601 21.687 4.16132 21.687 4.15664C21.7604 3.90066 21.7644 3.62976 21.6985 3.37174C21.6326 3.11373 21.4992 2.87791 21.312 2.68851ZM14.7973 21.7357L14.7926 21.7488L11.1026 13.9591L15.5314 9.52945C15.6661 9.38765 15.7401 9.19884 15.7376 9.00327C15.7351 8.8077 15.6563 8.62084 15.518 8.48254C15.3797 8.34424 15.1928 8.26543 14.9972 8.26293C14.8017 8.26043 14.6128 8.33442 14.4711 8.46914L10.0414 12.8979L2.25074 9.20789H2.26386L20.2507 3.74976L14.7973 21.7357Z"
                fill="#F5F5F5"
            />
        </svg>
    </SVGUniqueID>
);
