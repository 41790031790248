import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import cn from 'classnames';


import { useLocaleByLabel } from "src/hooks/useLocaleByLabel";
import useStores from "src/hooks/useStores";
import { INotification, NotificationCustomActionNames } from "src/store/models";

import ActionButton from "../ActionButton";

import { useStyles } from "./styles";

interface INotificationItemProps {
    notification: INotification;
}

const NotificationItem = ({ notification }: INotificationItemProps) => {
    const styles = useStyles();
    const { authStore } = useStores();
    const { getLocaleByLabel } = useLocaleByLabel();

    const currentLanguage = authStore.getLanguage();

    const isRankUpdateNotification =
        notification?.actions?.[0]?.actionProperties?.actionName ===
        NotificationCustomActionNames.reward_rank_progression;

    return (
        <Box
            className={cn(styles.standardNotification, {
                isRankUpdateNotification,
            })}
        >
            <div className={styles.titleWrapper}>
                <Typography
                    variant="h3"
                    className={cn(styles.standardNotificationTitle, {
                        isRankUpdateNotification,
                    })}
                >
                    {notification.locale?.title?.[currentLanguage] ??
                        getLocaleByLabel(
                            notification.locale?.label?.title!,
                            "You got a notification",
                            notification.locale?.label?.props
                        )}
                </Typography>
                <Typography
                    variant="subtitle1"
                    className={styles.standardNotificationTime}
                >
                    {moment(notification.created).format("DD.MM.YYYY")}
                </Typography>
            </div>
            <Typography
                variant="subtitle1"
                className={cn(styles.notificationDescription, {
                    isRankUpdateNotification,
                })}
            >
                {notification.locale?.description?.[currentLanguage] ??
                    getLocaleByLabel(
                        notification.locale?.label?.description!,
                        "You got a notification",
                        notification.locale?.label?.props
                    )}
            </Typography>
            <div className={styles.actionsList}>
                {notification.actions &&
                    notification.actions?.map((action) => {
                        if (action.disabled) return null;
                        return (
                            <ActionButton
                                action={action}
                                notification={notification}
                                isRankUpdateNotification={
                                    isRankUpdateNotification
                                }
                            />
                        );
                    })}
            </div>
        </Box>
    );
};

export default NotificationItem;
