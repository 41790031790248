import { useEffect, ChangeEvent } from 'react';

const useOnClickOutside = <T>(
    refs: any[],
    handler: (e: ChangeEvent<T>) => void
) => {
    useEffect(() => {
        const listener = (event) => {
            if (
                refs.find(
                    (ref) => !ref.current || ref.current.contains(event.target)
                )
            ) {
                return;
            } else {
                handler(event);
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [refs, handler]);
};

export default useOnClickOutside;
