import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { Socket } from 'socket.io-client';

import { ColorVariant } from '../models';

import GameStore from './index';

export enum DoublingStage {
    none,
    acceptOrRejectDoubling,
    opponentAcceptedDoubling,
}
export enum DrawStage {
    none,
    confirmDrawOffering,
    waitingForOpponentToDecide,
    acceptOrRejectDraw,
    opponentIsRejectedDraw,
}

export enum RematchStage {
    none,
    waitingForOpponentToDecide,
    acceptOrRejectRematch,
    rejected,
}

export enum GiveUpStage {
    none,
    confirm,
}

export enum ButtonState {
    active = 'active',
    disabled = 'disabled',
    hidden = 'hidden',
}

class GameDialogs {
    constructor(gameStore: GameStore) {
        makeAutoObservable(this);
        this.gameStore = gameStore;
    }
    socket: Socket;
    gameStore: GameStore;

    drawDialog: {
        isOpen: boolean;
        stage: DrawStage;
    } = {
        isOpen: false,
        stage: DrawStage.none,
    };

    giveUpDialog: {
        isOpen: boolean;
        stage: GiveUpStage;
    } = {
        isOpen: false,
        stage: GiveUpStage.none,
    };

    rematchDialog: {
        isOpen: boolean;
        stage: RematchStage;
    } = {
        isOpen: false,
        stage: RematchStage.none,
    };

    selectFiguresDialog: {
        isOpen: boolean;
        figures: any[];
        from?: string;
        to?: string;
    } = {
        isOpen: false,
        figures: [],
    };

    doublingDialog: {
        isOpen: boolean;
        stage: DoublingStage;
    } = {
        isOpen: false,
        stage: DoublingStage.none,
    };

    x2WarningDialog: {
        isOpen: boolean;
    } = {
        isOpen: false,
    };

    oponentThinkingDialog: {
        isOpen: boolean;
    } = {
        isOpen: false,
    };

    @action
    setSocket(socket: Socket) {
        this.socket = socket;
    }
    @action
    openResignDialog() {
        this.giveUpDialog.isOpen = true;
    }

    @action
    closeResignDialog() {
        this.giveUpDialog.isOpen = true;
    }

    @action
    acceptDraw() {
        this.socket.emit('standoff-close');
        this.drawDialog.isOpen = false;
        this.drawDialog.stage = DrawStage.none;
    }

    @action
    rejectDraw() {
        this.socket.emit('standoff-deny');
        this.drawDialog.isOpen = false;
        this.drawDialog.stage = DrawStage.none;
    }

    @action
    offerDraw() {
        this.socket.emit('standoff');
        this.drawDialog.isOpen = true;
        this.drawDialog.stage = DrawStage.waitingForOpponentToDecide;
    }

    @action
    openDecideDrawDialog() {
        this.drawDialog.isOpen = true;
        this.drawDialog.stage = DrawStage.acceptOrRejectDraw;
    }

    closeDrawDialog() {
        this.drawDialog.isOpen = false;
        this.drawDialog.stage = DrawStage.none;
    }

    @action
    openWaitToDecideDrawDialog() {
        this.drawDialog.isOpen = true;
        this.drawDialog.stage = DrawStage.waitingForOpponentToDecide;
    }

    @action
    initDrawOffering() {
        this.drawDialog.isOpen = true;
        this.drawDialog.stage = DrawStage.confirmDrawOffering;
    }

    @action
    openRejectedDrawDialog() {
        this.drawDialog.isOpen = true;
        this.drawDialog.stage = DrawStage.opponentIsRejectedDraw;
    }

    @action
    cancelDrawOffering() {
        this.drawDialog.isOpen = false;
        this.drawDialog.stage = DrawStage.none;
    }

    @action
    giveUpInit() {
        this.giveUpDialog.isOpen = true;
        this.giveUpDialog.stage = GiveUpStage.confirm;
    }

    @action
    confirmGiveUp() {
        this.socket.emit('resign');
        this.giveUpDialog.isOpen = false;
        this.giveUpDialog.stage = GiveUpStage.none;
        this.doublingDialog.isOpen = false;
        this.doublingDialog.stage = DoublingStage.none;
    }
    @action
    cancelGiveUp() {
        this.giveUpDialog.isOpen = false;
        this.giveUpDialog.stage = GiveUpStage.none;
    }

    @action
    requestRematch() {
        this.socket.emit('rematch');
    }

    @action
    openRematchDialogWaitingForOpponent() {
        this.rematchDialog = {
            isOpen: true,
            stage: RematchStage.waitingForOpponentToDecide,
        };
    }

    @action
    openRematchDialogDecide() {
        this.rematchDialog = {
            isOpen: true,
            stage: RematchStage.acceptOrRejectRematch,
        };
    }

    @action
    openRematchDialogRejected() {
        this.rematchDialog = {
            isOpen: true,
            stage: RematchStage.rejected,
        };
    }

    @action
    closeRematchDialog() {
        this.rematchDialog = {
            isOpen: false,
            stage: RematchStage.none,
        };
    }

    @action
    rejectRematch() {
        this.socket.emit('rematch:deny');
        this.openRematchDialogRejected();
    }

    @action
    acceptRematch() {
        this.socket.emit('rematch');
    }

    @action
    openThinkingDialog() {
        this.oponentThinkingDialog.isOpen = true;
    }

    @action
    closeThinkingDialog() {
        this.oponentThinkingDialog.isOpen = false;
    }

    @action
    sendDouble() {
        this.socket.emit('double');
    }

    @action
    confirmDoubling() {
        this.socket.emit('double');
    }

    @action
    rejectDoubling() {
        this.openResignDialog();
    }

    @action
    showX2Warning() {
        this.x2WarningDialog.isOpen = true;
    }

    @action
    closeX2Warning() {
        this.x2WarningDialog.isOpen = false;
    }

    @action
    showSelectFigureDialog(figures: string[], from: string, to: string) {
        this.selectFiguresDialog.isOpen = true;
        this.selectFiguresDialog.figures = figures;
        this.selectFiguresDialog.from = from;
        this.selectFiguresDialog.to = to;
    }

    @action
    closeSelectFiguresDialog() {
        this.selectFiguresDialog.isOpen = false;
        this.selectFiguresDialog.figures = [];
        this.selectFiguresDialog.from = undefined;
        this.selectFiguresDialog.to = undefined;
    }

    @action
    showDoublingDialog() {
        if (this.gameStore.initData?.over) {
            this.closeDoublingDialog();
            return;
        }
        this.doublingDialog.isOpen = true;
        this.doublingDialog.stage = DoublingStage.acceptOrRejectDoubling;
    }

    @action
    showOpponentAcceptedDoublingDialog() {
        if (this.gameStore.initData?.over) {
            this.closeDoublingDialog();
            return;
        }
        this.doublingDialog.isOpen = true;
        this.doublingDialog.stage = DoublingStage.opponentAcceptedDoubling;
    }

    @action
    closeDoublingDialog() {
        this.doublingDialog.isOpen = false;
        this.doublingDialog.stage = DoublingStage.none;
    }

    @computed
    getX2ButtonState() {
        if (!this.gameStore.initData) return ButtonState.hidden;
        const myColor = this.gameStore.gameState.getMyColor();
        const doublingData = this.gameStore.initData.doubling;
        if (this.gameStore.isViewMode()) return ButtonState.hidden;
        if (!this.gameStore.gameState.isMyTurn()) return ButtonState.hidden;
        if (myColor === doublingData.aggressor) {
            if (doublingData.accepted) return ButtonState.hidden;
        }
        return ButtonState.active;
    }

    @action
    refreshRematchDialogState() {
        const rematchInfo = this.gameStore.rematch;
        const myColor = this.gameStore.gameState.getMyColor();
        const opColor = this.gameStore.gameState.getOpponentColor();

        if (rematchInfo[myColor] && rematchInfo[opColor] === null) {
            this.openRematchDialogWaitingForOpponent();
        } else if (rematchInfo[opColor] && rematchInfo[myColor] === null) {
            this.openRematchDialogDecide();
        } else if (rematchInfo[myColor] && rematchInfo[opColor] === false) {
            this.openRematchDialogRejected();
        }
    }
}

export default GameDialogs;
