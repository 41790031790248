import OneSignal from 'react-onesignal';

const runOneSignal = async () => {
    // TODO: uncomment when env variables are added
    // await OneSignal.init({
    //     appId:
    //         process.env.REACT_APP_ONE_SIGNAL_APP_ID ??
    //         '1b834a8e-c545-446f-808e-6cdcb34a0781',
    //     allowLocalhostAsSecureOrigin:
    //         process.env.REACT_APP_NODE_ENV === 'development',
    //     safari_web_id:
    //         process.env.REACT_APP_ONE_SIGNAL_SAFARI_WEB_ID ??
    //         'web.onesignal.auto.4c35e1cb-920a-4e68-be7d-eea8363a1cf4',
    // });
};

export default runOneSignal;
