import { Box } from '@mui/material';
import cn from 'classnames';
import React from 'react';

import { useStyles } from './styles';

type BurgerMenuProps = {
    onClick: () => void;
    sideMenuIsOpen: boolean;
};

const BurgerMenu = ({ onClick, sideMenuIsOpen }: BurgerMenuProps) => {
    const styles = useStyles();
    return (
        <>
            <Box className={styles.wrapperBurger} onClick={onClick}>
                <Box
                    className={cn(styles.burgerLine, 'line1', {
                        line1Open: sideMenuIsOpen,
                    })}
                    key={'wrapperBurger_1'}
                />
                <Box
                    className={cn(styles.burgerLine, 'line2', {
                        line2Open: sideMenuIsOpen,
                    })}
                    key={'wrapperBurger_2'}
                />
                <Box
                    className={cn(styles.burgerLine, 'line3', {
                        line3Open: sideMenuIsOpen,
                    })}
                    key={'wrapperBurger_3'}
                />
            </Box>
        </>
    );
};

export default BurgerMenu;
