import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import { notificationService } from "src/service/services";
import { useLocale } from "src/providers/LocaleProvider";
import { useSocket } from "src/core/useSocket";
import { useSocketOpen } from "src/core/useSocketOpen";
import useStores from "src/hooks/useStores";
import sadIcon from "src/assets/icons/sad.svg";
import paths from "src/consts/paths";
import {
    IGame,
    IGameUpdate,
    IStateFromSocket,
    UserBalanceType,
} from "src/store/models";

import endpoints from "../../core/endpoints";
// import { IGame, IGameUpdate, UserBalanceType } from "../../store/models";
import { ILobbyStats } from "../../store/lobbyStore";
import { GameModeCreate } from "../../service/api/lobby/requestResponses";
import { runInAction } from "mobx";

const LobbySocket = () => {
    const history = useHistory();
    const location = useLocation();
    const { authStore, lobbyStore, achievementsStatStore } = useStores();
    const { diceChessLobby, lobbyFreePlay } = paths;
    const token = localStorage.getItem("t");

    // const socketWilBeActive =
    //     location.pathname === diceChessLobby ||
    //     location.pathname === lobbyFreePlay;

    const [initSocket, socket, disconnectSocket] = useSocketOpen();

    const {
        lobby: { userRejectedGame },
        friends: { friendRejectedGame, friendCanceledGame },
    } = useLocale();

    const [, setRefresh] = useState(Math.random());
    const [startedGameId, setStartedGameId] = useState<string>();
    const [canceledGameId, setCanceledGameId] = useState<string>();
    const [canceledJoinGameId, setCanceledJoinGameId] = useState<string>();

    const activeGames = lobbyStore.getMyNotReadyGame();

    const { acceptedGame, joinedGame } = lobbyStore;

    useEffect(() => {
        if (acceptedGame && acceptedGame.id === canceledJoinGameId) {
            lobbyStore.setAcceptedGame(null);
        }
        if (joinedGame && joinedGame.id === canceledJoinGameId) {
            lobbyStore.setJoinedGame(null);
        }
        setCanceledJoinGameId(undefined);
    }, [canceledJoinGameId, authStore.isAuthorized]);

    useEffect(() => {
        if (authStore.currentUser) {
            const acceptedGames = activeGames.filter(
                (game) => game.players[0].id === authStore.currentUser?._id
            );
            const joinedGamesFromActive = activeGames.filter(
                (game) =>
                    game.players.length === 2 &&
                    game.players[1].id === authStore.currentUser?._id
            );

            if (acceptedGames.length > 0) {
                if (history.location.pathname.indexOf("/game") === -1) {
                    if (acceptedGames[0].previousGameId) {
                        history.push(`/game/${acceptedGames[0].id}`);
                    } else {
                        lobbyStore.setAcceptedGame(acceptedGames[0]);
                    }
                }
            }
            if (joinedGamesFromActive.length > 0) {
                if (history.location.pathname.indexOf("/game") === -1) {
                    if (joinedGamesFromActive[0].previousGameId) {
                        history.push(`/game/${joinedGamesFromActive[0].id}`);
                    } else {
                        lobbyStore.setJoinedGame(joinedGamesFromActive[0]);
                    }
                }
            }
        }
    }, [authStore.currentUser, activeGames.length]);

    useEffect(() => {
        if (!socket) return;
        lobbyStore.initLobbySocket(socket);
        socket.on("connect", () => {
            setRefresh(Math.random());
            //lobbyStore.initLobbySocket(socket);
            lobbyStore.refreshLobby();
        });

        socket.on("connecting", (data) => {});

        socket.on("connect_failed", (data) => {});

        socket.on("reconnect_failed", (data) => {});

        socket.on("reconnect", (data) => {
            lobbyStore.refreshLobby();
        });

        socket.on(
            "balance",
            (data: {
                [UserBalanceType.play]: number;
                [UserBalanceType.coins]: number;
                [UserBalanceType.referralBonus]: number;
            }) => {
                authStore.updateBalance(data);
            }
        );

        socket.on("stats:update", (stats: ILobbyStats) => {
            // console.log('stats:update');
            lobbyStore.setStats(stats);
        });
        socket.on("game:lobby:started", (gameId: string) => {
            // console.log('game:lobby:started');
            setStartedGameId(gameId);
        });

        socket.on("game:lobby:canceled", (gameId: string) => {
            // console.log('game:lobby:canceled');
            setCanceledGameId(gameId);
        });

        socket.on("game:join:canceled", (gameId: string) => {
            // console.log("game:join:canceled");
            setCanceledJoinGameId(gameId);
        });

        socket.on("game:join:rejected", (game: IGame) => {
            const [owner] = game.players;
            notificationService.sendNotification(
                userRejectedGame.compile(
                    { nickname: owner.name },
                    "{{nickname}} refused to play"
                ),
                sadIcon
            );
        });

        socket.on("friend:join:refused", (game: IGame) => {
            notificationService.sendNotification(
                friendRejectedGame.compile(
                    { nickname: game.challenged?.nickname },
                    "{{nickname}} refused to play"
                ),
                sadIcon
            );
        });

        socket.on("lobby:add", (games: IGame[]) => {
            // console.log('lobby:add', games);
            if (!games) return;
            lobbyStore.addGamesToList(games);
        });

        socket.on("lobby:getAwaitingGames", (games: IGame[]) => {});
        socket.on("lobby:setAwaitingGames", (games: IGame[]) => {});

        socket.on("lobby:update", (games: IGameUpdate[]) => {
            // console.log('lobby:update', games);
            if (!games) return;
            lobbyStore.updateGamesFromList(games);
        });

        socket.on("lobby:addMyGames", (games: IGame[]) => {
            if (!games) return;
            lobbyStore.addMyGamesToList(games);
        });
        socket.on("lobby:updateMyGames", (games: IGameUpdate[]) => {
            if (!games) return;
            lobbyStore.updateMyGamesToList(games);
        });

        socket.on("lobby:remove", (games: string[]) => {
            //console.log('lobby:remove', games);
            lobbyStore.removeGamesFromList(games);
        });

        socket.on("friend:challenged", (game) => {
            const activeGames = lobbyStore.getActiveGames();
            if (activeGames.length) return;
            lobbyStore.setAcceptedGame(game);
        });

        socket.on("challengedGame:remove", (gameId) => {
            const acceptedGame = lobbyStore.acceptedGame;
            if (!acceptedGame || acceptedGame.id !== gameId) return;
            const friendName = acceptedGame.players[0]?.name || "Anonymous";
            lobbyStore.setAcceptedGame(null);
            notificationService.sendNotification(
                friendCanceledGame.compile(
                    { nickname: friendName },
                    "{{nickname}} canceled game"
                ),
                sadIcon
            );
        });

        //=========
        socket.on("state", (data: IStateFromSocket) => {

            const isFreePlay =
                document.location.href.indexOf("lobby-free-play") > 0;

            achievementsStatStore.setTopPlayersNew(data.topRateUsers);
            lobbyStore.setAllLobbyParticipatingGames(data.games);
            lobbyStore.setAllLobbyGames(
                data.games.filter(
                    (item) => isFreePlay === (item.type === GameModeCreate.Free)
                )
            );
            lobbyStore.setAllLobbyInviteGames(
                data.invites.filter(
                    (item) =>
                        isFreePlay ===
                        (item.details.type === GameModeCreate.Free)
                )
            );
            lobbyStore.setAllLobbyInvitesAccepts(
                data.invitesAccepts.filter(
                    (item) =>
                        isFreePlay ===
                        (item.details.type === GameModeCreate.Free)
                )
            );
            const user = authStore.currentUser;
            if (user && data.balance) {
                runInAction(() => {
                  user?.balance === data.balance
                })
                authStore.setCurrentUserNew(user);
            }
        });
    }, [socket]);

    useEffect(() => {
        // Prehabs we have problem with a lot of rerenders on view/lobby list when created many games, because on useEffect used [authStore.isAuthorized] with socket trigger
        // if (authStore.isAuthorized) return;

        return initSocket(endpoints.lobbySocket);
    }, [token]);

    // useEffect(() => {
    //     if (!authStore.isAuthorized) {
    //         console.log("DISCONNECT");
    //         disconnectSocket();
    //         return;
    //     }
    //     if (authStore.isAuthorized) {
    //         console.log("CONNECT AGAIN");
    //         initSocket(endpoints.lobbySocket);
    //     }
    // }, [authStore.isAuthorized]);

    return null;
};

export default observer(LobbySocket);
