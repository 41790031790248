import {observer} from 'mobx-react';
import {useEffect, useMemo} from 'react';

import {GameStoreProvider} from 'src/hooks/useGameStore';
import useStores from 'src/hooks/useStores';
import SpinnerContainer from './components/spinnerContainer/SpinnerContainer';

import GameContainer from './container';

const GameNewDesign = (props) => {
    const {gamesStore, lobbyStore, authStore} = useStores();
    const {id} = props.match.params;
    const isVisible = authStore.isVisibleWebApp;
    const me = authStore.currentUser?._id;

    const notReadyGames = lobbyStore.getMyNotReadyGame();

    // useEffect(() => {
    //     return () => {
    //         gamesStore.resetGameTables();
    //     };
    // }, []);

    // useEffect(() => {
    //     if (notReadyGames.length > 0 && isVisible) {
    //         if (ids !== notReadyGames[0].id) {
    //             lobbyStore.setAcceptedGame(notReadyGames[0]);
    //         }
    //     }
    // }, [notReadyGames.length]);

    const MemoizedGameContainer = useMemo(() => {
        if (!isVisible)
            return (
                <SpinnerContainer
                    isMultiTable={false}
                    rotateDecorator={!isVisible}
                    isAbsolute={true}
                />
            );
        return (
            <GameStoreProvider id={id}>
                <GameContainer {...props} numberOfGames={0} />
            </GameStoreProvider>
        );
    }, [id]);

    // if (!me) return;

    return <>{MemoizedGameContainer}</>;
};

export default observer(GameNewDesign);
