import React, { useMemo } from "react";
import { observer } from "mobx-react";

import useStores from "src/hooks/useStores";
import { EGameRematchStatus } from "src/store/models";

import { RematchStage } from "../../../../../store/gameStore/dialogs";

import MobileAcceptOrReject from "./AcceptOrReject";
import MobileWaitForOpponent from "./WaitForOpponent";
import MobileOpponentReject from "./OpponentReject";

const RematchDialogs = () => {
    const { gameStore, authStore } = useStores();
    const me = authStore?.currentUser;
    const rematchInfo = gameStore.dialogs.rematchData;
    const iAmInitiator = me?._id === rematchInfo.rematchStartedBy;

    const getRematchAlertVariant = useMemo(() => {
        if (rematchInfo.rematchStatus === EGameRematchStatus.NOT_INIT)
            return null;
        if (
            rematchInfo.rematchStatus === EGameRematchStatus.WAITING_FOR_ANSWER
        ) {
            if (iAmInitiator) {
                return <MobileWaitForOpponent />;
            }
            return <MobileAcceptOrReject />;
        } else if (
            rematchInfo.rematchStatus === EGameRematchStatus.DECLINED &&
            !iAmInitiator
        ) {
            return <MobileOpponentReject />;
        }
    }, [rematchInfo.rematchStatus, iAmInitiator]);

    return <>{getRematchAlertVariant}</>;
};

export const MobileRematchDialogs = observer(RematchDialogs);
