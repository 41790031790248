import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
// King
const WhiteDice = ({ width, height }) => {
    return (
        <svg
            id='w-05_svg__Layer_1'
            x={0}
            y={0}
            viewBox='0 0 70 70'
            xmlSpace='preserve'
            width={width}
            height={height}
        >
            <style />
            <linearGradient
                id='w-05_svg__SVGID_1_'
                gradientUnits='userSpaceOnUse'
                x1={35}
                y1={69.5}
                x2={35}
                y2={0.5}
            >
                <stop offset={0} stopColor='#bcc5c7' />
                <stop offset={0.65} stopColor='#e6e8e9' />
            </linearGradient>
            <path
                d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
                fill='url(#w-05_svg__SVGID_1_)'
            />
            <path
                d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
                fill='#fff'
            />
            <g>
                <linearGradient
                    id='w-05_svg__SVGID_2_'
                    gradientUnits='userSpaceOnUse'
                    x1={11.383}
                    y1={35.733}
                    x2={58.618}
                    y2={35.733}
                >
                    <stop offset={0} stopColor='#f7f5d2' />
                    <stop offset={1} stopColor='#d7d4b7' />
                </linearGradient>
                <path
                    d='M35 58.7c-10.2 0-15.5-1.9-15.5-2.7v-.2c.6-2.4.6-2.7.6-6.6 0-4.4-4.7-6.9-4.9-7-2.6-1.6-3.8-4.5-3.8-8.9 0-5.8 4.7-10.5 10.5-10.5 3.1 0 6 1.3 8 3.7l1.4 1.7-.3-2.1v-.5c0-2 1.5-3.7 3.5-4l.6-.1v-5.4h-3.4H35v-3.4 3.4h3.4H35v5.4l.6.1c2 .3 3.5 2 3.5 4v.5l-.2 2.2 1.4-1.7c2-2.3 4.9-3.7 8-3.7 5.8 0 10.5 4.7 10.5 10.5 0 3.7-1.9 7-5 8.9-.2.1-3.8 2.6-3.8 6.9 0 3.9 0 4.1.6 6.6v.2c-.1.8-5.4 2.7-15.6 2.7z'
                    fill='url(#w-05_svg__SVGID_2_)'
                />
                <linearGradient
                    id='w-05_svg__SVGID_3_'
                    gradientUnits='userSpaceOnUse'
                    x1={35.001}
                    y1={61.175}
                    x2={35.001}
                    y2={12.789}
                >
                    <stop offset={0.001} stopColor='#fff' />
                    <stop offset={1} stopColor='#a4b0b2' />
                </linearGradient>
                <path
                    d='M35 12.8v8.8l.6.1c2 .3 3.5 2 3.5 4v.5l-.2 2.2 1.4-1.7c2-2.3 4.9-3.7 8-3.7 5.8 0 10.5 4.7 10.5 10.5 0 3.7-1.9 7-5 8.9-.2.1-3.8 2.6-3.8 6.9 0 3.9 0 4.1.6 6.6v.2c0 .9-5.3 2.7-15.5 2.7s-15.5-1.9-15.5-2.7v-.2c.6-2.4.6-2.7.6-6.6 0-4.4-4.7-6.9-4.9-7-2.6-1.6-3.8-4.5-3.8-8.9 0-5.8 4.7-10.5 10.5-10.5 3.1 0 6 1.3 8 3.7l1.4 1.7-.4-2.2v-.5c0-2 1.5-3.7 3.5-4l.6-.1v-5.4h-3.4H35v-3.3m3.4 3.4H35h3.4m-5.9-3.4v.9h-.9v5h.9v.9c-1.5.6-2.6 1.7-3.3 3.1-2.1-1.5-4.7-2.3-7.3-2.3-7.1 0-13 5.8-13 13 0 5.3 1.7 9 5 11l.1.1h.1s3.6 1.9 3.6 4.7c0 3.7 0 3.8-.5 5.8l-.2.4v.6c0 2.5 2.8 3.4 5.9 4.1 2.3.5 6.2 1.1 12.1 1.1 8.4 0 15.3-1.3 17.1-3.2.6-.6.9-1.3.9-2.1v-.6l-.2-.3c-.5-1.9-.5-2.1-.5-5.8 0-2.9 2.3-4.6 2.6-4.8 3.9-2.4 6.1-6.5 6.1-11 0-7.1-5.8-13-13-13-2.6 0-5.2.8-7.3 2.3-.7-1.4-1.9-2.5-3.3-3.1v-.9h.9v-5h-.9v-.9h-4.9z'
                    fill='url(#w-05_svg__SVGID_3_)'
                />
                <path
                    d='M48.2 22.2c-3.4 0-6.4 1.5-8.5 3.9v-.5c0-2.4-1.8-4.3-4-4.7v-4.1H39v-1.3h-3.4v-3.4h-1.3v3.4H31v1.3h3.4v4.1c-2.3.3-4 2.3-4 4.7v.5c-2-2.4-5.1-3.9-8.5-3.9-6.1 0-11.1 5-11.1 11.1 0 4 1 7.5 4.2 9.5 0 0 4.6 2.3 4.6 6.4 0 3.9 0 4-.6 6.4-.1.1-.1.2-.1.4 0 1.9 7.3 3.4 16.2 3.4s16.2-1.5 16.2-3.4c0-.1 0-.2-.1-.4-.6-2.4-.6-2.5-.6-6.4 0-4 3.4-6.4 3.4-6.4 3.2-2 5.3-5.5 5.3-9.5 0-6.1-5-11.1-11.1-11.1zm-13.7.1l.5-.1.5.1c1.6.2 2.9 1.7 2.9 3.3v.4l-.2 2c-.5.5-2.3 2-3.1 4.7-.9-2.7-2.6-4.2-3.1-4.7l-.2-2v-.4c-.2-1.7 1.1-3.1 2.7-3.3zM20.8 50.6c1.8-.8 6.6-1.8 14.2-1.8 7.5 0 12.3 1 14.1 1.8 0 1.6 0 2.5.2 3.4-2.7-1.1-8.1-1.8-14.3-1.8-6.3 0-11.7.7-14.4 1.8.2-.9.2-1.8.2-3.4zm28.4-1.5c-2.8-1-8-1.7-14.1-1.7s-11.5.7-14.2 1.8c0-1.1-.3-2.1-.7-3h.2c.9-.9 6-2.2 14.6-2.2s13.6 1.3 14.7 2.1c-.3.9-.5 1.9-.5 3zm-33.6-7.4c-2.4-1.5-3.5-4.2-3.5-8.3 0-5.4 4.4-9.8 9.8-9.8 2.9 0 5.6 1.3 7.4 3.4l2.8 3.3c.9 1.1 1.9 3 1.9 5.6v6.7c-7 .1-12.8 1.1-14.6 2.5-1.4-2-3.4-3.2-3.8-3.4zM35 58c-8.6 0-13.7-1.4-14.8-2.2 0-.1 0-.1.1-.2 1.1-.8 6.2-2.2 14.7-2.2s13.5 1.3 14.7 2.1c0 .1 0 .2.1.2-1.1.9-6.2 2.3-14.8 2.3zm18.3-16.3c-.2.1-1.8 1.2-2.9 3.2-2.1-1.3-7.7-2.2-14.4-2.3v-6.7c0-2.6 1.1-4.5 2-5.6l2.8-3.2c1.9-2.2 4.6-3.4 7.4-3.4 5.4 0 9.8 4.4 9.8 9.8-.1 3.3-1.8 6.4-4.7 8.2z'
                    fill='#231f20'
                />
            </g>
        </svg>
    );
};

const GoldDice = ({ width, height }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 54 54'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                width='54'
                height='54'
                rx='7.03677'
                fill='url(#paint0_linear_5445_63166)'
            />
            <rect
                x='3'
                y='3'
                width='48'
                height='48'
                rx='7.03677'
                fill='url(#paint1_linear_5445_63166)'
            />
            <circle
                cx='27'
                cy='27'
                r='27'
                fill='url(#paint2_linear_5445_63166)'
                fill-opacity='0.6'
            />
            <g filter='url(#filter0_i_5445_63166)'>
                <path
                    d='M40.0065 21.6316C38.7677 20.4737 37.1161 19.8421 35.2581 19.8421C33.8129 19.8421 32.471 20.2632 31.4387 21C30.8194 19.6316 29.9452 18.5789 28.5 18.1579L28.5 16.5H30.5V14H28.5V12H25.5V14H23.5V16.5H25.5L25.5 18.1579C24.0548 18.5789 23.0774 19.6316 22.4581 21C21.3226 20.2632 19.9806 19.8421 18.6387 19.8421C16.8839 19.8421 15.2323 20.4737 13.9935 21.6316C12.1355 23 11 25.2105 11 27.6316C11 30.2632 12.3419 32.5789 14.4065 33.9474C16.5742 35.6316 16.8839 36.3684 16.9871 41C16.9871 41 16.9871 41 16.9871 41.1053C17.0903 42.1579 21.529 43 27 43C32.471 43 36.8064 42.1579 37.0129 41.1053C37.1161 36.3684 37.3226 35.7368 39.5935 34.0526C41.6581 32.6842 43 30.3684 43 27.7368C43 25.2105 41.8645 23 40.0065 21.6316Z'
                    fill='white'
                />
            </g>
            <path
                d='M40.0065 21.6316C38.7677 20.4737 37.1161 19.8421 35.2581 19.8421C33.8129 19.8421 32.471 20.2632 31.4387 21C30.8194 19.6316 29.9452 18.5789 28.5 18.1579L28.5 16.5H30.5V14H28.5V12H25.5V14H23.5V16.5H25.5L25.5 18.1579C24.0548 18.5789 23.0774 19.6316 22.4581 21C21.3226 20.2632 19.9806 19.8421 18.6387 19.8421C16.8839 19.8421 15.2323 20.4737 13.9935 21.6316C12.1355 23 11 25.2105 11 27.6316C11 30.2632 12.3419 32.5789 14.4065 33.9474C16.5742 35.6316 16.8839 36.3684 16.9871 41C16.9871 41 16.9871 41 16.9871 41.1053C17.0903 42.1579 21.529 43 27 43C32.471 43 36.8064 42.1579 37.0129 41.1053C37.1161 36.3684 37.3226 35.7368 39.5935 34.0526C41.6581 32.6842 43 30.3684 43 27.7368C43 25.2105 41.8645 23 40.0065 21.6316Z'
                stroke='#364367'
                stroke-opacity='0.7'
                stroke-width='1.24703'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <defs>
                <filter
                    id='filter0_i_5445_63166'
                    x='10.3765'
                    y='11.3765'
                    width='34.2471'
                    height='33.2471'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dx='1' dy='1' />
                    <feGaussianBlur stdDeviation='1.87055' />
                    <feComposite
                        in2='hardAlpha'
                        operator='arithmetic'
                        k2='-1'
                        k3='1'
                    />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='shape'
                        result='effect1_innerShadow_5445_63166'
                    />
                </filter>
                <linearGradient
                    id='paint0_linear_5445_63166'
                    x1='15'
                    y1='50.5'
                    x2='48.9003'
                    y2='16.287'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#8E4A06' />
                    <stop offset='0.392749' stop-color='#B1701F' />
                    <stop offset='0.58033' stop-color='#F8DD79' />
                    <stop offset='1' stop-color='#9F6013' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_5445_63166'
                    x1='16.3333'
                    y1='47.8889'
                    x2='46.467'
                    y2='17.4774'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#8E4A06' />
                    <stop offset='0.392749' stop-color='#B1701F' />
                    <stop offset='0.58033' stop-color='#F8DD79' />
                    <stop offset='1' stop-color='#9F6013' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_5445_63166'
                    x1='57'
                    y1='7'
                    x2='2.43556e-06'
                    y2='44.4194'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#FFF5A6' />
                    <stop offset='1' stop-color='#E5BD66' stop-opacity='0.54' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default function W5({ width, height }) {
    return (
        <SVGUniqueID>
            <WhiteDice width={width} height={height} />
            {/* <GoldDice width={width} height={width} /> */}
        </SVGUniqueID>
    );
}
