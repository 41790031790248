import { makeStyles } from "@mui/styles";

import {
    appColors,
    prizeModalGold,
    prizeModalSilver,
    prizeModalOther,
} from "src/consts/app-config";

interface IRatio {
    ratio: any;
}
import theme from "src/theme";
import { React_Brackets_WIDTH_CONDITION } from "src/consts/window-size-params";

const width = window.innerWidth;

export const useStyles = makeStyles({
    "@global": {
        "@keyframes OpenRaiting": {
            "0%": {
                opacity: 0,
                transform: `translate(${width * 2}px, 0px)`,
            },
            "30%": {
                opacity: 0,
                transform: `translate(${width * 2}px, 0px)`,
            },
            "100%": {
                opacity: 1,
                transform: `translate(${0}px, 0px)`,
            },
        },
        "@keyframes CloseRating": {
            "0%": {
                opacity: 1,
                transform: `translate(${0}px, 0px)`,
            },
            "100%": {
                opacity: 0,
                transform: `translate(${width * 2}px, 0px)`,
            },
        },
        "@keyframes topPlayerCrownMain": {
            "0%": {
                minHeight: 42,
                transform: "scale(1.2)",
            },
            "100%": {
                minHeight: 42,
                transform: "scale(1)",
            },
        },
    },

    tournamentsRaitingMobileWrapper: {
        width: "100%",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        transform: `translate(${width * 2}px, 0px)`,
        "&.open": {
            opacity: 0,
            animationDelay: "0s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "800ms",
            animationName: "OpenRaiting",
        },
        "&.close": {
            opacity: 1,
            animationDelay: "0s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "1000ms",
            animationName: "CloseRating",
        },
        "&.darkBlue": {
            background:
                "linear-gradient(270deg, #14103C 0%, #080520 50%, #140E5A 100%), linear-gradient(0deg, #2A343D, #2A343D), linear-gradient(270deg, #201B44 0%, #2F287D 52.6%, #201B44 99.99%)",
        },
        "&.darkGreen": {
            background:
                "radial-gradient(63.19% 181.28% at 51.57% 62.5%, rgb(0, 73, 78) 0%, rgb(0, 0, 0) 100%)",
        },
    },

    topPlayerCrownMain: {
        minHeight: 42,
        animationDelay: "0s",
        animationIterationCount: "1",
        animationFillMode: "forwards",
        animationDuration: "800ms",
        marginBottom: 5,
        animationName: "topPlayerCrownMain",
        // [theme.breakpoints.down(460)]: {
        //     marginTop: '48px',
        // },
    },

    topPlayerCrownWrapper: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        // position: 'absolute',
        // left: '50%',
        // top: '50%',
        // transform: 'translate(-50%, -50%)',
        // zIndex: 9999,
    },

    topPlayerCrown: {
        position: "relative",
        zIndex: 5,
        width: "fit-content",
        height: "fit-content",
        background: "none",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },

    topPlayerText: {
        margin: 0,
        fontSize: "12px",
        lineHeight: "12px",
        fontWeight: 500,
        position: "absolute",
        top: "52%",
        left: "50%",
        transform: "translate(-50%, -24%)",
        background: `linear-gradient(180deg, ${prizeModalOther.color1} 0%, ${prizeModalOther.color2} 100%)`,
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        "text-fill-color": "transparent",
        "&.first": {
            background: `linear-gradient(180deg, ${prizeModalGold.color1} 0%, ${prizeModalGold.color2} 100%)`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            backgroundClip: "text",
            "text-fill-color": "transparent",
        },
        "&.second": {
            background: `linear-gradient(180deg, ${prizeModalSilver.color1} 0%, ${prizeModalSilver.color2} 100%)`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            backgroundClip: "text",
            "text-fill-color": "transparent",
        },
        "&.overFlow": {
            fontSize: "10px",
            fontWeight: 400,
            transform: "translate(-50%, -15%)",
        },
    },

    topPlayersBoxShine: {
        width: "55%",
        maxWidth: "200px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 0,
        background:
            "radial-gradient(circle, rgba(255,255,255,0) 0%, rgb(239, 239, 239) 40%, rgb(210, 210, 210) 60%, rgb(194, 194, 194) 100%)",
        borderRadius: "50%",
        height: "15px",
        opacity: 0.4,
        filter: "blur(8px)",
    },

    crossTopPlayersBox: {
        position: "absolute",
        top: "12px",
        left: "15px",
        width: "27px",
        height: "27px",
        zIndex: 7,
    },

    crossTopPlayers: {
        width: "100%",
        height: "100%",
        position: "relative",
        "&::before": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(45deg)",
            transform: "translate(-50%,-50%) rotate(45deg)",
            backgroundColor: `${prizeModalGold.color2}`,
            boxShadow: `1px 1px 9px ${prizeModalGold.color1}`,
        },
        "&::after": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(-45deg)",
            transform: "translate(-50%,-50%) rotate(-45deg)",
            backgroundColor: `${prizeModalGold.color2}`,
            boxShadow: `1px 1px 9px ${prizeModalGold.color1}`,
        },
    },
});
