import { observer } from 'mobx-react';
import cn from 'classnames';
import { Box } from '@mui/material';

import theme from 'src/theme';
import ModalStatusWrapper from 'src/assets/icons/ModalStatusWrapper';
import ModalStatusWrapperMobile from 'src/assets/icons/ModalStatusWrapperMobile';
import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

type IModalUserStatusBox = {
    userStatus?: string | undefined;
    label?: string | undefined;
    placePosition?: string;
};

const ModalUserStatusBox = ({ userStatus, label, placePosition }: IModalUserStatusBox) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const isTablet = theme.breakpoints.values.md;
    const width = window.innerWidth;

    if (placePosition === 'fastGame') {
      return (
        <Box 
        className={cn(styles.modalStatusBox, appearanceTheme, label, placePosition)}>
          <ModalStatusWrapperMobile />
          <p
              className={cn(
                  styles.statusParagraphText,
                  appearanceTheme,
                  label,
                  placePosition
              )}
          >
              {userStatus}
          </p>
    </Box>
      )
    }
    

    return (
        <Box className={cn(styles.modalStatusBox, appearanceTheme, label)}>
            {width > isTablet ? (
                <ModalStatusWrapper />
            ) : (
                <ModalStatusWrapperMobile />
            )}
            <p
                className={cn(
                    styles.statusParagraphText,
                    appearanceTheme,
                    label
                )}
            >
                {userStatus}
            </p>
        </Box>
    );
};

export default observer(ModalUserStatusBox);
