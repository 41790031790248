import { makeStyles } from '@mui/styles';

import { appColors } from '../../consts/app-config';
import theme from '../../theme';

export const useStyles = makeStyles({
    editProfile: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: 'rgba(255, 255, 255, 0.7)',
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 5,
        padding: '15px 10px',
        cursor: 'pointer',
    },

    editProfileButton: {
        marginLeft: 10,
        fontSize: 18,
        fontWeight: 'bold',
    },

    notificationMainTitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        color: 'rgba(255, 255, 255, 0.7)',
        marginTop: 20,
    },
    notificationTitle: {
        fontSize: 24,
        fontWeight: 'bold',
    },

    standardNotification: {
        background: '#141623',
        borderRadius: 5,
        padding: 15,
        marginBottom: 15,
    },
    bonusNotificationTitle: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    standardNotificationTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        color: 'white',
    },
    standardNotificationTime: {
        fontSize: 14,
        color: '#898A91',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bonusNotification: {
        background:
            'linear-gradient(147.58deg, #FFDB47 23.72%, #F5AF3E 72.14%)',
        borderRadius: 5,
        padding: 15,
        marginBottom: 15,
        color: '#AC7C2F',
    },
    notificationText: {
        color: 'rgba(255, 255, 255, 0.5)',
        marginBottom: 15,
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    bonusButtonWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    bonusButton: {
        background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        color: 'white',
        padding: '10px 20px',
        borderRadius: 10,
        marginTop: 15,
        fontWeight: 'bold',
        cursor: 'pointer',
        border: '2px solid rgba(0, 0, 0, 0.2)',
        filter: 'drop-shadow(0px 0px 15.9358px rgba(0, 0, 0, 0.2))',
        mixBlendMode: 'normal',
    },
    bonusButtonTop: {
        position: 'fixed',
        top: '-4px',
        left: '0',
        height: 9,
        width: '100%',
        background:
            'radial-gradient(50.07% 50.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(36, 36, 50, 0) 100%)',
        mixBlendMode: 'color-dodge',
    },
    bonusButtonBottom: {
        position: 'fixed',
        bottom: '-3px',
        left: '0',
        height: 8,
        width: '100%',
        background:
            'radial-gradient(50.07% 50.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(36, 36, 50, 0) 100%)',
        mixBlendMode: 'color-dodge',
    },
    closeButton: {
        alignSelf: 'flex-end',
        marginBottom: 18,
        width: '100%',
        color: 'rgba(255, 255, 255, 0.5)',
        textAlign: 'center',
        cursor: 'pointer',
    },

    container: {
        background: 'linear-gradient(173.43deg, #30313F 0.98%, #30313F 96.27%)',
        padding: '0',
        '@media (min-width: 1060px)': {
            background: 'rgba(21, 21, 21, 0.4)',
        },
        '& .MuiDialog-paperScrollPaper': {
            maxHeight: '100%',
            '@media (min-width: 1060px)': {
                justifyContent: 'flex-end',
            },
        },
        '& .MuiDialog-scrollPaper': {
            '@media (min-width: 1060px)': {
                justifyContent: 'flex-end',
            },
        },
    },
    dialogPaper: {
        maxWidth: 470,
        background: 'linear-gradient(173.43deg, #30313F 0.98%, #30313F 96.27%)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 15px',
        margin: 0,
        overflowY: 'unset',
        [theme.breakpoints.down(500)]: {
            maxWidth: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },

    customScrollbar: {
        // height: 'calc(100vh - 170px)!important',
        // '@media screen and (max-width: 1060px)': {
        height: 'calc(100vh - 60px)!important',
        maskImage: 'linear-gradient(to bottom,black 97%,transparent 100%)',
        // },
        '& .ScrollbarsCustom-Content': {
            display: 'grid',
            gridTemplateColumns: '1fr',
            alignItems: 'flex-start',
            gridRowGap: 16,
            //minHeight: 'auto!important',
            '@media screen and (max-width: 675px)': {
                gridRowGap: 5,
            },
        },
        '& .ScrollbarsCustom-ThumbY': {
            background:
                'linear-gradient(43.94deg, #3F3F3F 19.8%, #6C6C6C 75.46%)!important',
            borderRadius: 19,
        },
    },
    customScrollTrackY: {
        background: 'linear-gradient(173.43deg, #30313F 0.98%, #30313F 96.27%)',
        borderRadius: 19,
        right: '-8px!important',
    },
});
