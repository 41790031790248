import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { Box } from '@mui/material';

import useCalcTableLabel from 'src/hooks/useCalcTableLabel';
import { useLocale } from 'src/providers/LocaleProvider';

import { useStyles } from './styles';

interface ITournamentsMatchResult {
    gameStage: any;
    winner: any;
    myColor: any;
}

export const TournamentsMatchResult = ({
    gameStage,
    winner,
    myColor,
}: ITournamentsMatchResult) => {
    const {
        tournaments: {
            infos: {
                gameResult: { nextRound, stayThisRound },
            },
        },
    } = useLocale();
    const styles = useStyles();
    const nextStageLabel = useCalcTableLabel(gameStage / 2);
    const currentStageLabel = useCalcTableLabel(gameStage);

    return (
        <Box className={cn(styles.matchResultAlertBox)}>
            {winner === myColor ? (
                <p className={styles.description}>
                    {nextRound.compile(
                        {
                            stage: nextStageLabel,
                        },
                        `Congratulations, You goes to the {{stage}} round)`
                    )}
                </p>
            ) : (
                <p className={styles.description}>
                    {stayThisRound.compile(
                        {
                            stage: currentStageLabel,
                        },
                        `You lost and finished in the {{stage}} round. Try roll it again`
                    )}
                </p>
            )}
        </Box>
    );
};

export default observer(TournamentsMatchResult);
