import React, {
    ReactNode,
    Fragment,
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    useRef,
} from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import calcNameLength from "src/utils/calcNameLength";
import lastMoveIsUnAvailable from "src/utils/lastMoveIsUnAvailable";
import FenParser from "src/store/gameStore/utils/parser";
import FenHelper from "src/store/gameStore/utils/helper";
import useStores from "src/hooks/useStores";
import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import shortColor from "src/utils/shortColor";
import { ArrayHelper } from "src/shared/helpers/ArrayHelper";
import getOppositeColor from "src/utils/oppositeColor";
import useChangeTheme from "src/hooks/useChangeTheme";
import {
    appColors,
    miniPiecesColors,
    miniPiecesStroke,
} from "src/consts/app-config";

import {
    ColorVariant,
    IPlayer,
    UserBalanceType,
    PlayerInfoExistPosition,
} from "src/store/models";

import { useStyles } from "./styles";

import miniPieces from "src/components/PlayerInfoRedesign/images/mini-pieces";

interface IKilledPiece {
    name: string;
    component: any;
    killed: boolean;
}
const allPieces = {
    B: 2,
    N: 2,
    R: 2,
    Q: 1,
    P: 8,
};

interface IPiecesComponent {
    color: ColorVariant;
    desktopWidth: number | undefined;
    killedPieces: {};
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
}

const PiecesComponent = observer((props: IPiecesComponent) => {
    const classes = useStyles();
    const appearanceTheme = useChangeTheme();

    const { isMultiTable, killedPieces, color, isTwoGameTable, desktopWidth } =
        props;

    const { gameStore, achievementsStatStore } = useStores();

    const location = useLocation();
    const currentWidth = window.innerWidth;

    const moveFinished = !gameStore?.gameState?.legalPieces?.length;
    const history = gameStore?.gameState?.history;
    const leftColor =
        gameStore?.gameState?.getMyColor?.() === ColorVariant.white
            ? ColorVariant.black
            : ColorVariant.white;

    const parse = new FenParser(FenHelper);

    const [pawns, setPawns] = useState<IKilledPiece[]>([]);
    const [pieces, setPieces] = useState<IKilledPiece[]>([]);
    const [killedPiecesLocal, setKilledPieces] = useState<any>();

    const moves = useMemo(() => {
        return parse.diceMessagesFromHistory(
            history as any,
            leftColor,
            [],
            moveFinished
        );
    }, [history?.length, leftColor, moveFinished]);

    useEffect(() => {
        // Logical For Show Hide VersusBox Preview
        if (moves?.[0] || gameStore?.currentGame?.history?.[0]) {
            return gameStore?.initGameBegin?.(true);
        }
        return gameStore?.initGameBegin?.(false);
    }, [moves, gameStore?.currentGame?.history]);

    useEffect(() => {
        if (!killedPiecesLocal && killedPieces) {
            setKilledPieces(killedPieces);
        }
        const collectedPawns: IKilledPiece[] = [];
        const collectedPieces: IKilledPiece[] = [];

        Object.keys(allPieces).forEach((key) => {
            const total =
                allPieces[key] > killedPieces[key]
                    ? allPieces[key]
                    : killedPieces[key];

            const c = shortColor(getOppositeColor(color));
            const piece = c + key;
            let killed = killedPieces[key];

            for (let i = 0; i < total; i++) {
                if (key !== "P") {
                    if (i <= allPieces[key] - 1) {
                        collectedPieces.push({
                            name: piece + i,
                            component: miniPieces[piece],
                            killed: killed > 0,
                        });
                        killed--;
                    } else {
                        collectedPawns.push({
                            name: `${c}P${collectedPawns.length}`,
                            component: miniPieces[`${c}P`],
                            killed: true,
                        });
                    }
                } else {
                    collectedPawns.push({
                        name: piece + i,
                        component: miniPieces[piece],
                        killed: killed > 0,
                    });
                    killed--;
                }
            }
        });
        setPieces(collectedPieces);
        setPawns(collectedPawns);
    }, [killedPieces, location.pathname]);

    const shotKilledPieces = useMemo(() => {
        const piecesByName = pieces.reduce((acc, elem) => {
            if (acc[elem.name.slice(0, 2)]) {
                acc[elem.name.slice(0, 2)].push(elem);
            } else {
                acc[elem.name.slice(0, 2)] = [elem];
            }
            return acc;
        }, {});
        return Object.values(piecesByName).map((piece: IKilledPiece[], idx) => {
            return ArrayHelper.reverseMap<IKilledPiece, ReactNode>(
                piece,
                (p, i) => {
                    return (
                        <div
                            key={i}
                            className={cn(
                                classes.pawnIconWrapper,
                                appearanceTheme,
                                "pawn-icon-wrapper",
                                {
                                    [classes.chessMenMargin +
                                    "chessmen-margin"]: i > 0,
                                    isMultiTable,
                                    isTwoGameTable,
                                    isKilled: p.killed,
                                }
                            )}
                            style={{
                                width: desktopWidth
                                    ? (desktopWidth / 16) * 0.75
                                    : 30,
                                height: desktopWidth
                                    ? (desktopWidth / 16) * 0.75
                                    : 30,
                            }}
                        >
                            {p.component(
                                p.killed
                                    ? [
                                          miniPiecesColors[appearanceTheme][
                                              "w"
                                          ],
                                          miniPiecesStroke[appearanceTheme][
                                              "w"
                                          ],
                                      ]
                                    : [
                                          "none",
                                          miniPiecesStroke[appearanceTheme][
                                              "w"
                                          ],
                                      ]
                            )}
                        </div>
                    );
                }
            );
        });
    }, [pieces, desktopWidth, appearanceTheme]);

    const shotKilledPawns = useMemo(() => {
        return ArrayHelper.reverseMap<IKilledPiece, ReactNode>(
            pawns,
            (p, i) => {
                return (
                    <div
                        key={i}
                        className={cn(
                            classes.pawnIconWrapper,
                            "pawn-icon-wrapper",
                            appearanceTheme,
                            {
                                [classes.chessMenMargin]: i > 0,
                                isMultiTable,
                                isTwoGameTable,
                                isKilled: p.killed,
                            }
                        )}
                        style={{
                            width: desktopWidth
                                ? (desktopWidth / 16) * 0.75
                                : 30,
                            height: desktopWidth
                                ? (desktopWidth / 16) * 0.75
                                : 30,
                        }}
                    >
                        {p.component(
                            p.killed
                                ? [
                                      miniPiecesColors[appearanceTheme]["w"],
                                      miniPiecesStroke[appearanceTheme]["w"],
                                  ]
                                : [
                                      "none",
                                      miniPiecesStroke[appearanceTheme]["w"],
                                  ]
                        )}
                    </div>
                );
            }
        );
    }, [pawns, desktopWidth, appearanceTheme]);

    return (
        <div
            className={cn(
                classes.piecesWrapper,
                "pieces-wrapper",
                appearanceTheme,
                {
                    isMultiTable,
                }
            )}
        >
            <div className={cn(classes.miniPieces, appearanceTheme)}>
                {shotKilledPawns}
            </div>
            <div
                className={cn(classes.miniPieces, appearanceTheme, {
                    isMultiTable,
                })}
            >
                {shotKilledPieces}
            </div>
        </div>
    );
});

export default PiecesComponent;
