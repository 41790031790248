import { observer } from 'mobx-react';
import React from 'react';
import { useLocale } from 'src/providers/LocaleProvider';

import useStores from 'src/hooks/useStores';
import GeneralGameDialogNew from '../common/generalDialogNew';

const OpponentIsRejectedDraw = () => {
    const {
        game: {
            dialogs: {
                draw: {
                    opponentIsRejectedDraw: { title },
                },
            },
        },
    } = useLocale();

    const { gameStore } = useStores();
    const rejectedPlayer = gameStore.gameState.getPlayerByColor(
        gameStore.gameState.getOpponentColor()
    );
    return (
        <GeneralGameDialogNew
            avatar={rejectedPlayer?.avatar}
            noButtons
            title={title.compile(
                { nickname: rejectedPlayer?.name },
                `The {{nickname}} is rejected a draw`
            )}
        />
    );
};

export default observer(OpponentIsRejectedDraw);
