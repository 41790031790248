import React, { useState } from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import { useLocale } from "src/providers/LocaleProvider";
import theme from "src/theme";
import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";
import { RapidIcon } from "src/components/TimeIcon/icons/rapid";
import { BlitzIcon } from "src/components/TimeIcon/icons/blitz";
import { BulletIcon } from "src/components/TimeIcon/icons/bullet";
import { GameTime, GameTimeValues } from "src/store/lobbyStore";
import TimeFilterPopup, {
    TimeFilterPopupPosition,
} from "src/pages/lobby/components/TimeFilterPopup";
import { getTimeAndIncrement } from "src/store/libs";
import { appColors, backgroundColors } from "src/consts/app-config";
import { ClassicalIcon } from "src/components/TimeIcon/icons/classical";

import { SortDown } from "../../../../images/sort-down";

import { useTimeConfigStyles } from "./styles";

// const icons = {
//     [GameTime["3m"]]: <BulletIcon />,
//     [GameTime["5m"]]: <BlitzIcon />,
//     [GameTime["10m"]]: <RapidIcon />,
//     [GameTime["20m"]]: <ClassicalIcon />,
// };

const icons = {
    [GameTimeValues["3m"]]: <BulletIcon />,
    [GameTimeValues["5m"]]: <BlitzIcon />,
    [GameTimeValues["10m"]]: <RapidIcon />,
    [GameTimeValues["20m"]]: <ClassicalIcon />,
};

type TimeConfigProps = {
    position?: TimeFilterPopupPosition;
    placePosition?: string | undefined;
};

const TimeConfig = ({ position, placePosition }: TimeConfigProps) => {
    const { width } = useWindowSize();
    const mobileAndTablet = theme.breakpoints.values.sm;
    const CLOSE = false;
    const styles = useTimeConfigStyles();
    const { lobbyStore } = useStores();
    const [isOpen, setOpenAdditional] = useState<boolean>(CLOSE);
    const timeIsOpen = lobbyStore.timeConfig.isOpen;
    const {
        lobby: {
            gameType: {
                timeDetails,
                timeDetailsShort,
                rapid: { time: rapidTime, timeShort: rapidTimeShort },
                classical: {
                    time: classicalTime,
                    timeShort: classicalTimeShort,
                },
            },
        },
    } = useLocale();

    const currentTimeConfigValues =
        width && width > mobileAndTablet ? timeDetails : timeDetailsShort;

    const isShortDevice = width && width <= mobileAndTablet;

    const toggler = (status: boolean): void => {
        if (!status && !isOpen) return;
        setOpenAdditional(status);
        lobbyStore.setTimeConfigIsOpen(status);
    };

    const stateCatcher = () => {
        toggler(!isOpen);
    };

    //Temporary turned off - didnt work locale from backend
    const getTimeLabel = () => {
        switch (lobbyStore.timeConfig.value) {
            // case GameTime["10m"]:
            case GameTimeValues["10m"]:
                return isShortDevice
                    ? rapidTimeShort("10м + 0сек")
                    : rapidTime.compile({});
            case GameTimeValues["20m"]:
                return isShortDevice
                    ? classicalTimeShort("20m + 0sec")
                    : classicalTime("20 мин + 0 сек");
            default:
                return currentTimeConfigValues.compile(
                    getTimeAndIncrement(lobbyStore.timeConfig.value)
                );
        }
    };

    return (
        <div className={styles.wrapperTimeConfig}>
            <OutsideClickHandler
                onOutsideClick={() => {
                    toggler(CLOSE);
                }}
            >
                {timeIsOpen && isOpen && (
                    <TimeFilterPopup
                        position={position}
                        placePosition={placePosition}
                    />
                )}
                <div className={styles.divContainer} onClick={stateCatcher}>
                    <Box
                        component="div"
                        className={cn(styles.container, placePosition)}
                    >
                        <Box
                            component="div"
                            className={cn(styles.leftSideBox, placePosition)}
                        >
                            <Box
                                component="div"
                                className={cn(styles.icon, placePosition)}
                            >
                                {icons[lobbyStore.timeConfig.value]}
                            </Box>
                            <Box
                                component="div"
                                className={cn(styles.text, placePosition)}
                            >
                                {getTimeLabel()}
                            </Box>
                        </Box>
                        {isOpen ? (
                            <Box
                                component="div"
                                className={cn(
                                    styles.sortDownOpen,
                                    placePosition
                                )}
                            >
                                <SortDown color={appColors.bg500} />
                            </Box>
                        ) : (
                            <Box
                                component="div"
                                className={cn(styles.sortDown, placePosition)}
                            >
                                <SortDown color={appColors.bg500} />
                            </Box>
                        )}
                    </Box>
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default observer(TimeConfig);
