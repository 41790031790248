import useStyles from "./style";
import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import {
    ButtonType,
    GreenButton,
} from "../../../../../components/buttons/greenButton";
import FadeInOutVertical from "src/components/Animated";
import { endpointsRoot } from "../../../../../core/endpoints";
import UserAvatar from "src/components/UserAvatar";
import cn from "classnames";
import useWindowSize from "src/hooks/useWindowSize";
import useChangeTheme from "src/hooks/useChangeTheme";
import { getButtonColor } from "src/helpers";
interface GeneralProps {
    avatar: string | undefined;
    noButtons: boolean;
    amount?: string;
    amountColor?: string;
    titleColor?: string;
    titleFontSize?: number;
    message?: string | JSX.Element;
    title: string | JSX.Element;
    loadingMessage?: boolean;
    isMultiTable?: boolean;
}

interface PropsNoButton extends GeneralProps {
    noButtons: true;
    isSystemAvatar?: boolean;
    isXlTablet?: boolean | undefined;
}

interface PropsWithButton extends GeneralProps {
    noButtons: false;
    approveText: string;
    cancelText: string;
    onApprove: () => void;
    onCancel: () => void;
    approveButtonType?: ButtonType;
    rejectButtonType?: ButtonType;
    isSystemAvatar?: boolean;
    isXlTablet?: boolean | undefined;
}

type Props = PropsWithButton | PropsNoButton;

const GeneralGameDialogNew = (props: Props) => {
    const { titleFontSize, isSystemAvatar, isXlTablet, isMultiTable } = props;
    const { width } = useWindowSize();
    const currentWidth = width || window.innerWidth;
    const styles = useStyles({ currentWidth, titleFontSize });
    const appearanceTheme = useChangeTheme();

    return (
        <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
            <div
                className={cn(styles.containerAlertsDesk, appearanceTheme, {
                    noButtons: props.noButtons,
                    isMultiTable,
                })}
            >
                <div
                    className={cn(styles.flexContainer, {
                        isXlTablet: isXlTablet,
                        isMultiTable,
                    })}
                >
                    <div>
                        <UserAvatar
                            avatarUrl={
                                (props.avatar &&
                                    `${endpointsRoot}/avatars/${props.avatar}`) ||
                                ""
                            }
                            nickname={""}
                            className={cn(styles.avatarBlock, { isMultiTable })}
                            isSystemAvatar={isSystemAvatar}
                        />
                    </div>
                    <div
                        className={cn(styles.generalDialogInfo, {
                            isMultiTable,
                        })}
                    >
                        <p className={cn(styles.title, { isMultiTable })}>
                            {props.title}
                        </p>
                        {!props.loadingMessage && (
                            <p className={cn(styles.message, { isMultiTable })}>
                                {props.message}
                            </p>
                        )}
                        {props.loadingMessage && (
                            <PulseLoader color={"#48E2CC"} size={12} />
                        )}
                    </div>
                </div>
                {!props.noButtons && (
                    <div className={styles.buttonContainer}>
                        <GreenButton
                            type={props.rejectButtonType || ButtonType.negative}
                            fontSize={isMultiTable ? 12 : 16}
                            text={props.cancelText}
                            onClick={props.onCancel}
                            isWidthFixed
                        />
                        <div className={styles.divider} />
                        <GreenButton
                            type={getButtonColor(appearanceTheme, ButtonType)}
                            fontSize={isMultiTable ? 12 : 16}
                            text={props.approveText}
                            onClick={props.onApprove}
                            isWidthFixed
                        />
                    </div>
                )}
            </div>
        </FadeInOutVertical>
    );
};

export default GeneralGameDialogNew;
