import { Match, Player, Round } from "./types";

const getNextPowerOfTwo = (num: number): number => {
    let power = 1;
    while (power < num) {
        power *= 2;
    }
    return power;
};

function generateTournamentBracket(tournament: {
    players?: Array<{ nickname: string, _id: string, email: string, avatar?: string }>;
    pairs?: Array<{
        step: number,
        gameId: string | null,
        player1: { nickname: string, _id: string, email: string, avatar?: string },
        player2: { nickname: string, _id: string, email: string, avatar?: string }
    }>;
    maxPlayers?: number;
} | null): Round[] {
    if (!tournament || !tournament.pairs || !tournament.pairs.length) {
        console.error("No pairs found in tournament data", tournament);
        return [];
    }

    const tbdPlayer: Player = { name: "Ожидается", score: 0 };
    const maxPlayers = getNextPowerOfTwo(tournament.maxPlayers || 2);

    // Создаем карту пар по шагам
    const pairsByStep = new Map<number, Array<{ player1: Player, player2: Player }>>();

    tournament.pairs.forEach(pair => {
        if (pair.player1 && pair.player2) {
            const matchPair = {
                player1: {
                    name: pair.player1.nickname,
                    avatar: pair.player1.avatar,
                    score: 0
                },
                player2: {
                    name: pair.player2.nickname,
                    avatar: pair.player2.avatar,
                    score: 0
                }
            };
            const pairs = pairsByStep.get(pair.step) || [];
            pairs.push(matchPair);
            pairsByStep.set(pair.step, pairs);
        }
    });

    // Функция для генерации названия раунда на основе количества пар
    const getRoundTitle = (step: number, totalPairs: number): string => {
        const totalPlayers = totalPairs * 2; // Общее количество игроков
        const playersInCurrentRound = totalPlayers / Math.pow(2, step - 1);

        if (playersInCurrentRound === 2) return "Финал";
        if (playersInCurrentRound === 4) return "Полуфинал";

        return `1/${playersInCurrentRound}`;
    };

    // Создаем раунды на основе пар
    const rounds: Round[] = [];
    const totalSteps = Math.ceil(Math.log2(maxPlayers));
    const totalInitialPairs = Math.max(...Array.from(pairsByStep.keys())); // Получаем максимальный step

    for (let step = 1; step <= totalSteps; step++) {
        const pairs = pairsByStep.get(step) || [];
        const expectedMatches = maxPlayers / Math.pow(2, step);
        const matches: Match[] = [];

        // Добавляем существующие пары
        pairs.forEach(pair => {
            matches.push({
                player1: {
                    name: pair.player1.name,
                    avatar: pair.player1.avatar,
                    score: 0
                },
                player2: {
                    name: pair.player2.name,
                    avatar: pair.player2.avatar,
                    score: 0
                }
            });
        });

        // Добавляем недостающие матчи с TBD игроками
        while (matches.length < expectedMatches) {
            matches.push({
                player1: tbdPlayer,
                player2: tbdPlayer
            });
        }

        const roundTitle = getRoundTitle(step, totalInitialPairs);
        rounds.push({
            title: roundTitle,
            matches: matches
        });

        // Если достигли финала - заканчиваем
        if (matches.length <= 1) break;
    }

    return rounds;
}

export default generateTournamentBracket;
