import { makeStyles } from "@mui/styles";
import { appColors } from "src/consts/app-config";

import theme from "src/theme";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";

export const useStyles = makeStyles({
    beatPiecesCounter: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("lg")]: {
            marginLeft: 5,
        },
    },

    beatPiecesParagraphImg: {
        width: "50%",
        aspectRatio: "1 / 1",
        marginBottom: "4px",
        opacity: 0.3,
        [theme.breakpoints.down("lg")]: {
            width: "auto",
            height: "40px",
            marginBottom: "5px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "auto",
            height: "30px",
            marginBottom: "4px",
        },
        "&.isViewMode": {
            width: "30%",
            aspectRatio: "auto",
        },
    },

    beatPiecesParagraph: {
        display: "flex",
        alignItems: "center",
        width: "100%",
 
        fontFamily: "Montserrat",
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: 400,
        color: "rgba(245, 245, 245, 0.8)",
        letterSpacing: "-1px",
        [theme.breakpoints.down("lg")]: {
            aspectRatio: "auto",
            fontSize: "24px",
            lineHeight: "26px",
            letterSpacing: "-1px",
        },
        [theme.breakpoints.down(350)]: {
            fontSize: "20px",
            lineHeight: "22px",
            letterSpacing: "-2px",
        },
        "&.isViewMode": {
            aspectRatio: "auto",
        },
    },
});
