import {usePageVisibility} from 'react-page-visibility';
import {useMemo} from 'react';

import {SoundFilesType} from 'src/store/models';
import useStores from 'src/hooks/useStores';

export const usePlayer = () => {
    const {authStore, generalStore} = useStores();
    const user = authStore.currentUser;
    const isVisibleApplication = usePageVisibility();
    const diceSoundPlay = 'diceSoundPlay';
    const pieceSoundPlay = 'pieceSoundPlay';

    const noteSoundLocal = localStorage.getItem('noteSound') === 'true';
    const pieceSoundLocal = localStorage.getItem('pieceSound') === 'true';
    const diceSoundLocal = localStorage.getItem('diceSound') === 'true';

    const noteSounds = noteSoundLocal ? ['raise', 'joinSoundPlay'] : [];
    const pieceSound = pieceSoundLocal ? ['pieceSoundPlay'] : [];
    const diceSound = diceSoundLocal ? ['diceSoundPlay'] : [];

    const soundArray = useMemo(
        () => [...noteSounds, ...pieceSound, ...diceSound],
        [noteSoundLocal, pieceSoundLocal, diceSoundLocal]
    );

    const player = (sound: keyof SoundFilesType) => {
       if (!sound) return;
    const soundFilePath = generalStore.sounds[sound];
    const audio = new Audio(soundFilePath);
    audio.volume = sound === 'gameCreate' ? 0.15 : 1;
    const soundIsEnabled = user?.settings.sound;

        if (soundIsEnabled && isVisibleApplication) {
            audio.play()
                .then(() => console.log(`Successfully started playing ${sound}`))
                .catch((err) => console.error('Audio play error:', err));
        }
        // if (
        //     ([diceSoundPlay, pieceSoundPlay].includes(sound) ||
        //         soundIsEnabled) &&
        //     isVisibleApplication
        // ) {
        //     const audioPlayerPromise = audio.play();
        //     audioPlayerPromise.catch(() => {});
        // }
    };

    return player;
};
