import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    // leftSide: ({ transformWidth }: { transformWidth: number }) => ({
    //     transform: `skew(-${transformWidth}deg, 0deg) translateX(${transformWidth}px)`,
    //     borderRadius: '5px 5px 10px 10px',
    //     left: 0,
    // }),

    watchingWrapper: {
        display: 'flex',
        justifСontent: 'center',
        alignItems: 'center',
        padding: '4px 12px',
        background: 'rgba(113, 121, 148, 0.2)',
        borderRadius: '24px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '0px',
        color: '#A8B1CE',
        width: 'fit-content',
        margin: '0 auto',
        cursor: 'pointer',
        '&.darkBlue': {
            background: 'rgba(113, 121, 148, 0.2)',
        },
        '&.darkGreen': {
            background: 'rgba(17, 131, 110, 0.25)',
        },
    },

    watchingUsersIcon: {},

    watchingUsersText: {},

    watchingUsersCount: {
        fontWeight: 700,
    },
});
