import { Player } from './types';

export const initialPlayers: Player[] = [
    { name: "ShadowHunter", score: 1, avatar: "https://example.com/shadowhunter-avatar.jpg" },
    { name: "MysticWizard", score: 0, avatar: "https://example.com/mysticwizard-avatar.jpg" },
    { name: "CrimsonArrow", score: 1, avatar: "https://example.com/crimsonarrow-avatar.jpg" },
    { name: "LunarPhantom", score: 0, avatar: "https://example.com/lunarphantom-avatar.jpg" },
    { name: "QuantumBeast", score: 0, avatar: "https://example.com/quantumbeast-avatar.jpg" },
    { name: "NebulaPirate", score: 1, avatar: "https://example.com/nebulapirate-avatar.jpg" },
    { name: "EmberWarlock", score: 1, avatar: "https://example.com/emberwarlock-avatar.jpg" },
    { name: "CelestialAce", score: 0, avatar: "https://example.com/celestialace-avatar.jpg" },
    { name: "VoidWalker", score: 1, avatar: "https://example.com/voidwalker-avatar.jpg" },
    { name: "StormBringer", score: 0, avatar: "https://example.com/stormbringer-avatar.jpg" },
    { name: "FrostNova", score: 1, avatar: "https://example.com/frostnova-avatar.jpg" },
    { name: "InfernoKnight", score: 0, avatar: "https://example.com/infernoknight-avatar.jpg" },
    { name: "EchoSiren", score: 1, avatar: "https://example.com/echosiren-avatar.jpg" },
    { name: "PhantomRogue", score: 0, avatar: "https://example.com/phantomrogue-avatar.jpg" },
    { name: "CyberNinja", score: 1, avatar: "https://example.com/cyberninja-avatar.jpg" },
    { name: "GalacticSamurai", score: 0, avatar: "https://example.com/galacticsamurai-avatar.jpg" },
    { name: "AetherDruid", score: 1, avatar: "https://example.com/aetherdruid-avatar.jpg" },
    { name: "TechnoMancer", score: 0, avatar: "https://example.com/technomancer-avatar.jpg" },
    { name: "AstralValkyrie", score: 1, avatar: "https://example.com/astralvalkyrie-avatar.jpg" },
    { name: "ChronoShifter", score: 0, avatar: "https://example.com/chronoshifter-avatar.jpg" },
    { name: "NeonSpectre", score: 1, avatar: "https://example.com/neonspectre-avatar.jpg" },
    { name: "PulsarGuardian", score: 0, avatar: "https://example.com/pulsarguardian-avatar.jpg" },
    { name: "ZephyrScout", score: 1, avatar: "https://example.com/zephyrscout-avatar.jpg" },
];

export const testPlaces = [
    { place: '1st place:', coins: 300, rp: 25 },
    { place: '2nd place:', coins: 200, rp: 15 },
    { place: '3rd place:', coins: 150, rp: 10 },
    { place: '4th place:', coins: 50, rp: 5 },
    { place: '5th place:', coins: 20, rp: 5 },
    { place: '6th place:', coins: 10, rp: 5 },
    { place: '7th place:', coins: 6, rp: 5 },
    { place: '8th place:', coins: 4, rp: 5 },
    { place: '9th place:', coins: 2, rp: 5 },
    { place: '10th place:', coins: 2, rp: 5 },
    { place: '11th place:', coins: 51, rp: 5 },
    { place: '12th place:', coins: 0, rp: 5 },
];

export const COLUMN_GAPS = [
    10,    // 1/32
    100,   // 1/16
    280,   // 1/8
    460,   // 1/4
    800    // Semi Final
];

export const COLUMN_MARGINS = [
    0,     // 1/32
    45,    // 1/16
    135,   // 1/8
    315,   // 1/4
    585    // Semi Final
];