import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

//Офицер
const WhiteDice = ({ width, height }) => {
    return (
        <svg
            id='w-03_svg__Layer_1'
            x={0}
            y={0}
            viewBox='0 0 70 70'
            xmlSpace='preserve'
            width={width}
            height={height}
        >
            <style>{'.w-03_svg__st1{fill:#231f20}'}</style>
            <linearGradient
                id='w-03_svg__SVGID_1_'
                gradientUnits='userSpaceOnUse'
                x1={35}
                y1={69.5}
                x2={35}
                y2={0.5}
            >
                <stop offset={0} stopColor='#bcc5c7' />
                <stop offset={0.65} stopColor='#e6e8e9' />
            </linearGradient>
            <path
                d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
                fill='url(#w-03_svg__SVGID_1_)'
            />
            <path
                d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
                fill='#fff'
            />
            <g>
                <linearGradient
                    id='w-03_svg__SVGID_2_'
                    gradientUnits='userSpaceOnUse'
                    x1={12.194}
                    y1={35.904}
                    x2={57.806}
                    y2={35.904}
                >
                    <stop offset={0} stopColor='#f7f5d2' />
                    <stop offset={1} stopColor='#d7d4b7' />
                </linearGradient>
                <path
                    d='M55.6 58.3c-1-.6-3-1.7-5.1-1.7-1.2 0-2.1.3-3 .5-1.3.4-2.7.8-5.8.8-4.8 0-5.4-4-5.4-4.1l-.1-.6h-2.5l-.1.6c0 .2-.7 4.1-5.4 4.1-3.1 0-4.5-.4-5.8-.8-.9-.3-1.8-.5-3-.5-2.1 0-4.1 1.1-5.1 1.7l-2.2-4.9c.7-.6 2.4-1.7 5.3-1.7 1.4 0 2.9.3 4.5.9 1.8.6 3.4 1 4.7 1 4 0 4.9-2.6 4.9-2.7l.3-.8-.8-.2c-1.6-.2-4.2-.8-5.3-1.6l3.6-7.9h-1s-2.5-.1-4.1-1.9c-1.2-1.4-1.6-3.4-1.3-6.1.4-3.3 3.4-5.8 6-8 2-1.7 3.8-3.2 4.1-4.8l.1-.5-.3-.4c-.7-.6-1.1-1.4-1.1-2.3 0-1.7 1.5-3 3.4-3s3.4 1.4 3.4 3c0 .9-.4 1.7-1.1 2.3l-.5.3.1.6c.4 1.8 2.2 3.3 4.3 5 2.6 2.1 5.5 4.4 5.8 7.7.3 2.7-.2 4.8-1.5 6.2-1.6 1.8-3.9 1.8-3.9 1.8h-1l3.6 7.9c-1.2.7-3.8 1.3-5.3 1.6l-.8.2.3.8c0 .1 1 2.7 4.9 2.7 1.4 0 3-.3 4.7-1 1.6-.6 3.1-.9 4.5-.9 2.9 0 4.6 1.2 5.3 1.7l-2.3 5z'
                    fill='url(#w-03_svg__SVGID_2_)'
                />
                <linearGradient
                    id='w-03_svg__SVGID_3_'
                    gradientUnits='userSpaceOnUse'
                    x1={35}
                    y1={61.252}
                    x2={35}
                    y2={11.465}
                >
                    <stop offset={0.001} stopColor='#fff' />
                    <stop offset={1} stopColor='#a4b0b2' />
                </linearGradient>
                <path
                    d='M35 13.5c1.9 0 3.4 1.4 3.4 3 0 .9-.4 1.7-1.1 2.3l-.4.2.1.6c.4 1.8 2.2 3.3 4.3 5 2.6 2.1 5.5 4.4 5.8 7.7.3 2.7-.2 4.8-1.5 6.2-1.6 1.8-3.9 1.8-3.9 1.8h-1l3.6 7.9c-1.2.7-3.8 1.3-5.3 1.6l-.8.2.3.8c0 .1 1 2.7 4.9 2.7 1.4 0 3-.3 4.7-1 1.6-.6 3.1-.9 4.5-.9 2.9 0 4.6 1.2 5.3 1.7l-2.2 4.9c-1-.6-3-1.7-5.1-1.7-1.2 0-2.1.3-3 .5-1.3.4-2.7.8-5.8.8-4.8 0-5.4-4-5.4-4.1l-.1-.6h-2.5l-.1.6c0 .2-.7 4.1-5.4 4.1-3.1 0-4.5-.4-5.8-.8-.9-.3-1.8-.5-3-.5-2.1 0-4.1 1.1-5.1 1.7l-2.2-4.9c.7-.6 2.4-1.7 5.3-1.7 1.4 0 2.9.3 4.5.9 1.8.6 3.4 1 4.7 1 4 0 4.9-2.6 4.9-2.7l.3-.8-.9-.1c-1.6-.2-4.2-.8-5.3-1.6l3.6-7.9h-1s-2.5-.1-4.1-1.9c-1.2-1.4-1.6-3.4-1.3-6.1.4-3.3 3.4-5.8 6-8 2-1.7 3.8-3.2 4.1-4.8l.1-.5-.3-.4c-.7-.6-1.1-1.4-1.1-2.3-.1-1.6 1.4-2.9 3.3-2.9m0-2c-3 0-5.4 2.3-5.4 5 0 1.2.4 2.3 1.2 3.2-.5.9-1.9 2.1-3.3 3.3-2.8 2.3-6.2 5.2-6.7 9.2-.4 3.3.2 5.8 1.8 7.7 1.1 1.3 2.6 2 3.7 2.3l-2.4 5.4-.7 1.6 1.5.9c.9.6 2.2 1 3.4 1.3-.4.1-.8.2-1.4.2-1.1 0-2.5-.3-4-.8-1.8-.6-3.5-1-5.2-1-3.4 0-5.6 1.4-6.6 2.2l-1.1 1 .6 1.4 2.2 4.9.9 2.1 1.9-1.2c.7-.4 2.4-1.4 4.1-1.4.9 0 1.6.2 2.4.4 1.3.4 3 .9 6.4.9 3.7 0 5.8-2 6.7-3.9.9 1.9 3 3.9 6.8 3.9 3.4 0 5-.5 6.4-.9.8-.3 1.5-.4 2.4-.4 1.6 0 3.4 1 4.1 1.4l1.9 1.2.9-2.1 2.2-4.9.6-1.4-1.2-1c-1-.8-3.1-2.2-6.6-2.2-1.6 0-3.4.3-5.2 1-1.5.6-2.9.8-4 .8-.6 0-1-.1-1.4-.2 1.2-.3 2.5-.7 3.4-1.3l1.5-.9-.7-1.6-2.4-5.4c1.1-.3 2.4-.9 3.5-2.2 1.6-1.8 2.3-4.4 2-7.7-.4-4.1-3.8-6.9-6.5-9.1-1.5-1.2-3-2.4-3.5-3.4.8-.9 1.2-2 1.2-3.2 0-2.9-2.4-5.1-5.4-5.1z'
                    fill='url(#w-03_svg__SVGID_3_)'
                />
                <path
                    className='w-03_svg__st1'
                    d='M38.4 31H36v-2.4c0-.5-.4-1-1-1-.5 0-1 .4-1 1V31h-2.4c-.5 0-1 .4-1 1v.1c0 .5.4 1 1 1H34v2.4c0 .5.4 1 1 1 .5 0 1-.4 1-1V33h2.4c.5 0 1-.4 1-1s-.5-1-1-1z'
                />
                <path
                    className='w-03_svg__st1'
                    d='M52.5 51c-1.3 0-2.9.2-4.7.9-1.9.7-3.3.9-4.5.9-3.5 0-4.3-2.3-4.3-2.3s4.7-.7 6.1-2l-3.4-7.4s6.9 0 6.1-8.8c-.6-6.2-9.4-9.1-10.1-12.8 0-.1 0-.2-.1-.2.9-.7 1.4-1.7 1.4-2.8 0-2-1.8-3.7-4-3.7s-4 1.7-4 3.7c0 1.1.5 2.1 1.4 2.8 0 .1 0 .1-.1.2-.7 3.4-9.4 6.7-10.1 12.8-1.1 8.6 5.9 8.8 6.1 8.8l-3.4 7.4c1.3 1.3 6.1 2 6.1 2s-.8 2.3-4.3 2.3c-1.2 0-2.6-.3-4.5-.9-1.8-.7-3.4-.9-4.7-.9-4.1 0-6.1 2.2-6.1 2.2l2.7 6.1s2.7-2 5.4-2 3.4 1.3 8.8 1.3 6.6-5.4 6.6-5.4 1.5 5.4 6.9 5.4 6.1-1.3 8.8-1.3 5.4 2 5.4 2l2.7-6.1c-.1.1-2.1-2.2-6.2-2.2zm-20.6-1.6l-.8-.1c-1.6-.2-3.5-.7-4.6-1.2 1.2-.5 8.4-2.8 16.8 0-.1 0-.2.1-.3.1-4.1 1.4-8.1 1.5-11.1 1.2zm10.9-2.7c-2.9-.8-5.6-1.1-7.9-1.1-3.4 0-6.1.6-7.7 1.1l2.3-5 .3-.6c2-.7 5.9-1.6 10.5.1l.2.5 2.3 5zM32.3 16.5c0-1.3 1.2-2.4 2.7-2.4s2.7 1.1 2.7 2.4c0 .7-.3 1.3-.9 1.7l-.7.5c-.9.2-1.8.1-2.3 0l-.6-.5c-.6-.4-.9-1-.9-1.7zm-8.8 16c.4-3 3.2-5.4 5.8-7.5 2.1-1.8 3.9-3.3 4.3-5.1.3.1.9.1 1.5.1.4 0 .8-.1 1.3-.1.4 2 2.4 3.7 4.5 5.4 2.6 2.1 5.3 4.3 5.6 7.3.2 2.5-.2 4.4-1.3 5.7-1.4 1.5-3.4 1.6-3.4 1.6h-2.1c-1.7-.5-3.4-.7-4.8-.7-2 0-3.7.4-4.9.8h-1.7s-2.2-.1-3.6-1.7c-1.1-1.4-1.5-3.3-1.2-5.8zm4.8 24.8c-3 0-4.4-.4-5.6-.8-1-.3-1.9-.6-3.2-.6-1.8 0-3.6.8-4.8 1.4L13 53.7c.8-.5 2.2-1.3 4.4-1.3 1.3 0 2.8.3 4.2.8 1.8.7 3.5 1 5 1 4.4 0 5.5-3.1 5.5-3.2l.1-.4c.6.1 1.2.1 1.8.1l-1 3c-.1.4-.7 3.6-4.7 3.6zm27 .1c-1.2-.6-3-1.4-4.8-1.4-1.3 0-2.2.3-3.2.6-1.2.4-2.6.8-5.6.8-4.1 0-4.7-3.2-4.7-3.6l-1.2-3.1c.6 0 1.2-.1 1.8-.1l.2.5c0 .1 1.1 3.2 5.5 3.2 1.5 0 3.1-.3 5-1 1.5-.5 2.9-.8 4.2-.8 2.2 0 3.7.7 4.4 1.3l-1.6 3.6z'
                />
            </g>
        </svg>
    );
};

const GoldDice = ({ width, height }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 54 54'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                width='54'
                height='54'
                rx='7.03677'
                fill='url(#paint0_linear_5445_63122)'
            />
            <rect
                x='3'
                y='3'
                width='48'
                height='48'
                rx='7.03677'
                fill='url(#paint1_linear_5445_63122)'
            />
            <circle
                cx='27'
                cy='27'
                r='27'
                fill='url(#paint2_linear_5445_63122)'
                fill-opacity='0.6'
            />
            <g filter='url(#filter0_i_5445_63122)'>
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M41.7973 37.8845C41.7973 37.8845 41.6959 37.7822 41.5946 37.7822C41.4932 37.6799 41.3919 37.5775 41.1892 37.5775C40.7838 37.3729 40.277 37.1683 39.5676 36.9637C38.1487 36.5544 36.1216 36.4521 33.5878 37.3729C31.2568 38.1914 29.7365 37.8845 28.8243 37.3729C28.6216 37.2706 28.2162 35.9323 28.2162 35.83C33.4865 35.83 33.4865 34.5 33.4865 34.5L33.2838 34.0908C33.2838 34.0908 30.8514 29.802 30.9527 29.802C31.4595 29.5973 32.2703 29.3927 32.9797 28.9835C33.6892 28.5742 34.3987 28.0627 34.9054 27.3465C35.4122 26.6304 35.6149 25.6072 35.3108 24.5841C35.0068 23.3564 34.2973 22.3333 33.4865 21.4125C32.6757 20.4917 31.7635 19.5709 30.9527 18.8548C30.2432 18.2409 29.6351 17.6271 29.2297 17.1155C28.8243 16.6039 28.4189 15.4785 28.4189 15.4785C28.9257 15.0693 29 14 29 14C29 13 28 12 27 12C26 12 25 12.7723 25 14C25 14.6139 25.4797 15.2739 25.8851 15.5808C25.8851 15.5808 25.5811 16.7063 25.0743 17.2178C24.6689 17.7294 24.0608 18.2409 23.3514 18.9571C22.5405 19.7756 21.527 20.594 20.8176 21.5148C20.0068 22.4356 19.2973 23.561 18.9932 24.6865C18.6892 25.8119 18.8919 26.7327 19.3986 27.4488C19.9054 28.165 20.6149 28.6766 21.3243 29.0858C22.0338 29.495 22.7432 29.6997 23.3514 29.9043C23.4527 29.9043 21.0203 34.1931 21.0203 34.1931L20.8176 34.5C20.8176 34.5 20.9189 35.83 26.0878 35.83C26.0878 35.83 25.6824 37.2706 25.4797 37.3729C24.5676 37.8845 23.0473 38.2937 20.7162 37.3729C18.1824 36.4521 16.1554 36.5544 14.7365 36.9637C14.027 37.1683 13.5203 37.3729 13.1149 37.5775C12.7095 37.5775 12 38.0891 12 38.0891L13.2162 43C13.2162 43 14.6351 42.0792 15.8514 41.4653C16.8649 40.9538 17.9797 41.1584 18.9932 41.4653C24.8716 43.4092 26.6959 40.8515 26.8986 40.1353C27.2027 40.8515 30.0405 43.4092 34.8041 41.4653C35.8176 41.0561 37.0338 40.9538 37.9459 41.4653C39.1622 42.1815 40.5811 43 40.5811 43L41.6959 38.396L41.7973 38.0891C42 38.0891 41.7973 37.8845 41.7973 37.8845Z'
                    fill='white'
                />
            </g>
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M41.7973 37.8845C41.7973 37.8845 41.6959 37.7822 41.5946 37.7822C41.4932 37.6799 41.3919 37.5775 41.1892 37.5775C40.7838 37.3729 40.277 37.1683 39.5676 36.9637C38.1487 36.5544 36.1216 36.4521 33.5878 37.3729C31.2568 38.1914 29.7365 37.8845 28.8243 37.3729C28.6216 37.2706 28.2162 35.9323 28.2162 35.83C33.4865 35.83 33.4865 34.5 33.4865 34.5L33.2838 34.0908C33.2838 34.0908 30.8514 29.802 30.9527 29.802C31.4595 29.5973 32.2703 29.3927 32.9797 28.9835C33.6892 28.5742 34.3987 28.0627 34.9054 27.3465C35.4122 26.6304 35.6149 25.6072 35.3108 24.5841C35.0068 23.3564 34.2973 22.3333 33.4865 21.4125C32.6757 20.4917 31.7635 19.5709 30.9527 18.8548C30.2432 18.2409 29.6351 17.6271 29.2297 17.1155C28.8243 16.6039 28.4189 15.4785 28.4189 15.4785C28.9257 15.0693 29 14 29 14C29 13 28 12 27 12C26 12 25 12.7723 25 14C25 14.6139 25.4797 15.2739 25.8851 15.5808C25.8851 15.5808 25.5811 16.7063 25.0743 17.2178C24.6689 17.7294 24.0608 18.2409 23.3514 18.9571C22.5405 19.7756 21.527 20.594 20.8176 21.5148C20.0068 22.4356 19.2973 23.561 18.9932 24.6865C18.6892 25.8119 18.8919 26.7327 19.3986 27.4488C19.9054 28.165 20.6149 28.6766 21.3243 29.0858C22.0338 29.495 22.7432 29.6997 23.3514 29.9043C23.4527 29.9043 21.0203 34.1931 21.0203 34.1931L20.8176 34.5C20.8176 34.5 20.9189 35.83 26.0878 35.83C26.0878 35.83 25.6824 37.2706 25.4797 37.3729C24.5676 37.8845 23.0473 38.2937 20.7162 37.3729C18.1824 36.4521 16.1554 36.5544 14.7365 36.9637C14.027 37.1683 13.5203 37.3729 13.1149 37.5775C12.7095 37.5775 12 38.0891 12 38.0891L13.2162 43C13.2162 43 14.6351 42.0792 15.8514 41.4653C16.8649 40.9538 17.9797 41.1584 18.9932 41.4653C24.8716 43.4092 26.6959 40.8515 26.8986 40.1353C27.2027 40.8515 30.0405 43.4092 34.8041 41.4653C35.8176 41.0561 37.0338 40.9538 37.9459 41.4653C39.1622 42.1815 40.5811 43 40.5811 43L41.6959 38.396L41.7973 38.0891C42 38.0891 41.7973 37.8845 41.7973 37.8845Z'
                stroke='#364367'
                stroke-opacity='0.7'
                stroke-width='1.24703'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <defs>
                <filter
                    id='filter0_i_5445_63122'
                    x='11.3765'
                    y='11.3765'
                    width='32.1348'
                    height='33.2471'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dx='1' dy='1' />
                    <feGaussianBlur stdDeviation='1.87055' />
                    <feComposite
                        in2='hardAlpha'
                        operator='arithmetic'
                        k2='-1'
                        k3='1'
                    />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='shape'
                        result='effect1_innerShadow_5445_63122'
                    />
                </filter>
                <linearGradient
                    id='paint0_linear_5445_63122'
                    x1='15'
                    y1='50.5'
                    x2='48.9003'
                    y2='16.287'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#8E4A06' />
                    <stop offset='0.392749' stop-color='#B1701F' />
                    <stop offset='0.58033' stop-color='#F8DD79' />
                    <stop offset='1' stop-color='#9F6013' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_5445_63122'
                    x1='16.3333'
                    y1='47.8889'
                    x2='46.467'
                    y2='17.4774'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#8E4A06' />
                    <stop offset='0.392749' stop-color='#B1701F' />
                    <stop offset='0.58033' stop-color='#F8DD79' />
                    <stop offset='1' stop-color='#9F6013' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_5445_63122'
                    x1='57'
                    y1='7'
                    x2='2.43556e-06'
                    y2='44.4194'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#FFF5A6' />
                    <stop offset='1' stop-color='#E5BD66' stop-opacity='0.54' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default function W3({ width, height }) {
    return (
        <SVGUniqueID>
            <WhiteDice width={width} height={height} />
            {/* <GoldDice width={width} height={width} /> */}
        </SVGUniqueID>
    );
}
