import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import { useEffect, useRef, useState, useMemo } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import _get from "lodash/get";

import { windowsSizeParams } from "src/consts/window-size-params";

import useCheckFullScreen from "src/hooks/useCheckFullScreen";
import useOrientation from "src/hooks/useOrientation";
import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";
import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import { usePlayer } from "src/sounds/use-player";
import {
    GameModeType,
    GameStatus,
    PlayerInfoExistPosition,
} from "src/store/models";

import DesktopGameChessboardWrapper from "src/pages/game-new2/layouts/layoutTypes/gameChessboardWrapper/desktopGameChessboard-wrapper";
import ViewDesktopChessboardWrapper from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/desktopChessboard-wrapper";

// const getPlayerWidthInMultipleTables = (chessBoardWidth: number): number => {
//     return chessBoardWidth + MathHelper.percentageOf(chessBoardWidth, 10);
// };

const DesktopLayout = (props) => {
    const { gameStore, authStore, lobbyStore, tournamentsStore } = useStores();
    const { showOpponentAcceptedDoublingDialog } = useDoublingStageStatus();
    const isVisible = authStore.isVisibleWebApp;
    const soundPlayer = usePlayer();
    const location = useLocation();

    // const appearanceTheme = useChangeTheme();

    // const viewMode = gameStore.isViewMode();
    const viewMode = gameStore.isViewMode();

    const piecesSize = gameStore.piecesSize;
    const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
    const isWon = gameStore.gameState.isWon;
    const gameStatus = gameStore.gameStatus;
    const gameType = gameStore.currentGame?.type;

    //Тут And Now Try To create depend wich contain some comditions^ if viewMode changed, get some funtions

    const [elemHeight, setHeight] = useState<number | undefined>();
    const [randomValue, setRandomValue] = useState<number>(1);

    //useEffect(() => {
    //    authStore.getChatStickerPacks();
    //}, []);

    //Logical for calculate Dice sizes on Multitable

    useEffect(() => {
        if (isVisible && !gameStore.rematch.gameId && gameStore.gameId) {
            gameStore.setGameId(gameStore.gameId);
        }
    }, [isVisible, gameStore.currentGame?.status]);

    useEffect(() => {
        isWon &&
            gameStatus === GameStatus.FINISHED &&
            !tournamentsStore?.tournamentWinnerAlert?.id &&
            soundPlayer("wonSoundPlay");
    }, [gameStatus]);

    useEffect(() => {
        if (doubleThinking || showOpponentAcceptedDoublingDialog) {
            let generateRandomNumber = (Math.random() * 10) | 0;
            if (generateRandomNumber && randomValue === generateRandomNumber) {
                setRandomValue(9 - generateRandomNumber);
            } else {
                setRandomValue(generateRandomNumber);
            }
        }
    }, [doubleThinking, showOpponentAcceptedDoublingDialog]);

    useEffect(() => {
        if (
            gameType &&
            gameType === GameModeType.TOURNAMENT &&
            lobbyStore.awaitingGames.length
        ) {
            lobbyStore.awaitingGames.map((g) => lobbyStore.removeGame(g));
        }
    }, []);

    // useEffect(() => {
    //     if (gameStore.gameState.hasSpecialActivity() || isShowWinOrLostDialog) {
    //         gameStore.dialogs.closeThinkingDialog();
    //     }
    // }, [gameStore.gameState.hasSpecialActivity(), isShowWinOrLostDialog]);

    const viewProps = {
        ...props,
        piecesSize,
        elemHeight,
    };

    return (
        <>
            {viewMode ? (
                <ViewDesktopChessboardWrapper {...viewProps} />
            ) : (
                <DesktopGameChessboardWrapper {...props} />
            )}
        </>
    );
};

export default observer(DesktopLayout);
