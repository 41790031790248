import { Grid } from "@mui/material";
import Badge from "@mui/material/Badge";
import cn from "classnames";
import { observer } from "mobx-react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Notifications from "src/components/Notifications";
import Avatar from "src/components/UserAvatar";
import UserNameBox from "src/components/UserNameBox";
import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import ArrowIcon from "src/assets/icons/selectIcon.svg";
import SettingsIcon from "src/assets/icons/gear.svg";

import endpoints from "../../../core/endpoints";
import {
    ModalProvider,
    ModalTypes,
} from "../../../pages/landing/utils/context";

import { useStyles } from "./styles";

type SplashProps = {
    nickname: string;
    avatar?: string;
    isSystemAvatar?: boolean;
    pathCompressed?: string;
};

const Splash = observer(({
    nickname,
    avatar,
    isSystemAvatar,
    pathCompressed
}: SplashProps) => {

    const styles = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { authStore, generalStore } = useStores();

    const isHalyk = generalStore.isHalyk;

    const prepareNickname =
        nickname &&
        nickname
            .toString()
            .split("")
            .map((a) => (a === "-" ? "-\u200d" : `${a}`))
            .join("");

    const [modalState, setModalState] = useState({
        notifications: false,
    });

    const userNotifications = authStore.currentUser?.notification?.numberOfUnseenNotifications;
    const newNotifications = userNotifications ? userNotifications : undefined;

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    return (
        <ModalProvider.Provider value={{ openModal, closeModal }}>
            {modalState.notifications && <Notifications />}
            <div className={styles.avatarNicknameWrapper}>
                {!isHalyk && (
                    <div className={styles.avatarWrapper}>
                        <Badge
                            color="error"
                            badgeContent={newNotifications}
                            className={cn(styles.badgeNotifications, {
                                emptyNote:
                                    !newNotifications || newNotifications < 1,
                            })}
                        >
                            <Avatar
                                avatarUrl={`${endpoints.avatarPath}${authStore.currentUser?.avatar}`} // Передаем обновленный URL аватара
                                className={styles.avatar}
                                isSystemAvatar={isSystemAvatar}
                                pathCompressed={pathCompressed}
                            />
                        </Badge>
                    </div>
                )}
                <span className={styles.userNameBox}>{prepareNickname}</span>
            </div>
        </ModalProvider.Provider>
    );
});

export default (Splash);
