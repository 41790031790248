import React from 'react';
import cn from 'classnames';

import { useStyles } from './styles';
import { ISpeed, InfoMessage } from './types';
import FadeInOutVertical from 'src/components/Animated';

function SpeedWarningComponent({ wifiSpeedText }: ISpeed) {
    const styles = useStyles({ wifiSpeedText });

    return (
        <FadeInOutVertical>
            <div
                className={cn(styles.speedWarningModal, {
                    connected: wifiSpeedText === InfoMessage.connected,
                    lost: wifiSpeedText === InfoMessage.lost,
                    slow: wifiSpeedText === InfoMessage.slow,
                })}
            >
                <p style={{ margin: 0, marginTop: 10, marginBottom: 10 }}>
                    {wifiSpeedText}
                </p>
            </div>
        </FadeInOutVertical>
    );
}

export default SpeedWarningComponent;
