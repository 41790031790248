export class FileHelper {
    baseRoot: string;
    constructor(baseRoot: string) {
        this.baseRoot = baseRoot;
    }
    public getFileURL(pathToFile: string) {
        return `${this.baseRoot}${pathToFile}`;
    }
    public getAvatarURL(filename: string) {
        return this.getFileURL(`/avatars/${filename}`);
    }
    public getMediaFileURL(filename: string) {
        return this.getFileURL(`/media/${filename}`);
    }
}
