import React, { useState } from 'react';
import { useLocale } from 'src/providers/LocaleProvider';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { Box } from '@mui/material';
import RankIcon from 'src/pages/lobby/components/LobbyTable/TableRow/components/RankIcon';

import CurrentRank from 'src/pages/profile/components/CurrentRank';

import useStores from 'src/hooks/useStores';

import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

type IModalUserRangBox = {
    rankCountTitle: string | undefined;
    rankPieceTitle: string | undefined;
    rank: string | undefined;
    playerRating: number;
    placePosition?: string
    label?: string | undefined;
    pathCompressed?: string | undefined;
    myAvatar?: string | undefined;
    myNickname?: string | undefined;
    myIsSystemAvatar?: boolean | undefined;
    nickname?: string | undefined;
    avatar?: string | undefined;
    isSystemAvatar?: boolean | undefined;
};

const ModalUserRangBox = ({
    label,
    rankPieceTitle,
    rank,
    playerRating,
    rankCountTitle,
    placePosition
}: IModalUserRangBox) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();

    if (!rank) return null;

    return (
      <Box className={cn(styles.modalRangWrapper, appearanceTheme, label, placePosition)}>
        <div
          className={cn(
            styles.modalRankParagraph,
            appearanceTheme,
            'rank',
            placePosition
          )}>
          {rankPieceTitle}:&nbsp;
          <CurrentRank rank={rank} label={label} placePosition={placePosition}/>
        </div>
        <div
          className={cn(
            styles.modalRankParagraph,
            appearanceTheme,
            'rankCount',
            placePosition
          )}>
        {rankCountTitle}:&nbsp;
        <span className={cn(styles.statsItemSpan, 'RP', placePosition)}>
          {playerRating || 'no rating'}
        </span>
      </div>
      </Box>
    );
};

export default observer(ModalUserRangBox);


{/* <Box className={cn(styles.modalRangWrapper, appearanceTheme, label, placePosition)}>
<Box className={cn(styles.modalRangBox, appearanceTheme, label, placePosition)}>
    <p className={cn(styles.modalRangeTitle, label, 'title', placePosition)}>
        {playerRating}
    </p>
    <p className={cn(styles.modalRangeTitle, label, 'paragraph', placePosition)}>
        {rankCountTitle}
    </p>
</Box>
<CurrentRank rank={rank} label={label} placePosition={placePosition}/>
<Box className={cn(styles.modalRangBox, appearanceTheme, label, placePosition)}>
    <p className={cn(styles.modalRangeTitle, label, 'title', placePosition)}>
        {rankCashBattle}
    </p>
    <p className={cn(styles.modalRangeTitle, label, 'paragraph', placePosition)}>
        {rightTitle}
    </p>
</Box>
</Box> */}