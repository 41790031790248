export class ObjectHelper {
    public static getObjectOrDefault<T, D = object>(
        input: T,
        defaultObject?: D
    ) {
        if (typeof input === "object" && input !== null) {
            return input;
        } else {
            return defaultObject ?? {};
        }
    }

    public static merge<T, D>(object1: T, object2: D): T & D {
        return {
            ...object1,
            ...object2,
        };
    }
}
