import GameStore from "src/store/gameStore";

import { RootStore } from "../store";

import { useGameStore } from "./useGameStore";

export const rootStore = new RootStore();

interface IStore extends RootStore {
    gameStore: GameStore;
}

const useStores = (): IStore => {
    const { gameStore } = useGameStore();

    return {
        ...rootStore,
        gameStore,
    };
};
export default useStores;
