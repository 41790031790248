import axios from 'axios';

import endpoints from '../../../core/endpoints';

import {
    IGetBusinessMsgRequest,
    IGetBusinessMsgResponse,
    IGetProfileForReferralsDataRequest,
    IGetProfileForReferralsDataResponse,
    IGetProfileForReferralsUsersRequest,
    IGetProfileForReferralsUsersResponse,
    IGetReferralWithdrawResponse,
} from './requestResponses';

export const getBusinessMsg = async (params: IGetBusinessMsgRequest) => {
    return await axios.post<IGetBusinessMsgResponse>(
        endpoints.businessMessage,
        params
    );
};

export const getProfileForReferralsData = async (
    params: IGetProfileForReferralsDataRequest
) => {
    return await axios.get<IGetProfileForReferralsDataResponse>(
        endpoints.getReferralStatistic,
        { params }
    );
};

export const getProfileForReferralsUsers = async (
    params: IGetProfileForReferralsUsersRequest
) => {
    return await axios.get<IGetProfileForReferralsUsersResponse>(
        endpoints.getReferralUsers,
        { params }
    );
};

export const getReferralWithdraw = async () => {
    return await axios.post<IGetReferralWithdrawResponse>(
        endpoints.getReferralWithdraw
    );
};
