import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

//Тура
const WhiteDice = ({ width, height }) => {
    return (
        <svg
            id='b-06_svg__Layer_1'
            x={0}
            y={0}
            viewBox='0 0 70 70'
            xmlSpace='preserve'
            width={width}
            height={height}
        >
            <style>{'.b-06_svg__st9{fill:#d4d1b4}'}</style>
            <linearGradient
                id='b-06_svg__SVGID_1_'
                gradientUnits='userSpaceOnUse'
                x1={35}
                y1={69.5}
                x2={35}
                y2={0.5}
            >
                <stop offset={0} stopColor='#bcc5c7' />
                <stop offset={0.65} stopColor='#e6e8e9' />
            </linearGradient>
            <path
                d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
                fill='url(#b-06_svg__SVGID_1_)'
            />
            <path
                d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
                fill='#fff'
            />
            <g>
                <linearGradient
                    id='b-06_svg__SVGID_2_'
                    gradientUnits='userSpaceOnUse'
                    x1={16.439}
                    y1={37.751}
                    x2={53.561}
                    y2={37.751}
                >
                    <stop offset={0} stopColor='#3a3a3f' />
                    <stop offset={1} stopColor='#040408' />
                </linearGradient>
                <path
                    fill='url(#b-06_svg__SVGID_2_)'
                    d='M49.5 53.3v-3.4l-4.7-4V29.7l5.4-3.4V16.2h-7.4v4h-4.1v-4h-7.4v4h-4.1v-4h-7.4v10.1l5.4 3.4v16.2l-4.7 4v3.4h-4.1v6h37.2v-6z'
                />
                <linearGradient
                    id='b-06_svg__SVGID_3_'
                    gradientUnits='userSpaceOnUse'
                    x1={35}
                    y1={60.849}
                    x2={35}
                    y2={14.653}
                >
                    <stop offset={0.001} stopColor='#fff' />
                    <stop offset={1} stopColor='#a4b0b2' />
                </linearGradient>
                <path
                    d='M50.2 16.2v10.1l-5.4 3.4v16.2l4.7 4v3.4h4v6.1H16.4v-6.1h4v-3.4l4.7-4V29.7l-5.4-3.4V16.2h7.4v4h4v-4h7.4v4h4v-4h7.7m1.5-1.5H41.3v4h-1v-4H29.9v4h-1v-4H18.5v12.4l.7.4 4.7 2.9v14.7l-4.2 3.6-.7.5V51.8h-4v9.1h40.1v-9.1H51V49.2l-.5-.4-4.2-3.6V30.5l4.7-2.9.7-.4V14.7z'
                    fill='url(#b-06_svg__SVGID_3_)'
                />
                <path
                    className='b-06_svg__st9'
                    d='M22.5 51.2h25v2h-25zM27.2 29h15.5v2H27.2zM21.8 23.6h26.3v2H21.8zM24.5 48.6h21l-2.7-2.1H27.2z'
                />
            </g>
        </svg>
    );
};

const GoldDice = ({ width, height }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 54 54'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                width='54'
                height='54'
                rx='7.03677'
                fill='url(#paint0_linear_5445_63094)'
            />
            <circle
                cx='27'
                cy='27'
                r='27'
                fill='url(#paint1_linear_5445_63094)'
                fill-opacity='0.3'
            />
            <g filter='url(#filter0_i_5445_63094)'>
                <path
                    d='M37 39.001V38.1213C37 36.763 36.4604 35.4604 35.5 34.5C34.5396 33.5395 34 32.2369 34 30.8786V25.2309C34 23.8656 34.7754 22.6187 36 22.0149C37.2246 21.411 38 20.1642 38 18.7988V17C38 15.3431 36.6569 14 35 14H33.4062C32.6296 14 32 14.6296 32 15.4062V15.8125C32 16.3648 31.5523 16.8125 31 16.8125C30.4477 16.8125 30 16.3648 30 15.8125V15.4062C30 14.6296 29.3704 14 28.5938 14H25.4066C24.6298 14 24 14.6297 24 15.4066V15.813C24 16.3651 23.5526 16.8127 23.0005 16.813C22.448 16.8133 22 16.3655 22 15.813V15.4069C22 14.6304 21.3706 14.001 20.5941 14.001H19C17.3431 14.001 16 15.3442 16 17.001V18.7988C16 20.1642 16.7754 21.4111 18 22.0149C19.2246 22.6187 20 23.8656 20 25.2309V30.8797C20 32.2379 19.4604 33.5405 18.5 34.501L17.6213 35.3797C17.2235 35.7775 17 36.317 17 36.8797V37.501C17 38.3294 16.3284 39.001 15.5 39.001C14.6716 39.001 14 39.6726 14 40.501V41.501C14 42.8817 15.1193 44.001 16.5 44.001H35C37.7614 44.001 40 41.7624 40 39.001L38.191 39.0016L37 39.001Z'
                    fill='#272626'
                />
            </g>
            <defs>
                <filter
                    id='filter0_i_5445_63094'
                    x='14'
                    y='14'
                    width='29.0457'
                    height='33.0467'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dx='3.04568' dy='3.04568' />
                    <feGaussianBlur stdDeviation='2.28426' />
                    <feComposite
                        in2='hardAlpha'
                        operator='arithmetic'
                        k2='-1'
                        k3='1'
                    />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='shape'
                        result='effect1_innerShadow_5445_63094'
                    />
                </filter>
                <linearGradient
                    id='paint0_linear_5445_63094'
                    x1='15'
                    y1='50.5'
                    x2='48.9003'
                    y2='16.287'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#8E4A06' />
                    <stop offset='0.484375' stop-color='#DFA863' />
                    <stop offset='1' stop-color='#9F6013' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_5445_63094'
                    x1='57'
                    y1='7'
                    x2='2.43556e-06'
                    y2='44.4194'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#FFF5A6' />
                    <stop offset='1' stop-color='#E5BD66' stop-opacity='0.54' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default function B6({ width, height }) {
    return (
        <SVGUniqueID>
            <WhiteDice width={width} height={height} />
            {/* <GoldDice width={width} height={width} /> */}
        </SVGUniqueID>
    );
}
