export enum TournamentStatus {
    REGISTERING = 'registering',
    FINISHED = 'finished',
    COMPLETED = 'completed',
    RUNNING = 'running',
}

export interface ITournamentRequest {
    id: string;
}

export enum TournamentTxType {
    nonBonus,
    referralBonus,
    split,
}

export enum TournamentAlertType {
    tournament,
    jackpot,
}

export interface ITournamentJoinRequest extends ITournamentRequest {
    type: TournamentTxType;
    alertType?: TournamentAlertType | undefined;
    password?: string | undefined;
}

export interface IResponseTopPlayers {
    skip: number;
    limit: number;
}

export interface ITournamentCreateRequest {
    title: string;
    description: string;
    type: 'regular' | 'jackpot';
    maxPlayers: number;
    numberOfPlayersToJoin: number;
    buyIn: number;
    balanceType: 'play' | 'referralBonus' | 'coins';
}

export interface ITopPlayers {
    id: string;
    avatar: string;
    isSystemAvatar: boolean;
    nickname: string;
    players?: number | undefined;
    score: number;
    _id?: string | undefined;
}
