import React, { memo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import TabPanel from "@mui/lab/TabPanel";

import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import {
    isGameMoveMessage,
    isGameNotification,
    isGameMoveMessageNew,
    isGameNotificationNew,
} from "src/store/gameStore/types";
import { EGameChatTab, IStickerRequest, ColorVariant } from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";
import { ICommunicationHistoryNotification } from "src/store/gameStore/types";

import BlockMessages from "../blockMessages";
import { useStyles } from "../styles";
import { GameChatHistoryMessage } from "../message";
import { IChatHistoryMessages } from "../types/IChat";
import { GameChatHistoryDiceMessage } from "../dice-message";

const HistoryChatPanel = observer(
    ({ currentTab, showHideOpponent, moveMessages }: IChatHistoryMessages) => {
        const appearanceTheme = useChangeTheme();
        const { extractCompiled } = useLocale();

        const getTextParser = {
            isLabel: "label",
            isNumber: "number",
            isObject: "object",
            isString: "string",
            isEmptyMessage: "",
        };

        const { isLabel, isObject, isEmptyMessage } = getTextParser;

        const { gameStore, authStore, tournamentsStore } = useStores();
        const shouldHide = tournamentsStore.isHideChat;
        const chessboardSize = gameStore?.chessboardSize;
        const stickers = authStore.stickers;

        const classes = useStyles({
            shouldHide,
            showHideOpponent,
            appearanceTheme,
            chessboardSize,
            stickers,
        });

        const getTextMessage = (message) => {
            if (typeof message === isLabel && message.id && message.pack) {
                return;
            }
            return typeof message === isObject
                ? getTextParser.isLabel in message
                    ? (extractCompiled(
                          message.label,
                          message.props || {},
                          message.defaultValue || isEmptyMessage
                      ) as JSX.Element)
                    : (JSON.stringify(message) as string)
                : (message as JSX.Element);
        };

        return (
            <TabPanel
                value={EGameChatTab.history}
                key={EGameChatTab.history}
                style={{
                    height: "100%",
                    padding: 0,
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    borderRadius: "12px",
                    margin: "0px 0px",
                    width: "auto",
                }}
            >
                <div className={cn(classes.messagesBox, appearanceTheme)}>
                    <div
                        className={cn(
                            classes.messagesBoxContainer,
                            EGameChatTab.history,
                            appearanceTheme
                        )}
                    >
                        {moveMessages &&
                            [...moveMessages].reverse().map((item, key) => {
                                return (
                                    <GameChatHistoryDiceMessage
                                        key={`${item.time}_${key}_${item.color}`}
                                        time={item.time}
                                        position={item.position}
                                        figures={item.figures}
                                        pieces={item.pieces}
                                        color={item.color || ColorVariant.white}
                                        animated={!shouldHide}
                                        isLastElem={key === 0}
                                    />
                                );
                            })}
                        {/* {moveMessages &&
                            [...moveMessages].reverse().map((item, key) => {
                                let el: JSX.Element | null = null;
                                if (
                                    isGameMoveMessageNew(
                                        item as ICommunicationHistoryNotification
                                    )
                                ) {
                                    return (
                                        <GameChatHistoryDiceMessage
                                            key={`${item.time}_${key}_${item.color}`}
                                            time={item.time}
                                            position={item.position}
                                            pieces={item.pieces}
                                            color={
                                                item.color || ColorVariant.white
                                            }
                                            animated={!shouldHide}
                                        />
                                    );
                                }
                                if (
                                    isGameNotificationNew(
                                        item as ICommunicationHistoryNotification
                                    )
                                ) {
                                    el = (
                                        <GameChatHistoryMessage
                                            key={`${item?.time}_${key}`}
                                            time={item?.time}
                                            position={item?.position}
                                        >
                                            {getTextMessage(item?.message)}
                                        </GameChatHistoryMessage>
                                    );
                                }
                                return <div key={key}>{el}</div>;
                            })} */}
                    </div>
                </div>
            </TabPanel>
        );
    }
);

export default memo(HistoryChatPanel);
