import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Box} from "@mui/material";
import cn from "classnames";
import {useHistory, useLocation} from "react-router-dom";

import useCalcTableLabel from "src/hooks/useCalcTableLabel";
import {useLocale} from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import {ColorVariant, IPlayer, GameModeType} from "src/store/models";
import {MAX_CHANGE_SCREEN_WIDTH} from "src/pages/game-new2/constants";
import {ChatExistPosition} from "src/store/models";
import {useStyles} from "./styles";
import {BPIcon, WPIcon} from "../fastChessboard/piece-memos-deepBlack";

interface IGameScore {
    stage?: number;
    title?: string;
    winner?: string;
    isView?: boolean;
    elementPosition?: ChatExistPosition;
}

const GameScoreRedesign = ({
                               stage,
                               title,
                               winner,
                               elementPosition,
                               isView = false,
                           }: IGameScore) => {
    const {
        tournaments: {
            titles: {
                detailsTab: {upTo},
            },
        },
        game: {pot, match},
    } = useLocale();
    const history = useHistory();
    const {gameStore} = useStores();

    const viewMode = gameStore?.isViewMode();
    const location = useLocation();

    const myColor = viewMode
        ? ColorVariant.white
        : gameStore?.gameState.getMyColor() ?? ColorVariant.white;

    const firstPlayer = viewMode
        ? gameStore?.gameState.getPlayerByColor(ColorVariant.white)
        : gameStore?.gameState.getPlayerByColor(myColor);

    const oponentColor = viewMode
        ? ColorVariant.black
        : gameStore?.gameState.getOpponentColor() ?? ColorVariant.black;
    const secondPlayer = viewMode
        ? gameStore?.gameState.getPlayerByColor(ColorVariant.black)
        : gameStore?.gameState.getPlayerByColor(oponentColor);

    const rounds = gameStore?.currentGameNew?.matchCount;
    const amount = gameStore?.currentGameNew?.bank;

    const [match1, setMatch1] = useState(0);
    const [match2, setMatch2] = useState(0);
    const [oldMatch1, setOldMatch1] = useState(0);
    const [oldMatch2, setOldMatch2] = useState(0);

    useEffect(() => {
        let _match1 = (gameStore?.currentGameNew?.matchId || 1) - 1;
        let _match2 = (gameStore?.currentGameNew?.matchId2 || 1) - 1;

        const meIsBlack = !isView &&
            gameStore?.currentGameNew?.players[0]._id !==
            gameStore.rootStore.authStore.currentUser?._id;

        if (meIsBlack) {
            const tmp = _match1;
            _match1 = _match2;
            _match2 = tmp;
        }

        setMatch1(_match1);
        setMatch2(_match2);

        const tm = setTimeout(() => {
            clearTimeout(tm);
            setOldMatch1(_match1);
            setOldMatch2(_match2);
        }, 10000);
    }, [
        gameStore?.currentGameNew?.matchId,
        gameStore?.currentGameNew?.matchId2,
    ]);

    const [firstPlayerState, setFirstScore] = useState<IPlayer | any>(null);
    const [secondPlayerState, setSecondScore] = useState<IPlayer | any>(null);
    const [clicker, setClick] = useState<boolean | string>(false);
    const delayForAnumationStart = 1000;

    const width = window.innerWidth;
    const styles = useStyles({
        clicker,
        myColor,
        oponentColor,
    });
    const stageLabel = useCalcTableLabel(stage);

    useEffect(() => {
        setClick(false);
        if (!winner || !firstPlayerState || !secondPlayerState) return;
        if (winner === myColor) {
            setClick("me");
            setTimeout(() => {
                setFirstScore({
                    ...firstPlayerState,
                    score: firstPlayerState.score + 1,
                });
            }, delayForAnumationStart);
            return;
        }
        if (winner === oponentColor) {
            setClick("oponent");
            setTimeout(() => {
                setSecondScore({
                    ...secondPlayerState,
                    score: secondPlayerState.score + 1,
                });
            }, delayForAnumationStart);
            return;
        }
    }, [winner]);

    useEffect(() => {
        if (firstPlayer && !firstPlayerState) {
            setFirstScore(firstPlayer);
        }
        if (secondPlayer && !secondPlayerState) {
            setSecondScore(secondPlayer);
        }
    }, [firstPlayer, secondPlayer]);

    useEffect(() => {
        if (location.pathname) {
            if (firstPlayer) {
                setFirstScore(firstPlayer);
            }
            if (secondPlayer) {
                setSecondScore(secondPlayer);
            }
        }
    }, [location.pathname]);

    return (
        <>
            <Box
                component="div"
                className={cn(styles.multiRoundGameWrapper, {
                    isRegualarOrMatch:
                        gameStore?.currentGame?.type === GameModeType.REGULAR,
                    isTournamentGame:
                        gameStore?.currentGame?.type ===
                        GameModeType.TOURNAMENT,
                    inProgressPreview:
                        elementPosition === ChatExistPosition.inProgressPreview,
                })}
            >
                {/* TODO: replace !title with isMultiRoundGame props */}
                {!title && (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {match("Match")} {rounds} wins
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                )}{" "}
                {!title && (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            <span
                                className={cn(styles.match, {
                                    [styles.matchAnimate]: match1 !== oldMatch1,
                                })}
                            >
                               <div style={ { width: 20, height: 20} }> {isView && <WPIcon
                                    width={12}
                                    mobileHover={false}
                                    isMultiTable={false}
                               />} </div>
                                {match1}
                            </span>-<span
                                className={cn(styles.match, {
                                    [styles.matchAnimate]: match2 !== oldMatch2,
                                })}
                            >
                                {match2}
                            <div style={ { width: 20, height: 20} }>{isView && <BPIcon
                                width={12}
                                mobileHover={false}
                                isMultiTable={false}
                            />}</div>
                            </span>
                            <br/>
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                )}
                {width <= MAX_CHANGE_SCREEN_WIDTH ? (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {title
                                ? `${title}, ${stageLabel}`
                                : `${pot("POT")}: $${amount}`}
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                ) : (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                "tournamentDetailsTitle",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {title ?? `${pot("POT")}: $${amount}`}
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                "tournamentDetailsRound",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {stageLabel}
                        </p>
                    </Box>
                )}
                <Box component="div" className={styles.matchRoundBox}>
                    {firstPlayerState && secondPlayerState && (
                        <>
                            <span
                                className={cn(styles.gameScoreTitlesFirst, {
                                    me: clicker === "me",
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitlesName,
                                        "score"
                                    )}
                                >
                                    {firstPlayerState?.score}
                                </p>
                            </span>
                            <span
                                className={cn(styles.gameScoreTitles, "colon", {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                })}
                            >
                                :
                            </span>
                            <span
                                className={cn(styles.gameScoreTitlesSecond, {
                                    oponent: clicker === "oponent",
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitlesName,
                                        "score"
                                    )}
                                >
                                    {secondPlayerState?.score}
                                </p>
                            </span>
                            {rounds && rounds > 1 && (
                                <span
                                    className={cn(
                                        styles.gameScoreTitles,
                                        "upTo",
                                        {
                                            inProgressPreview:
                                                elementPosition ===
                                                ChatExistPosition.inProgressPreview,
                                        }
                                    )}
                                >
                                    {`(${upTo(
                                        "Up to"
                                    ).toLowerCase()} ${rounds})`}
                                </span>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default observer(GameScoreRedesign);
