import React, {
    memo,
    useEffect,
    useState,
    useMemo,
    useLayoutEffect,
} from 'react';
import moment from 'moment';
import cn from 'classnames';
import {useLocation} from 'react-router-dom';
import {textAlign} from '@mui/system';

import {ColorVariant} from 'src/store/models';
import Countdown, {zeroPad} from 'react-countdown';
import {usePlayer} from 'src/sounds/use-player';
import shortColor from 'src/utils/shortColor';
import useChangeTheme from 'src/hooks/useChangeTheme';
import {usePreciesTimer} from 'src/hooks/usePreciseTimer';

import {useStyles} from './styles';
import useStores from '../../../../hooks/useStores';

type RedesignClockProps = {
    warningLimit: number;
    active: boolean;
    activeClock?: {color: ColorVariant};
    initTime: number;
    noTimerSound: boolean;
    color: ColorVariant;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    gameEnd?: boolean;
    isViewMode?: boolean;
};

export const RedesignClock = memo(
    ({
        warningLimit,
        initTime,
        activeClock,
        noTimerSound,
        color,
        isMultiTable,
        isTwoGameTable,
        gameEnd,
        isViewMode,
        ...props
    }: RedesignClockProps) => {
        // const [gameTime, setGameTime] = useState(0);
        const currentWidth = window.innerWidth;
        const appearanceTheme = useChangeTheme();

        const {gameStore} = useStores();
        const matchId = gameStore.currentGameNew?.matchId;
        const matchId2 = gameStore.currentGameNew?.matchId2;

        // useEffect(() => {
        //     if (gameTime !== initTime) {
        //         setGameTime(initTime);
        //     }
        // }, [initTime]);

        // const seconds = gameTime / 1000;

        // useEffect(() => {
        //     if (gameTime && gameTime / 1000 < 20) {
        //         if (!noTimerSound) soundPlayer('timeoutSoundPlay');
        //     }
        // }, [gameTime]);

        // console.log("activeClock", activeClock);

        // useEffect(() => {
        //     let timer;
        //     if (
        //         activeClock &&
        //         activeClock.color === shortColor(color) &&
        //         !gameEnd
        //     ) {
        //         timer = setInterval(() => {
        //             setGameTime((gameTime) =>
        //                 gameTime - 1000 <= 0 ? 0 : gameTime - 1000
        //             );
        //         }, 1000);

        //         return () => {
        //             clearInterval(timer);
        //         };
        //     }
        //     if (gameEnd) {
        //         const timerEnd = setTimeout(() => {
        //             setGameTime((gameTime) =>
        //                 gameTime - 1000 <= 0 ? 0 : gameTime - 1000
        //             );
        //         }, 1000);

        //         return () => {
        //             clearTimeout(timerEnd);
        //         };
        //     }
        // }, [
        //     activeClock,
        //     gameEnd,
        //     gameStore?.currentGameNew?.matchId,
        //     gameStore?.currentGameNew?.matchId2,
        // ]);

        // New Logical
        const updateTime = 1000;
        const msToSec = 1000;
        const activeMove =
            activeClock && activeClock.color === shortColor(color) && !gameEnd;

        const [time, setTime] = useState<number | null>(null);
        const [startMatch, setMatch] = useState<number | null>(null);
        const [startTime, setInitTime] = useState<number | null>(null);

        const preciesTimer = usePreciesTimer(
            updateTime,
            !!activeMove && !!props.active
        );

        useLayoutEffect(() => {
            // Put Updates for count of game
            if (matchId && matchId2) {
                setMatch(matchId + matchId2);
            }
        }, [matchId, matchId2]);

        useLayoutEffect(() => {
            //Logical for reset for rematch Games
            if (
                startMatch &&
                matchId &&
                matchId2 &&
                matchId + matchId2 !== startMatch
            ) {
                setTime(null);
                setInitTime(null);
            }
        }, [matchId, matchId2]);

        useLayoutEffect(() => {
            if (activeMove) {
                if (!time && initTime) {
                    setTime(initTime);
                }
                if (!startTime || startTime === 0) {
                    setInitTime(Date.now());
                }
            } else {
                setTime(initTime);
                setInitTime(0);
            }
        }, [startTime, time, initTime, activeMove]);

        const countdown = (
            preciesTimer: number,
            activeMove: boolean | undefined,
            time: number | null,
            startTime: number | null
        ) => {
            if (activeMove) {
                if (time && startTime && preciesTimer >= startTime) {
                    return Math.round(
                        (time - (preciesTimer - startTime)) / msToSec
                    );
                } else {
                    return time ? Math.round(time / msToSec) : 0;
                }
            } else {
                return time ? Math.round(time / msToSec) : 0;
            }
        };

        const classes = useStyles({
            warning:
                warningLimit >=
                countdown(preciesTimer, activeMove, time, startTime),
            appearanceTheme,
            gameEnd,
            ...props,
        });

        const desktopStyleProps = {
            // TODO: maybe we should set this values in useStyles?
            paddingLeft: !isMultiTable
                ? currentWidth > 1250
                    ? '0%'
                    : '0px'
                : undefined,
        };

        const timeText = moment()
            .startOf('day')
            .seconds(countdown(preciesTimer, activeMove, time, startTime))
            .format('mm:ss');

        return (
            <div
                style={{...desktopStyleProps}}
                className={cn(
                    classes.text,
                    'redesign-clock-text',
                    appearanceTheme,
                    {
                        isMultiTable,
                        isTwoGameTable,
                    }
                )}
            >
                <div
                    className={cn(classes.slicedTimerBox, appearanceTheme, {
                        isMultiTable,
                    })}
                >
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            'minutes',
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(0, 2)}
                    </span>
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            'colon',
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(2, 3)}
                    </span>
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            'seconds',
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(3)}
                    </span>
                </div>
            </div>
        );
    }
);
