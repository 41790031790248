import React, { ReactNode } from 'react';

import { useStyles } from './styles';

interface ITitleWithChildren {
    children?: ReactNode;
}

interface ITitleWithInnerHTML {
    innerHTML: string;
}

type ITitleProps = ITitleWithChildren | ITitleWithInnerHTML;

export const Title = (props: ITitleProps) => {
    const styles = useStyles();

    if ('children' in props) {
        return <span className={styles.title}>{props.children}</span>;
    } else if ('innerHTML' in props) {
        return (
            <span
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: props.innerHTML }}
            />
        );
    }

    return null;
};
