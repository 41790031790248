import React from 'react';
import { observer } from 'mobx-react';
import { useLocale } from 'src/providers/LocaleProvider';
import { Box } from '@mui/material';
import cn from 'classnames';
import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

import watchingUsers from 'src/assets/icons/watching-users.svg';
import {  IWatchingList } from '../types/watchingTypes';

function WatchingToggler({
    setOpen,
    openStatus,
    watchingArray,
    dryStyle,
    height,
    isMobileGamePage,
    togglerClassName,
}: IWatchingList): JSX.Element {
    const classes = useStyles({ height });
    const {
        friends: { watching },
    } = useLocale();
    const appearanceTheme = useChangeTheme();

    return (
        <Box
            style={{ margin: isMobileGamePage ? 0 : '0 auto' }}
            className={cn(appearanceTheme, togglerClassName, {
                [classes.watchingWrapperDry]: dryStyle,
                [classes.watchingWrapper]: !dryStyle,
            })}
            onClick={() => setOpen(openStatus)}
        >
            <img src={watchingUsers} className={classes.watchingUsersIcon} />
            &nbsp;
            <p className={classes.watchingUsersText}>{watching('Watching:')}</p>
            &nbsp;
            <p className={classes.watchingUsersCount}>{watchingArray.length}</p>
        </Box>
    );
}

export default observer(WatchingToggler);
