import { useEffect, useMemo, memo } from "react";
import { observer } from "mobx-react";
import { useHistory, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import { GameModeType } from "src/store/models";
import { MAX_XL_TABLET } from "../../constants";

import useStores from "src/hooks/useStores";
import { useStyles } from "./styles";

import JoinToGameFormNew from "src/components/wrapper/components/gameEventsDialogWrapperNew/joinToGameFormNew";
import ConfirmGameFormNew from "src/components/wrapper/components/gameEventsDialogWrapperNew/confirmGameFormNew";

const GameEventDialogsOnDesk = observer(({ placePosition }: any) => {
    const { lobbyStore, authStore } = useStores();
    const styles = useStyles();
    const isGameScreen = useRouteMatch("/game/:id");
    const isDesktopScreen = window.innerWidth > MAX_XL_TABLET;

    useEffect(() => {
        //Если не экран игры или не Десктоп
        if (!isGameScreen || !isDesktopScreen) return;
        const players = lobbyStore?.acceptedGame?.players;
        const me = authStore.currentUser?._id;
        const isFriendInvite = lobbyStore.getChallengedGames(me);
        const JOINED_USER = 1;
        if (!me) return;
        if (Array.isArray(players) && isFriendInvite.length > 0) {
            const playersArr: string[] = [];
            players.map((elem) => playersArr.push(elem?.name));
            const isMeInAcceptgame = playersArr.indexOf(me);
            return isMeInAcceptgame === JOINED_USER
                ? lobbyStore.setJoinedGame(lobbyStore.acceptedGame)
                : lobbyStore.setJoinedGame(null);
        }
    }, [lobbyStore.acceptedGame]);

    useEffect(() => {
        //Если не экран игры или не Десктоп
        if (!isGameScreen || !isDesktopScreen) return;
        const me = authStore.currentUser?._id;
        if (!me) return;
        const isFriendInvite = lobbyStore.getChallengedGames(me);
        if (
            isFriendInvite &&
            Array.isArray(isFriendInvite) &&
            isFriendInvite.length > 0
        ) {
            lobbyStore.setAcceptedGame(isFriendInvite[0]);
        }
    }, [lobbyStore.allMyGames]);

    if (
        isGameScreen &&
        isDesktopScreen &&
        lobbyStore.joinedGame &&
        lobbyStore.joinedGame.type === GameModeType.REGULAR
    ) {
        return (
            <div className={cn(styles.eventChatContainderWrapper)}>
                <JoinToGameFormNew placePosition={placePosition} />
            </div>
        );
    } else if (
        isGameScreen &&
        isDesktopScreen &&
        lobbyStore.acceptedGame &&
        lobbyStore.acceptedGame.type === GameModeType.REGULAR
    ) {
        return (
            <div className={cn(styles.eventChatContainderWrapper)}>
                <ConfirmGameFormNew placePosition={placePosition} />
            </div>
        );
    }
    {
        return null;
    }
});

export default memo(GameEventDialogsOnDesk);
