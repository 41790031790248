import React, { useEffect, useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import _get from "lodash/get";
import { Dialog } from "@mui/material";

import useChangeTheme from "src/hooks/useChangeTheme";
import { useLocale } from "src/providers/LocaleProvider";
import { emitter } from "src/shared/emitter";
import BetConfig from "src/pages/lobby/components/LobbyTable/HeaderBlock/CreateGameBlock/components/betConfig";
import TimeConfig from "src/pages/lobby/components/LobbyTable/HeaderBlock/CreateGameBlock/components/timeConfig";
import useStores from "src/hooks/useStores";
import { usePlayer } from "src/sounds/use-player";
import ModalTitles from "src/components/modals/ModalGameComponents/ModalTitles/ModalTitles";
import ModalDivider from "src/components/modals/ModalGameComponents/ModalDivider/ModalTitles";
import ModalButtonsBox from "src/components/modals/ModalGameComponents/ModalButtonsBox/ModalButtonsBox";
import { EModalsLabels } from "src/store/models";
import { MAX_NUMBER_OF_GAMES } from "src/consts/lobby-params";
import { errorService } from "src/service/services";

import { RoundsConfig } from "../RoundsConfig";

import { useStyles } from "./styles";

const CreateGameModal = () => {
    const [open, setOpen] = useState(false);
    const { authStore, lobbyStore } = useStores();
    const appearanceTheme = useChangeTheme();
    const {
        common: { newGame },
        friends: {
            requests: { cancelFriendRequest },
            inviteFriendToPlay: { playButton },
        },
    } = useLocale();
    const locales = useLocale();
    const classes = useStyles();
    const player = usePlayer();
    const [selectedRounds, setSelectedRounds] = useState(2);

    useEffect(() => {
        emitter.on("createGame:open", () => {
            setOpen(true);
        });
        emitter.on("createGame:close", () => {
            setOpen(false);
        });
    }, []);

    const onClose = () => {
        emitter.emit("createGame:close");
    };

    const handleCreateGame = async () => {
        try {
            await lobbyStore.createGame({ rounds: selectedRounds });
            player("gameCreateSoundPlay");
            emitter.emit("createGame:close");
        } catch (e) {
            const error = e?.response?.data?.error;
            if (error) {
                errorService.sendError(
                    _get(locales, error?.label)(error.defaultValue || "")
                );
            }
        }
    };

    const getAllMyParticipatingGames = lobbyStore.getAllMyParticipatingGames(
        authStore.currentUser?._id
    );

    const isMaxNumberOfGames =
        getAllMyParticipatingGames.length >= MAX_NUMBER_OF_GAMES;

    return (
        <Dialog
            open={open}
            maxWidth={false}
            className={classes.dialog}
            onClose={onClose}
            PaperProps={{
                className: cn(classes.dialogPaperInviteFriend, appearanceTheme),
            }}
        >
            <div className={cn(classes.pattern, appearanceTheme)} />
            <ModalTitles
                title={newGame("New Game")}
                label={EModalsLabels.friendFightInit}
            />
            <span className={classes.description}>
                Игра происходит без удвоений
            </span>
            <ModalDivider label={EModalsLabels.friendFightInit} />
            <div className={classes.gameFiltersHolder}>
                <BetConfig position="top" />
                <TimeConfig position="top" />
                <RoundsConfig
                    value={selectedRounds}
                    onChange={setSelectedRounds}
                    includeEntry
                />
            </div>
            <ModalButtonsBox
                acceptText={playButton("Let’s play")}
                rejectText={cancelFriendRequest("Cancel")}
                acceptActions={handleCreateGame}
                rejectActions={onClose}
                label={EModalsLabels.friendFightInit}
                isMaxNumberOfGames={isMaxNumberOfGames}
            />
        </Dialog>
    );
};

export default observer(CreateGameModal);
