import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";

const currentHeight = window.innerHeight;

export const useStyles = makeStyles({
    modalDevider: {
        width: "100%",
        flexDirection: "column",
        height: "150px",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
        overflow: "hidden",
        bottom: "0px",
        background:
            "radial-gradient(circle, rgb(152, 255, 218) 20%, rgba(152, 255, 218, 0.11) 80%)",
        borderTop: `1px solid ${appColors.white}`,
        borderBottomLeftRadius: "50px",
        borderBottomRightRadius: "50px",
        opacity: 0.2,
        "&.friendFightInit": {
            height: "150px",
        },
        "&.friendFightReceive": {
            height: "140px",
        },
        "&.lobbyConfirmGame": {
            height: "140px",
            display: "none",
            [theme.breakpoints.down("sm")]: {
                height: "130px",
            },
        },
        "&.deepBlack": {
            zIndex: -1,
        },
    },

    "@keyframes uploadAvatarWrapper": {
        "0%": {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: "10px",
        },
        "100%": {
            opacity: 1,
            borderRadius: "35px",
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },
});
