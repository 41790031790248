import React from "react";
import { Icon } from "@mui/material";

import iOSDetect from "src/utils/iOsDetect";

import rapidPng from "./png_icons/rapid.png";
import filterBlueClockIcon from "src/assets/icons/filter-blue-clock.svg";
import { rapid } from "./svg_icons";

export const RapidIcon = (): JSX.Element => {
    return (
        <>
            <img src={filterBlueClockIcon} alt="rapid" />
        </>
    );
};

// Save this for example svg logical, dont remove pls
// export const RapidIcon = () => {
//   return (
//       <>
//           {iOSDetect() ? (
//               <img src={rapidPng} alt='rapid' />
//           ) : (
//             <img src={rapidPng} alt='rapid' />
//               <SvgIcon component={rapid} />
//           )}
//       </>
//   );
// };
