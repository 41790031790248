import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

export const useStyles = makeStyles({
    modalWrapper: {
        padding: '10px',
        display: 'grid',
        placeItems: 'center',
        // backdropFilter: 'blur(7px)',
    },
    modal: {
        padding: 30,
        top: 'unset',
        left: 'unset',
        transform: 'unset',
        position: 'unset',
        background: '#17191E',
        borderRadius: 50,
    },
    modalContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
    },
    title: {
        fontSize: 30,
        fontWeight: 500,
        color: appColors.white,
    },
    tablesContainer: {
        display: 'grid',
        gridTemplateColumns: '180px 180px',
        gridTemplateRows: '120px 120px',
        gridColumnGap: 20,
        gridRowGap: 16,
    },
    tableItem: {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        background: 'rgba(255, 255, 255, 0.08)',
        border: '1px solid #9BB9C8',
        display: 'grid',
        placeItems: 'center',
        fontSize: 72,
        fontWeight: 700,
        color: 'rgba(255, 255, 255, 0.3)',
        cursor: 'pointer',
        '&.active': {
            color: appColors.white,
            background: 'rgba(255, 255, 255, 0.18)',
        },
    },
    buttonContainer: {
        marginTop: 33,
    },

    crossWrapper: {
        position: 'absolute',
        top: '5px',
        left: '5px',
        zIndex: 3,
        cursor: 'pointer',
    },
    cross: {
        width: 25,
        height: 25,
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            width: '110%',
            height: '2px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            '-webkit-transform': 'translate(-50%,-50%) rotate(45deg)',
            transform: 'translate(-50%,-50%) rotate(45deg)',
            background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        },
        '&::after': {
            content: '""',
            display: 'block',
            width: '110%',
            height: '2px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            '-webkit-transform': 'translate(-50%,-50%) rotate(-45deg)',
            transform: 'translate(-50%,-50%) rotate(-45deg)',
            background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 20,
            height: 20,
        },
    },
});
