import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

const currentHeight = window.innerHeight;

export const useStyles = makeStyles({
    modalStatusBox: {
        width: '280px',
        height: '90px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        position: 'absolute',
        left: '70%',
        top: '25%',
        transform: 'translate(-50%, -50%) scale(1)',
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            position: 'static',
            top: 0,
            left: 0,
            transform: 'translate(0%, 0%)',
            alignItems: 'end',
            width: '320px',
            height: '74px',
        },
        '&.fastGame': {
          width: '90%',
          maxWidth: '450px',
          maxHeight: '45px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          position: 'absolute',
          left: '50%',
          top: '20%',
          transform: 'translate(-50%, -50%) scale(1)',
        }
    },

    modalStatusWrapper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        '-moz-border-radius': '10px',
        '-webkit-border-radius': '10px',
    },

    statusParagraphText: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '10px 20px 10px 30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '16px',
        color: '#757A80',
        textAlign: 'center',
        wordBreak: 'break-all',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
            padding: '0px 10px 10px 10px',
            margin: 0,
        },
        '&.fastGame': {
          fontSize: '15px',
          padding: '0px 5px 5px 5px',
        }
    },

    '@keyframes uploadAvatarWrapper': {
        '0%': {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: '10px',
        },
        '100%': {
            opacity: 1,
            borderRadius: '35px',
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },
});
