import cn from "classnames";
import { appColors } from "./../../../consts/app-config";
import { makeStyles } from "@mui/styles";
import { MAX_CHANGE_SCREEN_WIDTH } from "../../../pages/game-new/constants";
import theme from "src/theme";

import veryfiedTick from "src/assets/images/verified.png";

export const useStyles = makeStyles({
    footer: {
        padding: '0 0.5vw',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#151719",
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            padding: '0',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            padding: '0 1.5vw',

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            padding: '4vw 2vw',

        },
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            padding: '0',
            marginBottom: '1vw',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            padding: '2vw 0',

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            padding: '1.5vw 0',

        },
    },
    logoIcon: {
        width: '9vw',
        height: 'auto',
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            width: '17%',

        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            width: '20%',

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            width: '35%',

        },
    },
    gcLogo: {
        width: '8vw',
        height: 'auto',
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            width: '13%',

        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            width: '15%',

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            width: '28%',

        },
    },
    aboutUsBox: {
        width: '100%',
        textAlign: 'center',
        marginTop: '0.5vw',
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            marginTop: '0.5vw',

        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            marginTop: '1vw 0',

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            marginTop: '4vw',

        },
    },
    aboutUsText: {
        color: '#888888',
        fontFamily: 'Montserrat, sans- serif',
        fontWeight: 400,
        fontSize: 12,
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'justify',
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            fontSize: '1vw',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            fontSize: 15,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 12,
        },
    },
    footerNavlinksWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            flexDirection: 'column',

        },
    },
    link: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        lineHeight: "1vw",
        textDecoration: 'none',
        fontSize: 14,
        '&:hover': {
            textDecoration: 'none',
        },
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            fontSize: 15,

        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            fontSize: 15,

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 14,
            padding: '2vw 0',
            fontWeight: 700,
            width: '100%',
        },
    },

    businessAndFollowUs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            flexDirection: 'column',
        },
    },

    linksSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: '4.5vw',
        margin: '3vw 0',
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            gap: '3vw',

        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '4vw 0',
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            margin: '5vw 0',
            flexDirection: 'column',
        },
    },

    businessContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '2vw 0',
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            height: '100%',
            display: 'flex',
            alignItems: 'end',
            margin: '0',
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            flexDirection: 'column',
        },
    },

    business: {
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        lineHeight: "1vw",
        fontSize: '1vw',
        padding: '0.5vw 0.8vw',
        margin: '0 2vw',
        cursor: 'pointer',
        border: '0.1vw solid #fff',
        borderRadius: "0.2vw",
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            height: 45,
            fontSize: 18,
            padding: '12px 16px',
            borderRadius: 4,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 18,
            padding: '2vw 0',
            margin: '2vw 0',
            fontWeight: 700,
            lineHeight: "2vw",
            width: '100%',
            height: 45,
            borderRadius: 4,
            justifyContent: 'center',
        },
    },
    businessIcon: {
        marginRight: 8,
        marginBottom: 3,
        width: '1vw',
        height: '1vw',
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            width: 18,
            height: 18,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            width: 18,
            height: 18,
        },
    },
    social: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        lineHeight: "0.7vw",
        fontSize: '0.85vw',
        border: '0.1vw solid #fff',
        padding: '0.4vw',
        borderRadius: "0.2vw",
        '& > span': {
            marginRight: theme.spacing(2),
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            height: 45,
            padding: '12px 16px',
            borderRadius: 4,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            padding: '2vw 0',
            margin: '2vw 0',
            fontWeight: 700,
            lineHeight: "2vw",
            width: '100%',
            height: 45,
            borderRadius: 4,
            justifyContent: 'center',
        },
    },

    contactUsText: {
        fontSize: 15,
        fontWeight: 700,
        whiteSpace: 'nowrap',
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            fontSize: 15,

        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 15,

        },
    },

    socialIcons: {
        display: "flex",
        gap: 24,
        cursor: 'pointer',
        justifyContent: "flex-end",
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            gap: 20,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            gap: "3vw",
        },
    },

    iconContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: "4.5vw",
        marginTop: '1vw',
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            marginBottom: "5vw",
            marginTop: '2.5vw',
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            margin: "5vw 0",            
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            margin: '3vw 0',
        },
    },

    bankIconsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%',
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            justifyContent: 'center',
        },
    },

    bankIconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            width: '25%',
            marginBottom: '10px',
        },
    },

    payIcon: {
        maxWidth: '100%',
        height: 'auto',
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            maxWidth: 60,
        },
    },

    smallIcon: {
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            width: 34,
            height: 34,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            width: 29,
            height: 29,
        },
    },


    socialIcon: {
        width: 'auto',
        '&:hover': {
            opacity: 0.8,
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            width: 20,
            height: 20,
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            width: 18,
            height: 18,
        },
    },

    followUsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            margin: '5vw 0',
        },
    },
    followUsTitle: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 700,
        marginBottom: '4vw',
        textAlign: 'start',
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            fontSize: 15,
            marginBottom: '1vw',

        },
    },
    followIcons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 15,
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            justifyContent: 'flex-start',
            gap: 35,

        },
    },
    folowIcon: {
        width: 20,
        height: 20,
        cursor: 'pointer',
    },
});
