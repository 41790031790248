export type ColorKeysType = keyof typeof appColors;
export type ColorValuesType = typeof appColors[ColorKeysType];

export const appColors = {
    white: "#FFFFFF",
    semiwhite: "#f2f2f2",
    black: "#000000",
    semiBlack: "#191919",

    deepBlack: "#151719",
    juiceGreen: "#00BA6C",

    txt1000: "#2F393F",
    txt970: "#4D4F51",
    txt950: "#B6B6B6",
    txt900: "#969BAD",
    txt870: "#A8B1CE",
    txt850: "#B9A0FF80",
    txt800: "#B0B8DE",
    txt700: "#3CC6CB",
    txt650: "#92E8D8",
    txt600: "#50BAAE",
    txt550: "#547672",
    txt500: "#6D7490",
    txt400: "#5C637C",
    txt300: "#F8BF44",
    txt250: "#F7F6F9",

    bg1500: "#262C4C",
    bg1300: "#202233",
    bg1200: "#13141F",
    bg1100: "linear-gradient(89.48deg, #101320 -6.29%, #1F2336 122.04%)",
    bg900: "#59BBAE",
    bg800: "#1F2234",
    bg700: "#264083",
    bg650: "#25F87C",
    bg600: "#1A6E64",
    bg500: "#F7F6F9",
    bg400: "#5C637C",
    bg300: "linear-gradient(19.92deg, #158F80 13.3%, #50BAAE 86.7%)",
    bg200: "#00BA6C4D",

    noneActive100: "#6D7490",

    border400: "#313B57",
    border300: "#343A59",
    border200: "#0B0C12",
    border100: "#0A0C14",
    placeholder100: "#969BAD",
} as const;

export const greenIconStyle = Object.freeze({
    color1: "#6C50BA",
    color2: "#220D5C",
    color3: "#503698",
    color4: "#46279B",
});

export const blueIconStyle = {
    color1: "#168174",
    color2: "#50BAAE",
    color3: "#2F9A8D",
    color4: "#0D5C52",
} as const;

export const yellowIconStyle = {
    color1: "#F8BF44",
    color2: "#A16529",
    color3: "#F8BF44",
    color4: "#A16529",
} as const;

export const redIconStyle = {
    color1: "#831D1D",
    color2: "#341010",
    color3: "#831D1D",
    color4: "#341010",
} as const;

export const tournamentIconColors = {
    gold: "#F3B145",
} as const;

export const prizeModalGold = {
    color1: "#F8BF44",
    color2: "#A16529",
} as const;

export const prizeModalSilver = {
    color1: "#F9F9F9",
    color2: "#545454",
} as const;

export const prizeModalOther = {
    color1: "gray",
    color2: "wheat",
} as const;

export const backgroundColors = {
    darkBlue:
        "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #575E85 0%, rgba(52, 58, 89, 0) 100%)",
    darkGreen:
        "radial-gradient(63.19% 181.28% at 51.57% 62.5%, rgb(0, 73, 78) 0%, rgb(0, 0, 0) 100%)",
    deepBlack: "#050505",
} as const;

export const miniPiecesColors = Object.freeze({
    deepBlack: {
        w: "rgba(216, 164, 127, 0.4)",
        b: "black",
    },
});
export const miniPiecesStroke = Object.freeze({
    deepBlack: {
        w: "rgba(216, 164, 127, 0.1)",
        b: "rgba(255, 255,255, 0.4)",
    },
});

export const chesboardSquares = {
    deepBlack: {
        black: "#B88762",
        white: "#EDD6B0",
    },
};

export const squareShineColors = {
    SQUARE_START_DARKBLUE_COLOR: "rgba(148, 146, 255, 1)",
    BACKLIGHT_DARKBLUE_COLOR: "rgba(148, 146, 255, 0.5)",
    BASE_END_DARKBLUE_COLOR: "rgba(148, 146, 255, 0)",
    SQUARE_START_DARKGEEN_COLOR: "rgba(0, 73, 78, 0.8)",
    BACKLIGHT_DARKGREEN_COLOR: "rgba(0, 73, 78, 0.2)",
    BASE_END_DARKGREEN_COLOR: "rgba(0, 73, 78, 0)",
    BACKLIGHT_DEEPBLACK_COLOR: "#99FFD5",
} as const;

export type OpponentColorType = typeof opponentColor;
export type ColorArrayFromValues = OpponentColorType[keyof OpponentColorType][];

export const opponentColor = Object.freeze({
    red: "red",
    purple: "purple",
    blue: "blue",
    tomato: "tomato",
    aqua: "aqua",
    green: "green",
    yellow: "yellow",
    orange: "orange",
    wheat: "wheat",
});
