import { makeStyles } from "@mui/styles";

import theme from "src/theme";

import { MAX_CHANGE_SCREEN_WIDTH, MAX_L_TABLET } from "../../constants";

export const useStyles = makeStyles({
    versusMatchWrapper: {
        width: "100%",
        height: "200px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "0 20px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        [theme.breakpoints.up("xs")]: {
            padding: "0px",
            height: "100% !important",
            width: "100% !important",
            gridTemplateColumns: "1fr auto 1fr !important",
            maxWidth: "300px",
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: "350px",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "100%",
            alignItems: "flex-end",
        },
        "&.rolled": {
            animationName: "$versusMatchWrapper",
            animationDelay: "0s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1200ms",
            zIndex: 6,
        },
    },

    "@keyframes versusMatchWrapper": {
        "0%": {
            backdropFilter: "blur(0px)",
            opacity: 1,
            transform: "translate(-50%, -50%)",
        },
        "90%": {
            transform: "translate(50%, -50%) scale(10)",
            opacity: 0,
        },
        "99%": {
            zIndex: -5,
            opacity: 0,
        },
        "100%": {
            opacity: 0,
            transform: "translateX(2000px)",
        },
    },

    playerImgWrapper: {
        position: "relative",
        width: 130,
        height: 130,
        background:
            "linear-gradient(205.49deg, #6E6F7D 16.14%, #EFEFEF 104%), linear-gradient(16.45deg, #E08C3C -60.85%, #FFD06F 183.7%), linear-gradient(205.49deg, #6E6F7D 16.14%, #EFEFEF 104%)",
        boxShadow: "7.61419px 6.09135px 17px rgba(0, 0, 0, 0.3)",
        borderRadius: "15%",
        [theme.breakpoints.up("xs")]: {
            width: "fit-content",
            height: "inherit",
            aspectRatio: 1,
            justifySelf: "center",
            maxHeight: 100,
        },
        [theme.breakpoints.up(1800)]: {
            maxHeight: 130,
        },
        [theme.breakpoints.up(2400)]: {
            maxHeight: 145,
        },
        "&.rolled": {
            animationName: "$imageRotate",
            animationDelay: "0s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1500ms",
            transform: "rotate(0deg)",
        },
        "&.isMultitable": {
            [theme.breakpoints.up(1800)]: {
                maxHeight: 90,
            },
        },
    },

    // playerImgWrapperAnimation: {
    //     position: 'relative',
    //     width: '150px',
    //     height: '150px',
    //     background:
    //         'linear-gradient(205.49deg, #6E6F7D 16.14%, #EFEFEF 104%), linear-gradient(16.45deg, #E08C3C -60.85%, #FFD06F 183.7%), linear-gradient(205.49deg, #6E6F7D 16.14%, #EFEFEF 104%)',
    //     boxShadow: '7.61419px 6.09135px 17px rgba(0, 0, 0, 0.3)',
    //     borderRadius: '30px',

    //     [theme.breakpoints.down(1600)]: {
    //         width: '120px',
    //         height: '120px',
    //     },
    // },

    "@keyframes imageRotate": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "50%": {
            transform: "rotate(360deg)",
        },
        "90%": {
            transform: "rotate(720deg)",
        },
        "100%": {
            transform: "rotate(1080deg)",
        },
    },

    avatarImage: {
        zIndex: 2,
        borderRadius: "50%",
        objectFit: "cover",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "calc(90% - 4%)",
        height: "calc(90% - 4%)",
        "&.isSystemAvatar": {
            width: "90%",
            height: "90%",
        },
        "&.isLoaded": {
            filter: "blur(4px)",
        },
    },

    avatarBorderImage: {
        width: "90%",
        height: "90%",
        objectFit: "contain",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        zIndex: 1,
    },

    versusMatchText: {
        display: "inline-box",
        opacity: "0.9",
        // fontSize: '100px !important',
        // fontSize: "4vw !important",
        backgroundClip: "border-box",
        fontWeight: 500,
        textAlign: "center",
        textShadow: "0px 0px 15.2259px rgba(0, 0, 0, 0.5)",
        background:
            "linear-gradient(178.82deg, #A2A2A2 31.6%, #FFFFFF 50.51%, #A9A9A9 76.41%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        "-webkit-box-decoration-break": "clone",
        // [theme.breakpoints.down(1025)]: {
        //     fontSize: '10vw !important',
        // },
        fontSize: "46px !important",
        [theme.breakpoints.down("lg")]: {
            fontSize: "38px !important",
            lineHeight: "38px",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "32px !important",
            lineHeight: "32px",
        },
    },
    lazyImageWrapper: {
        width: "100%",
        height: "100%",
    },
});
