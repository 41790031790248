import React from 'react';

import { useLocale } from 'src/providers/LocaleProvider';

import BaseAlert from '../BaseAlert';

const OpponentReject = () => {
    const {
        game: {
            dialogs: {
                rematch: {
                    opponentRejected: { title },
                },
            },
        },
    } = useLocale();
    return <BaseAlert title={title('Opponent refused')} />;
};

export default OpponentReject;
