import { useLocale } from 'src/providers/LocaleProvider';
import { GoldModal } from 'src/shared/ui/modals/GoldModal';
import { Content } from 'src/shared/ui/modals/GoldModal/Content';
import { ListItem } from 'src/shared/ui/modals/GoldModal/ListItem';
import { List } from 'src/shared/ui/modals/GoldModal/List';
import { Title } from 'src/shared/ui/modals/GoldModal/Title';
import { ObjectHelper } from 'src/shared/helpers/ObjectHelper';

interface IBonusDescriptionModalProps {
    className?: string;
    open?: boolean;
}

export const BonusDescriptionModal = ({
    className,
    open,
}: IBonusDescriptionModalProps) => {
    const {
        bonusModal: { title, listHeading, gameList },
    } = useLocale();

    const gameListLocale = Object.entries(
        ObjectHelper.getObjectOrDefault(gameList(), {})
    ).map(([_, game]) => game);

    return (
        <GoldModal className={className} open={open}>
            <Title>{title('Подарок от Dicechess')}</Title>
            <Content>
                <ListItem>{listHeading('Разыграй их в')}</ListItem>
                <List>
                    {gameListLocale.map((game: string, index: number) => (
                        <ListItem key={index} listStyleType='•'>
                            <span>{game}</span>
                        </ListItem>
                    ))}
                </List>
            </Content>
        </GoldModal>
    );
};
