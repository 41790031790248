import { createContext, useContext } from "react";
import { IGame } from "src/store/models";

type ChessBoardPropsContextType = {
    diceWidth: number;
    chessBoardWidth: number;
    chessBoardRef: any;
    allowDragDisabled: boolean;
    allowMoveDisabled: boolean;
    numberOfGames: number;
    isMultiTable: boolean;
    isTwoGameTable: boolean;
    currentGame?: IGame;
    onLobbyClose?: () => void;
    tableIndex?: number;
};

export const ChessBoardPropsContext = createContext<ChessBoardPropsContextType>(
    {} as ChessBoardPropsContextType
);

export const useChessBoardProps = () => useContext(ChessBoardPropsContext);
