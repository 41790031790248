import { action, computed, makeAutoObservable, runInAction } from "mobx";
import { Socket } from "socket.io-client";
import { errorService } from "src/service/services";

import {
    ICommunicationChatMessage,
    ICommunicationGameHistory,
    ICommunicationGameNotification,
    IRematchCollector,
} from "./types";

import { IClockPerPlayer } from "./types";

import { EGameRematchStatus } from "../models";

import {
    IBetRequest,
    IApplyBetRequest,
} from "../../service/api/game/requestResponses";
import GameStore from "./index";
import { ColorVariant } from "../models";

class RightBlockCollectorState {
    constructor(gameStore: GameStore) {
        makeAutoObservable(this);
        this.gameStore = gameStore;
        this.matchCounter = {
            itemAlpha: 0,
            itemBeta: 0,
        };
    }
    socket: Socket;
    gameStore: GameStore;
    clockData: number[] = [0, 0];
    matchCounter: {
        itemAlpha: number;
        itemBeta: number;
    };
    activeTimerId: string | null = null
}

export default RightBlockCollectorState;
