import { makeStyles } from '@mui/styles';

interface Props {
    size: number;
    color: string;
    time: number;
}
const defaultStyle = ({ size, color }: Pick<Props, 'size' | 'color'>) => ({
    width: size,
    height: size,
    backgroundColor: color,
    borderRadius: '100%',
});

export const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    first: ({ time, ...props }: Props) => ({
        ...defaultStyle(props),
        transition: `opacity ${time}s ease-in-out 0s`,
    }),
    second: ({ time, ...props }: Props) => ({
        ...defaultStyle(props),
        transition: `opacity ${time}s ease-in-out ${time / 2}s`,
    }),
    third: ({ time, ...props }: Props) => ({
        ...defaultStyle(props),
        transition: `opacity ${time}s ease-in-out ${time}s`,
    }),
});
