import loadable from '@loadable/component';
import { useMediaQuery, Box } from '@mui/material';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import paths from 'src/consts/paths';
import useStores from 'src/hooks/useStores';
import { useLocale } from 'src/providers/LocaleProvider';
import { GameMode } from 'src/store/models';

import appDownloadIcon from '../../../../../pages/lobbyNew/assets/download.svg';
import { errorService } from '../../../../../service/services';
import Splash from '../../../splash';

import InfoDesktopEdition from './desktopEdition';
import InfoMobileEdition from './mobileEdition';
import { useStyles } from './styles';
import LanguagePicker from '../LanguagePicker';

const NavDropdown = loadable(() => import('../NavDropdown'));

const Info = () => {
    const { authStore, generalStore } = useStores();
    const isDesktop = useMediaQuery('(min-width: 1025px)');
    const isMobile = useMediaQuery('(max-width: 1024px)');
    const isPwa = window.matchMedia('(display-mode: standalone)').matches;
    const {
        common: {
            balance: { errorOnSaveState },
        },
    } = useLocale();
    const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
    const [dropDownStatus, setDropDownStatus] = useState(false);
    const user = authStore.currentUser;
    const styles = useStyles();
    const history = useHistory();
    const isSystemAvatar = user?.isSystemAvatar;

    const onHiddenChange = async (balanceHidden: boolean) => {
        if (!authStore.isAuthorized) return;
        try {
            await authStore.updateHiddenBalance(balanceHidden);
        } catch (err) {
            // errorService.sendError(
            //     errorOnSaveState(
            //         'Ошибка при попытке сохранить состояния (ошибка сети)'
            //     )
            // );
        }
    };

    return (
        <Box component='div'
            className={cn(styles.appBarGridContainer, {
                inGeneralMode: generalStore.mode === GameMode.AGGREGATOR,
                isDesktop: isDesktop,
                isMobile: isMobile,
                isApp: !isPwa && isIOS && !isAndroid,
            })}
        >
            <InfoDesktopEdition
                onHiddenChange={onHiddenChange}
                isBonusModalOpen={isBonusModalOpen}
                onClickBonus={(status) => {
                    setIsBonusModalOpen(status);
                }}
            />
            <InfoMobileEdition
                onHiddenChange={onHiddenChange}
                isBonusModalOpen={isBonusModalOpen}
                onClickBonus={(status: boolean) => {
                    setIsBonusModalOpen(status);
                }}
            />
            <div
                className={cn(styles.appBarElement, {
                    dropDownStatus: dropDownStatus,
                })}
                onClick={() => {
                    setDropDownStatus((prev) => !prev);
                }}
            >
                {/*{window.innerWidth > 900 ? <Splash*/}
                {/*    nickname={user?.nickname ?? 'Anonymous'}*/}
                {/*    avatar={user?.avatar}*/}
                {/*    isSystemAvatar={isSystemAvatar || false}*/}
                {/*    pathCompressed={user?.pathCompressed}*/}
                {/*/> : <></>}*/}
                <Splash
                    nickname={user?.nickname ?? 'Anonymous'}
                    avatar={user?.avatar}
                    isSystemAvatar={isSystemAvatar || false}
                    pathCompressed={user?.pathCompressed}
                />
                <NavDropdown />
            </div>
        </Box>
    );
};
export default observer(Info);
