import React, { useMemo } from "react";
import cn from "classnames";
import { Scrollbar } from "react-scrollbars-custom";
import useStores from "src/hooks/useStores";
import { observer } from "mobx-react";
import { useTransition, animated } from "react-spring";

import crownIcon from "src/assets/icons/crown-contained.svg";
import { ITopPlayersNew } from "src/store/models";
import refreshIcon from "src/assets/icons/refresh.svg";
import { Spinner } from "src/components/spinner";

import { useStyles } from "./styles";
import { TopPlayerItem } from "./components/TopPlayerItem";
import TopListBox from "./components/TopListBox";
import { useLocale } from "../../../../providers/LocaleProvider";

export interface ITopPlayersElement {
    componentPlace?: string;
}

const TopPlayers = ({ componentPlace }: ITopPlayersElement) => {
    const classes = useStyles();
    const { authStore, achievementsStatStore } = useStores();
    const topPlayersNew = achievementsStatStore.topPlayersNew;
    const me = authStore?.currentUser?._id;
    const {
        lobby: {
            topPlayers: { top },
        },
    } = useLocale();
    const myPosition = (
        topArray: ITopPlayersNew[] | null,
        me: string | undefined
    ) => {
        if (topArray && me) {
            return topArray.findIndex((player) => player._id === me) + 1;
        }
        return null;
    };

    const myPositionMemo = useMemo(() => {
        return myPosition(topPlayersNew, me);
    }, [topPlayersNew, me]);

    if (componentPlace == "appBar") {
        return (
            <div
                className={cn(classes.currentRankWrapper, componentPlace)}
                onClick={() => {
                    achievementsStatStore.toggleTopPlayersInfoNew(
                        !achievementsStatStore.topPlayersToggleNew
                    );
                }}
            >
                <img
                    src={crownIcon}
                    className={cn(classes.rankIcon, componentPlace)}
                />
                <span className={cn(classes.currentRank, componentPlace)}>
                    {myPositionMemo ? myPositionMemo : "-"}
                </span>
            </div>
        );
    }

    if (componentPlace === "isMobile") {
        return (
            <div
                className={cn(classes.topPlayersWrapper, componentPlace, {
                    openTopPlayers: achievementsStatStore.topPlayersToggleNew,
                })}
            >
                {!topPlayersNew ? (
                    <Spinner />
                ) : (
                    <>
                        <div className={cn(classes.topWrapper)}>
                            <div className={classes.crossWrapper}>
                                <div
                                    className={classes.cross}
                                    onClick={() => {
                                        achievementsStatStore.toggleTopPlayersInfoNew(
                                            !achievementsStatStore.topPlayersToggleNew
                                        );
                                    }}
                                ></div>
                            </div>
                            <div className={classes.currentRankWrapper}>
                                <img
                                    src={crownIcon}
                                    className={classes.rankIcon}
                                />
                                <span className={classes.currentRank}>
                                    {myPositionMemo}
                                </span>
                            </div>
                            <span className={classes.headerTitle}>{top("TOP 100")}</span>
                        </div>
                        <TopListBox />
                    </>
                )}
            </div>
        );
    }

    return (
        <div className={classes.topPlayersWrapper}>
            {!topPlayersNew ? (
                <Spinner />
            ) : (
                <>
                    <div className={classes.topWrapper}>
                        <div className={classes.currentRankWrapper}>
                            <img src={crownIcon} className={classes.rankIcon} />
                            <span className={classes.currentRank}>
                                {myPositionMemo}
                            </span>
                        </div>
                        <span className={classes.headerTitle}>TOP 100</span>
                    </div>
                    <TopListBox />
                </>
            )}
        </div>
    );
};
export default observer(TopPlayers);
