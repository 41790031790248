import useStores from './useStores';
import { DrawStage } from '../store/gameStore/dialogs';

export const useDrawStageStatus = () => {
    const { gameStore } = useStores();
    const drawDialog = gameStore?.dialogs?.drawDialog;
    const isConfirmDrawOfferingDialog =
        drawDialog?.isOpen &&
        drawDialog.stage === DrawStage.confirmDrawOffering;
    const isWaitingForOpponentToDecideDialog =
        drawDialog?.isOpen &&
        drawDialog.stage === DrawStage.waitingForOpponentToDecide;
    const isAcceptOrRejectDrawDialog =
        drawDialog?.isOpen &&
        drawDialog?.stage === DrawStage.acceptOrRejectDraw;
    const opponentIsRejectedDraw =
        drawDialog?.isOpen &&
        drawDialog.stage === DrawStage.opponentIsRejectedDraw;
    return {
        isConfirmDrawOfferingDialog,
        isWaitingForOpponentToDecideDialog,
        isAcceptOrRejectDrawDialog,
        opponentIsRejectedDraw,
    };
};
