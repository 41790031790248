import React, { useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { observer } from 'mobx-react';

import { useLocale } from 'src/providers/LocaleProvider';
import useOrientation from 'src/hooks/useOrientation';
import { MAX_XL_TABLET } from 'src/pages/game-new/constants';
import useStores from 'src/hooks/useStores';
import useWindowSize from 'src/hooks/useWindowSize';

import { useStyles } from './styles';

const LandscapeBlocker = ({ children }) => {
    const { generalStore } = useStores();
    const { width, height } = useWindowSize();
    const styles = useStyles();
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;

    const availWidth = window?.screen?.availWidth ?? currentWidth;
    const availHeight = window?.screen?.availHeight ?? currentHeight;

    const orientationHook = useOrientation();
    const {
        common: { landscapeError },
    } = useLocale();
    const [isPwa] = useState(
        window.matchMedia('(display-mode: standalone)').matches
    );
    const orientation = !isPwa
        ? isAndroid
            ? availWidth - availHeight
            : currentWidth - currentHeight
        : window.orientation;

    const mobileHeightSize =
        currentHeight < 620 || currentWidth < MAX_XL_TABLET;

    // if (
    //     ((!isPwa && mobileHeightSize && orientation >= 0) ||
    //         (isPwa && orientation !== 0)) &&
    //     generalStore.isDicechessMode
    // ) {
    //     return (
    //         <div className={styles.wrapper}>
    //             {landscapeError(
    //                 'Attention! Turn your device to vertical position!'
    //             )}
    //         </div>
    //     );
    // }
    return <>{children}</>;
};

export default observer(LandscapeBlocker);
