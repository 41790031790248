import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Typography, Box } from "@mui/material";

import useStores from "src/hooks/useStores";

import LazyImage from "./lazyImage";
import { useStyles } from "./styles";
import { IStartMatch } from "./types";

function StartMatch({ rolled, elemHeight, isMultitable }: IStartMatch) {
    const styles = useStyles();
    const { gameStore } = useStores();
    const players = gameStore.initData?.players;

    return (
        <>
            {players &&
                Array.isArray(players) &&
                players.length > 0 &&
                !gameStore.dialogs.selectFiguresDialog.isOpen && (
                    <Box
                        className={cn(styles.versusMatchWrapper, {
                            rolled: rolled,
                        })}
                    >
                        <>
                            <LazyImage
                                player={players[0]}
                                rolled={rolled}
                                elemHeight={elemHeight}
                                isMultitable={isMultitable}
                            />
                            <Typography className={cn(styles.versusMatchText)}>
                                vs
                            </Typography>
                            <LazyImage
                                player={players[1]}
                                rolled={rolled}
                                elemHeight={elemHeight}
                                isMultitable={isMultitable}
                            />
                        </>
                    </Box>
                )}
        </>
    );
}

export default observer(StartMatch);
