import { useLocale } from 'src/providers/LocaleProvider';
import React from 'react';
import ILocaleText from './IlocaleText';

export default function LocaleText({
    tag,
    label,
    defaultValue,
    props,
    className,
    style,
}: ILocaleText) {
    const { extractCompiled } = useLocale();

    return (
        <span className={className} style={style}>
            {extractCompiled(label, props, defaultValue)}
        </span>
    );
}

