export const endpointsRoot = process.env.REACT_APP_API_ENDPOINT;

const endpoints = {
    getUser: `${endpointsRoot}/api/3/user`,
    createGameNew: `${endpointsRoot}/api/3/invite/create-free`,
    sendInvite: `${endpointsRoot}/api/3/invite/send-invite`,
    applyNormalInvite: `${endpointsRoot}/api/3/invite/apply-normal-invite`,
    declineNormalInvite: `${endpointsRoot}/api/3/invite/decline-normal-invite`,
    cancelNormalInvite: `${endpointsRoot}/api/3/invite/cancel-normal-invite`,
    applyFreeInvite: `${endpointsRoot}/api/3/invite/apply-free-invite`,
    login: `${endpointsRoot}/api/3/auth/sign-in`,
    phone: `${endpointsRoot}/api/3/auth/sms-request`,
    phoneCode: `${endpointsRoot}/api/3/auth/sms-confirm`,
    auth: `${endpointsRoot}/api/3/auth/sign-up`,
    recoveryPasswordNew: `${endpointsRoot}/api/3/auth/recover-password`,
    resetPasswordNew: `${endpointsRoot}/api/3/auth/reset-password`,
    country: `${endpointsRoot}/api/3/country`,

    initGame: `${endpointsRoot}/game`,
    gameMove: `${endpointsRoot}/api/3/game/move`,
    gameRoll: `${endpointsRoot}/api/3/game/roll`,
    gameBet: `${endpointsRoot}/api/3/game/bet`,
    gameApplyBet: `${endpointsRoot}/api/3/game/apply-bet`,
    gameMessage: `${endpointsRoot}/api/3/game/message`,

    rematchPropose: `${endpointsRoot}/api/3/game/rematch-propose`,
    rematchAccept: `${endpointsRoot}/api/3/game/rematch-accept`,
    rematchDecline: `${endpointsRoot}/api/3/game/decline-bet`,

    loginByToken: `${endpointsRoot}/api/v2/loginByToken`,
    logout: `${endpointsRoot}/api/v2/logout`,
    locale: `${endpointsRoot}/api/3/locale`,
    lobby: `${endpointsRoot}/api/v2/lobby`,
    diceLobby: `${endpointsRoot}/api/v2/diceChessLobby`,
    games: `${endpointsRoot}/api/v2/games`,
    createGame: `${endpointsRoot}/api/v2/game`,
    getActiveGames: `${endpointsRoot}/api/v2/activeGames`,
    game: `${endpointsRoot}/api/v2/game`,
    deleteGame: `${endpointsRoot}/api/v2/game`,

    getStreams: `${endpointsRoot}/api/3/static-block/streams`,

    //account: `${endpointsRoot}/api/v2/account`,
    editAccount: `${endpointsRoot}/api/v2/account/edit`,

    friends: `${endpointsRoot}/api/3/user-friend`,
    addFriend: `${endpointsRoot}/api/3/user-friend/add`,
    removeFriend: `${endpointsRoot}/api/3/user-friend/remove`,
    approveFriend: `${endpointsRoot}/api/3/user-friend/approve`,
    deleteFriend: `${endpointsRoot}/api/v2/friends/delete`,
    acceptFriendRequest: `${endpointsRoot}/api/3/friends/accept-request`,
    rejectFriendRequest: `${endpointsRoot}/api/3/friends/reject-request`,
    cancelFriendRequest: `${endpointsRoot}/api/3/friends/cancel-request`,

    users: `${endpointsRoot}/api/3/user/all`,
    searchUsers: `${endpointsRoot}/api/3/user/search`,
    markLocaleAsUsed: `${endpointsRoot}/api/3/locale/markAsUsed`,
    createLocale: `${endpointsRoot}/api/3/locale`,
    gamesHistory: `${endpointsRoot}/api/3/account/my-games`,
    register: `${endpointsRoot}/api/v2/registration`,
    takeRakeBack: `${endpointsRoot}/api/3/user/pay-rakeback`,
    getRanks: `${endpointsRoot}/api/3/rank`,
    checkEmail: `${endpointsRoot}/api/v2/check-email`,
    recoveryPassword: `${endpointsRoot}/api/v2/recovery-password`,
    resetPassword: `${endpointsRoot}/api/v2/reset-password`,
    lobbySocket: `${endpointsRoot}/lobby`,
    localeSocket: `${endpointsRoot}/locale`,
    mainSocket: `${endpointsRoot}/mainNamespace`,

    uploadAvatar: `${endpointsRoot}/api/3/user/upload-avatar`,
    uploadStaticAvatar: `${endpointsRoot}/api/v2/updateAvatarById`,
    uploadSystemAvatar: `${endpointsRoot}/api/3/user/upload-system-avatar`,

    updateSettings: `${endpointsRoot}/api/3/user/settings`,
    changeNickname: `${endpointsRoot}/api/3/user/nickname`,
    changeUserStatus: `${endpointsRoot}/api/3/user/status`,
    changePassword: `${endpointsRoot}/api/3/user/change-password`,

    updateProfile: `${endpointsRoot}/api/v2/profile`,
    updateBalanceHidden: `${endpointsRoot}/api/v2/profile/balanceHidden`,
    getPaymentForm: `${endpointsRoot}/api/v2/payments/getPaymentForm`,
    getPayoutForm: `${endpointsRoot}/api/v2/payments/getPayoutForm`,
    getAvatarList: `${endpointsRoot}/api/3/system-avatars`,
    interkassaCreatePayout: `${endpointsRoot}/api/v2/payments/interkassa/payout`,
    cryptoCreatePayout: `${endpointsRoot}/api/v2/payments/crypto/payout`,
    topUp: `${endpointsRoot}/api/3/payment/top-up`,
    p2pPaid: `${endpointsRoot}/api/3/payment/p2p-paid`,
    p2pCancel: `${endpointsRoot}/api/3/payment/p2p-cancel`,
    paymentsList: `${endpointsRoot}/api/3/payment`,
    createWithdraw: `${endpointsRoot}/api/3/payment/withdrawal`,
    cancelWithdraw: `${endpointsRoot}/api/3/payment/cancel-withdrawal`,
    createWithdrawalDetails: `${endpointsRoot}/api/3/payment/withdrawal-details`,
    avatarPath: `${endpointsRoot}/avatars/`,
    usersList: `${endpointsRoot}/api/v2/libs/getUsersSuggestions`,

    tournamentSocket: `${endpointsRoot}/tournaments`,
    createTournament: `${endpointsRoot}/api/3/create-tournament`,
    getTournaments: `${endpointsRoot}/api/3/tournament/list`,
    getCurrentTournament: `${endpointsRoot}/api/3/tournament/info`,
    joinTournaments: `${endpointsRoot}/api/3/tournament/join`,
    cancelJoinTournaments: `${endpointsRoot}/api/3/tournaments/cancel-join`,
    removeAllTournaments: `${endpointsRoot}/api/3/tournaments`,
    getTopPlayers: `${endpointsRoot}/api/3/tournaments/topPlayers`,

    lastVersion: `${endpointsRoot}/api/v2/versions`,
    changeBackGroundColor: `${endpointsRoot}/api/v2/profile/changeBackgroundAndDiceColor`,
    getSupportMessages: `${endpointsRoot}/api/3/support/messages`,
    supportMessage: `${endpointsRoot}/api/3/support/message`,
    supportFilesMessage: `${endpointsRoot}/api/v2/support/files-message`,
    supportMedia: `${endpointsRoot}/support-attachments`,
    alg1xBetGetUrl: `${endpointsRoot}/api/v2/externalGames/1xbet/getUrl`,
    algAltenteGetUrl: `${endpointsRoot}/api/3/external-game/open-game`,
    algLiveDealerGames: `${endpointsRoot}/api/3/external-game/live-dealers`,
    virtualSportGames: `${endpointsRoot}/api/3/external-game/virtual-sport`,
    externalGames: `${endpointsRoot}/api/3/external-game`,
    toggleExternalGameFavorite: `${endpointsRoot}/api/3/external-game/favorites`,
    getJackpokerUrl: `${endpointsRoot}/api/3/external-game/open-poker`,
    getPaymentMethods: `${endpointsRoot}/api/3/payment-method`,
    purchaseCoins: `${endpointsRoot}/api/v2/payments/purchaseCoins`,
    getReferralStatistic: `${endpointsRoot}/api/3/referral-program/stats`,
    getReferralUsers: `${endpointsRoot}/api/3/referral-program/users`,
    getReferralWithdraw: `${endpointsRoot}/api/3/referral-program/withdraw-revenue`,
    getJackpot: `${endpointsRoot}/api/3/notification/jackpot`,
    businessMessage: `${endpointsRoot}/api/v2/support/sendBusinessOpportunity`,
    userVerificationInfo: `${endpointsRoot}/api/3/verification-info`,
    userVerificationPhoto: `${endpointsRoot}/api/3/verification-info/photo`,
    //getMyNotifications: `${endpointsRoot}/api/v2/my-notifications`,
    markNotificationAsViewed: `${endpointsRoot}/api/v2/notifications/viewed`,
    //banners: `${endpointsRoot}/api/v2/banners`,
    media: `${endpointsRoot}/media`,
    getBonuses: `${endpointsRoot}/api/v2/rank-bonus`,
    impersonateUser: `${endpointsRoot}/api/3/auth/impersonate`,
    //variables: `${endpointsRoot}/api/v2/variables`,
    //gameChatSettings: `${endpointsRoot}/api/v2/account/update-right-block-mode`,
    gameChatStickers: `${endpointsRoot}/api/v2/sticker-packs`,
    externalGameProviders: `${endpointsRoot}/api/3/external-game/providers`,
    //opponentSettings: `${endpointsRoot}/api/v2/user-to-user-settings`,
    //myOpponentsSettings: `${endpointsRoot}/api/v2/my-user-to-user-settings`,
    //meOpponentsSettings: `${endpointsRoot}/api/v2/user-to-user-settings-to-me`,
    logger: `${endpointsRoot}/api/v2/libs/logger`,

    P2PInvoice: `${endpointsRoot}/api/v2/payments/p2p/invoice`,
    cassyInvoice: `${endpointsRoot}/api/v2/payments/cassy/invoice`,
    setP2PInvoiceAsPaid: `${endpointsRoot}/api/v2/payments/p2p/invoice/user-paid`,
    setP2PInvoiceAsCantPay: `${endpointsRoot}/api/v2/payments/p2p/invoice/user-cant-pay`,
    p2pWithdrawal: `${endpointsRoot}/api/v2/payments/p2p/withdrawal`,
    cassyWithdrawal: `${endpointsRoot}/api/v2/payments/cassy/withdrawal`,

    betting: `${endpointsRoot}/api/3/external-game/open-betting`,

    googleSignIn: `${endpointsRoot}/api/3/auth/google`,
    facebookSignIn: `${endpointsRoot}/api/3/auth/facebook`,

    sportEvent: `${endpointsRoot}/api/3/static-block/sportEvent`,
};

export default endpoints;
