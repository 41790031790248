import { useRef } from "react";
import { Round } from "../types";
import { BracketColumn } from "./bracketColumn";
import rightArrowIcon from 'src/assets/icons/ArrowLeft.svg';
import { observer } from "mobx-react";
import MatchPair from "./matchPair";
import TournamentLines from "./tournamentLines";

const BracketsCarousel: React.FC<{ bracket: Round[]; classes: any }> = ({ bracket, classes }) => {
    const carouselRef = useRef<HTMLDivElement | null>(null);

    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    return (
        <div className={classes.carouselContainer}>
            <button className={classes.carouselButton} onClick={scrollLeft}>
                <img src={rightArrowIcon} alt="Scroll Left" />
            </button>
            <div className={classes.bracketCarousel} ref={carouselRef}>
                <TournamentLines bracket={bracket} />
                {bracket.map((round, roundIndex) => (
                    <BracketColumn key={round.title} title={round.title} columnIndex={roundIndex}>
                        {round.matches.map((match, matchIndex) => (
                            <div
                                style={{
                                    marginBottom: !match.player2 || match.player2.name === "TBD" ? "20px" : "0px",
                                }}
                            >
                                <MatchPair
                                    key={matchIndex}
                                    player1={match.player1}
                                    player2={match.player2}
                                    columnIndex={roundIndex}
                                    matchIndex={matchIndex}
                                    isBye={!match.player2 || match.player2.name === "TBD"}
                                    totalColumns={bracket.length} 
                                />
                            </div>
                        ))}
                    </BracketColumn>
                ))}
            </div>
            <button className={classes.carouselButton} onClick={scrollRight}>
                <img style={{ transform: 'rotate(180deg)' }} src={rightArrowIcon} alt="Scroll Right" />
            </button>
        </div>
    );
};

export default observer(BracketsCarousel);
