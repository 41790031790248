import { ELanguages } from "src/store/models";
import { Nullable } from "src/shared/types";

// TODO: remove
export const bracketPlayerTranslate = (lang?: Nullable<ELanguages>) => {
    switch (lang) {
        case ELanguages.EN:
            return "Player absence";
        case ELanguages.DE:
            return "Abw. des Spielers";
        case ELanguages.FR:
            return "Absence du joueur";
        case ELanguages.RU:
            return "Неявка";
        default:
            return "Player absence";
    }
};
