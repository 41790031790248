import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '2px',
    },
});
