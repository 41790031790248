import React, { useState } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { observer } from "mobx-react";

import BetFilterPopup, {
    BetFilterPopupPosition,
} from "src/pages/lobby/components/BetFilterPopup";
import { appColors } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import freeCoinIcon from "src/assets/icons/freeCoin.png";
import coinIcon from "src/assets/icons/coin.svg";

import { SortDown } from "../../../../images/sort-down";
import { UserBalanceType } from "../../../../../../../../store/models";

import { useBetConfigStyles } from "./styles";

type BetConfigProps = {
    position?: BetFilterPopupPosition;
    placePosition?: string | undefined;
};

const BetConfig = ({ position, placePosition }: BetConfigProps) => {
    const OPEN = true;
    const CLOSE = false;
    const styles = useBetConfigStyles();
    const { lobbyStore } = useStores();
    const [isOpen, setOpenAdditional] = useState(CLOSE);
    const betIsOpen = lobbyStore.betConfig.isOpen;

    const toggler = (status: boolean): void => {
        if (!status && !isOpen) return;
        setOpenAdditional(status);
        lobbyStore.setBetConfigIsOpen(status);
    };

    const stateCatcher = () => {
        toggler(!isOpen);
    };

    return (
        <div className={styles.wrapperBetConfig}>
            <OutsideClickHandler
                onOutsideClick={() => {
                    toggler(CLOSE);
                }}
            >
                {betIsOpen && isOpen && (
                    <BetFilterPopup
                        position={position}
                        placePosition={placePosition}
                    />
                )}
                <Box
                    component="div"
                    className={cn(styles.container, placePosition)}
                    onClick={stateCatcher}
                >
                    <Box
                        component="div"
                        className={cn(styles.leftSideBox, placePosition)}
                    >
                        <Box
                            component="div"
                            className={cn(styles.icon, placePosition)}
                        >
                            <img
                                src={
                                    lobbyStore.lobbyMode ===
                                    UserBalanceType.play
                                        ? coinIcon
                                        : freeCoinIcon
                                }
                                className={cn(styles.coinIcon, placePosition)}
                            />
                        </Box>
                        <Box
                            component="div"
                            className={cn(styles.text, placePosition)}
                        >
                            {lobbyStore.betConfig.value}
                        </Box>
                    </Box>
                    {isOpen ? (
                        <Box
                            component="div"
                            className={cn(styles.sortDownOpen, placePosition)}
                        >
                            <SortDown color={appColors.bg500} />
                        </Box>
                    ) : (
                        <Box
                            component="div"
                            className={cn(styles.sortDown, placePosition)}
                        >
                            <SortDown color={appColors.bg500} />
                        </Box>
                    )}
                </Box>
            </OutsideClickHandler>
        </div>
    );
};

export default observer(BetConfig);
