import cn from 'classnames';
import { CSSProperties } from 'react';

import useChangeTheme from 'src/hooks/useChangeTheme';

import { Loader } from '../Loader';

import { useStyles } from './styles';

interface ISpinner {
    size?: number;
    isFullscreen?: boolean;
    isAbsolute?: boolean;
    background?: string;
    backgroundColor?: string;
    style?: CSSProperties;
}

export const Spinner = ({
    size,
    isFullscreen,
    isAbsolute,
    background,
    style,
}: ISpinner): JSX.Element => {
    const styles = useStyles();

    return (
        <div
            className={cn({
                [styles.fullscreen]: isFullscreen,
                [styles.fullscreenStatic]: !isAbsolute ? !isFullscreen : false,
                [styles.absoluteWrapper]: isAbsolute,
            })}
            style={{ ...style, background }}
        >
            <div className={styles.center}>
                <Loader size={size} />
            </div>
        </div>
    );
};
