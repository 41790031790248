import classicalPng from 'src/assets/icons/classical.png';

export const ClassicalIcon = (): JSX.Element => {
    return (
        <>
            <img
                src={classicalPng}
                alt='classical'
                style={{ width: 21, height: 21 }}
            />
        </>
    );
};
