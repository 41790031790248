import React, {
    ReactNode,
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    useRef,
} from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";

import { IPlayer } from "src/store/models";

import { useStyles } from "./styles";

interface IPlayerInfo {
    name: string;
    appearanceTheme: string;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    opponent?: IPlayer;
}

const NickPlayerComponent = observer((props: IPlayerInfo) => {
    const classes = useStyles();
    const { name, appearanceTheme, isMultiTable, opponent, isTwoGameTable } =
        props;

    return (
        <Box
            component="div"
            className={cn(classes.nicknameWrapper, "nickname-wrapper", {
                isMultiTable,
                isLargeNick: name.length >= 11 && !isMultiTable,
                isOpponent: opponent && !isMultiTable,
            })}
        >
            <div
                className={cn(classes.playerName, "player-name", {
                    isMultiTable,
                })}
            >
                <p
                    className={cn(
                        classes.playerNameParagraph,
                        appearanceTheme,
                        {
                            isMultiTable,
                            isTwoGameTable,
                            isLargeNick: name.length >= 10 && !isMultiTable,
                            isOpponent: opponent && !isMultiTable,
                        }
                    )}
                >
                    {name}
                    {/* {calcNameLength(name, currentWidth, 8)} */}
                </p>
            </div>
        </Box>
    );
});

export default NickPlayerComponent;
