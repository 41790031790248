import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import cx from 'classnames';
import { useTheme } from '@mui/material';


import iOSDetect from "src/utils/iOsDetect";
import { usePlayer } from "src/sounds/use-player";

import calcButtonWidth from "../../../utils/calcButtonWidth";

import { useStyles } from "./styles";

export enum ButtonType {
    primary = "primary",
    secondary = "secondary",
    negative = "negative",
    yellow = "yellow",
    purple = "purple",
    info = "info",
    registerBtn = "registerBtn",
    signUp = "signUp",
    logIn = "logIn",
    continueBtn = "continueBtn",
    takeBtn = "takeBtn",
}

type SteelButtonType = "button" | "reset" | "submit" | undefined;

const defaultProps = {
    ratio: 1,
    fontSize: 16,
    type: ButtonType.primary,
    disabled: false,
    loading: false,
    icon: undefined,
    iconPosition: "start",
    onClick: () => { },
    text: "",
    buttonType: "button" as SteelButtonType,
    buttonClass: undefined,
};
interface Props {
    type: ButtonType;
    onClick: () => void;
    disabled?: boolean;
    icon?: any;
    iconPosition?: "end" | "start";
    color?: string;
    textColor?: string;
    fontSize?: number | string;
    text: string;
    loading?: boolean;
    ratio?: number;
    buttonType?: SteelButtonType;
    buttonClass?: string;
    isBackground?: string | undefined;
    isMarginTop?: number | undefined;
    iOsSound?: any;
    isWidthFixed?: boolean;
    width?: number | string;
    height?: number | string;
    steelWrapperWidth?: number | string;
    padding?: number | string;
    paddingLeft?: number | string;
    paddingRight?: number | string;
    paddingTop?: number | string;
    paddingBottom?: number | string;
    marginLeft?: number | string;
    marginRight?: number | string;
    borderRadius?: number | string;
    fontFamily?: string;
    className?: string;
}

export const GreenButton = (props: Partial<Props>) => {
    let t = {
        ...defaultProps,
        ...props,
    };
    const classes = useStyles(t);
    const player = usePlayer();

    const {
        onClick,
        disabled,
        icon,
        color,
        fontSize,
        text,
        loading = false,
        isWidthFixed,
        iconPosition,
    } = t;

    const Icon = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {iconPosition === "start" && (
                    <div
                        style={{
                            paddingRight: 8,
                            paddingTop: 4,
                            transform: `scale(${props.ratio})`,
                        }}
                    >
                        {icon}
                    </div>
                )}
                <div>
                    {loading ? (
                        <PulseLoader size={14} color="white" loading />
                    ) : (
                        text
                    )}
                </div>
                {iconPosition === "end" && (
                    <div
                        style={{
                            paddingLeft: 8,
                            transform: `scale(${props.ratio})`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {icon}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div
            style={{
                background: `${props.isBackground}`,
                marginTop: `${props.isMarginTop}px`,
                marginLeft: `${props.marginLeft}px`,
                marginRight: `${props.marginRight}px`,
                width: props.width,
                transform: `scale(${props.ratio})`,
            }}
            className={cx(classes.buttonWrapper)}
            onClick={(e) => {
                e.stopPropagation();
                if (!disabled && !loading) {
                    onClick();
                    iOSDetect() && player(props.iOsSound);
                }
            }}
        >
            <button
                style={{
                    padding: props?.padding,
                    paddingLeft: props?.paddingLeft,
                    paddingRight: props?.paddingRight,
                    paddingTop: props?.paddingTop, // Добавлено
                    paddingBottom: props?.paddingBottom, // Добавлено
                    borderRadius: props?.borderRadius, // Добавлено
                    width: props.width,
                    minWidth: !isWidthFixed
                        ? undefined
                        : `${calcButtonWidth(typeof fontSize === 'number' ? fontSize : parseFloat(fontSize))}px`,
                    maxWidth: !isWidthFixed
                        ? undefined
                        : `${calcButtonWidth(typeof fontSize === 'number' ? fontSize : parseFloat(fontSize))}px`,
                }}
                className={cx(
                    //@ts-ignore
                    classes[t.type],
                    classes.customButton,
                    t["buttonClass"]
                )}
                //@ts-ignore
                type={t.buttonType === ButtonType.continueBtn ? "submit" : t.buttonType}
            >
                <div
                    style={{
                        fontSize: fontSize,
                        color,
                    }}
                    className={classes.buttonText}
                >
                    {icon ? (
                        <Icon />
                    ) : loading ? (
                        <PulseLoader size={14} color="white" loading />
                    ) : (
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: props.type === ButtonType.signUp ? 'black' : 'white',
                            }}
                        >
                            {text}
                        </div>
                    )}
                </div>
            </button>
        </div>
    );
};
