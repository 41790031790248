import { useEffect, useMemo } from "react";

import { useLocale } from "src/providers/LocaleProvider";
import { GameFinishReason, EGameRematchStatus } from "src/store/models";

import { usePlayer } from "../../../sounds/use-player";
import useStores from "../../../hooks/useStores";

export const useRematchDialogs = () => {
    const { gameStore, authStore } = useStores();
    const rematchInfo = gameStore.dialogs.rematchData;
    const me = authStore?.currentUser;
    const iAmInitiator = me?._id === rematchInfo.rematchStartedBy;

    const iAmInitAcceptOrRejectDialog =
        rematchInfo.rematchStatus === EGameRematchStatus.WAITING_FOR_ANSWER &&
        iAmInitiator;
    const initForMeAcceptOrRejectDialog =
        rematchInfo.rematchStatus === EGameRematchStatus.WAITING_FOR_ANSWER &&
        !iAmInitiator;

    return {
        iAmInitAcceptOrRejectDialog,
        initForMeAcceptOrRejectDialog,
    };
};
