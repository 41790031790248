import { ColorVariant } from 'src/store/models';
import getOppositeColor from 'src/utils/oppositeColor';
import FenHelper from 'src/store/gameStore/utils/helper';
import FenParser from 'src/store/gameStore/utils/parser';

const lastMoveIsUnAvailable = (
    moveFinished: boolean,
    history: string[],
    myColor: ColorVariant
) => {
    const parse = new FenParser(FenHelper);
    const leftColor = getOppositeColor(myColor);
    const moves = parse.diceMessagesFromHistory(
        history as any,
        leftColor,
        [],
        moveFinished
    );
    const lastMove = moves[moves.length - 1];
    if (!lastMove) return false;
    const allMoveAreUnAvailable = lastMove.pieces.every(
        (piece) => piece.moved && !piece.move
    );
    return allMoveAreUnAvailable;
};

export default lastMoveIsUnAvailable;
