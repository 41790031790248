import { map } from "rxjs/operators";
import { action, computed, makeAutoObservable, runInAction } from "mobx";
import { useHistory } from "react-router-dom";
import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";
import isEqual from "lodash/isEqual";
import { Nullable } from "src/shared/types";
import { errorService } from "src/service/services";
import throttle from "src/utils/throttle";

import paths from "src/consts/paths";

import {
  ColorVariant,
  GameResultData,
  GameStatus,
  IGame,
  UserBalanceType,
  IStickerRequest,
  GameModeType,
  ScreenType,
  IGameNew,
  EGameRematchStatus,
  IsocketGameData,
} from "../models";
import { getGameById } from "../../service/api/lobby";
import { sendMessageInGame } from "src/service/api/gameChat";
import { endpointsRoot } from "../../core/endpoints";
import { ISuccessSentMessage } from "src/service/api/gameChat/requestResponses";

import { rootStore } from "../../hooks/useStores";
import { RootStore } from "../index";

import {
  gameRoll,
  gameMove,
  gameBet,
  gameApplyBet,
  rematchPropose,
  rematchAccept,
  rematchDecline,
} from "src/service/api/game";

import {
  IClockPerPlayer,
  IConnectivityPerPlayer,
  IDoublingData,
  IGameMessage,
  IGameState,
  IGuest,
  IGuestNew,
  IInitGameData,
  INotification,
  IRematchInfo,
  INextRoundMatchData,
  IBaseGameDialogMessage,
  ERolledFigures,
  ERolledFiguresNumber,
  IConvertedRoleFigure,
  ICommunicationChatMessage,
  IRematchCollector,
  ICommunicationGameHistoryNew,
} from "./types";
import { extractFen, updateClocks } from "./utils";
import GameDialogs from "./dialogs";
import GameState from "./gameState";
import GameCommunicationState from "./communication";
import RightBlockCollectorState from "./rightBlockCollector";

import {
  IBetRequest,
  IMooveRequest,
  IRollRequest,
} from "src/service/api/game/requestResponses";
import { ISendMessageInGame } from "src/service/api/gameChat/requestResponses";
import ItemAvatar from "src/components/modals/ChangeAvatarModal/ItemAvatar";

export enum LoadingStatus {
  loading,
  loaded,
  notLoaded,
}

const defaultFen = "8/8/8/8/8/8/8/8";

export class GameStore {
  dialogs: GameDialogs = new GameDialogs(this);
  gameState: GameState = new GameState(this);
  communication: GameCommunicationState = new GameCommunicationState(this);
  rightBlockCollector: RightBlockCollectorState =
    new RightBlockCollectorState(this);
  rootStore: RootStore;
  socket: Socket | null;
  currentGame?: IGame;
  gameId?: string;
  joined: boolean = false;
  loadingStatus: LoadingStatus = LoadingStatus.loading;
  clock: IClockPerPlayer[] = [];
  connectivity: IConnectivityPerPlayer[] = [];
  state?: IGameState;
  initData?: IInitGameData;
  messages?: IGameMessage[] = [];
  notifications: INotification[] = [];
  rematch: IRematchInfo = {
    [ColorVariant.white]: null,
    [ColorVariant.black]: null,
  };
  // guests: IGuest[] = [];
  guests: any[] = [];
  gameIsStart: boolean = false;
  chessboardSize: number | null = null;
  piecesSize: number = 0;
  viewGameMode: boolean = false;
  newChatMessage: IBaseGameDialogMessage[] = [];
  nextRound: Nullable<INextRoundMatchData> = null;
  rollAwaiting: boolean = false;
  //======
  stateNew: IGameState = {
    fen: defaultFen,
    history: [],
    legalMoves: [],
    legalPieces: [],
    rolledFigures: undefined,
    movesHistory: [],
    undo: null,
  };
  currentGameNew?: IGameNew;

  get isReady() {
    return !!(
      this.initData &&
      this.connectivity &&
      this.state &&
      this.currentGame &&
      this.clock
    );
  }
  //===
  get isReadyNew() {
    return !!this.currentGameNew?._id;
  }

  get isLoaded() {
    return !!(
      this.initData &&
      this.state &&
      this.currentGame &&
      this.clock
    );
  }

  get isLoadedPreview() {
    return !!(this.stateNew.fen && this.currentGameNew?._id);
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setRematchInfo(rematchInfo: IRematchInfo) {
    // Для модалок в зависимости от инициатора для первого уровня - когда окончена игра
    this.rematch = rematchInfo;
    this.dialogs.refreshRematchDialogState();
  }

  @action
  setGuests(guests: IGuestNew[]) {
    this.guests = guests;
  }

  @action
  addGuest(guest: IGuestNew) {
    this.guests.push(guest);
  }

  @action
  removeGuest(guestId: IGuestNew["_id"]) {
    this.guests = this.guests.filter((guest) => guest._id !== guestId);
  }

  @action
  setDoubling(
    doublingData: IDoublingData,
    showOpponentAcceptedDoublingDialog: boolean = true
  ) {
    if (!this.initData) return;

    this.initData.doubling = doublingData;
    if (
      this.initData?.status === GameStatus.IN_PROGRESS &&
      doublingData &&
      doublingData.aggressor &&
      this.isParticipating()
    ) {
      if (
        doublingData.aggressor !== this.gameState.getMyColor() &&
        !doublingData.accepted
      ) {
        this.dialogs.showDoublingDialog();
      }
      if (
        doublingData.aggressor === this.gameState.getMyColor() &&
        doublingData.accepted &&
        showOpponentAcceptedDoublingDialog
      ) {
        this.dialogs.showOpponentAcceptedDoublingDialog();
        const tm = setTimeout(() => {
          this.dialogs.closeDoublingDialog();
          clearTimeout(tm);
        }, 1000);
      }
      if (
        doublingData.aggressor !== this.gameState.getMyColor() &&
        doublingData.accepted
      ) {
        this.dialogs.closeDoublingDialog();
      }
    } else if (
      this.initData?.status === GameStatus.IN_PROGRESS &&
      doublingData &&
      doublingData.aggressor &&
      this.isViewMode()
    ) {
      if (doublingData.accepted) {
        this.dialogs.showOpponentAcceptedDoublingDialog();
      } else {
        this.dialogs.showDoublingDialog();
      }
    } else {
      this.dialogs.closeDoublingDialog();
    }
  }

  @action
  setDraw(color: ColorVariant | null) {
    if (!this.initData) return;

    this.initData.drawingProposer = color;
    if (this.currentGame?.status === GameStatus.IN_PROGRESS && color) {
      if (color === this.gameState.getMyColor()) {
        this.dialogs.openWaitToDecideDrawDialog();
      } else {
        this.dialogs.openDecideDrawDialog();
      }
    } else {
      this.dialogs.closeDrawDialog();
    }
  }

  @action
  setOpponentRejectDraw(color: ColorVariant) {
    if (!this.initData) return;

    this.initData.drawingProposer = color;
    const isOpponentColor = color !== this.gameState.getMyColor();
    if (
      this.currentGame?.status === GameStatus.IN_PROGRESS &&
      color &&
      isOpponentColor
    ) {
      this.dialogs.openRejectedDrawDialog();
      const tm = setTimeout(() => {
        this.dialogs.closeDrawDialog();
        clearTimeout(tm);
      }, 1000);
    }
  }

  @action
  setNewChatMessage(message: IBaseGameDialogMessage | null) {
    if (!message) {
      return (this.newChatMessage = []);
    }
    this.newChatMessage.push(message);
  }

  @action
  setGameResult(resultData: GameResultData) {
    if (!this.initData) return;
    this.initData.over = resultData;
  }

  //
  @computed
  getMyColorNew() {
    const players = this.currentGameNew?.players;
    const isParticipateInGame =
      players &&
      players.some(
        (player) => player._id === rootStore.authStore.currentUser?._id
      );

    if (!isParticipateInGame) return ColorVariant.none;
    const myColor =
      this.currentGameNew?.players[0]._id ===
        rootStore.authStore.currentUser?._id
        ? ColorVariant.white
        : ColorVariant.black;
    return myColor;
  }

  @computed
  getOpponentColorNew() {
    const players = this.currentGameNew?.players;
    if (!players) return ColorVariant.white;
    const isParticipateInGame =
      players &&
      players.some(
        (player) => player._id === rootStore.authStore.currentUser?._id
      );
    if (!isParticipateInGame) return ColorVariant.none;
    const myColor =
      this.currentGameNew?.players[0]._id ===
        rootStore.authStore.currentUser?._id
        ? ColorVariant.black
        : ColorVariant.white;
    return myColor;
  }

  @computed
  getRolledDiceColorNew(fen: string) {
    const players = this.currentGameNew?.players;
    if (!players) return ColorVariant.none;
    const emptyRoll = "- -";
    if (fen === emptyRoll) {
      return this.isMyMove
        ? this.getOpponentColorNew()
        : this.getMyColorNew();
    }
    return this.currentGameNew?.players[0]._id ===
      this.currentGameNew?.curMove
      ? ColorVariant.white
      : ColorVariant.black;
  }

  @computed
  getClockByColor(color: ColorVariant) {
    return this.clock.find(
      (item) => item.color === color
    ) as IClockPerPlayer;
  }

  @action
  setClock(newClock: [IClockPerPlayer, IClockPerPlayer]) {
    this.clock = newClock;
  }

  @action
  setConnectivity(
    newConnectivity: [IConnectivityPerPlayer, IConnectivityPerPlayer]
  ) {
    this.connectivity = newConnectivity;
  }

  @action
  setGameState(state: IGameState) {
    this.state = state;
  }

  // @action
  // setKilledPiecesState(state: IGameState) {
  //     this.state = state;
  //     //this.gameState.rolled = false;
  // }

  move(move: string) {
    this.socket?.emit("move", move);
    this.gameState.resetMove();
  }

  @action
  roll() {
    this.socket?.emit("roll");
    this.gameState.diceRnd = Math.random().toString();
    this.gameState.rolled = true;
  }

  //=========
  @action
  async rollNew(gameId: string) {
    if (this.rollAwaiting) return;
    try {
      this.rollAwaiting = true;
      const res = await gameRoll({ gameId });
      runInAction(() => {
        this.gameState.diceRnd = Math.random().toString();
        this.gameState.rolled = true;
      })

      if ("success" in res.data) {
        console.log("Roll Response");
      }
    } catch (e) {
      return errorService.sendError(
        e.response?.data?.message || "Roll error"
      );
    } finally {
      // this.rollAwaiting = false;
    }
  }

  @action
  resetRollAwaiting() {
      this.rollAwaiting = false;
  }

  @action
  async rematchPropose(gameId?: string) {
    if (!gameId) return;
    try {
      const res = await rematchPropose({ gameId });
      if ("success" in res.data) {
        console.log("Propose Response");
      }
    } catch (e) {
      return errorService.sendError(
        e.response?.data?.message || "rematchPropose error"
      );
    }
  }

  @action
  async rematchAccept(gameId?: string) {
    if (!gameId) return;
    try {
      const res = await rematchAccept({ gameId });
      if ("success" in res.data) {
        console.log("Rematch Accepts Response");
      }
    } catch (e) {
      return errorService.sendError(
        e.response?.data?.message || "rematchAccept error"
      );
    }
  }

  @action
  async rematchDecline(gameId?: string) {
    if (!gameId) return;
    try {
      const res = await rematchDecline({ gameId });
      if ("success" in res.data) {
        console.log("Decline Rematch Response");
      }
    } catch (e) {
      return errorService.sendError(
        e.response?.data?.message || "rematchDecline error"
      );
    }
  }

  @action async moveNew(gameId: IMooveRequest) {
    try {
      const res = await gameMove(gameId);
      this.gameState.resetMove();
      if ("success" in res.data) {
      }
    } catch (e) {
      return errorService.sendError(
        e.response?.data?.message || "Move error"
      );
    }
  }

  @action async gameBet(gameId: IBetRequest) {
    try {
      const res = await gameBet(gameId);
      this.gameState.resetMove();
      if ("success" in res.data) {
      }
    } catch (e) {
      return errorService.sendError(
        e.response?.data?.message || "Move error"
      );
    }
  }
  @action async gameBetNew(body: IBetRequest) {
    try {
      const res = await gameBet(body);
      this.gameState.resetMove();
      if ("success" in res.data) {
        console.log("Bet Response");
        // this.dialogs.showOpponentAcceptedDoublingDialogNew();
      }
    } catch (e) {
      return errorService.sendError(
        e.response?.data?.message || "Move error"
      );
    }
  }

  get isMyGame() {
    return (
      this.currentGame?.players[0].id ===
      rootStore.authStore.currentUser?._id
    );
  }

  @action
  setGameId(gameId: string) {
    if (this.gameId === gameId) return;
    this.gameId = gameId;
    if (this.initData !== undefined) {
      this.initData = undefined;
      this.currentGame = undefined;
    }
    this.rematch = {
      [ColorVariant.white]: null,
      [ColorVariant.black]: null,
    };
    this.dialogs = new GameDialogs(this);
    this.gameState = new GameState(this);
    this.setup();
    this.rootStore.gamesStore.addGameStore(this.gameId, this);
  }

  @action
  setGameIdNew(gameId: string) {
    if (this.gameId === gameId) return;
    this.gameId = gameId;
    this.dialogs = new GameDialogs(this);
    this.gameState = new GameState(this);
    this.setUpEventListenerNew();
    this.rootStore.gamesStore.addGameStore(this.gameId, this);
  }

  // TODO: delete this method, this method is identical to setGameId, follow the DRY principle
  @action
  setGameIdMobileTab(gameId: string) {
    this.gameId = gameId;
    if (this.initData !== undefined) {
      this.initData = undefined;
      this.currentGame = undefined;
    }
    this.rematch = {
      [ColorVariant.white]: null,
      [ColorVariant.black]: null,
    };
    this.dialogs = new GameDialogs(this);
    this.gameState = new GameState(this);
    this.setup();
    this.rootStore.gamesStore.addGameStore(this.gameId, this);
  }

  @action
  resetGameId() {
    this.gameId = undefined;
  }

  @action
  initGameBegin(status: boolean) {
    this.gameIsStart = status;
  }

  @action
  setChessboardSize(size: number | null) {
    this.chessboardSize = size;
  }

  @action
  setPiecesSize(size: number, screenType: ScreenType) {
    let maxTabletDiceSize = 120;
    switch (screenType) {
      case ScreenType.mobile || ScreenType.tablet:
        return (this.piecesSize = Math.min(maxTabletDiceSize, size));
      case ScreenType.desktop:
        return (this.piecesSize = size);
      case ScreenType.multitable:
        return (this.piecesSize = size);
      default:
        this.piecesSize = size;
    }
  }

  @action
  setGame(game?: IGame) {
    this.currentGame = game;
    this.gameId = game?.id;
    // rootStore.lobbyStore.setLobbyMode(
    //     game?.balanceType || UserBalanceType.play
    // );
  }

  @action
  resetGame() {
    this.currentGame = undefined;
    this.gameId = undefined;
  }

  @action
  setInitData(initData: IInitGameData) {
    this.initData = initData;
    this.setConnectivity(initData.connectivity);
    this.setGameState(initData.state);
    this.setClock(initData.clock);
    this.setDoubling(initData.doubling, false);
    this.setDraw(initData.drawingProposer);
    this.setRematchInfo(initData.rematch);
    // this.setGuests(initData.guests);
    this.gameState.rolled = false;
  }

  async setup() {
    // await this.loadGame();
    this.setUpEventListener();
  }

  //======

  async loadGame() {
    runInAction(() => {
      this.loadingStatus = LoadingStatus.loading;
    });
    const gameId = this.gameId;
    if (!gameId) return;
    const res = await getGameById({ gameId });
    if (res.status === 200 && !res.data.error) {
      this.setGame(res.data);
      runInAction(() => {
        this.loadingStatus = LoadingStatus.loaded;
        if (this.currentGame?.type === GameModeType.TOURNAMENT) {
          this.rootStore.tournamentsStore.setCurrentTournamentGame(
            res.data
          );
        }
      });
    } else {
      runInAction(() => {
        this.loadingStatus = LoadingStatus.notLoaded;
      });
    }
  }

  addMessage(messages: IGameMessage[]) {
    this.messages = [...messages];
  }

  sendMessage(message: string | IStickerRequest) {
    this.socket?.emit("chat:message", message);
  }
  //
  @action async sendMessageNew(message: ISendMessageInGame) {
    try {
      const res = await sendMessageInGame(message);
      const data = res.data;
      if ("success" in data) {
        console.log("Успешно");
      }
    } catch (e) {
      return e.response?.data?.message || "Send error";
    }
  }

  setShowDrawDialog() {
    this.socket?.emit("standoff");
  }

  showState(state: any) { }

  refreshBalance() {
    if (!this.socket) return;
    this.socket.emit("balance");
  }

  /**
   * Initializes the game socket connection.
   *
   * This method creates a socket connection to the game server using the provided game ID and user token.
   * It sets up the necessary configurations for the socket connection, such as the query parameters, transports, and reconnection settings.
   *
   * @returns {Socket | null} The socket object if the connection is successful, or null if there is an error.
   * @throws {Error} If the game ID, user token, or host is missing.
   */
  @action async initGameSocket() {
    this.clearSocket();
    const token = this.rootStore.authStore.getAuthToken();
    const gameForSearch = location.href.split("/").slice(-1)[0];

    if (!this.gameId) {
      runInAction( () => {
        this.gameId = gameForSearch;
      })
    }
    const gameId = this.gameId;
    const host = process.env.REACT_APP_API_ENDPOINT;

    const createQuery = !!token
      ? {
        gameId: gameId,
        userKey: token,
        place: 4,
      }
      : {
        gameId: gameId,
        place: 4,
      };

    if (!gameId || !host) {
      window.location.href = paths.diceChessLobby;
      errorService.sendError("Problem with auth or path to game");
      // throw new Error("Missing: gameId, token, or host");
    }
    try {
      const socket = io(`${host}/game`, {
        query: createQuery,
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 2000,
      });
      socket.on("connect", () => { });
      socket.on("connect_error", (error) => { });
      socket.on("error", (error) => { });
      socket.on("disconnect", (reason) => { });
      return socket;
    } catch (error) {
      console.error("Socket connection error:", error);
      return null;
    }
  }

  @action rolledConvert(
    rolledFigures: [ERolledFigures, ERolledFigures, ERolledFigures]
  ): IConvertedRoleFigure[] {
    if (!rolledFigures || rolledFigures.length < 3) {
      return [
        {
          figure: ERolledFiguresNumber.p,
          active: false,
          id: 1,
        },
        {
          figure: ERolledFiguresNumber.p,
          active: false,
          id: 2,
        },
        {
          figure: ERolledFiguresNumber.p,
          active: false,
          id: 3,
        },
      ];
    }
    const converted = rolledFigures.map((item, index) => {
      switch (item) {
        case ERolledFigures.p:
          return {
            figure: ERolledFiguresNumber.p,
            active: false,
            id: index,
          };
        case ERolledFigures.n:
          return {
            figure: ERolledFiguresNumber.n,
            active: false,
            id: index,
          };
        case ERolledFigures.b:
          return {
            figure: ERolledFiguresNumber.b,
            active: false,
            id: index,
          };
        case ERolledFigures.r:
          return {
            figure: ERolledFiguresNumber.r,
            active: false,
            id: index,
          };
        case ERolledFigures.q:
          return {
            figure: ERolledFiguresNumber.q,
            active: false,
            id: index,
          };
        case ERolledFigures.k:
          return {
            figure: ERolledFiguresNumber.k,
            active: false,
            id: index,
          };
        default:
          return {
            figure: ERolledFiguresNumber.p,
            active: false,
            id: index,
          };
      }
    });
    return converted;
  }

  @action rolledConvertNumber(
    rolledFigures: [ERolledFigures, ERolledFigures, ERolledFigures]
  ) {
    if (!rolledFigures) return;
    const converted = rolledFigures.map((item, index) => {
      switch (item) {
        case ERolledFigures.p:
          return ERolledFiguresNumber.p;
        case ERolledFigures.n:
          return ERolledFiguresNumber.n;
        case ERolledFigures.b:
          return ERolledFiguresNumber.b;
        case ERolledFigures.r:
          return ERolledFiguresNumber.r;
        case ERolledFigures.q:
          return ERolledFiguresNumber.q;
        case ERolledFigures.k:
          return ERolledFiguresNumber.k;
      }
    });
    return converted;
  }
  //=======

  @action async setUpEventListenerNew() {
    runInAction(() => {
      this.loadingStatus = LoadingStatus.loading;
    })
    let previousMovesHistoryLength = 0;
    this.clearSocket();
    this.socket = await this.initGameSocket();

    if (this.socket) {
      this.dialogs.setSocket(this.socket);
      const _ = require("lodash");
      // this.loadingStatus = LoadingStatus.loaded;
      this.socket.on("game-state", (data: IsocketGameData) => {
        // console.log("game-state", data);

        runInAction(() => {
          const dataForGameState = {
            fen: data.fen,
            legalMoves: data.availableMoves,
            legalPieces: data.activeFigures,
            undo: null,
            history: data.history,
            movesHistory: data.movesHistory,
            rolledFigures: this.rolledConvert(data.rollFigures),
          };

          const existFen = this.stateNew.fen;
          const existRolledFigures = this.stateNew.rolledFigures;
          const existLegalPieces = this.stateNew.legalPieces;
          const existGameId = this.currentGameNew?._id;
          const existCurrentMove = this.currentGameNew?.curMove;
          const existExtra = this.currentGameNew?.extra;
          const existGameStatus = this.currentGameNew?.state;
          const existWinner = this.currentGameNew?.winner;
          const existBank = this.currentGameNew?.bank;
          const existCurrentPlayerCanX2 =
            this.currentGameNew?.currentPlayerCanX2;
          const existNextMatch = this.currentGameNew?.nextMatch;
          const existMatchId = this.currentGameNew?.matchId;
          const existMatchId2 = this.currentGameNew?.matchId;

          const requiredUpdate = !(
            _.isEqual(dataForGameState.fen, existFen) &&
            _.isEqual(
              dataForGameState.rolledFigures,
              existRolledFigures
            ) &&
            _.isEqual(
              dataForGameState.legalPieces,
              existLegalPieces
            ) &&
            _.isEqual(data._id, existGameId) &&
            _.isEqual(data.curMove, existCurrentMove) &&
            _.isEqual(data.extra, existExtra) &&
            _.isEqual(data.state, existGameStatus) &&
            _.isEqual(data.winner, existWinner) &&
            _.isEqual(
              data.currentPlayerCanX2,
              existCurrentPlayerCanX2
            ) &&
            _.isEqual(data.bank, existBank) &&
            _.isEqual(data.nextMatch, existNextMatch) &&
            _.isEqual(data.matchId, existMatchId) &&
            _.isEqual(data.matchId2, existMatchId2)
          );

          if (requiredUpdate) {
            this.stateNew = dataForGameState;
            this.currentGameNew = data;
          }
        });

        runInAction(() => {
          // Logical for get Messages
          const requiredUpdate = !_.isEqual(
            this.communication.messages,
            data.messages
          );

          if (requiredUpdate) {
            console.log("Communication Log");
            this.communication.messages =
              data.messages as ICommunicationChatMessage[];
          }
        });

        runInAction(() => {
          // Logical for get Viewers
          const requiredUpdate = !_.isEqual(
            this.guests,
            data.viewers
          );
          if (requiredUpdate) {
            this.guests = data.viewers as IGuestNew[];
          }
        });

        runInAction(() => {
          if (
            data.movesHistory &&
            data.movesHistory.length === 0 &&
            data.matchCount
          ) {
            previousMovesHistoryLength = 0;
            this.communication.history = [];
          }
          if (
            data.movesHistory &&
            data.movesHistory.length &&
            data.movesHistory.length > previousMovesHistoryLength
          ) {
            previousMovesHistoryLength = data.movesHistory.length;
            const movesHistory = data?.movesHistory;
            const me = this.rootStore?.authStore?.currentUser?._id;
            const processDataArray = (
              movesHistory
            ): ICommunicationGameHistoryNew[] => {
              const result: ICommunicationGameHistoryNew[] = [];
              let currentFigure = null;

              for (const item of movesHistory) {
                if (item.figures) {
                  if (currentFigure) {
                    result.push(currentFigure);
                  }
                  //@ts-ignore
                  currentFigure = {
                    time: Date.parse(item.date),
                    figures: item.figures.split(""),
                    pieces: [],
                    position:
                      item.user === me ? "rtl" : "ltr",
                    color:
                      item.user === me
                        ? this.getMyColorNew()
                        : this.getOpponentColorNew(),
                  };
                } else if (currentFigure) {
                  const piece = {
                    dice: 1,
                    type: item.moveFigure,
                    moved: false,
                    move: item.move,
                  };
                  //@ts-ignore
                  currentFigure.pieces.push(piece);
                } else {
                  //@ts-ignore
                  result.push(item);
                }
              }
              if (currentFigure) {
                result.push(currentFigure);
              }
              return result;
            };

            this.communication.history = [
              ...processDataArray(movesHistory),
            ];
          }
        });
        runInAction(() => {
          //Rematch Logical
          const rematchData = {
            rematchStatus: data.rematchStatus,
            rematchStartedBy: data.rematchStartedBy,
            matchId: data.matchId,
            matchCount: data.matchCount,
            nextMatch: data.nextMatch,
            gameDieTime: data.gameDieTime,
            rematchEnd: data.rematchEnd,
          };
          const existRematchData = this.dialogs.rematchData;
          const requiredUpdate = !_.isEqual(
            existRematchData,
            rematchData
          );
          if (requiredUpdate) {
            console.log("Rematch Log");
            this.dialogs.rematchData =
              rematchData as IRematchCollector;
          }
        });

        runInAction(() => {
          // Logical for Clock update
          const existClock = this.rightBlockCollector?.clockData;
          const existTimerActiveId = this.rightBlockCollector.activeTimerId;
          const clockData = data.timeLeft;
          const activeIdData = data.curMove;
          const matchExist1 =
            this.rightBlockCollector?.matchCounter.itemAlpha;
          const matchExist2 =
            this.rightBlockCollector?.matchCounter.itemBeta;

          const requiredUpdate = !_.isEqual(existClock, clockData);
          const requiredUpdateMove = !_.isEqual(existTimerActiveId, activeIdData)
          const initTime = 0;

          const updateClock = (time: number[]) => {
            this.rightBlockCollector.clockData = time as number[];
          };
          const updateMatchCounter = () => {
            this.rightBlockCollector.matchCounter = {
              itemAlpha: data?.matchId,
              itemBeta: data?.matchId2,
            };
          };

          const requireMatchUpdate =
            matchExist1 !== data?.matchId ||
            matchExist2 !== data?.matchId2;

          const isInitTime =
            existClock[0] === initTime &&
            existClock[1] === initTime;

            if (activeIdData !== existTimerActiveId) {
              this.rightBlockCollector.activeTimerId = activeIdData
            }


          if (requiredUpdate && isInitTime && !data.winner) {
            
            updateMatchCounter();
            updateClock(clockData);
          }
          // const throttle =
          //   existClock[0] - clockData[0] > updateTime ||
          //   existClock[1] - clockData[1] > updateTime;

          if (requiredUpdate && requireMatchUpdate && !data.winner) {
         
            updateClock(clockData);
            updateMatchCounter();
          }
      

          if (requiredUpdateMove && !data.winner) {
          
            updateClock(clockData);
          }
        });
        runInAction(() => {
          //Dialog Logical
          // console.log(data);
        });
      });
      this.socket.emit("init");
    }
  }

  @action
  setUpEventListener() {
    this.clearSocket();
    const game = this.currentGame;
    if (!game || game.status === GameStatus.FINISHED) return;
    this.socket = io(
      `${endpointsRoot}/game/${game.id}`,
      this.socketOptions()
    );
    this.dialogs.setSocket(this.socket);

    this.socket.on("joined", () => {
      runInAction(() => {
        this.joined = true;
        this.refreshBalance();
      });
    });

    this.socket.on("chat:dialog", (messages: IGameMessage[]) => {
      this.addMessage(messages);
    });

    this.socket.on(
      "chat:dialog:message",
      (message: IBaseGameDialogMessage) => {
        this.setNewChatMessage(message);
      }
    );

    this.socket.on("game:join:cancelled", () => {
      runInAction(() => {
        this.joined = false;
        this.refreshBalance();
      });
    });

    this.socket.on("status", (status: GameStatus) => {
      runInAction(() => {
        game.status = status;
        if (status === GameStatus.FINISHED) {
          runInAction(() => {
            if (!this.state) return;
            this.state.legalMoves = [];
          });
        }
      });
    });

    this.socket.on(
      "connectivity",
      (data: [IConnectivityPerPlayer, IConnectivityPerPlayer]) => {
        this.setConnectivity(data);
      }
    );

    this.socket.on("init", (data: IInitGameData) => {
      // @ts-ignore
      if (data.players.length === 1) {
        // this.setUpEventListener();
        // return;
      } else if (data.status === GameStatus.NOT_READY) {
        const tm = setTimeout(() => {
          this.socket?.emit("init");
          clearTimeout(tm);
        }, 500);
        return;
      }
      this.setInitData(data);
    });

    this.socket.on("players", (players) => {
      runInAction(() => {
        game.players = players;
      });
    });

    this.socket.on(
      "balance",
      (data: {
        [UserBalanceType.play]: number;
        [UserBalanceType.coins]: number;
        [UserBalanceType.referralBonus]: number;
      }) => {
        runInAction(() => {
          this.rootStore.authStore.updateBalance(data);
        });
      }
    );

    this.socket.on("error", function (error) {
      errorService.sendError(error);
    });

    this.socket.on("doubling", (doublingData: IDoublingData) => {
      if (doublingData && !doublingData.aggressor) return;
      this.setDoubling(doublingData);
      this.refreshBalance();
    });

    this.socket.on(
      "doubleDoneUpdate",
      (doublingUpdateData: {
        doubling: IDoublingData;
        clocks: [IClockPerPlayer, IClockPerPlayer];
      }) => {
        console.log("doubleDoneUpdate");
        const doublingData = doublingUpdateData.doubling;
        if (doublingData && doublingData.aggressor) {
          this.setDoubling(doublingData);
          this.refreshBalance();
        }
        updateClocks(this, doublingUpdateData.clocks);
      }
    );

    this.socket.on("clock", (data: [IClockPerPlayer, IClockPerPlayer]) => {
      // console.log('clock');
      if (!this.gameState) return;
      updateClocks(this, data);
    });

    this.socket.on("over", (data: GameResultData) => {
      // console.log('state');
      this.setGameResult(data);
      this.loadGame();
      this.refreshBalance();
    });

    this.socket.on("state", (state: IGameState) => {
      this.setGameState(state);
      runInAction(() => {
        if (!this.initData || !this.state) return;
        this.initData.history.push(this.state.fen);
      });
    });

    this.socket.on("nextRound", (round: INextRoundMatchData) => {
      runInAction(() => {
        this.nextRound = round;
      });
    });

    this.socket.on(
      "moveUpdate",
      (data: {
        state: IGameState;
        clocks: [IClockPerPlayer, IClockPerPlayer];
      }) => {
        this.setGameState(data.state);

        runInAction(() => {
          if (!this.initData || !this.state) return;
          this.initData.history.push(this.state.fen);
        });

        updateClocks(this, data.clocks);
      }
    );

    this.socket.on("standoff-deny", (color) => {
      this.setOpponentRejectDraw(color);
    });
    this.socket.on("standoff", (color) => {
      this.setDraw(color);
    });

    this.socket.on("rematch", (data: IRematchInfo) => {
      if (!data) return;
      this.setRematchInfo(data);
      this.refreshBalance();
    });

    this.socket.on("guest:connected", (guest: IGuestNew) => {
      this.addGuest(guest);
    });

    this.socket.on("guest:disconnected", (guestId: IGuestNew["_id"]) => {
      this.removeGuest(guestId);
    });

    this.socket.on("notifications", (notifications: INotification[]) => {
      runInAction(() => {
        this.notifications = notifications;
      });
    });

    this.socket.on("connect_error", () => {
      //alert(`Передай Антону, что случилось game connect_error`)
    });
    this.socket.on("reconnect_error", () => {
      //alert(`Передай Антону, что случилось game reconnect_error`)
    });
    this.socket.on("reconnect", () => {
      //alert(`Передай Антону, что случилось game reconnect`)
    });
    this.socket.emit("init");
    //==========
    this.socket.on("state", () => {
      //alert(`Передай Антону, что случилось game reconnect`)
    });
  }

  clearSocket() {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.socket = null;
    }
  }

  join() {
    this.socket?.emit("join");
  }

  @action
  cancelJoin() {
    this.socket?.emit("join:canceled");
  }

  @action
  reset() {
    this.gameId = undefined;
    if (this.currentGame?.id) {
      this.rootStore.gamesStore.removeGameStoreById(this.currentGame?.id);
    }
    this.currentGame = undefined;
    this.clearSocket();
    this.joined = false;
  }

  @computed
  get fen() {
    if (!this.state) return defaultFen;
    return extractFen(this.state.fen);
  }

  @computed
  get getGameID() {
    return this.currentGameNew?._id;
  }

  @computed
  get getGamePlayers() {
    return this.currentGameNew?.players;
  }

  @computed
  get getGameType() {
    return this.currentGameNew?.type;
  }

  @computed
  get gameSettings() {
    return this.initData?.settings;
  }

  @computed
  isParticipating() {
    const user = this.currentGame?.players.find((item) => {
      return item.id === rootStore.authStore.currentUser?._id;
    });
    return !!user;
  }

  @computed
  isViewMode() {
    const getMode = this.getMyColorNew();
    switch (getMode) {
      case ColorVariant.white:
        runInAction(() => {
          this.viewGameMode = false;
        });
        return false;
      case ColorVariant.black:
        runInAction(() => {
          this.viewGameMode = false;
        });
        return false;
      case ColorVariant.none:
        runInAction(() => {
          this.viewGameMode = true;
        });
        return true;
      default:
        false;
    }
  }

  @computed
  getScores() {
    // TODO: refactor
    const player1Color = this.currentGame?.players[0].color!;
    const player2Color = this.currentGame?.players[1]?.color!;
    let player1Score =
      this.gameState.getPlayerByColor(player1Color)?.score || 0;
    let player2Score =
      this.gameState.getPlayerByColor(player2Color)?.score || 0;

    if (this.initData?.over) {
      const winner = this.gameState.getPlayerByColor(
        this.initData.over.winner
      );
      if (winner) {
        if (winner.color === player1Color) {
          player1Score = player1Score + 1;
        } else {
          player2Score = player2Score + 1;
        }
      }
    }

    return [player1Score, player2Score];
  }

  // @computed
  // get isShowWinOrLostDialog() {
  //     return !this.dialogs.rematchDialog.isOpen && this.gameState.isOver;
  // }

  @computed
  get isShowWinOrLostDialog() {
    return this.gameState.isOver;
  }

  @computed
  get gameStatus() {
    return this.currentGame?.status;
  }

  // @computed
  // get gameBalanceType() {
  //     return this.currentGame?.balanceType;
  // }

  @computed
  get gameBalanceType() {
    return this.currentGameNew?.coinStatus;
  }

  @computed
  get dialogsAreHide() {
    return (
      !this.dialogs.selectFiguresDialog.isOpen &&
      !this.dialogs.drawDialog.isOpen &&
      !this.dialogs.doublingDialog.isOpen &&
      !this.dialogs.rematchDialog.isOpen
    );
  }

  @computed
  get getShowWatchersBlock() {
    return this.dialogsAreHide && this.isShowWinOrLostDialog;
  }
  //===
  @computed
  get isMyMove() {
    if (!this.viewGameMode) {
      return (
        this.currentGameNew?.curMove ===
        this.rootStore.authStore.currentUser?._id
      );
    }
    if (this.currentGameNew?.players) {
      // letsfind Users position in Players array
      const getViewUserPosition = this.currentGameNew?.players.findIndex(
        (player) => {
          player._id === this.currentGameNew?.curMove;
        }
      );
      // if getViewUserPosition === 0, return !!getViewUserPosition(false) othervise return true
      return !!getViewUserPosition;
    }
    return false;
  }

  @action
  isActiveViewPlayer(playerId: string) {
    return playerId === this.currentGameNew?.curMove ? true : false;
  }

  @computed
  get isCreator() {
    if (!this.viewGameMode) {
      // If User first - return 0, otherwise return 1
      const isEquMyId = (element) =>
        element._id === this.rootStore.authStore.currentUser?._id;
      const getUserPosition =
        this.currentGameNew?.players?.findIndex(isEquMyId);
      return getUserPosition || 0;
    }
    return 0;
  }

  socketOptions() {
    const gameForSearch = location.href.split("/").slice(-1)[0];
    if (!this.gameId) {
      this.gameId = gameForSearch;
    }

    return {
      withCredentials: true,
      //forceNew: true,
      query: {
        gameId: this.gameId,
        userKey: this.rootStore.authStore.getAuthToken(),
        place: 5,
      },
      //transports: ['ws', 'polling'],
    } as Partial<ManagerOptions & SocketOptions>;
  }
}

export default GameStore;
