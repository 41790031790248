import React from 'react';
import { observer } from 'mobx-react';

import { useLocale } from 'src/providers/LocaleProvider';

import useStores from '../../../../hooks/useStores';
import GeneralGameDialogNew from '../common/generalDialogNew';

const ConfirmDrawOfferingDialog = () => {
    const {
        game: {
            dialogs: {
                draw: {
                    offerDraw: { confirm, cancel, title, message },
                },
            },
        },
    } = useLocale();
    const { gameStore } = useStores();
    const player = gameStore.gameState.getCurrentPlayer();
    if (!player) return null;

    const onApprove = () => {
        gameStore.dialogs.offerDraw();
    };
    const onCancel = () => {
        gameStore.dialogs.cancelDrawOffering();
    };

    return (
        <GeneralGameDialogNew
            avatar={player?.avatar}
            noButtons={false}
            onApprove={onApprove}
            onCancel={onCancel}
            title={title('You are about to offer a draw')}
            message={message('Are you sure?')}
            approveText={confirm('Yes')}
            cancelText={cancel("No, I'll finish")}
            isSystemAvatar={player.isSystemAvatar}
        />
    );
};

export default observer(ConfirmDrawOfferingDialog);
