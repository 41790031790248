import React, {useCallback, useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import classNames from "classnames";
import {Link, useHistory} from "react-router-dom";
import {ThemeProvider} from "@mui/styles";
import {Box, createTheme, Dialog, FormControl, Grid, OutlinedInput,} from "@mui/material";
import _get from "lodash/get";
import {observer} from "mobx-react";

import LocaleText from "src/components/locale-text/locale.text";
import {useLocale} from "src/providers/LocaleProvider";

import {errorService} from "../../service/services";
import {useStyles} from "../styles/styles.js";

import {useStyles as useMuiStyles} from "./styles";

import "./style.css";

import closeIcon from "src/assets/icons/x.svg";
import {useModal} from "src/pages/landing/utils/context";
import paths from "src/consts/paths";

import useStores from "../../hooks/useStores";
import {ButtonType, GreenButton} from "../../components/buttons/greenButton";
import {authPhone, authPhoneCode} from "../../service/api/auth";

const theme = createTheme({
    palette: {
        primary: {
            main: "#5B627E",
            dark: "#fff",
            contrastText: "#5B627E",
            light: "#5B627E",
        },
    },
});

const PhoneAuth = () => {
    const [remember, setRemember] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isphoneHidden, setVisiblephone] = useState(true);
    const inputEl = useRef<any>(null);
    const { authStore } = useStores();
    const muiStyles = useMuiStyles();
    const { closeModal, openModal } = useModal();
    const [ phoneVal, setPhoneVal ] = useState('');
    const [ codeVal, setCodeVal ] = useState('');
    const [ state, setState ] = useState(0);

    // состояния для таймера
    const [timer, setTimer] = useState(0);
    const [isTimerActive, setIsTimerActive] = useState(false);


    // Функция для запуска таймера
    const startTimer = useCallback(() => {
        console.log("startTimer called");
        setTimer(60);
        setIsTimerActive(true);
    }, []);

    // Эффект для управления таймером
    useEffect(() => {
        console.log("Timer effect. isTimerActive:", isTimerActive, "timer:", timer);
        let interval: ReturnType<typeof setInterval> | null = null;

        if (isTimerActive && timer > 0) {
            console.log("Starting interval");
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    console.log("Updating timer:", prevTimer - 1);
                    return prevTimer - 1;
                });
            }, 1000);
        } else if (timer === 0) {
            console.log("Timer reached 0, deactivating");
            setIsTimerActive(false);
            setState(0); // Возвращаемся к вводу номера телефона
            setCodeVal(''); // Очищаем поле ввода кода
        }

        return () => {
            if (interval) {
                console.log("Clearing interval");
                clearInterval(interval);
            }
        };
    }, [isTimerActive, timer]);

    // для форматирования текста таймера
    const formatTimerText = useCallback((seconds) => {
        return `Resend in: ${seconds} sec`;
    }, []);

    // Эффект для отслеживания изменений таймера
    useEffect(() => {
        console.log("Timer value changed:", timer);
    }, [timer]);

    // const fingerprintData = useVisitorData();

    const onButtonClick = (e) => {
        e.preventDefault();
        const el = inputEl.current;
        if (!el) return;
        const input = el.querySelector("#phone");
        if (!input) return;
        if (isphoneHidden) {
            setVisiblephone(false);
            input.type = "text";
        } else {
            setVisiblephone(true);
            input.type = "phone";
        }
    };
    // useEffect(() => {
    //     console.log(fingerprintData);
    // }, [fingerprintData.isLoading]);

    useEffect(() => {
        if (authStore.isAuthorized) history.push(paths.lobby);
    }, [authStore.isAuthorized]);

    const btnStyle = classNames({
        hidephoneBtn: true,
        hidephoneBtnClosed: isphoneHidden,
        hidephoneBtnOpen: !isphoneHidden,
    });

    const history = useHistory();
    const classes = useStyles();

    const {
        phoneAuth: {
            errors: {
                useJustEnglishLetters,
                tooLongphone,
                enterThephone,
            },
            form: { phone, code },
            loginButton,
            dontHaveAccount,
            signUp,
            timerText,
        },
    } = useLocale();

    const locales = useLocale();

    const handleChangeLanguage = async (language) => {
        if (language == null) return;
        authStore.changeLanguage(language);
        await authStore.updateGameSettings({
            language: language,
        });
    };

    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .trim()
            .matches(
                /[a-zA-Z\d\s|!#$%^&*()_}{"'`~+,-\[\]]/g,
                useJustEnglishLetters("Используйте только английские буквы!")
            )
            .max(20, tooLongphone("Код очень длинный (макс. 20 символа)!"))
            .required(enterThephone("Введите код")),
        code: Yup.string()
            .trim()
            .test('is-not-empty', enterThephone("Введите код"), function (value) {
                // Only validate if the field has been touched and has a value
                return !this.parent.codeTouched || (!!value && value.length > 0);
            })
            .matches(
                /[a-zA-Z\d\s|!#$%^&*()_}{"'`~+,-\[\]]/g,
                useJustEnglishLetters("Используйте только английские буквы!")
            )
            .max(20, tooLongphone("Код очень длинный (макс. 20 символа)!")),
    });

const onSubmitForm = async (values: { phone: string; code?: string }) => {
        setLoading(true);
        try {
            // const error = await authStore.signIn(
            //     email,
            //     phone,
            //     authStore.selectedLanguage || ELanguages.EN,
            //     remember
            // );
            // if (error) {
            //     setError(true);
            //     errorService.sendError(error);
            // } else {
            //     await authStore.getMeNew();
            //     history.push(paths.lobby);
            //     closeModal("login");
            //     return;
            // }
            // После успешной отправки запускаем таймер
            startTimer();
            return true;
        } catch (err) {
            const error = err?.response?.data?.error;
            if (error) {
                errorService.sendError(
                    _get(
                        locales,
                        error.locale?.label
                    )(error.locale.defaultValue || "")
                );
            }
            return false;
        } finally {
            setLoading(false);
        }
    };

    if (authStore.isAuthorized) {
        return null;
    }

    const handleSuccessfulLogin = () => {
        closeModal('phone-auth');
        history.push(paths.jackpoker);
    };

    const handleSubmitProvider = async (eve) => {
        eve.preventDefault();

        if (state === 0) {
            setState(1);
        } else if (state === 1) {
            try {
                await authPhone({ phone: phoneVal });
                startTimer();
                setState(2);
            } catch (error) {
                console.error("Ошибка при отправке номера телефона:", error);
            }
        } else if (state === 2) {
            try {
                await authStore.signPhoneCode({
                    phone: phoneVal,
                    code: codeVal,
                });
                await authStore.getMeNew();
                history.push(paths.lobby);
                closeModal('phone-auth');
            } catch (error) {
                console.error("Ошибка при отправке кода:", error);
            }
        }
    };



    return (
        <Dialog
            open
            className={muiStyles.container}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeModal('phone-auth');
                }
            }}
            disableEscapeKeyDown
            PaperProps={{ className: muiStyles.dialogPaper }}
        >
            <Box component="div" className={muiStyles.formContainer}>
                <ThemeProvider theme={theme}>
                    <Formik
                        initialValues={{ phone: "", code: "" }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            const success = await onSubmitForm(values);
                            setSubmitting(false);
                            if (success) {
                                closeModal('phone-auth');
                            }
                        }}
                        render={({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                        }) => {
                            return (
                                <form
                                    onSubmit={handleSubmitProvider}
                                    className={muiStyles.formWrapper}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid
                                            xs={12}
                                            item
                                            className={muiStyles.titleGridItem}
                                        >
                                            <div
                                                className={muiStyles.titleBlock}
                                            >
                                                <div
                                                    className={
                                                        muiStyles.enterGame
                                                    }
                                                >
                                                    <LocaleText
                                                        label="login.title"
                                                        defaultValue="Log In"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        muiStyles.closeIconWrapper
                                                    }
                                                >
                                                    <img
                                                        src={closeIcon}
                                                        alt="Close"
                                                        className={
                                                            muiStyles.closeIcon
                                                        }
                                                        onClick={() =>
                                                            closeModal("phone-auth")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(
                                                    muiStyles.joinGame,
                                                    "joinGame joinGame--login"
                                                )}
                                            ></div>
                                        </Grid>

                                        <Grid
                                            xs={12}
                                            item
                                            className="fieldGrid"
                                        >
                                            <div className="OutlinedInput">
                                                { state === 0 ? <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <label
                                                        htmlFor="phone"
                                                        className={
                                                            muiStyles.outlinedLabel
                                                        }
                                                    >
                                                        <LocaleText
                                                            label="phoneAuth.phoneLabel"
                                                            defaultValue="Phone*"
                                                        />
                                                    </label>

                                                    <OutlinedInput
                                                        ref={inputEl}
                                                        id="phone"
                                                        onChange={(_data) => {
                                                            setPhoneVal(_data.target.value);
                                                            handleChange(_data);
                                                        }}
                                                        error={
                                                            !!errors.phone
                                                        }
                                                        name="phone"
                                                        value={values.phone}
                                                        className={`${muiStyles.outlinedInput} ${muiStyles.inputAutoFillOverride}`}
                                                        placeholder={phone(
                                                            "Enter your phone"
                                                        )}
                                                        required
                                                    />


                                                    <div
                                                        className={btnStyle}
                                                        onClick={onButtonClick}
                                                    />
                                                    {errors.phone && (
                                                        <div className="error">
                                                            <LocaleText
                                                                label={`phone.errors.phone.${errors.phone}`}
                                                                defaultValue={
                                                                   'Phone error'
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </FormControl> :


                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <label
                                                        htmlFor="code"
                                                        className={
                                                            muiStyles.outlinedLabel
                                                        }
                                                    >
                                                        <LocaleText
                                                            label="login.codeLabel"
                                                            defaultValue="Code*"
                                                        />
                                                    </label>

                                                    <OutlinedInput
                                                        ref={inputEl}
                                                        id="code"
                                                        onChange={(_data) => {
                                                            setCodeVal(_data.target.value);
                                                            handleChange(_data);
                                                        }}
                                                        error={
                                                            !!errors.code
                                                        }
                                                        name="code"
                                                        value={values.code}
                                                        className={`${muiStyles.outlinedInput} ${muiStyles.inputAutoFillOverride}`}
                                                        placeholder={code(
                                                            "Enter your code"
                                                        )}
                                                        type="password"
                                                    />


                                                    <div
                                                        className={btnStyle}
                                                        onClick={onButtonClick}
                                                    />
                                                    {errors.code && (
                                                        <div className="error">
                                                            <LocaleText
                                                                label={`login.errors.code.${errors.code}`}
                                                                defaultValue={
                                                                    'Code error'
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </FormControl> }
                                            </div>
                                        </Grid>


                                        {error && (
                                            <Grid
                                                xs={12}
                                                item
                                                className="fieldGrid"
                                            >
                                                <div className="joinGame joinGame--notFound">
                                                    <LocaleText
                                                        label="phone.accountNotFound"
                                                        defaultValue="Account not found"
                                                    />
                                                    &nbsp;
                                                    <Link to={paths.login}>
                                                        <LocaleText
                                                            label="phone.createAccount"
                                                            defaultValue="Create Account"
                                                        />
                                                    </Link>
                                                </div>
                                            </Grid>
                                        )}

                                       {/* Таймер */}
                                        { isTimerActive && state === 2 && (
                                            <Grid xs={12} item className="fieldGrid">
                                                <div className={muiStyles.timerText}>
                                                    {formatTimerText(timer)}
                                                </div>
                                            </Grid>
                                        )}

                                        <Grid
                                            item
                                            xs={12}
                                            className="fieldGrid"
                                        >
                                            <div
                                                className={classNames(
                                                    muiStyles.formBtnWrap,
                                                    "formBtnWrap--login"
                                                )}
                                            >
                                                <GreenButton
                                                    loading={isLoading}
                                                    buttonType={ state === 0 ? 'button' : 'submit' }
                                                    text={loginButton(
                                                        "Continue"
                                                    )}
                                                    onClick={() => {
                                                        if (state === 0) {
                                                            setState(1);
                                                        }
                                                        if (state === 1) {
                                                            setState(2);
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    type={
                                                        state === 0 ? ButtonType.logIn : ButtonType.continueBtn
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs
                                        className="fieldGrid"
                                        alignContent={"flex-start"}
                                    >
                                        <Grid
                                            container
                                            className={muiStyles.signUpBlock}
                                        >
                                            <p>
                                                <LocaleText
                                                    label="login.noAccount"
                                                    defaultValue={dontHaveAccount(
                                                        "Don't have an account?"
                                                    )}
                                                />
                                            </p>
                                            <button
                                                className={muiStyles.signUp}
                                                onClick={() => {
                                                    closeModal("login");
                                                    openModal("register");
                                                }}
                                            >
                                                <LocaleText
                                                    label="login.signUp"
                                                    defaultValue={signUp(
                                                        "Sign Up"
                                                    )}
                                                />
                                            </button>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                </ThemeProvider>
            </Box>
        </Dialog>
    );
};

export default observer(PhoneAuth);
