import React from 'react';
import { observer } from 'mobx-react';
import { useLocale } from 'src/providers/LocaleProvider';

import { useDrawStageStatus } from '../../../../../hooks/useDrawStageStatus';
import BaseAlert from '../BaseAlert';
import useStores from 'src/hooks/useStores';
import getOppositeColor from 'src/utils/oppositeColor';

const DrawDialogs = () => {
    const {
        game: {
            dialogs: {
                draw: {
                    offerDraw: { title: offerDrawTitle },
                    waitForOpponentToDecide: {
                        title: waitForOpponentToDecideTitle,
                    },
                    acceptOrReject: { title: acceptOrRejectTitle },
                    opponentIsRejectedDraw: {
                        title: opponentIsRejectedDrawTitle,
                    },
                },
            },
        },
    } = useLocale();

    const {
        isAcceptOrRejectDrawDialog,
        isConfirmDrawOfferingDialog,
        isWaitingForOpponentToDecideDialog,
        opponentIsRejectedDraw,
    } = useDrawStageStatus();

    const { gameStore } = useStores();
    const myColor = gameStore.gameState.getMyColor();
    const rejectedPlayer = gameStore.gameState.getPlayerByColor(
        getOppositeColor(myColor)
    );

    if (isConfirmDrawOfferingDialog) {
        return (
            <BaseAlert
                title={offerDrawTitle('You are about to offer a draw')}
            />
        );
    } else if (isWaitingForOpponentToDecideDialog) {
        return (
            <BaseAlert title={waitForOpponentToDecideTitle('Thinking...')} />
        );
    } else if (isAcceptOrRejectDrawDialog) {
        return <BaseAlert title={acceptOrRejectTitle("Let's make a draw")} />;
    } else if (opponentIsRejectedDraw) {
        return (
            <BaseAlert
                title={opponentIsRejectedDrawTitle.compile(
                    { nickname: rejectedPlayer?.name },
                    `The {{nickname}} is rejected a draw`
                )}
            />
        );
    }
    return null;
};

export const MobileDrawDialogs = observer(DrawDialogs);
