import { Dice } from '../types';
import { ColorVariant } from '../../models';

export enum DiceStatus {
    active = 'active',
    inactive = 'inactive',
}

export interface IDiceItemDetails {
    figure: number;
    active: boolean;
    status: DiceStatus;
}
class FenHelper {
    static letterToName(char: string) {
        if (char === 'p') {
            return 'pawn';
        } else if (char === 'n') {
            return 'knight';
        } else if (char === 'b') {
            return 'bishop';
        } else if (char === 'q') {
            return 'queen';
        } else if (char === 'k') {
            return 'king';
        } else if (char === 'r') {
            return 'rook';
        }

        return 'other';
    }

    static nameToLetter(word: string, color: string) {
        if (!word) {
            return '';
        }

        color = color.slice(0, 1);

        const clr = !color ? 'b' : color;
        let char = '';

        if (word === 'pawn') {
            char = 'p';
        } else if (word === 'knight') {
            char = 'n';
        } else if (word === 'bishop') {
            char = 'b';
        } else if (word === 'queen') {
            char = 'q';
        } else if (word === 'king') {
            char = 'k';
        } else if (word === 'rook') {
            char = 'r';
        }

        if (clr === 'w') {
            return char.toUpperCase();
        }

        return char;
    }

    static numberToLetter(num: number) {
        if (num === 1) {
            return 'p';
        } else if (num === 2) {
            return 'n';
        } else if (num === 3) {
            return 'b';
        } else if (num === 4) {
            return 'r';
        } else if (num === 5) {
            return 'q';
        } else if (num === 6) {
            return 'k';
        }

        return 'other';
    }

    static letterToNumber(figure: string) {
        const char = figure.toLowerCase();

        if (char === 'p') {
            return 1;
        } else if (char === 'n') {
            return 2;
        } else if (char === 'b') {
            return 3;
        } else if (char === 'q') {
            return 5;
        } else if (char === 'k') {
            return 6;
        } else if (char === 'r') {
            return 4;
        }

        return -1;
    }

    static colorCodeToWord(code: string) {
        const char = code.substring(0, 1);
        if (char === 'w') {
            return ColorVariant.white;
        } else if (char === 'b') {
            return ColorVariant.black;
        }

        return ColorVariant.none;
    }

    static inverseColor(color: string) {
        color = FenHelper.colorCodeToWord(color);
        if (color === 'white') {
            return 'black';
        }

        return 'white';
    }

    static rowToLetter(row: number) {
        return String.fromCharCode(96 + row);
    }

    static getPieceColor(piece: string) {
        let color = ColorVariant.black;
        if (piece === piece.toUpperCase()) {
            color = ColorVariant.white;
        }

        return color;
    }

    static diceArrayToObject(dice: Dice, active: boolean, status?: DiceStatus) {
        let response: IDiceItemDetails[] = [];
        for (let i = 0; i < dice.length; i++) {
            response[i] = {
                active: active,
                figure: dice[i],
                status: active
                    ? DiceStatus.active
                    : status
                    ? status
                    : DiceStatus.inactive,
            };
        }

        return response;
    }
    /*
    static bindLegalDice(dice: Dice, legal: string[]) {
        const legalNumbers: number[] = [];
        for (let j = 0; j < legal.length; j++) {
            legalNumbers.push(FenHelper.letterToNumber(legal[j]));
        }

        let num, index;
        for (let i = 0; i < 3; i++) {
            num = dice[i].dice ? dice[i].dice : dice[i].figure;

            index = legalNumbers.indexOf(num);
            if (index === -1) {
                dice[i].active = false;
            } else {
                legalNumbers.splice(index, 1);
            }
        }

        return dice;
    }

    static diceAreOkay(dice) {
        if (dice.length === 0) {
            return false;
        }

        var sum = 0;
        for (var i = 0; i < 3; i++) {
            sum += dice[i].dice ? dice[i].dice : dice[i].figure;
        }

        if (sum < 3) {
            return false;
        }

        return true;
    }

    static allDiceAreActive(dice) {
        if (dice.length < 3) {
            return false;
        }

        var activeCount = 0;
        for (var i = 0; i < 3; i++) {
            activeCount += dice[i].active ? 1 : 0;
        }

        if (activeCount === 3) {
            return true;
        }

        return false;
    }

    static isPlayer(color) {
        if (
            FenHelper.colorCodeToWord(color) === 'white' ||
            FenHelper.colorCodeToWord(color) === 'black'
        ) {
            return true;
        }

        return false;
    }*/
}

export default FenHelper;
