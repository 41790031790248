import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
const clientWidth = window.innerWidth;
const clientHeight = window.innerHeight;
import theme from 'src/theme';
import { DARKGREEN_ALERTS } from 'src/pages/game-new/constants';

export const useStyles = makeStyles({
  '@global': {
    '@keyframes eventChatContainderWrapper': {
        '0%': {
          opacity: 0,
          transform: "translate(-100%, 0%)",
        },
        '100%': {
          opacity: 1,
          transform: "translate(0%, 0%)",
        },
    },
},

    eventChatContainderWrapper: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      borderRadius: "5px",
      background: "rgba(0,0,0,0.8)",
      backdropFilter: 'blur(5px)',
      border: `1px solid ${appColors.txt950}`,
      opacity: 0.4,
      transform: "translate(-100%, 0%)",
      animationName: 'eventChatContainderWrapper',
      animationDelay: '0.1s',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },
});
