import {RookBlack} from "../figures/rook-black";
import {KnightBlack} from "../figures/knight-black";
import {BishopBlack} from "../figures/bishop-black";
import {QueenBlack} from "../figures/queen-black";
import {KingBlack} from "../figures/king-black";
import {BishopWhite} from "../figures/bishop-white";
import {KnightWhite} from "../figures/knight-white";
import {RookWhite} from "../figures/rook-white";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KingWhite} from "../figures/king-white";
import {PawnWhite} from "../figures/pawn-white";
import {ChessMap} from "../chess/chess.map";
import {IGameNew} from "../../../store/models";
import {Pawn} from "../figures/pawn";
import {Movement} from "../chess/movement";
import {Figure} from "../chess/figure";



export enum EGameFen {
    FEN_NORMAL = 1,
    FEN_960 = 2,
}

export interface IMove {
    move: string;
    user: string;
    date: Date;
    type: string;
    fenAfter: string;
    beatFigure: string;
    moveFigure: string;
    isCastling: boolean;
    figureTransform: string;
    figures?: string;
}


export const boardAsChessMap = (entity?: IGameNew) => {
    //calc moves

    if (!entity) return new ChessMap();

    const symbMap = {
        r: RookBlack,
        n: KnightBlack,
        b: BishopBlack,
        q: QueenBlack,
        k: KingBlack,
        p: PawnDark,
        R: RookWhite,
        N: KnightWhite,
        B: BishopWhite,
        Q: QueenWhite,
        K: KingWhite,
        P: PawnWhite,
    };

    const fen = entity.fen;
    const chessMap = new ChessMap();

    chessMap.is960 = entity.fenType === EGameFen.FEN_960;

    let rowId = 0;

    const parts = fen.split(' ');

    const parts0 = parts[0];
    for (const row of parts0.split('/')) {
        let columnId = 1;
        for (const symb of row.split('')) {
            const clss = symbMap[symb];

            if (/\d/.test(symb)) {
                for (let i = 0; i < +symb; i++) {
                    chessMap.cells[rowId][columnId].figure = undefined;
                    columnId++;
                }
            } else if (clss) {
                const fig = new clss(chessMap);

                if (fig instanceof Pawn) {
                    if (
                        (fig.moveVector === -1 && rowId !== 6) ||
                        (fig.moveVector === 1 && rowId !== 1)
                    ) {
                        fig.moves = 100;
                    }
                }

                chessMap.cells[rowId][columnId].figure = fig;

                columnId++;
            } else {
                chessMap.cells[rowId][columnId].figure = undefined;
                columnId++;
            }
        }
        rowId++;
    }

    const lastMoves: IMove[] = [];

    if (entity.movesHistory) {
        let st = entity.movesHistory.length - 1;
        while (st >= 0) {
            const ent = entity.movesHistory[st];

            if (ent.type === 'move') {
                if (ent.user.toString() !== entity.curMove.toString()) {
                    lastMoves.push((ent as any) as IMove);
                } else if (lastMoves.length) {
                    break;
                }
            }

            st--;
        }

        for (const ent of lastMoves) {
            if (
                ent.moveFigure === 'p' &&
                Math.abs(+ent.move[3] - +ent.move[1]) === 2
            ) {
                const tY =
                    ent.move[2].toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0) + 1;
                const tX = 8 - +ent.move[3];

                if (
                    chessMap.cells[tX][tY].figure &&
                    chessMap.cells[tX][tY].figure?.type === 'p' &&
                    chessMap.cells[tX][tY].figure?.isBlack !== chessMap.currentMoveBlack
                ) {
                    (chessMap.cells[tX][tY].figure as Pawn).moves = 1;
                }
            }
        }
    }

    chessMap.currentMoveBlack = parts[1] === 'b';

    if (parts[2] != '-') {
        const map = {
            k: 'canRightBlack',
            q: 'canLeftBlack',
            K: 'canRightWhite',
            Q: 'canLeftWhite',
        };

        for (const symb of parts[2]) {
            chessMap[map[symb]] = true;
        }
    }

    chessMap.moves = [];

    for (const key of parts[4].split('')) {
        if (symbMap[key]) {
            chessMap.moves.push(new symbMap[key]());
        }
    }

    return chessMap;
}

export const moveToFen = (moves: Movement[]) => {
    return moves.map((move) => movementToFen(move));
}


export const figCase = (type: string, black: boolean) => {
    let curType = type;
    if (curType.toLowerCase() === 'h') curType = 'n';

    return black ? curType.toLowerCase() : curType.toUpperCase();
}

export const getFiguresToFen = (figures: Figure[]) => {
    const availableFigures: string[] = [];
    for (const move of figures) {
        let key = move.type;
        if (key === 'h') key = 'n';
        availableFigures.push(key);
    }

    if (!availableFigures.length) return '-';

    return availableFigures.join('');
}

export const chessMapToFen = (chessMap: ChessMap) => {
    const result: string[] = [];

    for (let rowId = 0; rowId < 8; rowId++) {
        const str: string[] = [];
        let st = 0;
        for (let columnId = 1; columnId <= 8; columnId++) {
            const figure = chessMap.cells[rowId][columnId].figure;
            if (figure) {
                if (st) str.push(st.toString());
                st = 0;

                str.push(figCase(figure.type, figure.isBlack));
            } else {
                st++;
            }
        }

        if (st) str.push(st.toString());

        result.push(str.join(''));
    }

    return result.join('/');
}

export const movementToFen = (move: Movement) => {
    const xFrom = String.fromCharCode(
        'a'.charCodeAt(0) + move.movement.columnFrom - 1,
    );
    const xTo = String.fromCharCode(
        'a'.charCodeAt(0) + move.movement.columnTo - 1,
    );

    let figureChar = move.movement.transformTo?.type || '';
    if (figureChar === 'h') {
        figureChar = 'n';
    }

    return (
        xFrom +
        (8 - move.movement.rowFrom) +
        xTo +
        (8 - move.movement.rowTo) +
        figureChar
    );
}