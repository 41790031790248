import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

import { MAX_CHANGE_SCREEN_WIDTH } from '../../../constants';

export const useStyles = makeStyles({
    '@global': {
        '@keyframes diceButtonWithSpinner': {
            '0%': {
                transform: 'rotate3d(0, 1, 0, 0deg)',
                filter: 'blur(0px)',
            },
            '50%': {
                transform: 'rotate3d(0, 1, 0, 360deg)',
                filter: 'blur(5px)',
            },
            '75%': {
                transform: 'rotate3d(0, 1, 0, 1440deg)',
            },
        },
    },
    diceButtonWithSpinner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.between('md', MAX_CHANGE_SCREEN_WIDTH)]: {
            height: '100%',
            '@media (orientation: landscape)': {
                '&.isRotate': {
                    animationName: 'diceButtonWithSpinner',
                    animationDelay: '0s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    transitionTimingFunction: 'ease-in',
                    animationDuration: '1000ms',
                },
            },
            '@media (orientation: portrait)': {
                '&.isRotate': {
                    animationName: 'diceButtonWithSpinner',
                    animationDelay: '0s',
                    animationIterationCount: 10,
                    animationFillMode: 'forwards',
                    transitionTimingFunction: 'ease-in',
                    animationDuration: '1500ms',
                },
            },
        },
        [theme.breakpoints.down('md')]: {
            height: '100%',
            '@media (orientation: landscape)': {
                '&.isRotate': {
                    animationName: 'diceButtonWithSpinner',
                    animationDelay: '0s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    transitionTimingFunction: 'ease-in',
                    animationDuration: '1000ms',
                },
            },
            '@media (orientation: portrait)': {
                '&.isRotate': {
                    animationName: 'diceButtonWithSpinner',
                    animationDelay: '0s',
                    animationIterationCount: 10,
                    animationFillMode: 'forwards',
                    transitionTimingFunction: 'ease-in',
                    animationDuration: '1500ms',
                },
            },
        },
    },
});
