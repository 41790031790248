import { makeStyles } from "@mui/styles";
import { appColors } from "src/consts/app-config";

import theme from "src/theme";

type IWrapper = {
    elemHeight?: any;
    resign?: string;
};

export const useStyles = makeStyles({
    "@global": {
        "@keyframes alertHolderAnimate": {
            "0%": {
                opacity: 0,
            },
            "40%": {
                opacity: 0,
            },
            "100%": {
                opacity: 1,
            },
        },
        "@keyframes viewMobilePotWrapperAnimate": {
            "0%": {
                minHeight: "40px",
            },
            "100%": {
                minHeight: "0px",
                padiing: 0,
                margin: 0,
            },
        },
    },
    gameChatHistoryBox: {
        textAlign: "center",
        // height: "fit-content",
        height: "100%",
        width: "100%",
        "@media (min-width: 450px) and (max-width: 1023px)": {
            paddingTop: "50px",
        },
        "@media  (orientation: landscape) and (min-width: 1024px)": {
            // height: "fit-content",
            height: "100%",
            position: "relative",
            width: "100%",
        },
        "&.mobileType": {
            "@media  (orientation: portrait) ": {
                [theme.breakpoints.between("xs", "lg")]: {
                    height: "100%",
                    width: "100%",
                    // background:
                    //     "linear-gradient(0deg, rgba(18, 18, 18, 0.8), rgba(18, 18, 18, 0.8)), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
                    display: "flex",
                    flexDirection: "column",
                    background: "#121212",

                    "&.open": { zIndex: 1 },
                    "&.hide": {},
                },

                "&.isViewMode": {
                    background: "#121212",
                },
            },
        },
        "&.inProgressPreviewFull": {
            "&.isViewMode": {
                background: "#121212",
            },
            [theme.breakpoints.up("lg")]: {
                paddingTop: 8,
                paddingLeft: 8,
                display: "flex",
                flexDirection: "column",
            },
        },
    },

    gameChatHistoryTabBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            display: "flex", // Показывать на маленьких экранах
        },
        [theme.breakpoints.up("lg")]: {
            display: "none", // Скрывать на больших экранах
        },
        zIndex: 2,
        "&.isViewers": {
            justifyContent: "space-between",
            [theme.breakpoints.down("lg")]: {
                padding: "10px 20px 0px 20px",
            },
        },
    },

    gameChatHistoryLeftTab: {
        "&.deepBlack": {
            height: "inherit",
            display: "flex",
            justiFyContent: "flex-start",
            alignItems: "center",
            gridGap: "5px",
            "&.deepBlack": {
                "& > img": {
                    height: "inherit",
                    aspectRatio: "1 / 1",
                },
                "& > p": {
                    color: appColors.white,
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "20px",
                },
            },
        },
    },

    tabDescribeBox: {
        display: "flex",
        height: "100%",
        gridGap: "3px",
        "& > p": {
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "20px",
            color: appColors.white,
        },
        "&.isViewMode": {
            fontSize: "24px",
            lineHeight: "24px",
            height: "28px",
            alignItems: "center",
            "& > p": {
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "24px",
                color: appColors.white,
            },
        },
    },

    crossOnMobileChatTabs: {
        width: 24,
        height: 24,
        position: "relative",
        cursor: "pointer",
        "&::before": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(45deg)",
            transform: "translate(-50%,-50%) rotate(45deg)",
            background: "linear-gradient(180deg, gray 0%, white 100%)",
        },
        "&::after": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(-45deg)",
            transform: "translate(-50%,-50%) rotate(-45deg)",
            background: "linear-gradient(180deg, gray 0%, white 100%)",
        },
        [theme.breakpoints.down("sm")]: {
            width: 24,
            height: 24,
        },
    },
});
