import { useLocale } from "src/providers/LocaleProvider";

const useCalcTableLabel = (value: string | number | undefined) => {
    const {
        tournaments: {
            bracket: {
                stagesTitles: { final, semiFinal }
            }
        }
    } = useLocale();

    if (!value) return;
    if (+value === 1) return final("Final");
    if (+value === 2) return semiFinal("Semi-final");
    return `1 / ${+value}`;
};

export default useCalcTableLabel;
