import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import cn from "classnames";
import { Box, TabScrollButton } from "@mui/material";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import { TextField } from "src/shared/ui/TextField";

import { EFilter, ExternalGameType } from "../..";
import { ProviderSelect } from "../ProviderSelect";

import { useStyles } from "./styles";
import { SearchTextField } from "./SearchTextField";

interface IFiltersProps {
    onFilterChange: (value: EFilter) => void;
    onProviderChange: (value: string[]) => void;
    isMultiTable?: boolean;
    selectedFilter: EFilter;
    selectedProviders: string[];
    gameType: ExternalGameType;
    searchText: string;
    onSearchTextChange?: (value: string) => void;
}

export const Filters = ({
    onFilterChange,
    onProviderChange,
    isMultiTable,
    selectedFilter,
    selectedProviders,
    gameType,
    searchText,
    onSearchTextChange,
}: IFiltersProps) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const {
        externalGames: {
            filters: { all, newGames, favorites, search },
        },
    } = useLocale();

    const handleChange = (_: React.SyntheticEvent, newValue: EFilter) => {
        onFilterChange(newValue);
    };

    const filterList = [
        {
            label: all("All"),
            value: EFilter.all,
        },
        {
            label: newGames("New"),
            value: EFilter.new,
        },
        {
            label: favorites("Favorites"),
            value: EFilter.favorites,
        },
    ];

    return (
        <Box className={styles.container}>
            <Box className={styles.tabContainer}>
                <Tabs
                    value={selectedFilter}
                    onChange={handleChange}
                    textColor="secondary"
                    aria-label="Slots filter"
                    className={cn(styles.tabs, appearanceTheme)}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            display: "none",
                        },
                    }}
                >
                    {filterList.map((filter) => (
                        <Tab
                            key={filter.value}
                            value={filter.value}
                            label={filter.label}
                            className={cn(styles.tabItem, { [styles.isMultiTable]: isMultiTable })}
                        />
                    ))}
                    <Tab
                        value="providers"
                        label={
                            <ProviderSelect
                                value={selectedProviders}
                                onChange={onProviderChange}
                                gameType={gameType}
                                isMultiTable={isMultiTable}
                                isSelected={selectedFilter === EFilter.providers}
                            />
                        }
                        className={cn(
                            styles.tabItem,
                            styles.providerSelectTab,
                            { [styles.isMultiTable]: isMultiTable }
                        )}
                    />
                </Tabs>
                <Box className={styles.searchContainer}>
                    <TextField
                        value={searchText}
                        onChange={(e) => onSearchTextChange?.(e.target.value)}
                        onIconClick={() => onSearchTextChange?.("")}
                        type="search"
                        placeholder={search("Поиск")}
                        className={cn(styles.searchTextField, { [styles.isMultiTable]: isMultiTable })}
                    />
                </Box>
            </Box>
        </Box>
    );
};
