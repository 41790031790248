import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    title: {
        fontSize: 16,
        fontWeight: 700,
        color: '#866838',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
});
