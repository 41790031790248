export enum EParamsRatio {
    aspectRatio = "aspectRatio",
    maxHeight = "maxHeight",
}
export const getDesktopGameScreenSize = (
    calcType: EParamsRatio,
    widthMediana: number,
    heightMediana: number,
    paddingWidthParams: number = 0,
    paddingHeightParams: number = 0
) => {
    switch (calcType) {
        case EParamsRatio.maxHeight:
            return (
                ((heightMediana - paddingHeightParams) /
                    (widthMediana - paddingWidthParams)) *
                    100 +
                "dvw"
            );

        case EParamsRatio.aspectRatio:
            return (
                (widthMediana - paddingWidthParams) /
                (heightMediana - paddingHeightParams)
            );
    }
};
