export function getLocale(
    options: {
        locale?: string;
        defLocale?: string;
        data?: { [s: string]: any };
        onUnknown?: (path: string, value: string) => void;
    } = {}
): any {
    // Remove this logic from here
    const isHalyk = process.env.REACT_APP_IS_HALYK === "1";
    let {
        locale = "",
        defLocale = isHalyk ? "ru" : "en",
        data = {},
        onUnknown,
    } = options;

    if (!locale) {
        //@ts-ignore
        locale = `${navigator.language || navigator.userLanguage}`.replace(
            /^([^-]+)(-?.*)?$/,
            "$1"
        );

        if (isHalyk && !["ru", "kk"].includes(locale)) {
            locale = "ru";
        }
    }

    if (!onUnknown) {
        onUnknown = (path, value) => {
            console.warn(
                `LOCALE: not found locale for queried path '${path}' ${
                    value ? `having default value '${value}'` : ""
                }`
            );
        };
    }
    const value = data[locale] || {};
    const valueDefault = data[defLocale] || {};

    return localeValue({
        value,
        valueDefault,
        path: `[${locale}]`,
        onUnknown,
    });
}

const sentLocales: { [s: string]: boolean } = {};

function localeValue({
    value,
    valueDefault,
    path,
    onUnknown,
}: {
    value: any;
    valueDefault: any;
    path: string;
    onUnknown: (path: string, value: string) => void;
}) {
    const getValue = (def: string) => {
        if (!value) {
            onUnknown(path, valueDefault || def);
            return valueDefault || def || path;
        }
        return value;
    };

    const extract = (proxy: any) => (label: string, defaultValue: string) =>
        label.split(".").reduce((locale, label) => locale[label], proxy)(
            defaultValue
        );

    const compile = (value: string, props: Record<string, string>) => {
        const rex = /{{2}\s*([^}\s]+)\s*}{2}/gm;
        const tokens = (value.match(rex) || [])
            .map(
                (match) => [match, match.replace(rex, "$1")] as [string, string]
            )
            .reduce((map, [match, value]) => {
                map.set(match, value);
                return map;
            }, new Map<string, string>());
        const getPropsKey = (key: string) => (key && props[key]) || "";
        return Array.from(tokens.entries()).reduce(
            (template: string, [token, name]: [string, string]) =>
                template.replace(
                    new RegExp(
                        `${token}`.replace(
                            /[-[\]{}()*+!<=:?./\\^$|#,]/g,
                            "\\$&"
                        ),
                        "g"
                    ),
                    `${getPropsKey(name)}`
                ),
            value
        );
    };

    return new Proxy(function () {}, {
        get(target, prop: string, proxy) {
            if (prop === "extract") {
                return extract(proxy);
            }
            if (prop === "compile") {
                return (props: Record<string, string>, defaultValue: string) =>
                    compile(getValue(defaultValue), props);
            }
            if (prop === "extractCompiled") {
                return (
                    label: string,
                    props: Record<string, string>,
                    defaultValue: string
                ) => compile(extract(proxy)(label, defaultValue), props);
            }
            const currentPath = `${path}.${prop}`;
            /*if(!sentLocales[currentPath]){
                markLocaleAsUsed(currentPath.split('.').slice(1).join('.'));
                sentLocales[currentPath] = true;
            }*/
            return localeValue({
                value: value && value[prop],
                valueDefault: valueDefault && valueDefault[prop],
                path: currentPath,
                onUnknown,
            });
        },
        apply(target, thisArg, [def]: [string]) {
            return getValue(def);
        },
    });
}
