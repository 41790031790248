// DiceSizeCalc
export const getDiceSize = (width: number): number => {
    const screenRatio = window.innerHeight / window.innerWidth;
    const screenWidth = window.innerWidth;
    if (screenRatio < 1.9 && screenWidth < 450) {
        return width / 7.5;
    }
    if (screenRatio < 2 && screenWidth < 450) {
        return width / 7.1;
    }
    if (screenRatio < 2.15 && screenWidth < 450) {
        return width / 6;
    }
    if (screenRatio < 2.18 && screenWidth < 450) {
        return width / 5.8;
    }
    if (screenRatio < 2.2 && screenWidth < 450) {
        return width / 5.5;
    }
    if (screenRatio < 2.25 && screenWidth < 450) {
        return width / 5.3;
    }
    if (screenRatio < 2.3 && screenWidth < 450) {
        return width / 5;
    } else {
        return width / 5.8;
    }
};
