import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
import { useStyles } from 'src/components/Avatar/styles';
import AvatarBorder from 'src/assets/images/avatar-border.svg';

interface Props {
    width: number;
}
export const AvatarStyled = ({ width }: Props) => {
    const styles = useStyles({ width });
    return (
        <SVGUniqueID>
            <svg
                id='eLiPUzea02x1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 600 600'
                shape-rendering='geometricPrecision'
                text-rendering='geometricPrecision'
                className={styles.avatarStyled}
                width='44'
                height='44'
                fill='none'
            >
                <ellipse
                    rx='300'
                    ry='300'
                    transform='translate(300 300)'
                    fill='rgba(0, 186, 108, 1)'
                    strokeWidth='0'
                />
            </svg>
            {/* 
            THERE IS old metalic style border, above this svg we can see simple white color background
            <svg
                className={styles.avatarStyled}
                width='44'
                height='44'
                viewBox='0 0 44 44'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M21.8784 43.0351C33.6762 43.0351 43.2402 33.4711 43.2402 21.6733C43.2402 9.87553 33.6762 0.311523 21.8784 0.311523C10.0806 0.311523 0.516602 9.87553 0.516602 21.6733C0.516602 33.4711 10.0806 43.0351 21.8784 43.0351Z'
                    fill='url(#paint0_linear_avatar)'
                />
                <path
                    d='M21.878 41.5884C32.8767 41.5884 41.793 32.6722 41.793 21.6734C41.793 10.6746 32.8767 1.7583 21.878 1.7583C10.8792 1.7583 1.96289 10.6746 1.96289 21.6734C1.96289 32.6722 10.8792 41.5884 21.878 41.5884Z'
                    fill='url(#paint1_linear_avatar)'
                />
                <path
                    d='M21.8791 40.8198C32.4536 40.8198 41.0258 32.2475 41.0258 21.6731C41.0258 11.0986 32.4536 2.52637 21.8791 2.52637C11.3047 2.52637 2.73242 11.0986 2.73242 21.6731C2.73242 32.2475 11.3047 40.8198 21.8791 40.8198Z'
                    fill='#1A171B'
                />
                <defs>
                    <linearGradient
                        id='paint0_linear_avatar'
                        x1='21.8775'
                        y1='43.0328'
                        x2='21.8775'
                        y2='0.312175'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop stop-color='#C0C0C2' />
                        <stop offset='0.1333' stop-color='#1A171B' />
                        <stop offset='0.2293' stop-color='#2C2B2E' />
                        <stop offset='0.3394' stop-color='#3D3D3F' />
                        <stop offset='0.5' stop-color='#E1E1E2' />
                        <stop offset='0.5217' stop-color='#CFCFD0' />
                        <stop offset='0.5654' stop-color='#9F9FA0' />
                        <stop offset='0.6266' stop-color='#525254' />
                        <stop offset='0.6424' stop-color='#3D3D3F' />
                        <stop offset='0.8061' stop-color='#1A171B' />
                        <stop offset='0.9333' stop-color='#ACACAE' />
                    </linearGradient>
                    <linearGradient
                        id='paint1_linear_avatar'
                        x1='1.95941'
                        y1='21.6725'
                        x2='41.7948'
                        y2='21.6725'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop offset='0.0666666' stop-color='#ACACAE' />
                        <stop offset='0.1939' stop-color='#1A171B' />
                        <stop offset='0.3576' stop-color='#3D3D3F' />
                        <stop offset='0.3734' stop-color='#525254' />
                        <stop offset='0.4346' stop-color='#9F9FA0' />
                        <stop offset='0.4783' stop-color='#CFCFD0' />
                        <stop offset='0.5' stop-color='#E1E1E2' />
                        <stop offset='0.6606' stop-color='#3D3D3F' />
                        <stop offset='0.7707' stop-color='#2C2B2E' />
                        <stop offset='0.8667' stop-color='#1A171B' />
                        <stop offset='1' stop-color='#C0C0C2' />
                    </linearGradient>
                </defs>
            </svg> */}
        </SVGUniqueID>
    );
};
