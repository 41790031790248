import { makeStyles } from "@mui/styles";
import calcButtonTextSize from "src/utils/calcButtonTextSize";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes defaultTextAnimation": {
            "0%": {
                transform: "scale(0.1)",
                opacity: 0,
            },

            "100%": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
        "@keyframes propTextAnimation": {
            "0%": {
                transform: "scale(2)",
                color: "red",
                opacity: 1,
            },

            "100%": {
                transform: "scale(1)",
                color: "white",
                opacity: 1,
            },
        },
    },

    mainActionButtonWrapper: ({ bgColor, disabled }): any => ({
        bottom: "0",
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
        transition: "0.2s",
        //width: "100%",
        //height: "100%",
        //background:
        //   "linear-gradient(239.36deg, #6D7491 32.89%, #2D3142 64.62%, #1B2030 93.81%)",
        //...(bgColor === "mobileNote" && {
        //   background: "none",
        //}),
        "&:active": {
            filter: "drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.4))",
            transform: "scale(0.98)",
        },
        // "&.deepBlack": {
        "&.deepBlack": {
            borderRadius: "4px",
            cursor: "pointer",
            width: "100%",
            height: "100%",
            border: 0,
            padding: 0,
            margin: 0,
            background: bgColor,
            ...(bgColor === "mobileNote" && {
                background: "none",
            }),
            transition: "0.2s",
            "&:active": {
                filter: "drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.4))",
                transform: "scale(1)",
            },
        },
    }),

    buttonIcon: ({ width, height, text, padding }: any) => ({
        width: "100%",
        height: "100%",
        objectFit: "contain",
        padding: "5%",
        "&.deepBlack": {},
    }),

    buttonText: ({ width, height, text, padding }: any) => ({
        "&.deepBlack": {
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: calcButtonTextSize(width, height, text),
            // lineHeight: calcButtonTextSize(width * 1.1, height, text),
            color: "rgba(17, 20, 28, 1)",
            padding: padding ? padding : "0px 5px",
            display: "flex",
            justifyContent: "center",
            aligItems: "center",
            wordBreak: "break-all",
        },
        "&.defaultTextAnimation": {
            animationName: "defaultTextAnimation",
            animationDelay: "0s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "300ms",
        },
        "&.propTextAnimation": {
            animationName: "propTextAnimation",
            animationDelay: "0s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "300ms",
        },
    }),
});
