import theme from "src/theme";

export enum SizePropsType {
    text = "text",
    icon = "icon",
    pulse = "pulse",
}

export interface SizeProps {
    width: number;
    height: number;
    text?: string;
    sizeRefactor?: number;
    type?: SizePropsType;
}

const calcButtonTextSize = (
    width: number,
    height: number,
    text = "icon",
    sizeRefactor = 1,
    type = SizePropsType.text
): string => {
    const defaultValue = "14px";
    const mainBorderSize = "10px";
    if (!text || !width) return defaultValue;
    const size = width / text.length / sizeRefactor;
    const maxSize = Math.min(width, height);
    return size > 8 ? `${Math.min(size, maxSize)}px` : mainBorderSize;
};

export const calcButtonPulseSize = (
    width: number,
    height: number,
    sizeRefactor = 1,
    type = SizePropsType.pulse
): number => {
    const defaultValue = 14;
    if (!width) return defaultValue;
    const size = width / sizeRefactor;
    const maxSize = Math.min(width, height);
    if (type === SizePropsType.pulse) {
        return Math.min(size, maxSize);
    }
    return defaultValue;
};

export default calcButtonTextSize;
