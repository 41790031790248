/* eslint-disable import/no-unresolved */
import React, { memo } from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';
import { isIOS, isAndroid } from 'react-device-detect';
import { useRouteMatch } from 'react-router-dom';

import paths from 'src/consts/paths';
import FullScreenSVG from 'src/assets/icons/profileAndSettings/fullScreenSVG';
import NormalScreenSVG from 'src/assets/icons/profileAndSettings/normalScreenSVG';
import {
    fullScreenRunner,
    fullScreenCanceler,
} from 'src/utils/fullscreenToggler';
import useCheckFullScreen from 'src/hooks/useCheckFullScreen';
import { ICustomIconProps } from 'src/assets/icons/tournaments/types';
import { MAX_XL_TABLET } from 'src/pages/game-new/constants';

import { useStyles } from './styles';

const FullScreenToggler = memo(
    ({ width, height, fillColor }: ICustomIconProps) => {
        const styles = useStyles();
        const isPoker = useRouteMatch(paths.jackpoker);
        const isLanding = useRouteMatch(paths.lobby);
        const screenStatus = useCheckFullScreen();

        if (
            (isIOS || isAndroid) &&
            navigator.maxTouchPoints > 1 &&
            window.innerWidth < MAX_XL_TABLET
        )
            return null;

        return (
            <Box
                className={cn(styles.fullScreenTogglerBox, {
                    isPoker: isPoker,
                    isLanding: isLanding,
                })}
            >
                {screenStatus ? (
                    <NormalScreenSVG
                        onClick={fullScreenCanceler}
                        width={width}
                        height={height}
                        fillColor={fillColor}
                    />
                ) : (
                    <FullScreenSVG
                        onClick={() =>
                            fullScreenRunner(document.documentElement)
                        }
                        width={width}
                        height={height}
                        fillColor={fillColor}
                    />
                )}
            </Box>
        );
    }
);

export default FullScreenToggler;
