import { useEffect } from "react";
import { observer } from "mobx-react";
import axios from "axios";

import { useSocket } from "src/core/useSocket";
import useStores from "src/hooks/useStores";
import { ITopPlayers } from "src/store/models";
import { endpointsRoot } from "src/core/endpoints";

const TournamentModalSocket = () => {
    const { authStore, tournamentsStore } = useStores();
    const [initSocket, socket, disconnectSocket] = useSocket({
        token: authStore.getAuthToken(),
    });
    const tournamentModalInfoId = tournamentsStore.tournamentModalId;

    useEffect(() => {
        console.log({ socket });
        if (!socket) return;
        socket.on("tournament:players", (data: { players: ITopPlayers[] }) => {
            // console.log('tournament:players', data.players);
            tournamentsStore.updatePlayersOnModal(data.players);
        });
    }, [socket]);

    const connectToSocket = async () => {
        const response = await axios.get(
            `${endpointsRoot}/api/v2/tournament/${tournamentModalInfoId}`
        );
        initSocket(
            `${endpointsRoot}/tournament-details/${tournamentModalInfoId}`
        );
        if (response) {
            tournamentsStore.setTournamnetDataToModal(response.data);
        }
    };

    useEffect(() => {
        if (!authStore.isAuthorized || !tournamentModalInfoId) {
            disconnectSocket();
            return;
        }
        if (authStore.isAuthorized) {
            connectToSocket();
        }
    }, [authStore.isAuthorized, tournamentModalInfoId]);

    return null;
};

export default observer(TournamentModalSocket);
