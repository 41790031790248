import React from "react";
import cn from "classnames";
import CloseIcon from "@mui/icons-material/Close";

import useChangeTheme from "src/hooks/useChangeTheme";
import searchIcon from "src/assets/icons/search.svg";

import { useStyles } from "./styles";
import { ITextFieldProps } from "./types";

export const TextField = ({
    value,
    onChange,
    placeholder,
    icon,
    type,
    className,
    onIconClick,
    ...props
}: ITextFieldProps) => {
    const appearanceTheme = useChangeTheme();
    const styles = useStyles();

    const icons = {
        close: <CloseIcon className={styles.icon} onClick={onIconClick} />,
    };

    return (
        <div className={cn(styles.wrapper, className)}>
            <input
                {...props}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={cn(styles.input, appearanceTheme)}
            />
            {type === "search" && (
                <img src={searchIcon} className={styles.icon} />
            )}
            {icon && typeof icon === "string" ? icons[icon] : icon}
        </div>
    );
};
