import { memo } from "react";
import cn from "classnames";

import useChangeTheme from "src/hooks/useChangeTheme";
import { Spinner } from "src/components/spinner";

import useStyles from "./style";
interface ISpinnerContainer {
    isAbsolute?: boolean;
    isMultiTable?: boolean;
    rotateDecorator: boolean;
}

const SpinnerContainer = ({
    isMultiTable,
    isAbsolute,
    rotateDecorator,
}: ISpinnerContainer) => {
    const appearanceTheme = useChangeTheme();
    const styles = useStyles();

    return (
        <>
            {rotateDecorator ? (
                <div className={cn(styles.spinnerDecorator, appearanceTheme)}>
                    <Spinner isAbsolute />
                </div>
            ) : (
                <div
                    className={cn(styles.spinnerLoader, appearanceTheme)}
                    style={{
                        height: isMultiTable ? "100%" : "100dvh",
                        position: isMultiTable ? "relative" : undefined,
                    }}
                >
                    <Spinner isAbsolute={isAbsolute} />
                </div>
            )}
        </>
    );
};

export default memo(SpinnerContainer);
