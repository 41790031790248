import { createContext, useContext } from "react";

import {
    GameChatType,
    GameChatToggleStatus,
    EGameChatTab,
} from "src/store/models";

type LeftLayoutPropsContext = {
    onChatTabsToggler?: (value: keyof typeof EGameChatTab) => void;
    currentTab?: keyof typeof EGameChatTab | undefined;
    chatTogglerState?:
        | GameChatToggleStatus.open
        | GameChatToggleStatus.hide
        | boolean
        | undefined;
    onChatToggler?: () => void | undefined;
    chatMobileType?: GameChatType.MOBILE | undefined;
    chatDesktopType?: GameChatType.DESKTOP | undefined;
};

export const LeftLayoutPropsContext = createContext<LeftLayoutPropsContext>(
    {} as LeftLayoutPropsContext
);

export const useLeftLayoutContext = () => useContext(LeftLayoutPropsContext);
