import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";

import { observer } from "mobx-react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import _get from "lodash/get";
import { ChessboardType } from "src/store/models";

import RiseAndCoinInitiator from "src/components/controls/bank/coinAnimation/riseAndCoinAnimation/initiator";
import RiseAndCoinInitiatorView from "src/components/controls/bank/coinAnimation/riseAndCoinAnimation/initiatorView";

import useStores from "src/hooks/useStores";

export interface IRiseAndCoinWrapper {
    chessboardType?: ChessboardType;
    view?: boolean;
}

const RiseAndCoinWrapper = ({ chessboardType, view }: IRiseAndCoinWrapper) => {
    const { gameStore, authStore } = useStores();
    const location = useLocation();

    //MOBX STORE DATA
    const curMove = gameStore.currentGameNew?.curMove;
    const extra = gameStore?.currentGameNew?.extra;

    return (
        <>{view ? <RiseAndCoinInitiatorView /> : <RiseAndCoinInitiator />}</>
    );
};

export default observer(RiseAndCoinWrapper);
