import cn from "classnames";

import { useStyles } from "./styles";
import { IButtonProps } from "./types";

export const Button = ({
    text,
    additionalInfo,
    className,
    classNameIcon,
    type,
    variant,
    color,
    onClick,
    disabled,
    icon,
    border,
}: IButtonProps) => {
    const classes = useStyles();
    return (
        <div
            className={cn(
                classes.wrapper,
                className,
                classes[type || "primary"],
                classes[variant || "contained"],
                classes[color as string],
                classes[border as string],
                {
                    [classes[`${type || "primary"}Disabled`]]: disabled,
                }
            )}
            onClick={() => !disabled && onClick?.()}
        >
            {icon && <img src={icon} className={classNameIcon} />}
            <span className={cn(classes.textSpan)}>
                {additionalInfo ? (
                    <>
                        <p>{text}</p>
                        <p>{additionalInfo}</p>
                    </>
                ) : (
                    <p>{text}</p>
                )}
            </span>
        </div>
    );
};
