import React, { useState } from "react";
import { observer } from "mobx-react";

import avatar from "src/assets/images/avatar-border.svg";

import { useStyles } from "./styles";
import WatchingList from "./components/WatchingList";
import WatchingToggler from "./components/WatchingToggler";
import {
    IWatchingList,
    WatchersStatusType,
} from "./components/types/watchingTypes";

function WatchingPlayers({
    setOpen,
    openStatus,
    isOpen,
    watchingArray,
    hideToggler,
    isPreview,
    isMobileView,
}: IWatchingList) {
    const classes = useStyles();

    return (
        <>
            {!hideToggler && (
                <WatchingToggler
                    setOpen={(properties) => setOpen(properties)}
                    openStatus={WatchersStatusType.show}
                    isOpen={isOpen}
                    watchingArray={watchingArray}
                />
            )}

            {!isMobileView && (
                <WatchingList
                    setOpen={(properties) => setOpen(properties)}
                    isOpen={isOpen}
                    openStatus={WatchersStatusType.hide}
                    watchingArray={watchingArray}
                    isPreview={isPreview}
                />
            )}
        </>
    );
}

export default observer(WatchingPlayers);
