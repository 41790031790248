import { useEffect, useMemo, useLayoutEffect, useState } from "react";
import { observer, useObserver } from "mobx-react";
import { useHistory, useRouteMatch } from "react-router-dom";

import React from "react";
import Box from "@mui/material/Box";

import { Button } from "src/shared/ui/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { GameModeType } from "src/store/models";
import { MAX_XL_TABLET } from "src/pages/game-new/constants";

import useStores from "../../../../hooks/useStores";

import JoinToGameFormNew from "./joinToGameFormNew";
import ConfirmGameFormNew from "./confirmGameFormNew";

const GameEventDialogsNew = () => {
    const {
        lobbyStore,
        authStore,
        gameStore,
        achievementsStatStore,
        generalStore,
    } = useStores();

    // Локальное состояние для управления модальными окнами
    const [modalState, setModalState] = useState({
        inviteToMyGame: null,
        inviteToUsersGame: null,
        isProcessing: false // флаг для предотвращения множественных обработок
    });

    const history = useHistory();
    const isGameScreen = useRouteMatch("/game/:id");
    const isMobileScreen = window.innerWidth <= MAX_XL_TABLET;
    const me = authStore.currentUser?._id;
    const inviteToMyGame = useObserver(() => lobbyStore.inviteToMyGame());
    const inviteToUsersGame = useObserver(() => lobbyStore.inviteToUsersGame());

    useEffect(() => {
        if (isGameScreen && !isMobileScreen) return;
        const players = lobbyStore?.acceptedGame?.players;

        const isFriendInvite = lobbyStore.getChallengedGames(me);
        const JOINED_USER = 1;
        if (!me) return;
        if (Array.isArray(players) && isFriendInvite.length > 0) {
            const playersArr: string[] = [];
            players.map((elem) => playersArr.push(elem?.name));
            const isMeInAcceptgame = playersArr.indexOf(me);
            return isMeInAcceptgame === JOINED_USER
                ? lobbyStore.setJoinedGame(lobbyStore.acceptedGame)
                : lobbyStore.setJoinedGame(null);
        }
    }, [lobbyStore.acceptedGame]);

    useEffect(() => {
        if (isGameScreen && !isMobileScreen) return;
        const me = authStore.currentUser?._id;
        if (!me) return;
        const isFriendInvite = lobbyStore.getChallengedGames(me);
        if (
            isFriendInvite &&
            Array.isArray(isFriendInvite) &&
            isFriendInvite.length > 0
        ) {
            lobbyStore.setAcceptedGame(isFriendInvite[0]);
        }
    }, [lobbyStore.allMyGames]);

    // if (
    //     //Рендер если у нас не экран и игры, или мобилка
    //     (!isGameScreen || isMobileScreen) &&
    //     lobbyStore.joinedGame &&
    //     lobbyStore.joinedGame.type === GameModeType.REGULAR
    // ) {
    //     return <JoinToGameForm />;
    // } else if (
    //     (!isGameScreen || isMobileScreen) &&
    //     lobbyStore.acceptedGame &&
    //     lobbyStore.acceptedGame.type === GameModeType.REGULAR
    // ) {
    //     return <ConfirmGameForm />;
    // }
    // return null;

    const resetActiveMobileModals = () => {
        achievementsStatStore.toggleTopPlayersInfoNew(false);
        generalStore.closeSideMenu();
    };


    useLayoutEffect(() => {
        if (inviteToMyGame || inviteToUsersGame) {
            resetActiveMobileModals();
        }
    }, [inviteToUsersGame, inviteToMyGame]);

    const modalCollector = () => {

        if (inviteToUsersGame) {
            return <JoinToGameFormNew invite={inviteToUsersGame} />;
        }

        if (inviteToMyGame) {
            return <ConfirmGameFormNew invite={inviteToMyGame} />;
        }

        return null;
    };

    const ModalCollectorMemo = useMemo(
        () => modalCollector(),
        [inviteToMyGame, inviteToUsersGame, me, isGameScreen]
    );

    if (!me || isGameScreen) return null;

    return <>{ModalCollectorMemo}</>;
};

export default observer(GameEventDialogsNew);
