import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: `'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"
        sans-serif`,
    },
    breakpoints: {
        values: {
            // xs: 321,
            xs: 260,
            sm: 451,
            md: 769,
            lg: 1025,
            xl: 1367,
        },
    },
});

export default theme;
