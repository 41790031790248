export const fullScreenRunner = (element) => {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitrequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.mozRequestFullscreen) {
        element.mozRequestFullScreen();
    }
    return;
};

export const fullScreenCanceler = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
        //@ts-ignore
    } else if (document?.webkitExitFullscreen) {
        //@ts-ignore
        document?.webkitExitFullscreen();
        //@ts-ignore
    } else if (document.mozCancelFullScreen) {
        //@ts-ignore
        document?.mozCancelFullScreen();
        //@ts-ignore
    } else if (document.msExitFullscreen) {
        //@ts-ignore
        document?.msExitFullscreen();
    }
};
