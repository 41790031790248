import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import pattern from "src/assets/pattern.png";
import patternGreen from "src/assets/patternGreen.png";
import { appColors, backgroundColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    dialog: {
        padding: "0px 9px",
        backdropFilter: "blur(3px)",
        "&.fastGame": {
            backdropFilter: "none",
            position: "absolute",
            padding: "10px",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            "@media (max-height: 750px)": {
                justifyContent: "space-between",
            },
        },
    },
    dialogPaperConfirmNewGame: {
        maxWidth: 680,
        width: "100%",
        border: "1px solid rgba(255, 255, 255, 0.25)",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "25px 10px",
        margin: 0,
        overflowY: "unset",
        background: "#070707 !important",
        borderRadius: "24px",
        gridGap: "20px",
        color: "white",
        fontFamily: "Montserrat",
        // '&.darkBlue': {
        //     background:
        //         'radial-gradient(40.69% 165.03% at 51.57% 62.5%, #343A59 0%, rgba(52, 58, 89, 0.437526) 45.01%, rgba(52, 58, 89, 0) 100%), #202233',
        // },
        // '&.darkGreen': {
        //     background: '#374949',
        // },
        [theme.breakpoints.down("md")]: {
            maxWidth: "95%",
        },
        "@media screen and (max-width: 768px) and (max-height: 680px)": {
            minHeight: "98%",
            gridGap: "10px",
            justifyContent: "space-between",
        },
        "@media screen and (max-height: 650px)": {
            transform: "scale(0.9)",
            padding: 10,
            maxHeight: "unset",
        },
        "@media screen and (max-height: 500px)": {
            transform: "scale(0.8)",
        },
        "@media screen and (max-height: 450px)": {
            transform: "scale(0.7)",
        },
        "@media screen and (max-height: 400px)": {
            transform: "scale(0.6)",
        },
        "@media screen and (max-height: 350px)": {
            transform: "scale(0.5)",
        },
        [theme.breakpoints.down(300)]: {
            padding: "0px!important",
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down(375)]: {
            padding: "15px 10px 15px 10px",
        },
    },
    pattern: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0.5,
        mixBlendMode: "soft-light",
        zIndex: 0,
        backgroundImage: `url(${pattern})`,
    },
    title: {
        fontWeight: 700,
        fontSize: 24,
        lineHeight: "100%",
        textShadow: "0px 3.19791px 0px rgba(0, 0, 0, 0.15)",
        margin: 0,
        marginBottom: 16,
        color: appColors.white,
        fontFamily: "Montserrat",
    },
    userInfoHolder: {
        margin: "47px 0 0",
    },

    buttonGroup: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: 18,
        marginTop: 24,
        zIndex: 1,
    },
    button: {
        width: "100%",
        "& .steel-wrapper, & .black-wrapper, & .button": {
            width: "100%",
        },
    },
});
