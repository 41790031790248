import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    dialog: {
        '& .MuiDialog-paper': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderRadius: '12px',
            color: 'white',
            padding: '20px',
            maxWidth: '80vh',
            width: '100%',
            maxHeight: '90vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '1px solid slategrey'
            }
        },
        '& .MuiDialogContent-root': {
            padding: 0,
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: 'white',
    },
    title: {
        textAlign: 'center',
        marginBottom: '5px',
        fontSize: '1.5rem',
        fontFamily: 'Montserrat',
    },
    subtitle: {
        textAlign: 'center',
        fontSize: 15,
        fontFamily: 'Montserrat',
    },
    input: {
        '& .MuiOutlinedInput-root': {
            color: 'white',
            backgroundColor: 'rgba(52, 73, 94, 0.5)',
            borderRadius: '8px',
            height: '40px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
            '& input': {
                padding: '10px 14px',
            },
        },
        marginBottom: '10px',
    },
    multilineInput: {
        '& .MuiOutlinedInput-root': {
            height: 'auto',
        },
    },
    submitButton: {
        backgroundColor: '#27ae60',
        color: 'white',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        '&:hover': {
            backgroundColor: '#2ecc71',
        },
        marginTop: '10px',
    }
});