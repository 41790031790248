import React, { useEffect, useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react";

import { useStyles } from "./styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import coinIcon from "src/assets/icons/coin.svg";
import arrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import arrowRIcon from "src/assets/icons/RA.svg";
import { ITournament } from "../../../../store/models";
import useStores from "../../../../hooks/useStores";
import { Theme, useMediaQuery } from "@mui/material";
import { BracketsContainerProps, LocationState } from "./types";
import generateTournamentBracket from "./generateTournamentBracket";
import TournamentLines from "./components/tournamentLines";
import BracketColumn from "./components/bracketColumn";
import MatchPair from "./components/matchPair";
import BracketCarousel from "./components/bracketCarousel";
import { testPlaces } from "./constants";
import { fileHelper } from "../../../../shared/helpers/FileHelper";
import { useLocale } from "src/providers/LocaleProvider";


const BracketsContainerNew: React.FC<BracketsContainerProps> = observer(() => {
    const classes = useStyles({ matchIndex: 0, marginTop: 0, columnIndex: 0 });
    const location = useLocation<LocationState>();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { tournamentsStore } = useStores();
    const backgroundImage = location.state?.backgroundImage;
    const [tournament, setTournament] = useState<ITournament | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const {
        lobby: {
            tournaments: {
                wins, 
                back, 
                upTitle,
                ratTitle,
            },
        },
    } = useLocale();

    useEffect(() => {
        const loadTournament = async () => {
            if (!id) return;

            try {
                setIsLoading(true);
                const response = await tournamentsStore.getTournamentDetails(id);
                console.log("Tournament data:", response?.data?.tournament); 
                if (response?.data?.tournament) {
                    setTournament(response.data.tournament);
                }
            } catch (error) {
                console.error("Error loading tournament:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadTournament();
    }, [id]);

    useEffect(() => {
        const mainContainerEl = document.querySelector('.makeStyles-mainContainer-9') as HTMLElement;
        if (mainContainerEl && tournament?.imageURL) {
            // фоновое изображение для основного контейнера
            mainContainerEl.style.backgroundImage = `url(${fileHelper.getMediaFileURL(tournament.imageURL)})`;
            mainContainerEl.style.backgroundSize = 'cover';
            mainContainerEl.style.backgroundPosition = 'center';
            mainContainerEl.style.backgroundRepeat = 'no-repeat';
            mainContainerEl.style.backgroundColor = '#000';
            mainContainerEl.style.position = 'relative';

            //  div для градиента
            let gradientOverlay = mainContainerEl.querySelector('.gradient-overlay') as HTMLDivElement | null;
            if (!gradientOverlay) {
                gradientOverlay = document.createElement('div') as HTMLDivElement;
                gradientOverlay.className = 'gradient-overlay';
                gradientOverlay.style.position = 'absolute';
                gradientOverlay.style.top = '0';
                gradientOverlay.style.left = '0';
                gradientOverlay.style.right = '0';
                gradientOverlay.style.bottom = '0';
                gradientOverlay.style.background = 'linear-gradient(180deg, rgba(5, 5, 5, 0.25) 0%, rgba(5, 5, 5, 0.98) 42%, #050505 88.12%)';
                gradientOverlay.style.pointerEvents = 'none';
                mainContainerEl.appendChild(gradientOverlay);
            }
        }

        return () => {
            if (mainContainerEl) {
                const gradientOverlay = mainContainerEl.querySelector('.gradient-overlay');
                if (gradientOverlay) {
                    gradientOverlay.remove();
                }
                mainContainerEl.style.backgroundImage = '';
                mainContainerEl.style.backgroundSize = '';
                mainContainerEl.style.backgroundPosition = '';
                mainContainerEl.style.backgroundRepeat = '';
                mainContainerEl.style.backgroundColor = '';
                mainContainerEl.style.position = '';
            }
        };
    }, [tournament]);

    if (!tournament) {
        return (
            <div className={classes.errorContainer}>
                
            </div>
        );
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleBackClick = () => {
        history.push('/tournaments');
    };

    const bracket = generateTournamentBracket(tournament);

    const gameSeries = tournament.gameSettings?.matchCount || 2;

    return (
        <div className={classes.root}>
            <div
                className={classes.backgroundImage}
            />
            <div className={classes.gradientOverlay} />
            <div className={classes.content}>
                {isMobile ? (
                    <div className={classes.headerMobile}>
                        <button className={classes.backButtonMobile} onClick={handleBackClick}>
                            <img className={classes.arrowRight} src={arrowRIcon} alt="back" />
                            {back('Back')}
                        </button>
                        <div className={classes.titleMobile}>
                            {tournament.title}
                            <div className={classes.prizeInfoMobile}>
                                <img className={classes.coin} src={coinIcon} alt="coin" />
                                <span className={classes.gradientSpan}>{tournament.totalPrize}</span>
                                <div className={classes.dropdownWrapper}>
                                    <img
                                        className={cn(classes.arrowDown, {
                                            [classes.arrowDownRotated]: isDropdownOpen,
                                        })}
                                        src={arrowIcon}
                                        alt="arrow"
                                        onClick={toggleDropdown}
                                    />
                                    {isDropdownOpen && (
                                        <div className={classes.dropdownContent}>
                                            <div className={classes.placeWrapper}>
                                                {testPlaces.map((place, index) => (
                                                    <div key={index} className={classes.placeRow}>
                                                        <span className={classes.placeText}>{place.place}</span>
                                                        <div className={classes.rewardText}>
                                                            <img src={coinIcon} alt="Coin" className={classes.coinIcon} />
                                                            <span className={classes.gradientText}>{place.coins}</span>
                                                            <span className={classes.regularText}> + {place.rp} {ratTitle('RP')}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={classes.dateInfoMobile}>
                            {upTitle('Up to')} <span style={{ fontWeight: 700 }}>{tournament.gameSettings.matchCount}</span> {wins('wins ')} • {" "}
                            <span style={{ fontWeight: 700 }}>
                                {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className={classes.header}>
                        <button className={classes.backButton} onClick={handleBackClick}>
                            <img className={classes.arrowRight} src={arrowRIcon} alt="back" />
                            {back('Back')}
                        </button>
                        <div className={classes.titleContainer}>
                            <div className={classes.title}>
                                <div className={classes.titleText}>
                                    {tournament.title}
                                    <img className={classes.coin} src={coinIcon} alt="coin" />
                                    <span className={classes.gradientSpan}>{tournament.totalPrize}</span>
                                    <div className={classes.dropdownWrapper}>
                                        <img
                                            className={cn(classes.arrowDown, {
                                                [classes.arrowDownRotated]: isDropdownOpen,
                                            })}
                                            src={arrowIcon}
                                            alt="arrow"
                                            onClick={toggleDropdown}
                                        />
                                        {isDropdownOpen && (
                                            <div className={classes.dropdownContent}>
                                                <div className={classes.placeWrapper}>
                                                    {testPlaces.map((place, index) => (
                                                        <div key={index} className={classes.placeRow}>
                                                            <span className={classes.placeText}>{place.place}</span>
                                                            <div className={classes.rewardText}>
                                                                <img src={coinIcon} alt="Coin" className={classes.coinIcon} />
                                                                <span className={classes.gradientText}>{place.coins}</span>
                                                                <span className={classes.regularText}> + {place.rp} {ratTitle('RP')}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.dateInfo}>
                                    {upTitle('Up to')} <span style={{ fontWeight: 700 }}>{tournament.gameSettings.matchCount}</span> {wins('wins ')} • {" "}
                                <span style={{ fontWeight: 700 }}>
                                    {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {isMobile ? (
                    <BracketCarousel bracket={bracket} classes={classes} />
                ) : (
                    <div className={classes.bracket}>
                        <TournamentLines bracket={bracket} />
                            {bracket.map((round, roundIndex) => (
                                <BracketColumn key={round.title} title={round.title} columnIndex={roundIndex}>
                                    {round.matches.map((match, matchIndex) => (
                                        <div
                                            className={cn({
                                                [classes.singleMatch]: !match.player2 || match.player2.name === "TBD",
                                            })}
                                        >
                                            <MatchPair
                                                key={matchIndex}
                                                player1={match.player1}
                                                player2={match.player2}
                                                columnIndex={roundIndex}
                                                matchIndex={matchIndex}
                                                isBye={!match.player2 || match.player2.name === "TBD"}
                                                totalColumns={bracket.length} 
                                            />
                                        </div>
                                    ))}
                                </BracketColumn>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
});


export default BracketsContainerNew;