import { ReactComponent as Pawn3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/pawn-3-reached.svg";
import { ReactComponent as Pawn2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/pawn-2-reached.svg";
import { ReactComponent as Pawn1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/pawn-1-reached.svg";
import { ReactComponent as Knight3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/knight-3-reached.svg";
import { ReactComponent as Knight2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/knight-2-reached.svg";
import { ReactComponent as Knight1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/knight-1-reached.svg";
import { ReactComponent as Bishop3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/bishop-3-reached.svg";
import { ReactComponent as Bishop2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/bishop-2-reached.svg";
import { ReactComponent as Bishop1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/bishop-1-reached.svg";
import { ReactComponent as Rook3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/rook-3-reached.svg";
import { ReactComponent as Rook2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/rook-2-reached.svg";
import { ReactComponent as Rook1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/rook-1-reached.svg";
import { ReactComponent as Queen3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/queen-3-reached.svg";
import { ReactComponent as Queen2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/queen-2-reached.svg";
import { ReactComponent as Queen1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/queen-1-reached.svg";
import { ReactComponent as King3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/king-3-reached.svg";
import { ReactComponent as King2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/king-2-reached.svg";
import { ReactComponent as King1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/king-1-reached.svg";
import { ReactComponent as GM3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/gm-3-reached.svg";
import { ReactComponent as GM2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/gm-2-reached.svg";
import { ReactComponent as GM1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/gm-1-reached.svg";
import { ReactComponent as Elite3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/elite-3-reached.svg";
import { ReactComponent as Elite2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/elite-2-reached.svg";
import { ReactComponent as Elite1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/elite-1-reached.svg";
import { ReactComponent as Pro3ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/pro-3-reached.svg";
import { ReactComponent as Pro2ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/pro-2-reached.svg";
import { ReactComponent as Pro1ReachedIconComponent } from "src/assets/icons/chessmen/profile/reached/pro-1-reached.svg";
import { ReactComponent as Pawn3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/pawn-3-not-reached.svg";
import { ReactComponent as Pawn2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/pawn-2-not-reached.svg";
import { ReactComponent as Pawn1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/pawn-1-not-reached.svg";
import { ReactComponent as Knight3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/knight-3-not-reached.svg";
import { ReactComponent as Knight2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/knight-2-not-reached.svg";
import { ReactComponent as Knight1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/knight-1-not-reached.svg";
import { ReactComponent as Bishop3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/bishop-3-not-reached.svg";
import { ReactComponent as Bishop2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/bishop-2-not-reached.svg";
import { ReactComponent as Bishop1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/bishop-1-not-reached.svg";
import { ReactComponent as Rook3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/rook-3-not-reached.svg";
import { ReactComponent as Rook2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/rook-2-not-reached.svg";
import { ReactComponent as Rook1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/rook-1-not-reached.svg";
import { ReactComponent as Queen3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/queen-3-not-reached.svg";
import { ReactComponent as Queen2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/queen-2-not-reached.svg";
import { ReactComponent as Queen1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/queen-1-not-reached.svg";
import { ReactComponent as King3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/king-3-not-reached.svg";
import { ReactComponent as King2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/king-2-not-reached.svg";
import { ReactComponent as King1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/king-1-not-reached.svg";
import { ReactComponent as GM3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/gm-3-not-reached.svg";
import { ReactComponent as GM2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/gm-2-not-reached.svg";
import { ReactComponent as GM1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/gm-1-not-reached.svg";
import { ReactComponent as Elite3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/elite-3-not-reached.svg";
import { ReactComponent as Elite2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/elite-2-not-reached.svg";
import { ReactComponent as Elite1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/elite-1-not-reached.svg";
import { ReactComponent as Pro3NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/pro-3-not-reached.svg";
import { ReactComponent as Pro2NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/pro-2-not-reached.svg";
import { ReactComponent as Pro1NotReachedIconComponent } from "src/assets/icons/chessmen/profile/not-reached/pro-1-not-reached.svg";

export interface IRankIcon {
    outlined: any;
    contained: any;
    color: string;
}

export const rankIconsList: {
    [key: string]: IRankIcon;
} = {
    pawn3: {
        outlined: Pawn3NotReachedIconComponent,
        contained: Pawn3ReachedIconComponent,
        color: "#22D76D",
    },
    pawn2: {
        outlined: Pawn2NotReachedIconComponent,
        contained: Pawn2ReachedIconComponent,
        color: "#30CE9F",
    },
    pawn1: {
        outlined: Pawn1NotReachedIconComponent,
        contained: Pawn1ReachedIconComponent,
        color: "#60E0E3",
    },

    knight3: {
        outlined: Knight3NotReachedIconComponent,
        contained: Knight3ReachedIconComponent,
        color: "#0BA0E0",
    },
    knight2: {
        outlined: Knight2NotReachedIconComponent,
        contained: Knight2ReachedIconComponent,
        color: "#147BCE",
    },
    knight1: {
        outlined: Knight1NotReachedIconComponent,
        contained: Knight1ReachedIconComponent,
        color: "#1968C4",
    },

    bishop3: {
        outlined: Bishop3NotReachedIconComponent,
        contained: Bishop3ReachedIconComponent,
        color: "#3971E3",
    },
    bishop2: {
        outlined: Bishop2NotReachedIconComponent,
        contained: Bishop2ReachedIconComponent,
        color: "#3B51EA",
    },
    bishop1: {
        outlined: Bishop1NotReachedIconComponent,
        contained: Bishop1ReachedIconComponent,
        color: "#2031C9",
    },

    castle3: {
        outlined: Rook3NotReachedIconComponent,
        contained: Rook3ReachedIconComponent,
        color: "#A484FF",
    },
    castle2: {
        outlined: Rook2NotReachedIconComponent,
        contained: Rook2ReachedIconComponent,
        color: "#A055D7",
    },
    castle1: {
        outlined: Rook1NotReachedIconComponent,
        contained: Rook1ReachedIconComponent,
        color: "#A93EC7",
    },

    queen3: {
        outlined: Queen3NotReachedIconComponent,
        contained: Queen3ReachedIconComponent,
        color: "#BD00C0",
    },
    queen2: {
        outlined: Queen2NotReachedIconComponent,
        contained: Queen2ReachedIconComponent,
        color: "#BA0987",
    },
    queen1: {
        outlined: Queen1NotReachedIconComponent,
        contained: Queen1ReachedIconComponent,
        color: "#B60F55",
    },

    king3: {
        outlined: King3NotReachedIconComponent,
        contained: King3ReachedIconComponent,
        color: "#6C50BA",
    },
    king2: {
        outlined: King2NotReachedIconComponent,
        contained: King2ReachedIconComponent,
        color: "#5831C6",
    },
    king1: {
        outlined: King1NotReachedIconComponent,
        contained: King1ReachedIconComponent,
        color: "#5316FF",
    },

    gm3: {
        outlined: GM3NotReachedIconComponent,
        contained: GM3ReachedIconComponent,
        color: "#DBC820",
    },
    gm2: {
        outlined: GM2NotReachedIconComponent,
        contained: GM2ReachedIconComponent,
        color: "#FFC731",
    },
    gm1: {
        outlined: GM1NotReachedIconComponent,
        contained: GM1ReachedIconComponent,
        color: "#FFA115",
    },

    elit3: {
        outlined: Elite3NotReachedIconComponent,
        contained: Elite3ReachedIconComponent,
        color: "#FA6C00",
    },
    elit2: {
        outlined: Elite2NotReachedIconComponent,
        contained: Elite2ReachedIconComponent,
        color: "#DB4810",
    },
    elit1: {
        outlined: Elite1NotReachedIconComponent,
        contained: Elite1ReachedIconComponent,
        color: "#DD2800",
    },

    pro3: {
        outlined: Pro3NotReachedIconComponent,
        contained: Pro3ReachedIconComponent,
        color: "#CE2133",
    },
    pro2: {
        outlined: Pro2NotReachedIconComponent,
        contained: Pro2ReachedIconComponent,
        color: "#ED4A5E",
    },
    pro1: {
        outlined: Pro1NotReachedIconComponent,
        contained: Pro1ReachedIconComponent,
        color: "#ED4A5E",
    },
};
