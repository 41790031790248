import { makeStyles } from '@mui/styles';
import { media } from 'src/utils/media';
import theme from 'src/theme';
import { MAX_XL_TABLET } from 'src/pages/game-new/constants';

export const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
    },
    balanceWrapper: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0, // Важно для корректной работы flexbox
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [theme.breakpoints.up('lg')]: {
            padding: '0',
            minWidth: '90px',
            '&.gold': {
                // background: 'transparent',
                // backgroundImage:
                //     'linear-gradient(19.92deg, #0E0F0F 13.3%, #0E0F0F 86.7%), linear-gradient(#F8BF44C2, #A16529)',
                // backgroundClip: 'padding-box, border-box',
                // backgroundOrigin: 'border-box',
                // border: '1px solid transparent',
                // '&:hover': {
                //     cursor: 'pointer',
                //     transition: 'all 0.7s',
                //     boxShadow: '0px 0px 20px 0px rgba(255, 187, 124, 0.5)',
                //     borderRadius: '12px',
                // },
            },
            '&.silver': {
                // background: 'transparent',
                // backgroundImage:
                //     'linear-gradient(19.92deg, #0E0F0F 13.3%, #0E0F0F 86.7%), linear-gradient(#e9eefc6e, #c2c2c291)',
                // backgroundClip: 'padding-box, border-box',
                // backgroundOrigin: 'border-box',
                // border: '1px solid transparent',
                // '&:hover': {
                //     cursor: 'pointer',
                //     transition: 'all 0.7s',
                //     boxShadow: '0px 0px 20px 0px silver',
                //     borderRadius: '12px',
                // },
            },
        },
        [theme.breakpoints.down('lg')]: {
            '&.noBackground': {
                background: 'none',
                border: 'none',
                borderRadius: '1vw',
            },
        },
        [theme.breakpoints.down('md')]: {
            padding: '1vw',
        },
    },
    balancesContainer: {
        position: 'relative',
    },
    balanceWrapperSkeleton: {
        opacity: 0,
        visibility: 'hidden',
    },
    mainBalanceWrapper: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%', 
        minWidth: '100px',
        [theme.breakpoints.down('lg')]: {
            minWidth: '90px',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '80px',
        },
    },
    amountWrapper: {
        position: 'relative',
        justifyContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        flex: 1, 
        minWidth: '50px',
        height: '100%',
        padding: 0, 
        margin: 0, 
        [theme.breakpoints.down('sm')]: {
            minWidth: '40px',
        },
    },
    amount: {
        position: 'relative',
        zIndex: 1,
        display: 'inline-block',
        marginRight: 0,
        background: 'linear-gradient(180deg, #F8BF44 50%, #A16529 100%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        MozBackgroundClip: 'text',
        color: 'transparent',
        WebkitTextFillColor: 'transparent',
        textShadow: '0px 1.13216px 3.39647px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.up('lg')]: {
            fontSize: media(18, 30, 1022),
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.1vw',
        },
        [theme.breakpoints.down('md')]: {
            width: 'auto !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    amountCoins: {
        textShadow: '0px 1.13216px 3.39647px rgba(0, 0, 0, 0.25)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        background: 'linear-gradient(180deg, #e9eefc 50%, #c2c2c2 100%)',
        marginRight: 0, 
        marginLeft: 0, 
        display: 'inline !important',
        position: 'relative',
        zIndex: 1,
        [theme.breakpoints.up('lg')]: {
            fontSize: media(20, 32, 1024),
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('md')]: {
            width: 'auto!important',
        },
        [theme.breakpoints.down('sm')]: {
            //lineHeight: '1vw',
            fontSize: 18,
            marginLeft: 2,
        },
    },
    amountBase: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    amountSkeleton: {
        opacity: 0,
    },
    iconWrapper: {
        flexShrink: 0,
        width: 22,
        height: 22,
        marginRight: '8px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '0',

        },
    },
    coinIcon: {
        width: 20,
        height: 20,
        objectFit: 'cover',
        marginRight: '0.5vw',
        [theme.breakpoints.down('md')]: {
            width: 19,
            height: 19,
            marginRight: '1.5vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: 19,
            height: 19,
            marginRight: '1.5vw',
        },
    },
    hiddenBalance: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 4,
        right: 0,
        bottom: 0,
        fontSize: '1vw',
        zIndex: 2,
        '& > span': {
            display: 'inline !important',
            textShadow: '0px 1.13216px 3.39647px rgba(0, 0, 0, 0.25)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            background: 'linear-gradient(180deg, #F8BF44 50%, #A16529 100%)',
            backgroundClip: 'text',
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.5vw',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '2vw',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.5vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vw',

        },
    },

});
