import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new/constants";

const useStyles = makeStyles({
    figuresAlertWrapper: {
        opacity: 1,
        background: "#262C4C",
        filter: "drop-shadow(0px 9.65126px 9.65126px rgba(0, 0, 0, 0.25))",
        width: "fit-content",
        minWidth: "250px",
        height: "280px",
        marginTop: "0%",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid gray",
        borderRadius: "12px",
        //

        "&.darkBlue": {
            background:
                "linear-gradient(87.41deg, #101320 28.82%, #1F2336 120.76%)",
            "@media (orientation: portrait)": {
                border: "0px solid #343A59",
            },
        },
        "&.darkGreen": {
            background:
                "linear-gradient(19.92deg, rgba(1, 1, 1, 0.7) 13.3%, rgba(14, 15, 15, 0.7) 86.7%)",
            "@media (orientation: portrait)": {
                border: "0px solid #343A59",
            },
        },
        "&.deepBlack": {
            background:
                "linear-gradient(87.41deg, rgba(0,0,0,0.8) 28.82%, rgba(0,0,0,1) 120.76%)",
            width: "100%",
            minWidth: "250px",
            marign: "0px",
            padding: "6px",
            height: "fit-content",
            gridGap: "4px",
            marginTop: "12px",
            [theme.breakpoints.down("lg")]: {},
            "@media (orientation: portrait)": {
                border: "0px solid #343A59",
                //
            },
        },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_XL_TABLET)]: {
            "@media (orientation: landscape)": {
                marginTop: "20%",
                minWidth: "240px",
                transform: "scale(0.9)",
            },
        },
    },
    mobileFiguresAlertWrapper: {
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        justifyContent: "center",
        border: "1px solid rgba(255,255,255,0.3)",
        padding: "5px 12px",
        gridGap: "4px",
        height: "fit-content",
        borderRadius: "8px",
        width: "fit-content",
    },

    chooseFigureAlert: {
        fontWeight: 500,
        fontSize: 28,
        // marginTop: "30px",
        marginTop: "0px",
        color: appColors.white,
        width: "100%",
        display: "inline-block",
        textAlign: "center",
        textShadow: "0px 1.13216px 3.39647px rgba(0, 0, 0, 0.25)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        margin: 0,
        background:
            "linear-gradient(180deg, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0.51)  100%)",
        [theme.breakpoints.down("xl")]: {
            fontWeight: 500,
            fontSize: 22,
            marginTop: 0,
        },
        [theme.breakpoints.down("lg")]: {
            fontWeight: 500,
            fontSize: 16,
            marginTop: 0,
        },
        [`@media screen and (max-width: 430px) and (max-height: 780px)`]: {
            fontSize: 14,
        },
        [`@media screen and (max-width: 430px) and (max-height: 720px)`]: {
            fontSize: 13,
        },
        [`@media screen and (max-width: 430px) and (max-height: 660px)`]: {
            fontSize: 12,
        },
    },

    buttonsCenter: {
        margin: "0 auto",
        // paddingTop: 30,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0 10px",
        padding: "0px",
        paddingTop: 0,
        gridGap: "14px",
        [theme.breakpoints.down("lg")]: {
            height: "100%",
            justifyContent: "center",
        },
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    wrapperRect: {
        position: "relative",
        width: "100%",
        aspectRatio: "1 / 1",
        background:
            "linear-gradient(180deg, rgba(200,200,200,1) 0%, rgba(255,255,255,1) 100%)",
        transition: "all .4s",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: "5px",
        // margin: "0 7px",
        opacity: "1",
        cursor: "pointer",
        "@media (hover: hover)": {
            opacity: "0.7",
            "&:hover": {
                transform: "scale(1.2)",
                opacity: "1",
            },
        },
        [theme.breakpoints.down("lg")]: {
            width: "auto",
            aspectRatio: "1 / 1",
        },
    },

    rect: {
        // width: "80px",
        // height: "80px",
        width: "100%",
        height: "100%",
        backgroundColor: "#EBE7EA",
        borderRadius: 8,
        flexBasis: "0% important",
        background: "#rgb(200,200,200)",
        outline: "2px solid white",
        borderRadius: "10px",
        background:
            "-moz-linear-gradient(0deg, rgba(190,190,190,1) 0%, rgba(255,255,255,1) 85%)",
        background:
            "-webkit-linear-gradient(0deg, rgba(190,190,190,1) 0%, rgba(255,255,255,1) 85%)",
        background:
            "linear-gradient(0deg, rgba(190,190,190,1) 0%, rgba(255,255,255,1) 85%)",
    },
});

export default useStyles;
