import React, { memo } from 'react';
import cn from 'classnames';

import sadBlockedChatSmile from 'src/assets/icons/gameChatIcons/sadBlockedChatSmile.svg';
import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

interface IBlockedMessages {
    message: string;
}

const BlockMessages = ({ message }: IBlockedMessages) => {
    const appearanceTheme = useChangeTheme();
    const styles = useStyles();

    return (
        <div className={cn(styles.blockedChatWrapper, appearanceTheme)}>
            <img
                className={cn(styles.blockChatSmile, appearanceTheme)}
                src={sadBlockedChatSmile}
                alt='sad smile'
                role='img'
                aria-label='sad smile image'
            />
            <p className={cn(styles.blockChatText, appearanceTheme)}>
                {message}
            </p>
        </div>
    );
};

export default memo(BlockMessages);
