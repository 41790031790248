import React, { useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react";

import EngSVG from "src/assets/icons/languages/eng.svg";
import FrSVG from "src/assets/icons/languages/fr.svg";
import DeSVG from "src/assets/icons/languages/de.svg";
import RuSVG from "src/assets/icons/languages/ru.svg";
import KzSVG from "src/assets/icons/languages/kz.svg";
import UaSVG from "src/assets/icons/languages/ua.svg";
import arrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { ELanguages } from "src/store/models";

import { useStyles } from "./styles";

interface ILanguagePicker {
    showInDropper?: boolean;
    pickerPage?: string;
}

const LanguagePicker: React.FC<ILanguagePicker> = () => {
    const styles = useStyles();
    const { authStore, generalStore } = useStores();
    const { languages: { english, russian, french, german, kazakh, ukrainian } } = useLocale();
    const [isOpen, setIsOpen] = useState(false);
    const isHalyk = generalStore.isHalyk;

    const userLanguage = authStore.getLanguage();

    const languages = isHalyk
        ? [
            { label: russian("Русский"), value: ELanguages.RU, icon: RuSVG },
            { label: kazakh("Қазақша"), value: ELanguages.KZ, icon: KzSVG },
            { label: english("English"), value: ELanguages.EN, icon: EngSVG },
        ]
        : [
            { label: english("English"), value: ELanguages.EN, icon: EngSVG },
            { label: french("French"), value: ELanguages.FR, icon: FrSVG },
            { label: german("German"), value: ELanguages.DE, icon: DeSVG },
            { label: russian("Русский"), value: ELanguages.RU, icon: RuSVG },
            { label: ukrainian("Українська"), value: ELanguages.UA, icon: UaSVG },
        ];

    const { setActiveLocale } = useLocale();

    const handleChangeLanguage = async (language: ELanguages) => {
        if (language !== userLanguage) {
            authStore.changeLanguage(language);
            setActiveLocale(language);
            try {
                await authStore.updateGameSettings({ language });
            } catch (e) {
                console.error("Failed to update language settings", e);
            }
        }
        setIsOpen(false);
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const selectedLanguage = languages.find(lang => lang.value === userLanguage) || languages[0];

    return (
        <div className={isHalyk ? styles.halykLanguagePicker : styles.languagePicker}>
            <div
                className={isHalyk ? styles.halykSelectedLanguage : styles.selectedLanguage}
                onClick={toggleDropdown}
            >
                <img
                    src={selectedLanguage.icon}
                    alt={selectedLanguage.label}
                    className={isHalyk ? styles.halykLanguageIcon : styles.languageFlag}
                />
                <span className={isHalyk ? styles.halykLanguageLabel : styles.languageCode}>
                    {selectedLanguage.value.toUpperCase()}
                </span>
                <img
                    src={arrowIcon}
                    className={cn(styles.dropdownIcon, { [styles.dropdownIconOpen]: isOpen })}
                    alt="arrow"
                />
            </div>
            {isOpen && (
                <div className={isHalyk ? styles.halykDropdownMenu : styles.dropdownMenu}>
                    {languages.map((lang) => (
                        <div
                            key={lang.value}
                            className={cn(
                                isHalyk ? styles.halykMenuItem : styles.menuItem,
                                {
                                    [styles.menuItemSelected]: lang.value === userLanguage
                                }
                            )}
                            onClick={() => handleChangeLanguage(lang.value)}
                        >
                            <img
                                src={lang.icon}
                                alt={lang.label}
                                className={isHalyk ? styles.halykLanguageIcon : styles.languageFlag}
                            />
                            <span className={isHalyk ? styles.halykLanguageLabel : styles.languageCode}>
                                {lang.value.toUpperCase()}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default observer(LanguagePicker);
