import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MIN_L_TABLET,
} from "src/pages/game-new/constants";

// import bg from "./images/clockBackgroundFixed.svg";

export const useStyles = makeStyles({
    piecesWrapper: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        width: "100%",
        // flexDirection: "column",
        flexDirection: "row",
        "&.isMultiTable": {
            overflow: "hidden",
        },
    },
    killedPieces: {
        "&.isMultiTable": {
            "@media screen and (max-height: 900px)": {
                marginBottom: -2,
            },
        },
    },
    miniPieces: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
        width: "100%",
    },
    chessMenMargin: {
        // marginRight: -13,

        marginRight: 0,
        "&.isMultiTable": {
            marginRight: -10,
            [theme.breakpoints.down(1700)]: {
                marginRight: -8,
            },
            [theme.breakpoints.down(1450)]: {
                marginRight: -7,
            },
            "@media screen and (max-height: 900px)": {
                marginRight: -8,
            },
        },
        "&.isTwoGameTable": {
            marginRight: -10,
        },
        // "@media screen and (max-width: 768px)": {
        //     marginRight: -10,
        // },
        // "@media screen and (max-width: 520px)": {
        //     marginRight: -12,
        // },
        // "@media screen and (max-width: 450px)": {
        //     marginRight: -10,
        // },
    },
    piecesGroupWrapper: {
        display: "flex",
    },
    piecesGroupWrapperMargin: {
        marginRight: 5,
        "&.isMultiTable": {
            marginRight: 0,
            [theme.breakpoints.down(1450)]: {
                marginRight: 3,
            },
        },
        "&.isTwoGameTable": {
            marginRight: 4,
        },
        "@media screen and (max-width: 768px)": {
            marginRight: 3,
        },
        "@media screen and (max-width: 600px)": {
            marginRight: 0,
        },
        "@media screen and (max-width: 450px)": {
            marginRight: 0,
        },
    },

    pawnIconWrapper: {
        zIndex: 1,
        display: "block",
        opacity: 0.5,
        maxWidth: 55,
        maxHeight: 55,
        "&.isMultiTable svg": {
            width: 17,
            height: 17,
            [theme.breakpoints.down(1700)]: {
                width: 16,
                height: 16,
            },
            [theme.breakpoints.down(1600)]: {
                width: 15,
                height: 15,
            },
            "@media screen and (max-height: 900px)": {
                width: 14,
                height: 14,
            },
        },
        "&.isTwoGameTable svg": {
            width: 22,
            height: 22,
        },
        "&.isKilled": {
            opacity: 1,
        },
        "& svg": {
            width: "inherit",
            height: "inherit",
            maxWidth: 55,
            maxHeight: 55,
        },
    },
});
