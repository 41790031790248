import { useState, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";

import _get from "lodash/get";
import Box from "@mui/material/Box";
import { useSpring, animated } from "react-spring";
import { useLocale } from "src/providers/LocaleProvider";
import { getBeatCounter } from "src/utils/getBeatCounter";
import paths from "src/consts/paths";
import ViewModeModals from "src/pages/game-new2/components/modals/index";
import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import RiseAndCoinWrapper from "src/components/controls/bank/coinAnimation/riseAndCoinAnimation";
import CoinAnimationContainer from "src/components/controls/bank/coinAnimation/coinAnimationContainer";
import GameScore from "src/components/gameScore";
import Bank from "src/components/controls/bank";
import DicesMainWrapper from "src/components/DiceThreeJS";
import NormalScreenSVG from "src/assets/icons/profileAndSettings/normalScreenSVG";
import { EGameRematchStatus } from "src/store/models";
import { appColors } from "src/consts/app-config";
import {
    ChessboardType,
    ColorVariant,
    GameModeType,
    GameModeTypeNew,
    GameStatus,
    ScreenType,
} from "src/store/models";

import { useWinOrLostStatus } from "src/pages/game-new2/hooks/useWinOrLostStatus";
import { MAX_XL_TABLET } from "src/pages/game-new2/constants";

import useStores from "src/hooks/useStores";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";

import { DoublingStage } from "src/store/gameStore/dialogs";

import DicesWrapper from "src/pages/game-new2/components/dicesWrapper";

import X2AndDiceRedesign from "src/pages/game-new2/components/x2ButtonAndRollDice";
import GiveUpDialog from "src/pages/game-new2/alerts/giveUpDialog";
import RematchAlerts from "src/pages/game-new2/alerts/rematchAlerts";
import DrawDialog from "src/pages/game-new2/alerts/drawDialog/";
import PlayerThinking from "src/pages/game-new2/components/playerThinking";
import WinOrLostAlert from "src/pages/game-new2/alerts/winOrLostAlerts";
import X2Dialogs from "src/pages/game-new2/alerts/X2Dialogs";
import RakeBackModal from "src/pages/game-new2/components/modals/RakeBackModal";
import StartMatch from "src/pages/game-new2/components/startMatch";
import SelectFiguresAlertWrapper from "src/pages/game-new2/alerts/select-figure-alert/index";

import { useStyles } from "./styles";
import PlayerInfoRedesign from "src/components/PlayerInfoRedesign";

import WatchingList from "src/components/WatchingPlayers/components/WatchingList";
import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";

import { usePlayer } from "src/sounds/use-player";
import { PlayerInfoExistPosition } from "src/store/models";
import shortColor from "src/utils/shortColor";

import GameScoreRedesign from "src/components/gameScoreRedesign";

export interface RightLayoutProps {
    rndPrefix?;
    diceClickRnd?;
    setDiceClickRnd?;
}

const RightLayoutView = (): JSX.Element | null => {
    const {
        gameStore,
        authStore: { currentUser },
    } = useStores();
    const history = useHistory();
    const { isMultiTable, isTwoGameTable } = useChessBoardProps();
    const style = useStyles();
    const location = useLocation();
    const { showOpponentAcceptedDoublingDialog } = useDoublingStageStatus();
    const { isWon } = useWinOrLostStatus();
    const [randomValue, setRandomValue] = useState<number>(1);
    const {
        game: {
            dialogs: {
                draw: {
                    waitForOpponentToDecide: {
                        title: waitForOpponentToDecideTitle,
                    },
                },
            },
        },
    } = useLocale();

    const resign = gameStore?.gameState?.gameResultData?.cause;
    const winner = gameStore?.currentGameNew?.winner;
    const extra = gameStore?.currentGameNew?.extra;
    const isLeftBet = !!extra && !!extra.leftBet;
    const isRightBet = !!extra && !!extra.rightBet;
    // const game = lobbyStore.selectedGame;
    const game = gameStore.currentGameNew;
    const watchers = gameStore.currentGameNew?.viewers || [];

    const killedPieces = useMemo(() => {
        return gameStore.gameState.killedPiecesNew;
    }, [gameStore.stateNew.movesHistory]);

    const piecesBeatCounter = useMemo(() => {
        const data = getBeatCounter(killedPieces);
        return data;
    }, [killedPieces]);

    const activeClock = gameStore.gameState.activeClockView;
    const pWhitePieces = gameStore.currentGameNew?.players[0];
    const pBlackPieces = gameStore.currentGameNew?.players[1];

    const initTimeWhitePieces = useMemo(() => {
        if (!gameStore?.rightBlockCollector.clockData.length) return 0;
        const getOpponentTime = 0;
        const timeLeftMemo =
            gameStore?.rightBlockCollector.clockData[getOpponentTime];
        return timeLeftMemo || 0;
    }, [
        gameStore?.rightBlockCollector.clockData,
        gameStore.gameId,
        activeClock,
    ]);
    const initTimeBlackPieces = useMemo(() => {
        if (!gameStore?.rightBlockCollector.clockData.length) return 0;
        const getOpponentTime = 1;
        const timeLeftMemo =
            gameStore?.rightBlockCollector.clockData[getOpponentTime];
        return timeLeftMemo || 0;
    }, [
        gameStore?.rightBlockCollector.clockData,
        gameStore.gameId,
        activeClock,
    ]);

    //=====
    const userRakeBack = 123;
    const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
    const myMove = gameStore.isMyMove;
    const curMove = gameStore.currentGameNew?.curMove;
    const curChessMove = gameStore.currentGameNew?.curChessMove;

    // const gameType = gameStore.currentGame?.type;
    const gameType = gameStore.currentGameNew?.type;

    // const winner = gameStore?.gameState?.gameResultData?.winner;

    const viewMode = gameStore.isViewMode();
    // Сначала получаем цвет
    // const myColor = useMemo(() => {
    //     return viewMode ? ColorVariant.white : gameStore.gameState.getMyColor();
    // }, [viewMode]);
    //======
    const myColor = useMemo(() => {
        return gameStore.getMyColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    const opponentColor = useMemo(() => {
        return gameStore.getOpponentColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    // Затем получаем Наши данные по ранее полученному цвету
    // const meOrWhitePlayer = viewMode
    //     ? gameStore.gameState.getPlayerByColor(ColorVariant.white)
    //     : gameStore.gameState.getPlayerByColor(myColor);

    // const meOrWhitePlayer = useMemo(() => {
    //     return viewMode
    //         ? gameStore.gameState.getPlayerByColor(ColorVariant.white)
    //         : gameStore.gameState.getPlayerByColor(myColor);
    // }, [viewMode]);
    //=======

    useEffect(() => {
        if (currentUser?._id === curChessMove && curMove !== curChessMove) {
            return gameStore.dialogs.openThinkingDialog();
        }

        return gameStore.dialogs.closeThinkingDialog();
    }, [curMove, curChessMove, currentUser]);

    useEffect(() => {
        if (doubleThinking || showOpponentAcceptedDoublingDialog) {
            let generateRandomNumber = (Math.random() * 10) | 0;
            if (randomValue === generateRandomNumber) {
                setRandomValue(9 - generateRandomNumber);
            } else {
                setRandomValue(generateRandomNumber);
            }
        }
    }, [doubleThinking, showOpponentAcceptedDoublingDialog]);

    const isMultiRoundGame =
        gameStore.currentGame?.settings.rounds! > 1 ||
        gameType === GameModeTypeNew.REGULAR;

    const { authStore, lobbyStore, tournamentsStore, achievementsStatStore } =
        useStores();

    const soundPlayer = usePlayer();

    //
    const userCoinBalance = authStore.userCoinBalance;
    const userFreeCoinBalance = authStore.userFreeCoinBalance;
    const balanceType = gameStore.gameBalanceType;

    //Тут And Now Try To create depend wich contain some comditions^ if viewMode changed, get some funtions

    const yourColor = useMemo(() => {
        return viewMode
            ? ColorVariant.black
            : gameStore.gameState.getOpponentColor();
    }, [viewMode, location.pathname, gameStore.gameId]);

    // console.log("opponentTime", opponentTime, "myTime", myTime);

    const [elemHeight, setHeight] = useState<number | undefined>();

    const [isOpenWatchers, setOpenWatchers] = useState<
        boolean | WatchersStatusType
    >(false);
    const [chessBoardHeight, setChessBoardHeight] = useState<
        number | undefined
    >(undefined);
    const [uniqWatchers, setUniqWatchers] = useState<any>(watchers);
    const classes = useStyles({ elemHeight });
    const [isOpen, setIsOpen] = useState(true);
    const currentPlayerCanX2 = gameStore?.currentGameNew?.currentPlayerCanX2;

    const rematchInfo =
        gameStore.dialogs.rematchData.rematchStatus ===
        EGameRematchStatus.WAITING_FOR_ANSWER;

    const waitingBetApply = extra
        ? extra["waitingBetApply"] && curMove !== authStore.currentUser?._id
        : false;
    const springProps = useSpring({
        transform:
            doubleThinking && extra && extra["waitingBetApply"]
                ? "translateY(10%)"
                : "translateY(-101%)",
        config: { tension: 150, friction: 20 },
    });

    const springProps2 = useSpring({
        transform: rematchInfo ? "translateY(10%)" : "translateY(-101%)",
        config: { tension: 150, friction: 20 },
    });

    const getAllMyParticipatingGames = lobbyStore.getParticipatingGames();

    const isActivePlayer = (color, clock) =>
        shortColor(color) === _get(clock, "color", "unknown");

    // const getCurrentCashbox = useMemo(() => {
    //     if (balanceType === UserBalanceType.play) {
    //         return userCoinBalance;
    //     } else {
    //         return userFreeCoinBalance;
    //     }
    // }, [balanceType]);

    const getCurrentCashbox = useMemo(() => {
        if (balanceType === 0) {
            return userCoinBalance;
        } else {
            return userFreeCoinBalance;
        }
    }, [balanceType]);

    // const killedPieces = useMemo(() => {
    //     return gameStore.gameState.killedPieces;
    // }, [gameStore.gameState?.plainFen]);

    const activeDoublingModal =
        !gameStore.isShowWinOrLostDialog &&
        !waitingBetApply &&
        curMove !== curChessMove &&
        myMove;

    useEffect(() => {
        if (activeDoublingModal) {
            gameStore.dialogs.showDoublingDialogNew();
        }
    }, [activeDoublingModal]);

    if (!pBlackPieces || !pWhitePieces) return null;

    if (window.innerWidth >= MAX_XL_TABLET) {
        return (
            <div
                className={cn(style.rightPartNew, {
                    isGameOver: gameStore.gameState.isGameOver(),
                    isMultiTable,
                    isTwoGameTable,
                })}
            >
                <div
                    className={cn(
                        classes.viewModeModalsWrapper,
                        PlayerInfoExistPosition.inProgressPreviewFull,
                        "top"
                    )}
                >
                    <ViewModeModals player={pWhitePieces} />
                </div>

                <div
                    className={cn(style.normalScreenBox, {})}
                    onClick={() => history.push(paths.diceChessLobby)}
                >
                    <NormalScreenSVG
                        width={25}
                        height={25}
                        fillColor={appColors.white}
                    />
                </div>
                {gameStore.isShowWinOrLostDialog &&
                    userRakeBack &&
                    gameType === GameModeTypeNew.REGULAR && <RakeBackModal />}
                {pBlackPieces && !gameStore.dialogs.rematchDialog.isOpen && (
                    <PlayerInfoRedesign
                        resign={resign}
                        winner={winner}
                        noTimerSound
                        avatar={pBlackPieces.avatar}
                        name={pBlackPieces.nickname || "Unknown"}
                        initTime={initTimeBlackPieces}
                        activeClock={activeClock}
                        active={gameStore.isActiveViewPlayer(pBlackPieces?._id)}
                        killedPieces={killedPieces[ColorVariant.white]}
                        color={ColorVariant.black}
                        isSystemAvatar={pBlackPieces.isSystemAvatar}
                        piecesBeatCounter={
                            piecesBeatCounter[ColorVariant.black]
                        }
                        elementPlace={
                            PlayerInfoExistPosition.inProgressPreviewFull
                        }
                    />
                )}
                <div
                    className={cn(style.actionAndDiceCenterBox, {
                        isSoloGameEnd: gameStore.gameState.isGameOver(),
                        isMultiTable: isMultiTable,
                    })}
                >
                    <div className={cn(style.bankWrapperNew, {})}>
                        {/* {gameStore.currentGame?.settings.rounds === 1 &&
                            gameType === GameModeType.REGULAR && (
                                <Bank>
                                    <CoinAnimationContainer
                                        balanceType={
                                            gameStore?.currentGame?.balanceType
                                        }
                                        isMultiTable={isMultiTable}
                                        isTwoGameTable={isTwoGameTable}
                                        chessboardType={
                                            ChessboardType.desktopGame
                                        }
                                    />
                                </Bank>
                            )} */}
                        {[
                            GameModeTypeNew.REGULAR,
                            GameModeTypeNew.FREE,
                        ].includes(gameType as GameModeTypeNew) && (
                            <div
                                className={cn(style.bankContainer, {
                                    activeInit: isLeftBet,
                                    isWinnerWithReject:
                                        isLeftBet && !isRightBet && winner,
                                    acceptedAndFinished:
                                        isLeftBet && isRightBet && !winner,
                                    isWinnerAndHideBank: winner && isRightBet,
                                    solidWinner:
                                        winner && !isLeftBet && !isRightBet,
                                })}
                            >
                                <RiseAndCoinWrapper
                                    chessboardType={ChessboardType.desktopGame}
                                    view
                                />
                            </div>
                        )}
                        {(gameStore.currentGame?.settings?.rounds! > 1 ||
                            gameType === GameModeTypeNew.MATCH) && (
                            <GameScoreRedesign
                                winner={winner}
                                stage={
                                    gameStore?.currentGame?.tournament?.stage
                                }
                                // @ts-ignore
                                title={
                                    gameStore?.currentGame?.tournament?.title
                                }
                            />
                        )}
                    </div>

                    <div
                        className={cn(style.dicesWrapperNew, {
                            isWinner: winner,
                        })}
                    >
                        <DicesWrapper
                            rndPrefix={""}
                            diceClickRnd={123}
                            myColor={ColorVariant.white}
                            isMultiTable={false}
                            isDesktop
                            viewMode={true}
                            opponentColor={ColorVariant.black}
                        />
                    </div>

                    {!gameStore.gameState.isGameOver() && (
                        // Old logical
                        <div className={cn(style.showPopupContainerNew)}>
                            {/* {gameStore.dialogs.giveUpDialog.isOpen && (
                                <GiveUpDialog />
                            )}

                            {gameStore.dialogs.doublingDialog.isOpen &&
                                !gameStore.dialogs.giveUpDialog.isOpen && (
                                    <X2Dialogs randomValue={randomValue} />
                                )}
                            {gameStore.dialogs.drawDialog.isOpen && (
                                <DrawDialog isTabletMobile={false} />
                            )} */}
                            {/* {gameStore.gameState.isMyTurn() &&
                                !gameStore.gameState.hasSpecialActivity() &&
                                !viewMode && (
                                    // /Old logical
                                    <X2Button
                                        isTournamentGame={
                                            gameType === GameModeType.TOURNAMENT
                                        }
                                        isMultiTable={isMultiTable}
                                        isTwoGameTable={isTwoGameTable}
                                    />
                                )} */}
                        </div>
                    )}
                </div>
                <div
                    className={cn(
                        classes.viewModeModalsWrapper,
                        PlayerInfoExistPosition.inProgressPreviewFull,
                        "bottom"
                    )}
                >
                    <ViewModeModals player={pBlackPieces} />
                </div>
                {pWhitePieces && (
                    <PlayerInfoRedesign
                        resign={resign}
                        winner={winner}
                        noTimerSound={false}
                        avatar={pWhitePieces.avatar}
                        name={pWhitePieces.nickname || "Unknown"}
                        initTime={initTimeWhitePieces}
                        activeClock={activeClock}
                        active={gameStore.isActiveViewPlayer(pWhitePieces?._id)}
                        killedPieces={killedPieces[ColorVariant.black]}
                        color={ColorVariant.white}
                        reverse
                        isSystemAvatar={pWhitePieces.isSystemAvatar}
                        piecesBeatCounter={
                            piecesBeatCounter[ColorVariant.white]
                        }
                        elementPlace={
                            PlayerInfoExistPosition.inProgressPreviewFull
                        }
                    />
                )}
            </div>
        );
    }

    return null;
};
export default observer(RightLayoutView);
