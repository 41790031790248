import {useState, useLayoutEffect} from 'react';

export const usePreciesTimer = (
    timeDelay: number,
    status: boolean,
    fnForClear?: () => void
) => {
    const initValue = 0;
    const [actualtime, setActualTime] = useState<number>(initValue);

    useLayoutEffect(() => {
        let timeInterval;
        if (!status) {
            fnForClear ? fnForClear() : null;
            return () => {
                clearInterval(timeInterval);
                setActualTime(initValue);
            };
        }
        timeInterval = setInterval(() => {
            setActualTime(Date.now());
        }, timeDelay);
        if (timeInterval) {
            return () => clearInterval(timeInterval);
        }
    }, [timeDelay, status]);

    return actualtime;
};
