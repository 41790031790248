import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    avatarWrapper: {
        width: "fit-content",
        position: "relative",
        "&:hover $uploadHover": {
            // display: 'block',
            opacity: 1,
            transform: "translate(0, 0)",
            visibility: "visible",
        },
    },
    uploadHover: {
        position: "absolute",
        bottom: 0,
        background:
            "linear-gradient(0deg, rgba(84,84,84,0.3) 0%, rgba(84,84,84,0.3) 35%, rgba(255,255,255,0) 35%)",
        zIndex: 5,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        // display: 'none',
        opacity: 0,
        visibility: "hidden",
        transform: "translate(0, 10px)",
        transition: "0.2s",
    },
    uploadHoverContent: {
        width: "100%",
        height: "35%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        bottom: 0,
        cursor: "pointer",
    },
    isNotProfile: (props: { width: number }) => ({
        "&:before": {
            content: '""',
            display: "block",
            width: props.width / 2,
            height: props.width / 2,
            background:
                "radial-gradient(50.07% 50.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(234, 242, 255, 0.9612) 3.88%, rgba(179, 210, 255, 0.8533) 14.67%, rgba(132, 181, 255, 0.7416) 25.84%, rgba(91, 157, 255, 0.6281) 37.19%, rgba(58, 138, 255, 0.5127) 48.73%, rgba(32, 122, 255, 0.3945) 60.55%, rgba(14, 112, 255, 0.2725) 72.75%, rgba(4, 105, 255, 0.1445) 85.55%, rgba(0, 103, 255, 0) 100%)",
            mixBlendMode: "hard-light",
            opacity: 0.5,
            left: -props.width / 4 - (16 * props.width) / 200 + 1.5,
            top: `calc(50% - ${props.width / 4}px)`,
            position: "absolute",
            zIndex: 2,
        },
        "&:after": {
            content: '""',
            display: "block",
            width: props.width / 2,
            height: props.width / 2,
            background:
                "radial-gradient(50.07% 50.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(234, 242, 255, 0.9612) 3.88%, rgba(179, 210, 255, 0.8533) 14.67%, rgba(132, 181, 255, 0.7416) 25.84%, rgba(91, 157, 255, 0.6281) 37.19%, rgba(58, 138, 255, 0.5127) 48.73%, rgba(32, 122, 255, 0.3945) 60.55%, rgba(14, 112, 255, 0.2725) 72.75%, rgba(4, 105, 255, 0.1445) 85.55%, rgba(0, 103, 255, 0) 100%)",
            mixBlendMode: "hard-light",
            opacity: 0.5,
            left: `calc(50% - ${props.width / 4}px)`,
            top: -props.width / 4 - (16 * props.width) / 200 + 1.5,
            position: "absolute",
            zIndex: 2,
        },
    }),
    isProfile: (props: { width: number }) => ({
        "&:before": {
            content: '""',
            display: "block",
            width: props.width / 2,
            height: props.width / 2,
            background:
                "radial-gradient(49.96% 49.96% at 49.96% 49.96%, #FFFFFF 5.21%, rgba(0, 0, 0, 0) 100%)",
            mixBlendMode: "color-dodge",
            opacity: 0.5,
            position: "absolute",
            zIndex: 2,
            left: -props.width / 3.5,
            top: `calc(50% - ${props.width / 4}px)`,
        },
        "&:after": {
            content: '""',
            display: "block",
            width: props.width / 2,
            height: props.width / 2,
            background:
                "radial-gradient(49.96% 49.96% at 49.96% 49.96%, #FFFFFF 5.21%, rgba(0, 0, 0, 0) 100%)",
            mixBlendMode: "color-dodge",
            opacity: 0.5,
            position: "absolute",
            zIndex: 2,
            left: `calc(50% - ${props.width / 4}px)`,
            top: -props.width / 3.5,
        },
    }),
    avatarBlock: (props) => ({
        display: "flex",
        position: "relative",
        width: "fit-content",
    }),
    avatarStyled: (props) => ({
        width: props.width + (8 * props.width) / 100, 
        height: props.width + (8 * props.width) / 100, 
        zIndex: 0,
        position: "absolute",
        top: (-8 * props.width) / 200, 
        left: (-8 * props.width) / 200, 
    }),
});
