import React from 'react';
import { Avatar as AvatarMUI } from '@mui/material';

import { useStyles } from './styles';
import { AvatarStyled } from './images/AvatarStyled';
import { Camera } from './images/Camera';

interface Props {
    avatarUrl?: string;
    nickname: string;
    width: number;
    isProfile?: boolean;
    allowUpload?: boolean;
    onUpload?: () => void;
    isSystemAvatar?: any;
}
export const Avatar = ({
    avatarUrl,
    nickname,
    width,
    isProfile,
    allowUpload,
    onUpload,
    isSystemAvatar,
}: Props) => {
    // @ts-ignore
    const styles = useStyles({ width, isProfile });

    return (
        <div className={styles.avatarWrapper}>
            {avatarUrl ? (
                <div className={styles.avatarBlock}>
                    <AvatarMUI
                        variant='circular'
                        style={{
                            width: width,
                            height: width,
                            zIndex: 3,
                            backgroundColor: '#1a171b',
                            fontSize: width / 4,
                        }}
                        src={avatarUrl}
                        alt={nickname ? nickname.toUpperCase() : ''}
                    />
                    {!isSystemAvatar ? <AvatarStyled width={width} /> : null}
                    {allowUpload && (
                        <div className={styles.uploadHover}>
                            <div
                                className={styles.uploadHoverContent}
                                onClick={onUpload}
                            >
                                <Camera />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.avatarBlock}>
                    <AvatarMUI
                        variant='circular'
                        style={{
                            width: width,
                            height: width,
                            zIndex: 3,
                            backgroundColor: '#1a171b',
                            fontSize: width / 4,
                        }}
                    >
                        {nickname && nickname[0].toUpperCase()}
                    </AvatarMUI>
                    <AvatarStyled width={width} />
                    {allowUpload && (
                        <div className={styles.uploadHover}>
                            <div
                                className={styles.uploadHoverContent}
                                onClick={onUpload}
                            >
                                <Camera />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
