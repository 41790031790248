import React, { ReactNode } from 'react';

import { useStyles } from './styles';

export interface IListProps {
    children: ReactNode;
}

export const List = ({ children }: IListProps) => {
    const styles = useStyles();
    return <ul className={styles.list}>{children}</ul>;
};
