import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import ILoadingDots from './types/ILoadingDots';

const LoadingDots = ({
    size,
    color,
    time,
    delay,
    className,
    stopped,
}: ILoadingDots) => {
    const timeValues = {
        opacityInit: 1,
        timeMult: 2000,
        delayMult: 1000,
    };
    const { opacityInit, timeMult, delayMult } = timeValues;
    const classes = useStyles({ size: size, color: color, time: time });
    const [opacity, setOpacity] = useState(opacityInit);

    useEffect(() => {
        if (!stopped) {
            setTimeout(() => {
                setOpacity(opacity ? 0 : opacityInit);
            }, time * timeMult + opacity * delay * delayMult);
        } else {
            setOpacity(1);
        }
    }, [opacity, stopped]);

    return (
        <div className={className + ' ' + classes.root}>
            <span className={classes.first} style={{ opacity }} />
            <span className={classes.second} style={{ opacity }} />
            <span className={classes.third} style={{ opacity }} />
        </div>
    );
};

export default LoadingDots;
