import { observer } from "mobx-react";

import FadeInOutVertical from "src/components/Animated";

import { useDrawStageStatus } from "../../../../hooks/useDrawStageStatus";

import ConfirmDrawOfferingDialog from "./confirmDrawOfferingDialog";
import WaitingForOpponentToDecideDialog from "./waitingForOpponentToDecideDialog";
import AcceptOrRejectDraw from "./acceptOrRejectDraw";
import OpponentIsRejectedDraw from "./OpponentIsRejectedDraw";

const DrawDialog = ({ isTabletMobile }) => {
    const {
        isAcceptOrRejectDrawDialog,
        isConfirmDrawOfferingDialog,
        isWaitingForOpponentToDecideDialog,
        opponentIsRejectedDraw,
    } = useDrawStageStatus();

    if (isConfirmDrawOfferingDialog) {
        return (
            <FadeInOutVertical>
                <ConfirmDrawOfferingDialog />
            </FadeInOutVertical>
        );
    } else if (isWaitingForOpponentToDecideDialog) {
        return (
            <FadeInOutVertical>
                <WaitingForOpponentToDecideDialog />
            </FadeInOutVertical>
        );
    } else if (isAcceptOrRejectDrawDialog) {
        return (
            <FadeInOutVertical>
                <AcceptOrRejectDraw />
            </FadeInOutVertical>
        );
    } else if (opponentIsRejectedDraw) {
        return (
            <FadeInOutVertical>
                <OpponentIsRejectedDraw />
            </FadeInOutVertical>
        );
    }
    return null;
};

export default observer(DrawDialog);
