import { ChessMap } from '../chess/chess.map';
import { Figure } from '../chess/figure';
import { Movement } from '../chess/movement';

export class Bishop extends Figure {
  constructor(
    public isBlack: boolean,
    public chessMapRef: ChessMap | null = null,
  ) {
    super(isBlack, 'b', chessMapRef);
  }

  getMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    const movements: Movement[] = [];

    const vect = [
      { x: 1, y: 1, stop: false },
      { x: 1, y: -1, stop: false },
      { x: -1, y: 1, stop: false },
      { x: -1, y: -1, stop: false },
    ];

    for (let dt = 1; dt <= 8; dt++) {
      for (let id = 0; id < vect.length; id++) {
        if (vect[id].stop) continue;

        const toX = fromX + dt * vect[id].x;
        const toY = fromY + dt * vect[id].y;

        if (this.canMove(map, toX, toY, this.isBlack)) {
          movements.push(
            new Movement({
              rowFrom: fromX,
              columnFrom: fromY,
              rowTo: toX,
              columnTo: toY,
            }),
          );

          if (
            map.cells[toX][toY].figure &&
            map.cells[toX][toY].figure?.isBlack !== this.isBlack
          ) {
            vect[id].stop = true;
          }
        } else {
          vect[id].stop = true;
        }
      }
    }

    return movements;
  }
  getSafeMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    const movements: Movement[] = [];

    const vect = [
      { x: 1, y: 1, stop: false },
      { x: 1, y: -1, stop: false },
      { x: -1, y: 1, stop: false },
      { x: -1, y: -1, stop: false },
    ];

    for (let dt = 1; dt <= 8; dt++) {
      for (let id = 0; id < vect.length; id++) {
        if (vect[id].stop) continue;

        const toX = fromX + dt * vect[id].x;
        const toY = fromY + dt * vect[id].y;

        if (this.canMove(map, toX, toY, !this.isBlack)) {
          if (
            map.cells[toX][toY].figure &&
            map.cells[toX][toY].figure?.isBlack === this.isBlack
          ) {
            movements.push(
              new Movement({
                rowFrom: fromX,
                columnFrom: fromY,
                rowTo: toX,
                columnTo: toY,
              }),
            );
            vect[id].stop = true;
          }
        } else {
          vect[id].stop = true;
        }
      }
    }

    return movements;
  }
  getAttackMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
    const movements: Movement[] = [];

    const vect = [
      { x: 1, y: 1, stop: false },
      { x: 1, y: -1, stop: false },
      { x: -1, y: 1, stop: false },
      { x: -1, y: -1, stop: false },
    ];

    for (let dt = 1; dt <= 8; dt++) {
      for (let id = 0; id < vect.length; id++) {
        if (vect[id].stop) continue;

        const toX = fromX + dt * vect[id].x;
        const toY = fromY + dt * vect[id].y;

        if (this.canMove(map, toX, toY, this.isBlack)) {
          if (
            map.cells[toX][toY].figure &&
            map.cells[toX][toY].figure?.isBlack !== this.isBlack
          ) {
            movements.push(
              new Movement({
                rowFrom: fromX,
                columnFrom: fromY,
                rowTo: toX,
                columnTo: toY,
              }),
            );
          }
        } else {
          vect[id].stop = true;
        }
      }
    }

    return movements;
  }
}
