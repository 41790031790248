import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    listItem: {
        fontSize: 16,
        fontWeight: 400,
        color: '#866838',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
    listItemDot: {
        fontSize: 13,
        marginRight: 4,
    },
});
