
const sleep = (ms: number) => {
    return new Promise<void>((res, rej) => {
        setTimeout(() => {
            res()
        }, ms)
    })
}

export default sleep;
