import React, { useState } from "react";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import LazyImages from "src/components/lazyImages";
import { endpointsRoot } from "src/core/endpoints";
import { ITopPlayersNew } from "src/store/models";
import defaultAvatar from "src/assets/images/defaultPlaceholder.png";

import { useStyles } from "../styles";

interface ITopPlayerItemProps {
    place: number;
    topPlayer: ITopPlayersNew;
    myPosition?: boolean | undefined;
}

export const TopPlayerItem = ({
    place,
    topPlayer,
    myPosition,
}: ITopPlayerItemProps) => {
    const classes = useStyles();
    const {
        profile: {
            status: { rating },
        },
    } = useLocale();
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const avatar = !!topPlayer.avatar
        ? `${endpointsRoot}/avatars/${topPlayer.avatar}`
        : defaultAvatar;

    return (
        <div
            className={cn(classes.itemWrapper, `place-${place}`, {
                myPosition: myPosition,
            })}
            key={place}
        >
            <div className={classes.itemInfoWrapper}>
                <LazyImages
                    className={cn(classes.itemAvatar, {
                        isLoaded: !isLoaded,
                    })}
                    placeholderImg={defaultAvatar}
                    src={avatar}
                    errorImg={defaultAvatar}
                    alt={topPlayer.nickname}
                    setLoaded={(status: boolean) => setLoaded(status)}
                />
                <div className={classes.itemNameAndRating}>
                    <span className={classes.itemName}>
                        {topPlayer.nickname}
                    </span>
                    <span className={classes.itemRating}>
                        {rating("Rating")}:{" "}
                        <span>{topPlayer.rating.toFixed(0)}</span>
                    </span>
                </div>
            </div>
            <div className={cn(classes.itemPlace, `place-${place}`)}>
                {place}
            </div>
        </div>
    );
};
