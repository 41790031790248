import { makeStyles } from '@mui/styles';

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from 'src/pages/game-new/constants';
import theme from 'src/theme';

export const useStyles = makeStyles({
    appBarGridContainer: {
        display: 'flex',
        height: '65px',
        flexWrap: 'nowrap',

        '@media (max-width: 1000px)': {
            display: 'flex',
            justifyContent: 'space-between', 
            alignItems: 'center',
        },

        '&.showCashBoxButton': {
            display: 'flex',
        },

        '&.inGeneralMode': {
            display: 'flex',
        },

        '@media screen and (max-width: 1200px) and (orientation: portrait)': {
            height: '55px',
        },

        '@media screen and (max-width: 460px) and (orientation: portrait)': {
            height: '48px',
        },

        '@media screen  (max-width: 1200px) and (orientation: landscape)': {
            display: 'flex',
            height: '100%',
        },

        '&.isApp': {

            '@media screen and (max-width: 1200px) and (orientation: portrait)': {
                height: '55px',
            },

            '@media screen and (max-width: 460px) and (orientation: portrait)': {
                height: '48px',
            },

            '@media screen and (max-width: 1200px) and (orientation: landscape)': {
                display: 'flex',
                height: '100%',
            },

        },
    },

    appBarElement: {
        position: 'relative',
        width: 'auto',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        '&.balance': {
            marginRight: 5,
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'column-reverse',
                alignItems: 'flex-start',
            },
        },
        '@media (hover: hover)': {
            '&:hover .navDropdown': {
                visibility: 'visible',
                opacity: 1,
                transform: 'translateY(0)',
            },
        },
        '@media (hover: none)': {
            '&.dropDownStatus .navDropdown': {
                visibility: 'visible',
                opacity: 1,
                transform: 'translateY(0)',
            },
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            marginLeft: '0',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            marginLeft: '0',
        },
    },

    languagePickerItem: {
        [theme.breakpoints.up('lg')]: {
            '&:hover .languagePickerDropdown': {
                visibility: 'visible',
                opacity: 1,
                transform: 'translateY(0)',
                '&.darkBlue': {},
                '&.darkGreen': {
                    background:
                        'linear-gradient(19.92deg, rgba(1, 1, 1, 0.7) 13.3%, rgba(14, 15, 15, 0.7) 86.7%)',
                },
            },
        },
        [theme.breakpoints.down('lg')]: {
            '&.active': {
                '& .languagePickerDropdown': {
                    visibility: 'visible',
                    opacity: 1,
                    transform: 'translateY(0)',
                    '&.darkBlue': {},
                    '&.darkGreen': {
                        background:
                            'linear-gradient(19.92deg, rgba(1, 1, 1, 0.7) 13.3%, rgba(14, 15, 15, 0.7) 86.7%)',
                    },
                },
            },
        },
        [theme.breakpoints.down(370)]: {
            '& .languagePickerDropdown': {
                right: 'unset',
                left: 0,
            },
        },
        // [theme.breakpoints.down('sm')]: {
        //     display: 'none',
        // },
    },
    languageIcon: {
        width: 32,
        height: 32,
        [theme.breakpoints.down('sm')]: {
            width: 28,
            height: 28,
        },
    },
    bonusBalanceWrapper: {
        '&:hover $bonusDescriptionModal': {
            opacity: 1,
            transform: 'translateY(0px)',
            visibility: 'visible',
        },
        '&.desktopBonusBalance': {
            transform: 'scale(1)',
        },
    },
    bonusDescriptionModal: {},
    bonusBalance: {
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
        },
        '&.bonusCoin': {
            [theme.breakpoints.down('lg')]: {
                marginTop: 0,
            },
        },
    },

    app: {
        color: 'white',
        fontSize: 12,
        padding: '0',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
    },
    join: {
        color: 'white',
        fontSize: '12px',
        marginLeft: 5,
        fontWeight: 'bolder',
    },
    pwaIcon: {
        width: '25px',
    },

    unitedAppbarBalance: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(245, 245, 245, 0.04)',
        padding: '0 0 0 1vw',
        marginLeft: 'auto',
        borderRadius: '5px',
        [theme.breakpoints.down('lg')]: {
            padding: '1vw',
            marginLeft: 'auto',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            padding: '1vw',
            margin: 0,
        },

        [theme.breakpoints.between('sm', 'md')]: {
            padding: '0 0.5vw',
            margin: 0,
        },

        [theme.breakpoints.down('sm')]: {
            padding: '0 0.5vw',
            margin: '0 1vw',
        },
    },

    unitedAppbarEye: {
        width: 'fit-content',
        height: 'fit-conntent',
        color: 'rgba(245, 245, 245, 0.04)',
        //[theme.breakpoints.down(700)]: {
        //    display: 'none',
        //},
    },

    unitedAppbarCount: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 10px',
        '&.gold': {
            padding: '0 10px',
            fontFamily: 'Montserrat', 
            fontSize: '20px',
            lineHeight: '24px',
            letterSpacing: '0.02em',
            textAlign: 'center',
        },
        '&.silver': {
            padding: '0 10px',
            fontFamily: 'Montserrat',
            fontSize: '20px', 
            lineHeight: '24px',
            letterSpacing: '0.02em',
            textAlign: 'center',
        },
        '& > *': {
        //    marginRight: '5px',
        },
        '& > *:last-child': {
            marginRight: 0,
        },
        '&.isAggregatorMode': {
            width: 'auto',
            padding: '0px 5px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 2px',
            '&.gold': {
                background: 'transparent',
                backgroundClip: 'padding-box, border-box',
                backgroundOrigin: 'border-box',
            },
            '&.aggregatorMode': {
                height: 40,
            },
            '&.silver': {
                background: 'transparent',
                backgroundClip: 'padding-box, border-box',
                backgroundOrigin: 'border-box',
            },
        },
        [theme.breakpoints.up(1150)]: {
            height: '45px',
        },
        '@media (max-width: 400px)': {
            padding: 0,
            '&.gold': {
                padding: 0,
            },
            '&.silver': {
                padding: 0,
            },
            '&.isAggregatorMode': {
                padding: 0,
            },
        },
        '@media (max-width: 800px)': {
            padding: 0,
            '&.gold': {
                padding: 0,
            },
            '&.silver': {
                padding: 0,
            },
            '&.isAggregatorMode': {
                padding: 0,
            },
        },
    },

    separator1: {
        color: 'rgba(255, 255, 255, 0.2)',
        //marginRight: '5px',
        //marginLeft: '5px',
        lineHeight: '1.2',
        fontSize: '18px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '300',
        [theme.breakpoints.down(700)]: {
            display: 'none',
        },
    },
    separator2: {
        color: 'rgba(255, 255, 255, 0.2)',
        marginRight: '5px',
        marginLeft: '10px',
        lineHeight: '1.2',
        fontSize: '18px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '300',
        [theme.breakpoints.down(700)]: {
            marginRight: '0',
            marginLeft: '0',
        },
    },
    languagePickerWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1vw',
    },
    hidden: {
        display: 'none',
    },
});
