import React from "react";

import { arrayAsKeyStructure } from "src/utils/key-as-arr-structure";
import Junior1Icon from 'src/assets/icons/chessmen/profile/Junior_Level_01.svg';
import Junior2Icon from 'src/assets/icons/chessmen/profile/Junior_Level_02.svg';
import Junior3Icon from 'src/assets/icons/chessmen/profile/Junior_Level_03.svg';
import Pawn1Icon from 'src/assets/icons/chessmen/profile/Pawn_Level_01.svg';
import Pawn2Icon from 'src/assets/icons/chessmen/profile/Pawn_Level_02.svg';
import Pawn3Icon from 'src/assets/icons/chessmen/profile/Pawn_Level_03.svg';
import Bishop1Icon from 'src/assets/icons/chessmen/profile/Bishop_Level_01.svg';
import Bishop2Icon from 'src/assets/icons/chessmen/profile/Bishop_Level_02.svg';
import Bishop3Icon from 'src/assets/icons/chessmen/profile/Bishop_Level_03.svg';
import Knight1Icon from 'src/assets/icons/chessmen/profile/Knigh_Level_01.svg';
import Knight2Icon from 'src/assets/icons/chessmen/profile/Knigh_Level_02.svg';
import Knight3Icon from 'src/assets/icons/chessmen/profile/Knigh_Level_03.svg';
import Rook1Icon from 'src/assets/icons/chessmen/profile/Rook_Level_01.svg';
import Rook2Icon from 'src/assets/icons/chessmen/profile/Rook_Level_02.svg';
import Rook3Icon from 'src/assets/icons/chessmen/profile/Rook_Level_03.svg';
import Queen1Icon from 'src/assets/icons/chessmen/profile/Queen_Level_01.svg';
import Queen2Icon from 'src/assets/icons/chessmen/profile/Queen_Level_02.svg';
import Queen3Icon from 'src/assets/icons/chessmen/profile/Queen_Level_03.svg';
import King1Icon from 'src/assets/icons/chessmen/profile/King_Level_01.svg';
import King2Icon from 'src/assets/icons/chessmen/profile/King_Level_02.svg';
import King3Icon from 'src/assets/icons/chessmen/profile/King_Level_03.svg';
import Maverick1Icon from 'src/assets/icons/chessmen/profile/Maverick_Level_01.svg';
import Maverick2Icon from 'src/assets/icons/chessmen/profile/Maverick_Level_02.svg';
import Maverick3Icon from 'src/assets/icons/chessmen/profile/Maverick_Level_03.svg';
import Expert1Icon from 'src/assets/icons/chessmen/profile/Expert_Level_01.svg';
import Expert2Icon from 'src/assets/icons/chessmen/profile/Expert_Level_02.svg';
import Expert3Icon from 'src/assets/icons/chessmen/profile/Expert_Level_03.svg';
import Virtuoso1Icon from 'src/assets/icons/chessmen/profile/Virtuoso_Level_01.svg';
import Virtuoso2Icon from 'src/assets/icons/chessmen/profile/Virtuoso_Level_02.svg';
import Virtuoso3Icon from 'src/assets/icons/chessmen/profile/Virtuoso_Level_03.svg';
import Pro1Icon from 'src/assets/icons/chessmen/profile/Pro_Level_01.svg';
import Pro2Icon from 'src/assets/icons/chessmen/profile/Pro_Level_02.svg';
import Pro3Icon from 'src/assets/icons/chessmen/profile/Pro_Level_03.svg';
import Maestro1Icon from 'src/assets/icons/chessmen/profile/Maestro_Level_01.svg';
import Maestro2Icon from 'src/assets/icons/chessmen/profile/Maestro_Level_02.svg';
import Maestro3Icon from 'src/assets/icons/chessmen/profile/Maestro_Level_03.svg';
import GM1Icon from 'src/assets/icons/chessmen/profile/Grand Master_Level_01.svg';
import GM2Icon from 'src/assets/icons/chessmen/profile/Grand Master_Level_02.svg';
import GM3Icon from 'src/assets/icons/chessmen/profile/Grand Master_Level_03.svg';
import Atlant1Icon from 'src/assets/icons/chessmen/profile/Atlant_Level_01.svg';
import Atlant2Icon from 'src/assets/icons/chessmen/profile/Atlant_Level_02.svg';
import Atlant3Icon from 'src/assets/icons/chessmen/profile/Atlant_Level_03.svg';
import Reptilian1Icon from 'src/assets/icons/chessmen/profile/Reptilian_Level_01.svg';
import Reptilian2Icon from 'src/assets/icons/chessmen/profile/Reptilian_Level_02.svg';
import Reptilian3Icon from 'src/assets/icons/chessmen/profile/Reptilian_Level_03.svg';
import Kairos1Icon from 'src/assets/icons/chessmen/profile/Kairos_Level_01.svg';
import Kairos2Icon from 'src/assets/icons/chessmen/profile/Kairos_Level_02.svg';
import Kairos3Icon from 'src/assets/icons/chessmen/profile/Kairos_Level_03.svg';
import CaissasHand1Icon from 'src/assets/icons/chessmen/profile/Caissas_hand_01.svg';
import CaissasHand2Icon from 'src/assets/icons/chessmen/profile/Caissas_hand_02.svg';
import CaissasHand3Icon from 'src/assets/icons/chessmen/profile/Caissas_hand_03.svg';

import { RankType } from "../../store/models";

import { useStyles } from "./styles";


const ranksHelper = arrayAsKeyStructure([
    [[RankType.junior1, RankType.junior2, RankType.junior3], "junior"],
    [[RankType.pawn1, RankType.pawn2, RankType.pawn3], "pawn"],
    [[RankType.bishop1, RankType.bishop2, RankType.bishop3], "bishop"],
    [[RankType.knight1, RankType.knight2, RankType.knight3], "knight"],
    [[RankType.rook1, RankType.rook2, RankType.rook3], "castle"],
    [[RankType.queen1, RankType.queen2, RankType.queen3], "queen"],
    [[RankType.king1, RankType.king2, RankType.king3], "king"],
    [[RankType.maverick1, RankType.maverick2, RankType.maverick3], "mavrick"],
    [[RankType.expert1, RankType.expert2, RankType.expert3], "expert"],
    [[RankType.virtuoso1, RankType.virtuoso2, RankType.virtuoso3], "mavrick"],
    [[RankType.pro1, RankType.pro2, RankType.pro3], "pro"],
    [[RankType.maestro1, RankType.maestro2, RankType.maestro3], "maestro"],
    [[RankType.gm1, RankType.gm2, RankType.gm3], "gameMaster"],
    [[RankType.atlant1, RankType.atlant2, RankType.atlant3], "atlant"],
    [[RankType.reptilian1, RankType.reptilian2, RankType.reptilian3], "reptilian"],
    [[RankType.kairos1, RankType.kairos2, RankType.kairos3], "kairos"],
    [[RankType.caissashand1, RankType.caissashand2, RankType.caissashand3], "caissasHand"],
]);

const ranks = {
    junior: {
        img: {
            junior1: Junior1Icon,
            junior2: Junior2Icon,
            junior3: Junior3Icon,
        },
    },
    pawn: {
        img: {
            pawn1: Pawn1Icon,
            pawn2: Pawn2Icon,
            pawn3: Pawn3Icon,
        },
    },
    bishop: {
        img: {
            bishop1: Bishop1Icon,
            bishop2: Bishop2Icon,
            bishop3: Bishop3Icon,
        },
    },
    knight: {
        img: {
            knight1: Knight1Icon,
            knight2: Knight2Icon,
            knight3: Knight3Icon,
        },
    },
    rook: {
        img: {
            rook1: Rook1Icon,
            rook2: Rook2Icon,
            rook3: Rook3Icon,
        },
    },
    queen: {
        img: {
            queen1: Queen1Icon,
            queen2: Queen2Icon,
            queen3: Queen3Icon,
        },
    },
    king: {
        img: {
            king1: King1Icon,
            king2: King2Icon,
            king3: King3Icon,
        },
    },
    castle: {
        img: {
            rook1: Rook1Icon,
            rook2: Rook2Icon,
            rook3: Rook3Icon,
        },
    },
    maverick: {
        img: {
            maverick1: Maverick1Icon,
            maverick2: Maverick2Icon,
            maverick3: Maverick3Icon,
        },
    },
    expert: {
        img: {
            expert1: Expert1Icon,
            expert2: Expert2Icon,
            expert3: Expert3Icon,
        },
    },
    virtuoso: {
        img: {
            virtuoso1: Virtuoso1Icon,
            virtuoso2: Virtuoso2Icon,
            virtuoso3: Virtuoso3Icon,
        },
    },
    pro: {
        img: {
            pro1: Pro1Icon,
            pro2: Pro2Icon,
            pro3: Pro3Icon,
        },
    },
    maestro: {
        img: {
            maestro1: Maestro1Icon,
            maestro2: Maestro2Icon,
            maestro3: Maestro3Icon,
        },
    },
    gameMaster: {
        img: {
            gameMaster1: GM1Icon,
            gameMaster2: GM2Icon,
            gameMaster3: GM3Icon,
        },
    },
    atlant: {
        img: {
            atlant1: Atlant1Icon,
            atlant2: Atlant2Icon,
            atlant3: Atlant3Icon,
        },
    },
    reptilian: {
        img: {
            reptilian1: Reptilian1Icon,
            reptilian2: Reptilian2Icon,
            reptilian3: Reptilian3Icon,
        },
    },
    kairos: {
        img: {
            kairos1: Kairos1Icon,
            kairos2: Kairos2Icon,
            kairos3: Kairos3Icon,
        },
    },
    caissasHand: {
        img: {
            caissasHand1: CaissasHand1Icon,
            caissasHand2: CaissasHand2Icon,
            cissasHand3: CaissasHand3Icon,
        },
    },
};

interface Props {
    rank?: RankType;
    size: number | string;
}

const RankItem = (props: Props) => {
    let rank = props.rank || RankType.none;
    const size = props.size;

    const rankKey = rank.split(' ')[0].toLowerCase();

    let rankLevel = rank.match(/\d+/)?.[0] || "1"; 

    let rankConfig = ranks[rankKey];

    const styles = useStyles();

    //console.log('Rank:', rank); 
    //console.log('Rank Key:', rankKey); 
    //console.log('Rank Config:', rankConfig); 
    //console.log('Rank Level:', rankLevel);

    return (
        <div
            style={{
                height: size,
                width: size,
            }}
            className={styles.container}
        >
            <div
                className={styles.innerContainer}
                style={{
                    background: rankConfig?.color,
                }}
            >
                {rankConfig?.img?.[`${rankKey}${rankLevel}`] ? (
                    <img
                        src={rankConfig.img[`${rankKey}${rankLevel}`]}
                        alt=""
                        style={{ height: "100%", width: "100%" }}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};


export default RankItem;
