import React from 'react';
import { observer } from 'mobx-react';
import { useLocale } from 'src/providers/LocaleProvider';

import useStores from '../../../../../hooks/useStores';
import ViewModeModal, { textType } from '../ViewModeModal';
import getOppositeColor from 'src/utils/oppositeColor';

const X2AcceptedModal = () => {
    const { gameStore } = useStores();
    const {
        game: {
            dialogs: {
                x2: { userAcceptedDoubling },
            },
        },
    } = useLocale();

    const player = gameStore.gameState.getPlayerByColor(
        getOppositeColor(gameStore.initData?.doubling.aggressor!)
    );

    return (
        <ViewModeModal
            title={userAcceptedDoubling.compile(
                {
                    userWhoAgreedToDouble: player?.name ?? 'Anonymous',
                },
                '{{userWhoAgreedToDouble}} accepted a doubling'
            )}
            titleType={textType.gold}
            messsageType={textType.gray}
        />
    );
};

export default observer(X2AcceptedModal);
