import React, { useEffect, useState, memo } from 'react';
import { observer } from 'mobx-react';
import OutsideClickHandler from 'react-outside-click-handler';

import {
    MAX_XL_TABLET,
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
} from 'src/pages/game-new/constants';

import { IWatchingList, WatchersStatusType } from '../types/watchingTypes';

import MobileWatchingList from './MobileList/mobileList';
import DesktopWatchingList from './DesktopList/desktopList';

const WatchingList = observer(
    ({
        setOpen,
        isOpen,
        watchingArray,
        openStatus,
        isPreview,
    }: IWatchingList): JSX.Element => {
        const MOBILE_WIDTH = MIN_CHANGE_SCREEN_WIDTH;
        const DeviceWidth = window.innerWidth;

        if (
            DeviceWidth >= MAX_XL_TABLET &&
            DeviceWidth <= MAX_CHANGE_SCREEN_WIDTH &&
            isPreview
        ) {
            return (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        isOpen === WatchersStatusType.show &&
                            setOpen(WatchersStatusType.hide);
                    }}
                >
                    <DesktopWatchingList
                        setOpen={setOpen}
                        isOpen={isOpen}
                        watchingArray={watchingArray}
                        openStatus={openStatus}
                    />
                </OutsideClickHandler>
            );
        }

        return (
            <OutsideClickHandler
                onOutsideClick={() => {
                    isOpen === WatchersStatusType.show &&
                        setOpen(WatchersStatusType.hide);
                }}
            >
                {DeviceWidth >= MOBILE_WIDTH ? (
                    <DesktopWatchingList
                        setOpen={setOpen}
                        isOpen={isOpen}
                        watchingArray={watchingArray}
                        openStatus={openStatus}
                    />
                ) : (
                    <MobileWatchingList
                        setOpen={setOpen}
                        isOpen={isOpen}
                        watchingArray={watchingArray}
                        openStatus={openStatus}
                        isMobileGamePage={true}
                    />
                )}
            </OutsideClickHandler>
        );
    }
);

export default memo(WatchingList);
