import { IBoard2State } from "..";

export enum EActionBoard {
    MY_COLOR = "myColor",
    BOARD = "board",
    AVALIABLE_MOVE_START = "availableMovesStart",
    AVALIABLE_MOVE_END = "availableMovesEnd",
    KEYS = "keys",
    LABELS = "labels",
    PICKED = "picked",
    LAST_FENS = "lastFens",
    LAST_FENS_PUSH = "lastFensPush",
    STATE_UPDATE = "state",
}

const {
    MY_COLOR,
    BOARD,
    AVALIABLE_MOVE_START,
    AVALIABLE_MOVE_END,
    KEYS,
    LABELS,
    PICKED,
    LAST_FENS,
    LAST_FENS_PUSH,
    STATE_UPDATE,
} = EActionBoard;

export const boardStateUpdater = (
    boardState: IBoard2State,
    action: { type: EActionBoard; [key: string]: any }
) => {
    switch (action.type) {
        case MY_COLOR: {
            return {
                ...boardState,
                myColor: action.myColor,
            };
        }
        case BOARD: {
            return {
                ...boardState,
                board: action.board,
            };
        }
        case AVALIABLE_MOVE_START: {
            return {
                ...boardState,
                availableMovesStart: action.availableMovesStart,
            };
        }
        case AVALIABLE_MOVE_END: {
            return {
                ...boardState,
                availableMovesEnd: action.availableMovesEnd,
            };
        }
        case KEYS: {
            return {
                ...boardState,
                keys: action.keys,
            };
        }
        case LABELS: {
            return {
                ...boardState,
                labels: action.labels,
            };
        }
        case PICKED: {
            return {
                ...boardState,
                picked: action.picked,
            };
        }
        case LAST_FENS: {
            return {
                ...boardState,
                lastFens: action.lastFens,
            };
        }
        case LAST_FENS_PUSH: {
            return {
                ...boardState,
                lastFens: boardState.lastFens.push(action.lastFensPush),
            };
        }
        case STATE_UPDATE: {
            return {
              ...boardState,
              ...action.state,
            };
        }
        default: {
            return boardState;
        }
    }
};
