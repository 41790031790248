import React, { useCallback, useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

import useStores from "src/hooks/useStores";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import FenParser from "src/store/gameStore/utils/parser";
import FenHelper from "src/store/gameStore/utils/helper";
import watchersIconActiveFilled from "src/assets/icons/gameChatIcons/redesignIcons/watchersNoActive.svg";

import { useStyles } from "./styles";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";

import DesktopWatchers from "src/pages/game-new2/components/desktopWatchers";

import { ChatExistPosition } from "src/store/models";

const LeftLayoutView = () => {
    const { gameStore, authStore } = useStores();
    const watchers = gameStore.currentGameNew?.viewers || [];
    const {
        game: {
            chat: { tabs, viewers },
        },
    } = useLocale();
    const classes = useStyles();
    const {
        chatMobileType,
        onChatToggler,
        chatTogglerState,
        currentTab: currentTabProvider,
    } = useLeftLayoutContext();
    const appearanceTheme = useChangeTheme();

    const isViewMode = gameStore.isViewMode();

    return (
        <>
            <Box
                className={cn(
                    classes.gameChatHistoryBox,
                    chatMobileType,
                    appearanceTheme,
                    ChatExistPosition.inProgressPreviewFull,
                    {
                        isViewMode: isViewMode,
                    }
                )}
            >
                <div
                    className={cn(classes.tabDescribeBox, {
                        isViewMode: isViewMode,
                    })}
                >
                    <img src={watchersIconActiveFilled} alt="viewers" />
                    <p>
                        {viewers("Viewers")}&nbsp;
                        <b>{`(${watchers.length || 1})`}</b>
                    </p>
                </div>
                <DesktopWatchers
                    componentPosition={ChatExistPosition.inProgressPreviewFull}
                />
            </Box>
        </>
    );
};

export default observer(LeftLayoutView);
