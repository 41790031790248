import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';
import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';

export const useStyles = makeStyles(() => ({
    fullScreenTogglerBox: {
        position: 'absolute',
        top: '22px',
        right: '14px',
        zIndex: 200,
        cursor: 'pointer',
        transform: 'scale(0.60)',
        transition: 'transform 150ms ease-out',
        opacity: 0.7,
        '&.isLanding': {
            //top: '1.35vw',
            //right: '5px',
        },
        '&.isPoker': {
            top: '10px',
            right: '45px',
            display: 'none',
        },
        [theme.breakpoints.down('xl')]: {
            right: '5px',
            '&.isPoker': {
                top: '10px',
                right: '45px',
            },
        },
        '&:hover': {
            opacity: 0.7,
            transform: 'scale(1)',
            transition: 'transform 150ms ease-in',
        },
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
}));
