import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    progressWrapper: {
        width: '100%',
        '&.tournamentSecondModal': {
            maxWidth: 550,
            // overflow: 'hidden',
        },
    },
    timeText: {
        fontSize: 16,
        fontWeight: 500,
        color: '#fff',
        margin: 0,
        textAlign: 'center',
        marginBottom: 6,
    },
    progressHolder: {
        width: '100%',
        height: 8,
        borderRadius: 8,
        background: '#525871',
    },
    progressActive: {
        background: 'linear-gradient(90deg, #717BB7 0%, #BFC8FF 101.95%)',
        borderRadius: '8px 0px 0px 8px',
        height: '100%',
        transition: '1s linear',
    },
});
